import React, { useState, useEffect } from "react";
import HalfCircleChart from "../../components/charts/HalfCircleChart";
import { backgroundColor } from "./ChartConfig";
import Label from "../../components/ui/Label";
import DateRangeInput from "../../components/ui/DateRangeInput";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { handleApiCall, jsonToQueryParams } from "../../utilities/helpers";
import { API_URL } from "../../utilities/constants";

const ScanFlowArc = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    total: 0,
    ongoing: 0,
    completed: 0,
  });
  const [controller, setController] = useState(null);
  const fetchData = async () => {
    setData({
      total: 0,
      ongoing: 0,
      completed: 0,
    });
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;

    const query = jsonToQueryParams({
      startDate: startDate || null,
      endDate: endDate || null,
    });
    setLoading(true);

    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/analytics/scanflow-overview?${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setData({
          total: response.data.total,
          ongoing: response.data.incomplete,
          completed: response.data.completed,
        });
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const halfCircleData = {
    total: data.total,
    divisions: [data.completed, data.ongoing],
    colors: [backgroundColor[1], backgroundColor[0]],
    labels: ["Completed", "Ongoing"],
  };

  const emptyGraphData = {
    total: 1,
    divisions: [1],
    colors: ["#e0e0e0"],
    labels: ["No Data"],
  };

  return (
    <div className="chart-card">
      <div
        className="flex center-center justify-between flex-row of-hid"
        style={{
          padding: "16px",
          gap: "16px",
        }}
      >
        <p className="f-w-700"> Scan Flow Progress</p>

        <div>
          <DateRangeInput
            initialRange={{
              startDate: startDate || null,
              endDate: endDate || null,
            }}
            onApply={(v) => {
              setStartDate(v?.startDate || null);
              setEndDate(v?.endDate || null);
            }}
            onCancel={(v) => {
              setStartDate(null);
              setEndDate(null);
            }}
          />
        </div>
      </div>

      {/* Half Circle Chart */}
      <div
        style={{
          height: "300px",
        }}
      >
        {loading ? (
          <div className="flex center-center" style={{ height: "100%" }}>
            <LoaderIcon
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </div>
        ) : data.total || data.completed || data.ongoing ? (
          <HalfCircleChart
            {...halfCircleData}
            title_count={data.total}
            title_heading={"Total Scan Flow"}
          />
        ) : (
          <HalfCircleChart
            {...emptyGraphData}
            title_count={0}
            title_heading={"No Data Available"}
          />
        )}
      </div>
    </div>
  );
};

export default ScanFlowArc;
