import ButtonTertiary from "./ButtonTertiary";
import FileViewer from "./FileViewer";
import { MdCancel } from "react-icons/md";

 
const FullScreenFileViewerModal = ({ title = "", fileUrl, setShow }) => {
  return ( 
      <section
        style={{
          position: "fixed",
          inset: 0,
          zIndex: 9999,
          backgroundColor: "rgba(255, 255, 255, 0.86)",
        }}
      >
        <section
          className="col-1-1 flex center-center"
          style={{
            height: "100vh",
            width: "100vw",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              height: "100vh",
              width: "100vw",
              margin: "10px",
              padding: "15px",
              border: "solid 1px #f0f0f0",
              background: "white",
              borderRadius: "8px",
              margin: "0 auto",
              boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
            }}
          >
            <div
              className="col-1-1 flex center-left"
              style={{
                borderBottom: "solid 1px #f0f0f0",
                paddingBottom: "12px",
                marginBottom: "16px",
                gap: "10px",
              }}
            >
              <h6 className="color-primary f-w-300 t-t-u l-s-n">{title || "Document"}</h6>
              <div className="flex center-right mar-l-a" style={{
                gap:"10px"
              }} >
              {fileUrl && (
                <div>
                    <a
                      href={fileUrl}
                      download 
                      rel="noreferrer" 
                    >
                  <ButtonTertiary title="Click to Download" actionType="download"  />
                  </a>
                </div>
              )}
              <div
                className="mar-l-a cursor-pointer "
                onClick={() => setShow(false)}
              >
               <MdCancel  size={28}/>
              </div>
            </div>
            </div>

            <section style={{
              width:"100%",
              padding:"0 80px"
            }} >
              <FileViewer fileUrl={fileUrl} />
            </section>
          </div>
        </section>
      </section> 
  );
};

export default FullScreenFileViewerModal;
