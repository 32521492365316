import React, { useState, useEffect, useRef, useCallback } from "react";
import LoaderIcon from "../../components/ui/LoaderIcon";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { API_URL } from "../../utilities/constants";
import { handleApiCall } from "../../utilities/helpers";
import { TbArrowBackUp } from "react-icons/tb";

const Caution = ({ title }) => (
  <p
    className="t-a-c"
    style={{
      fontSize: "12px",
      padding: "5px",
      color: "#777",
    }}
  >
    {title}
  </p>
);

const InsuranceSelect = ({
  setTouched,
  getAll = false,
  selectedValue = null,
  callback,
  setShow,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [noMoreOptions, setNoMoreOptions] = useState(false);
  const [page, setPage] = useState(1);

  const debounceTimeout = useRef(null);
  useEffect(() => {
    const handleSearchDebounce = (searchQuery, page) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        fetchData(searchQuery, page);
        console.log("page", page);
      }, 500);
    };
    handleSearchDebounce(searchQuery, page);
  }, [searchQuery, page]);

  useEffect(() => {
    setSearchQuery(selectedValue?.description || "");
  }, [selectedValue]);

  const fetchData = async (search = "", pageNum = 1) => {
    if (noMoreOptions) return;
    try {
      setIsLoading(true);
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/insurance?sortOrder=ASC&pageSize=${getAll ? 1000 : 10}&pageNo=${
          getAll ? 0 : pageNum - 1
        }${search ? `&description=${search}` : ""}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        const data = response.data.list || [];
        if (data.length === 0) setNoMoreOptions(true);
        setFilteredOptions((prev) =>
          pageNum === 1 ? data : [...prev, ...data]
        );
      } else {
        console.error(
          "Error in response:",
          response.result.responseDescription
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
    setNoMoreOptions(false);
  };

  useEffect(() => {
    setTouched();
  }, []);

  const handleSelect = (item) => {
    const returnItem = {
      value: item.id,
      label: item.description,
      obj: item,
    };
    setTouched();
    callback(returnItem);
    setShow(false);
  };
  const handleScroll = () => {
    setPage((prevPage) => prevPage + 1);
  };
  return (
    <section id="custom-modal">
      <div className="inner">
        <div
          className="box "
          id="activity-detail-box"
          style={{
            maxHeight: "80vh",
            width: "70%",
          }}
        >
          <div className="flex center-left">
            <div>
              <h6 className="color-primary f-w-300">Select Insurance</h6>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <ButtonLightTextIcon
                title="Cancel"
                onClick={() => setShow(false)}
                icon={
                  <TbArrowBackUp />
                }
              />
            </div>
          </div>
          <br />

          <div>
            <div className="flex center-center pad-10-0">
              <input
                style={{ lineHeight: "12px" }}
                placeholder="Search"
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                className="primary"
              />
            </div>
            <div
              className="rs-table-holder"
              style={{
                maxHeight: "50vh",
                overflowY: "auto",
                padding: "0px",
              }}
            >
              <table className="rs-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Electronic Payer ID</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOptions.length > 0 &&
                    filteredOptions.map((item) => (
                      <tr
                        key={item.id}
                        className=" cursor-pointer"
                        onClick={() => handleSelect(item)}
                      >
                        <td>
                          <p
                            style={{
                              width: "350px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            title={item?.description}
                          >
                            {item?.description}
                          </p>
                        </td>
                        <td>{item?.electronicPayerId}</td>
                        <td>
                          <p
                            style={{
                              width: "350px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            title={
                              (item?.addressLine1
                                ? item?.addressLine1 + ", "
                                : "") +
                              (item?.addressLine2
                                ? item?.addressLine2 + ", "
                                : "") +
                              (item?.city ? item?.city + ", " : "") +
                              (item?.state ? item?.state + ", " : "") +
                              (item?.zip ? item?.zip : "")
                            }
                          >
                            {(item?.addressLine1
                              ? item?.addressLine1 + ", "
                              : "") +
                              (item?.addressLine2
                                ? item?.addressLine2 + ", "
                                : "") +
                              (item?.city ? item?.city + ", " : "") +
                              (item?.state ? item?.state + ", " : "") +
                              (item?.zip ? item?.zip : "")}
                          </p>
                        </td>
                      </tr>
                    ))}
                  {!isLoading && !noMoreOptions && (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            handleScroll();
                          }}
                        >
                          <Caution title="Click to load more." />
                        </div>
                      </td>
                    </tr>
                  )}
                  {isLoading && (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        <Caution
                          title={
                            <>
                              Loading{" "}
                              <LoaderIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            </>
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {!isLoading && noMoreOptions && (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        <Caution title="No more options" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InsuranceSelect;
