import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import {
  formatInputPhoneNumber,
  formatServerDate,
  handleApiCall,
  jsonToQueryParams,
} from "../utilities/helpers";
import SurgicalAssistantFilter from "./surgical-assistant/SurgicalAssistantFilter";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import SurgicalAssistantDetails from "./surgical-assistant/SurgicalAssistantDetails";
import useQueryParams from "../hooks/useQueryParams";
import PageToolbar from "../components/common/PageToolbar";
import PopUpModal from "../components/common/PopUpModal";
import { FiPlusCircle } from "react-icons/fi";

const SurgicalAssistantPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ids, setIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const search = queryParams.get("search") || "";
  const status = queryParams.get("status") || "";
  const { permissions, addToastMessage } = useUI();
  const [controller, setController] = useState(null);
  const showId = queryParams.get("showId") || null;
	const [viewAssistant, setViewAssistant] = useState("");

	useEffect(() => {
		if (showId && data?.records?.some(item => item.id == showId)) {
			setViewAssistant(showId);
		} 
  }, [showId, data]);
  

  const toggleNumberInArray = (number) => {
    setIds((prevArray) => {
      const index = prevArray.indexOf(number);
      if (index === -1) {
        return [...prevArray, number];
      } else {
        const newArray = [...prevArray];
        newArray.splice(index, 1);
        return newArray;
      }
    });
  };
  // ====== RESET FILTER
  const resetFilters = () => {
    updateQueryParam("status", "");
    updateQueryParam("search", "");
    updateQueryParam("pageNo", 0);
  };

  const getListApi = async (s) => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    try {
      setLoading(true);
      const query = jsonToQueryParams({
        search: search,
        status: status,
      });
      const response = await handleApiCall(
        API_URL +
          `/api/1.0.0/surgical-assistant/list?pageSize=${pageSize}&pageNo=${pageNo}&${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus == true) {
        setData(response.data);
      } else {
        setData(null);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error:", e);
    }
  };

  const active = async (type) => {
    try {
      if (!ids.length) {
        addToastMessage({
          status: false,
          message: `Something went wrong while ${type} surgical assistants. Please try again.`,
        });
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/surgical-assistant/status/${type}`,
        {
          method: "PUT",
          body: {
            ids: ids,
          },
        }
      );
      if (response.responseStatus == true) {
        getListApi();
        setIds([]);
        addToastMessage({
          status: true,
          message: `Surgical assistance ${type === "0" ? "Deactivated" : type === "1" ? "Activated" : "NA"} successfully.`,
        });
        return true;
      } else {
        addToastMessage({
          status: false,
          message: response?.responseDescription || "Invalid Request",
        });
        return false;
      }
    } catch (error) {
      console.log(error);
      addToastMessage({
        status: false,
        message: `Something went wrong while  ${type === "0" ? "Deactivated" : type === "1" ? "Activated" : "NA"} surgical assistants. Please try again.`,
      });
      return false;
    }
  };

  const handleDelete = async (id) => {
    try {
      if (!id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting surgical assistance. Please try again.`,
        });
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/surgical-assistant/${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.responseStatus === true) {
        getListApi();
        addToastMessage({
          status: true,
          message: `Surgical assistance deleted successfully.`,
        });
        return true;
      }
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting surgical assistance. Please try again.`,
      });
      return false;
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting surgical assistance. Please try again.`,
      });
      return false;
    }
  };

  useEffect(() => {
    getListApi();
  }, [location.search]);

  return (
    <section id="physician-assistant">
      <PageToolbar
        title="Surgical Assistant"
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => navigate("/add-assistant")}
              icon={<FiPlusCircle />}
            />
          )
        }
      />
      <SurgicalAssistantFilter
        ids={ids}
        callback={(type) => {
          active(type);
        }}
        resetFilters={() => {
          resetFilters();
        }}
      />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.records.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>NPI</th>
                <th>Certification,Expiration date</th>
                {/* <th>Credential</th> */}
                <th>Cell Phone Number</th>
                <th>Work Email</th>
                <th>Address</th>
                <th>State, City, Zip</th>
                {/* <th>Status</th> */}
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            {data && (
              <tbody>
                {data?.records?.map((item, i) => (
                  <tr key={i}>
                    <td>{pageNo * pageSize + i + 1}</td>
                    <td>
                      {item.firstName + " " + item.lastName}
                      {item?.credential ? ", " + item.credential : ""}
                    </td>
                    {/* <td>{item.credential ? item.credential : "-"}</td> */}
                    <td>{item?.npi || "-"}</td>
                    <td>
                      {item?.certificateNumber || "-"}
                      {item?.licenseExpiryDate
                        ? ", " + formatServerDate(item?.licenseExpiryDate)
                        : ""}
                    </td>
                    <td>
                      {item.cellPhone
                        ? formatInputPhoneNumber(item.cellPhone.trim())
                        : "-"}
                    </td>
                    <td>{item?.workEmail || "-"}</td>
                    <td>{item.homeAddress || "-"}</td>
                    <td>
                      {item.homeState || "-"}, {item.homeCity || "-"},{" "}
                      {item.homeZip || "-"}
                    </td>
                    {/* <td>
                      <p className="f-w-500">
                        {item.statusId === 0
                          ? "Inactive"
                          : item.statusId === 1
                            ? "Active"
                            : "NA"}
                      </p>
                    </td> */}
                    <td>
                      <div
                        className="flex center-center"
                        style={{ gap: "12px" }}
                      >
                        {permissions?.isReadAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <ButtonTertiary
                              onClick={() => {
                                updateQueryParam("showId", item?.id);
                              }}
                              actionType="view"
                              title="View"
                            />
                          </div>
                        )}

                        {permissions?.isUpdateAllowed && (
                          <div>
                            <NavLink
                              to={`/edit-assistant?next=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                              state={{ id: item?.id }}
                            >
                              <ButtonTertiary actionType="edit" title="Edit" />
                            </NavLink>
                          </div>
                        )}

                        {permissions?.isDeleteAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="reload"
                                  title="Archive User"
                                />
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    await handleDelete(item.id);
                                    return true;
                                  }}
                                  message="Are you sure you want to Archive this surgical assistant?"
                                />
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      {viewAssistant && (
        <PopUpModal>
          <SurgicalAssistantDetails
            setViewAssistant={setViewAssistant}
            id={viewAssistant}
          />
        </PopUpModal>
      )}
      {data && !loading && (
        <div
          className="flex center-center"
          style={{
            padding: "10px 15px 10px 15px",
            gap: "12px",
          }}
        >
          <p className="f-w-300" style={{ fontSize: "11px" }}>
            Page no:
          </p>
          <Pagination
            totalRecords={data.totalRecords}
            pageNo={data.pageNo}
            pageSize={data.pageSize}
          />
        </div>
      )}
    </section>
  );
};

export default SurgicalAssistantPage;
