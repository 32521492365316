import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import {
  formatServerDate,
  getUserTypeInfo,
  handleApiCall,
  jsonToQueryParams,
} from "../utilities/helpers";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import UserFilter from "./user/UserFilter";
import DefaultUserImage from "../images/icons/default.png";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import UserDetails from "./user/UserDetails";
import useQueryParams from "../hooks/useQueryParams";
import PageToolbar from "../components/common/PageToolbar";
import { FiPlusCircle } from "react-icons/fi";

const UsersPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageNo = queryParams.get("pageNo") || 0;
  const pageSize = queryParams.get("pageSize") || 12;
  const { addToastMessage } = useUI();
  const [controller, setController] = useState(null);

  const { permissions } = useUI();

  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("userType", "");
    updateQueryParam("pageNo", 0);
  };

  const handleDelete = async (id) => {
    try {
      if (!id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting user. Please try again.`,
        });
        return false;
      }
      const response = await handleApiCall(API_URL + `/api/1.0.0/users/${id}`, {
        method: "DELETE",
      });
      if (response.responseStatus == true) {
        await callListApi();
        addToastMessage({
          status: true,
          message: `User deleted successfully.`,
        });
      } else {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting user. Please try again.`,
        });
      }
      return true;
    } catch (error) {
      console.error("Error deleting patient:", error);
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting user. Please try again.`,
      });
      return false;
    }
  };

  const callListApi = async () => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    setLoading(true);
    const query = jsonToQueryParams({
      search: queryParams.get("search") || "",
      userType: queryParams.get("userType") || "",
      pageNo: queryParams.get("pageNo") || 0,
      pageSize: queryParams.get("pageSize") || 12,
    });
    try {
      const response = await handleApiCall(
        API_URL +
          `/api/1.0.0/users/list?${query}&showArchivedUsers=hide&sortOrder=DESC`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    callListApi();
  }, [location.search]);

  return (
    <section id="activity-v2">
      <PageToolbar
        title={"Users"}
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => navigate("/add-user")}
              icon={<FiPlusCircle />}
            />
          )
        }
      />
      <UserFilter
        resetFilters={() => {
          resetFilters();
        }}
      />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.records.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Type</th>
                <th>Activated on</th>
                <th>Expires at</th>
                <th>Notes</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.records.map((data, index) => (
                  <tr key={index}>
                    <td>{pageNo * pageSize + index + 1}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img
                          src={data.image || DefaultUserImage}
                          alt={data.fullName}
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />
                        <span>{data.fullName}</span>
                      </div>
                    </td>
                    <td>{data.email}</td>
                    <td>{getUserTypeInfo(data.type)?.label || "--"}</td>
                    <td>
                      {data.activeStart
                        ? formatServerDate(data.activeStart)
                        : "--"}
                    </td>
                    <td>
                      {data.activeEnd ? formatServerDate(data.activeEnd) : "--"}
                    </td>
                    <td>{data.notes}</td>
                    <td>
                      <div className="flex" style={{ gap: "4px" }}>
                        {permissions?.isReadAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="view"
                                  title="View"
                                />
                              }
                              content={
                                <UserDetails
                                  id={data?.id}
                                  showedit={
                                    permissions?.isUpdateAllowed &&
                                    data.type !== "Physician Assistant" &&
                                    data.type !== "assistant"
                                  }
                                />
                              }
                            />
                          </div>
                        )}
                        {permissions?.isDeleteAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="reload"
                                  title="Archive User"
                                />
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    await handleDelete(data.id);
                                    return true;
                                  }}
                                  message="Are you sure you want to Archive this user?"
                                />
                              }
                            />
                          </div>
                        )}
                        {permissions?.isUpdateAllowed &&
                          data.type !== "assistant" &&
                          data.type !== "Physician Assistant" && (
                            <NavLink
                              to={`/edit-user?next=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                              state={{
                                id: data.id,
                              }}
                            >
                              <ButtonTertiary actionType="edit" title="Edit" />
                            </NavLink>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "24px 15px 32px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};
export default UsersPage;
