import { useRef, useState } from "react";
import ResultsPerPage from "../../components/common/ResultsPerPage";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useLocation, useNavigate } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import Label from "../../components/ui/Label";
import DatePickerInput from "../../components/ui/DatePickerInput";
import { GrPowerReset } from "react-icons/gr";

const PatientFilter = ({ resetFilters }) => {
  const { updateQueryParam, queryParams } = useQueryParams();
  const [searchValue, setSearchValue] = useState(queryParams.get("search") || "");
  const debounceTimeout = useRef(null);

  const handleSearchChange = (value) => {
    setSearchValue(value);
 
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
 
    debounceTimeout.current = setTimeout(() => {
      queryParams.set("pageNo", 0);
      updateQueryParam("search", value);
    }, 500);
  };

  return (
    <div className="col-1-1 flex center-left filters">
      <div>
        <Label variant="filter" title="Patient Name" />
        <input
          className="primary"
          type="text"
          value={searchValue}
          placeholder="Eg: John Deo"
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </div>
      <div>
        <Label variant="filter" title="DOB" />
        <DatePickerInput
          callback={(v) => {
            queryParams.set("pageNo", 0);
            updateQueryParam("dob", v);
          }}
          value={queryParams.get("dob")}
        />
      </div>
      <div className="flex center-left" style={{ gap: "12px" }}>
        <div>
          <Label variant="filter" title="Reset" />
          <ButtonLightTextIcon
            title="Reset"
            className="light"
            icon={
         <GrPowerReset />
            }
            onClick={() => {
              setSearchValue("");
              resetFilters();
            }}
          />
        </div>
        <div className="mar-l-a">
          <Label variant="filter" title="Per page" />
          <ResultsPerPage />
        </div>
      </div>
    </div>
  );
};

export default PatientFilter;
