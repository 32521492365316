import { useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import { NavLink } from "react-router-dom";
import LoaderIcon from "../../components/ui/LoaderIcon";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import InfoItem from "../../components/ui/InfoItem";
import { getRandomColor, handleApiCall } from "../../utilities/helpers";
import { TbArrowBackUp, TbUserCircle } from "react-icons/tb";
import { FiEdit } from "react-icons/fi";
import { GoFileSubmodule } from "react-icons/go";
import {
  MdLockOpen,
  MdLockOutline,
  MdOutlineLockOpen,
  MdOutlineRoomPreferences,
} from "react-icons/md";

const GroupDetails = ({ setShow, id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { permissions } = useUI();
  const fetchFacilityData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/groups/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      } else {
        setData(null);
        setError("Failed to fetch facility data.");
      }
    } catch (e) {
      setError("Failed to fetch facility data.");
      setLoading(false);
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchFacilityData(id);
  }, [id]);

  return (
    <div id="activity-detail-box">
      <div className="flex center-left">
        <div>
          <h6 className="color-primary f-w-300">Group Details</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ButtonLightTextIcon
            title="Cancel"
            onClick={() => setShow(false)}
            icon={<TbArrowBackUp />}
          />
        </div>
      </div>
      <div
        style={{
          height: "60vh",
          margin: "10px",
          overflowY: "scroll",
          padding: "20px 5px",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
      >
        {loading && (
          <div
            className="col-1-1"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderIcon
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
        )}
        {!loading && error && (
          <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
            {error}
          </p>
        )}
        {data && !loading && !error && (
          <div className="activity-card pos-rel">
            <div
              style={{
                position: "absolute",
                content: "",
                height: "4px",
                left: "-1px",
                right: "-1px",
                bottom: "-2px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            ></div>
            <div
              className="col-1-1 flex center-left"
              style={{
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <h5 className="f-w-700">
                <span className="f-w-300">#</span>
                &nbsp;
                {data?.id}
              </h5>
            </div>
            <div
              style={{
                padding: "12px 0 12px 0 ",
              }}
            >
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbUserCircle color={getRandomColor()} size={20} />}
                  label={"Group name"}
                  name={data?.groupName || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineRoomPreferences
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"Patient listing preference"}
                  name={data?.patientListingOption || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineRoomPreferences
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"Facility listing preference"}
                  name={data?.facilityListingOption || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineRoomPreferences
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"Surgeon listing preference"}
                  name={data?.surgeonListingOption || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineRoomPreferences
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"Preference card listing preference"}
                  name={data?.preferenceCardListingOption || "NA"}
                />
              </div>
              <hr
                style={{
                  marginTop: "10px",
                  marginBottom: "12px",
                }}
              />
              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                Web Modules
              </p>
              {data?.webModules?.length ? (
                data.webModules.map((webModule, index) => (
                  <>
                    <div
                      key={`web-${index}`}
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <GoFileSubmodule color={getRandomColor()} size={20} />
                        }
                        label="Module Name"
                        name={webModule.module?.moduleName || "NA"}
                      />
                    </div>
                    <div
                      key={`web-${index}`}
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          webModule.isListAccess ? (
                            <MdOutlineLockOpen
                              color={getRandomColor()}
                              size={20}
                            />
                          ) : (
                            <MdLockOutline color={getRandomColor()} size={20} />
                          )
                        }
                        label="List Access"
                        name={webModule.isListAccess ? "Yes" : "No"}
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          webModule.isAddAccess ? (
                            <MdOutlineLockOpen
                              color={getRandomColor()}
                              size={20}
                            />
                          ) : (
                            <MdLockOutline color={getRandomColor()} size={20} />
                          )
                        }
                        label="Add Access"
                        name={webModule.isAddAccess ? "Yes" : "No"}
                      />
                    </div>
                    <div
                      key={`web-${index}`}
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          webModule.isUpdateAccess ? (
                            <MdOutlineLockOpen
                              color={getRandomColor()}
                              size={20}
                            />
                          ) : (
                            <MdLockOutline color={getRandomColor()} size={20} />
                          )
                        }
                        label="Update Access"
                        name={webModule.isUpdateAccess ? "Yes" : "No"}
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          webModule.isDeleteAccess ? (
                            <MdOutlineLockOpen
                              color={getRandomColor()}
                              size={20}
                            />
                          ) : (
                            <MdLockOutline color={getRandomColor()} size={20} />
                          )
                        }
                        label="Delete Access"
                        name={webModule.isDeleteAccess ? "Yes" : "No"}
                      />
                    </div>
                    <hr
                      style={{
                        marginTop: "10px",
                        marginBottom: "12px",
                      }}
                    />
                  </>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px 12px",
                    backgroundColor: "rgb(245, 245, 245)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        No Web Modules Available
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                App Modules
              </p>
              {data?.appModules?.length ? (
                data.appModules.map((appModule, index) => (
                  <>
                    <div
                      key={`app-${index}`}
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <GoFileSubmodule color={getRandomColor()} size={20} />
                        }
                        label="App Module Name"
                        name={appModule.module?.moduleName || "NA"}
                      />
                    </div>
                    <div
                      key={`web-${index}`}
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          appModule.isListAccess ? (
                            <MdOutlineLockOpen
                              color={getRandomColor()}
                              size={20}
                            />
                          ) : (
                            <MdLockOutline color={getRandomColor()} size={20} />
                          )
                        }
                        label="List Access"
                        name={appModule.isListAccess ? "Yes" : "No"}
                      />

                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          appModule.isAddAccess ? (
                            <MdOutlineLockOpen
                              color={getRandomColor()}
                              size={20}
                            />
                          ) : (
                            <MdLockOutline color={getRandomColor()} size={20} />
                          )
                        }
                        label="Add Access"
                        name={appModule.isAddAccess ? "Yes" : "No"}
                      />
                    </div>
                    <div
                      key={`web-${index}`}
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          appModule.isUpdateAccess ? (
                            <MdOutlineLockOpen
                              color={getRandomColor()}
                              size={20}
                            />
                          ) : (
                            <MdLockOutline color={getRandomColor()} size={20} />
                          )
                        }
                        label="Update Access"
                        name={appModule.isUpdateAccess ? "Yes" : "No"}
                      />

                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          appModule.isDeleteAccess ? (
                            <MdOutlineLockOpen
                              color={getRandomColor()}
                              size={20}
                            />
                          ) : (
                            <MdLockOutline color={getRandomColor()} size={20} />
                          )
                        }
                        label="Delete Access"
                        name={appModule.isDeleteAccess ? "Yes" : "No"}
                      />
                    </div>
                    <hr
                      style={{
                        marginTop: "10px",
                        marginBottom: "12px",
                      }}
                    />
                  </>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px 12px",
                    backgroundColor: "rgb(245, 245, 245)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        No App Modules Available
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        {permissions?.isUpdateAllowed && (
          <NavLink
            to={
              data?.id
                ? `/edit-group?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                : "#"
            }
            state={data?.id ? { id: data.id } : null}
          >
            <ButtonLightTextIcon
              title="Edit Details"
              icon={<FiEdit />}
              onClick={() => {
                setShow(false);
              }}
            />
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default GroupDetails;
