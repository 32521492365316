import React from "react";
import ComingSoon from "../images/icons/under-construction.png";
import { NavLink } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import { TbArrowBackUp } from "react-icons/tb";

const UnderConstructionPage = () => {
	return (
		<>
			<style>
				{`
          @keyframes moveImage {
            0% {
              transform: translateX(0);
            }
            50% {
              transform: translateX(20px); /* Horizontal motion */
            }
            100% {
              transform: translateX(0);
            }
          }

          /* Responsive Styles */
          @media (max-width: 768px) {
            /* Stack content vertically on small screens */
            div {
              flexDirection: "column"; /* Stack items vertically */
              textAlign: "center"; /* Center text on small screens */
            }

            .content-text {
              margin: 20px 0;
            }

            .image-container {
              width: 80%;
              margin-top: 20px;
            }

            .heading {
              font-size: 28px;
            }

            .description {
              font-size: 16px;
            }
          }

          @media (max-width: 480px) {
            /* Adjust further for very small screens */
            .heading {
              font-size: 24px;
            }

            .description {
              font-size: 14px;
            }

            .image-container {
              width: 100%;
            }
          }
        `}
			</style>
			<div className='flex center-center height-100dvh'>
				<div
					className='flex center-center'
					style={{ maxWidth: "1080px" }}>
					<div className='col-1-2'>
						<div
							style={{
								maxWidth: "480px",
							}}>
							<h1 className='color-primary f-w-900'>
								This module is <br />
								Under development.
							</h1>
							<br />
							<hr
								style={{
									height: "4px",
									width: "24px",
									outline: "none",
									border: "none",
									background: "#f0f0f0",
								}}
							/>
							<br />
							<h6 className='f-w-300'>
								This module is currently under development and
								will be available once it is complete. Stay
								tuned or visit back again later.
							</h6>
							<br />
							<div>
								<NavLink to={"/activities"}>
									<ButtonTextIcon
										title='Return Back'
										icon={<TbArrowBackUp />}
									/>
								</NavLink>
							</div>
						</div>
					</div>
					<div className='col-1-2'>
						<img
							src={ComingSoon}
							alt='Under Construction'
							className='col-1-1 height-auto dis-b'
							style={{
								animation: "moveImage 4s linear infinite",
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default UnderConstructionPage;
