import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const PieChart = ({ data, options, style, className }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const pieChart = new Chart(chartRef.current, {
      type: "pie",
      data: data,
      options: {
        ...options,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 1000,
          easing: "easeInOutQuad",
        },
      },
    });

    return () => pieChart.destroy();
  }, [data, options]);

  return <canvas ref={chartRef} style={style} className={className} />;
};

export default PieChart;