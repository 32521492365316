import { useEffect, useRef, useState } from "react";
import PageToolbar from "../components/common/PageToolbar";
import { useUI } from "../context/ui";
import useQueryParams from "../hooks/useQueryParams";
import { API_URL, SCANFLOW_STATUS_LIST } from "../utilities/constants";
import { handleApiCall, jsonToQueryParams } from "../utilities/helpers";
import { ScanflowProvider, useScanflow } from "./scanflow/scanflow-context";
import TableFilter from "./scanflow/TableFilter";
import Tabs from "./scanflow/Tabs";
import TaskStats from "./scanflow/TaskStats";
import LoaderIcon from "../components/ui/LoaderIcon";
import SingleTask from "./scanflow/SingleTask";
import TaskDetails from "./scanflow/TaskDetails";
import Pagination from "../components/common/Pagination";
import PopUpModal from "../components/common/PopUpModal";

const ScanflowPage = () => {
	const [controller, setController] = useState(null);
	const [taskController, setTaskController] = useState(null);
	const containerRef = useRef(null);
	const { updateQueryParam, queryParams, location } = useQueryParams();
	const {
		random,
		stats,
		taskList,
		setTaskList,
		task,
		setTask,
		taskLoading,
		setTaskLoading,
		setStats,
	} = useScanflow();
	const [dataLoading, setDataLoading] = useState(false);
	const [taskShow, setTaskShow] = useState(false);

	// ====== FILTER QUERY PARAMS
	const pageSize = queryParams.get("pageSize") || 12;
	const pageNo = queryParams.get("pageNo") || 0;
	const isCompleted = queryParams.get("isCompleted") || "all";
	const id = queryParams.get("id");
	const billStatus = queryParams.get("billStatus") || "";
	const startDate = queryParams.get("startDate") || "";
	const endDate = queryParams.get("endDate") || "";
	const claimStatus = queryParams.get("claimStatus") || "";
	const hospitalContract = queryParams.get("hospitalContract") || "";
	const patient = queryParams.get("patient") || null;
	const assistant = queryParams.get("assistant") || null;
	const insurance = queryParams.get("insurance") || null;
	const facility = queryParams.get("facility") || null;
	const practice = queryParams.get("practice") || null;
	const patientChartNo = queryParams.get("patientChartNo") || "";

	// ====== RESET FILTER
	const resetFilters = () => {
		updateQueryParam("billStatus", "");
		updateQueryParam("startDate", "");
		updateQueryParam("endDate", "");
		updateQueryParam("claimStatus", "");
		updateQueryParam("hospitalContract", "");
		updateQueryParam("patient", null);
		updateQueryParam("assistant", null);
		updateQueryParam("insurance", null);
		updateQueryParam("facility", null);
		updateQueryParam("practice", null);
		updateQueryParam("patientChartNo", "");
		updateQueryParam("pageNo", 0);
	};

	const callTaskByIdApi = async (id) => {
		taskController?.abort();
		const newController = new AbortController();
		setTaskController(newController);
		const { signal } = newController;
		setTaskLoading(true);

		try {
			const response = await handleApiCall(
				API_URL + `/api/1.0.1/scanflow-v2/task/${id}`,
				{ method: "GET" },
				null,
				signal
			);

			if (response.responseStatus === true) {
				setTask(response.data);
			} else if (
				response.responseStatus === false &&
				response.responseCode !== 299
			) {
				setTask(null);
			}
		} catch (e) {
			console.log("error", e);
		} finally {
			setTaskLoading(false);
		}
	};

	const callTaskApi = async () => {
		if (queryParams.get("id")) {
			return;
		}
		controller?.abort();
		const newController = new AbortController();
		setController(newController);
		const { signal } = newController;
		setDataLoading(true);
		try {
			const query = jsonToQueryParams({
				isCompleted:
					isCompleted === "incomplete"
						? false
						: isCompleted === "completed"
							? true
							: null,
				pageNo: pageNo,
				pageSize: pageSize,
				billStatus,
				startDate,
				endDate,
				claimStatus,
				hospitalContract,
				patientId: patient ? JSON.parse(patient)?.value : null,
				practiceId: practice ? JSON.parse(practice)?.value : null,
				facilityId: facility ? JSON.parse(facility)?.value : null,
				insuranceCompanyId: insurance
					? JSON.parse(insurance)?.value
					: null,
				assistantId: assistant ? JSON.parse(assistant)?.value : null,
				patientChartNo: patientChartNo,
			});
			const response = await handleApiCall(
				API_URL + `/api/1.0.1/scanflow-v2/task?${query}`,
				{
					method: "GET",
				},
				null,
				signal
			);

			const defaultStats = {
				incompleted: 0,
				completed: 0,
			};

			if (response.responseStatus === true) {
				setTaskList(response.data);
				setStats(response.data.stats || defaultStats);
			} else if (
				response.responseStatus === false &&
				response.responseCode !== 299
			) {
				setTaskList(null);
				setStats(defaultStats);
			}

			if (response.responseCode !== 299) {
				setDataLoading(false);
			}
		} catch (e) {
			setDataLoading(false);
			setTaskList(null);
			setStats({
				notStarted: 0,
				completed: 0,
				inProgress: 0,
				forwarded: 0,
				paused: 0,
			});
			console.log("error", e);
		}
	};

	useEffect(() => {
		if (containerRef.current) {
			if (id) {
				const targetElement = containerRef.current.querySelector(
					`#scanflow-${id}`
				);
				if (targetElement) {
					targetElement.scrollIntoView({
						behavior: "smooth",
						block: "center",
					});
					targetElement.focus();
				}
			} else {
				const firstElement = containerRef.current.querySelector(".one");
				if (firstElement) {
					firstElement.scrollIntoView({
						behavior: "smooth",
						block: "center", // Scroll to the center of the container
					});
					firstElement.focus(); // Focus the first task
				}
			}
		}
	}, [taskList, id]);

	useEffect(() => {
		callTaskApi();
		return () => {
			if (controller) {
				controller.abort();
			}
		};
	}, [location.search, isCompleted, random]);

	useEffect(() => {
		if (id) {
			callTaskByIdApi(id);
			setTaskShow(true);
		} else {
			setTaskShow(false);
			setTask(null);
		}
		return () => {
			if (taskController) {
				taskController.abort();
			}
		};
	}, [location.search, id, random]);
	return (
		<section id='scanflow-v2'>
			<PageToolbar
				title='Scanflow'
				tabs={
					<Tabs
						options={SCANFLOW_STATUS_LIST.map((o) => ({
							title: o.name,
							id: o.slug,
							content: <></>,
						}))}
						active={isCompleted}
						setActive={(v) => {
							updateQueryParam("id");
							updateQueryParam("pageNo", 0);
							updateQueryParam(
								"isCompleted",
								v === null ? null : v
							);
						}}
					/>
				}
			/>
			<TableFilter resetFilters={resetFilters} />
			{dataLoading ? (
				<div
					className='col-1-1 flex center-center'
					style={{ height: "400px" }}>
					<LoaderIcon
						style={{
							width: "30px",
							height: "30px",
						}}
					/>
				</div>
			) : (
				<>
					<TaskStats stats={stats} taskList={taskList} />
					<div className='col-1-1'>
						<div ref={containerRef} className='all-tasks'>
							{taskList &&
								taskList?.list &&
								taskList?.list?.length > 0 &&
								taskList?.list?.map((o, _i) => (
									<SingleTask key={_i} id={id} data={o} />
								))}
						</div>
						{taskList &&
							taskList?.list &&
							taskList?.list?.length === 0 && (
								<div
									className=' flex center-center'
									style={{ height: "120px" }}>
									<p>No record found.</p>
								</div>
							)}
						{!taskList && !dataLoading && (
							<div className=' flex center-center'>
								<p>Sorry, we couldn't load the records.</p>
							</div>
						)}
						<div
							className='flex center-center'
							style={{ marginBottom: "20px" }}>
							{taskList && taskList?.list?.length !== 0 && (
								<div
									className='flex center-left'
									style={{
										padding: "10px 15px 10px 15px",
										gap: "12px",
									}}>
									<p
										className='f-w-300'
										style={{ fontSize: "11px" }}>
										Page no:
									</p>
									<Pagination
										totalRecords={taskList?.totalRecords}
										pageNo={taskList?.pageNo}
										pageSize={taskList?.pageSize}
										removeParamKey={"id"}
									/>
								</div>
							)}
						</div>
					</div>
				</>
			)}
			{taskShow && (
				<section
					style={{
						position: "fixed",
						inset: 0,
						zIndex: 99999,
					}}>
					<TaskDetails
						id={id}
						CloseTaskdetails={() => {
							setTaskShow(false);
						}}
					/>
				</section>
			)}
		</section>
	);
};

const WrappedScanflowPage = () => (
	<ScanflowProvider>
		<ScanflowPage />
	</ScanflowProvider>
);

export default WrappedScanflowPage;
