import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ButtonTextIcon from "./ButtonTextIcon";
import ButtonLightTextIcon from "./ButtonLightTextIcon";
import { format } from "date-fns";
import { TbArrowBackUp, TbCalendarShare } from "react-icons/tb";
import { FiArrowRightCircle, FiPlusCircle } from "react-icons/fi";
const DateRangeInput = ({ onApply, onCancel, initialRange = {} }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
    color: "#5dc6b3",
    borderColor: "#5dc6b3",
  });

  const isValidRange =
    selectedRange.startDate instanceof Date &&
    !isNaN(selectedRange.startDate) &&
    selectedRange.endDate instanceof Date &&
    !isNaN(selectedRange.endDate);

  useEffect(() => {
    setSelectedRange({
      startDate: initialRange.startDate
        ? new Date(initialRange.startDate)
        : null,
      endDate: initialRange.endDate ? new Date(initialRange.endDate) : null,
      key: "selection",
      color: "#5dc6b3",
      borderColor: "#5dc6b3",
    });
  }, [initialRange]);

  const handleSelect = (ranges) => {
    setSelectedRange(ranges.selection);
  };

  const handleApply = () => {
    if (onApply) {
      onApply({
        startDate: selectedRange?.startDate
          ? format(selectedRange?.startDate, "yyyy-MM-dd")
          : new Date().toISOString().split("T")[0],
        endDate: selectedRange?.endDate
          ? format(selectedRange?.endDate, "yyyy-MM-dd")
          : new Date().toISOString().split("T")[0],
      });
    }
    setIsPickerOpen(false);
  };

  const handleCancel = () => {
    setIsPickerOpen(false);
  };
  const FormateDate = (date) => {
    const formattedDate = new Date(date);
    const day = String(formattedDate.getDate()).padStart(2, "0");
    const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
    const year = formattedDate.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <div className="pos-rel dis-i-b col-1-1">
      <div
        className="ui-like-input col-1-1 cursor-pointer"
        style={{ whiteSpace: "nowrap" }}
      >
        <div
          className="flex"
          style={{
            justifyContent: "space-between",
          }}
        >
          <span
            onClick={() => setIsPickerOpen(!isPickerOpen)}
            style={{ fontSize: "10px" }}
          >
            {selectedRange?.startDate?.toLocaleDateString() ? (
              <span className="color-primary">
                {FormateDate(selectedRange.startDate.toLocaleDateString())}
              </span>
            ) : (
              "MM/DD/YYYY"
            )}
            &nbsp;to&nbsp;
            {selectedRange?.endDate?.toLocaleDateString() ? (
              <span className="color-primary">
                {FormateDate(selectedRange.endDate.toLocaleDateString())}
              </span>
            ) : (
              "MM/DD/YYYY"
            )}
          </span>
          {!selectedRange?.startDate && !selectedRange?.endDate ? (
            <span onClick={() => setIsPickerOpen(!isPickerOpen)}>
              <TbCalendarShare />
            </span>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                className="cursor-pointer"
                style={{
                  backgroundColor: "#f0f0f0",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
                }}
                onClick={() => {
                  setIsPickerOpen(false);
                  onCancel();
                }}
              >
                &times;
              </span>
            </div>
          )}
        </div>
      </div>

      {isPickerOpen && (
        <>
          {/* Background Overlay */}
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(255, 255, 255, 0.72)",
              zIndex: 999,
            }}
            onClick={handleCancel}
          />

          {/* Date Picker */}
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: "4px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              maxWidth: "90%",
              width: "auto",
              padding: "10px",
              overflowX: "scroll",
              scrollbarWidth: "thin",
            }}
          >
            <DateRangePicker
              className="date-range"
              color="#17181f"
              ranges={
                isValidRange
                  ? [selectedRange]
                  : [
                      {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: "selection",
                        color: "#5dc6b3",
                        borderColor: "#5dc6b3",
                      },
                    ]
              }
              onChange={handleSelect}
              months={2}
              direction="horizontal"
              // editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              retainEndDateOnFirstSelection={true}
            />
            <div className="flex center-right pad-10" style={{ gap: "10px" }}>
              <div>
                <ButtonLightTextIcon
                  icon={<TbArrowBackUp />}
                  type="button"
                  title="Cancel"
                  onClick={handleCancel}
                />
              </div>
              <div>
                <ButtonTextIcon
                  icon={<FiArrowRightCircle />}
                  type="button"
                  title="Apply"
                  onClick={() => handleApply(selectedRange)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DateRangeInput;
