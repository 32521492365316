import { NavLink } from "react-router-dom";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useUI } from "../../context/ui";
import { getRandomColor, handleApiCall } from "../../utilities/helpers";
import InfoItem from "../../components/ui/InfoItem";
import { TbArrowBackUp, TbUserCircle } from "react-icons/tb";
import { FiEdit } from "react-icons/fi";
import { CgPokemon } from "react-icons/cg";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { PiPathBold } from "react-icons/pi";
import { FaUserDoctor } from "react-icons/fa6";


const ProcedureDetails = ({ setShow, id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { permissions } = useUI();

  const fetchFacilityData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/procedure-list/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      } else {
        setError("Failed to fetch facility data.");
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch facility data.");
      setLoading(false);
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchFacilityData(id);
  }, [id]);

  return (
    <div id="activity-detail-box">
      <div className="flex center-left">
        <div>
          <h6 className="color-primary f-w-300">Procedure Details</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ButtonLightTextIcon
            title="Cancel"
            onClick={() => setShow(false)}
            icon={<TbArrowBackUp />}
          />
        </div>
      </div>
      <div
        style={{
          height: "60vh",
          margin: "10px",
          overflowY: "scroll",
          padding: "20px 5px",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
      >
        {loading && (
          <div
            className="col-1-1"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderIcon
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
        )}
        {!loading && error && (
          <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
            {error}
          </p>
        )}
        {data && !loading && !error && (
          <div className="activity-card pos-rel">
            <div
              style={{
                position: "absolute",
                content: "",
                height: "4px",
                left: "-1px",
                right: "-1px",
                bottom: "-2px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            ></div>
            <div
              className="col-1-1 flex center-left"
              style={{
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <h5 className="f-w-700">
                <span className="f-w-300">#</span>
                &nbsp;
                {data?.id}
              </h5>
            </div>
            <div
              style={{
                padding: "12px 0 12px 0 ",
              }}
            >
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbUserCircle color={getRandomColor()} size={20} />}
                  label={"Procedure"}
                  name={data?.procedureName || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <AiOutlineFieldNumber color={getRandomColor()} size={20} />
                  }
                  label={"CPT Code"}
                  name={data?.cptCode || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CgPokemon color={getRandomColor()} size={20} />}
                  label={"Related Dx Code"}
                  name={data?.relatedDxCode || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<FaUserDoctor color={getRandomColor()} size={20} />}
                  label={"Specialty"}
                  name={data?.specialty?.name || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<PiPathBold color={getRandomColor()} size={20} />}
                  label={"CPT Description"}
                  name={data?.cptDescription || "NA"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {permissions?.isUpdateAllowed && (
          <NavLink
            to={
              data?.id
                ? `/edit-procedure?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                : "#"
            }
            state={data?.id ? { id: data.id } : null}
          >
            <ButtonLightTextIcon title="Edit Procedure" icon={<FiEdit />} />
          </NavLink>
        )}
      </div>
    </div>
  );
};
export default ProcedureDetails;
