import { NavLink } from "react-router-dom";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import {
  formatInputPhoneNumber,
  formatServerDate,
  getRandomColor,
  handleApiCall,
} from "../../utilities/helpers";
import { useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useUI } from "../../context/ui";
import InfoItem from "../../components/ui/InfoItem";
import {
  TbArrowBackUp,
  TbCalendarShare,
  TbTimelineEvent,
  TbUserCircle,
} from "react-icons/tb";
import { FiEdit } from "react-icons/fi";
import { LuMapPin } from "react-icons/lu";
import {
  MdAlternateEmail,
  MdGroups,
  MdOutlineRealEstateAgent,
  MdOutlineSecurity,
  MdOutlineSpeakerGroup,
  MdOutlineSpeakerNotes,
} from "react-icons/md";
import { FaFax } from "react-icons/fa6";
import { PiContactlessPayment, PiGenderIntersexLight } from "react-icons/pi";
import { GoCodeOfConduct, GoFileSubmodule } from "react-icons/go";
import { CiVoicemail, CiWallet } from "react-icons/ci";
import { SiCodesignal } from "react-icons/si";
import { GiRelationshipBounds } from "react-icons/gi";
import { GrIndicator } from "react-icons/gr";

const PatientDetails = ({ id, setShow }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { permissions } = useUI();

  const fetchFacilityData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/patient/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      } else {
        setData(null);
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch facility data.");
      setLoading(false);
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchFacilityData(id);
  }, [id]);

  return (
    <div id="activity-detail-box">
      <div className="flex center-left">
        <div>
          <h6 className="color-primary f-w-300">Patient Details</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ButtonLightTextIcon
            title="Cancel"
            onClick={() => setShow(false)}
            icon={<TbArrowBackUp />}
          />
        </div>
      </div>
      <div
        style={{
          height: "60vh",
          margin: "10px",
          overflowY: "scroll",
          padding: "20px 5px",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
      >
        {loading && (
          <div
            className="col-1-1"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderIcon
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
        )}
        {!loading && error && (
          <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
            {error}
          </p>
        )}
        {data && !loading && !error && (
          <div className="activity-card pos-rel">
            <div
              style={{
                position: "absolute",
                content: "",
                height: "4px",
                left: "-1px",
                right: "-1px",
                bottom: "-2px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            ></div>
            <div
              className="col-1-1 flex center-left"
              style={{
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <h5 className="f-w-700">
                <span className="f-w-300">#</span>
                &nbsp;
                {data?.id}
              </h5>
            </div>
            <div
              style={{
                padding: "12px 0 12px 0 ",
              }}
            >
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbUserCircle color={getRandomColor()} size={20} />}
                  label={"Patient"}
                  name={
                    (data?.firstName || " ") +
                    " " +
                    (data?.middleName || " ") +
                    " " +
                    (data?.lastName || " ")
                  }
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuMapPin color={getRandomColor()} size={20} />}
                  label={"Address"}
                  name={data?.address1 || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineRealEstateAgent
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"Country"}
                  name={data?.country?.name || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineRealEstateAgent
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"State"}
                  name={data?.state?.name || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineRealEstateAgent
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"City"}
                  name={data?.city?.name || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<FaFax color={getRandomColor()} size={20} />}
                  label={"Fax"}
                  name={data?.fax || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <PiGenderIntersexLight color={getRandomColor()} size={20} />
                  }
                  label={"Gender"}
                  name={data?.gender || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbCalendarShare color={getRandomColor()} size={20} />}
                  label={"Date of Birth"}
                  name={
                    data?.dateOfBirth
                      ? formatServerDate(data?.dateOfBirth)
                      : "NA"
                  }
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<GoFileSubmodule color={getRandomColor()} size={20} />}
                  label={"Insurance"}
                  name={data?.patientInsuranceData?.description || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<MdAlternateEmail color={getRandomColor()} size={20} />}
                  label={"Email"}
                  name={data?.email || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CiWallet color={getRandomColor()} size={20} />}
                  label={"Account Number"}
                  name={data?.accountNumber || "NA"}
                />

                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<SiCodesignal color={getRandomColor()} size={20} />}
                  label={"SSN"}
                  name={data?.ssn || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CiVoicemail color={getRandomColor()} size={20} />}
                  label={"Phone Number (Work)"}
                  name={
                    data?.additionalNumber
                      ? formatInputPhoneNumber(data.additionalNumber.trim())
                      : "NA"
                  }
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuMapPin color={getRandomColor()} size={20} />}
                  label={"Zip"}
                  name={data?.zip || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CiVoicemail color={getRandomColor()} size={20} />}
                  label={"Phone Number (Home)"}
                  name={
                    data?.phoneNumber
                      ? formatInputPhoneNumber(data.phoneNumber.trim())
                      : "NA"
                  }
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CiVoicemail color={getRandomColor()} size={20} />}
                  label={"Cell Phone "}
                  name={
                    data?.cellNumber
                      ? formatInputPhoneNumber(data.cellNumber.trim())
                      : "NA"
                  }
                />
              </div>

              <hr
                style={{
                  marginTop: "10px",
                  marginBottom: "12px",
                }}
              />
              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                Guarantor
              </p>

              <>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<TbUserCircle color={getRandomColor()} size={20} />}
                    label="Guarantor Name"
                    name={
                      data?.guarantor?.guarantorFirstName &&
                      data?.guarantor?.guarantorLastName
                        ? data.guarantor.guarantorFirstName +
                          " " +
                          data.guarantor.guarantorLastName
                        : "NA"
                    }
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<TbUserCircle color={getRandomColor()} size={20} />}
                    label={"Spouse/ Parent's Name"}
                    name={data?.guarantor?.guarantorSpouseName || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<LuMapPin color={getRandomColor()} size={20} />}
                    label="Address"
                    name={data?.guarantor?.guarantorAddress1 || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<CiVoicemail color={getRandomColor()} size={20} />}
                    label="Phone Number"
                    name={
                      data?.guarantor?.guarantorSpousePhone
                        ? formatInputPhoneNumber(
                            data?.guarantor?.guarantorSpousePhone.trim()
                          )
                        : "NA"
                    }
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <GiRelationshipBounds
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"Relationship"}
                    name={data?.guarantor?.guarantorRelationship || "NA"}
                  />
                </div>

                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "12px",
                  }}
                />
              </>

              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                Worker's Comp
              </p>

              <>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <GoCodeOfConduct color={getRandomColor()} size={20} />
                    }
                    label="Patient Condition"
                    name={data?.workerComp?.patientCondition || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<TbUserCircle color={getRandomColor()} size={20} />}
                    label={"Employer Name"}
                    name={data?.workerComp?.employerName || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<GrIndicator color={getRandomColor()} size={20} />}
                    label="Illness Indicator"
                    name={data?.workerComp?.illnessIndicator || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <TbCalendarShare color={getRandomColor()} size={20} />
                    }
                    label={"Injury Date"}
                    name={
                      data?.workerComp?.dateOfInjury
                        ? formatServerDate(data?.workerComp?.dateOfInjury)
                        : "NA"
                    }
                  />
                </div>

                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "12px",
                  }}
                />
              </>
              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                Primary Insurance
              </p>

              <>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineSecurity color={getRandomColor()} size={20} />
                    }
                    label="Primary Insurance"
                    name={data?.primaryInsurance?.description || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <PiContactlessPayment
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"Master Payer"}
                    name={data?.primaryInsurance?.masterPayerId || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <PiContactlessPayment
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label="Electronic Payer"
                    name={data?.primaryInsurance?.electronicPayerId || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<SiCodesignal color={getRandomColor()} size={20} />}
                    label={"Category"}
                    name={data?.primaryInsurance?.category || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <TbTimelineEvent color={getRandomColor()} size={20} />
                    }
                    label="Insurance Type"
                    name={data?.primaryInsurance?.insuranceType || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<LuMapPin color={getRandomColor()} size={20} />}
                    label="Address"
                    name={data?.primaryInsurance?.addressLine1 || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineRealEstateAgent
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"City"}
                    name={data?.primaryInsurance?.city || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineRealEstateAgent
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"Zip"}
                    name={data?.primaryInsurance?.zip || "NA"}
                  />
                </div>

                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "12px",
                  }}
                />
              </>
              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                Primary Subscriber's Data
              </p>

              <>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<TbUserCircle color={getRandomColor()} size={20} />}
                    label="Subscriber Name"
                    name={
                      data?.primarySubscriberData?.subscriberFirstName ||
                      data?.primarySubscriberData?.subscriberLastName
                        ? `${data?.primarySubscriberData?.subscriberFirstName ?? ""} ${data?.primarySubscriberData?.subscriberLastName ?? ""}`.trim()
                        : "NA"
                    }
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<MdGroups color={getRandomColor()} size={20} />}
                    label={"Group Number"}
                    name={
                      data?.primarySubscriberData?.subscriberGroupNumber || "NA"
                    }
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineSpeakerGroup
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label="Member Number"
                    name={
                      data?.primarySubscriberData?.subscriberMemberNumber ||
                      "NA"
                    }
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <TbCalendarShare color={getRandomColor()} size={20} />
                    }
                    label={"Date Of Birth"}
                    name={
                      data?.primarySubscriberData?.subscriberDob
                        ? formatServerDate(
                            data?.primarySubscriberData?.subscriberDob
                          )
                        : "NA"
                    }
                  />
                </div>
                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "12px",
                  }}
                />
              </>
              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                Secondary Insurance
              </p>

              <>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineSecurity color={getRandomColor()} size={20} />
                    }
                    label="Secondary Insurance"
                    name={data?.secondaryInsurance?.description || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <PiContactlessPayment
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"Master Payer"}
                    name={data?.secondaryInsurance?.masterPayerId || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <PiContactlessPayment
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label="Electronic Payer"
                    name={data?.secondaryInsurance?.electronicPayerId || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<SiCodesignal color={getRandomColor()} size={20} />}
                    label={"Category"}
                    name={data?.secondaryInsurance?.category || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <TbTimelineEvent color={getRandomColor()} size={20} />
                    }
                    label="Insurance Type"
                    name={data?.secondaryInsurance?.insuranceType || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineRealEstateAgent
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label="Address"
                    name={data?.secondaryInsurance?.addressLine1 || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineRealEstateAgent
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"City"}
                    name={data?.secondaryInsurance?.city || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineRealEstateAgent
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"Zip"}
                    name={data?.secondaryInsurance?.zip || "NA"}
                  />
                </div>

                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "12px",
                  }}
                />
              </>

              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                Secondary Subscriber's Data
              </p>

              <>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<TbUserCircle color={getRandomColor()} size={20} />}
                    label="Subscriber Name"
                    name={
                      data?.secondarySubscriberData?.subscriberFirstName ||
                      data?.secondarySubscriberData?.subscriberLastName
                        ? `${data?.secondarySubscriberData?.subscriberFirstName ?? ""} ${data?.secondarySubscriberData?.subscriberLastName ?? ""}`.trim()
                        : "NA"
                    }
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<MdGroups color={getRandomColor()} size={20} />}
                    label={"Group Number"}
                    name={
                      data?.secondarySubscriberData?.subscriberGroupNumber ||
                      "NA"
                    }
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineSpeakerGroup
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label="Member Number"
                    name={
                      data?.secondarySubscriberData?.subscriberMemberNumber ||
                      "NA"
                    }
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <TbCalendarShare color={getRandomColor()} size={20} />
                    }
                    label={"Date Of Birth"}
                    name={
                      data?.secondarySubscriberData?.subscriberDob
                        ? formatServerDate(
                            data?.secondarySubscriberData?.subscriberDob
                          )
                        : "NA"
                    }
                  />
                </div>
                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "12px",
                  }}
                />
              </>

              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                Tertiary Insurance
              </p>

              <>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineSecurity color={getRandomColor()} size={20} />
                    }
                    label="Tertiary Insurance"
                    name={data?.tertiaryInsurance?.description || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <PiContactlessPayment
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"Master Payer"}
                    name={data?.tertiaryInsurance?.masterPayerId || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <PiContactlessPayment
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label="Electronic Payer"
                    name={data?.tertiaryInsurance?.electronicPayerId || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<SiCodesignal color={getRandomColor()} size={20} />}
                    label={"Category"}
                    name={data?.tertiaryInsurance?.category || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <TbTimelineEvent color={getRandomColor()} size={20} />
                    }
                    label="Insurance Type"
                    name={data?.tertiaryInsurance?.insuranceType || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineRealEstateAgent
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label="Address"
                    name={data?.tertiaryInsurance?.addressLine1 || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineRealEstateAgent
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"City"}
                    name={data?.tertiaryInsurance?.city || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineRealEstateAgent
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"Zip"}
                    name={data?.tertiaryInsurance?.zip || "NA"}
                  />
                </div>

                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "12px",
                  }}
                />
              </>

              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                Tertiary Subscriber's Data
              </p>

              <>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<TbUserCircle color={getRandomColor()} size={20} />}
                    label="Subscriber Name"
                    name={
                      data?.tertiarySubscriberData?.subscriberFirstName ||
                      data?.tertiarySubscriberData?.subscriberLastName
                        ? `${data?.tertiarySubscriberData?.subscriberFirstName ?? ""} ${data?.tertiarySubscriberData?.subscriberLastName ?? ""}`.trim()
                        : "NA"
                    }
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<MdGroups color={getRandomColor()} size={20} />}
                    label={"Group Number"}
                    name={
                      data?.tertiarySubscriberData?.subscriberGroupNumber ||
                      "NA"
                    }
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineSpeakerGroup
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label="Member Number"
                    name={
                      data?.tertiarySubscriberData?.subscriberMemberNumber ||
                      "NA"
                    }
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <TbCalendarShare color={getRandomColor()} size={20} />
                    }
                    label={"Date Of Birth"}
                    name={
                      data?.tertiarySubscriberData?.subscriberDob
                        ? formatServerDate(
                            data?.tertiarySubscriberData?.subscriberDob
                          )
                        : "NA"
                    }
                  />
                </div>
                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "12px",
                  }}
                />
              </>

              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "20px",
                }}
              >
                <div className="col-1-1 info-row-column">
                  <div className="flex center-left" style={{ gap: "10px" }}>
                    <div>
                      <MdOutlineSpeakerNotes
                        color={getRandomColor()}
                        size={20}
                      />
                    </div>
                    <div
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <p
                        className="f-w-300"
                        style={{
                          color: "#aaaaaa",
                          fontSize: "10px",
                          lineHeight: 1,
                          paddingBottom: "2px",
                        }}
                      >
                        Note
                      </p>
                      <p
                        style={{
                          whiteSpace: "normal",
                          overflowWrap: "break-word",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                        }}
                      >
                        {data?.notes || "NA"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  label={"Created At"}
                  name={
                    data?.createdAt ? formatServerDate(data?.createdAt) : "NA"
                  }
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  label={"Updated At "}
                  name={
                    data?.updatedAt ? formatServerDate(data?.updatedAt) : "NA"
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {permissions?.isUpdateAllowed && (
          <NavLink
            to={
              data?.id
                ? `/edit-patient?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                : "#"
            }
            state={data?.id ? { id: data.id } : null}
          >
            <ButtonLightTextIcon
              title="Edit Patient"
              icon={<FiEdit />}
              onClick={() => {
                setShow(false);
              }}
            />
          </NavLink>
        )}
      </div>
    </div>
  );
};
export default PatientDetails;
