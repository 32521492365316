import { useEffect, useRef } from "react";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AppLogout = ({ children }) => {
  const timerRef = useRef(null);

  useEffect(() => {
    const resetTimer = () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };

    const handleUserActivity = () => {
      resetTimer();
      startTimer();
    };

    const startTimer = () => {
      timerRef.current = setTimeout(() => {
        logoutUser();
      }, 3600000); // 1 hour in milliseconds
    };

    const logoutUser = () => {
        localStorage.clear();
        window.location.href = "/login";
    };
 
    events.forEach((event) => {
      window.addEventListener(event, handleUserActivity);
    });
 
    startTimer();
 
    return () => {
      resetTimer();
      events.forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
      });
    };
  }, []);

  return children;
};

export default AppLogout;
