import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import {
	API_URL,
	CLEARANCE_OPTIONS,
	SUFFIX_OPTIONS,
} from "../../utilities/constants";
import PaginatedDropdown from "../../components/ui/PaginatedDropdown";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import PhoneNumberInput from "../../components/ui/PhoneNumberInput";
import Label from "../../components/ui/Label";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertToMMDDYYYY, handleApiCall } from "../../utilities/helpers";
import { mapOptions } from "../../components/ui/PaginatedDropdown";
import ZipCodeInput from "../../components/ui/ZipCodeInput";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
const AddForm = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const { addToastMessage } = useUI();
	const [isZipDataFilled, setIsZipDataFilled] = useState(false);
	const initialValues = {
		degrees: [],
		firstName: "",
		lastName: "",
		middleName: "",
		suffix: "",
		designation: "",
		npi: "",
		priority: "",
		additionalContact: "",
		address: "",
		city: "",
		state: "",
		zip: "",
		email: "",
		phone: "",
		fax: "",
		primaryTaxonomy: "",
		secondaryTaxonomy: "",
		primarySpecialty: "",
		subSpecialty: "",
		billingNotes: "",
		license: "",
		financialClearance: "",
		facilityId: "",
	};

	const validationSchema = Yup.object({
		firstName: Yup.string()
			.min(2, "First Name must be at least 2 characters long")
			.required("First name is required"),
		lastName: Yup.string()
			.min(2, "Last Name must be at least 2 characters long")
			.required("Last name is required"),
		npi: Yup.string().required("Npi is required"),
		suffix: Yup.string().required("Credential is required"),
		// email: Yup.string().email('Email must be a valid email').required('Email is required'),
		phone: Yup.string()
			.matches(/^\d+$/, "Phone Number must contain only numbers")
			.max(15, "Phone must be at most 15 digits")
			.notRequired(),
	});

	const createSurgeon = async (values) => {
		setLoading(true);
		setStatus("");
		try {
			const payload = {
				degrees: values?.degrees.length
					? values?.degrees.map((detail) => ({
							dateAcquired: detail.dateAcquired
								? convertToMMDDYYYY(detail.dateAcquired)
								: "",
							dateExpired: detail.dateExpired
								? convertToMMDDYYYY(detail.dateExpired)
								: null,
							degree: detail.degree || "",
							state: detail.state.value,
							status: detail.status,
							attachment: detail.attachment,
						}))
					: null,
				firstName: values?.firstName || "",
				lastName: values?.lastName || "",
				middleName: values?.middleName || "",
				suffix: values?.suffix || "",
				designation: values?.designation || "",
				npi: values?.npi || "",
				priority: values?.priority || "",
				additionalContact: values?.additionalContact || "",
				address: values?.address || "",
				city: values?.city?.label || "",
				state: values?.state?.value || "",
				zip: values?.zip || "",
				email: values?.email || "",
				phone: values?.phone || "",
				fax: values?.fax || "",
				primaryTaxonomy: values?.primaryTaxonomy || "",
				secondaryTaxonomy: values?.secondaryTaxonomy || "",
				primarySpecialty: values?.primarySpecialty?.value || "",
				subSpecialty: values?.subSpecialty?.value || "",
				billingNotes: values?.billingNotes || "",
				license: values?.license || "",
				financialClearance: values?.financialClearance || "",
				facilityId: values?.facilityId?.value || "",
			};

			const response = await handleApiCall(
				API_URL + `/api/1.0.0/surgeon`,
				{
					method: "POST",
					body: payload,
				}
			);
			if (response.responseStatus == true) {
				setStatus(
					"You have successfully created a surgeon, thank you."
				);
				addToastMessage({
					status: true,
					message: `You have successfully created a surgeon, thank you.`,
				});
				navigate("/surgeons");
				return true;
			} else {
				setStatus(
					`${response.responseDescription || "Something went wrong, please try again later."}`
				);
				addToastMessage({
					status: false,
					message: `${response.responseDescription || "Something went wrong, please try again later."}`,
				});
				return false;
			}
		} catch (e) {
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
			console.error("Error:", e);
			return false;
		}
	};

	const labelStateFunc = (o) => `${o.name}`;
	const labelCityFunc = (o) => `${o.name}`;
	const labelSpecilityFunc = (o) => `${o.name}`;
	const labelFacilityFunc = (o) => `${o.facilityName}`;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				const api = await createSurgeon(values);
				if (api) {
					resetForm();
				} else {
					setStatus("Something went wrong, please try again later.");
				}
				setLoading(false);
			}}>
			{({
				values,
				setFieldValue,
				errors,
				touched,
				handleSubmit,
				validateForm,
				setFieldTouched,
			}) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<br />
								<tr>
									<td width='25%'>
										<Label
											title='First Name'
											required={true}
										/>
										<Field
											type='text'
											placeholder='Enter First Name'
											name='firstName'
											className={`col-1-1 primary ${
												errors.firstName &&
												touched.firstName
													? "error"
													: ""
											}`}
										/>
										<ErrorMessage
											name='firstName'
											component='p'
											className='error-messages'
										/>
									</td>
									<td width='25%'>
										<Label title='Middle Name' />
										<Field
											type='text'
											placeholder='Enter middle Name'
											name='middleName'
											className={`col-1-1 primary ${
												errors.middleName &&
												touched.middleName
													? "error"
													: ""
											}`}
										/>
									</td>
									<td width='25%'>
										<Label
											title='Last Name'
											required={true}
										/>
										<Field
											type='text'
											placeholder='Enter Last Name'
											name='lastName'
											className={`col-1-1 primary ${
												errors.lastName &&
												touched.lastName
													? "error"
													: ""
											}`}
										/>
										<ErrorMessage
											name='lastName'
											component='p'
											className='error-messages'
										/>
									</td>
									<td width='25%'>
										<Label title='Email' />
										<Field
											type='email'
											placeholder='Enter Email'
											name='email'
											className={`col-1-1 primary ${
												errors.email && touched.email
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='25%'>
										<Label
											title='Credential'
											required={true}
										/>
										<Field
											as='select'
											name='suffix'
											className={`col-1-1 primary ${
												errors.suffix && touched.suffix
													? "error"
													: ""
											}`}>
											<option value=''>Select</option>
											{SUFFIX_OPTIONS.map((d, i) => (
												<option value={d.value} key={i}>
													{d.label}
												</option>
											))}
										</Field>
									</td>
									<td width='25%'>
										<Label title='Designation' />
										<Field
											type='text'
											placeholder='Enter Designation'
											name='designation'
											className={`col-1-1 primary ${
												errors.designation &&
												touched.designation
													? "error"
													: ""
											}`}
										/>
									</td>
									<td width='25%'>
										<Label title='Phone' />
										<PhoneNumberInput
											name='phone'
											placeholder='Eg: 888-888-8888'
											error={
												errors.phone && touched.phone
											}
											value={values.phone}
											setTouched={() =>
												setFieldTouched("phone", "")
											}
											callback={(v) =>
												setFieldValue("phone", v)
											}
										/>
										<ErrorMessage
											name='phone'
											component='p'
											className='error-messages'
										/>
									</td>
									<td width='25%'>
										<Label title='Fax' />
										<Field
											type='text'
											placeholder='Enter Fax'
											name='fax'
											className={`col-1-1 primary ${
												errors.fax && touched.fax
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='25%'>
										<Label title='Zip' />
										<ZipCodeInput
											name='zip'
											clearFields={() => {
												setFieldValue("state", null);
												setFieldValue("city", null);
											}}
											onZipDetailsFetched={(data) => {
												if (data.country.code !== "US")
													return;
												setIsZipDataFilled(true);
												setFieldValue("state", {
													value: data.state.code,
													label: data.state.name,
													obj: data.state,
												});
												setFieldValue("city", {
													value: data.city.code,
													label: data.city.name,
													obj: data.city,
												});
											}}
										/>
									</td>
									<td width='25%'>
										<Label title='Address' />
										<Field
											type='text'
											placeholder='Enter Address'
											name='address'
											className={`col-1-1 primary ${
												errors.address &&
												touched.address
													? "error"
													: ""
											}`}
										/>
									</td>
									<td width='25%'>
										<Label title='State' />
										<PaginatedDropdown
											datakey='records'
											url={`${API_URL}/api/1.0.0/reference/states/US?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"code",
													labelStateFunc
												)
											}
											setTouched={() =>
												setFieldTouched("state", true)
											}
											error={
												errors.state && touched.state
											}
											placeHolder='Search'
											selectedValue={values.state}
											onChange={(v) => {
												if (isZipDataFilled)
													setFieldValue("zip", "");
												setIsZipDataFilled(false);
												setFieldValue("state", v);
												setFieldValue("city", null);
											}}
										/>
									</td>
									<td width='25%'>
										<Label title='City' />
										<PaginatedDropdown
											datakey='records'
											url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.state?.value}?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"name",
													labelCityFunc
												)
											}
											error={errors.city && touched.city}
											setTouched={() =>
												setFieldTouched("city", true)
											}
											placeHolder='Search'
											selectedValue={values.city}
											onChange={(v) => {
												if (isZipDataFilled)
													setFieldValue("zip", "");
												setIsZipDataFilled(false);
												setFieldValue("city", v);
											}}
										/>
									</td>
								</tr>
								<tr></tr>
								<tr>
									<td width='25%'>
										<Label title='Additional Contact' />
										<Field
											type='text'
											placeholder='Enter Additional Contact'
											name='additionalContact'
											className={`col-1-1 primary ${
												errors.additionalContact &&
												touched.additionalContact
													? "error"
													: ""
											}`}
										/>
									</td>
									<td width='25%'>
										<Label title='Primary Taxonomy' />
										<Field
											type='text'
											placeholder='Enter Primary Taxonomy'
											name='primaryTaxonomy'
											className={`col-1-1 primary ${
												errors.primaryTaxonomy &&
												touched.primaryTaxonomy
													? "error"
													: ""
											}`}
										/>
									</td>
									<td width='25%'>
										<Label title='Secondary Taxonomy' />
										<Field
											type='text'
											placeholder='Enter Secondary Taxonomy'
											name='secondaryTaxonomy'
											className={`col-1-1 primary ${
												errors.secondaryTaxonomy &&
												touched.secondaryTaxonomy
													? "error"
													: ""
											}`}
										/>
									</td>
									<td width='25%'>
										<Label title='NPI #' required={true} />
										<Field
											type='text'
											placeholder='Enter NPI #'
											name='npi'
											className={`col-1-1 primary ${
												errors.npi && touched.npi
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='25%'>
										<Label title='License' />
										<Field
											type='text'
											placeholder='Enter License'
											name='license'
											className={`col-1-1 primary ${
												errors.license &&
												touched.license
													? "error"
													: ""
											}`}
										/>
									</td>
									<td width='25%'>
										<Label title='Finacial Clearance' />
										<Field
											as='select'
											name='financialClearance'
											className={`col-1-1 primary ${
												errors.financialClearance &&
												touched.financialClearance
													? "error"
													: ""
											}`}>
											<option value=''>Select</option>
											{CLEARANCE_OPTIONS.map((d, i) => (
												<option value={d.value} key={i}>
													{d.label}
												</option>
											))}
										</Field>
									</td>
									<td width='25%'>
										<Label title='Primary Specialty' />
										<PaginatedDropdown
											datakey='records'
											url={`${API_URL}/api/1.0.0/reference/specialities?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelSpecilityFunc
												)
											}
											error={
												errors.primarySpecialty &&
												touched.primarySpecialty
											}
											setTouched={() =>
												setFieldTouched(
													"primarySpecialty",
													true
												)
											}
											placeHolder='Search'
											selectedValue={
												values.primarySpecialty
											}
											onChange={(v) => {
												setFieldValue(
													"primarySpecialty",
													v
												);
											}}
										/>
									</td>
									<td width='25%'>
										<Label title='Sub Specialty' />
										<PaginatedDropdown
											datakey='records'
											url={`${API_URL}/api/1.0.0/reference/specialities?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelSpecilityFunc
												)
											}
											error={
												errors.subSpecialty &&
												touched.subSpecialty
											}
											setTouched={() =>
												setFieldTouched(
													"subSpecialty",
													true
												)
											}
											placeHolder='Search'
											selectedValue={values.subSpecialty}
											onChange={(v) => {
												setFieldValue(
													"subSpecialty",
													v
												);
												// setFieldValue("specialityId", null)
											}}
										/>
									</td>
								</tr>
								<tr>
									<td width='25%'>
										<Label title='Special Billing Notes' />
										<Field
											type='text'
											placeholder='Enter Notes'
											name='billingNotes'
											className={`col-1-1 primary ${
												errors.billingNotes &&
												touched.billingNotes
													? "error"
													: ""
											}`}
										/>
									</td>
									<td width='25%'>
										<Label title='Facility Preferance' />
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/facility?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelFacilityFunc
												)
											}
											error={
												errors.facilityId &&
												touched.facilityId
											}
											setTouched={() =>
												setFieldTouched(
													"facilityId",
													true
												)
											}
											placeHolder='Search Facility'
											selectedValue={values.facilityId}
											onChange={(v) =>
												setFieldValue("facilityId", v)
											}
										/>
									</td>
								</tr>
								<br />
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonLightTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => navigate("/surgeons")}
							icon={
								<TbArrowBackUp />
							}
						/>
						<ButtonTextIcon
							type='button'
							disabled={loading}
							title='Create Now'
							onClick={async () => {
								const errors = await validateForm();
								if (Object.keys(errors).length > 0) {
									const firstErrorMessage =
										Object.values(errors)[0];
									addToastMessage({
										status: false,
										message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
									});
								}
								handleSubmit();
							}}
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<FiArrowRightCircle />
								)
							}
						/>
					</div>
					{status && (
						<>
							<br />
							<p className='color-primary f-w-700'>{status}</p>
						</>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default AddForm;
