import { TbDownload, TbReload } from "react-icons/tb";
import { PiArrowSquareOutBold } from "react-icons/pi";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

const ButtonTertiary = ({ actionType, title, className, ...rest }) => {
  const getRespectiveType = () => {
    switch (actionType) {
      case "download":
        return "tertiary-download";
      case "reload":
        return "tertiary-reload";
      case "view":
        return "tertiary-view";
      case "edit":
        return "tertiary-edit";
      case "delete":
        return "tertiary-delete";
      default:
        return "";
    }
  };

  const getIcon = () => {
    switch (actionType) {
      case "download":
        return <TbDownload />;
      case "reload":
        return <TbReload />;
      case "view":
        return <PiArrowSquareOutBold />;
      case "edit":
        return <FiEdit />;
      case "delete":
        return <AiOutlineDelete />;
      default:
        return <></>;
    }
  };

  return (
    <button
      className={`tertiary ${getRespectiveType()} ${className || ""}`}
      {...rest}
    >
      <span>
        {getIcon()}
        <span>{title}</span>
      </span>
    </button>
  );
};

export default ButtonTertiary;
