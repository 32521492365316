import { useState } from "react";
import ButtonLightTextIcon from "./ButtonLightTextIcon";
import ButtonTextIcon from "./ButtonTextIcon";
import LoaderIcon from "./LoaderIcon";
import { TbArrowBackUp, TbLogout } from "react-icons/tb";
import { RiCloseCircleFill } from "react-icons/ri";

const ConfirmationModal = ({ message, onConfirm, setShow }) => {
	const [loading, setLoading] = useState(false);

	return (
		<section
			style={{
				position: "fixed",
				inset: 0,
				zIndex: 9999,
				backgroundColor: "rgba(255, 255, 255, 0.86)",
			}}>
			<section
				className='col-1-1 flex center-center'
				style={{
					height: "100vh",
					width: "100vw",
				}}>
				<div
					style={{
						width: "486px",
						padding: "24px",
						height: "auto",
						maxHeight: "80vh",
						border: "solid 1px #f0f0f0",
						background: "white",
						borderRadius: "8px",
						margin: "0 auto",
						boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
					}}>
					<div
						className='col-1-1 flex center-left'
						style={{
							borderBottom: "solid 1px #f0f0f0",
							paddingBottom: "12px",
							marginBottom: "16px",
						}}>
						<h6 className='color-primary f-w-700'>Confirmation</h6>
						<div
							className='mar-l-a cursor-pointer'
							title='Close'
							onClick={() => setShow(false)}>
							<RiCloseCircleFill
								className='color-primary width-auto dis-b'
								style={{
									height: "28px",
								}}
							/>
						</div>
					</div>
					<div>
						<section>
							<p dangerouslySetInnerHTML={{ __html: message }} />
						</section>
					</div>
					<div
						className='col-1-1 flex '
						style={{
							marginTop: "16px",
							borderTop: "solid 1px #f0f0f0",
							paddingTop: "16px",
							justifyContent: "space-between",
						}}>
						<ButtonLightTextIcon
							title='Return Back'
							onClick={() => setShow(false)}
							icon={<TbArrowBackUp />}
						/>

						<ButtonTextIcon
							title='Confirm'
							onClick={async () => {
								setLoading(true);
								await onConfirm();
								setShow();
								setLoading(false);
							}}
							disabled={loading}
							icon={loading ? <LoaderIcon /> : <TbLogout />}
						/>
					</div>
				</div>
			</section>
		</section>
	);
};

export default ConfirmationModal;
