import React, { useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PaginatedDropdown from "../../components/ui/PaginatedDropdown";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import DragFileUpload from "../../components/ui/DragFileUpload";
import { API_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import { useNavigate } from "react-router-dom";
import { handleApiCall } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { FcInfo } from "react-icons/fc";

const validationSchema = Yup.object({
  name: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  articlecategory: Yup.mixed()
    .nullable()
    .test(
      "articlecategory-required",
      "Article Category is Required",
      function (value) {
        return value !== null && value.value;
      }
    ),
  articlesubcategory: Yup.mixed()
    .nullable()
    .test(
      "articlesubcategory-required",
      "Article Sub Category is required",
      function (value) {
        return value !== null && value.value;
      }
    ),
});

function AddForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const { addToastMessage } = useUI();
  const [uploaded, setUploaded] = useState(false);
  const fileUploadRef = useRef();
  const initialValues = {
    name: "",
    articlecategory: null,
    articlesubcategory: null,
    description: "",
    file: null,
  };
  const handleReset = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFile();
    }
  };

  const addKnowledgeBase = async (values) => {
    setLoading(true);
    setStatus("");

    try {
      // setLoading(false);
      const payload = {
        name: values.name,
        description: values.description,
        knowledgeBaseSubCategoryId: Number(values?.articlesubcategory?.obj?.id),
      };
      if (values.file) {
        payload["fileLink"] = values.file;
      }
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/knowledge-base`,
        {
          method: "POST",
          body: payload,
        }
      );

      if (response.responseStatus === true) {
        setStatus("You have successfully created a knowledge base, thank you.");

        addToastMessage({
          status: true,
          message: `You have successfully created an knowledge base, thank you.`,
        });
        navigate("/knowledge-base");

        return true;
      } else {
        setStatus(
          `${response.responseDescription || "Something went wrong, please try again later."}`
        );
        addToastMessage({
          status: false,
          message: `${response.responseDescription || "Something went wrong, please try again later."}`,
        });
        return false;
      }
    } catch (e) {
      setStatus("Something went wrong, please try again later.");
      addToastMessage({
        status: false,
        message: `Something went wrong, please try again later.`,
      });
      console.error("Error:", e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await addKnowledgeBase(values);
        if (api) {
          handleReset();
          resetForm();
        }
      }}
    >
      {({
        handleSubmit,
        validateForm,
        setFieldValue,
        errors,
        touched,
        values,
        setFieldTouched,
      }) => (
        <Form>
          <div className="col-1-1 ">
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <Label title="Title" required />
                    <Field
                      name="name"
                      type="text"
                      placeholder="Eg: How to Create.."
                      className={`primary ${errors.name && touched.name ? "error" : ""}`}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="Category" required />
                    <PaginatedDropdown
                      url={`${API_URL}/api/1.0.0/knowledge-base-category?`}
                      searchkey="name"
                      mapOptions={(records) =>
                        records?.map((record) => ({
                          value: record["id"],
                          label: record.name,
                          obj: record,
                        }))
                      }
                      setTouched={() => {
                        setFieldTouched("articlecategory", true);
                      }}
                      error={errors.articlecategory && touched.articlecategory}
                      selectedValue={values.articlecategory}
                      placeHolder="Search"
                      datakey="knowledgeBaseCategoryList"
                      onChange={(v) => {
                        setFieldValue("articlecategory", v);
                        setFieldValue("articlesubcategory", null);
                      }}
                    />
                  </td>
                  <td>
                    <Label title="Sub Category" required />
                    <PaginatedDropdown
                      searchkey="name"
                      datakey="knowledgeBaseSubCategoryList"
                      url={`${API_URL}/api/1.0.0/knowledge-base-subcategory?statusId=1&categoryId=${values?.articlecategory?.value}&`}
                      name="articlesubcategory"
                      mapOptions={(records) =>
                        records?.map((record) => ({
                          value: record["id"],
                          label: record.name,
                          obj: record,
                        }))
                      }
                      placeHolder="Search"
                      setTouched={() => {
                        setFieldTouched("articlesubcategory", true);
                      }}
                      error={
                        errors.articlesubcategory && touched.articlesubcategory
                      }
                      onChange={(v) => {
                        setFieldValue("articlesubcategory", v);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="flex center-left" style={{ gap: "8px" }}>
                      <div
                        className="opl-tooltip"
                        data-tooltip="Please only select PDF files for upload."
                      >
                        <Label title="Upload File" />
                      </div>
                      <FcInfo />
                    </div>
                    <DragFileUpload
                      ref={fileUploadRef}
                      fileType={"KNOWLEDGE_BASE_DOC"}
                      error={errors.file && touched.file}
                      afterUpload={(url) => {
                        setFieldValue("file", url);
                      }}
                      setUploading={setUploaded}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Label title="Description" required />
                    <Field
                      name="description"
                      as="textarea"
                      placeholder="Enter Description"
                      className={`primary ${errors.description && touched.description ? "error" : ""}`}
                      style={{
                        width: "100%",
                        height: "100px",
                        resize: "none",
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => navigate("/knowledge-base")}
              icon={<TbArrowBackUp />}
            />
            <ButtonTextIcon
              type="submit"
              disabled={loading || uploaded}
              title="Create Now"
              onClick={async () => {
                const errors = await validateForm();
                if (Object.keys(errors).length > 0) {
                  const firstErrorMessage = Object.values(errors)[0];
                  addToastMessage({
                    status: false,
                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                  });
                }
                handleSubmit();
              }}
              className="mar-l-a"
              icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
            />
          </div>
          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default AddForm;
