import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import { useUI } from "../context/ui";
import {
	getRandomColor,
	handleApiCall,
	jsonToQueryParams,
} from "../utilities/helpers";
import useQueryParams from "../hooks/useQueryParams";
import PageToolbar from "../components/common/PageToolbar";
import SpecialtyFilter from "./specialty2/SpecialtyFilter";
import {
	TbHeart,
	TbHeartbeat,
	TbHeartCheck,
	TbHeartHandshake,
	TbHeartQuestion,
	TbHearts,
} from "react-icons/tb";
import CustomModal from "../components/common/CustomModal";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import AddSpecialty from "./specialty2/AddSpecialty";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiPlusCircle } from "react-icons/fi";

const getIcon = () => {
	const array = [
		<TbHeart />,
		<TbHeartQuestion />,
		<TbHeartHandshake />,
		<TbHeartCheck />,
		<TbHeartbeat />,
		<TbHearts />,
	];
	const randomIndex = Math.floor(Math.random() * array.length);
	return array[randomIndex];
};

const SpecialtyCard = ({ count, data, handleDelete, permissions, ...rest }) => {
	return (
		<div {...rest} className='single transition pos-rel' title={data.name}>
			<div className='flex center-left'>
				<p className='f-w-700'>{`#${count}`}</p>
			</div>
			<div
				className='b-r-100 mar-0-a icon flex center-center'
				style={{
					background: getRandomColor(),
				}}>
				{getIcon()}
			</div>
			<hr />
			<p className='f-w-700 l-h-1 name'>{data.name.toUpperCase()}</p>
			{permissions?.isDeleteAllowed && (
				<CustomModal
					selector={
						<div
							className='btn-del flex center-left b-r-100 cursor-pointer color-red bg-color-light-red hover-background-red hover-color-white transition'
							style={{
								padding: "8px",
							}}>
							<RiDeleteBin6Line
								className='width-auto dis-b'
								style={{
									height: "16px",
								}}
							/>
						</div>
					}
					content={
						<ConfirmationModal
							onConfirm={async () => {
								await handleDelete(data.id);
								return true;
							}}
							message={`Are you sure you want to delete "<strong>${data.name.toUpperCase()}</strong>" Specialty?`}
						/>
					}
				/>
			)}
		</div>
	);
};

const SpecialtyPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const { addToastMessage, permissions } = useUI();
	const { updateQueryParam, queryParams } = useQueryParams();

	const pageSize = queryParams.get("pageSize") || 12;
	const pageNo = queryParams.get("pageNo") || 0;

	const [controller, setController] = useState(null);

	const resetFilters = () => {
		updateQueryParam("search", "");
		updateQueryParam("pageNo", 0);
	};

	const handleDelete = async (id) => {
		try {
			if (!id) {
				addToastMessage({
					status: false,
					message: `Something went wrong while deleting specialty. Please try again.`,
				});
				return false;
			}
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/specialty/${id}`,
				{
					method: "DELETE",
				}
			);
			if (response.responseStatus === true) {
				getSpecialtyApi();
				addToastMessage({
					status: true,
					message: `Specialty deleted successfully.`,
				});
				return true;
			} else {
				addToastMessage({
					status: false,
					message: `Something went wrong while deleting specialty. Please try again.`,
				});
				return false;
			}
		} catch (error) {
			addToastMessage({
				status: false,
				message: `Something went wrong while deleting specialty. Please try again.`,
			});
			return false;
		}
	};

	const getSpecialtyApi = async () => {
		controller?.abort();
		const newController = new AbortController();
		setController(newController);
		const { signal } = newController;
		setLoading(true);
		try {
			const query = jsonToQueryParams({
				search: queryParams.get("search") || "",
				pageNo: queryParams.get("pageNo") || 0,
				pageSize: queryParams.get("pageSize") || 12,
			});
			const response = await handleApiCall(
				API_URL +
					`/api/1.0.0/reference/specialities?sortOrder=ASC&${query}`,
				{
					method: "GET",
				},
				null,
				signal
			);

			if (response.responseStatus === true) {
				setData(response.data);
			}
			if (response.responseCode !== 299) {
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			console.error("Error:", e);
		}
	};

	useEffect(() => {
		getSpecialtyApi();
	}, [location.search]);

	return (
		<section id='category'>
			<PageToolbar
				title='Specialties'
				button={
					permissions?.isCreateAllowed && (
						<CustomModal
							selector={
								<ButtonTextIcon
									title='Add New'
									className='mar-l-a'
									icon={<FiPlusCircle />}
								/>
							}
							content={
								<AddSpecialty callback={getSpecialtyApi} />
							}
						/>
					)
				}
			/>
			<SpecialtyFilter resetFilters={resetFilters} />
			<div>
				{data && !loading && (
					<div
						className='flex center-left'
						style={{
							padding: "10px 15px 10px 15px",
							gap: "12px",
						}}>
						<p className='f-w-300' style={{ fontSize: "11px" }}>
							Page no:
						</p>
						<Pagination
							totalRecords={data.totalRecords}
							pageNo={data.pageNo}
							pageSize={data.pageSize}
						/>
					</div>
				)}
			</div>
			{loading ? (
				<section
					style={{
						height: "100vh",
						paddingBottom: "20vh",
						backgroundColor: "#fafaff",
					}}
					className='flex center-center'>
					<LoaderIcon style={{ height: "32px", width: "32px" }} />
				</section>
			) : data?.records.length ? (
				<div className=''>
					<div className='all-specialties'>
						{data?.records?.map((data, index) => (
							<>
								<SpecialtyCard
									count={pageNo * pageSize + index + 1}
									key={index}
									data={data}
									handleDelete={handleDelete}
									permissions={permissions}
								/>
							</>
						))}
					</div>
				</div>
			) : (
				<section
					style={{
						height: "80vh",
						paddingBottom: "20vh",
						backgroundColor: "#fff",
					}}
					className='flex center-center'>
					<p>No records found.</p>
				</section>
			)}
			<div>
				{data && !loading && (
					<div
						className='flex center-center'
						style={{
							padding: "10px 15px 10px 15px",
							gap: "12px",
						}}>
						<p className='f-w-300' style={{ fontSize: "11px" }}>
							Page no:
						</p>
						<Pagination
							totalRecords={data.totalRecords}
							pageNo={data.pageNo}
							pageSize={data.pageSize}
						/>
					</div>
				)}
			</div>
		</section>
	);
};

export default SpecialtyPage;
