import { useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import UpdateForm from "./insurance/UpdateForm";
import { API_URL } from "../utilities/constants";
import { useEffect, useState } from "react";
import LoaderIcon from "../components/ui/LoaderIcon";
import { handleApiCall } from "../utilities/helpers";
import PageToolbar from "../components/common/PageToolbar";
import useQueryParams from "../hooks/useQueryParams";
import { TbArrowBackUp } from "react-icons/tb";

const UpdateInsurancePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state?.id;
  const [insuranceData, setInsuranceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {queryParams,updateQueryParam } =useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";

  const fetchInsuranceData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/insurance/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        setInsuranceData(response.data);
      } else {
        setInsuranceData(null);
      }
      setLoading(false);
    } catch (e) {
      setError("Failed to fetch group data.");
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    fetchInsuranceData(id);
  }, [id]);
  return (
    <section id="add-insurance">
      <PageToolbar
        title="Update Insurance"
        button={
          <ButtonTextIcon
            title="Cancel"
            className="light mar-l-a"
            onClick={() => {
              if (nextUrl) {
                navigate(nextUrl);
              } else {
                navigate("/insurance");
              }
            }}
            icon={
              <TbArrowBackUp />
            }
          />
        }
      />
      <div className="col-1-1">
        <div className="form-holder">
          <div className="inner">
            {!loading && insuranceData ? (
              <UpdateForm insuranceData={insuranceData} />
            ) : (
              <p
                style={{
                  height: "100vh",
                  paddingBottom: "20vh",
                  backgroundColor: "#fafaff",
                }}
                className="flex center-center"
              >
                <LoaderIcon style={{ height: "32px", width: "32px" }} />
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdateInsurancePage;
