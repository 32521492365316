import ResultsPerPage from "../../components/common/ResultsPerPage";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import PaginatedDropdown, {
  mapOptions,
} from "../../components/ui/PaginatedDropdown";
import { API_URL, BILL_STATUS_OPTTIONS } from "../../utilities/constants";
import DateRangeInput from "../../components/ui/DateRangeInput";
import useQueryParams from "../../hooks/useQueryParams";
import Label from "../../components/ui/Label";
import MonthYearInput from "../../components/ui/MonthYearInput";
import CustomSelect from "../../components/ui/CustomSelect";
import { GrPowerReset } from "react-icons/gr";

const TableFilter = ({ resetFilters }) => {
  const { updateQueryParam, queryParams } = useQueryParams();

  const DROPDOWNS = [
    // {
    // 	title: "Patients",
    // 	url: "/reference/patients",
    // 	param: "patient",
    // 	mapFunc: mapOptions,
    // 	labelFunc: labelPatientsFunc,
    // 	placeholder: "Search patient",
    // },
    // {
    // 	title: "Practices",
    // 	url: "/reference/practice",
    // 	param: "practice",
    // 	mapFunc: mapOptions,
    // 	labelFunc: (o) => o.payToName,
    // 	placeholder: "Search practice",
    // },
    {
      title: "Facilities",
      url: "/reference/facility",
      param: "facility",
      mapFunc: mapOptions,
      labelFunc: (o) => o.facilityName,
      placeholder: "Search facility",
    },
  ];

  return (
    <div className="col-1-1 filters">
      {DROPDOWNS.map(
        ({ title, url, param, mapFunc, labelFunc, placeholder }) => (
          <div key={param}>
            <Label variant="filter" title={title} />
            <PaginatedDropdown
              url={`${API_URL}/api/1.0.0${url}?`}
              selectedValue={
                queryParams.get(param)
                  ? JSON.parse(queryParams.get(param))
                  : null
              }
              onChange={(v) =>
                updateQueryParam(param, v ? JSON.stringify(v) : null)
              }
              mapOptions={(records) => mapFunc(records, "id", labelFunc)}
              placeHolder={placeholder}
            />
          </div>
        )
      )}
      <div>
        <Label variant="filter" title="Bill Type" />
        <CustomSelect
          options={BILL_STATUS_OPTTIONS}
          placeHolder={"Select Bill Type"}
          selectedValue={
            BILL_STATUS_OPTTIONS.find(
              (o) => o.value === queryParams.get("billType")
            ) || null
          } 
          onChange={(selectedOption) => {
            const newValue = selectedOption ? selectedOption.value : "";
            updateQueryParam("billType", newValue);
          }}
        />
      </div>
      <div>
        <Label variant="filter" title="Date Filter" />
        <MonthYearInput
				initialValue={{
					month: queryParams.get("month") ? JSON.parse(queryParams.get("month")) : null, 
					year : queryParams.get("year") ? JSON.parse(queryParams.get("year")) : null
				}} 
				onApply={(v) => {   
					updateQueryParam("month",  v?.month ? JSON.stringify(v?.month) : null); 
					updateQueryParam("year", v?.year ? JSON.stringify(v?.year) : null); 
				}} 
				onCancel={() => { 
					updateQueryParam("month", null); 
					updateQueryParam("year", null); 
				}}  
			/>
      </div>
      <div>
          <Label variant="filter" title="Reset" />
          <ButtonLightTextIcon
            title="Reset"
            className="light"
            icon={
              <GrPowerReset />
            }
            onClick={resetFilters}
          />
        </div> 
        <div >
          <Label variant="filter" title="Per page" />
          <ResultsPerPage />
        </div>
    </div>
  );
};

export default TableFilter;
