import React, { useEffect, useState } from "react";
import PieChart from "../../components/charts/PieChart";
import { backgroundColor, chartOptions, emptyPieData } from "./ChartConfig";
import { API_URL, BILL_STATUS_OPTTIONS } from "../../utilities/constants";
import DateRangeInput from "../../components/ui/DateRangeInput";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { set } from "date-fns";
import { handleApiCall, jsonToQueryParams } from "../../utilities/helpers";

const InvoicePi = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [billStatusData, setBillStatusData] = useState([]);
  const [controller, setController] = useState(null);

  const fetchData = async () => {
    setBillStatusData([]);
    setLoading(true);
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;

    const query = jsonToQueryParams({
      startDate: startDate || null,
      endDate: endDate || null,
    });
    setLoading(true);

    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/analytics/bill-status-progress?${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        if (response.data.length === 0) {
          setBillStatusData([]);
        } else {
          const billStatusData = response.data
          .filter(item => item.billStatus !== null && item.billStatus !== undefined)
        .filter(item => item.billStatus === "not_set" || BILL_STATUS_OPTTIONS.some(option => option.value === item.billStatus)) // Keep "not_set"
        .map(item => {
            const matchedOption = BILL_STATUS_OPTTIONS.find(
              (option) => option.value === item.billStatus
          );
          console.log("new Changes 2");
            const statusLabel = matchedOption
              ? matchedOption.label.replace(/_/g, " ")
              : item.billStatus.replace(/_/g, " ");
            return {
              billStatus: statusLabel,
              count: item.count,
            };
          });

          BILL_STATUS_OPTTIONS.forEach((option) => {
            const optionLabel = option.label.replace(/_/g, " ");
            if (!billStatusData.some((item) => item.billStatus === optionLabel)) {
              billStatusData.push({
                billStatus: optionLabel,
                count: 0,
              });
            }
          });

          setBillStatusData(billStatusData);
        }
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const labels = billStatusData.map((item) => item.billStatus.toUpperCase());
  const data = billStatusData.map((item) => item.count);
  const pieData = {
    labels,
    datasets: [
      {
        label: "Bill Status",
        data,
        backgroundColor: backgroundColor.slice(0, billStatusData.length),
        borderColor: backgroundColor
          .slice(0, billStatusData.length)
          .map((color) => color.replace("0.7", "1")),
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="chart-card">
      <div
        className="flex center-center justify-between flex-row of-hid"
        style={{
          padding: "16px",
          gap: "16px",
        }}
      >
        <p className="f-w-700"> Bill Status</p>
        <div>
          <DateRangeInput
            initialRange={{ startDate, endDate }}
            onApply={(v) => {
              setStartDate(v?.startDate || null);
              setEndDate(v?.endDate || null);
            }}
            onCancel={() => {
              setStartDate(null);
              setEndDate(null);
            }}
          />
        </div>
      </div>
      <div style={{ height: "300px" }}>
        {loading ? (
          <div className="flex center-center" style={{ height: "100%" }}>
            <LoaderIcon style={{ width: "30px", height: "30px" }} />
          </div>
        ) : billStatusData.length > 0 ? (
          <PieChart data={pieData} options={chartOptions} />
        ) : (
          <PieChart data={emptyPieData} options={chartOptions} />
        )}
      </div>
    </div>
  );
};

export default InvoicePi;
