import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useEffect, useRef, useState, Fragment } from "react";
import { API_URL } from "../../utilities/constants";
import DragFileUpload from "../../components/ui/DragFileUpload";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import PaginatedDropdown, {
	mapOptions,
} from "../../components/ui/PaginatedDropdown";
import { useUI } from "../../context/ui";
import PhoneNumberInput from "../../components/ui/PhoneNumberInput";
import { formatInputPhoneNumber, handleApiCall } from "../../utilities/helpers";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Label from "../../components/ui/Label";
import FullScreenFileViewerModal from "../../components/ui/FullScreenFileViewerModal";
import CustomModal from "../../components/common/CustomModal";
import ZipCodeInput from "../../components/ui/ZipCodeInput";
import useQueryParams from "../../hooks/useQueryParams";
import { FiArrowRightCircle, FiPlusCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { RxCrossCircled } from "react-icons/rx";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { LuEye } from "react-icons/lu";
import { FcInfo } from "react-icons/fc";

const UpdateForm = ({ insuranceData }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [id, setId] = useState(null);
	const { addToastMessage } = useUI();
	const { queryParams, updateQueryParam } = useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";
	const [uploaded, setUploaded] = useState(false);
	const [isZipDataFilled, setIsZipDataFilled] = useState(false);

	const [initialValues, setInitialValues] = useState({
		insuranceContactDetails: [],
		insuranceDocuments: [],
		city: "",
		state: "",
		zip: "",
		addressLine2: "",
		addressLine1: "",
		masterPayerId: "",
		description: "",
		electronicPayerId: "",
		category: "",
		insuranceType: "",
	});

	useEffect(() => {
		setInitialValues({
			id: setId(insuranceData?.id),
			city: insuranceData?.city
				? {
						value: insuranceData?.city?.code,
						label: insuranceData?.city?.name,
					}
				: null,
			state: insuranceData?.state
				? {
						value: insuranceData?.state?.code,
						label: insuranceData?.state?.name,
					}
				: null,
			zip: insuranceData?.zip || null,
			addressLine2: insuranceData?.addressLine2 || null,
			addressLine1: insuranceData?.addressLine1 || null,
			masterPayerId: insuranceData?.masterPayerId || null,
			description: insuranceData?.description || null,
			electronicPayerId: insuranceData?.electronicPayerId || null,
			category: insuranceData?.category || null,
			insuranceType: insuranceData?.insuranceType || null,
			insuranceContactDetails: insuranceData?.insuranceContactDetails
				?.length
				? insuranceData.insuranceContactDetails.map((item) => ({
						contact_type: item.type,
						contact_info: item.information,
					}))
				: [],

			insuranceDocuments: insuranceData?.insuranceDocuments
				? insuranceData?.insuranceDocuments.map((item) => ({
						file: item.path,
						path: item.fileName,
						description: item.description,
					}))
				: [],
		});
	}, [insuranceData]);

	const validationSchema = Yup.object({
		// insuranceContactDetails: Yup.array().of(
		// 	Yup.object({
		// 		contact_type: Yup.string().required("Contact Type is required"),
		// 		contact_info: Yup.string().required("Contact Information is required"),
		// 	})
		// ),
		description: Yup.string().required("Insurance Name is required"),
		insuranceContactDetails: Yup.array().of(
			Yup.object().shape({
				contact_type: Yup.string().required("Contact Type is required"),
				contact_info: Yup.string().when("contact_type", {
					is: "Fax",
					then: (schema) =>
						schema
							.max(15, "Fax must be at most 15 characters")
							.required("Fax is required"),
					otherwise: (schema) =>
						schema.when("contact_type", {
							is: "Phone",
							then: (innerSchema) =>
								innerSchema
									.matches(
										/^\d+$/,
										"Phone number must contain only digits"
									)
									.max(
										15,
										"Phone number must be at most 15 digits"
									)
									.required("Phone number is required"),
							otherwise: (innerSchema) =>
								innerSchema.when("contact_type", {
									is: "Email",
									then: (emailSchema) =>
										emailSchema
											.email("Invalid email format")
											.required("Email is required"),
									otherwise: (defaultSchema) =>
										defaultSchema.required(
											"Contact information is required"
										),
								}),
						}),
				}),
			})
		),
	});

	const updateInsurance = async (values) => {
		setLoading(true);
		setStatus("");
		try {
			const payload = {
				insuranceDocuments: values.insuranceDocuments
					? values.insuranceDocuments.map((item) => ({
							fileName: item.file,
							description: item.description,
						}))
					: [],
				insuranceContactDetails: values.insuranceContactDetails
					? values.insuranceContactDetails.map((item) => ({
							type: item.contact_type,
							information: item.contact_info,
						}))
					: [],
				city: values.city?.label || "",
				state: values.state?.value || "",
				zip: values.zip,
				addressLine2: values.addressLine2,
				addressLine1: values.addressLine1,
				masterPayerId: values.masterPayerId,
				description: values.description,
				electronicPayerId: values.electronicPayerId,
				category: values.category,
				insuranceType: values.insuranceType,
			};

			const response = await handleApiCall(
				API_URL + `/api/1.0.0/insurance/${id}`,
				{
					method: "PUT",
					body: payload,
				}
			);
			if (response.responseStatus === true) {
				setStatus(
					"You have successfully updated an insurance, thank you."
				);
				addToastMessage({
					status: true,
					message: `You have successfully updated an insurance, thank you.`,
				});
				if (nextUrl) {
					navigate(nextUrl);
				} else {
					navigate("/insurance");
				}
				return true;
			} else {
				setStatus(
					`${response.responseDescription || "Something went wrong, please try again later."}`
				);
				addToastMessage({
					status: false,
					message: `${response.responseDescription || "Something went wrong, please try again later."}`,
				});
				return false;
			}
		} catch (e) {
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
			console.error("Error:", e);
			return false;
		} finally {
			setLoading(false);
		}
	};

	const labelStateFunc = (o) => `${o.name}`;
	const labelCityFunc = (o) => `${o.name}`;

	return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await updateInsurance(values);
        if (api) {
          resetForm();
        }
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
        validateForm,
        setFieldTouched,
      }) => (
        <Form>
          <div className="col-1-1">
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <h5 className="color-primary">Basic Information</h5>
                  </td>
                </tr>
                <br />
                <tr>
                  <td width="50%">
                    <Label title="Insurance Name" required={true} />
                    <Field
                      type="text"
                      placeholder="Enter Insurance Name"
                      name="description"
                      className={`col-1-1 primary ${
                        errors.description && touched.description ? "error" : ""
                      }`}
                    />
                  </td>
                  <td>
                    <Label title="Electronic Payer ID" />
                    <Field
                      type="text"
                      placeholder="Electronic Payer ID"
                      name="electronicPayerId"
                      className={`col-1-1 primary ${
                        errors.electronicPayerId && touched.electronicPayerId
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Master Payer ID" />
                    <Field
                      type="text"
                      placeholder="Master Payer ID"
                      name="masterPayerId"
                      className={`col-1-1 primary ${
                        errors.masterPayerId && touched.masterPayerId
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                  <td>
                    <Label title="Payer Type" />
                    <Field
                      type="text"
                      placeholder="Enter Payer Type"
                      name="category"
                      className={`col-1-1 primary ${
                        errors.category && touched.category ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Type Code" />
                    <Field
                      type="text"
                      placeholder="Enter Type Code"
                      name="insuranceType"
                      className={`col-1-1 primary ${
                        errors.insuranceType && touched.insuranceType
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                  <td>
                    <Label title="Address" />
                    <Field
                      type="text"
                      placeholder="Address"
                      name="addressLine1"
                      className={`col-1-1 primary ${
                        errors.addressLine1 && touched.addressLine1
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Address 2" />
                    <Field
                      type="text"
                      placeholder="Enter Address 2"
                      name="addressLine2"
                      className={`col-1-1 primary ${
                        errors.addressLine2 && touched.addressLine2
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                  <td>
                    <Label title="Zip Code" />
                    <ZipCodeInput
                      name="zip"
                      clearFields={() => {
                        setFieldValue("state", null);
                        setFieldValue("city", null);
                      }}
                      onZipDetailsFetched={(data) => {
                        if (data.country.code !== "US") return;
                        setIsZipDataFilled(true);
                        setFieldValue("state", {
                          value: data.state.code,
                          label: data.state.name,
                          obj: data.state,
                        });
                        setFieldValue("city", {
                          value: data.city.code,
                          label: data.city.name,
                          obj: data.city,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="State" />
                    <PaginatedDropdown
                      datakey="records"
                      url={`${API_URL}/api/1.0.0/reference/states/US?`}
                      mapOptions={(records) =>
                        mapOptions(records, "code", labelStateFunc)
                      }
                      error={errors.state && touched.state}
                      setTouched={() => setFieldTouched("state", true)}
                      placeHolder="Search"
                      selectedValue={values.state}
                      onChange={(v) => {
                        if (isZipDataFilled) setFieldValue("zip", "");
                        setIsZipDataFilled(false);
                        setFieldValue("state", v);
                        setFieldValue("city", null);
                      }}
                    />
                  </td>
                  <td>
                    <Label title="City" />
                    <PaginatedDropdown
                      datakey="records"
                      url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.state?.value}?`}
                      mapOptions={(records) =>
                        mapOptions(records, "name", labelCityFunc)
                      }
                      error={errors.city && touched.city}
                      setTouched={() => setFieldTouched("city", true)}
                      placeHolder="Search"
                      selectedValue={values.city}
                      onChange={(v) => {
                        if (isZipDataFilled) setFieldValue("zip", "");
                        setIsZipDataFilled(false);
                        setFieldValue("city", v);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                    <hr />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{ margin: "10px" }}>
                    <FieldArray name="insuranceContactDetails">
                      {({ push, remove }) => (
                        <>
                          {values.insuranceContactDetails.map(
                            (insuranceContactDetails, index) => (
                              <Fragment key={index}>
                                <>
                                  <div
                                    style={{
                                      margin: "10px 0",
                                      justifyContent: "space-between",
                                      borderBottom: "1px solid  #ccc",
                                      alignContent: "center",
                                      padding: "5px 0px",
                                    }}
                                    className="flex center-center "
                                  >
                                    <h5 className="color-primary f-w-700">
                                      Contact Details #{index + 1}
                                    </h5>
                                    <div
                                      style={{
                                        margin: "2px 0px",
                                      }}
                                    >
                                      <ButtonLightTextIcon
                                        type="button"
                                        title="Remove"
                                        icon={<RxCrossCircled size={20} />}
                                        onClick={() => remove(index)}
                                      />
                                    </div>
                                  </div>
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td width="50%">
                                          <Label
                                            title="Contact Type"
                                            required={true}
                                          />
                                          <Field
                                            as="select"
                                            name={`insuranceContactDetails.${index}.contact_type`}
                                            className={` primary ${
                                              errors?.insuranceContactDetails &&
                                              errors.insuranceContactDetails[
                                                index
                                              ]?.contact_type &&
                                              touched?.insuranceContactDetails &&
                                              touched.insuranceContactDetails[
                                                index
                                              ]?.contact_type
                                                ? "error"
                                                : ""
                                            }`}
                                          >
                                            <option value="">
                                              Select Contact Type
                                            </option>
                                            <option value="Phone">Phone</option>
                                            <option value="Fax">Fax</option>
                                            <option value="Email">Email</option>
                                          </Field>
                                          <ErrorMessage
                                            name={`insuranceContactDetails.${index}.contact_type`}
                                            component="p"
                                            className="error-messages"
                                          />
                                        </td>
                                        <td>
                                          <Label
                                            title="Contact Information"
                                            required={true}
                                          />
                                          {values.insuranceContactDetails[index]
                                            ?.contact_type === "Phone" ? (
                                            <>
                                              <PhoneNumberInput
                                                name={`insuranceContactDetails.${index}.contact_info`}
                                                placeholder="Eg: 888-888-8888"
                                                value={
                                                  insuranceContactDetails.contact_info
                                                }
                                                setTouched={() =>
                                                  setFieldTouched(
                                                    `insuranceContactDetails.${index}.contact_info`,
                                                    true
                                                  )
                                                }
                                                error={
                                                  errors?.insuranceContactDetails &&
                                                  errors
                                                    .insuranceContactDetails[
                                                    index
                                                  ]?.contact_info &&
                                                  touched?.insuranceContactDetails &&
                                                  touched
                                                    .insuranceContactDetails[
                                                    index
                                                  ]?.contact_info
                                                }
                                                callback={(v) =>
                                                  setFieldValue(
                                                    `insuranceContactDetails.${index}.contact_info`,
                                                    v
                                                  )
                                                }
                                              />
                                              <ErrorMessage
                                                name={`insuranceContactDetails.${index}.contact_info`}
                                                component="p"
                                                className="error-messages"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <Field
                                                type="text"
                                                placeholder="Contact Information"
                                                name={`insuranceContactDetails.${index}.contact_info`}
                                                className={`col-1-1 primary ${
                                                  errors?.insuranceContactDetails &&
                                                  errors
                                                    .insuranceContactDetails[
                                                    index
                                                  ]?.contact_info &&
                                                  touched?.insuranceContactDetails &&
                                                  touched
                                                    .insuranceContactDetails[
                                                    index
                                                  ]?.contact_info
                                                    ? "error"
                                                    : ""
                                                }`}
                                              />
                                              <ErrorMessage
                                                name={`insuranceContactDetails.${index}.contact_info`}
                                                component="p"
                                                className="error-messages"
                                              />
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </>
                              </Fragment>
                            )
                          )}
                          <div
                            className="flex center-center"
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            <ButtonTextIcon
                              type="button"
                              title="Add Contact"
                              onClick={() => {
                                push({
                                  contact_type: "",
                                  contact_info: "",
                                });
                              }}
                              icon={<FiPlusCircle />}
                            />
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                    <hr />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <h5 className="color-primary">Documents</h5>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <NestedDocumentForm
                      onAdd={(newDocument) => {
                        setFieldValue("insuranceDocuments", [
                          ...values.insuranceDocuments,
                          newDocument,
                        ]);
                      }}
                      setUploaded={setUploaded}
                      uploaded={uploaded}
                    />
                  </td>
                </tr>

                {values.insuranceDocuments && (
                  <tr>
                    <td colSpan="2">
                      <div className="contect-date">
                        <table>
                          <thead>
                            <tr>
                              <th>Document File</th>
                              <th>Document Description</th>
                              <th
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.insuranceDocuments.map((contact, index) => (
                              <tr>
                                <td className="t-t-u">{`${values?.description || ""} Document - ${index + 1}`}</td>
                                <td>{contact.description}</td>
                                <td>
                                  <div
                                    className="flex center-center"
                                    style={{
                                      gap: "10px",
                                    }}
                                  >
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => {
                                        const updatedContacts =
                                          values.insuranceDocuments.filter(
                                            (_, i) => i !== index
                                          );
                                        setFieldValue(
                                          "insuranceDocuments",
                                          updatedContacts
                                        );
                                      }}
                                    >
                                      <MdOutlineDeleteSweep color="red" />
                                    </div>
                                    {contact?.path && (
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "8px",
                                        }}
                                      >
                                        <NavLink
                                          to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                            contact?.path || ""
                                          )}&title=${encodeURIComponent(
                                            `${values?.description || ""} Document - ${index + 1}`
                                          )}`}
                                          state={location.state}
                                        >
                                          <span
                                            style={{
                                              background: "transparent",
                                              border: "none",
                                              cursor: "pointer",
                                            }}
                                            title="View file"
                                          >
                                            <LuEye /> 
                                          </span>
                                        </NavLink>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => {
                if (nextUrl) {
                  navigate(nextUrl);
                } else {
                  navigate("/insurance");
                }
              }}
              icon={<TbArrowBackUp />}
            />
            <ButtonTextIcon
              type="button"
              disabled={loading || uploaded}
              title="Update Now"
              onClick={async () => {
                const errors = await validateForm();
                if (Object.keys(errors).length > 0) {
                  let firstErrorMessage = "";
                  const getFirstErrorMessage = (errObj) => {
                    if (!errObj) return null;
                    if (typeof errObj === "string") return errObj;
                    if (Array.isArray(errObj)) {
                      for (const item of errObj) {
                        const message = getFirstErrorMessage(item);
                        if (message) return message;
                      }
                    }
                    if (typeof errObj === "object") {
                      for (const key of Object.keys(errObj)) {
                        const message = getFirstErrorMessage(errObj[key]);
                        if (message) return message;
                      }
                    }
                    return null;
                  };
                  firstErrorMessage =
                    getFirstErrorMessage(errors) ||
                    "Please fill in all required fields";
                  addToastMessage({
                    status: false,
                    message: firstErrorMessage,
                  });
                  return;
                }
                handleSubmit();
              }}
              className="mar-l-a"
              icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
            />
          </div>
          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const NestedDocumentForm = ({ onAdd, setUploaded, uploaded }) => {
	const fileUploadRef = useRef();
	const validationSchema = Yup.object().shape({
		file: Yup.string().required("File is required"),
		description: Yup.string().required("Description is required"),
	});
	const handleReset = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.resetFile();
		}
	};

	return (
    <Formik
      initialValues={{
        file: "",
        description: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        resetForm();
        handleReset();
      }}
    >
      {({ errors, touched, setFieldValue, values, handleSubmit }) => (
        <Form>
          <table>
            <tbody>
              <tr>
                <td colSpan="2">
                  <div className="flex center-left" style={{ gap: "8px" }}>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <Label title="Upload File" required={true} />
                    </div>
                    <FcInfo />
                  </div>
                  <DragFileUpload
                    ref={fileUploadRef}
                    fileType={"insurance"}
                    error={errors.file && touched.file}
                    afterUpload={(url) => {
                      setFieldValue("file", url);
                    }}
                    setUploading={setUploaded}
                  />
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <Label title="Description" required={true} />
                  <Field
                    type="text"
                    placeholder="Enter Description"
                    name="description"
                    className={`col-1-1 primary ${
                      errors.description && touched.description ? "error" : ""
                    }`}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </td>
                <td>
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonLightTextIcon
                        title="Add Document"
                        type="button"
                        disabled={uploaded}
                        onClick={handleSubmit}
                        icon={
							<FiPlusCircle/> 
                        }
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

const NestedContactForm = ({ onAdd }) => {
	const validationSchema = Yup.object().shape({
		contact_type: Yup.string().required("Contact Type is required"),
		contact_info: Yup.string().when("contact_type", {
			is: "Fax",
			then: (schema) =>
				schema
					.max(15, "Fax must be at most 15 characters")
					.required("Fax is required"),
			otherwise: (schema) =>
				schema.when("contact_type", {
					is: "Phone",
					then: (innerSchema) =>
						innerSchema
							.matches(
								/^\d+$/,
								"Phone number must contain only digits"
							)
							.max(15, "Phone number must be at most 15 digits")
							.required("Phone number is required"),
					otherwise: (innerSchema) =>
						innerSchema.when("contact_type", {
							is: "Email",
							then: (emailSchema) =>
								emailSchema
									.email("Invalid email format")
									.required("Email is required"),
							otherwise: (defaultSchema) =>
								defaultSchema.required(
									"Contact information is required"
								),
						}),
				}),
		}),
	});

	return (
    <Formik
      initialValues={{
        contact_type: "",
        contact_info: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        resetForm();
      }}
    >
      {({
        errors,
        touched,
        values,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
      }) => (
        <Form>
          <table>
            <tbody>
              <tr>
                <td width="50%">
                  <Label title="Contact Type" required={true} />
                  <Field
                    as="select"
                    name="contact_type"
                    className={`col-1-1 primary ${
                      errors.contact_type && touched.contact_type ? "error" : ""
                    }`}
                  >
                    <option value="">Select Contact Type</option>
                    <option value="Phone">Phone</option>
                    <option value="Fax">Fax</option>
                    <option value="Email">Email</option>
                  </Field>
                </td>
                <td>
                  <Label title="Contact Information" required={true} />
                  {values.contact_type === "Phone" ? (
                    <>
                      <PhoneNumberInput
                        name="contact_info"
                        placeholder="Eg: 888-888-8888"
                        error={errors.contact_info && touched.contact_info}
                        value={values.contact_info}
                        setTouched={() => setFieldTouched("contact_info", true)}
                        callback={(v) => setFieldValue("contact_info", v)}
                      />
                      <ErrorMessage
                        name="contact_info"
                        component="p"
                        className="error-messages"
                      />
                    </>
                  ) : (
                    <>
                      <Field
                        type="text"
                        placeholder="Contact Information"
                        name="contact_info"
                        className={`col-1-1 primary ${
                          errors.contact_info && touched.contact_info
                            ? "error"
                            : ""
                        }`}
                      />
                      {errors.contact_info && touched.contact_info && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "5px",
                          }}
                        >
                          {errors.contact_info}
                        </div>
                      )}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonLightTextIcon
                        title="Add Contact Detail"
                        type="button"
                        onClick={handleSubmit}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateForm;