import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import ButtonLightTextIcon from "../components/ui/ButtonLightTextIcon";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import {
  formatInputPhoneNumber,
  getRandomColor,
  handleApiCall,
  jsonToQueryParams,
} from "../utilities/helpers";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import FacilityFilter from "./facilities/FacilityFilter";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import useQueryParams from "../hooks/useQueryParams";
import PageToolbar from "../components/common/PageToolbar";
import InfoItem from "../components/ui/InfoItem";
import { FiEdit, FiPlusCircle } from "react-icons/fi";
import { TbArrowBackUp, TbTimelineEvent, TbUserCircle } from "react-icons/tb";
import { CiHospital1, CiPercent, CiVoicemail } from "react-icons/ci";
import {
  MdAlternateEmail,
  MdManageAccounts,
  MdOutlineContactless,
  MdOutlineRealEstateAgent,
} from "react-icons/md";
import { LuMapPin } from "react-icons/lu";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import { CgPokemon } from "react-icons/cg";
import { GiDna1 } from "react-icons/gi";
import { SiSemrush } from "react-icons/si";
import { RiContactsLine } from "react-icons/ri";

const Facilities = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateQueryParam, queryParams } = useQueryParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const pageNo = queryParams.get("pageNo") || 0;
  const pageSize = queryParams.get("pageSize") || 12;
  const sortOrder = queryParams.get("sortOrder") || "ASC";
  const { addToastMessage, permissions } = useUI();
  const [controller, setController] = useState(null);

  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("pageNo", 0);
    updateQueryParam("sortOrder", "ASC");
  };

  const callListApi = async () => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    try {
      setLoading(true);
      const query = jsonToQueryParams({
        search: queryParams.get("search") || "",
      });
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/facility?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=${sortOrder}&${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );

      if (response.responseStatus == true) {
        setData(response.data);
      } else {
        setData(null);
      }

      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
    }
  };

  useEffect(() => {
    callListApi();
  }, [location.search]);

  const handleDelete = async (id) => {
    try {
      if (!id) {
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/facility/change-status/${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.responseStatus == true) {
        callListApi();
        addToastMessage({
          status: true,
          message: `Facility deleted successfully.`,
        });
        return true;
      }

      addToastMessage({
        status: false,
        message: `Something went wrong while deleting procedure. Please try again.`,
      });

      return false;
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting procedure. Please try again.`,
      });
      return false;
    }
  };
  return (
    <section id="activity-v2">
      <PageToolbar
        title="Facilities"
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => navigate("/add-facility")}
              icon={<FiPlusCircle />}
            />
          )
        }
      />
      <FacilityFilter resetFilters={resetFilters} />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.facilityList.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Facility Name</th>
                <th>Address</th>
                <th>State, City, Zip</th>
                <th>Primary Contact Title</th>
                <th>Primary Contact Name</th>
                <th>Primary Contact Email</th>
                <th>Primary Contact Phone</th>
                <th>Fax</th>
                <th>Npi</th>
                <th>Taxonomy ID</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.facilityList.map((data, index) => (
                  <tr key={index}>
                    <td>{pageNo * pageSize + index + 1}</td>
                    <td>{data.facilityName}</td>
                    <td>
                      {data.address || "-"} {data.address2}
                    </td>
                    <td>
                      {data?.state || "-"}, {data?.city || "-"},{" "}
                      {data?.zipCode || "-"}
                    </td>
                    <td>
                      {data?.additionalContacts?.length > 0 &&
                      data.additionalContacts[0]?.title?.trim()
                        ? data.additionalContacts[0].title
                        : "-"}
                    </td>
                    <td>
                      {data?.additionalContacts?.length > 0 &&
                      data.additionalContacts[0]?.name?.trim()
                        ? data.additionalContacts[0].name
                        : "-"}
                    </td>
                    <td>
                      {data?.additionalContacts?.length > 0 &&
                      data.additionalContacts[0]?.email?.trim()
                        ? data?.additionalContacts[0].email
                        : "-"}
                    </td>
                    <td>
                      {data?.additionalContacts?.length > 0 &&
                      data.additionalContacts[0]?.phone?.trim()
                        ? formatInputPhoneNumber(
                            data?.additionalContacts[0]?.phone.trim()
                          )
                        : "-"}
                    </td>
                    <td>{data.contactFax}</td>
                    <td>{data.npi}</td>
                    <td>{data.taxonomy}</td>
                    <td>
                      <div
                        className="flex center-center"
                        style={{ gap: "12px" }}
                      >
                        {permissions?.isReadAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="view"
                                  title="View"
                                />
                              }
                              content={<FacilityDetailsContent id={data?.id} />}
                            />
                          </div>
                        )}
                        {permissions?.isUpdateAllowed && (
                          <div>
                            <NavLink
                              to={`/edit-facility?next=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                              state={{ id: data.id }}
                            >
                              <ButtonTertiary actionType="edit" title="Edit" />
                            </NavLink>
                          </div>
                        )}
                        {permissions?.isDeleteAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="delete"
                                  title="Delete"
                                />
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    await handleDelete(data.id);
                                    return true;
                                  }}
                                  message="Are you sure you want to delete this facility ?"
                                />
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

const FacilityDetailsContent = ({ setShow, id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { permissions } = useUI();

  const fetchFacilityData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/facility/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus == true) {
        setData(response.data);
        setLoading(false);
      } else {
        setError("Failed to fetch facility data.");
        setLoading(false);
        setData(null);
      }
    } catch (e) {
      setError("Failed to fetch facility data.");
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    if (id) fetchFacilityData(id);
  }, [id]);

  return (
    <div id="activity-detail-box">
      <div className="flex center-left">
        <div>
          <h6 className="color-primary f-w-300">Facility Details</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ButtonLightTextIcon
            title="Cancel"
            onClick={() => setShow(false)}
            icon={<TbArrowBackUp />}
          />
        </div>
      </div>
      <div
        style={{
          height: "60vh",
          margin: "10px",
          overflowY: "scroll",
          padding: "20px 5px",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
      >
        {loading && (
          <div
            className="col-1-1"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderIcon
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
        )}
        {!loading && error && (
          <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
            {error}
          </p>
        )}
        {data && !loading && !error && (
          <div className="activity-card pos-rel">
            <div
              style={{
                position: "absolute",
                content: "",
                height: "4px",
                left: "-1px",
                right: "-1px",
                bottom: "-2px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            ></div>
            <div
              className="col-1-1 flex center-left"
              style={{
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <h5 className="f-w-700">
                <span className="f-w-300">#</span>
                &nbsp;
                {data?.id}
              </h5>
              <hr
                style={{
                  outline: "none",
                  border: "none",
                  height: "16px",
                  width: "2px",
                  background: "#EBEBEB",
                }}
              />
            </div>
            <div
              style={{
                padding: "12px 0 12px 0 ",
              }}
            >
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CiHospital1 color={getRandomColor()} size={20} />}
                  label={"Facility"}
                  name={data?.facilityName}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuMapPin color={getRandomColor()} size={20} />}
                  label={"Address"}
                  name={data?.address || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineRealEstateAgent
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"State"}
                  name={data?.state || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineRealEstateAgent
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"City"}
                  name={data?.city || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbTimelineEvent color={getRandomColor()} size={20} />}
                  label={"Facility Type"}
                  name={data?.facilityType || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<BiIdCard color={getRandomColor()} size={20} />}
                  label={"Taxonomy Id"}
                  name={data?.taxonomy || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <AiOutlineFieldNumber color={getRandomColor()} size={20} />
                  }
                  label={"NPI"}
                  name={data?.npi || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <AiOutlineFieldNumber color={getRandomColor()} size={20} />
                  }
                  label={"Fax"}
                  name={data?.contactFax || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CgPokemon color={getRandomColor()} size={20} />}
                  label={"GPO"}
                  name={data?.gpo || "NA"}
                />

                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<GiDna1 color={getRandomColor()} size={20} />}
                  label={"IDN"}
                  name={data?.idn || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuMapPin color={getRandomColor()} size={20} />}
                  label={"Place of Service"}
                  name={data?.facilityPlaceOfService || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<SiSemrush color={getRandomColor()} size={20} />}
                  label={"EMR Access"}
                  name={data?.facilityEmrAccess || "NA"}
                />
              </div>

              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CiPercent color={getRandomColor()} size={20} />}
                  label={"Tax Percent"}
                  name={data?.taxPercent || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<MdManageAccounts color={getRandomColor()} size={20} />}
                  label={"Internal Identifier"}
                  name={data?.internalIdentifier || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbUserCircle color={getRandomColor()} size={20} />}
                  label={"Case Capacity"}
                  name={data?.caseCapacity || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              ></div>

              <hr
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />

              {data?.additionalContacts?.length ? (
                data?.additionalContacts?.map(
                  (contractPersonalDetails, index) => (
                    <>
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <MdOutlineContactless
                              color={getRandomColor()}
                              size={20}
                            />
                          }
                          label={" Contact Title"}
                          name={contractPersonalDetails?.title || "NA"}
                        />
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <RiContactsLine
                              color={getRandomColor()}
                              size={20}
                            />
                          }
                          label={"Contact Name"}
                          name={contractPersonalDetails?.name}
                        />
                      </div>
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <MdAlternateEmail
                              color={getRandomColor()}
                              size={20}
                            />
                          }
                          label={"Primary Person Email"}
                          name={contractPersonalDetails?.email || "NA"}
                        />
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <CiVoicemail color={getRandomColor()} size={20} />
                          }
                          label={"Contact Person Phone"}
                          name={
                            contractPersonalDetails?.phone
                              ? formatInputPhoneNumber(
                                  contractPersonalDetails?.phone.trim()
                                )
                              : "NA"
                          }
                        />
                      </div>
                      <hr
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </>
                  )
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px 12px",
                    backgroundColor: "rgb(245, 245, 245)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        No detail found.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        {permissions?.isUpdateAllowed && (
          <NavLink
            to={
              data?.id
                ? `/edit-facility?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                : "#"
            }
            state={{ id: data?.id }}
          >
            <ButtonLightTextIcon
              title="Edit Details"
              onClick={() => setShow(false)}
              icon={<FiEdit />}
            />
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default Facilities;
