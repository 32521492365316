import AddForm from "./procedure/AddForm";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import PageToolbar from "../components/common/PageToolbar";
import { useNavigate } from "react-router-dom";
import { TbArrowBackUp } from "react-icons/tb";

const AddProcedurePage = () => {
    const navigate = useNavigate();
  return (
    <section id="add-activity">
          <PageToolbar
              title="Add Procedure"
              button={
                <ButtonTextIcon
                title="Cancel"
                className="light mar-l-a"
                onClick={() => navigate("/procedures")}
                icon={
                 <TbArrowBackUp />
                }
              />
          }
        />

      <div className="col-1-1">
        <div className="form-holder">
          <div className="inner">
            <AddForm />
          </div>
        </div>
      </div>
    </section>
  );
};
export default AddProcedurePage;
