import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatISO, parseISO } from "date-fns";
import { formatAndValidateDate } from '../../utilities/helpers';

const DatePickerInput = ({ callback, value = "", className = "", error, setTouched = () => { }, }) => {
  const handleDatePickerChange = (callback, date, e) => {
    if (e?.target?.value !== undefined) {
      if (e?.target?.value) {
        callback(formatAndValidateDate(e.target.value));
      } else {
        callback("");
      }
    } else if (date) {
      callback(formatISO(date, { representation: "date" }));
    } else {
      callback("");
    }
  };

  return (
    <DatePicker
      className={`primary react-datepicker-popper ${className} ${error ? "error" : ""}`} 
      isClearable={true}
      portalId="root-portal"
      selected={value ? parseISO(value) : ""}
      onChange={(date, e) => {
        handleDatePickerChange(callback, date, e);
      }}
      onBlur={()=>setTouched()}
      showYearDropdown
      placeholderText="MM/DD/YYYY"
      dateFormat="MM/dd/yyyy"
    />
  );
};

export default DatePickerInput;