import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useState } from "react";
import { API_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import { formatInputPhoneNumber, handleApiCall } from "../../utilities/helpers";
import PaginatedDropdown, {
	mapOptions,
} from "../../components/ui/PaginatedDropdown";
import PhoneNumberInput from "../../components/ui/PhoneNumberInput";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import Label from "../../components/ui/Label";
import DatePickerInput from "../../components/ui/DatePickerInput";
import { useNavigate } from "react-router-dom";
import { FiArrowRightCircle, FiPlusCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { CiCircleCheck } from "react-icons/ci";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdOutlineDeleteSweep } from "react-icons/md";

const SingleStep = ({ title, subtitle, completion, style }) => {
	const getClassName = () => {
		const hasEmptyElement = completion.some((element) => element === "");
		if (hasEmptyElement) {
			return "incomplete";
		} else {
			return "complete";
		}
	};
	const dynamicStyle = {
		fontSize: "11px",
		...style,
	};

	return (
		<div className={`step t-a-c ${getClassName()}`}>
			<p className='color-primary' style={dynamicStyle}>
				<span className='f-w-700'>0{title}</span> {subtitle}
			</p>
			<div className={`b-r-100 icon`}>
			<IoMdCheckmarkCircle />

			</div>
		</div>
	);
};

const AddForm = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const { addToastMessage } = useUI();

	const initialValues = {
		contractContactDetails: [],
		contractTypeId: null,
		startDate: "",
		endDate: "",
		facilityId: null,
	};

	const validationSchema = Yup.object({
		// contactPersonName: Yup.string().required("Person Name Required"),
		// contactPersonPhone: Yup.string()
		//             .matches(/^\d+$/, "Phone Number must contain only numbers")
		//             .max(15, "Phone Number must be at most 15 digits")
		//             .required("Phone Number is required"),
		// contactPersonEmail: Yup.string()
		//             .email("Invalid email address")
		//             .required("Email is required"),
		contractTypeId: Yup.mixed()
			.nullable()
			.test(
				"Contract Type required",
				"Contract Type is Required",
				function (value) {
					return value !== null && value.value;
				}
			),
		startDate: Yup.string().required("Start Date required"),
		endDate: Yup.date()
			.required("End Date is required")
			.test(
				"is-after-startDate",
				"End date must be greater then Start date",
				function (value) {
					const { startDate } = this.parent;
					return (
						!startDate ||
						!value ||
						new Date(value) > new Date(startDate)
					);
				}
			),
		facilityId: Yup.mixed()
			.nullable()
			.test(
				"facility-required",
				"Facility is Required",
				function (value) {
					return value !== null && value.value;
				}
			),
	});

	const createContract = async (values) => {
		setLoading(true);
		setStatus("");
		try {
			if (values?.contractContactDetails?.length === 0) {
				setStatus(
					"At least one contact detail is required to create a contract. Please provide a valid contact detail."
				);
				addToastMessage({
					status: false,
					message: `At least one contact detail is required to create a contract. Please provide a valid contact detail.`,
				});
				return false;
			}

			const payload = {
				contractTypeId: values.contractTypeId.value,
				startDate: values.startDate,
				endDate: values.endDate,
				contactPersonDetails: values.contractContactDetails
					? values.contractContactDetails.map((item) => ({
							contactPersonEmail:
								item?.contactPersonEmail || null,
							contactPersonPhone:
								item?.contactPersonPhone || null,
							contactPersonName: item?.contactPersonName || null,
							contactPersonTitle:
								item?.contactPersonTitle || null,
						}))
					: [],
				facilityId: values.facilityId.value,
			};
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/contract`,
				{
					method: "POST",
					body: payload,
				}
			);
			if (response.responseStatus == true) {
				setStatus(
					"You have successfully created a contract, thank you."
				);
				addToastMessage({
					status: true,
					message: `You have successfully created a contract, thank you.`,
				});
				navigate({
					pathname: "/edit-contracts",
					state: { id: response.data.id },
				});
				return true;
			} else {
				setStatus(
					`${response.responseDescription || "Something went wrong, please try again later."}`
				);
				addToastMessage({
					status: false,
					message: `${response.responseDescription || "Something went wrong, please try again later."}`,
				});
				return false;
			}
		} catch (e) {
			console.error("Error:", e);
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
			return false;
		} finally {
			setLoading(false);
		}
	};

	const labelOnCallTypeFunc = (o) => `${o.typeName}`;
	const labelFacilityFunc = (o) => `${o.facilityName}`;

	return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await createContract(values);
        if (api) {
          resetForm();
        }
        setLoading(false);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        handleSubmit,
        validateForm,
      }) => (
        <Form>
          <section
            id="stepper-should-fix"
            className="config-application-stepper"
          >
            <div className="steps flex center-center" style={{ gap: "20px" }}>
              <SingleStep
                title="1"
                subtitle="Basic Information"
                style={{ marginRight: "20px" }}
                completion={[
                  values.contractTypeId,
                  values.facilityId,
                  values.startDate,
                  values.endDate,
                  values?.contractContactDetails?.length > 0 ? "values" : "",
                  // values.contactPersonPhone,
                  // values.contactPersonName,
                  // values.contactPersonEmail,
                ]}

                // completion={[data?.tenant + ""]}
              />

              <SingleStep
                title="2"
                subtitle="Procedure Configuration"
                completion={[
                  values?.procedureConfiguration?.length > 0 ? "values" : "",
                ]}
              />
              <SingleStep
                title="3"
                subtitle="On Call Configuration"
                completion={[
                  values?.onCallConfiguration?.length > 0 ? "values" : "",
                ]}
              />
            </div>
          </section>
          <br />
          <div className="col-1-1">
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <h5 className="color-primary">1. Basic Information</h5>
                  </td>
                </tr>
                <br />
                <tr>
                  <td width="50%">
                    <Label title="Contract Type" required={true} />
                    <PaginatedDropdown
                      datakey="records"
                      url={`${API_URL}/api/1.0.0/reference/contract-types?`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelOnCallTypeFunc)
                      }
                      setTouched={() => setFieldTouched("contractTypeId", true)}
                      placeHolder="Search"
                      selectedValue={values.contractTypeId}
                      error={errors.contractTypeId && touched.contractTypeId}
                      onChange={(v) => {
                        setFieldValue("contractTypeId", v);
                      }}
                    />
                  </td>
                  <td width="50%">
                    <Label title="Facility" required={true} />
                    <PaginatedDropdown
                      url={`${API_URL}/api/1.0.0/reference/facility?`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelFacilityFunc)
                      }
                      setTouched={() => setFieldTouched("facilityId", true)}
                      error={errors.facilityId && touched.facilityId}
                      placeHolder="Search Facility"
                      selectedValue={values.facilityId}
                      onChange={(v) => setFieldValue("facilityId", v)}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Start Date" required={true} />
                    <DatePickerInput
                      value={values.startDate}
                      error={errors.startDate && touched.startDate}
                      setTouched={() => setFieldTouched("startDate", true)}
                      callback={(v) => setFieldValue("startDate", v)}
                    />
                    <ErrorMessage
                      name="startDate"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td width="50%">
                    <Label title="End Date" required={true} />
                    <DatePickerInput
                      value={values.endDate}
                      error={errors.endDate && touched.endDate}
                      setTouched={() => setFieldTouched("endDate", true)}
                      callback={(v) => setFieldValue("endDate", v)}
                    />
                    <ErrorMessage
                      name="endDate"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
                <br />
                <tr>
                  <td colSpan="2">
                    <NestedPersonalDetailForm
                      onAdd={(newProcedure) => {
                        setFieldValue("contractContactDetails", [
                          ...values.contractContactDetails,
                          newProcedure,
                        ]);
                      }}
                    />
                  </td>
                </tr>
                {values.contractContactDetails && (
                  <tr>
                    <td colSpan="2">
                      <div className="contect-date">
                        <table>
                          <thead>
                            <tr>
                              <th>Designation</th>
                              <th>Name</th>
                              <th>Phone</th>
                              <th>Email</th>
                              <th
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.contractContactDetails.map(
                              (contact, index) => (
                                <tr>
                                  <td>
                                    <span>
                                      {contact?.contactPersonTitle || "-"}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {contact?.contactPersonName || "-"}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {contact.contactPersonPhone
                                        ? formatInputPhoneNumber(
                                            contact.contactPersonPhone.trim()
                                          )
                                        : "-"}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {contact?.contactPersonEmail || "-"}
                                    </span>
                                  </td>

                                  <td>
                                    <div className="flex center-center">
                                      <span
                                        className="cursor-pointer"
                                        onClick={() => {
                                          const updatedConfiguration =
                                            values.contractContactDetails.filter(
                                              (_, i) => i !== index
                                            );
                                          setFieldValue(
                                            "contractContactDetails",
                                            updatedConfiguration
                                          );
                                        }}
                                      >
                                        <MdOutlineDeleteSweep color="red" />
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => navigate("/contracts")}
              icon={<TbArrowBackUp />}
            />
            <ButtonTextIcon
              type="button"
              disabled={loading}
              title="Create Now"
              onClick={async () => {
                const errors = await validateForm();
                if (Object.keys(errors).length > 0) {
                  const firstErrorMessage = Object.values(errors)[0];
                  addToastMessage({
                    status: false,
                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                  });
                }
                handleSubmit();
              }}
              className="mar-l-a"
              icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
            />
          </div>

          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const NestedPersonalDetailForm = ({ onAdd }) => {
	const validationSchema = Yup.object().shape({
		contactPersonName: Yup.string().required("Person Name Required"),
		contactPersonPhone: Yup.string()
			.matches(/^\d+$/, "Phone Number must contain only numbers")
			.max(15, "Phone Number must be at most 15 digits")
			.required("Phone Number is required"),
		contactPersonEmail: Yup.string()
			.email("Invalid email address")
			.required("Email is required"),
	});

	return (
    <Formik
      initialValues={{
        contactPersonEmail: "",
        contactPersonPhone: "",
        contactPersonName: "",
        contactPersonTitle: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        try {
          onAdd(values);
          resetForm();
        } catch (error) {
          console.error("Error during form submission:", error);
        }
      }}
    >
      {({
        errors,
        touched,
        values,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
      }) => (
        <Form>
          <table>
            <tbody>
              <tr>
                <td width="50%">
                  <Label title="Contact Person Designation" />
                  <Field
                    type="text"
                    placeholder="Eg: Doctor"
                    name="contactPersonTitle"
                    className={`col-1-1 primary ${
                      errors.contactPersonTitle && touched.contactPersonTitle
                        ? "error"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    name="contactPersonTitle"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td width="50%">
                  <Label title="Contact Person Name" required={true} />
                  <Field
                    type="text"
                    placeholder="Enter Name"
                    name="contactPersonName"
                    className={`col-1-1 primary ${
                      errors.contactPersonName && touched.contactPersonName
                        ? "error"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    name="contactPersonName"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <Label title="Contact Person Phone" required={true} />
                  <PhoneNumberInput
                    name="contactPersonPhone"
                    placeholder="Eg: 888-888-8888"
                    error={
                      errors.contactPersonPhone && touched.contactPersonPhone
                    }
                    value={values.contactPersonPhone}
                    setTouched={() =>
                      setFieldTouched("contactPersonPhone", true)
                    }
                    callback={(v) => setFieldValue("contactPersonPhone", v)}
                  />
                  <ErrorMessage
                    name="contactPersonPhone"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td width="50%">
                  <Label title="Contact Person Email" required={true} />
                  <Field
                    type="text"
                    placeholder="Enter Email"
                    name="contactPersonEmail"
                    className={`col-1-1 primary ${
                      errors.contactPersonEmail && touched.contactPersonEmail
                        ? "error"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    name="contactPersonEmail"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <div className="flex bottom-right">
                    <div>
                      <ButtonLightTextIcon
                        title="Add Contact Details"
                        type="button"
                        onClick={handleSubmit}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

export default AddForm;