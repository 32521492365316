import useQueryParams from "../../hooks/useQueryParams";
import {
  RiArrowLeftDoubleLine,
  RiArrowLeftSLine,
  RiArrowRightDoubleLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";

const Pagination = ({ history, location, totalRecords, pageSize, removeParamKey }) => {
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageNo = parseInt(queryParams.get("pageNo") || 0);
  pageSize = queryParams.get("pageSize") || pageSize || 12;

  const maxPagesToShow = 5;
  const totalPages = Math.ceil(totalRecords / pageSize);

  const handlePageClick = (pageIndex) => {
    if (pageIndex !== pageNo && pageIndex >= 0 && pageIndex < totalPages) {
      updateQueryParam("pageNo", pageIndex);
    }
  };

  const getPaginationUI = () => {
    if (totalPages > 0) {
      let startPage = Math.max(0, pageNo - Math.floor(maxPagesToShow / 2));
      let endPage = startPage + maxPagesToShow - 1;

      if (endPage >= totalPages) {
        endPage = totalPages - 1;
        startPage = Math.max(0, endPage - maxPagesToShow + 1);
      }

      let allData = [];

      // Go to First Page button
      allData.push(
        <li
          key="first"
          onClick={() => handlePageClick(0)}
          disabled={pageNo === 0}
          className={pageNo === 0 ? "cursor-not-allowed" : ""}
        >
          <RiArrowLeftDoubleLine size={"14px"} />
        </li>
      );

      // Previous page button
      allData.push(
        <li
          key="prev"
          onClick={() => handlePageClick(pageNo - 1)}
          disabled={pageNo === 0}
          className={pageNo === 0 ? "cursor-not-allowed" : ""}
        >
          <RiArrowLeftSLine
           size={"14px"}
          />
        </li>
      );

      // Ellipsis before the first page number
      if (startPage > 0) {
        allData.push(
          <li key="start-ellipsis">
            <IoEllipsisHorizontalSharp size={"14px"}/>
          </li>
        );
      }

      // Page numbers
      for (let index = startPage; index <= endPage; index++) {
        allData.push(
          <li
            key={index}
            className={`${index === pageNo ? "active" : ""}`}
            onClick={() => handlePageClick(index)}
          >
            {index + 1}
          </li>
        );
      }

      // Ellipsis after the last page number
      if (endPage < totalPages - 1) {
        allData.push(
          <li key="end-ellipsis">
            <IoEllipsisHorizontalSharp size={"14px"}/>
          </li>
        );
      }

      // Next page button
      allData.push(
        <li
          key="next"
          onClick={() => handlePageClick(pageNo + 1)}
          disabled={pageNo === totalPages - 1}
          className={pageNo === totalPages - 1 ? "cursor-not-allowed" : ""}
        >
          <RiArrowRightSLine size={"14px"} />
        </li>
      );

      // Go to Last Page button
      allData.push(
        <li
          key="last"
          onClick={() => handlePageClick(totalPages - 1)}
          disabled={pageNo === totalPages - 1}
          className={pageNo === totalPages - 1 ? "cursor-not-allowed" : ""}
        >
          <RiArrowRightDoubleLine size={"14px"} />
        </li>
      );

      return allData;
    } else {
      return [];
    }
  };

  return <ul className="opl-pagination">{getPaginationUI()}</ul>;
};

export default Pagination;
