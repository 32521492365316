import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useEffect, useState } from "react";
import {
  API_URL,
  STATUS_USER_OPTIONS,
  USER_TYPE_OPTIONS,
} from "../../utilities/constants";
import PaginatedDropdown, {
  mapOptions,
} from "../../components/ui/PaginatedDropdown";
import DragFileUpload from "../../components/ui/DragFileUpload";
import { useUI } from "../../context/ui";
import { handleApiCall, sleep } from "../../utilities/helpers";
import { useNavigate } from "react-router-dom";
import Label from "../../components/ui/Label";
import DatePickerInput from "../../components/ui/DatePickerInput";
import { TbArrowBackUp } from "react-icons/tb";
import { FcInfo } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { FiArrowRightCircle } from "react-icons/fi";
const AddUserForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const { addToastMessage } = useUI();
  const [searchGroup, setSearchGroup] = useState("");
  const [searchPractice, setSearchPractice] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const initialValues = {
    name: "",
    lastName: "",
    email: "",
    type: "",
    groupIds: [],
    practice: [],
    notes: "",
    isActive: "1",
    activeEnd: "",
    activeStart: "",
    region: "",
    image: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("First Name is required")
      .min(2, "At least two characters is required in firstname"),
    lastName: Yup.string()
      .required("Last Name is required")
      .min(2, "At least two characters is required in lastname"),
    activeStart: Yup.string().required("Active Date is required"),
    activeEnd: Yup.date().nullable().test(
      "is-after-activeStart",
      "Active end must be greater than Active start",
      function (value) {
        const { activeStart } = this.parent;
        return (
          !activeStart || !value || new Date(value) > new Date(activeStart)
        );
      }
    ),
    type: Yup.string().required("User Type is required"),
    email: Yup.string().email().required("Email is required"),
    groupIds: Yup.array()
      .of(
        Yup.object().shape({
          val: Yup.number().required("Group is Required"),
        })
      )
      .required("Group is Required")
      .min(1, "At least one group is required"),
    practice: Yup.array()
      .of(
        Yup.object().shape({
          val: Yup.string().required("Required"),
          name: Yup.string().required("Practice is required"),
        })
      )
      .required("Required")
      .min(1, "At least one practice is required"),
    isActive: Yup.string().required("Status is required"),
  });

  const createUser = async (values) => {
    setLoading(true);
    setStatus("");
    try {
      const payload = {
        name: values.name,
        lastName: values.lastName,
        email: values.email,
        groupIds: values.groupIds.map((item) => item.val),
        practice: values.practice.map((item) => item.val).join(","),
        isActive: values.isActive,
        notes: values.notes,
        type: values.type,
        activeStart: values.activeStart,
        activeEnd: values.activeEnd,
        region: values.region,
        image: values.image,
        isAddAccess: true, // needs to be discussed
        isDeleteAccess: false,
        isUpdateAccess: false,
      };
      const response = await handleApiCall(API_URL + `/api/1.0.0/users`, {
        method: "POST",
        body: payload,
      });
      if (response.responseStatus === true) {
        setStatus("You have successfully created a user, thank you.");
        addToastMessage({
          status: true,
          message: `You have successfully created a User, thank you.`,
        });
        navigate("/users");
        return true;
      } else {
        setStatus(
          `${response.responseDescription || "Something went wrong, please try again later."}`
        );
        addToastMessage({
          status: false,
          message: `${response.responseDescription || "Something went wrong, please try again later."}`,
        });
        setLoading(false);

        return false;
      }
    } catch (e) {
      setStatus("Something went wrong, please try again later.");
      addToastMessage({
        status: false,
        message: "Something went wrong, please try again later.",
      });
      setLoading(false);
      console.error("Error:", e);
      return false;
    }
  };
  const labelPracticeFunc = (o) => `${o.payToName}`;
  const labelRegionFunc = (o) => `${o.name}`;
  const labelGroupFunc = (o) => `${o.groupName}`;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await createUser(values);
        if (api) {
          resetForm();
        }
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
        validateForm,
        setFieldTouched,
      }) => (
        <Form>
          <div className="col-1-1">
            <table>
              <tbody>
                <tr>
                  <td width="50%">
                    <Label title="First Name" required={true} />
                    <Field
                      type="text"
                      placeholder="Enter First Name"
                      name="name"
                      className={`col-1-1 primary ${
                        errors.name && touched.name ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="name"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td width="50%">
                    <Label title="Last Name" required={true} />
                    <Field
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      className={`col-1-1 primary ${
                        errors.lastName && touched.lastName ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Email" required={true} />
                    <Field
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      className={`col-1-1 primary ${
                        errors.email && touched.email ? "error" : ""
                      }`}
                    />
                  </td>
                  <td>
                    <Label title="User Type" required={true} />
                    <Field
                      as="select"
                      name="type"
                      className={`col-1-1 primary ${
                        errors.type && touched.type ? "error" : ""
                      }`}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setFieldValue("type", selectedValue);

                        if (selectedValue !== "assistant") {
                          setFieldValue("region", "");
                        }
                      }}
                    >
                      <option value="">Select</option>
                      {USER_TYPE_OPTIONS.filter(
                        (d) =>
                          d.value != "assistant" &&
                          d.value != "Physician Assistant"
                      ).map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>

                    {values.type === "assistant" && (
                      <div style={{ marginTop: "10px" }}>
                        <Label title="Region" />
                        <PaginatedDropdown
                          url={`${API_URL}/api/1.0.0/regions?`}
                          mapOptions={(records) =>
                            mapOptions(records, "id", labelRegionFunc)
                          }
                          error={errors.region && touched.region}
                          setTouched={() => setFieldTouched("region", true)}
                          placeHolder="Search"
                          onChange={(v) => {
                            setFieldValue("region", v.value);
                          }}
                        />
                      </div>
                    )}
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div className="flex center-left" style={{ gap: "8px" }}>
                      <div
                        className="opl-tooltip"
                        data-tooltip="Please only select PDF files for upload."
                      >
                        <Label title="Upload Image" />
                      </div>
                      <FcInfo />
                    </div>
                    <DragFileUpload
                      afterUpload={(url) => {
                        setFieldValue("image", url);
                      }}
                      setUploading={setUploaded}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Label title="Practice" required={true} />
                    <PaginatedDropdown
                      url={`${API_URL}/api/1.0.0/reference/practice?`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelPracticeFunc)
                      }
                      error={errors.practice && touched.practice}
                      placeHolder="Search"
                      setTouched={() => setFieldTouched("practice", true)}
                      selectedValue={searchPractice}
                      onChange={async (v) => {
                        if (v) {
                          setSearchPractice(v);
                          const p = values.practice;
                          const n = {
                            val: v.value,
                            name: v.label,
                          };
                          if (!p.some((item) => item.val === v.value)) {
                            setFieldValue("practice", [...values.practice, n]);
                          }
                          await sleep();
                          setSearchPractice(null);
                        }
                      }}
                    />

                    {values.practice.length > 0 && (
                      <ul
                        className="col-1-1 t-a-l"
                        style={{ padding: "10px 0" }}
                      >
                        {values.practice.map((p, j) => (
                          <li
                            key={j}
                            data-tooltip="Click to delete the practice."
                            onClick={() => {
                              const updatedPractice = values.practice.filter(
                                (obj) => obj.val !== p.val
                              );
                              setFieldValue("practice", updatedPractice);
                            }}
                            style={{
                              listStyle: "none",
                              marginRight: "4px",
                              marginBottom: "4px",
                              borderRadius: "2px",
                              lineHeight: "32px",
                              padding: "10px 12px",
                              verticalAlign: "middle",
                            }}
                            className="opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                          >
                            <div className="flex center-left">
                              <p
                                className="of-hid"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "108px",
                                  textOverflow: "ellipsis",
                                  fontSize: "12px",
                                }}
                              >
                                {p.name}
                              </p>
                              <MdCancel />
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Label title="Groups" required={true} />
                    <PaginatedDropdown
                      datakey="list"
                      url={`${API_URL}/api/1.0.0/groups?`}
                      mapOptions={(records) => {
                        const selectedGroupIds = values.groupIds.map(
                          (group) => group.val
                        );
                        return mapOptions(
                          records.filter(
                            (record) => !selectedGroupIds.includes(record.id)
                          ),
                          "id",
                          labelGroupFunc
                        );
                      }}
                      error={errors.groupIds && touched.groupIds}
                      setTouched={() => setFieldTouched("groupIds", true)}
                      placeHolder="Search"
                      selectedValue={searchGroup}
                      onChange={async (v) => {
                        if (v) {
                          setSearchGroup(v);
                          const newGroup = { val: v.value, name: v.label };
                          if (
                            !values.groupIds.some(
                              (item) => item.val === v.value
                            )
                          ) {
                            setFieldValue("groupIds", [
                              ...values.groupIds,
                              newGroup,
                            ]);
                          }
                          await sleep();
                          setSearchGroup(null);
                        }
                      }}
                    />

                    {/* Display selected groups */}
                    {values.groupIds.length > 0 && (
                      <ul
                        className="col-1-1 t-a-l"
                        style={{ padding: "10px 0" }}
                      >
                        {values.groupIds.map((p, j) => (
                          <li
                            key={j}
                            data-tooltip="Click to delete the group."
                            onClick={() => {
                              const updatedGroupsIds = values.groupIds.filter(
                                (obj) => obj.val !== p.val
                              );
                              setFieldValue("groupIds", updatedGroupsIds);
                            }}
                            style={{
                              listStyle: "none",
                              marginRight: "4px",
                              marginBottom: "4px",
                              borderRadius: "2px",
                              lineHeight: "32px",
                              padding: "10px 12px",
                              verticalAlign: "middle",
                            }}
                            className="opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                          >
                            <div className="flex center-left">
                              <p
                                className="of-hid"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "108px",
                                  textOverflow: "ellipsis",
                                  fontSize: "12px",
                                }}
                              >
                                {p.name}
                              </p>
                              <MdCancel />
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="Active Date" required={true} />
                    <DatePickerInput
                      callback={(v) => setFieldValue("activeStart", v)}
                      value={values.activeStart}
                      error={errors.activeStart && touched.activeStart}
                      setTouched={() => setFieldTouched("activeStart", true)}
                    />
                  </td>
                  <td>
                    <Label title="Active End" />
                    <DatePickerInput
                      callback={(v) => setFieldValue("activeEnd", v)}
                      value={values.activeEnd}
                      error={errors.activeEnd && touched.activeEnd}
                      setTouched={() => setFieldTouched("activeEnd", true)}
                    />
                    {errors.activeEnd && touched.activeEnd && (
                      <div
                        style={{
                          color: "red",
                          "font-size": "0.875rem",
                        }}
                      >
                        {errors.activeEnd}
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Notes" />
                    <Field
                      type="text"
                      placeholder="Enter Notes"
                      name="notes"
                      className={`col-1-1 primary ${
                        errors.notes && touched.notes ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => navigate("/users")}
              icon={<TbArrowBackUp />}
            />
            <ButtonTextIcon
              type="submit"
              disabled={loading || uploaded}
              title="Submit"
              onClick={async () => {
                const errors = await validateForm();
                if (Object.keys(errors).length > 0) {
                  const firstErrorMessage = Object.values(errors)[0];
                  addToastMessage({
                    status: false,
                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                  });
                }
                handleSubmit();
              }}
              className="mar-l-a"
              icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
            />
          </div>
          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddUserForm;
