import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PaginatedDropdown, {
	mapOptions,
} from "../../components/ui/PaginatedDropdown";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL, ORGANIZATION_PAY_OPTIONS } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import Label from "../../components/ui/Label";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { useNavigate } from "react-router-dom";
import { handleApiCall } from "../../utilities/helpers";
import ZipCodeInput from "../../components/ui/ZipCodeInput";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";

function AddPracticesForm() {
	const navigate = useNavigate();
	const [data, setData] = useState("");
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const { addToastMessage } = useUI();
	const [isZipDataFilled, setIsZipDataFilled] = useState(false);
	const initialValues = {
		organizationIndividual: "",
		billingAddress: "",
		billingAddress2: "",
		cliaNumber: "",
		einSsn: "",
		payToCity: "",
		payToFirstName: "",
		payToName: "",
		payToNpi: "",
		payToState: "",
		payToZip: "",
		taxIdentification: "",
		taxonomyCode: "",
		// organization:''
	};

	const validationSchema = Yup.object({
		// organizationIndividual: Yup.string().required('Organization individual field should be unique'),
		payToNpi: Yup.string()
			.min(3, "At least three characters is required")
			.required("NPI is required"),
		einSsn: Yup.string()
			.matches(/^\d+$/, "EIN/SSN must only contain numbers") // Regex to match digits only
			.notRequired(),
		payToName: Yup.string()
			.required("Organization/Individual/Group Practice is required")
			.min(3, "atleast 3"),

		billingAddress: Yup.string()
			.nullable()
			.max(190, "Billing Address must not exceed 190 characters"),
		billingAddress2: Yup.string()
			.nullable()
			.max(190, "Billing Address 2 must not exceed 190 characters"),
		// cliaNumber: Yup.string(),
		// einSsn: Yup.string().required('EIN/SSN is required'),
		// payToCity: Yup.string().required('City is required'),
		// payToFirstName: Yup.string(),
		// payToState: Yup.string().required('State is required'),
		// payToZip: Yup.string().required('ZIP Code is required'),
		// taxIdentification: Yup.string(),
		// taxonomyCode: Yup.string().required('Taxonomy code is required'),
		// organization: Yup.string().required('required')
	});
	const createPractice = async (values) => {
		setLoading(true);
		setStatus("");
		try {
			const payload = {
				organizationIndividual: values.organizationIndividual,
				billingAddress: values.billingAddress,
				billingAddress2: values.billingAddress2,
				cliaNumber: values.cliaNumber,
				einSsn: values.einSsn ? values.einSsn : null,
				payToFirstName: values.payToFirstName
					? values.payToFirstName
					: null,
				payToName: values.payToName,
				payToNpi: values.payToNpi,
				payToState: values.payToState?.value || "",
				payToCity: values.payToCity?.label || "",
				payToZip: values.payToZip,
				taxIdentification: values.taxIdentification,
				taxonomyCode: values.taxonomyCode,
			};
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/practice`,
				{
					method: "POST",
					body: payload,
				}
			);
			if (response.responseStatus == true) {
				setStatus(
					"You have successfully created a practice, thank you."
				);
				addToastMessage({
					status: true,
					message: `You have successfully created a practice, thank you.`,
				});
				navigate("/practices");
				return true;
			} else {
				setStatus("Something went wrong, please try again later.");
				addToastMessage({
					status: false,
					message: `Something went wrong, please try again later.`,
				});
				return false;
			}
		} catch (e) {
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
			console.error("Error:", e);
			return false;
		} finally {
			setLoading(false);
		}
	};

	const labelStateFunc = (o) => `${o.name}`;
	const labelStateCodeFunc = (o) => `${o.code}`;
	const labelCityFunc = (o) => `${o.name}`;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				const api = await createPractice(values);
				if (api) {
					resetForm();
				}
			}}>
			{({
				values,
				errors,
				touched,
				setFieldValue,
				validateForm,
				handleSubmit,
				setFieldTouched,
			}) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td>
										<Label
											title='Pay to NPI Number'
											required
										/>
										<Field
											name='payToNpi'
											type='text'
											placeholder='NPI'
											className={`primary ${errors.payToNpi && touched.payToNpi ? "error" : ""}`}
										/>
										<ErrorMessage
											name='payToNpi'
											component='p'
											className='error-messages'
										/>
									</td>
									<td>
										<Label
											title='Organization/Individual/Group Practice'
											required={true}
										/>

										<Field
											name='payToName'
											type='text'
											placeholder='Eg: Acme Corp'
											className={`primary ${errors.payToName && touched.payToName ? "error" : ""}`}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label title='Billing Address' />
										<Field
											name='billingAddress'
											type='text'
											placeholder='Billing Address'
											className={`primary  ${errors.billingAddress && touched.billingAddress ? "error" : ""}`}
										/>
										<ErrorMessage
											name='billingAddress'
											component='p'
											className='error-messages'
										/>
									</td>
									<td>
										<Label title='Billing Address 2' />
										<Field
											name='billingAddress2'
											type='text'
											placeholder='Billing Address 2'
											className={`primary  ${errors.billingAddress2 && touched.billingAddress2 ? "error" : ""}`}
										/>
										<ErrorMessage
											name='billingAddress2'
											component='p'
											className='error-messages'
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label title='State' />
										<PaginatedDropdown
											datakey='records'
											url={`${API_URL}/api/1.0.0/reference/states/US?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"code",
													labelStateFunc
												)
											}
											error={
												errors.payToState &&
												touched.payToState
											}
											setTouched={() =>
												setFieldTouched(
													"payToState",
													true
												)
											}
											placeHolder='Search'
											selectedValue={values.payToState}
											onChange={(v) => {
												if (isZipDataFilled)
													setFieldValue(
														"payToZip",
														""
													);
												setIsZipDataFilled(false);
												setFieldValue("payToState", v);
												setFieldValue(
													"payToCity",
													null
												);
											}}
										/>
									</td>
									<td>
										<Label title='City' />
										<PaginatedDropdown
											datakey='records'
											url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.payToState?.value}?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"name",
													labelCityFunc
												)
											}
											error={
												errors.payToCity &&
												touched.payToCity
											}
											setTouched={() =>
												setFieldTouched(
													"payToCity",
													true
												)
											}
											placeHolder='Search'
											selectedValue={values.payToCity}
											onChange={(v) => {
												if (isZipDataFilled)
													setFieldValue(
														"payToZip",
														""
													);
												setIsZipDataFilled(false);
												setFieldValue("payToCity", v);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label title='ZIP Code' />
										<ZipCodeInput
											name='payToZip'
											clearFields={() => {
												setFieldValue("state", null);
												setFieldValue("city", null);
											}}
											onZipDetailsFetched={(data) => {
												if (data.country.code !== "US")
													return;
												setIsZipDataFilled(true);
												setFieldValue("state", {
													value: data.state.code,
													label: data.state.name,
													obj: data.state,
												});
												setFieldValue("city", {
													value: data.city.code,
													label: data.city.name,
													obj: data.city,
												});
											}}
										/>
									</td>
									<td>
										<Label title='EIN/SSN' />
										<Field
											name='einSsn'
											type='text'
											placeholder='EIN/SSN'
											className={`primary ${errors.einSsn && touched.einSsn ? "error" : ""}`}
										/>
										<ErrorMessage
											name='einSsn'
											component='p'
											className='error-messages'
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label title='Taxonomy Code' />
										<Field
											name='taxonomyCode'
											type='text'
											placeholder='Taxonomy Code'
											className={`primary ${errors.taxonomyCode && touched.taxonomyCode ? "error" : ""}`}
										/>
									</td>
									<td>
										<Label title='Pay to Organization/Individual' />
										<Field
											as='select'
											name='organizationIndividual'
											className={`col-1-1 primary ${
												errors.organizationIndividual &&
												touched.organizationIndividual
													? "error"
													: ""
											}`}>
											<option value=''>Select</option>
											{ORGANIZATION_PAY_OPTIONS.map(
												(d, i) => (
													<option
														value={d.value}
														key={i}>
														{d.label}
													</option>
												)
											)}
										</Field>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => navigate("/practices")}
							icon={
								<TbArrowBackUp />
							}
						/>
						<ButtonTextIcon
							type='submit'
							disabled={loading}
							title='Create Now'
							onClick={async () => {
								const errors = await validateForm();
								if (Object.keys(errors).length > 0) {
									const firstErrorMessage =
										Object.values(errors)[0];
									addToastMessage({
										status: false,
										message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
									});
								}
								handleSubmit();
							}}
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<FiArrowRightCircle />
								)
							}
						/>
					</div>
					{status && (
						<>
							<br />
							<p className='color-primary f-w-700'>{status}</p>
						</>
					)}
				</Form>
			)}
		</Formik>
	);
}

export default AddPracticesForm;
