import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../../utilities/constants";
import { NavLink, useNavigate } from "react-router-dom";
import LoaderIcon from "../../components/ui/LoaderIcon";
import {
  decodeModuleAccessFromToken,
  getRandomColor,
  handleApiCall,
} from "../../utilities/helpers";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import InfoItem from "../../components/ui/InfoItem";
import { useUI } from "../../context/ui";
import { TbArrowBackUp, TbUserCircle } from "react-icons/tb";
import { FiEdit } from "react-icons/fi";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { LuMapPin, LuQrCode, LuSchool } from "react-icons/lu";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { BiIdCard } from "react-icons/bi";

const PracticeDetail = ({ setShow, id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { permissions } = useUI();

  const fetchFacilityData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/practice/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      } else {
        setError("Failed to fetch facility data.");
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch facility data.");
      setLoading(false);
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchFacilityData(id);
  }, [id]);

  return (
    <div id="activity-detail-box">
      <div className="flex center-left">
        <div>
          <h6 className="color-primary f-w-300">Practice Details</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ButtonLightTextIcon
            title="Cancel"
            onClick={() => setShow(false)}
            icon={<TbArrowBackUp />}
          />
        </div>
      </div>
      <div
        style={{
          height: "60vh",
          margin: "10px",
          overflowY: "scroll",
          padding: "20px 5px",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
      >
        {loading && (
          <div
            className="col-1-1"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderIcon
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
        )}
        {!loading && error && (
          <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
            {error}
          </p>
        )}
        {data && !loading && !error && (
          <div className="activity-card pos-rel">
            <div
              style={{
                position: "absolute",
                content: "",
                height: "4px",
                left: "-1px",
                right: "-1px",
                bottom: "-2px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            ></div>
            <div
              className="col-1-1 flex center-left"
              style={{
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <h5 className="f-w-700">
                <span className="f-w-300">#</span>
                &nbsp;
                {data?.id}
              </h5>
              <hr
                style={{
                  outline: "none",
                  border: "none",
                  height: "16px",
                  width: "2px",
                  background: "#EBEBEB",
                }}
              />
            </div>
            <div
              style={{
                padding: "12px 0 12px 0 ",
              }}
            >
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbUserCircle color={getRandomColor()} size={20} />}
                  label={"Pay To Name"}
                  name={data?.payToName || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <AiOutlineFieldNumber color={getRandomColor()} size={20} />
                  }
                  label={"Pay To Npi"}
                  name={data?.payToNpi || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuSchool color={getRandomColor()} size={20} />}
                  label={"Organization/Individual/Group Practice"}
                  name={data?.organizationIndividual || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuMapPin color={getRandomColor()} size={20} />}
                  label={"Billing Address"}
                  name={data?.billingAddress || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuMapPin color={getRandomColor()} size={20} />}
                  label={"Billing Address 2"}
                  name={data?.billingAddress2 || "NA"}
                />

                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineRealEstateAgent
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"State"}
                  name={data?.payToState?.name || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineRealEstateAgent
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"City"}
                  name={data?.payToCity?.name || "NA"}
                />

                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuMapPin color={getRandomColor()} size={20} />}
                  label={"ZIP Code"}
                  name={data?.payToZip || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<BiIdCard color={getRandomColor()} size={20} />}
                  label={"EIN/SSN"}
                  name={data?.einSsn || "NA"}
                />

                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuQrCode color={getRandomColor()} size={20} />}
                  label={"Taxonomy Code"}
                  name={data?.taxonomyCode || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuSchool color={getRandomColor()} size={20} />}
                  label={"Pay to Organization/Individual"}
                  name={data?.taxIdentification || "NA"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {permissions?.isUpdateAllowed && (
          <NavLink
            to={
              data?.id
                ? `/edit-practice?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                : "#"
            }
            state={data?.id ? { id: data.id } : null}
          >
            <ButtonLightTextIcon title="Edit Practice" icon={<FiEdit />} />
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default PracticeDetail;
