import React, { useEffect, useRef, useState } from "react";
import LoaderIcon from "./LoaderIcon";

const FileViewer = ({ fileUrl, isNoFullHeight }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isPdf, setIsPdf] = useState(null);
	const [numPages, setNumPages] = useState(0);
	const [pages, setPages] = useState([]); // Store an array of canvases for each page

	useEffect(() => {
		const fetchAndRenderPdf = async () => {
			try {
				setLoading(true);
				const response = await fetch(fileUrl, {
					method: "GET",
				});

				if (!response.ok) {
					throw new Error("Failed to fetch PDF");
				}

				const pdfData = await response.arrayBuffer();
				const pdf = await window.pdfjsLib.getDocument({ data: pdfData })
					.promise;
				setNumPages(pdf.numPages);

				const newPages = [];
				for (
					let pageNumber = 1;
					pageNumber <= pdf.numPages;
					pageNumber++
				) {
					const page = await pdf.getPage(pageNumber);
					const viewport = page.getViewport({ scale: 1 });

					const canvas = document.createElement("canvas");
					const context = canvas.getContext("2d");
					canvas.height = viewport.height;
					canvas.width = viewport.width;
					canvas.style.width = "100%";
					canvas.style.height = "auto";
					await page.render({
						canvasContext: context,
						viewport: viewport,
					}).promise;
					newPages.push(canvas);
				}

				setPages(newPages);
				setLoading(false);
			} catch (err) {
				setError(err.message);
				setLoading(false);
			}
		};

		setLoading(true);
		const urlObj = new URL(fileUrl);

		if (urlObj.pathname.toLowerCase().endsWith(".pdf")) {
			setIsPdf(true);
			fetchAndRenderPdf();
		} else {
			setIsPdf(false);
			setLoading(false);
		}

		return () => {
			setLoading(true);
			setError(null);
			setPages([]);
		};
	}, [fileUrl]);

	return (
		<div className='col-1-1'>
			<div
				style={{
					height: isNoFullHeight ? "auto" : "100%",
					width: "auto",
					maxWidth: "100%",
					overflowY: "auto",
				}}>
				{loading && (
					<LoaderIcon
						style={{
							width: "20px",
							height: "20px",
						}}
					/>
				)}
				{!loading && error && (
					<p>Sorry, we couldn't load the PDF: {error}</p>
				)}
				{!loading && !error && isPdf && (
					<div
						style={{
							margin: "10px",
							overflowY: "scroll",
							padding: "20px 5px",
							overflowX: "hidden",
							scrollbarWidth: "thin",
						}}>
						{pages.map((canvas, index) => (
							<div key={index} style={{ marginBottom: "10px" }}>
								<canvas
									ref={(node) =>
										node && node.replaceWith(canvas)
									}
								/>
							</div>
						))}
					</div>
				)}
				{!loading && !error && !isPdf && (
					<img
						src={fileUrl}
						alt="Sorry, we couldn't load the image"
						style={{ width: "100%" }}
					/>
				)}
			</div>
		</div>
	);
};

export default FileViewer;
