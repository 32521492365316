import { useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import LoaderIcon from "../../components/ui/LoaderIcon";
import {
  formatRateUSD,
  formatServerDate,
  handleApiCall,
} from "../../utilities/helpers";
import FullScreenUpdateBillFormModal from "./FullScreenUpdateBillFormModal";
import { MdCancel } from "react-icons/md";

const FullScreenBillDetailsModal = ({ id = null, title = "", setShow,editEnabled = false, }) => {
  const [billData, setBillData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const fetchBillData = async (id) => {
    try {
      const response = await handleApiCall(API_URL + `/api/1.0.0/bill/${id}`, {
        method: "GET",
      });
      if (response.responseStatus == true) {
        setBillData(response.data);
        setLoading(false);
      } else {
        setError("Failed to fetch bill data.");
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch bill data.");
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    fetchBillData(id);
  }, [id]);

  return (
    <section
      style={{
        position: "fixed",
        inset: 0,
        zIndex: 9999,
        backgroundColor: "rgba(255, 255, 255, 0.86)",
      }}
    >
      <section
        className="col-1-1 flex center-center"
        style={{
          height: "100vh",
          width: "100vw",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            height: "100vh",
            width: "100vw",
            margin: "10px",
            padding: "15px",
            border: "solid 1px #f0f0f0",
            background: "white",
            borderRadius: "8px",
            margin: "0 auto",
            boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
          }}
        >
          <div
            className="col-1-1 flex center-left"
            style={{
              borderBottom: "solid 1px #f0f0f0",
              paddingBottom: "12px",
              marginBottom: "16px",
              gap: "10px",
            }}
          >
            <h6 className="color-primary f-w-700 t-t-u l-s-n">{title || ""}</h6>
            <div
              className="mar-l-a cursor-pointer "
              onClick={() => setShow(false)}
            >
           <MdCancel  size={28}/>
            </div>
          </div>
          <section id="add-activity">
            <div className="col-1-1">
              {!error && !loading && billData && (
                  <MedicalBill
                    data={billData}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    fetchBillData={fetchBillData}
                    editEnabled={editEnabled}
                  />
                )}

              {!error && loading && !billData && (
                <p
                  style={{
                    height: "100vh",
                    paddingBottom: "20vh",
                    backgroundColor: "#fafaff",
                  }}
                  className="flex center-center"
                >
                  <LoaderIcon
                    style={{
                      height: "32px",
                      width: "32px",
                    }}
                  />
                </p>
              )}
              {error && !loading && !billData && (
                <p
                  style={{
                    height: "100vh",
                    paddingBottom: "20vh",
                    backgroundColor: "#fafaff",
                  }}
                  className="flex center-center"
                >
                  {error || "Error"}
                </p>
              )}
            </div>
          </section>
        </div>
      </section>
    </section>
  );
};

export default FullScreenBillDetailsModal;

const MedicalBill = ({data,isEdit,setIsEdit,fetchBillData,editEnabled}) =>  { 
  

  return (
    <>
      {!isEdit ? (
        <div
          style={{
            maxWidth: "700px",
            margin: "20px auto",
            padding: "20px",
            backgroundColor: "white",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            fontSize: "11px",
            border: "1px solid #ccc",
            textTransform: "uppercase",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              borderBottom: "1px solid #ccc",
              paddingBottom: "10px",
            }}
          >
            <div>
              <h1
                style={{ fontSize: "18px", fontWeight: "bold", color: "#333" }}
              >
                Medical Bill
              </h1>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "16px",
              marginTop: "20px",
            }}
          >
            <div>
              <h2
                style={{
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "#555",
                  marginBottom: "8px",
                  textTransform: "uppercase",
                }}
              >
                Patient Information
              </h2>
              <div style={{ color: "#666", lineHeight: "1.5" }}>
                {[
                  {
                    label: "Name:",
                    value: `${data?.activity?.patient?.firstName || "NA"} ${
                      data?.activity?.patient?.middleName || ""
                    } ${data?.activity?.patient?.lastName || ""}`,
                  },
                  {
                    label: "DOB:",
                    value: data?.activity?.patient?.dateOfBirth
                      ? formatServerDate(data?.activity?.patient?.dateOfBirth)
                      : "NA",
                  },
                  {
                    label: "Chart No:",
                    value: data?.activity?.patient?.patientChartNo || "NA",
                  },
                  {
                    label: "Service Date:",
                    value: data?.activity?.patient?.serviceDate
                      ? formatServerDate(data?.activity?.patient?.serviceDate)
                      : "NA",
                  },
                  {
                    label: "Surgeon:",
                    value: data?.activity?.surgeon?.firstName
                      ? data?.activity?.surgeon?.firstName +
                        " " +
                        (data?.activity?.surgeon?.middleName || "")
                      : "NA",
                  },
                ].map((item, idx) => (
                  <p
                    key={idx}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        minWidth: "150px",
                        fontWeight: "500",
                        flexShrink: 0,
                      }}
                    >
                      {item.label}
                    </span>
                    <span>{item.value}</span>
                  </p>
                ))}
              </div>
            </div>
            <div>
              <h2
                style={{
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "#555",
                  marginBottom: "8px",
                  textTransform: "uppercase",
                }}
              >
                Activity Details
              </h2>
              <div style={{ color: "#666", lineHeight: "1.5" }}>
                {[
                  {
                    label: "Facility:",
                    value: data?.facility?.facilityName || "NA",
                  },
                  {
                    label: "Specialty:",
                    value: data?.speciality?.name || "NA",
                  },
                  {
                    label: "Procedures:",
                    value: data?.procedures?.length
                      ? data?.procedures
                          .map((proc) => proc.procedureName)
                          .join(", ")
                      : "NA",
                  },
                  {
                    label: "Assistant:",
                    value: data?.activity?.event_surgical_assitant?.name
                      ? data?.activity?.event_surgical_assitant?.name +
                        " " +
                        (data?.activity?.event_surgical_assitant?.lastName ||
                          "")
                      : "NA",
                  },
                ].map((item, idx) => (
                  <p
                    key={idx}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        minWidth: "150px",
                        fontWeight: "500",
                        flexShrink: 0,
                      }}
                    >
                      {item.label}
                    </span>
                    <span>{item.value}</span>
                  </p>
                ))}
              </div>
            </div>
          </div>

          <hr style={{ border: "0.5px solid #ccc", margin: "20px 0" }} />

          <div>
            <h2
              style={{
                fontWeight: "600",
                fontSize: "12px",
                color: "#555",
                marginBottom: "12px",
                textTransform: "uppercase",
              }}
            >
              Charges
            </h2>
            <table
              style={{
                width: "100%",
                fontSize: "11px",
                color: "#666",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#f9f9f9" }}>
                  <th
                    className="color-primary"
                    style={{
                      textAlign: "left",
                      padding: "8px",
                      fontWeight: "600",
                      color: "#555",
                    }}
                  >
                    Description
                  </th>
                  <th
                    className="color-primary"
                    style={{
                      textAlign: "right",
                      padding: "8px",
                      fontWeight: "600",
                      color: "#555",
                    }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.oncallCharge ? (
                  <tr>
                    <td
                      className="color-primary"
                      style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
                    >
                      On-call Charge
                    </td>
                    <td
                      className="color-primary"
                      style={{
                        textAlign: "right",
                        padding: "8px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      {formatRateUSD(data.oncallCharge)}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}

                {data.procedureCharge ? (
                  <tr>
                    <td
                      className="color-primary"
                      style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
                    >
                      Specialty Charge
                    </td>
                    <td
                      className="color-primary"
                      style={{
                        textAlign: "right",
                        padding: "8px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      {formatRateUSD(data.procedureCharge)}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {data.billType === "billable" && (
                  <tr>
                    <td
                      className="color-primary"
                      style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
                    >
                      Insurance Payment Received
                    </td>
                    <td
                      className="color-primary"
                      style={{
                        textAlign: "right",
                        padding: "8px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      {formatRateUSD(data.insurancePaymentReceived)}
                    </td>
                  </tr>
                )}
                <tr style={{ fontWeight: "bold", color: "#333" }}>
                  <td className="color-primary" style={{ padding: "8px" }}>
                    Total Amount Due
                  </td>
                  <td
                    className="color-primary"
                    style={{ textAlign: "right", padding: "8px" }}
                  >
                    {formatRateUSD(
                      Math.max(0, data.amount - data.insurancePaymentReceived)
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <hr style={{ border: "0.5px solid #ccc", margin: "5px 0" }} />
            <br />
            <div>
              <h2
                style={{
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "#555",
                  marginBottom: "12px",
                  textTransform: "uppercase",
                }}
              >
                Comments:-
              </h2>
              <p style={{ fontSize: "11px", color: "#666", lineHeight: "1.5" }}>
                {data?.comment || "-"}
              </p>
            </div>
          </div>
          {!(data?.invoiceStatus === 2 || data?.invoiceStatus === 4) && editEnabled && (
            <div style={{ marginTop: "20px", textAlign: "end" }}>
              <div className="color-secondary transition hover-color-primary cursor-pointer ">
                <a
                  style={{
                    fontSize: "11px",
                    color: "#666",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsEdit(true)}
                >
                  Want to edit this bill? Click here
                </a>
              </div>
            </div>
          )}
        </div>
      ) : (
        <FullScreenUpdateBillFormModal
          title="Edit Bill"
          id={data.id}
          setShow={setIsEdit}
          Callback={fetchBillData}
        />
      )}
    </>
  );
};
