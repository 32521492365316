import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react"; 
import WithLayout from "../hoc/withLayout";
import { decodeModuleAccessFromToken, getUserDeatilsFromToken, handleApiCall } from "../utilities/helpers"; 
import NotAuthorizedPage from "../pages/_common/NotAuthorizedPage";
import { API_URL } from "../utilities/constants";
import LoaderIcon from "../components/ui/LoaderIcon";
import { useUI } from "./ui";
import useDocumentTitle from "../hooks/useDocumentTitle";
import AppLogout from "../components/common/AppLogout";

const ApplicationLayout = ({ component: Component, module, checkAccess = ["isReadAllowed"], title }) => {
    const navigate = useNavigate();
	  const location = useLocation();
    const { profile, setProfile, status, setStatus,setPermissions,permissions } = useUI();
    
    useDocumentTitle(title);
    
    useEffect(() => {
        const decodePermissions = decodeModuleAccessFromToken(module); 
        setPermissions(decodePermissions);
        if (!decodePermissions) {
            navigate("/login");
            return;
        }
 
        if (!profile && status === "idle") {
            const fetchUserProfile = async () => {
                setStatus("loading");
                try { 
	                const user = getUserDeatilsFromToken();
                    const response = await handleApiCall(API_URL + "/api/1.0.0/profile/" + user?.userid, {
                        method: "GET",
                    });
                    if (response.responseStatus === true) {
                        setProfile(response.data);
                        setStatus("success");
                    } else {
                        setStatus("error");
                        navigate("/login");
                    }
                } catch (error) {
                    console.error("Failed to fetch user profile:", error);
                    setStatus("error");
                    navigate("/login");
                }
            };

            fetchUserProfile();
        }
    }, [module, location.pathname, profile, status, setProfile, setStatus, navigate]);

 


    const isAccessAllowed = checkAccess.every((accessKey) => permissions?.[accessKey]);
    if (isAccessAllowed && status === "success") {
        return (
          <AppLogout>
            <WithLayout> 
                <Component /> 
            </WithLayout>
          </AppLogout>
        );
    }

    if (status === "loading" || status === "idle" ) {
        return (
          <section style={{ height: "100vh" }}>
            <div
              className="flex center-center"
              style={{ backgroundColor: "white", height: "100vh" }}
            >
              <LoaderIcon
                style={{
                  width: "32px",
                  height: "32px",
                }}
              />
            </div>
          </section>
        );  
    }

    return ( 
      <AppLogout>
        <WithLayout>
          <NotAuthorizedPage />
        </WithLayout> 
      </AppLogout>
    );
};

export default ApplicationLayout;
