import React, { useState, useEffect, useCallback, useRef } from "react";
import Select from "react-select";
import { handleApiCall } from "../../utilities/helpers";

const CustomLoader = () => (
	<div
		style={{
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}}>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 100 100'
			preserveAspectRatio='xMidYMid'
			width='30'
			height='30'
			style={{ shapeRendering: "auto", display: "block" }}>
			<g>
				{[...Array(8)].map((_, index) => (
					<g key={index} transform={`rotate(${index * 45} 50 50)`}>
						<rect
							fill='#ccc'
							height='12'
							width='12'
							ry='5'
							rx='5'
							y='28'
							x='28'>
							<animate
								repeatCount='indefinite'
								begin={`${-0.994 + index * 0.142}s`}
								dur='1.5s'
								keyTimes='0;1'
								values='1;0'
								attributeName='opacity'
							/>
						</rect>
					</g>
				))}
			</g>
		</svg>
	</div>
);

const PaginatedDropdown = ({
	datakey = "records",
	searchkey = "search",
	url,
	placeHolder = "Search options...",
	selectedValue = null,
	onChange,
	getAll = false,
	mapOptions,
	error = false,
	disabled = false,
	setTouched = () => {},
	preload = false,
}) => {
	const controllerRef = useRef(null);
	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [page, setPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [noMoreOptions, setNoMoreOptions] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		setSelectedOption(selectedValue || null);
		setSearchQuery(selectedValue?.label || "");
	}, [selectedValue]);

	const debounce = (func, delay) => {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => func(...args), delay);
		};
	};

	const fetchOptions = async (search = "", pageNum = 1) => {
		if (controllerRef.current) {
			controllerRef.current.abort();
		}

		const newController = new AbortController();
		controllerRef.current = newController;
		const { signal } = newController;

		if (noMoreOptions) return;

		try {
			setIsLoading(true);
			const response = await handleApiCall(
				`${url}sortOrder=ASC&pageSize=${getAll ? 1000 : 10}&pageNo=${
					getAll ? 0 : pageNum - 1
				}${search ? `&${searchkey}=${search}` : ""}`,
				{
					method: "GET",
				},
				null,
				signal
			);

			if (response.responseStatus) {
				const newOptions = mapOptions(response.data[datakey]);
				setOptions((prevOptions) => [...prevOptions, ...newOptions]);

				if (
					!getAll &&
					response.data.totalPages === response.data.pageNo + 1
				) {
					setNoMoreOptions(true);
				}
				if (response?.data?.totalRecords === 0) {
					setNoMoreOptions(true);
				}
			}
			if (response.responseCode !== 299) {
				setIsLoading(false);
			}
		} catch (error) {
			if (error.name === "AbortError") {
				console.log("Request aborted:", error.message);
			} else {
				console.error("Error fetching options:", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const debouncedFetchOptions = useCallback(
		debounce((...args) => fetchOptions(...args), 500),
		[url]
	);

	useEffect(() => {
		return () => {
			if (controllerRef.current) {
				controllerRef.current.abort();
			}
		};
	}, []);

	useEffect(() => {
		if (preload || isMenuOpen) {
			debouncedFetchOptions(searchQuery, page);
		}
	}, [searchQuery, page, debouncedFetchOptions, preload, isMenuOpen]);

	const customStyles = {
		menuPortal: (base) => ({
			...base,
			zIndex: 999999999999,
		}),
		menuList: (base) => ({
			...base,
			"maxHeight": "200px",
			"width": "100%",
			"overflowY": "auto",
			"wordBreak": "break-word",
			"border": "1px solid #ccc",
			"borderRadius": "4px",
			"backgroundColor": "white",
			"zIndex": 999999999999,
			"&::-webkit-scrollbar": {
				width: "5px",
			},
			"&::-webkit-scrollbar-thumb": {
				"backgroundColor": "#aaa",
				"borderRadius": "10px",
				"&:hover": {
					backgroundColor: "#888",
				},
			},
		}),
		option: (base, { isSelected }) => ({
			...base,
			"borderBottom": "1px solid #f0f0f0",
			"fontSize": "12px",
			"color": "#283f54",
			"&:hover": {
				backgroundColor: "#f0f0f0",
			},
			...(isSelected && {
				backgroundColor: "#e0e0e0",
			}),
		}),
		loadingIndicator: (base) => ({
			...base,
		}),
		input: (base) => ({
			...base,
			"color": "inherit",
			"fontSize": "12px",
			"minWidth": "140px",
			"&:hover": {
				color: "inherit",
			},
		}),
		control: (base, { isFocused }) => ({
			...base,
			"borderColor": error ? "red" : "#ccc",
			"boxShadow": "none",
			"&:hover": {
				borderColor: error ? "red" : "#ccc",
			},
			...(isFocused && {
				borderColor: error ? "red" : "#ccc",
				boxShadow: "none",
			}),
		}),
	};

	const handleMenuOpen = () => {
		setIsMenuOpen(true);
		if (!preload) {
			debouncedFetchOptions(searchQuery, page);
		}
	};

	const handleMenuClose = () => {
		setIsMenuOpen(false);
	};

	return (
		<Select
			isDisabled={disabled}
			isClearable
			isLoading={isLoading}
			options={options}
			value={selectedOption}
			onInputChange={(query) => {
				setSearchQuery(query);
				setPage(1);
				setNoMoreOptions(false);
				setOptions([]);
			}}
			onChange={(option) => {
				setSelectedOption(option || null);
				if (!option) {
					setSearchQuery("");
				}
				onChange(option);
			}}
			onMenuOpen={handleMenuOpen}
			onMenuClose={handleMenuClose}
			placeholder={placeHolder}
			menuIsOpen={isMenuOpen}
			onMenuScrollToBottom={() => {
				if (!getAll) {
					if (!noMoreOptions && !isLoading) {
						setPage((prevPage) => prevPage + 1);
					}
				}
			}}
			styles={customStyles}
			classNamePrefix={error ? "error" : ""}
			components={{
				LoadingIndicator: CustomLoader,
			}}
			onBlur={() => setTouched(true)}
			menuPortalTarget={document.body}
			menuPosition='fixed'
		/>
	);
};

export const mapOptions = (records, valueKey, labelFormatter) =>
	records.map((record) => ({
		value: record[valueKey],
		label: labelFormatter(record).replace(/\s+/g, " ").trim(),
		obj: record,
	}));

export default PaginatedDropdown;
