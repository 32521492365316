import "./css/animations.scss";
import "./css/base.scss";
import "./css/pages.scss";
import "./css/responsive.scss";

import { UIProvider } from "./context/ui";

import { Route, Routes } from "react-router-dom";

// ====== Contexts & HOCs ====== //

import ApplicationLayout from "./context/layout";

// ====== Common Pages ====== //

// =======dashboard ==============//

import LoginPage from "./pages/_common/LoginPage";
import AzureCallbackPage from "./pages/_common/AzureCallbackPage";
// import SchedulingPage from "./pages/SchedulingPage";
import NotFoundPage from "./pages/_common/NotFoundPage";
import ActivityPage from "./pages/ActivityPage";
import AddActivityPage from "./pages/AddActivityPage";
import UpdateActivity from "./pages/UpdateActivity";
import PatientPage from "./pages/PatientPage";
import AddPatientPage from "./pages/AddPatientPage";
import UpdatePatientPage from "./pages/UpdatePatientPage";
import ProcedurePage from "./pages/ProcedurePage";
import AddProcedurePage from "./pages/AddProcedurePage";
import UpdateProcedurePage from "./pages/UpdateProcedurePage";
import GroupPage from "./pages/GroupPage";
import UpdateGroupPage from "./pages/UpdateGroupPage";
import AddGroupPage from "./pages/AddGroupPage";
import UsersPage from "./pages/UsersPage";
import AddUser from "./pages/AddUserPage";
import UpdateUser from "./pages/UpdateUserPage";
import ArchivedUser from "./pages/ArchivedUser";
import AddInsurancePage from "./pages/AddInsurancePage";
import InsurancePage from "./pages/InsurancePage";
import UpdateInsurancePage from "./pages/UpdateInsurancePage";
import ScanflowPage from "./pages/ScanflowPage";
import UpdatePracticePage from "./pages/UpdatePracticePage";
import PracticePage from "./pages/PracticePage";
import AddPracticePage from "./pages/AddPracticePage";
import AddPhysicianAssistant from "./pages/AddPhysicianAssistant";
import PhysicianAssistantPage from "./pages/PhysicianAssistantPage";
import UpdatePhysicianAssistant from "./pages/UpdatePhysicianAssistant";
import UpdateSurgicalAssistant from "./pages/UpdateSurgicalAssistant";
import SurgicalAssistantPage from "./pages/SurgicalAssistantPage";
import AddSurgicalAssistant from "./pages/AddSurgicalAssistant";
import UpdateSurgeonPage from "./pages/UpdateSurgeonPage";
import SurgeonPage from "./pages/SurgeonPage";
import AddSurgeonPage from "./pages/AddSurgeonPage";
import AddSurgeonAssociationPage from "./pages/AddSurgeonAssociationPage";
import SurgeonAssociationPage from "./pages/SurgeonAssociationPage";
import AddFacility from "./pages/AddFacility";
import Facilities from "./pages/Facilities";
import UpdateFacility from "./pages/UpdateFacility";
import PatientRegistrationPage from "./pages/PatientRegistrationPage";
import VolunteerRegistrationPage from "./pages/VolunteerRegistrationPage";
import UpdateKnowledgeBaseCategoryPage from "./pages/UpdateKnowledgeBaseCategoryPage";
import KnowledgeBaseCategoryPage from "./pages/KnowledgeBaseCategoryPage";
import AddKnowledgeBaseCategoryPage from "./pages/AddKnowledgeBaseCategoryPage";
import UpdateKnowledgeBaseSubCategoryPage from "./pages/UpdateKnowledgeBaseSubCategoryPage";
import KnowledgeBaseSubCategoryPage from "./pages/KnowledgeBaseSubCategoryPage";
import AddKnowledgeBaseSubCategoryPage from "./pages/AddKnowledgeBaseSubCategoryPage";
import UpdateKnowledgeBasePage from "./pages/UpdateKnowledgeBasePage";
import KnowledgeBasePage from "./pages/KnowledgeBasePage";
import AddKnowledgeBasePage from "./pages/AddKnowledgeBasePage";
import UpdateRegionPage from "./pages/UpdateRegionPage";
import RegionPage from "./pages/RegionPage";
import AddRegionPage from "./pages/AddRegionPage";
import ProfilePage from "./pages/ProfilePage";
import BillPage from "./pages/BillPage";
import InvoicingPage from "./pages/InvoicingPage";
import ContractPage from "./pages/ContractPage";
import AddContractPage from "./pages/AddContractPage";
import UpdateContractPage from "./pages/UpdateContractPage";
import UnderConstructionPage from "./pages/UnderConstructionPage";
import ZipPage from "./pages/ZipPage";
import NPIPage from "./pages/NPIPage";
import DocView from "./components/ui/DocView";
import SpecialtyPage from "./pages/SpecialtyPage";
import PreferencePage from "./pages/PreferencePage";
import DashboardPage from "./pages/DashboardPage";

// ====== App Routes ====== //
const App = () => {
	return (
		<UIProvider>
			<Routes>
				<Route exact path='/' element={<LoginPage />} />

				<Route exact path='/login' element={<LoginPage />} />

				<Route
					exact
					path='/auth/callback'
					element={<AzureCallbackPage />}
				/>

				{/* =================== Analytics  ==================  */}
				<Route
					exact
					path='/analytics'
					element={
						<ApplicationLayout
							module='analytics'
							checkAccess={[
								"isReadAllowed",
							]}
							component={DashboardPage}
							title='Analytics'
						/>
					}
				/>

				{/* =================== Scheduling  ==================  */}
				<Route
					exact
					path='/scheduling'
					element={
						<ApplicationLayout
							module='scheduling'
							checkAccess={[
								"isReadAllowed",
								"isCreateAllowed",
								"isUpdateAllowed",
							]}
							component={UnderConstructionPage}
							title='Scheduling'
						/>
					}
				/>
				{/* =================== Activites  ================== */}
				<Route
					exact
					path='/activities'
					element={
						<ApplicationLayout
							module='activities'
							checkAccess={["isReadAllowed"]}
							component={ActivityPage}
							title='Activities'
						/>
					}
				/>
				<Route
					exact
					path='/add-activity'
					element={
						<ApplicationLayout
							module='activities'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddActivityPage}
							title='Activities'
						/>
					}
				/>
				<Route
					exact
					path='/edit-activity'
					element={
						<ApplicationLayout
							module='activities'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateActivity}
							title='Activities'
						/>
					}
				/>
				{/* =================== Patient  ================== */}
				<Route
					exact
					path='/patients'
					element={
						<ApplicationLayout
							module='patients'
							checkAccess={["isReadAllowed"]}
							component={PatientPage}
							title='Patients'
						/>
					}
				/>
				<Route
					exact
					path='/add-patient'
					element={
						<ApplicationLayout
							module='patients'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddPatientPage}
							title='Patients'
						/>
					}
				/>
				<Route
					exact
					path='/edit-patient'
					element={
						<ApplicationLayout
							module='patients'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdatePatientPage}
							title='Patients'
						/>
					}
				/>
				{/* =================== SpecialtyPage ================== */}
				<Route
					exact
					path='/specialty'
					element={
						<ApplicationLayout
							module='specialty'
							checkAccess={["isReadAllowed"]}
							component={SpecialtyPage}
							title='Specialty'
						/>
					}
				/>
				{/* =================== procedure ================== */}
				<Route
					exact
					path='/procedures'
					element={
						<ApplicationLayout
							module='procedures'
							checkAccess={["isReadAllowed"]}
							component={ProcedurePage}
							title='Procedures'
						/>
					}
				/>
				<Route
					exact
					path='/add-procedure'
					element={
						<ApplicationLayout
							module='procedures'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddProcedurePage}
							title='Procedures'
						/>
					}
				/>
				<Route
					exact
					path='/edit-procedure'
					element={
						<ApplicationLayout
							module='procedures'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateProcedurePage}
							title='Procedures'
						/>
					}
				/>
				{/* =================== Group  ================== */}
				<Route
					exact
					path='/groups'
					element={
						<ApplicationLayout
							module='groups'
							checkAccess={["isReadAllowed"]}
							component={GroupPage}
							title='Groups'
						/>
					}
				/>
				<Route
					exact
					path='/edit-group'
					element={
						<ApplicationLayout
							module='groups'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateGroupPage}
							title='Groups'
						/>
					}
				/>
				<Route
					exact
					path='/add-group'
					element={
						<ApplicationLayout
							module='groups'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddGroupPage}
							title='Groups'
						/>
					}
				/>
				{/* =================== User ================== */}
				<Route
					exact
					path='/users'
					element={
						<ApplicationLayout
							module='users'
							checkAccess={["isReadAllowed"]}
							component={UsersPage}
							title='Users'
						/>
					}
				/>
				<Route
					exact
					path='/add-user'
					element={
						<ApplicationLayout
							module='users'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddUser}
							title='Users'
						/>
					}
				/>
				<Route
					exact
					path='/edit-user'
					element={
						<ApplicationLayout
							module='users'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateUser}
							title='Users'
						/>
					}
				/>
				<Route
					exact
					path='/archived-users'
					element={
						<ApplicationLayout
							module='users'
							checkAccess={[
								"isReadAllowed",
								"isUpdateAllowed",
								"isCreateAllowed",
							]}
							component={ArchivedUser}
							title='Archived Users / Assistants'
						/>
					}
				/>

				{/* =================== Insurance  ================== */}

				<Route
					exact
					path='/edit-insurance'
					element={
						<ApplicationLayout
							module='insurance'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateInsurancePage}
							title='Insurance'
						/>
					}
				/>
				<Route
					exact
					path='/insurance'
					element={
						<ApplicationLayout
							module='insurance'
							checkAccess={["isReadAllowed"]}
							component={InsurancePage}
							title='Insurance'
						/>
					}
				/>
				<Route
					exact
					path='/add-insurance'
					element={
						<ApplicationLayout
							module='insurance'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddInsurancePage}
							title='Insurance'
						/>
					}
				/>

				{/* =================== Practices  ================== */}
				<Route
					exact
					path='/edit-practice'
					element={
						<ApplicationLayout
							module='practices'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdatePracticePage}
							title='Practices'
						/>
					}
				/>
				<Route
					exact
					path='/practices'
					element={
						<ApplicationLayout
							module='practices'
							checkAccess={["isReadAllowed"]}
							component={PracticePage}
							title='Practices'
						/>
					}
				/>
				<Route
					exact
					path='/add-practice'
					element={
						<ApplicationLayout
							module='practices'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddPracticePage}
							title='Practices'
						/>
					}
				/>

				{/* =================== physicians assistance  ================== */}
				<Route
					exact
					path='/edit-physician-assistant'
					element={
						<ApplicationLayout
							module='physicians_assistance'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdatePhysicianAssistant}
							title='Physician Assistant'
						/>
					}
				/>
				<Route
					exact
					path='/physicians_assistance'
					element={
						<ApplicationLayout
							module='physicians_assistance'
							checkAccess={["isReadAllowed"]}
							component={PhysicianAssistantPage}
							title='Physician Assistant'
						/>
					}
				/>
				<Route
					exact
					path='/add-physician-assistant'
					element={
						<ApplicationLayout
							module='physicians_assistance'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddPhysicianAssistant}
							title='Physician Assistant'
						/>
					}
				/>

				{/* =================== physicians assistance  ================== */}

				<Route
					exact
					path='/edit-assistant'
					element={
						<ApplicationLayout
							module='surgical_assistance'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateSurgicalAssistant}
							title='Surgical Assistant'
						/>
					}
				/>
				<Route
					exact
					path='/surgical_assistance'
					element={
						<ApplicationLayout
							module='surgical_assistance'
							checkAccess={["isReadAllowed"]}
							component={SurgicalAssistantPage}
							title='Surgical Assistant'
						/>
					}
				/>
				<Route
					exact
					path='/add-assistant'
					element={
						<ApplicationLayout
							module='surgical_assistance'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddSurgicalAssistant}
							title='Surgical Assistant'
						/>
					}
				/>
				{/* =================== surgeon ================== */}
				<Route
					exact
					path='/edit-surgeon'
					element={
						<ApplicationLayout
							module='surgeons'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateSurgeonPage}
							title='Surgeon'
						/>
					}
				/>
				<Route
					exact
					path='/surgeons'
					element={
						<ApplicationLayout
							module='surgeons'
							checkAccess={["isReadAllowed"]}
							component={SurgeonPage}
							title='Surgeon'
						/>
					}
				/>
				<Route
					exact
					path='/surgeons/:id/prefernces'
					element={
						<ApplicationLayout
							module='preference-card'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={PreferencePage}
							title='Preference Card'
						/>
					}
				/>
				<Route
					exact
					path='/add-surgeon'
					element={
						<ApplicationLayout
							module='surgeons'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddSurgeonPage}
							title='Surgeon'
						/>
					}
				/>
				{/* =================== Surgeon Association  ================== */}
				<Route
					exact
					path='/surgeon-association'
					element={
						<ApplicationLayout
							module='surgeon-association'
							checkAccess={["isReadAllowed"]}
							component={SurgeonAssociationPage}
							title='Surgeon Association'
						/>
					}
				/>
				<Route
					exact
					path='/add-surgeon-association'
					element={
						<ApplicationLayout
							module='surgeon-association'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddSurgeonAssociationPage}
							title='Surgeon Association'
						/>
					}
				/>
				{/* =================== Facilities  ================== */}
				<Route
					exact
					path='/edit-facility'
					element={
						<ApplicationLayout
							module='facilities'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateFacility}
							title='Facilities'
						/>
					}
				/>
				<Route
					exact
					path='/facilities'
					element={
						<ApplicationLayout
							module='facilities'
							checkAccess={["isReadAllowed"]}
							component={Facilities}
							title='Facilities'
						/>
					}
				/>
				<Route
					exact
					path='/add-facility'
					element={
						<ApplicationLayout
							module='facilities'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddFacility}
							title='Facilities'
						/>
					}
				/>
				{/* =================== knowledge base categories ================== */}
				<Route
					exact
					path='/edit-knowledge-base-categories'
					element={
						<ApplicationLayout
							module='knowledge-base-categories'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateKnowledgeBaseCategoryPage}
							title={"Knowledge Base Categories"}
						/>
					}
				/>
				<Route
					exact
					path='/knowledge-base-categories'
					element={
						<ApplicationLayout
							module='knowledge-base-categories'
							checkAccess={["isReadAllowed"]}
							component={KnowledgeBaseCategoryPage}
							title={"Knowledge Base Categories"}
						/>
					}
				/>
				<Route
					exact
					path='/add-knowledge-base-categories'
					element={
						<ApplicationLayout
							module='knowledge-base-categories'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddKnowledgeBaseCategoryPage}
							title={"Knowledge Base Categories"}
						/>
					}
				/>

				{/* =================== knowledge base sub categories ================== */}
				<Route
					exact
					path='/edit-knowledge-base-sub-categories'
					element={
						<ApplicationLayout
							module='knowledge-base-sub-categories'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateKnowledgeBaseSubCategoryPage}
							title={"Knowledge Base Sub Categories"}
						/>
					}
				/>
				<Route
					exact
					path='/knowledge-base-sub-categories'
					element={
						<ApplicationLayout
							module='knowledge-base-sub-categories'
							checkAccess={["isReadAllowed"]}
							component={KnowledgeBaseSubCategoryPage}
							title={"Knowledge Base Sub Categories"}
						/>
					}
				/>
				<Route
					exact
					path='/add-knowledge-base-sub-categories'
					element={
						<ApplicationLayout
							module='knowledge-base-sub-categories'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddKnowledgeBaseSubCategoryPage}
							title={"Knowledge Base Sub Categories"}
						/>
					}
				/>

				{/* =================== knowledge base ================== */}
				<Route
					exact
					path='/edit-knowledge-base'
					element={
						<ApplicationLayout
							module='knowledge-base'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateKnowledgeBasePage}
							title={"Knowledge Base"}
						/>
					}
				/>
				<Route
					exact
					path='/knowledge-base'
					element={
						<ApplicationLayout
							module='knowledge-base'
							checkAccess={["isReadAllowed"]}
							component={KnowledgeBasePage}
							title={"Knowledge Base"}
						/>
					}
				/>
				<Route
					exact
					path='/add-knowledge-base'
					element={
						<ApplicationLayout
							module='knowledge-base'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddKnowledgeBasePage}
							title={"Knowledge Base"}
						/>
					}
				/>
				{/* =================== region ================== */}
				<Route
					exact
					path='/edit-region'
					element={
						<ApplicationLayout
							module='regions'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateRegionPage}
							title='Regions'
						/>
					}
				/>
				<Route
					exact
					path='/regions'
					element={
						<ApplicationLayout
							module='regions'
							checkAccess={["isReadAllowed"]}
							component={RegionPage}
							title='Regions'
						/>
					}
				/>
				<Route
					exact
					path='/add-region'
					element={
						<ApplicationLayout
							module='regions'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddRegionPage}
							title='Regions'
						/>
					}
				/>

				{/* =================== Billing  ================== */}
				<Route
					exact
					path='/bills'
					element={
						<ApplicationLayout
							module='bills'
							checkAccess={["isReadAllowed"]}
							component={BillPage}
							title='Bills'
						/>
					}
				/>

				{/* =================== Invoicing  ================== */}
				<Route
					exact
					path='/invoicing'
					element={
						<ApplicationLayout
							module='invoicing'
							checkAccess={["isReadAllowed"]}
							component={InvoicingPage}
							title='Invoicing'
						/>
					}
				/>

				{/* =================== Profile  ================== */}
				<Route
					path='/profile'
					element={
						<ApplicationLayout
							module='scheduling'
							allowedRoles={[1, 2, 3, 4, 6, 7, 8, 9, 10]}
							component={ProfilePage}
						/>
					}
				/>

				{/* =================== Contarct ================== */}
				<Route
					exact
					path='/contracts'
					element={
						<ApplicationLayout
							module='contracts'
							checkAccess={["isReadAllowed"]}
							component={ContractPage}
							title='Contracts'
						/>
					}
				/>
				<Route
					exact
					path='/add-contracts'
					element={
						<ApplicationLayout
							module='contracts'
							checkAccess={["isReadAllowed", "isCreateAllowed"]}
							component={AddContractPage}
							title='Contracts'
						/>
					}
				/>
				<Route
					exact
					path='/edit-contracts'
					element={
						<ApplicationLayout
							module='contracts'
							checkAccess={["isReadAllowed", "isUpdateAllowed"]}
							component={UpdateContractPage}
							title='Contracts'
						/>
					}
				/>

				{/* =================== Scanflow  ================== */}
				<Route
					exact
					path='/scan-flow'
					element={
						<ApplicationLayout
							module='scan-flow'
							checkAccess={[
								"isReadAllowed",
								"isUpdateAllowed",
								"isCreateAllowed",
							]}
							component={ScanflowPage}
							title='Scanflow'
						/>
					}
				/>

				{/* =================== NPI LIST  ================== */}
				<Route
					exact
					path='/npi'
					element={
						<ApplicationLayout
							module='scheduling'
							checkAccess={["isReadAllowed"]}
							component={NPIPage}
							title={"NPI List"}
						/>
					}
				/>

				{/* ===============================Zip Code - Start================================ */}
				<Route
					exact
					path='/zip-codes'
					element={
						<ApplicationLayout
							module='zip'
							checkAccess={["isReadAllowed"]}
							component={ZipPage}
							title={"Zip"}
						/>
					}
				/>

				{/* ===============================Doc View ================================ */}
				<Route
					exact
					path='/doc'
					element={
						<ApplicationLayout
							module='scheduling'
							checkAccess={["isReadAllowed"]}
							component={DocView}
							title='Doc View'
						/>
					}
				/>

				{/* =================== PBFUSA  ================== */}

				<Route
					exact
					path='/patient-registrations'
					element={<PatientRegistrationPage />}
				/>
				<Route
					exact
					path='/volunteer-registrations'
					element={<VolunteerRegistrationPage />}
				/>

				<Route path='*' element={<NotFoundPage />} />
			</Routes>
		</UIProvider>
	);
};
export default App;
