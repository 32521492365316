import useQueryParams from '../../hooks/useQueryParams';
import { DEFAULT_PAGE_SIZE } from '../../utilities/constants';

const ResultsPerPage = ({  label }) => {
	const { updateQueryParam, queryParams } = useQueryParams(); 
 
	const getPerPageValue = () => {
		const pageSize = queryParams.get("pageSize") || DEFAULT_PAGE_SIZE;
		return pageSize;
	};

	return (
		<div className='flex center-left' style={{ gap: "10px" }}>
			{label && (
				<div>
					<p className='color-primary f-w-500'>{label}</p>
				</div>
			)}
			<div>
				<select
					className='primary'
					style={{ width: "80px" }}
					value={getPerPageValue()}
					onChange={(e) => {
						if (e.target.value) {
							updateQueryParam("pageSize", e.target.value);
							updateQueryParam("pageNo", 0);
						}
					}}>
					<option value='12'>12</option>
					<option value='24'>24</option>
					<option value='36'>36</option>
					<option value='48'>48</option>
				</select>
			</div>
		</div>
	);
};

export default ResultsPerPage;
