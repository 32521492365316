import FallbackErrorUI from "./components/FallbackErrorUI";

const NotAuthorizedPage = () => {
  return (
    <FallbackErrorUI
      pageTitle="Not Authorized"
      title="Access Denied."
      message="You are not authorized to view this content."
    />
  );
};

export default NotAuthorizedPage;
