import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useState } from "react";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import { API_URL } from "../../utilities/constants";
import Label from "../../components/ui/Label";
import { handleApiCall } from "../../utilities/helpers";
import { TbArrowBackUp } from "react-icons/tb";
import { MdCancel } from "react-icons/md";
import { FiArrowRightCircle } from "react-icons/fi";

const AddSpecialty = ({ setShow, callback }) => {
    const [loading, setLoading] = useState(false);

  const { addToastMessage } = useUI();

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
  });
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        name: values.name,
      };
      const response = await handleApiCall(`${API_URL}/api/1.0.0/specialty`, {
        method: "POST",
        body: payload,
      });

      if (response.responseStatus === true) {
        addToastMessage({
          status: true,
          message: "Specialty Added Successfully!",
        });
        setShow(false);
        callback();
      } else {
        addToastMessage({
          status: false,
          message:
            response.responseDescription ||
            "Something went wrong while adding specialty. Please try again.",
        });
      }
    } catch (error) {
      addToastMessage({
        status: false,
        message:
          "Something went wrong while adding specialty. Please try again.",
      });
    }
    setLoading(false);
  };

  return (
    <div className="box" id="activity-detail-box">
      <div className="flex center-left mb-3">
        <div>
          <h6 className="color-primary f-w-600"> Add Specialty</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div
            className="cursor-pointer"
            onClick={() => {
              setShow(false);
            }}
          >
            <MdCancel size={20} />
          </div>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, validateForm }) => (
          <Form>
            <table
              style={{
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <Label title="Specialty Name" required={true} />
                    <Field
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      className={`col-1-1 primary ${
                        errors.name && touched.name ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="name"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <br />
            <div className="flex space-between">
              <ButtonLightTextIcon
                type="button"
                className="light"
                title="Cancel"
                onClick={() => setShow(false)}
                icon={<TbArrowBackUp />}
              />
              <ButtonTextIcon
                type="submit"
                disabled={!values.name || loading}
                title={"Add Specialty"}
                className="mar-l-a"
                icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSpecialty;
