import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ResultsPerPage from "../../components/common/ResultsPerPage";
import { useEffect, useRef, useState } from "react";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import CustomModal from "../../components/common/CustomModal";
import { useUI } from "../../context/ui";
import useQueryParams from "../../hooks/useQueryParams";
import Label from "../../components/ui/Label";
import { GrPowerReset } from "react-icons/gr";
import { MdOutlineDeleteSweep } from "react-icons/md";

const ProcedureFilter = ({ resetFilters, callBack, isDeleteDisabled }) => {
  const debounceTimeout = useRef(null);

  const { updateQueryParam, queryParams } = useQueryParams();
  const [searchValue, setSearchValue] = useState(queryParams.get("name") || "");
  const { permissions } = useUI();

  const handleSearchChange = (value) => {
    setSearchValue(value);
 
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
 
    debounceTimeout.current = setTimeout(() => {
      queryParams.set("pageNo", 0);
      updateQueryParam("name", value);
    }, 500);
  };

  return (
    <div className="col-1-1 flex center-left filters">
      <div>
        <Label variant="filter" title='Procedure Name/CPT Code'  />
        <input
          className="primary"
          type="text"
          value={searchValue}
          placeholder="Eg: ANTXXXX"
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </div>
      <div className="flex center-left" style={{ gap: "12px" }}>
        <div>
          <Label variant="filter" title="Reset" />
          <ButtonLightTextIcon
            title="Reset"
            className="light"
            icon={
            <GrPowerReset />
            }
            onClick={() => {
              setSearchValue("");
              resetFilters();
            }}
          />
        </div>
        <div className="mar-l-a">
          <Label variant="filter" title="Per page" />
          <ResultsPerPage />
        </div>
      </div>
      <div>
        {permissions?.isDeleteAllowed && (
          <CustomModal
            selector={
              <span>
                <Label variant="filter" title="&nbsp;" />
                <ButtonTextIcon
                  title="Delete Procedure/s"
                  style={{ width: "100%" }}
                  className=""
                  disabled={isDeleteDisabled} // Disable
                  icon={
                    <MdOutlineDeleteSweep/>
                  }
                />
              </span>
            }
            content={
              <ConfirmationModal
                onConfirm={async () => {
                  await callBack();
                  return true;
                }}
                message="Are you sure you want to delete selected procedures ?"
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default ProcedureFilter;
