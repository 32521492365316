import React, { useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import PageToolbar from "../components/common/PageToolbar";
import { useNavigate } from "react-router-dom";
import { TbArrowBackUp, TbEye, TbPencilMinus } from "react-icons/tb";
import useQueryParams from "../hooks/useQueryParams";
import ButtonLightTextIcon from "../components/ui/ButtonLightTextIcon";
import {
  decodeModuleAccessFromToken,
  handleApiCall,
} from "../utilities/helpers";
import { useUI } from "../context/ui";
import { API_URL } from "../utilities/constants";
import LoaderIcon from "../components/ui/LoaderIcon";
import PopUpModal from "../components/common/PopUpModal";
import { RiDeleteBin6Line } from "react-icons/ri";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import AddPreferenceCardForm from "./preference-card/AddPreferenceCard";
import Pagination from "../components/common/Pagination";
import PreferenceCardDetails from "./preference-card/PreferenceCardDetails";
import UpdatePreferenceCard from "./preference-card/UpdatePreferenceCard";

const PreferencePage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { queryParams, location } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || 10;
  const pageNo = queryParams.get("pageNo") || 0;
  const { addToastMessage } = useUI();
  const nextUrl = decodeURIComponent(queryParams.get("next")) || "";
  const name = decodeURIComponent(queryParams.get("name")) || "";
  const [controller, setController] = useState(null);
  const [error, setError] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const permissions = decodeModuleAccessFromToken("preference-card");
  const getIdFromUrl = () => {
    const path = window.location.pathname;
    const id = path.split("/")[2];
    return id;
  };
  const id = getIdFromUrl();

  const fetchPreferenceCards = async (id) => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    setLoading(true);
    try {
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/preference-card?pageSize=${pageSize}&pageNo=${pageNo}&surgeonId=${id}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setData(response.data);
      } else {
        setError("Failed to fetch preference cards.");
        setData([]);
      }
    } catch (e) {
      setError("Failed to fetch preference cards.");
      console.error("Error:", e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) fetchPreferenceCards(id);
  }, [id, location.search]);

  const handleDelete = async (card_id) => {
    try {
      if (!card_id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting specialty. Please try again.`,
        });
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/preference-card/${card_id}`,
        {
          method: "DELETE",
        }
      );
      if (response.responseStatus === true) {
        fetchPreferenceCards(id);
        addToastMessage({
          status: true,
          message: `Preference deleted successfully.`,
        });
        return true;
      } else {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting Preference. Please try again.`,
        });
        return false;
      }
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting Preference. Please try again.`,
      });
      return false;
    }
  };
  return (
    <section id="patient">
      <PageToolbar
        title={name ? `Surgeon : ${name.toUpperCase()}` : "Preference"}
        button={
          <div className="flex center-center" style={{ gap: "10px" }}>
            {permissions?.isCreateAllowed && (
              <ButtonTextIcon
                title="Add New"
                className="mar-l-a"
                onClick={() => setShowAddForm(true)}
                icon={<FiPlusCircle />}
              />
            )}
            <ButtonLightTextIcon
              title="Cancel"
              className="mar-l-a light"
              onClick={() => navigate(nextUrl || -1)}
              icon={<TbArrowBackUp />}
            />
          </div>
        }
      />
      <div className="pad-20">
        {data && !loading && data?.list?.length > 0 && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 0px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
        {loading ? (
          <section
            style={{
              height: "100vh",
              backgroundColor: "#fafaff",
            }}
            className="flex center-center"
          >
            <LoaderIcon style={{ height: "32px", width: "32px" }} />
          </section>
        ) : data?.list?.length > 0 ? (
          <div
            className="center-left"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(268px, 1fr))",
              gap: "10px",
              overflow: "auto",
              alignItems: "stretch",
            }}
          >
            {data?.list?.map((item, index) => (
              <Card
                item={item}
                index={pageNo * pageSize + index + 1}
                key={index}
                handleDelete={handleDelete}
                permissions={permissions}
                callback={() => fetchPreferenceCards(id)}
              />
            ))}
          </div>
        ) : (
          <div
            className="flex center-center"
            style={{
              height: "400px",
            }}
          >
            <p>No cards found. Add a new card to get started!</p>
          </div>
        )}
        {data && !loading && data?.list?.length > 0 && (
          <div
            className="flex center-center"
            style={{
              padding: "10px 0px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
        {showAddForm && (
          <PopUpModal>
            <AddPreferenceCardForm
              setShowAddForm={setShowAddForm}
              id={id}
              callback={() => fetchPreferenceCards(id)}
            />
          </PopUpModal>
        )}
      </div>
    </section>
  );
};

const Card = ({ item, handleDelete, permissions, index, callback }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [showCard, setShowCard] = useState(false);
  const [updateCard, setUpdateCard] = useState(false);
  const getTitleName = (title) => {
    try {
      return JSON.parse(title)?.name || "NA";
    } catch (error) {
      return "NA";
    }
  };
  return (
    <div
      className="col-1-1 b-r-8 bg-color-faded of-hid flex"
      style={{
        boxShadow: "0 0 6px rgba(0,0,0,0.08)",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          borderLeft: "1px solid #ddd",
          borderRight: "1px solid #ddd",
        }}
      >
        <div
          className="flex pad-10 bg-color-primary "
          style={{
            backgroundColor: " #2c6487",
            justifyContent: "space-between",
            paddingLeft: "20px",
          }}
        >
          <div
            className="flex center-left of-hid"
            style={{
              textOverflow: "ellipsis",
              width: "80%",
            }}
            title={getTitleName(item?.title)}
          >
            <p
              className="f-w-500 color-white cursor-pointer of-hid"
              style={{ textOverflow: "ellipsis" }}
            >
              {getTitleName(item?.title).toUpperCase() || "NA"}
            </p>
          </div>
          <div>
            {permissions?.isDeleteAllowed && (
              <div
                className="btn-del flex center-left b-r-100 cursor-pointer color-red bg-color-light-red hover-background-red hover-color-white transition"
                style={{
                  padding: "8px",
                }}
                title="Delete"
                onClick={() => setShowConfirmation(true)}
              >
                <RiDeleteBin6Line
                  className="width-auto dis-b"
                  style={{
                    height: "16px",
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className="pad-10 flex"
          style={{
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <div>
            <p className="f-w-700 color-primary">Tools:</p>
            <div
              className="flex of-hid"
              style={{
                gap: "10px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item.tools.split(", ").map((tool, index) => (
                <p
                  className="font-size-12 pad-5 mar-0-10 color-primary dis-i-b  "
                  key={index}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "rgb(231, 231, 231)",
                  }}
                  title={tool}
                >
                  {tool.toUpperCase() || ""}
                </p>
              ))}
            </div>
          </div>
          <div
            className="mar-10-0"
            style={{
              overflowWrap: "break-word",
            }}
          >
            <p className="f-w-700 color-primary">Description:</p>
            <p
              className="f-w-300 color-primary"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={item.description}
            >
              {item.description}
            </p>
          </div>
        </div>
      </div>
      <div className="flex center-center">
        <button
          type="button"
          className="flex center-center f-w-700 cursor-pointer font-size-14 col-1-1 pad-10 color-primary"
          style={{
            backgroundColor: "rgba(73, 149, 196, 0.25)",
            borderRadius: "0 0 0 8px",
            gap: "4px",
          }}
          onClick={() => {
            setUpdateCard(true);
          }}
        >
          <TbPencilMinus />
          <span>Edit</span>
        </button>
        <button
          type="button"
          className="flex center-center f-w-700 cursor-pointer font-size-14 col-1-1 pad-10 color-primary"
          style={{
            backgroundColor: "rgba(73, 149, 196, 0.75)",
            borderRadius: "0 0px 8px 0",
            gap: "4px",
          }}
          onClick={() => {
            setShowCard(true);
          }}
        >
          <TbEye />
          <span>View</span>
        </button>
      </div>
      {showConfirmation && (
        <ConfirmationModal
          onConfirm={async () => {
            await handleDelete(item.id);
            return true;
          }}
          message={`Are you sure you want to delete "<strong style="
            word-wrap: break-word;
            overflow-wrap: break-word;
            text-overflow: ellipsis;
          ">${getTitleName(item?.title).toUpperCase()}</strong>" Preference Card?`}
          setShow={setShowConfirmation}
        />
      )}
      {showCard && (
        <PopUpModal>
          <PreferenceCardDetails
            setShow={setShowCard}
            id={item.id}
            setUpdateCard={setUpdateCard}
          />
        </PopUpModal>
      )}
      {updateCard && (
        <PopUpModal>
          <UpdatePreferenceCard
            setShowAddForm={setUpdateCard}
            id={item.id}
            callback={callback}
          />
        </PopUpModal>
      )}
    </div>
  );
};

export default PreferencePage;
