import React, { useEffect, useState } from "react";
import { backgroundColor, chartOptions, emptyLineData } from "./ChartConfig";
import LineChart from "../../components/charts/LineChart";
import DateRangeInput from "../../components/ui/DateRangeInput";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL } from "../../utilities/constants";
import { handleApiCall, jsonToQueryParams } from "../../utilities/helpers";
import MonthRangeInput from "../../components/ui/MonthRangeInput";

const PatientBar = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [yearMonth1, setYearMonth1] = useState(
    `${currentYear}-${currentMonth < 10 ? "0" : ""}${currentMonth}`
  );
  const [yearMonth2, setYearMonth2] = useState(
    `${currentYear}-${currentMonth - 1 < 10 ? "0" : ""}${currentMonth - 1}`
  );
  const [controller, setController] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setData([]);
    setLineData((prev) => ({
      ...prev,
      labels: [],
      datasets: [
        {
          ...prev.datasets[0],
          data: [],
        },
        {
          ...prev.datasets[1],
          data: [],
        },
      ],
    }));
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;

    const query = jsonToQueryParams({
      yearMonth1: yearMonth1 || null,
      yearMonth2: yearMonth2 || null,
    });
    setLoading(true);

    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/analytics/patient-onboarded?${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setData(response.data);
        const month1Data = response.data.month1.map((item) => item.count);
        const month2Data = response.data.month2.map((item) => item.count);
        const maxWeeks = Math.max(month1Data.length, month2Data.length);
        const labels = Array.from(
          { length: maxWeeks },
          (_, i) => `Week ${i + 1}`
        );

        setLineData((prev) => ({
          labels,
          datasets: [
            {
              ...prev.datasets[0],
              data: month1Data,
              label: `${yearMonth1} Patient On-Boarded`,
            },
            {
              ...prev.datasets[1],
              data: month2Data,
              label: `${yearMonth2} Patient On-Boarded`,
            },
          ],
        }));
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [yearMonth1, yearMonth2]);

  const [lineData, setLineData] = useState({
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Last Month Patient On-Boarded",
        data: [],
        backgroundColor: backgroundColor[0],
        borderColor: backgroundColor[0],
        borderWidth: 1,
        barThickness: 20,
      },
      {
        label: "This Month Patient On-Boarded",
        data: [],
        backgroundColor: backgroundColor[1],
        borderColor: backgroundColor[1],
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  });
  return (
    <div className="chart-card">
      <div
        className="flex center-center justify-between flex-row of-hid"
        style={{
          padding: "16px",
          gap: "16px",
        }}
      >
        <p className="f-w-700"> Patients On-Boarded</p>

        <div>
          <MonthRangeInput
            initialValue={{
              yearMonth1: yearMonth1 ? yearMonth1 : null,
              yearMonth2: yearMonth2 ? yearMonth2 : null,
            }}
            onApply={(v) => {
              setYearMonth1(v.yearMonth1);
              setYearMonth2(v.yearMonth2);
            }}
            onCancel={() => {
              setYearMonth1(null);
              setYearMonth2(null);
            }}
          />
        </div>
      </div>
      <div style={{ height: "300px" }}>
        {loading ? (
          <div className="flex center-center" style={{ height: "100%" }}>
            <LoaderIcon
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </div>
        ) : data.length === 0 ? (
          <LineChart data={emptyLineData} options={{ chartOptions }} />
        ) : (
          <LineChart
            data={lineData}
            options={{
              ...chartOptions,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PatientBar;
