import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../../utilities/constants";
import VideoURL from "../../images/icons/video.mp4";
import LogoImg from "../../images/icons/logo-full.png";
import LoaderIcon from "../../components/ui/LoaderIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { handleApiCall } from "../../utilities/helpers";
import { TbLogin2 } from "react-icons/tb";
import { FaKey } from "react-icons/fa6";

const LoginPage = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [openDirectLogin, setOpenDirectLogin] = useState(false);
	const [inputVal, setInputVal] = useState("");
	useDocumentTitle("Login");

	const handleLogin = async () => {
		setLoading(true);
		setStatus("");
		try {
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/login/client/azure`,
				{
					method: "POST",
					body: {
						redirectURL: window.location.origin + "/auth/callback",
						state: "web",
					},
				}
			);
			if (response.responseStatus === true) {
				window.location.href = response.data;
			} else {
				setStatus("Something went wrong please try again.");
			}
		} catch (e) {
			console.log("Error occurred", e);
		} finally {
			setLoading(false);
		}
	};

	const getClientName = () => {
		let url = window.location.href;
		url = url.split(".");

		let firstPart = url[0].replace("http://", "").replace("https://", "");
		if (
			firstPart.indexOf("/") < 0 &&
			firstPart !== "usp" &&
			firstPart !== "staging" &&
			firstPart !== "dev" &&
			firstPart !== "web"
		) {
			return firstPart;
		} else {
			if (firstPart === "usp") {
				return "Prod Master";
			} else if (firstPart === "staging") {
				return "Stage Master";
			} else if (firstPart === "dev") {
				return "Dev Master";
			} else if (firstPart === "web") {
				return "Web Master";
			} else {
				return firstPart;
			}
		}
	};

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (!token) {
			localStorage.clear();
		} else {
			navigate("/activities");
		}
	}, [navigate]);

	function isValidEmail(email) {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	const submitDirectIP = async () => {
		if (inputVal !== "" && isValidEmail(inputVal)) {
			setLoading(true);
			try {
				const resp = await fetch(
					API_URL + `/api/1.0.0/login/client/azure/direct-login`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"X-JWT-Assertion": SYSTEM_TOKEN,
						},
						body: JSON.stringify({
							email: inputVal,
							platform: "WEB",
						}),
					}
				);
				if (resp.ok) {
					const response = await resp.json();
					if (response) {
						localStorage.setItem("token", response.data.token);
						navigate("/activities");
					}
				}
			} catch (e) {
				console.log("Error", e);
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<section id='login-v2' className='flex center-center'>
			<video
				className='bg-video'
				playsInline={true}
				controls={false}
				autoPlay={true}
				muted={true}
				loop={true}>
				<source src={VideoURL} type='video/mp4' />
			</video>
			<div>
				<div className='form'>
					<img className='logo' src={LogoImg} alt='coremax' />
					<h5
						className='color-primary f-w-700 t-a-c'
						style={{
							marginBottom: "10px",
						}}>
						Login to your account.
					</h5>
					<p className='color-light'>
						You will be redirected to microsoft online portal. Enter
						the email associated with CoreMax to confirm your login.
					</p>
					<hr />
					<div>
						<ButtonTextIcon
							onClick={() => handleLogin()}
							title='Click to login'
							style={{
								width: "100%",
								height: "52px",
							}}
							disabled={loading}
							icon={
								<>
									{loading ? (
										<LoaderIcon />
									) : (
										<TbLogin2
											style={{
												background: "transparent",
											}}
										/>
									)}
								</>
							}
						/>
					</div>
					{status && (
						<>
							<p
								className='color-secondary l-h-1-2'
								style={{ paddingTop: "15px" }}>
								{status}
							</p>
						</>
					)}
					{getClientName() !== "Prod Master" && openDirectLogin && (
						<form
							onSubmit={(e) => {
								e.preventDefault();
								submitDirectIP();
							}}>
							<div
								className='flex center-left'
								style={{ paddingTop: "12px" }}>
								<input
									style={{
										flex: "1",
										fontSize: "12px",
										border: "solid 1px #f0f0f0",
										lineHeight: "36px",
										height: "36px",
										padding: "0 12px",
										fontFamily: '"Inter", sans-serif',
									}}
									value={inputVal}
									placeholder='Enter Email'
									onChange={(e) => {
										setInputVal(e.target.value);
									}}
								/>
								<ButtonTextIcon
									type='submit'
									disabled={loading}
									style={{
										borderRadius: "0",
										padding: "0 0 0 12px",
									}}
									icon={
										<FaKey />

									}
								/>
							</div>
						</form>
					)}
				</div>
				<p
					className='t-a-c color-white'
					style={{
						position: "relative",
						zIndex: 3,
						paddingTop: "12px",
						fontSize: "11px",
					}}>
					<span style={{ opacity: 0.4 }}>Client: </span>
					<span>{getClientName()}</span>
				</p>
				{getClientName() !== "Prod Master" && (
					<p
						className='color-white t-a-c'
						style={{
							position: "relative",
							zIndex: 3,
							paddingTop: "0px",
							fontSize: "11px",
						}}
						onClick={() => setOpenDirectLogin(!openDirectLogin)}>
						<span className='cursor-pointer'>
							{`${openDirectLogin ? "Close" : "Open"} Direct Login`}
						</span>
					</p>
				)}
			</div>
		</section>
	);
};

export default LoginPage;
