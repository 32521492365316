import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useState } from "react";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import zipcodes from "zipcodes";
import states from "./State.json";
import { API_URL } from "../../utilities/constants";
import Label from "../../components/ui/Label";
import LikeInput from "../../components/ui/LikeInput";
import { handleApiCall } from "../../utilities/helpers";
import { TbArrowBackUp } from "react-icons/tb";
import { FiArrowRightCircle } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";
import { MdCancel } from "react-icons/md";

const AddForm = ({ setShow, callback }) => {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { addToastMessage } = useUI();

  const initialValues = {
    zip: "",
    city: "",
    state: "",
    stateCode: "",
  };

  const validationSchema = Yup.object().shape({
    zip: Yup.string()
      .matches("^[0-9]{5}(?:-[0-9]{4})?$", "Invalid Zip")
      .required("Zip code is required."),
    city: Yup.string().required("City is required."),
    state: Yup.string().required("State is required."),
  });

  const handleSearch = async (zip, setFieldValue) => {
    setLoadingSearch(true);
    try {
      const dbResponse = await handleApiCall(
        `${API_URL}/api/1.0.0/reference/zip-details/${zip}`
      );

      if (dbResponse.responseStatus === true) {
        const dbData = dbResponse?.data;
        setFieldValue("city", dbData?.city?.name || null);
        setFieldValue("stateCode", dbData?.state?.code || null);
        setFieldValue("state", dbData?.state?.name || null);
        setLoadingSearch(false);
        return;
      }

      const zipData = zipcodes.lookup(zip);
      if (zipData) {
        setFieldValue("city", zipData?.city || null);
        setFieldValue("stateCode", zipData?.state || null);
        setFieldValue("state", states[zipData?.state] || "-");
      } else {
        setFieldValue("city", "");
        setFieldValue("state", "");
        setFieldValue("stateCode", "");
        addToastMessage({
          status: false,
          message: "Zip code not found. Please enter details manually.",
        });
      }
    } catch (error) {
      addToastMessage({
        status: false,
        message: "Something went wrong while searching for the zip code.",
      });
    }
    setLoadingSearch(false);
  };

  const handleSubmit = async (values) => {
    setLoadingSubmit(true);
    try {
      const payload = {
        stateCode: values.stateCode,
        cityName: values.city,
        zipCode: values.zip,
      };
      const response = await handleApiCall(`${API_URL}/api/1.0.0/zip`, {
        method: "POST",
        body: payload,
      });

      if (response.responseStatus === true) {
        addToastMessage({
          status: true,
          message: "Zip Code Added Successfully!",
        });
        setShow(false);
        callback();
      } else {
        addToastMessage({
          status: false,
          message:
            response.responseDescription === "Invalid state code"
              ? "Please insert a Valid state."
              : response.responseDescription ||
                "Something went wrong while adding zip code. Please try again.",
        });
      }
    } catch (error) {
      addToastMessage({
        status: false,
        message:
          "Something went wrong while adding zip code. Please try again.",
      });
    }
    setLoadingSubmit(false);
  };

  return (
    <div className="box" id="activity-detail-box">
      <div className="flex center-left mb-3">
        <div>
          <h6 className="color-primary f-w-600">Zip Add</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div
            className="cursor-pointer"
            onClick={() => {
              setShow(false);
            }}
          >
            <MdCancel  size={28}/>
          </div>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, validateForm }) => (
          <Form>
            <table
              style={{
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      padding: "5px",
                    }}
                  >
                    <Label title="Zip Code" required={true} />
                    <Field
                      type="text"
                      placeholder="Eg. 12345"
                      name="zip"
                      className={`col-1-1 primary ${
                        errors.zip && touched.zip ? "error" : ""
                      }`}
                      onChange={async (e) => {
                        const value = e.target.value;
                        await setFieldValue("zip", value);
                        await setFieldValue("city", "");
                        await setFieldValue("state", "");
                        await setFieldValue("stateCode", "");
                        await validateForm();
                      }}
                    />
                    <ErrorMessage
                      name="zip"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td
                    style={{
                      padding: "5px",
                    }}
                  >
                    <Label title={`\u00A0`} />
                    <ButtonTextIcon
                      type="button"
                      title={"Search"}
                      style={{ width: "100%", height: "100%" }}
                      disabled={!values.zip || loadingSearch || errors.zip}
                      onClick={() => handleSearch(values.zip, setFieldValue)}
                      icon={!loadingSearch ? <CiSearch /> : <LoaderIcon />}
                    />
                    {errors.zip && touched.zip && <p>&nbsp;</p>}
                  </td>
                </tr>
                <tr>
                  <td
                    width={"50%"}
                    style={{
                      padding: "5px",
                    }}
                  >
                    <Label title="City" />
                    <Field
                      type="text"
                      name="city"
                      placeholder="Enter City"
                      className={`col-1-1 primary ${
                        errors.city && touched.city ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="city"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td
                    style={{
                      padding: "5px",
                    }}
                  >
                    <Label title="State" />
                    <Field
                      type="text"
                      name="state"
                      placeholder="Enter State"
                      className={`col-1-1 primary ${
                        errors.state && touched.state ? "error" : ""
                      }`}
                      onChange={(e) => {
                        const stateName = e.target.value;
                        setFieldValue("state", stateName);

                        // Find the state code from JSON
                        const stateCode = Object.keys(states).find(
                          (code) =>
                            states[code].toLowerCase() ===
                            stateName.toLowerCase()
                        );

                        if (stateCode) {
                          setFieldValue("stateCode", stateCode);
                        } else {
                          setFieldValue("stateCode", "");
                        }
                      }}
                    />
                    <ErrorMessage
                      name="state"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <br />
            <div className="flex space-between">
              <ButtonLightTextIcon
                type="button"
                className="light"
                title="Cancel"
                onClick={() => setShow(false)}
                icon={<TbArrowBackUp />}
              />
              <ButtonTextIcon
                type="submit"
                disabled={
                  !values.zip || !values.city || !values.state || loadingSubmit
                }
                title={"Add Zip"}
                className="mar-l-a"
                icon={loadingSubmit ? <LoaderIcon /> : <FiArrowRightCircle />}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddForm;
