import {
	formatServerDate,
	handleApiCall,
	labelAssistantsFunc,
	labelPatientsFunc,
	getAllowedFields,
} from "../../utilities/helpers";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import {
	API_URL,
	BILL_STATUS_OPTTIONS,
	CLAIM_STATUS_DROP_DOWN_OPTIONS,
	FIELD_ALLOWED_SCANFLOW,
	getLabelByValue,
	SUGGESTED_COMMENTS,
} from "../../utilities/constants";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { useUI } from "../../context/ui";
import PaginatedDropdown, {
	mapOptions,
} from "../../components/ui/PaginatedDropdown";
import { useScanflow } from "./scanflow-context";
import LoaderIcon from "../../components/ui/LoaderIcon";
import AddPatientForm from "./AddPatientForm";
import useQueryParams from "../../hooks/useQueryParams";
import DatePickerInput from "../../components/ui/DatePickerInput";
import LikeInput from "../../components/ui/LikeInput";
import { CiWarning } from "react-icons/ci";

const ScanflowForm = ({ CloseTaskdetails }) => {
	// const isNotCompleted = !data?.isCompleted || false;
	const isNotCompleted = true;
	const { updateQueryParam } = useQueryParams();
	const { task: data, refreshHandler, handleReOpen } = useScanflow();
	const {
		addToastMessage,
		profile: { id: userId, type: role },
	} = useUI();

	const [loading, setLoading] = useState(false);
	const labelFacilityFunc = (o) => `${o.facilityName}`;
	const labelInsurancesFunc = (o) => `${o.description}${o.addressLine1 ? ', ' + o.addressLine1.trim() : ''}`;
	const labelPayToFunc = (o) => `${o.payToName}`;
	const allowedFields = getAllowedFields(role);
	const isFieldDisabled = (fieldName) => {
		return !allowedFields.includes(fieldName);
	};
	const [initialData, setInitialData] = useState({});
	const [initialScanFlowValues, setInitialScanFlowValues] = useState({
		minFieldsRequired: [],
		type: "",
		errorType: "",
		statusId: "",
		serviceDate: "",
		patient: null,
		assistant: null,
		facility: null,
		insurance: null,
		codes: "",
		patientChartNo: "",
		billStatus: "",
		claimStatus: "",
		practice: "",
		comment: "",
		isCompleted: false,
	});

	useEffect(() => {
		const updatedInitialScanFlowValues = {
			minFieldsRequired: data?.stepsConfig?.minFieldsRequired
				? JSON.parse(data.stepsConfig.minFieldsRequired)
				: [],
			type: "",
			statusId: "",
			errorType: "",
			serviceDate:
				data?.patientActivity?.patientActivity?.event_start_date || "",
			patient: data?.patientActivity?.patientActivity?.patient
				? {
						value: data?.patientActivity?.patientActivity?.patient
							.id,
						label: [
							data?.patientActivity?.patientActivity?.patient
								?.firstName ?? "",
							data?.patientActivity?.patientActivity?.patient
								?.middleName ?? "",
							data?.patientActivity?.patientActivity?.patient
								?.lastName ?? "",
						]
							.filter(Boolean)
							.join(" "),
					}
				: null,
			assistant: data?.patientActivity?.patientActivity
				?.event_surgical_assitant
				? {
						value: data?.patientActivity?.patientActivity
							?.event_surgical_assitant.id,
						label:
							data?.patientActivity?.patientActivity
								?.event_surgical_assitant.name +
							" " +
							data?.patientActivity?.patientActivity
								?.event_surgical_assitant.lastName +
							(data?.patientActivity?.patientActivity
								?.event_surgical_assitant?.credential
								? ", " +
									data?.patientActivity?.patientActivity
										?.event_surgical_assitant.credential
								: ""),
					}
				: null,
			practice: data?.patientActivity?.payToAdmin
				? {
						value: data?.patientActivity?.payToAdmin?.id,
						label:
							data?.patientActivity?.payToAdmin?.payToName || "",
					}
				: null,
			facility: data?.patientActivity?.patientActivity?.event_facility
				? {
						value: data?.patientActivity?.patientActivity
							?.event_facility.id,
						label: data?.patientActivity?.patientActivity
							?.event_facility.facilityName,
					}
				: null,
			insurance: data?.patientActivity?.patientActivity?.insurance
				? {
						value: data?.patientActivity?.patientActivity?.insurance
							.id,
						label: data?.patientActivity?.patientActivity?.insurance
							.description,
					}
				: null,
			codes: data?.patientActivity?.patientActivity?.cpt_codes || "",
			patientChartNo:
				data?.patientActivity?.patientActivity?.patient_chart_no || "",
			billStatus: getLabelByValue(
				data?.patientActivity?.patientActivity?.billStatus || ""
			)
				? data?.patientActivity?.patientActivity?.billStatus
				: "",
			claimStatus:
				data?.patientActivity?.patientActivity?.claimStatus || "",
			comment: data?.comment || "",
			isCompleted: data?.isCompleted || false,
		};
		setInitialScanFlowValues(updatedInitialScanFlowValues);
		setInitialData(data);
	}, [data]);

	const isMandatory = (fieldName) => {
		const userConfig = FIELD_ALLOWED_SCANFLOW.find(
			(config) => config.userType === role
		);
		if (userConfig) {
			return userConfig.mandatoryFields.includes(fieldName);
		}
		return false;
	};

	const validationSchema = Yup.object({
		serviceDate: Yup.string().test(
			"serviceDate-required",
			"Service date is required",
			function (value) {
				if (isMandatory("serviceDate")) {
					return !!value;
				}
				return true;
			}
		),
		practice: Yup.mixed()
			.nullable()
			.test(
				"practice-required",
				"practice is required",
				function (value) {
					if (isMandatory("practice")) {
						return !!value;
					}
					return true;
				}
			),
		comment: Yup.string().test(
			"comment-required",
			"Comment is required",
			function (value) {
				if (isMandatory("comment")) {
					return !!value;
				}
				return true;
			}
		),
		patient: Yup.mixed()
			.nullable()
			.test("patient-required", "Patient is required", function (value) {
				if (isMandatory("patient")) {
					return value !== null && value.value;
				}
				return true;
			}),
		assistant: Yup.mixed()
			.nullable()
			.test(
				"assistant-required",
				"Assistant is required",
				function (value) {
					if (isMandatory("assistant")) {
						return value !== null && value.value;
					}
					return true;
				}
			),
		facility: Yup.mixed()
			.nullable()
			.test(
				"facility-required",
				"Facility is required",
				function (value) {
					if (isMandatory("facility")) {
						return value !== null && value.value;
					}
					return true;
				}
			),
		insurance: Yup.mixed()
			.nullable()
			.test(
				"insurance-required",
				"Insurance is required",
				function (value) {
					if (isMandatory("insurance")) {
						return value !== null && value.value;
					}
					return true;
				}
			),
		patientChartNo: Yup.string().test(
			"patientChartNo-required",
			"Patient chart no. is required",
			function (value) {
				if (isMandatory("patientChartNo")) {
					return !!value;
				}
				return true;
			}
		),
		codes: Yup.string().test(
			"codes-required",
			"Codes is required",
			function (value) {
				if (isMandatory("codes")) {
					return !!value;
				}
				return true;
			}
		),
		billStatus: Yup.string().test(
			"billStatus-required",
			"Bill status is required",
			function (value) {
				if (isMandatory("billStatus")) {
					return !!value;
				}
				return true;
			}
		),
		claimStatus: Yup.string().test(
			"claimStatus-required",
			"Claim status is required",
			function (value) {
				if (isMandatory("claimStatus")) {
					return !!value;
				}
				return true;
			}
		),
	});

	const handleScanFlowSubmit = async (values) => {
		setLoading(true);
		try {
			let body = {
				userId: userId,
				stepExecutionId: data.id,
				statusId: values.statusId,

				billStatus: values?.billStatus || null,
				serviceDate: values?.serviceDate || null,
				assistantId: values?.assistant?.value || null,
				patientId: values?.patient?.value || null,
				facilityId: values?.facility?.value || null,
				insuranceId: values?.insurance?.value || null,
				patientChartNo: values?.patientChartNo || null,
				practiceId: values?.practice?.value || null,
				codes: values.codes || null,
				claimStatus: values.claimStatus || null,
				isCompleted: values.isCompleted || false,
				metadata: {
					comment: values.comment || null,
					retrivalData: initialData || null,
				},
			};
			if (
				body?.metadata?.retrivalData &&
				body?.metadata?.retrivalData.logs
			) {
				delete body?.metadata?.retrivalData?.logs;
			}
			const response = await handleApiCall(
				API_URL + `/api/1.0.1/scanflow-v2/task/${data.id}`,
				{
					method: "POST",
					body: body,
				}
			);
			if (response.responseStatus === true) {
				addToastMessage({
					status: true,
					message: `Task ${values.type} successfully.`,
				});
				updateQueryParam("id");
				refreshHandler();
			} else {
				addToastMessage({
					status: false,
					message:
						response?.responseDescription ||
						`Something went wrong while ${values.errorType} task. Please try again.`,
				});
			}
		} catch (e) {
			console.log("error", e);
			addToastMessage({
				status: false,
				message: `Something went wrong while ${values.errorType} task. Please try again.`,
			});
		} finally {
			setLoading(false);
		}
	};

	const FieldTitle = ({ title }) => {
		return (
			<p
				className='color-primary f-w-300 l-h-1'
				style={{ fontSize: "13px", paddingBottom: "8px" }}>
				{title}
			</p>
		);
	};

	const FieldWrapper = ({ title, children }) => (
		<div style={{ width: "100%", paddingBottom: "10px" }}>
			<FieldTitle title={title} />
			{children}
		</div>
	);

	const BillStatusField = ({ errors, touched }) => (
		<>
			<Field
				as='select'
				name='billStatus'
				className={`primary ${
					errors.billStatus && touched.billStatus ? "error" : ""
				} ${isFieldDisabled("billStatus") ? "cursor-not-allowed" : "cursor-pointer"}`}
				disabled={isFieldDisabled("billStatus")}>
				<option value=''>Select Bill Status</option>
				{BILL_STATUS_OPTTIONS.map((o) => (
					<option key={o.value} value={o.value}>
						{o.label}
					</option>
				))}
			</Field>
			<ErrorMessage
				name='billStatus'
				component='p'
				className='error-messages'
			/>
		</>
	);
	const ClaimStatusField = ({ errors, touched }) => (
		<>
			<Field
				as='select'
				name='claimStatus'
				className={`primary ${
					errors.claimStatus && touched.claimStatus ? "error" : ""
				} ${isFieldDisabled("claimStatus") ? "cursor-not-allowed" : "cursor-pointer"}`}
				disabled={isFieldDisabled("claimStatus")}>
				<option value=''>Select Claim Status</option>
				{CLAIM_STATUS_DROP_DOWN_OPTIONS.map((o) => (
					<option key={o.value} value={o.value}>
						{o.label}
					</option>
				))}
			</Field>
			<ErrorMessage
				name='claimStatus'
				component='p'
				className='error-messages'
			/>
		</>
	);

	return (
		<Formik
			initialValues={initialScanFlowValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={handleScanFlowSubmit}>
			{({
				values,
				setFieldValue,
				errors,
				touched,
				handleSubmit,
				validateForm,
				setFieldTouched,
			}) => (
				<Form>
					<div>
						{/* Bill  */}
						<FieldWrapper
							title={
								<span>
									Bill Status
									{isNotCompleted &&
									isMandatory("billStatus") ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							<BillStatusField
								userId={userId}
								isNotCompleted={isNotCompleted}
								role={role}
								data={data}
								errors={errors}
								touched={touched}
								values={values}
							/>
						</FieldWrapper>

						{/*  Service Date */}
						<FieldWrapper
							title={
								<span>
									Service Date
									{isNotCompleted &&
									isMandatory("serviceDate") ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{isNotCompleted &&
							!isFieldDisabled("serviceDate") ? (
								<>
									<DatePickerInput
										callback={(v) => {
											setFieldValue("serviceDate", v);
										}}
										value={values.serviceDate}
										error={
											errors.serviceDate &&
											touched.serviceDate
										}
										setTouched={() =>
											setFieldTouched("serviceDate", true)
										}
										disabled={isFieldDisabled(
											"serviceDate"
										)}
									/>
									<ErrorMessage
										name='serviceDate'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={
										values.serviceDate
											? formatServerDate(
													values.serviceDate
												)
											: "-"
									}
								/>
							)}
						</FieldWrapper>

						{/* Patient */}
						<FieldWrapper
							title={
								<span>
									Patient
									{isNotCompleted &&
									isMandatory("patient") ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{isNotCompleted && !isFieldDisabled("patient") ? (
								<>
									<PaginatedDropdown
										url={`${API_URL}/api/1.0.0/reference/patients?`}
										mapOptions={(records) =>
											mapOptions(
												records,
												"id",
												labelPatientsFunc
											)
										}
										error={
											errors.patient && touched.patient
										}
										setTouched={() =>
											setFieldTouched("patient", true)
										}
										placeHolder=''
										selectedValue={values.patient}
										onChange={(v) => {
											setFieldValue("patient", v);
											if (v?.o?.serviceDate) {
												setFieldValue(
													"serviceDate",
													v.o.serviceDate
												);
											}
											if (v?.o?.patientChartNo) {
												setFieldValue(
													"patientChartNo",
													v.o.patientChartNo
												);
											}
										}}
									/>
									<ErrorMessage
										name='patient'
										component='p'
										className='error-messages'
									/>
									<AddPatientForm
										callback={(v) => {
											setFieldValue("patient", {
												label: `${v?.firstName?.toUpperCase()} ${v?.middleName?.toUpperCase()} ${v?.lastName?.toUpperCase()}`,
												value: v?.id,
											});
											if (!values.insurance) {
												const insuranceTypes = [
													"primaryInsurance",
													"secondaryInsurance",
													"tertiaryInsurance",
												];
												for (const type of insuranceTypes) {
													if (v?.[type]) {
														setFieldValue(
															"insurance",
															{
																label: v[type]
																	.description,
																value: v[type]
																	.id,
															}
														);
														break;
													}
												}
											}
										}}
									/>
								</>
							) : (
								<LikeInput
									title={values?.patient?.label || "-"}
								/>
							)}
						</FieldWrapper>

						{/* Assistant */}
						<FieldWrapper
							title={
								<span>
									Assistant
									{isNotCompleted &&
									isMandatory("assistant") ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{isNotCompleted && !isFieldDisabled("assistant") ? (
								<>
									<PaginatedDropdown
										url={`${API_URL}/api/1.0.0/reference/assistants?`}
										mapOptions={(records) =>
											mapOptions(
												records,
												"id",
												labelAssistantsFunc
											)
										}
										error={
											errors.assistant &&
											touched.assistant
										}
										setTouched={() =>
											setFieldTouched("assistant", true)
										}
										placeHolder=''
										selectedValue={values.assistant}
										onChange={(v) =>
											setFieldValue("assistant", v)
										}
									/>
									<ErrorMessage
										name='assistant'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={values?.assistant?.label || "-"}
								/>
							)}
						</FieldWrapper>
						{/* practice */}
						<FieldWrapper
							title={
								<span>
									Pay To
									{isNotCompleted &&
									isMandatory("practice") ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{isNotCompleted && !isFieldDisabled("practice") ? (
								<>
									<PaginatedDropdown
										url={`${API_URL}/api/1.0.0/reference/practice?`}
										mapOptions={(records) =>
											mapOptions(
												records,
												"id",
												labelPayToFunc
											)
										}
										setTouched={() =>
											setFieldTouched("practice", true)
										}
										error={
											errors.practice && touched.practice
										}
										placeHolder='Search'
										selectedValue={values.practice}
										onChange={(v) => {
											setFieldValue("practice", v);
										}}
									/>
									<ErrorMessage
										name='practice'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={values?.practice?.label || "-"}
								/>
							)}
						</FieldWrapper>

						{/* Facility */}
						<FieldWrapper
							title={
								<span>
									Facility
									{isNotCompleted &&
									isMandatory("facility") ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{isNotCompleted && !isFieldDisabled("facility") ? (
								<>
									<PaginatedDropdown
										url={`${API_URL}/api/1.0.0/reference/facility?`}
										mapOptions={(records) =>
											mapOptions(
												records,
												"id",
												labelFacilityFunc
											)
										}
										error={
											errors.facility && touched.facility
										}
										setTouched={() =>
											setFieldTouched("facility", true)
										}
										placeHolder=''
										selectedValue={values.facility}
										onChange={(v) =>
											setFieldValue("facility", v)
										}
									/>
									<ErrorMessage
										name='facility'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={values?.facility?.label || "-"}
								/>
							)}
						</FieldWrapper>

						{/* Insurance Field */}
						<FieldWrapper
							title={
								<span>
									Insurance
									{isNotCompleted &&
									isMandatory("insurance") ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{isNotCompleted && !isFieldDisabled("insurance") ? (
								<>
									<PaginatedDropdown
										url={`${API_URL}/api/1.0.0/reference/insurances?`}
										mapOptions={(records) =>
											mapOptions(
												records,
												"id",
												labelInsurancesFunc
											)
										}
										error={
											errors.insurance &&
											touched.insurance
										}
										setTouched={() =>
											setFieldTouched("insurance", true)
										}
										placeHolder=''
										selectedValue={values.insurance}
										onChange={(v) =>
											setFieldValue("insurance", v)
										}
									/>
									<ErrorMessage
										name='insurance'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={values?.insurance?.label || "-"}
								/>
							)}
						</FieldWrapper>

						{/* Patient Chart Number */}
						<FieldWrapper
							title={
								<span>
									Patient Chart No.
									{isNotCompleted &&
									isMandatory("patientChartNo") ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{isNotCompleted &&
							!isFieldDisabled("patientChartNo") ? (
								<>
									<Field
										name='patientChartNo'
										type='text'
										placeholder='12345 *'
										className={`primary  ${
											errors.patientChartNo &&
											touched.patientChartNo
												? "error"
												: ""
										}`}
									/>
									<ErrorMessage
										name='patientChartNo'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={values.patientChartNo || "-"}
								/>
							)}
						</FieldWrapper>

						{/* Codes */}
						<FieldWrapper
							title={
								<span>
									Codes
									{isNotCompleted && isMandatory("codes") ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{isNotCompleted && !isFieldDisabled("codes") ? (
								<>
									<Field
										as='textarea'
										name='codes'
										placeholder='12345,4567,... *'
										className={`primary ${
											errors.codes && touched.codes
												? "error"
												: ""
										}`}
									/>
									<ErrorMessage
										name='codes'
										component='p'
										className='error-messages'
									/>
								</>
							) : (
								<LikeInput
									title={values.codes || "-"}
									style={{ height: "100px" }}
								/>
							)}
						</FieldWrapper>

						{/* Claim Status */}
						<FieldWrapper
							title={
								<span>
									Claim Status
									{isNotCompleted &&
									isMandatory("claimStatus") ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							<ClaimStatusField
								userId={userId}
								isNotCompleted={isNotCompleted}
								role={role}
								data={data}
								errors={errors}
								touched={touched}
								values={values}
							/>
						</FieldWrapper>

						{/* comment */}
						<FieldWrapper
							title={
								<span>
									Comment
									{isNotCompleted &&
									isMandatory("comment") ? (
										<span style={{ color: "red" }}>
											&nbsp;*
										</span>
									) : (
										""
									)}
								</span>
							}>
							{isNotCompleted && !isFieldDisabled("comment") ? (
								<>
									<Field
										as='textarea'
										name='comment'
										placeholder='Enter your comment'
										className={`primary ${
											errors.comment && touched.comment
												? "error"
												: ""
										}`}
									/>
									<ErrorMessage
										name='comment'
										component='p'
										className='error-messages'
									/>
									<div
										className='flex center-left cursor-pointer'
										style={{
											gap: "6px",
											padding: "10px 0",
											flexWrap: "wrap",
										}}>
										{SUGGESTED_COMMENTS?.map((c, i) => (
											<div
												key={i}
												className='flex center-center b-r-20'
												style={{
													background:
														"rgb(240, 240, 240)",
													padding: "10px 12px",
													width: "48%",
													marginBottom: "8px",
												}}
												onClick={() => {
													setFieldValue("comment", c);
												}}>
												<p
													className='l-h-1 f-w-300 color-primary'
													style={{
														width: "100px",
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow:
															"ellipsis",
													}}>
													{c}
												</p>
											</div>
										))}
									</div>
								</>
							) : (
								<LikeInput
									title={values.comment || "-"}
									style={{ height: "100px" }}
								/>
							)}
						</FieldWrapper>

						{isNotCompleted && !isFieldDisabled("isCompleted") && (
							<FieldWrapper title=''>
								<div className='flex'>
									<label
										className='flex center-left cursor-pointer'
										style={{
											gap: "12px",
										}}>
										<Field
											type='checkbox'
											name='isCompleted'
											className={`primary ${
												errors.isCompleted &&
												touched.isCompleted
													? "error"
													: ""
											}`}
										/>
										<p className='color-primary f-w-300 l-h-1'>
											Mark as completed
										</p>
									</label>
								</div>
								<div
									className='flex center-center'
									style={{
										marginTop: "10px",
										display: "flex",
										alignItems: "center",
										gap: "5px",
										padding: "8px",
										border: "1px solid #d97706",
										backgroundColor: "#FEF3C7",
										borderRadius: "6px",
									}}>
									<span
										style={{
											color: "red",
											fontSize: "18px",
										}}>
										<CiWarning color='#d97706' />
									</span>
									<p
										className='f-w-300 l-h-1'
										style={{
											color: "#D97706",
											margin: 0,
										}}>
										Please fill all required fields before
										marking as completed.
									</p>
								</div>

								<ErrorMessage
									name='isCompleted'
									component='p'
									className='error-messages'
								/>
							</FieldWrapper>
						)}
						<div
							className='flex center-left '
							style={{ gap: "5px", paddingBlock: "12px" }}>
							<p>&nbsp;</p>
							{loading && (
								<>
									<LoaderIcon
										style={{
											width: "10px",
											height: "10px",
										}}
									/>
									<p
										className='color-primary f-w-300'
										style={{ fontSize: "12px" }}>
										Saving your changes, please wait...
									</p>
								</>
							)}
						</div>

						{isNotCompleted && (
							<div>
								<div className='col-1-1'>
									<ButtonTextIcon
										title={
											!values.isCompleted
												? "Update"
												: "Update & Complete"
										}
										style={{ width: "100%" }}
										type='button'
										onClick={async () => {
											await setFieldValue("statusId", 5);
											await setFieldValue(
												"type",
												"completed"
											);
											await setFieldValue(
												"errorType",
												"completing"
											);
											const errors = await validateForm();
											handleSubmit();
										}}
										disabled={loading}
									/>
								</div>
							</div>
						)}
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ScanflowForm;
