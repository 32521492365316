import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import Pagination from "../components/common/Pagination";
import PracticeFilter from "./practice/PracticeFilter";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import { handleApiCall, jsonToQueryParams } from "../utilities/helpers";
import CustomModal from "../components/common/CustomModal";
import { useUI } from "../context/ui";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import PracticeDetails from "./practice/PracticeDetails";
import useQueryParams from "../hooks/useQueryParams";
import PageToolbar from "../components/common/PageToolbar";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import { FiPlusCircle } from "react-icons/fi";

const PracticePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const { addToastMessage } = useUI();
  const { permissions } = useUI();
  const [controller, setController] = useState(null);
  const search = queryParams.get("search") || "";
  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("pageNo", 0);
  };

  const getPracticeApi = async () => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    setLoading(true);
    try {
      const query = jsonToQueryParams({
        search: search,
      });
      const response = await handleApiCall(
        API_URL +
          `/api/1.0.0/reference/practice?pageSize=${pageSize}&pageNo=${pageNo}&${query}&sortOrder=DESC`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus == true) {
        setData(response.data);
        setLoading(false);
      } else {
        setData(null);
      }

      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    getPracticeApi();
  }, [location.search]);

  const handleDelete = async (id) => {
    try {
      if (!id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting practice. Please try again.`,
        });
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/reference/practice/${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.responseStatus == true) {
        getPracticeApi();
        addToastMessage({
          status: true,
          message: `Practice deleted successfully.`,
        });
        return true;
      }
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting practice. Please try again.`,
      });
      return false;
    } catch (error) {
      console.error("Error deleting patient:", error);
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting practice. Please try again.`,
      });
      return false;
    }
  };

  return (
    <section id="category">
      <PageToolbar
        title="Practices"
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => navigate("/add-practice")}
             icon={<FiPlusCircle />}
            />
          )
        }
      />

      <PracticeFilter
        resetFilters={() => {
          resetFilters();
        }}
      />

      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.records.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>NPI</th>
                <th>Name</th>
                <th> Taxonomy</th>
                <th>EIN Number</th>
                {/* <th>Regions/Markets</th> */}
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.records.map((data, index) => (
                  <tr key={index}>
                    <td>{pageNo * pageSize + index + 1}</td>
                    <td>{data.payToNpi}</td>
                    <td>{data.payToName}</td>
                    <td>{data?.taxonomyCode ? data?.taxonomyCode : ""}</td>
                    <td>
                      {data?.taxIdentification ? data?.taxIdentification : ""}
                    </td>
                    {/* <td>{data.payRegion}</td> */}

                    <td>
                      <div
                        className="flex center-center"
                        style={{ gap: "12px" }}
                      >
                        {permissions?.isReadAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="view"
                                  title="View"
                                />
                              }
                              content={<PracticeDetails id={data?.id} />}
                            />
                          </div>
                        )}
                        {permissions?.isUpdateAllowed && (
                          <div>
                            <NavLink
                              to={
                                data?.id
                                  ? `/edit-practice?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                                  : "#"
                              }
                              state={data?.id ? { id: data.id } : null}
                            >
                              <ButtonTertiary actionType="edit" title="Edit" />
                            </NavLink>
                          </div>
                        )}

                        {permissions?.isDeleteAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="delete"
                                  title="Delete"
                                />
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    await handleDelete(data.id);
                                    return true;
                                  }}
                                  message="Are you sure you want to delete this practice?"
                                />
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}

      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "24px 15px 32px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default PracticePage;
