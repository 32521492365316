import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PaginatedDropdown from "../../components/ui/PaginatedDropdown";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import DragFileUpload from "../../components/ui/DragFileUpload";
import { API_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { handleApiCall } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import useQueryParams from "../../hooks/useQueryParams";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { FcInfo } from "react-icons/fc";
import { LuBadgeInfo, LuEye } from "react-icons/lu";
import { FaRegFileLines } from "react-icons/fa6";

const validationSchema = Yup.object({
  name: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  articlecategory: Yup.mixed()
    .nullable()
    .test(
      "articlecategory-required",
      "Article Category is required",
      function (value) {
        return value !== null && value.value;
      }
    ),
  articlesubcategory: Yup.mixed()
    .nullable()
    .test(
      "articlesubcategory-required",
      "Article Sub Category is required",
      function (value) {
        return value !== null && value.value;
      }
    ),
});

function UpdateForm({ data }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const { addToastMessage } = useUI();
  const [uploaded, setUploaded] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    articlecategory: null,
    articlesubcategory: null,
    description: "",
    file: "",
  });

  const { queryParams, updateQueryParam } = useQueryParams();
  const nextUrl = decodeURIComponent(queryParams.get("next")) || "";

  useEffect(() => {
    setInitialValues({
      name: data?.name || "",
      articlecategory: data?.knowledgeBaseSubCategory?.knowledgeBaseCategory
        ? {
            label: data?.knowledgeBaseSubCategory?.knowledgeBaseCategory.name,
            value: data?.knowledgeBaseSubCategory?.knowledgeBaseCategory.id,
            obj: data?.knowledgeBaseSubCategory?.knowledgeBaseCategory,
          }
        : null,
      articlesubcategory: data?.knowledgeBaseSubCategory
        ? {
            label: data?.knowledgeBaseSubCategory.name,
            value: data?.knowledgeBaseSubCategory.id,
            obj: data?.knowledgeBaseSubCategory,
          }
        : null,
      description: data?.description || "",
      file: data?.fileLink || "",
    });
    setUploadedFile(data?.fileLink);
  }, [data]);

  const updateKnowledgeBase = async (values) => {
    setLoading(true);
    setStatus("");

    if (!data?.id) {
      setStatus(`Something went wrong, please try again later.`);
      addToastMessage({
        status: false,
        message: `Something went wrong, please try again later.`,
      });
      return false;
    }

    try {
      const payload = {
        id: data?.id || null,
        name: values.name,
        description: values.description,
        knowledgeBaseSubCategoryId: Number(values?.articlesubcategory?.obj?.id),
      };
      if (values.file) {
        payload["fileLink"] = values.file;
      }
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/knowledge-base/${data?.id}`,
        {
          method: "PUT",
          body: payload,
        }
      );
      if (response.responseStatus == true) {
        setStatus(
          "You have successfully updated the knowledge base, thank you."
        );
        addToastMessage({
          status: true,
          message: `You have successfully updated an knowledge base, thank you.`,
        });
        return true;
      } else {
        setStatus(
          `${response.responseDescription || "Something went wrong, please try again later."}`
        );
        addToastMessage({
          status: false,
          message: `${response.responseDescription || "Something went wrong, please try again later."}`,
        });
        return false;
      }
    } catch (e) {
      console.error("Error:", e);
      addToastMessage({
        status: false,
        message: `Something went wrong, please try again later.`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values) => {
        const api = await updateKnowledgeBase(values);
        if (api) {
          if (nextUrl) {
            navigate(nextUrl);
          } else {
            navigate("/knowledge-base");
          }
        }
      }}
    >
      {({
        handleSubmit,
        validateForm,
        setFieldValue,
        errors,
        touched,
        values,
        setFieldTouched,
      }) => (
        <Form>
          <div className="col-1-1 ">
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <Label title="Title" required />
                    <Field
                      name="name"
                      type="text"
                      placeholder="Eg: How to Create.."
                      className={`primary ${errors.name && touched.name ? "error" : ""}`}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="Category" required />
                    <PaginatedDropdown
                      searchkey="name"
                      selectedValue={values.articlecategory}
                      url={`${API_URL}/api/1.0.0/knowledge-base-category?`}
                      mapOptions={(records) =>
                        records?.map((record) => ({
                          value: record["id"],
                          label: record.name,
                          obj: record,
                        }))
                      }
                      error={errors.articlecategory && touched.articlecategory}
                      setTouched={() =>
                        setFieldTouched("articlecategory", true)
                      }
                      placeHolder="Search"
                      datakey="knowledgeBaseCategoryList"
                      onChange={(v) => {
                        setFieldValue("category", v);
                      }}
                    />
                  </td>
                  <td>
                    <Label title="Sub Category" required />
                    <PaginatedDropdown
                      searchkey="name"
                      selectedValue={values.articlesubcategory}
                      url={`${API_URL}/api/1.0.0/knowledge-base-subcategory?statusId=1&categoryId=${values?.articlecategory?.value}&`}
                      name="articlesubcategory"
                      mapOptions={(records) =>
                        records?.map((record) => ({
                          value: record["id"],
                          label: record.name,
                          obj: record,
                        }))
                      }
                      error={
                        errors.articlesubcategory && touched.articlesubcategory
                      }
                      setTouched={() =>
                        setFieldTouched("articlesubcategory", true)
                      }
                      placeHolder="Search"
                      className={`primary ${errors.articlesubcategory && touched.articlesubcategory ? "error" : ""}`}
                      onChange={(value) =>
                        setFieldValue("articlesubcategory", value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="flex center-left" style={{ gap: "8px" }}>
                      <div
                        className="opl-tooltip"
                        data-tooltip="Please only select PDF files for upload."
                      >
                        <Label title="Upload File" />
                      </div>
                      <FcInfo />
                    </div>
                    <DragFileUpload
                      fileType={"KNOWLEDGE_BASE_DOC"}
                      error={errors.file && touched.file}
                      afterUpload={(url) => {
                        setFieldValue("file", url);
                      }}
                      setUploading={setUploaded}
                    />
                    {uploadedFile && (
                      <div
                        className="flex center-left"
                        style={{ gap: "8px", marginTop: "8px" }}
                      >
                        <span>
                          <LuBadgeInfo color="red" />
                        </span>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "rgb(255, 72, 72)",
                          }}
                        >
                          Uploading a new file will replace the existing one.
                          You will no longer have access to the old file.
                        </p>
                      </div>
                    )}
                  </td>
                </tr>
                {data?.fileLinkS3Url && uploadedFile === values.file && (
                  <tr>
                    <td colSpan={2}>
                      <div
                        className="cursor-pointer"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "5px 12px",
                          backgroundColor: "rgb(245, 245, 245)",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                        >
                          <FaRegFileLines />
                          <div>
                            <div
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                              className="t-t-u l-s-n"
                            >
                              {data?.fileLink?.split("/").pop() || "-"}
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                color: "#7D7D7D",
                                textTransform: "capitalize",
                              }}
                            >
                              {/* {data?.fileLink?.split("/").pop() || "-"} */}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                          }}
                        >
                          <NavLink
                            to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                              data?.fileLinkS3Url || ""
                            )}&title=${encodeURIComponent("")}`}
                            state={{ ...location.state }}
                          >
                            <span
                              style={{
                                background: "transparent",
                                border: "none",
                                cursor: "pointer",
                              }}
                              title="View file"
                            >
                              <LuEye />
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={2}>
                    <Label title="Description" required />
                    <Field
                      name="description"
                      as="textarea"
                      placeholder="Enter Description"
                      className={`primary ${errors.description && touched.description ? "error" : ""}`}
                      style={{
                        width: "100%",
                        height: "100px",
                        resize: "none",
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => {
                if (nextUrl) {
                  navigate(nextUrl);
                } else {
                  navigate("/knowledge-base");
                }
              }}
              icon={<TbArrowBackUp />}
            />
            <ButtonTextIcon
              type="submit"
              disabled={loading || uploaded}
              title="Update Now"
              onClick={async () => {
                const errors = await validateForm();
                if (Object.keys(errors).length > 0) {
                  const firstErrorMessage = Object.values(errors)[0];
                  addToastMessage({
                    status: false,
                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                  });
                }
                handleSubmit();
              }}
              className="mar-l-a"
              icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
            />
          </div>
          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default UpdateForm;
