import { useEffect, useState } from "react";
import { useUI } from "../../context/ui";
import { API_URL } from "../../utilities/constants";
import { ErrorMessage, Field, Form, Formik } from "formik";
import LoaderIcon from "../../components/ui/LoaderIcon";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import * as Yup from "yup";
import { handleApiCall } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import { TbArrowBackUp } from "react-icons/tb";
import { MdCancel } from "react-icons/md";
import { HiCheckBadge } from "react-icons/hi2";
import { LuBadgeInfo } from "react-icons/lu";
import { FiArrowRightCircle } from "react-icons/fi";

const InvoiceStatus = ({ id, invoiceStatus, setShow, callback }) => {
	const { addToastMessage } = useUI();
	const [loading, setLoading] = useState(false);
	const validationSchema = Yup.object().shape({
		selectedRadio: Yup.string().required(
			"Select an option, it is required"
		),

		chequeNumber: Yup.string()
			.test(
				"cheque-number-required",
				"Cheque number is required",
				function (value) {
					const { selectedRadio } = this.parent; // Access parent form values
					if (selectedRadio === "4" && !value) {
						return false;
					}
					return true;
				}
			)
			.max(255, "Cheque number must not exceed 255 characters"),

		amountReceived: Yup.string()
			.test(
				"amount-received-required",
				"Amount must be a valid number and is required",
				function (value) {
					const { selectedRadio } = this.parent; // Access parent form values
					if (
						selectedRadio === "4" &&
						(!value || isNaN(value) || value.trim() === "")
					) {
						return false;
					}
					return true;
				}
			)
			.matches(
				/^[0-9]+(\.[0-9]{1,2})?$/, // Regular expression to allow only numbers and up to 2 decimal places
				"Amount must be a valid number"
			)
			.test(
				"amount-range",
				"Amount must not be greater than 999999 or less than 0",
				function (value) {
					if (value && !isNaN(value)) {
						const numericValue = parseFloat(value);
						return numericValue >= 0 && numericValue <= 999999;
					}
					return true; // If no value, let the required test handle it
				}
			),
	});
	const initialValues = {
		selectedRadio: null,
		chequeNumber: "",
		amountReceived: "",
	};
	const handleSubmit = async (values) => {
		setLoading(true);
		if (!id) {
			addToastMessage({
				status: false,
				message: "Something went wrong. Please try again later.",
			});
			setLoading(false);
			return;
		}

		try {
			const payload = {
				status: parseInt(values.selectedRadio),
				paymentReceived: values.amountReceived,
				reference: values.chequeNumber,
			};
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/invoice/${id}`,
				{
					method: "PUT",
					body: payload,
				}
			);

			if (response.responseStatus === true) {
				addToastMessage({
					status: true,
					message: "Invoice status updated successfully.",
				});
				callback();
				setLoading(false);
				setShow(false);

				return;
			} else {
				addToastMessage({
					status: false,
					message:
						response?.responseDescription ||
						"Something went wrong, please try again later.",
				});
			}
		} catch (e) {
			console.log("error", e);
		} finally {
			setLoading(false);
		}
	};

	const isDisabled = (radioValue) => {
		if (invoiceStatus === 1 && radioValue === 2) return false;
		if (invoiceStatus === 2 && (radioValue === 3 || radioValue === 4))
			return false;
		if (invoiceStatus === 3 || invoiceStatus === 4) return true;
		return true;
	};

	return (
		<div
			className='box'
			id='activity-detail-box'
			style={{
				width: "600px",
				padding: "24px",
				border: "solid 1px #f0f0f0",
				background: "white",
				borderRadius: "8px",
				margin: "0 auto",
				boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
			}}>
			<div>
				<div
					className='col-1-1 flex center-left'
					style={{
						borderBottom: "solid 1px #f0f0f0",
						paddingBottom: "12px",
						marginBottom: "16px",
					}}>
					<h6 className='color-primary f-w-300 '>
						Invoice Status (
						{invoiceStatus === 1
							? "Ready"
							: invoiceStatus === 2
								? "Send to Facility"
								: invoiceStatus === 3
									? "Update Required"
									: invoiceStatus === 4
										? "Payment Received"
										: "NA"}
						)
					</h6>
					<div
						className='mar-l-a cursor-pointer '
						onClick={() => setShow(false)}>
						<MdCancel  size={20}/>
					</div>
				</div>
				<div style={{ overflowY: "auto" }}>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						enableReinitialize={true}
						onSubmit={handleSubmit}>
						{({ values, setFieldValue, errors, touched }) => (
							<Form>
								<div>
									<div>
										<div
											style={{
												marginBottom: "20px",
											}}>
											<div
												className='flex'
												style={{
													gap: "10px",
													justifyContent:
														"space-between",
												}}>
												{/* label 2*/}

												<label
													style={{
														display: "block",
														backgroundColor:
															parseInt(
																values.selectedRadio
															) === 2
																? " #6ca0ff"
																: " #ffffff",
														color:
															parseInt(
																values.selectedRadio
															) === 2
																? "#ffffff"
																: "#6ca0ff",
														border: "1px solid #6ca0ff",
														padding: "5px",
														borderRadius: "5px",
														textAlign: "center",
														width: "30%",
														cursor: isDisabled(2)
															? "not-allowed"
															: "pointer",
														opacity: isDisabled(2)
															? 0.5
															: 1,
														display: "flex",
														alignItems: "center",
														justifyContent:
															"center",
													}}
													onClick={() => {
														if (!isDisabled(2)) {
															setFieldValue(
																"selectedRadio",
																2
															);
															setFieldValue(
																"chequeNumber",
																""
															);
															setFieldValue(
																"amountReceived",
																""
															);
														}
													}}>
													{/* Radio button */}
													<Field
														type='radio'
														name='selectedRadio'
														value='2'
														hidden
														disabled={isDisabled(2)}
													/>
													<span
														style={{
															width: "20px",
															height: "20px",
															borderRadius: "50%",
															border:
																parseInt(
																	values.selectedRadio
																) === 2
																	? "0px"
																	: "1px solid #6ca0ff",
															marginRight: "8px",
															borderColor:
																parseInt(
																	values.selectedRadio
																) === 2
																	? "white"
																	: " #6ca0ff",
															display: "flex",
															justifyContent:
																"center",
															alignItems:
																"center",
														}}>
														{parseInt(
															values.selectedRadio
														) === 2 && (
															<HiCheckBadge size={20} color="green" />
														)}
													</span>
													<p
														style={{
															color:
																parseInt(
																	values.selectedRadio
																) === 2
																	? "#ffffff"
																	: "#6ca0ff",
															margin: 0,
														}}>
														Send to Facility
													</p>
												</label>
												{/* label 3*/}

												<label
													style={{
														display: "block",
														backgroundColor:
															parseInt(
																values.selectedRadio
															) === 3
																? " #6ca0ff"
																: " #ffffff",
														color:
															parseInt(
																values.selectedRadio
															) === 3
																? "#ffffff"
																: "#6ca0ff",
														border: "1px solid #6ca0ff",
														padding: "5px",
														borderRadius: "5px",
														textAlign: "center",
														width: "30%",
														cursor: isDisabled(3)
															? "not-allowed"
															: "pointer",
														opacity: isDisabled(3)
															? 0.5
															: 1,
														display: "flex",
														alignItems: "center",
														justifyContent:
															"center",
													}}
													onClick={() => {
														if (!isDisabled(3)) {
															setFieldValue(
																"selectedRadio",
																3
															);
															setFieldValue(
																"chequeNumber",
																""
															);
															setFieldValue(
																"amountReceived",
																""
															);
														}
													}}>
													{/* Radio button */}
													<Field
														type='radio'
														name='selectedRadio'
														value='3'
														hidden
														disabled={isDisabled(3)}
													/>
													<span
														style={{
															width: "20px",
															height: "20px",
															borderRadius: "50%",
															border:
																parseInt(
																	values.selectedRadio
																) === 3
																	? "0px"
																	: "1px solid #6ca0ff",
															marginRight: "8px",
															borderColor:
																parseInt(
																	values.selectedRadio
																) === 3
																	? "white"
																	: " #6ca0ff",
															display: "flex",
															justifyContent:
																"center",
															alignItems:
																"center",
														}}>
														{parseInt(
															values.selectedRadio
														) === 3 && (
															<HiCheckBadge size={20} color="green" />
														)}
													</span>
													<p
														style={{
															color:
																parseInt(
																	values.selectedRadio
																) === 3
																	? "#ffffff"
																	: "#6ca0ff",
															margin: 0,
														}}>
														Update Required
													</p>
												</label>
												{/* label 4*/}
												<label
													style={{
														display: "block",
														backgroundColor:
															parseInt(
																values.selectedRadio
															) === 4
																? " #6ca0ff"
																: " #ffffff",
														color:
															parseInt(
																values.selectedRadio
															) === 4
																? "#ffffff"
																: "#6ca0ff",
														border: "1px solid #6ca0ff",
														padding: "5px",
														borderRadius: "5px",
														textAlign: "center",
														width: "30%",
														cursor: isDisabled(4)
															? "not-allowed"
															: "pointer",
														opacity: isDisabled(4)
															? 0.5
															: 1,
														display: "flex",
														alignItems: "center",
														justifyContent:
															"center",
													}}
													onClick={() => {
														if (!isDisabled(4)) {
															setFieldValue(
																"selectedRadio",
																4
															);
														}
													}}>
													{/* Radio button */}
													<Field
														type='radio'
														name='selectedRadio'
														value='4'
														hidden
														disabled={isDisabled(4)}
													/>
													<span
														style={{
															width: "20px",
															height: "20px",
															borderRadius: "50%",
															border:
																parseInt(
																	values.selectedRadio
																) === 4
																	? "0px"
																	: "1px solid #6ca0ff", // Default border
															marginRight: "8px",
															borderColor:
																parseInt(
																	values.selectedRadio
																) === 4
																	? "white"
																	: " #6ca0ff",
															display: "flex",
															justifyContent:
																"center",
															alignItems:
																"center",
														}}>
														{parseInt(
															values.selectedRadio
														) === 4 && (
															<HiCheckBadge size={20} color="green" />
														)}
													</span>
													<p
														style={{
															color:
																parseInt(
																	values.selectedRadio
																) === 4
																	? "#ffffff"
																	: "#6ca0ff",
															margin: 0,
														}}>
														{" "}
														Payment Received
													</p>
												</label>
											</div>
											<ErrorMessage
												name='selectedRadio'
												component='p'
												className='error-messages'
											/>
										</div>

										{parseInt(values.selectedRadio) ===
											4 && (
											<div>
												<div
													style={{
														marginBottom: "5px",
													}}>
													<Label
														title='Cheque Number'
														required
													/>
													<Field
														type='text'
														name='chequeNumber'
														className={`primary ${
															errors.chequeNumber &&
															touched.chequeNumber
																? "error"
																: ""
														}`}
													/>
													<ErrorMessage
														name='chequeNumber'
														component='p'
														className='error-messages'
													/>
												</div>
												<div
													style={{
														marginBottom: "10px",
													}}>
													<Label
														title='Amount Received'
														required
													/>
													<Field
														type='text'
														name='amountReceived'
														className={`primary ${
															errors.amountReceived &&
															touched.amountReceived
																? "error"
																: ""
														}`}
													/>
													<ErrorMessage
														name='amountReceived'
														component='p'
														className='error-messages'
													/>
												</div>
											</div>
										)}
										<div
											style={{
												padding: "10px",
												borderRadius: "5px",
												width: "100%",
											}}>
											<p
												style={{
													color: "rgb(0, 0, 0)",
												}}>
												<span
													style={{ display: "flex" }}>
													 <LuBadgeInfo size={20} />
													<p>{" Disclaimer:"}</p>
												</span>

												<p
													style={{
														color: "rgb(86, 86, 86)",
													}}>
													{invoiceStatus === 1
														? `Invoice is ready to be sent to the facility, only "Send to facility" option is available`
														: invoiceStatus === 2
															? `Invoice has been sent to the facility awaiting feedback, only "Update required" and "Payment received" option is available`
															: invoiceStatus ===
																  3
																? `All options are disabled, after changes are made in necessary fields. Invoice needs to be regenerated and the invoice status will automatically change to ready state.`
																: invoiceStatus ===
																	  4
																	? `Payment has been received, all options are disabled`
																	: " NA"}
												</p>
											</p>
										</div>
									</div>
									<div
										className=' flex '
										style={{
											marginTop: "16px",
											borderTop: "solid 1px #f0f0f0",
											paddingTop: "16px",
											justifyContent: "space-between",
										}}>
										<div>
											<ButtonLightTextIcon
												title='Cancel'
												onClick={() => setShow(false)}
												icon={
													<TbArrowBackUp />
												}
											/>
										</div>
										<div>
											<ButtonTextIcon
												title='Update Status'
												type='submit'
												disabled={loading}
												icon={
													loading ? (
														<LoaderIcon />
													) : (
														<FiArrowRightCircle /> 
													)
												}
											/>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default InvoiceStatus;
