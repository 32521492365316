import LoaderIcon from "../../components/ui/LoaderIcon";
import Label from "../../components/ui/Label";
import React, { useEffect, useState } from "react";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { TbArrowBackUp, TbPlus, TbUpload } from "react-icons/tb";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { MdCancel } from "react-icons/md";
import * as Yup from "yup";
import { API_URL } from "../../utilities/constants";
import { handleApiCall, labelProcedureFunc } from "../../utilities/helpers";
import { useUI } from "../../context/ui";
import PaginatedDropdown, {
  mapOptions,
} from "../../components/ui/PaginatedDropdown";

const UpdatePreferenceCard = ({ setShowAddForm, id, callback }) => {
  const { addToastMessage } = useUI();
  const [procedures, setProcedures] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const [initialValues, setInitialValues] = useState({
    surgeonId: "",
    title: "",
    tools: "",
    toolsList: [],
    description: "",
  });
  useEffect(() => {
    const parsedTitle = data?.title ? JSON.parse(data.title) : null;

    setInitialValues({
      surgeonId: data?.surgeon?.id,
      title: parsedTitle
        ? {
            value: parsedTitle?.id || null,
            label: parsedTitle?.name || null,
          }
        : null,
      tools: "",
      toolsList: data?.tools ? data.tools.split(", ") : [],
      description: data?.description || "",
    });
  }, [data]);
  useEffect(() => {
    if (id) {
      fetchPreferenceData(id);
    }
  }, [id]);

  const fetchPreferenceData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/preference-card/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      } else {
        setError("Failed to fetch Preference data.");
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch Preference data.");
      setLoading(false);
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.object()
      .required("Title is required.")
      .nonNullable("Title is required."),
    description: Yup.string()
      .required("Description is required.")
      .nonNullable(),
    toolsList: Yup.array()
      .min(1, "At least one tool is required.")
      .required("Tools are required.")
      .nonNullable(),
  });

  const handleAddTool = (values, setFieldValue) => {
    if (values.tools.trim() !== "") {
      const newTool = values.tools.trim();
      const updatedToolsList = [...values.toolsList, newTool];
      setFieldValue("toolsList", updatedToolsList);
      setFieldValue("tools", "");
    }
  };

  const handleRemoveTool = (index, setFieldValue, values) => {
    const updatedToolsList = values.toolsList.filter((_, i) => i !== index);
    setFieldValue("toolsList", updatedToolsList);
  };

  const handleSubmit = async (values) => {
    console.log("values", values);
    setLoading(true);
    try {
      const payload = {
        surgeonId: values.surgeonId,
        title: JSON.stringify({
          id: values.title.value,
          name: values.title.label,
        }),
        tools: values.toolsList.join(", "),
        description: values.description,
      };
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/preference-card/${id}`,
        {
          method: "PUT",
          body: payload,
        }
      );

      if (response.responseStatus === true) {
        addToastMessage({
          status: true,
          message: "Preference Card Added Successfully!",
        });
        setShowAddForm(false);
        callback();
      } else {
        addToastMessage({
          status: false,
          message:
            response.responseDescription ||
            "Something went wrong while adding preference card. Please try again.",
        });
      }
    } catch (error) {
      addToastMessage({
        status: false,
        message:
          "Something went wrong while adding preference card. Please try again.",
      });
    }
    setLoading(false);
  };

  return (
    <section
      style={{
        position: "fixed",
        inset: 0,
        zIndex: 9999,
        backgroundColor: "rgba(255, 255, 255, 0.86)",
      }}
    >
      <section className="col-1-1 flex center-center height-100dvh col-1-1">
        <div
          id="activity-detail-box"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="flex center-left">
            <div>
              <h6 className="color-primary f-w-300">Edit Preference</h6>
            </div>
            <div
              className="flex mar-l-a"
              style={{
                gap: "10px",
              }}
            >
              <ButtonLightTextIcon
                title="Cancel"
                onClick={() => {
                  setShowAddForm(false);
                }}
                icon={<TbArrowBackUp />}
              />
            </div>
          </div>
          <div
            style={{
              height: "60vh",
              margin: "10px",
              overflowY: "scroll",
              padding: "20px 5px",
              overflowX: "hidden",
              scrollbarWidth: "thin",
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                handleSubmit,
                validateForm,
              }) => (
                <Form>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td>
                          <Label title="Procedure" required={true} />
                          <PaginatedDropdown
                            url={`${API_URL}/api/1.0.0/reference/procedure?`}
                            mapOptions={(records) =>
                              mapOptions(records, "id", labelProcedureFunc)
                            }
                            error={errors.title && touched.title}
                            setTouched={() => setFieldTouched("title", true)}
                            selectedValue={values.title}
                            placeHolder="Search"
                            onChange={(v) => {
                              setFieldValue("title", v);
                            }}
                          />
                          <ErrorMessage
                            name="title"
                            component="p"
                            className="error-messages"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Label title="Tools" required={true} />
                          <div
                            className="flex center-left"
                            style={{ gap: "10px" }}
                          >
                            <Field
                              type="text"
                              name="tools"
                              placeholder="Enter Tools"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault(); // Prevent form submission on Enter key press
                                  handleAddTool(values, setFieldValue);
                                  validateForm();
                                }
                              }}
                              className={`col-1-1 primary ${
                                errors.toolsList && touched.toolsList
                                  ? "error"
                                  : ""
                              }`}
                            />
                            <ButtonTextIcon
                              type="button"
                              onClick={() => {
                                handleAddTool(values, setFieldValue);
                                validateForm();
                              }}
                              title={"Add"}
                              icon={<TbPlus />}
                            />
                          </div>
                          {errors.toolsList && touched.toolsList && (
                            <p className="error-messages">{errors.toolsList}</p>
                          )}
                          <ul
                            className="col-1-1 t-a-l"
                            style={{
                              padding: "10px 0",
                            }}
                          >
                            {values.toolsList.map((tool, index) => (
                              <li
                                data-tooltip={`Click to delete the tool.`}
                                onClick={() => {
                                  handleRemoveTool(
                                    index,
                                    setFieldValue,
                                    values
                                  );
                                  validateForm();
                                }}
                                style={{
                                  listStyle: "none",
                                  marginRight: "4px",
                                  marginBottom: "4px",
                                  borderRadius: "2px",
                                  lineHeight: "32px",
                                  padding: "10px 12px",
                                  verticalAlign: "middle",
                                }}
                                key={index}
                                className="opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                              >
                                <div className="flex center-left">
                                  <p
                                    className="of-hid font-size-12"
                                    style={{
                                      whiteSpace: "nowrap",
                                      width: "108px",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {tool}
                                  </p>
                                  <MdCancel size={20} />
                                </div>
                              </li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Label title="Description" required={true} />
                          <Field
                            type="text"
                            name="description"
                            placeholder="Enter Description"
                            className={`col-1-1 primary ${
                              errors.description && touched.description
                                ? "error"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="description"
                            component="p"
                            className="error-messages"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <div className="flex center-right">
                    <ButtonTextIcon
                      type="submit"
                      onClick={handleSubmit}
                      disabled={loading || values.toolsList.length === 0}
                      title={"Update"}
                      icon={loading ? <LoaderIcon /> : <TbUpload />}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <ButtonLightTextIcon
            title="Cancel"
            onClick={() => {
              setShowAddForm(false);
            }}
            icon={<TbArrowBackUp />}
          />
        </div>
      </section>
    </section>
  );
};
export default UpdatePreferenceCard;
