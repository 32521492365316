import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import { useNavigate } from "react-router-dom";
import Label from "../../components/ui/Label";
import { handleApiCall } from "../../utilities/helpers";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";

const validationSchema = Yup.object({
	name: Yup.string().required("Category name is required"),
});

function AddForm() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const { addToastMessage } = useUI();

	const createCategory = async (values) => {
		setLoading(true);
		try {
			const response = await handleApiCall(
				`${API_URL}/api/1.0.0/knowledge-base-category`,
				{
					method: "POST",
					body: {
						name: values.name,
					},
				}
			);
			if (response.responseStatus == true) {
				setStatus(
					"You have successfully created a category, thank you."
				);
				addToastMessage({
					status: true,
					message: `You have successfully created a category, thank you.`,
				});
				navigate("/knowledge-base-categories");
				return true;
			} else {
				setStatus(
					`${response.responseDescription || "Something went wrong, please try again later."}`
				);
				addToastMessage({
					status: false,
					message: `${response.responseDescription || "Something went wrong, please try again later."}`,
				});
				return false;
			}
		} catch (e) {
			console.error("Error:", e);
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
			return false;
		}
	};

	return (
		<Formik
			initialValues={{ name: "" }}
			validationSchema={validationSchema}
			onSubmit={async (values, { resetForm }) => {
				resetForm();
				setLoading(true);
				const api = await createCategory(values);
				if (api) {
					resetForm();
				}
				setLoading(false);
			}}>
			{({ handleSubmit, validateForm, errors, touched }) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td>
										<Label title='Category Name' required />
										<Field
											name='name'
											type='text'
											placeholder='Enter Category Name'
											className={`primary ${errors.name && touched.name ? "error" : ""}`}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() =>
								navigate("/knowledge-base-categories")
							}
							icon={
								<TbArrowBackUp />
							}
						/>
						<ButtonTextIcon
							type='submit'
							disabled={loading}
							title='Create Now'
							onClick={async () => {
								const errors = await validateForm();
								if (Object.keys(errors).length > 0) {
									const firstErrorMessage =
										Object.values(errors)[0];
									addToastMessage({
										status: false,
										message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
									});
								}
								handleSubmit();
							}}
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<FiArrowRightCircle />
								)
							}
						/>
					</div>
					{status && (
						<>
							<br />
							<p className='color-primary f-w-700'>{status}</p>
						</>
					)}
				</Form>
			)}
		</Formik>
	);
}

export default AddForm;
