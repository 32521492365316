import { useEffect, useState } from "react";
import { useScanflow } from "./scanflow-context";
import {
  formatDateTime,
  formatTime,
  getRandomColor,
  handleApiCall,
} from "../../utilities/helpers";
import LoaderIcon from "../../components/ui/LoaderIcon";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { API_URL } from "../../utilities/constants";
import { TbArrowBackUp } from "react-icons/tb";
import { MdCancel } from "react-icons/md";

const DetailRow = ({ label, value }) => (
  <div>
    <p
      className="f-w-300 l-h-1"
      style={{
        fontSize: "12px",
        paddingBottom: "4px",
        color: "#aaaaaa",
      }}
    >
      {label}:
    </p>
    <p className="f-w-400 color-primary l-h-1" style={{}}>
      {value}
    </p>
  </div>
);

const ScanFlowHistory = ({ id, setShow }) => {
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { random, task } = useScanflow();
  if (!id) return null;

  return (
    <div id="activity-detail-box">
      <div
        className="col-1-1 flex center-left"
        style={{
          borderBottom: "solid 1px #f0f0f0",
          paddingBottom: "12px",
          marginBottom: "16px",
        }}
      >
        <h6 className="color-primary f-w-300">Scanflow History</h6>
        <div className="mar-l-a cursor-pointer" onClick={() => setShow(false)}>
        <MdCancel  size={20}/>
        </div>
      </div>
      <div
        style={{
          height: "492px",
          overflowY: "auto",
          background: "white",
        }}
      >
        <section>
          {loading && (
            <div
              className="col-1-1"
              style={{
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoaderIcon
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
          )}
          {!loading && error && (
            <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
              {error}
            </p>
          )}
          {!loading &&
            !error &&
            task.logs.length > 0 &&
            task.logs
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map(({ createdAt, createdBy, log, id }, i) => (
                <div className="col-1-1" key={i}>
                  <div>
                    <div className="flex center-left">
                      <div
                        className="flex center-center b-r-100"
                        style={{
                          flex: "0 40px",
                          background: "#f0f0f0",
                          height: "40px",
                          width: "40px",
                        }}
                      >
                        <p
                          className="f-w-300 color-primary l-h-1"
                          style={{
                            fontSize: "11px",
                          }}
                        >
                          #
                          <span className="f-w-700">
                            {task.logs.length - i}
                          </span>
                        </p>
                      </div>
                      <div
                        style={{
                          height: "4px",
                          width: "24px",
                          borderTop: "dashed 2px #f0f0f0",
                        }}
                      ></div>
                      <div
                        className="b-r-20"
                        style={{
                          padding: "12px 16px",
                          backgroundColor: getRandomColor(),
                        }}
                      >
                        <p
                          className="color-white f-w-400 l-h-1 t-t-u"
                          style={{
                            fontSize: "11px",
                          }}
                        >
                          {createdAt || "NA"}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        height: "16px",
                        width: "4px",
                        borderLeft: "dashed 2px #f0f0f0",
                        marginLeft: "20px",
                      }}
                    ></div>
                    <div
                      className="b-r-5"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gridTemplateRows: "repeat(auto-fill, auto)",
                        gridColmnGap: "12px",
                        gridRowGap: "12px",
                        background: "#f4fbff",
                        padding: "16px",
                        border: "solid 1px #f0f0f0",
                      }}
                    >
                      <div>
                        <DetailRow
                          label="Updated BY"
                          value={createdBy.name || "NA"}
                        />
                      </div>
                      <div>
                        <DetailRow
                          label="Updated At"
                          value={
                            createdAt
                              ? formatDateTime(createdAt).toLocaleString()
                              : "-"
                          }
                        />
                      </div>
                      <div>
                        <DetailRow
                          label="comment"
                          value={JSON.parse(log)?.metadata?.comment || "-"}
                        />
                      </div>
                    </div>
                    {task.length !== i + 1 && (
                      <div
                        style={{
                          height: "48px",
                          width: "4px",
                          borderLeft: "dashed 2px #f0f0f0",
                          marginLeft: "20px",
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              ))}
          {!loading && !error && task.logs && task.logs.length === 0 && (
            <div
              className="flex center-center col-1-1"
              style={{ height: "100%" }}
            >
              <p className="f-w-300 color-primary">No history available.</p>
            </div>
          )}
        </section>
      </div>
      <div
        className="col-1-1 flex center-left"
        style={{
          marginTop: "16px",
          borderTop: "solid 1px #f0f0f0",
          paddingTop: "16px",
        }}
      >
        <ButtonLightTextIcon
          title="Cancel"
          onClick={() => setShow(false)}
          icon={
            <TbArrowBackUp />
          }
        />
      </div>
    </div>
  );
};

export default ScanFlowHistory;
