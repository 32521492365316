import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import {
  decodeModuleAccessFromToken,
  formatInputPhoneNumber,
  formatServerDate,
  getRandomColor,
  handleApiCall,
  jsonToQueryParams,
} from "../utilities/helpers";
import TableFilter from "./surgeon/TableFilter";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import ButtonLightTextIcon from "../components/ui/ButtonLightTextIcon";
import useQueryParams from "../hooks/useQueryParams";
import PageToolbar from "../components/common/PageToolbar";
import InfoItem from "../components/ui/InfoItem";
import { FiEdit, FiPlusCircle } from "react-icons/fi";
import { TbArrowBackUp, TbLicense, TbUserCircle } from "react-icons/tb";
import { HiCheckBadge } from "react-icons/hi2";
import { FaUserDoctor } from "react-icons/fa6";
import { LuBadgeInfo, LuMapPin } from "react-icons/lu";
import { SiCodesignal, SiSemrush } from "react-icons/si";
import { CiHospital1, CiVoicemail } from "react-icons/ci";
import { AiOutlineFieldNumber } from "react-icons/ai";
import {
  MdAirplanemodeInactive,
  MdAlternateEmail,
  MdFolderSpecial,
} from "react-icons/md";

const StatusIcon = ({ id }) => {
  switch (id) {
    case 1:
      return (
        <div className="flex center-left" style={{ gap: "6px" }}>
          <HiCheckBadge color="green" />
          <p
            className="f-w-300"
            style={{
              fontSize: "12px",
              color: "#27ae60",
            }}
          >
            Active
          </p>
        </div>
      );
    case 0:
      return (
        <div className="flex center-left" style={{ gap: "6px" }}>
          <MdAirplanemodeInactive color={"#E65464"} />
          <p
            className="f-w-300"
            style={{
              fontSize: "12px",
              color: "#E65464",
            }}
          >
            Inactive
          </p>
        </div>
      );
    default:
      return <></>;
  }
};
const SurgeonPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [ids, setIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [controller, setController] = useState(null);
  const preferencePermissions = decodeModuleAccessFromToken("preference-card");
  const { updateQueryParam, queryParams } = useQueryParams();
  const { permissions, addToastMessage } = useUI();
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const search = queryParams.get("search") || "";
  const status = queryParams.get("status") || "";
  const [showPreferenceCardId, setShowPreferenceCardId] = useState(null);

  const toggleNumberInArray = (number) => {
    setIds((prevArray) => {
      const index = prevArray.indexOf(number);
      if (index === -1) {
        return [...prevArray, number];
      } else {
        const newArray = [...prevArray];
        newArray.splice(index, 1);
        return newArray;
      }
    });
  };

  const resetFilters = () => {
    updateQueryParam("status", "");
    updateQueryParam("search", "");
    updateQueryParam("pageNo", 0);
  };

  const getListApi = async (s) => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    try {
      setLoading(true);
      const query = jsonToQueryParams({
        search: search,
        status: status,
      });
      const response = await handleApiCall(
        API_URL +
          `/api/1.0.0/surgeon?pageSize=${pageSize}&pageNo=${pageNo}&${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus == true) {
        setData(response.data);
      } else {
        setData(null);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error:", e);
    }
  };

  const active = async (type) => {
    try {
      if (!ids.length) {
        addToastMessage({
          status: false,
          message: `Something went wrong while ${type} surgeons. Please try again.`,
        });
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/surgeon/change-status/${type}`,
        {
          method: "PUT",
          body: {
            ids: ids,
          },
        }
      );
      if (response.responseStatus == true) {
        getListApi();
        setIds([]);
        addToastMessage({
          status: true,
          message: `Surgeon ${type === "0" ? "Deactivated" : type === "1" ? "Activated" : "NA"} successfully.`,
        });
        return true;
      } else {
        addToastMessage({
          status: false,
          message: response?.responseDescription || "Invalid Request",
        });
        return false;
      }
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while  ${type === "0" ? "Deactivated" : type === "1" ? "Activated" : "NA"} surgeons. Please try again.`,
      });
      return false;
    }
  };

  const handleDelete = async (id) => {
    try {
      if (!id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting surgeons. Please try again.`,
        });
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/surgeon/${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.responseStatus == true) {
        getListApi();
        addToastMessage({
          status: true,
          message: `surgeon deleted successfully.`,
        });
        return true;
      }

      addToastMessage({
        status: false,
        message: `Something went wrong while deleting  surgeon. Please try again.`,
      });

      return false;
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting surgeon. Please try again.`,
      });
      return false;
    }
  };

  useEffect(() => {
    getListApi();
  }, [location.search]);

  return (
    <section id="physician-assistant">
      <PageToolbar
        title={"Surgeon"}
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => navigate("/add-surgeon")}
              icon={<FiPlusCircle />}
            />
          )
        }
      />
      <TableFilter
        ids={ids}
        callback={(type) => {
          active(type);
        }}
        resetFilters={() => {
          resetFilters();
        }}
      />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.records.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                {/* <th>Credential</th> */}
                <th>NPI</th>
                {/* <th>Address</th> */}
                {/* <th>State, City, Zip</th> */}
                <th>Office Phone Number</th>
                <th>Fax Number</th>
                <th>Status</th>
                <th>Primary Practice Address</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            {data && (
              <tbody>
                {data?.records?.map((item, i) => (
                  <tr key={i}>
                    <td>{pageNo * pageSize + i + 1}</td>
                    <td>
                      {(item?.firstName || "") +
                        " " +
                        (item?.middleName || "") +
                        " " +
                        (item?.lastName || "")}
                      {(item?.suffix ? ", " : "") + (item?.suffix || "")}
                    </td>
                    <td>{item?.npi || "-"}</td>
                    <td>{item?.surgeonDetails?.phone || "-"}</td>
                    <td>{item?.surgeonDetails?.fax || "-"}</td>
                    <td>{item?.statusId === 1 ? "Active" : "Inactive"}</td>
                    <td>
                      {item?.surgeonDetails
                        ? `${item.surgeonDetails.address || "-"}${item.surgeonDetails.state ? `, ${item.surgeonDetails.state}` : ""}
                            ${item.surgeonDetails.city ? `, ${item.surgeonDetails.city}` : ""}
                            ${item.surgeonDetails.zip ? `, ${item.surgeonDetails.zip}` : ""}`
                        : "-"}
                    </td>
                    <td>
                      <div
                        className="flex center-center"
                        style={{ gap: "12px" }}
                      >
                        {permissions?.isReadAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="view"
                                  title="View"
                                />
                              }
                              content={<SurgeonDetailsContent id={item?.id} />}
                            />
                          </div>
                        )}
                        {preferencePermissions?.isReadAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <ButtonTertiary
                              actionType="view"
                              title="Preference Card"
                              onClick={() => {
                                navigate(
                                  `/surgeons/${item?.id}/prefernces?next=${encodeURIComponent(window.location.pathname + window.location.search)}&name=${encodeURIComponent(
                                    `${item?.firstName || ""} ${
                                      item?.middleName || ""
                                    } ${item?.lastName || ""}, ${
                                      item?.suffix || ""
                                    }`
                                  )}`
                                );
                              }}
                            />
                          </div>
                        )}
                        {permissions?.isUpdateAllowed && (
                          <div>
                            <NavLink
                              to={`/edit-surgeon?next=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                              state={{ id: item.id }}
                            >
                              <ButtonTertiary actionType="edit" title="Edit" />
                            </NavLink>
                          </div>
                        )}

                        {permissions?.isDeleteAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="delete"
                                  title="Delete"
                                />
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    await handleDelete(item.id);
                                    return true;
                                  }}
                                  message="Are you sure you want to delete this surgeon?"
                                />
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      {data && !loading && (
        <div
          className="flex center-center"
          style={{
            padding: "10px 15px 10px 15px",
            gap: "12px",
          }}
        >
          <p className="f-w-300" style={{ fontSize: "11px" }}>
            Page no:
          </p>
          <Pagination
            totalRecords={data.totalRecords}
            pageNo={data.pageNo}
            pageSize={data.pageSize}
          />
        </div>
      )}
    </section>
  );
};

const SurgeonDetailsContent = ({ setShow, id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { permissions } = useUI();

  const fetchFacilityData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/surgeon/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus == true) {
        setData(response.data);
        setLoading(false);
      } else {
        setError("Failed to fetch facility data.");
        setData(null);
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch facility data.");
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    if (id) fetchFacilityData(id);
  }, [id]);

  return (
    <div id="activity-detail-box">
      <div className="flex center-left">
        <div>
          <h6 className="color-primary f-w-300">Surgeon Details</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ButtonLightTextIcon
            title="Cancel"
            onClick={() => setShow(false)}
            icon={<TbArrowBackUp />}
          />
        </div>
      </div>
      <div
        style={{
          height: "60vh",
          margin: "10px",
          overflowY: "scroll",
          padding: "20px 5px",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
      >
        {loading && (
          <div
            className="col-1-1"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderIcon
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
        )}
        {!loading && error && (
          <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
            {error}
          </p>
        )}
        {data && !loading && !error && (
          <div className="activity-card pos-rel">
            <div
              style={{
                position: "absolute",
                content: "",
                height: "4px",
                left: "-1px",
                right: "-1px",
                bottom: "-2px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            ></div>
            <div
              className="col-1-1 flex center-left"
              style={{
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <h5 className="f-w-700">
                <span className="f-w-300">#</span>
                &nbsp;
                {data?.id}
              </h5>
              <hr
                style={{
                  outline: "none",
                  border: "none",
                  height: "16px",
                  width: "2px",
                  background: "#EBEBEB",
                }}
              />
              <StatusIcon id={data.statusId} />
            </div>
            <div
              style={{
                padding: "12px 0 12px 0 ",
              }}
            >
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<FaUserDoctor color={getRandomColor()} size={20} />}
                  label={"Surgeon"}
                  name={
                    (data?.firstName || "") +
                    " " +
                    (data?.middleName || "") +
                    " " +
                    (data?.lastName || "") +
                    (data?.suffix ? ", " : "") +
                    (data?.suffix || "")
                  }
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuMapPin color={getRandomColor()} size={20} />}
                  label={"Address"}
                  name={data?.surgeonDetails?.address || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<SiCodesignal color={getRandomColor()} size={20} />}
                  label={"Designation"}
                  name={data?.designation || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CiVoicemail color={getRandomColor()} size={20} />}
                  label={"Phone Number"}
                  name={
                    data?.surgeonDetails?.phone
                      ? formatInputPhoneNumber(
                          data?.surgeonDetails?.phone.trim()
                        )
                      : "NA"
                  }
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <AiOutlineFieldNumber color={getRandomColor()} size={20} />
                  }
                  label={"NPI"}
                  name={data?.npi || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<MdAlternateEmail color={getRandomColor()} size={20} />}
                  label={"Email"}
                  name={data?.surgeonDetails?.email || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbLicense color={getRandomColor()} size={20} />}
                  label={"License"}
                  name={data?.surgeonDetails?.license || "NA"}
                />

                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<SiSemrush color={getRandomColor()} size={20} />}
                  label={"Primary Taxonomy"}
                  name={data?.surgeonDetails?.primaryTaxonomy || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<SiSemrush color={getRandomColor()} size={20} />}
                  label={"Secondary Taxonomy"}
                  name={data?.surgeonDetails?.secondaryTaxonomy || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<MdFolderSpecial color={getRandomColor()} size={20} />}
                  label={"Primary Specialty"}
                  name={data?.surgeonDetails?.primarySpecialty?.name || "NA"}
                />
              </div>

              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<MdFolderSpecial color={getRandomColor()} size={20} />}
                  label={"Sub Specialty"}
                  name={data?.surgeonDetails?.primarySpecialty?.name || "NA"}
                />

                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CiHospital1 color={getRandomColor()} size={20} />}
                  label={"Facility"}
                  name={data?.surgeonDetails?.facility?.facilityName || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbUserCircle color={getRandomColor()} size={20} />}
                  label={"Financial Clearance"}
                  name={data?.surgeonDetails?.financialClearance || "NA"}
                />
              </div>
              <hr
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />

              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "20px",
                }}
              >
                <div className="col-1-1 info-row-column">
                  <div className="flex center-left" style={{ gap: "10px" }}>
                    <div>
                      <LuBadgeInfo color={getRandomColor()} size={20} />
                    </div>
                    <div
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <p
                        className="f-w-300"
                        style={{
                          color: "#aaaaaa",
                          fontSize: "10px",
                          lineHeight: 1,
                          paddingBottom: "2px",
                        }}
                      >
                        Note
                      </p>
                      <p
                        style={{
                          whiteSpace: "normal",
                          overflowWrap: "break-word",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                        }}
                      >
                        {data?.billingNotes || "NA"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  label={"Created At"}
                  name={
                    data?.createdAt ? formatServerDate(data?.createdAt) : "NA"
                  }
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  label={"Updated At "}
                  name={
                    data?.updatedAt ? formatServerDate(data?.updatedAt) : "NA"
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {permissions?.isUpdateAllowed && (
          <NavLink
            to={
              data?.id
                ? `/edit-surgeon?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                : "#"
            }
            state={data?.id ? { id: data?.id } : null}
          >
            <ButtonLightTextIcon
              title="Edit Details"
              onClick={() => setShow(false)}
              icon={<FiEdit />}
            />
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default SurgeonPage;
