import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import { useNavigate } from "react-router-dom";
import { handleApiCall } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import useQueryParams from "../../hooks/useQueryParams";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";

const validationSchema = Yup.object({
	name: Yup.string().required("Category  name is required"),
});

function UpdateForm({ category }) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { addToastMessage } = useUI();
	const [status, setStatus] = useState("");
	const [initialValues, setInitialValues] = useState({
		id: null,
		code: "",
		name: "",
	});
	const { queryParams, updateQueryParam } = useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";

	useEffect(() => {
		setInitialValues({
			id: category?.id,
			code: category?.code,
			name: category?.name,
		});
	}, [category]);

	const updatecategory = async (values) => {
		setLoading(true);
		setStatus("");

		if (!values?.id) {
			return false;
		}
		try {
			const payload = {
				name: values.name,
				id: values.id,
			};

			const response = await handleApiCall(
				`${API_URL}/api/1.0.0/knowledge-base-category/${category?.id}`,
				{
					method: "PUT",
					body: payload,
				}
			);
			if (response.responseStatus == true) {
				setStatus(
					"You have successfully updated the category, thank you."
				);
				addToastMessage({
					status: true,
					message: `You have successfully updated a category, thank you.`,
				});
				if (nextUrl) {
					navigate(nextUrl);
				} else {
					navigate("/knowledge-base-categories");
				}
				return true;
			} else {
				setStatus(
					`${response.responseDescription || "Something went wrong, please try again later."}`
				);
				addToastMessage({
					status: false,
					message: `${response.responseDescription || "Something went wrong, please try again later."}`,
				});
				return false;
			}
		} catch (e) {
			console.error("Error:", e);
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={validationSchema}
			onSubmit={async (values, { resetForm }) => {
				const api = await updatecategory(values);
				if (api) {
					resetForm();
				}
				setLoading(false);
			}}>
			{({ handleSubmit, validateForm, errors, touched }) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<Label title='Code' required={true} />
									<Field
										name='name'
										type='text'
										disabled
										value={initialValues.code}
										className={`primary ${errors.name && touched.name ? "error" : ""}`}
									/>
								</tr>
								<tr>
									<td>
										<Label
											title='Category Name'
											required={true}
										/>
										<Field
											name='name'
											type='text'
											placeholder='Enter Category Name'
											className={`primary ${errors.name && touched.name ? "error" : ""}`}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => {
								if (nextUrl) {
									navigate(nextUrl);
								} else {
									navigate("/knowledge-base-categories");
								}
							}}
							icon={
								<TbArrowBackUp />
							}
						/>
						<ButtonTextIcon
							type='submit'
							disabled={loading}
							title='Update Now'
							onClick={async () => {
								const errors = await validateForm();
								if (Object.keys(errors).length > 0) {
									const firstErrorMessage =
										Object.values(errors)[0];
									addToastMessage({
										status: false,
										message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
									});
								}
								handleSubmit();
							}}
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<FiArrowRightCircle />
								)
							}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default UpdateForm;
