import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useEffect, useRef, useState } from "react";
import {
  API_URL,
  CREDENTIAL_OPTIONS,
  DEGREE_OPTIONS,
  DEGREE_STATUS_OPTIONS,
  ROLE_OPTIONS,
  STATUS_USER_OPTIONS,
  ASSISTANT_PTO_DROP_DOWN_OPTIONS,
  ASSISTANT_SCREENING_DROP_DOWN_OPTIONS,
} from "../../utilities/constants";
import PaginatedDropdown, {
  mapOptions,
} from "../../components/ui/PaginatedDropdown";
import DragFileUpload from "../../components/ui/DragFileUpload";
import {
  convertToMMDDYYYY,
  formatDateTime,
  formatServerDate,
  handleApiCall,
} from "../../utilities/helpers";
import Tabs from "./Tabs";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import PhoneNumberInput from "../../components/ui/PhoneNumberInput";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Label from "../../components/ui/Label";
import DatePickerInput from "../../components/ui/DatePickerInput";
import FullScreenFileViewerModal from "../../components/ui/FullScreenFileViewerModal";
import CustomModal from "../../components/common/CustomModal";
import ZipCodeInput from "../../components/ui/ZipCodeInput";
import CustomSelect from "../../components/ui/CustomSelect";
import { FiArrowRightCircle, FiPlusCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { FcInfo } from "react-icons/fc";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { LuEye } from "react-icons/lu";

const AddForm = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [isZipDataFilled, setIsZipDataFilled] = useState(false);
  const [isZipDataFilled2, setIsZipDataFilled2] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const nextUrl = decodeURIComponent(queryParams.get("next")) || "";

  const [initialValues, setInitialValues] = useState({
    facilities_detail: [],
    vaccinations_detail: [],
    background_checks_detail: [],
    pto_detail: [],
    degrees_detail: [],
    documents: [],
    screenings: [],
    firstName: "", //required
    lastName: "", //required
    preferredName: "",
    workEmail: "", // required|email|unique:users
    personalEmail: "",
    workCellPhone: "",
    cellPhone: "",
    status: "",
    homeAddress: "", // "practice1Address1": "456 Practice Rd",
    homeZip: "", // "practice1Zip": "12345",
    homeState: null, // "practice1State": "NY",
    homeCity: null, // "practice1City": "New York",
    practice1Address2: "",
    practice1Address3: "",
    practice2Address1: "",
    practice2Address2: "",
    practice2State: null,
    practice2City: null,
    practice2Zip: "",
    drivingLicense: "",
    drivingLicenseState: null, //required
    drivingIssueDate: "",
    drivingExpiryDate: "",
    npi: "",
    primaryTaxonomy: "",
    secondaryTaxonomy: "",
    certificate: null, //required
    certificateNumber: "",
    region: null, //required
    licenseIssueDate: "",
    licenseExpiryDate: "",
    joiningDate: "",
    terminationDate: "",
    role: "",
    credential: "",
  });
  const { addToastMessage } = useUI();
  // const queryParams = new URLSearchParams(location.search);

  const tab = queryParams.get("tab") || "basic-information";
  const updateQueryParam = (key, value) => {
    const id = location.state?.id;
    value ? queryParams.set(key, value) : queryParams.delete(key);
    const queryString = queryParams.toString();
    navigate(
      {
        pathname: location.pathname,
        search: queryString,
      },
      { state: { id: id }, replace: true }
    );
  };

  // ====== FILTER QUERY PARAMS

  useEffect(() => {
    setInitialValues({
      facilities_detail: data.physicianAssistant?.facilities
        ? data.physicianAssistant?.facilities?.map((detail) => ({
            attachments: detail.attachment,
            s3attachment: detail.s3attachment,
            date_expiry: detail.expiryDate,
            date_renewed: detail.renewalDate,
            fee_paid: detail.feePaid,
            facility: { label: detail.name, value: detail.id },
          }))
        : [],
      vaccinations_detail: data.physicianAssistant?.vaccinations
        ? data.physicianAssistant?.vaccinations.map((detail) => ({
            vaccine: { label: detail.name, value: detail.id },
            name: detail.name,
            date_administer: detail.administeredDate,
            date_expiry: detail.expiryDate,
            attachments: detail.attachment,
            s3attachment: detail.s3attachment,
          }))
        : [],
      background_checks_detail: data.physicianAssistant?.backgroundChecks
        ? data.physicianAssistant?.backgroundChecks.map((detail) => ({
            notes: detail.notes,
            fee_paid: detail.feePaid,
            attachments: detail.attachment,
            s3attachment: detail.s3attachment,
            date_performed: detail.datePerformed,
          }))
        : [],
      pto_detail: data.physicianAssistant?.ptos
        ? data.physicianAssistant?.ptos.map((detail) => ({
            date: detail.date,
            endDate: detail.endDate,
            type: {
              label: detail?.type || "",
              value: detail?.type || "",
            },
            amount: detail.amount,
            attachments: detail.attachment,
            s3attachment: detail.s3attachment,
          }))
        : [],
      degrees_detail: data.physicianAssistant?.degrees
        ? data.physicianAssistant?.degrees.map((detail) => ({
            date_aquired: detail.dateAcquired,
            date_expiry: detail.dateExpired,
            degree: detail.degree || "",
            nameOfSchool: detail.nameOfSchool || "",
            state: {
              label: detail?.state || "",
              value: detail?.state || "",
            },
            degree_status: detail.status,
            attachments: detail.attachment,
            s3attachment: detail.s3attachment,
          }))
        : [],
      documents: data.physicianAssistant?.documents
        ? data.physicianAssistant?.documents.map((detail) => ({
            documentPath: detail.documentPath,
            documentS3Url: detail.documentS3Url,
            name: detail.name,
            notes: detail.notes,
            createdAt: detail.createdAt,
          }))
        : [],
      screenings: data.physicianAssistant?.screenings
        ? data.physicianAssistant?.screenings.map((detail) => ({
            name: { label: detail.name, value: detail.name },
            administeredDate: detail.administeredDate,
            expiryDate: detail.expiryDate,
            attachments: detail.attachments,
            attachmentS3URL: detail.attachmentS3URL,
          }))
        : [],
      firstName: data.name || "", // required
      lastName: data.lastName || "", // required
      preferredName: data.physicianAssistant?.preferredName || "",
      workEmail: data.physicianAssistant?.workEmail || "", // required|email|unique:users
      personalEmail: data.physicianAssistant?.personalEmail || "",
      workCellPhone: data.physicianAssistant?.workCellPhone || "",
      cellPhone: data.physicianAssistant?.cellPhone || "",
      status: data.physicianAssistant?.statusId === 1 ? "1" : "0",
      homeAddress: data.physicianAssistant?.homeAddress || "",
      homeZip: data.physicianAssistant?.homeZip || "",
      homeState: data.physicianAssistant?.homeState
        ? {
            value: data.physicianAssistant?.homeState.code,
            label: data.physicianAssistant?.homeState.name,
          }
        : null,
      homeCity: data.physicianAssistant?.homeCity
        ? {
            value: data.physicianAssistant?.homeCity.name,
            label: data.physicianAssistant?.homeCity.name,
          }
        : null,
      practice1Address2: data.physicianAssistant?.practice1Address2 || "",
      practice1Address3: data.physicianAssistant?.practice1Address3 || "",
      practice2Address1: data.physicianAssistant?.practice2Address1 || "",
      practice2Address2: data.physicianAssistant?.practice2Address2 || "",
      practice2State: data.physicianAssistant?.practice2State
        ? {
            value: data.physicianAssistant?.practice2State.code,
            label: data.physicianAssistant?.practice2State.name,
          }
        : null,
      practice2City: data.physicianAssistant?.practice2City
        ? {
            value: data.physicianAssistant?.practice2City.name,
            label: data.physicianAssistant?.practice2City.name,
          }
        : null,
      practice2Zip: data.physicianAssistant?.practice2Zip || "",
      drivingLicense: data.physicianAssistant?.drivingLicense || "",
      drivingLicenseState: data.physicianAssistant?.drivingLicenseState
        ? {
            value: data.physicianAssistant?.drivingLicenseState.code,
            label: data.physicianAssistant?.drivingLicenseState.name,
          }
        : null, // required
      drivingIssueDate: data.physicianAssistant?.drivingIssueDate || "",
      drivingExpiryDate: data.physicianAssistant?.drivingExpiryDate || "",
      npi: data.physicianAssistant?.npi || "",
      primaryTaxonomy: data.physicianAssistant?.primaryTaxonomy || "",
      secondaryTaxonomy: data.physicianAssistant?.secondaryTaxonomy || "",
      certificate: data.physicianAssistant?.certificate
        ? {
            value: data.physicianAssistant?.certificate.id,
            label: data.physicianAssistant?.certificate.name,
          }
        : null, // required
      certificateNumber: data.physicianAssistant?.certificateNumber || "",
      region: data.physicianAssistant?.region
        ? {
            value: data.physicianAssistant?.region.id,
            label: data.physicianAssistant?.region.name,
          }
        : null, // required
      licenseIssueDate: data.physicianAssistant?.licenseIssueDate || "",
      licenseExpiryDate: data.physicianAssistant?.licenseExpiryDate || "",
      joiningDate: data.physicianAssistant?.joiningDate || "",
      terminationDate: data.physicianAssistant?.terminationDate || "",
      credential: data?.physicianAssistant?.credential,
    });
  }, [data]);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "First Name must be at least 2 characters long")
      .required("First name is required"),
    lastName: Yup.string()
      .min(2, "Last Name must be at least 2 characters long")
      .required("Last name is required"),
    joiningDate: Yup.string().required("Start Date is required"),
    terminationDate: Yup.date()
      .test(
        "is-after-joiningDate",
        "Termination date must be greater then Start date",
        function (value) {
          const { joiningDate } = this.parent;
          return (
            !joiningDate || !value || new Date(value) > new Date(joiningDate)
          );
        }
      )
      .notRequired(""),
    workEmail: Yup.string()
      .email("Work email must be a valid email")
      .required("Work email is required"),
    drivingLicenseState: Yup.mixed()
      .nullable()
      .test(
        "drivingLicenseState-required",
        "Driving license state is required",
        function (value) {
          const { drivingLicense } = this.parent;
          if (drivingLicense) {
            return value !== null && value.value;
          }
          return true;
        }
      ),
    certificate: Yup.mixed()
      .nullable()
      .test(
        "certificate-required",
        "Certificate is required",
        function (value) {
          return value !== null && value.value;
        }
      ),
    region: Yup.mixed()
      .nullable()
      .test("region-required", "Region is required", function (value) {
        return value !== null && value.value;
      }),
    drivingIssueDate: Yup.string().notRequired(),
    drivingExpiryDate: Yup.date()
      .test(
        "is-after-drivingIssueDate",
        "Driver's license expiry date must be greater then Driver's license issue date",
        function (value) {
          const { drivingIssueDate } = this.parent;
          return (
            !drivingIssueDate ||
            !value ||
            new Date(value) > new Date(drivingIssueDate)
          );
        }
      )
      .notRequired(""),
    licenseIssueDate: Yup.string().notRequired(),
    licenseExpiryDate: Yup.date()
      .test(
        "is-after-licenseIssueDate",
        "Certificate expiry date must be greater then Certificate issue date",
        function (value) {
          const { licenseIssueDate } = this.parent;
          return (
            !licenseIssueDate ||
            !value ||
            new Date(value) > new Date(licenseIssueDate)
          );
        }
      )
      .notRequired(""),
  });

  const updatePhysicianAssistant = async (values) => {
    setLoading(true);
    setStatus("");
    try {
      const payload = {
        credential: values?.credential || "",
        facilities: values?.facilities_detail.length
          ? values?.facilities_detail?.map((detail) => ({
              attachment: detail.attachments,
              expiryDate: detail.date_expiry
                ? convertToMMDDYYYY(detail.date_expiry)
                : null,
              renewalDate: detail.date_renewed
                ? convertToMMDDYYYY(detail.date_renewed)
                : null,
              feePaid: detail.fee_paid
                ? parseFloat(detail.fee_paid.toFixed(2))
                : 0.0,
              id: detail.facility.value,
            }))
          : null,
        vaccinations: values?.vaccinations_detail.length
          ? values?.vaccinations_detail.map((detail) => ({
              id: detail.vaccine.value,
              name: detail.vaccine.label,
              administeredDate: detail.date_administer
                ? convertToMMDDYYYY(detail.date_administer)
                : null,
              expiryDate: detail.date_expiry
                ? convertToMMDDYYYY(detail.date_expiry)
                : null,
              attachment: detail.attachments,
            }))
          : null,
        backgroundChecks: values?.background_checks_detail.length
          ? values?.background_checks_detail.map((detail) => ({
              notes: detail.notes || " ",
              feePaid: detail.fee_paid
                ? parseFloat(detail.fee_paid.toFixed(2))
                : 0.0,
              attachment: detail.attachments,
              datePerformed: detail.date_performed
                ? convertToMMDDYYYY(detail.date_performed)
                : null,
            }))
          : null,
        ptos: values?.pto_detail.length
          ? values?.pto_detail.map((detail) => ({
              date: detail.date ? convertToMMDDYYYY(detail.date) : null,
              endDate: detail.endDate
                ? convertToMMDDYYYY(detail.endDate)
                : null,
              type: detail.type.value || null,
              amount: detail.amount ? parseInt(detail.amount) : 0,
              attachment: detail.attachments,
            }))
          : null,
        screenings: values?.screenings.length
          ? values?.screenings.map((detail) => ({
              name: detail.name.value || null,
              administeredDate: detail.administeredDate || null,
              expiryDate: detail.expiryDate || null,
              attachments: detail.attachments,
            }))
          : null,
        documents: values?.documents.length
          ? values?.documents.map((detail) => ({
              documentPath: detail.documentPath || null,
              documentS3Url: detail.documentS3Url || null,
              name: detail.name || null,
              notes: detail.notes || null,
              createdAt: detail.createdAt || null,
            }))
          : null,
        degrees: values?.degrees_detail.length
          ? values?.degrees_detail.map((detail) => ({
              dateAcquired: detail.date_aquired
                ? convertToMMDDYYYY(detail.date_aquired)
                : null,
              dateExpired: detail.date_expiry
                ? convertToMMDDYYYY(detail.date_expiry)
                : null,
              degree: detail.degree || "",
              nameOfSchool: detail.nameOfSchool || "",
              state: detail.state.value,
              status: detail.degree_status,
              attachment: detail.attachments,
            }))
          : null,
        firstName: values?.firstName || "",
        lastName: values?.lastName || "",
        preferredName: values?.preferredName || "",
        workEmail: values?.workEmail || "",
        personalEmail: values?.personalEmail || "",
        workCellPhone: values?.workCellPhone || "",
        cellPhone: values?.cellPhone || "",
        status: Number(values?.status),
        homeAddress: values?.homeAddress || "",
        homeZip: values?.homeZip || "",
        homeState: values?.homeState?.value || "",
        homeCity: values?.homeCity?.label || "",
        practice1Address2: values?.practice1Address2 || "",
        practice1Address3: values?.practice1Address3 || "",
        practice2Address1: values?.practice2Address1 || "",
        practice2Address2: values?.practice2Address2 || "",
        practice2State: values?.practice2State?.value || "",
        practice2City: values?.practice2City?.label || "",
        practice2Zip: values?.practice2Zip || "",
        drivingLicense: values?.drivingLicense || "",
        drivingLicenseState: values?.drivingLicenseState?.value || "",
        drivingIssueDate: values?.drivingIssueDate
          ? convertToMMDDYYYY(values.drivingIssueDate)
          : null,
        drivingExpiryDate: values?.drivingExpiryDate
          ? convertToMMDDYYYY(values.drivingExpiryDate)
          : null,
        npi: values?.npi || "",
        primaryTaxonomy: values?.primaryTaxonomy || "",
        secondaryTaxonomy: values?.secondaryTaxonomy || "",
        certificate: values?.certificate?.value || "",
        certificateNumber: values?.certificateNumber || "",
        region: values?.region?.value || "",
        licenseIssueDate: values?.licenseIssueDate
          ? convertToMMDDYYYY(values.licenseIssueDate)
          : null,
        licenseExpiryDate: values?.licenseExpiryDate
          ? convertToMMDDYYYY(values.licenseExpiryDate)
          : null,
        joiningDate: values?.joiningDate
          ? convertToMMDDYYYY(values.joiningDate)
          : null,
        terminationDate: values?.terminationDate
          ? convertToMMDDYYYY(values.terminationDate)
          : null,
        role: values?.role || "",
      };

      const response = await handleApiCall(
        API_URL +
          `/api/1.0.0/physician-assistant/${data.physicianAssistant.id}`,
        {
          method: "PUT",
          body: payload,
        }
      );
      if (response.responseStatus === true) {
        setStatus(
          "You have successfully updated a physician assistant, thank you."
        );
        addToastMessage({
          status: true,
          message: `You have successfully updated a physician assistant, thank you.`,
        });
        if (nextUrl) {
          navigate(nextUrl);
        } else {
          navigate("/physicians_assistance");
        }
        return true;
      } else {
        setStatus(
          `${response.responseDescription || "Something went wrong, please try again later."}`
        );
        addToastMessage({
          status: false,
          message: `${response.responseDescription || "Something went wrong, please try again later."}`,
        });
        return false;
      }
    } catch (e) {
      setStatus("Something went wrong, please try again later.");
      addToastMessage({
        status: false,
        message: `Something went wrong, please try again later.`,
      });
      console.error("Error:", e);
      return false;
    }
  };

  const labelStateFunc = (o) => `${o.name}`;
  const labelCityFunc = (o) => `${o.name}`;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await updatePhysicianAssistant(values);
        if (api) {
          resetForm();
        }
        setLoading(false);
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
        validateForm,
        setFieldTouched,
      }) => (
        <Form>
          <div className="col-1-1">
            <Tabs
              options={[
                {
                  name: "Basic Information",
                  slug: "basic-information",
                  content: (
                    <table>
                      <tbody>
                        <br />
                        <tr>
                          <td width="25%">
                            <Label title="First Name" required={true} />
                            <Field
                              type="text"
                              placeholder="Enter First Name"
                              name="firstName"
                              className={`col-1-1 primary ${
                                errors.firstName && touched.firstName
                                  ? "error"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="firstName"
                              component="p"
                              className="error-messages"
                            />
                          </td>
                          <td width="25%">
                            <Label title="Last Name" required={true} />
                            <Field
                              type="text"
                              placeholder="Enter Last Name"
                              name="lastName"
                              className={`col-1-1 primary ${
                                errors.lastName && touched.lastName
                                  ? "error"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="lastName"
                              component="p"
                              className="error-messages"
                            />
                          </td>
                          <td width="25%">
                            <Label title="Preferred Name" />
                            <Field
                              type="text"
                              placeholder="Enter Preferred Name"
                              name="preferredName"
                              className={`col-1-1 primary ${
                                errors.preferredName && touched.preferredName
                                  ? "error"
                                  : ""
                              }`}
                            />
                          </td>
                          <td width="25%">
                            <Label title="Work Email" required={true} />
                            <Field
                              type="email"
                              placeholder="Enter Work Email"
                              name="workEmail"
                              className={`col-1-1 primary ${
                                errors.workEmail && touched.workEmail
                                  ? "error"
                                  : ""
                              }`}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="25%">
                            <Label title="Personal Email" />
                            <Field
                              type="email"
                              placeholder="Enter Personal Email"
                              name="personalEmail"
                              className={`col-1-1 primary ${
                                errors.personalEmail && touched.personalEmail
                                  ? "error"
                                  : ""
                              }`}
                            />
                          </td>
                          <td width="25%">
                            <Label title="Work Phone" />
                            <PhoneNumberInput
                              name="workCellPhone"
                              placeholder="Eg: 888-888-8888"
                              error={
                                errors.workCellPhone && touched.workCellPhone
                              }
                              value={values.workCellPhone}
                              setTouched={() =>
                                setFieldTouched("workCellPhone", true)
                              }
                              callback={(v) =>
                                setFieldValue("workCellPhone", v)
                              }
                            />
                            <ErrorMessage
                              name="workCellPhone"
                              component="p"
                              className="error-messages"
                            />
                          </td>
                          <td width="25%">
                            <Label title="Cell Phone" />
                            <PhoneNumberInput
                              name="cellPhone"
                              placeholder="Eg: 888-888-8888"
                              error={errors.cellPhone && touched.cellPhone}
                              value={values.cellPhone}
                              setTouched={() =>
                                setFieldTouched("cellPhone", true)
                              }
                              callback={(v) => setFieldValue("cellPhone", v)}
                            />
                            <ErrorMessage
                              name="cellPhone"
                              component="p"
                              className="error-messages"
                            />
                          </td>
                          <td width="25%">
                            <Label title="Region" required={true} />
                            <PaginatedDropdown
                              datakey="regionList"
                              url={`${API_URL}/api/1.0.0/region?`}
                              mapOptions={(records) =>
                                mapOptions(records, "id", (o) => `${o.name}`)
                              }
                              error={errors.region && touched.region}
                              setTouched={() => setFieldTouched("region", true)}
                              placeHolder="Search"
                              selectedValue={values.region}
                              onChange={(v) => {
                                setFieldValue("region", v);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <Label title="Home Address" />
                            <Field
                              as="textarea"
                              placeholder="Enter Home Address"
                              name="homeAddress"
                              maxLength={255}
                              className={`col-1-1 primary ${
                                errors.homeAddress && touched.homeAddress
                                  ? "error"
                                  : ""
                              }`}
                            />
                            <p
                              className="flex center-right"
                              style={{
                                fontSize: "12px",
                                color:
                                  values.homeAddress.length >= 255
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {255 - parseInt(values.homeAddress.length)}{" "}
                              characters left
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td width="25%">
                            <Label title="Zip" />
                            <ZipCodeInput
                              name="homeZip"
                              clearFields={() => {
                                setFieldValue("homeState", null);
                                setFieldValue("homeCity", null);
                              }}
                              onZipDetailsFetched={(data) => {
                                setIsZipDataFilled(true);
                                if (data.country.code != "US") return;
                                setFieldValue("homeState", {
                                  value: data.state.code,
                                  label: data.state.name,
                                  obj: data.state,
                                });
                                setFieldValue("homeCity", {
                                  value: data.city.code,
                                  label: data.city.name,
                                  obj: data.city,
                                });
                              }}
                            />
                          </td>
                          <td width="25%">
                            <Label title="State" />
                            <PaginatedDropdown
                              datakey="records"
                              url={`${API_URL}/api/1.0.0/reference/states/US?`}
                              mapOptions={(records) =>
                                mapOptions(records, "code", labelStateFunc)
                              }
                              error={errors.homeState && touched.homeState}
                              setTouched={() =>
                                setFieldTouched("homeState", true)
                              }
                              placeHolder="Search"
                              selectedValue={values.homeState}
                              onChange={(v) => {
                                if (isZipDataFilled)
                                  setFieldValue("homeZip", "");
                                setIsZipDataFilled(false);
                                setFieldValue("homeState", v);
                                setFieldValue("homeCity", null);
                              }}
                            />
                          </td>
                          <td width="25%">
                            <Label title="City" />
                            <PaginatedDropdown
                              datakey="records"
                              url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.homeState?.value}?`}
                              mapOptions={(records) =>
                                mapOptions(records, "name", labelCityFunc)
                              }
                              error={errors.homeCity && touched.homeCity}
                              setTouched={() =>
                                setFieldTouched("homeCity", true)
                              }
                              placeHolder="Search"
                              selectedValue={values.homeCity}
                              onChange={(v) => {
                                if (isZipDataFilled)
                                  setFieldValue("homeZip", "");
                                setIsZipDataFilled(false);
                                setFieldValue("homeCity", v);
                              }}
                            />
                          </td>
                          <td width="25%">
                            <Label title="Role" />
                            <Field
                              as="select"
                              name="role"
                              className={`col-1-1 primary ${
                                errors.role && touched.role ? "error" : ""
                              }`}
                            >
                              <option value="">Select Role</option>
                              {ROLE_OPTIONS.map((d, i) => (
                                <option value={d.value} key={i}>
                                  {d.label}
                                </option>
                              ))}
                            </Field>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" width="50%">
                            <Label title="Practice Address" />
                            <Field
                              as="textarea"
                              placeholder="Practice Address"
                              name="practice2Address1"
                              maxLength={255}
                              className={`col-1-1 primary ${
                                errors.practice2Address1 &&
                                touched.practice2Address1
                                  ? "error"
                                  : ""
                              }`}
                            />
                            <p
                              className="flex center-right"
                              style={{
                                fontSize: "12px",
                                color:
                                  values.practice2Address1.length >= 255
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {255 - parseInt(values.practice2Address1.length)}{" "}
                              characters left
                            </p>
                          </td>
                          <td colSpan="2" width="50%">
                            <Label title="Practice Address 2" />
                            <Field
                              as="textarea"
                              placeholder="Enter Practice Address 2"
                              name="practice2Address2"
                              maxLength={255}
                              className={`col-1-1 primary ${
                                errors.practice2Address2 &&
                                touched.practice2Address2
                                  ? "error"
                                  : ""
                              }`}
                            />
                            <p
                              className="flex center-right"
                              style={{
                                fontSize: "12px",
                                color:
                                  values.practice2Address2.length >= 255
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {255 - parseInt(values.practice2Address2.length)}{" "}
                              characters left
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td width="25%">
                            <Label title="Zip" />
                            <ZipCodeInput
                              name="practice2Zip"
                              clearFields={() => {
                                setFieldValue("practice2State", null);
                                setFieldValue("practice2City", null);
                              }}
                              onZipDetailsFetched={(data) => {
                                setIsZipDataFilled2(true);
                                if (data.country.code != "US") return;
                                setFieldValue("practice2State", {
                                  value: data.state.code,
                                  label: data.state.name,
                                  obj: data.state,
                                });
                                setFieldValue("practice2City", {
                                  value: data.city.code,
                                  label: data.city.name,
                                  obj: data.city,
                                });
                              }}
                            />
                          </td>
                          <td width="25%">
                            <Label title="State" />
                            <PaginatedDropdown
                              datakey="records"
                              url={`${API_URL}/api/1.0.0/reference/states/US?`}
                              mapOptions={(records) =>
                                mapOptions(records, "code", labelStateFunc)
                              }
                              error={
                                errors.practice2State && touched.practice2State
                              }
                              setTouched={() =>
                                setFieldTouched("practice2State", true)
                              }
                              placeHolder="Search"
                              selectedValue={values.practice2State}
                              onChange={(v) => {
                                if (isZipDataFilled2)
                                  setFieldValue("practice2Zip", "");
                                setIsZipDataFilled2(false);
                                setFieldValue("practice2State", v);
                                setFieldValue("practice2City", null);
                              }}
                            />
                          </td>
                          <td width="25%">
                            <Label title="City" />
                            <PaginatedDropdown
                              datakey="records"
                              url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.practice2State?.value}?`}
                              mapOptions={(records) =>
                                mapOptions(records, "name", labelCityFunc)
                              }
                              error={
                                errors.practice2City && touched.practice2City
                              }
                              setTouched={() =>
                                setFieldTouched("practice2City", true)
                              }
                              placeHolder="Search"
                              selectedValue={values.practice2City}
                              onChange={(v) => {
                                if (isZipDataFilled2)
                                  setFieldValue("practice2Zip", "");
                                setIsZipDataFilled2(false);
                                setFieldValue("practice2City", v);
                              }}
                            />
                          </td>
                          <td width="25%">
                            <Label title="Primary Taxonomy" />
                            <Field
                              // as='textarea'
                              type="text"
                              placeholder="Enter Primary Taxonomy"
                              name="primaryTaxonomy"
                              className={`col-1-1 primary ${
                                errors.primaryTaxonomy &&
                                touched.primaryTaxonomy
                                  ? "error"
                                  : ""
                              }`}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="25%">
                            <Label title="Secondary Taxonomy" />
                            <Field
                              type="text"
                              placeholder="Enter Secondary Taxonomy"
                              name="secondaryTaxonomy"
                              className={`col-1-1 primary ${
                                errors.secondaryTaxonomy &&
                                touched.secondaryTaxonomy
                                  ? "error"
                                  : ""
                              }`}
                            />
                          </td>
                          <td width="25%">
                            <Label title="NPI #" />
                            <Field
                              type="text"
                              placeholder="Enter NPI #"
                              name="npi"
                              className={`col-1-1 primary ${
                                errors.npi && touched.npi ? "error" : ""
                              }`}
                            />
                          </td>
                          <td width="25%">
                            <Label title="Credential" />
                            <Field
                              as="select"
                              name="credential"
                              className={`col-1-1 primary ${
                                errors.credential && touched.credential
                                  ? "error"
                                  : ""
                              }`}
                            >
                              <option value="">Select</option>
                              {CREDENTIAL_OPTIONS.map((d, i) => (
                                <option value={d.value} key={i}>
                                  {d.label}
                                </option>
                              ))}
                            </Field>
                          </td>
                          <td width="25%">
                            <Label title="Certificate" required={true} />
                            <PaginatedDropdown
                              datakey="records"
                              url={`${API_URL}/api/1.0.0/reference/certificates?`}
                              mapOptions={(records) =>
                                mapOptions(records, "id", (o) => `${o.name}`)
                              }
                              error={errors.certificate && touched.certificate}
                              setTouched={() =>
                                setFieldTouched("certificate", true)
                              }
                              placeHolder="Search"
                              selectedValue={values.certificate}
                              onChange={(v) => {
                                setFieldValue("certificate", v);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="25%">
                            <Label title="Driver's License" />
                            <Field
                              type="text"
                              placeholder="Enter Driver's License"
                              name="drivingLicense"
                              className={`col-1-1 primary ${
                                errors.drivingLicense && touched.drivingLicense
                                  ? "error"
                                  : ""
                              }`}
                            />
                          </td>
                          <td width="25%">
                            <Label title="Driver's License State" />
                            <PaginatedDropdown
                              datakey="records"
                              url={`${API_URL}/api/1.0.0/reference/states/US?`}
                              mapOptions={(records) =>
                                mapOptions(records, "code", labelStateFunc)
                              }
                              error={
                                errors.drivingLicenseState &&
                                touched.drivingLicenseState
                              }
                              setTouched={() =>
                                setFieldTouched("drivingLicenseState", true)
                              }
                              placeHolder="Search"
                              selectedValue={values.drivingLicenseState}
                              onChange={(v) => {
                                setFieldValue("drivingLicenseState", v);
                              }}
                            />
                          </td>
                          <td width="25%">
                            <Label title="Driver's License Issue Date" />
                            <DatePickerInput
                              value={values.drivingIssueDate}
                              setTouched={() => {
                                setFieldTouched("drivingIssueDate", true);
                              }}
                              callback={(v) => {
                                setFieldValue("drivingIssueDate", v);
                              }}
                              error={
                                errors.drivingIssueDate &&
                                touched.drivingIssueDate
                              }
                            />
                          </td>
                          <td width="25%">
                            <Label title="Driver's License Expiry Date" />
                            <DatePickerInput
                              value={values.drivingExpiryDate}
                              setTouched={() => {
                                setFieldTouched("drivingExpiryDate", true);
                              }}
                              callback={(v) => {
                                setFieldValue("drivingExpiryDate", v);
                              }}
                              error={
                                errors.drivingExpiryDate &&
                                touched.drivingExpiryDate
                              }
                            />
                            <ErrorMessage
                              name="drivingExpiryDate"
                              component="p"
                              className="error-messages"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="25%">
                            <Label title="License Certificate #" />
                            <Field
                              type="text"
                              placeholder="Enter License Certificate #"
                              name="certificateNumber"
                              className={`col-1-1 primary ${
                                errors.certificateNumber &&
                                touched.certificateNumber
                                  ? "error"
                                  : ""
                              }`}
                            />
                          </td>
                          <td width="25%">
                            <Label title="Certificate Issue Date" />
                            <DatePickerInput
                              value={values.licenseIssueDate}
                              setTouched={() => {
                                setFieldTouched("licenseIssueDate", true);
                              }}
                              callback={(v) => {
                                setFieldValue("licenseIssueDate", v);
                              }}
                              error={
                                errors.licenseIssueDate &&
                                touched.licenseIssueDate
                              }
                            />
                          </td>
                          <td width="25%">
                            <Label title="Certificate Expiry Date" />
                            <DatePickerInput
                              value={values.licenseExpiryDate}
                              setTouched={() => {
                                setFieldTouched("licenseExpiryDate", true);
                              }}
                              callback={(v) => {
                                setFieldValue("licenseExpiryDate", v);
                              }}
                              error={
                                errors.licenseExpiryDate &&
                                touched.licenseExpiryDate
                              }
                            />
                            <ErrorMessage
                              name="licenseExpiryDate"
                              component="p"
                              className="error-messages"
                            />
                          </td>
                          <td width="25%">
                            <Label title="Start Date" required={true} />
                            <DatePickerInput
                              value={values.joiningDate}
                              setTouched={() => {
                                setFieldTouched("joiningDate", true);
                              }}
                              callback={(v) => {
                                setFieldValue("joiningDate", v);
                              }}
                              error={errors.joiningDate && touched.joiningDate}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="25%">
                            <Label title="Termination Date" />
                            <DatePickerInput
                              value={values.terminationDate}
                              setTouched={() => {
                                setFieldTouched("terminationDate", true);
                              }}
                              callback={(v) => {
                                setFieldValue("terminationDate", v);
                              }}
                              error={
                                errors.terminationDate &&
                                touched.terminationDate
                              }
                            />
                            <ErrorMessage
                              name="terminationDate"
                              component="p"
                              className="error-messages"
                            />
                          </td>
                        </tr>
                        <br />
                      </tbody>
                    </table>
                  ),
                },
                {
                  name: "Facilities",
                  slug: "facilities",
                  content: (
                    <NestedFacilityForm
                      onAdd={(newData) => {
                        setFieldValue("facilities_detail", [
                          ...values.facilities_detail,
                          newData,
                        ]);
                      }}
                      list={values?.facilities_detail}
                      onRemove={(index) => {
                        const updatedData = values?.facilities_detail.filter(
                          (_, i) => i !== index
                        );
                        setFieldValue("facilities_detail", updatedData);
                      }}
                      setUploaded={setUploaded}
                      uploaded={uploaded}
                    />
                  ),
                },
                {
                  name: "Vaccinations",
                  slug: "vaccinations",
                  content: (
                    <NestedVaccinationForm
                      onAdd={(newData) => {
                        setFieldValue("vaccinations_detail", [
                          ...values.vaccinations_detail,
                          newData,
                        ]);
                      }}
                      list={values?.vaccinations_detail}
                      onRemove={(index) => {
                        const updatedData = values?.vaccinations_detail.filter(
                          (_, i) => i !== index
                        );
                        setFieldValue("vaccinations_detail", updatedData);
                      }}
                      setUploaded={setUploaded}
                      uploaded={uploaded}
                    />
                  ),
                },
                {
                  name: "Screenings",
                  slug: "screenings",
                  content: (
                    <NestedScreeningForm
                      onAdd={(newData) => {
                        setFieldValue("screenings", [
                          ...values.screenings,
                          newData,
                        ]);
                      }}
                      list={values?.screenings}
                      onRemove={(index) => {
                        const updatedData = values?.screenings.filter(
                          (_, i) => i !== index
                        );
                        setFieldValue("screenings", updatedData);
                      }}
                    />
                  ),
                },
                {
                  name: "Background Checks",
                  slug: "background-checks",
                  content: (
                    <NestedBackgroundCheckForm
                      onAdd={(newData) => {
                        setFieldValue("background_checks_detail", [
                          ...values.background_checks_detail,
                          newData,
                        ]);
                      }}
                      list={values?.background_checks_detail}
                      onRemove={(index) => {
                        const updatedData =
                          values?.background_checks_detail.filter(
                            (_, i) => i !== index
                          );
                        setFieldValue("background_checks_detail", updatedData);
                      }}
                      setUploaded={setUploaded}
                      uploaded={uploaded}
                    />
                  ),
                },
                {
                  name: "PTO",
                  slug: "pto",
                  content: (
                    <NestedPTOForm
                      onAdd={(newData) => {
                        setFieldValue("pto_detail", [
                          ...values.pto_detail,
                          newData,
                        ]);
                      }}
                      list={values?.pto_detail}
                      onRemove={(index) => {
                        const updatedData = values?.pto_detail.filter(
                          (_, i) => i !== index
                        );
                        setFieldValue("pto_detail", updatedData);
                      }}
                      setUploaded={setUploaded}
                      uploaded={uploaded}
                    />
                  ),
                },
                {
                  name: "Education",
                  slug: "degrees",
                  content: (
                    <NestedDegreeForm
                      onAdd={(newData) => {
                        setFieldValue("degrees_detail", [
                          ...values.degrees_detail,
                          newData,
                        ]);
                      }}
                      list={values?.degrees_detail}
                      onRemove={(index) => {
                        const updatedData = values?.degrees_detail.filter(
                          (_, i) => i !== index
                        );
                        setFieldValue("degrees_detail", updatedData);
                      }}
                      setUploaded={setUploaded}
                      uploaded={uploaded}
                    />
                  ),
                },
                {
                  name: "Documents",
                  slug: "documents",
                  content: (
                    <NestedDocumentsForm
                      onAdd={(newData) => {
                        setFieldValue("documents", [
                          ...values.documents,
                          newData,
                        ]);
                      }}
                      list={values?.documents}
                      onRemove={(index) => {
                        const updatedData = values?.documents.filter(
                          (_, i) => i !== index
                        );
                        setFieldValue("documents", updatedData);
                      }}
                    />
                  ),
                },
              ].map((o) => ({
                title: o.name,
                id: o.slug,
                content: o.content,
              }))}
              active={tab}
              setActive={(v) => {
                updateQueryParam("tab", v);
              }}
            />
          </div>
          <br />
          <div className="flex" style={{ justifyContent: "space-between" }}>
            <ButtonLightTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => {
                if (nextUrl) {
                  navigate(nextUrl);
                } else {
                  navigate("/physicians_assistance");
                }
              }}
              icon={<TbArrowBackUp />}
            />
            {tab === "basic-information" ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  title="Update Now"
                  onClick={async () => {
                    const errors = await validateForm();
                    if (Object.keys(errors).length > 0) {
                      const firstErrorMessage = Object.values(errors)[0];
                      addToastMessage({
                        status: false,
                        message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                      });
                    }
                    handleSubmit();
                  }}
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  onClick={async () => {
                    const errors = await validateForm();
                    if (!errors || Object.keys(errors).length === 0) {
                      updateQueryParam("tab", "facilities");
                    } else {
                      handleSubmit();
                    }
                  }}
                  title="Next"
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
              </div>
            ) : tab === "facilities" ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  title="Update Now"
                  onClick={async () => {
                    const errors = await validateForm();
                    if (Object.keys(errors).length > 0) {
                      const firstErrorMessage = Object.values(errors)[0];
                      addToastMessage({
                        status: false,
                        message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                      });
                    }
                    handleSubmit();
                  }}
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  onClick={() => {
                    updateQueryParam("tab", "vaccinations");
                  }}
                  title="Next"
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
              </div>
            ) : tab === "vaccinations" ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  title="Update Now"
                  onClick={async () => {
                    const errors = await validateForm();
                    if (Object.keys(errors).length > 0) {
                      const firstErrorMessage = Object.values(errors)[0];
                      addToastMessage({
                        status: false,
                        message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                      });
                    }
                    handleSubmit();
                  }}
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  onClick={() => {
                    updateQueryParam("tab", "screenings");
                  }}
                  title="Next"
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
              </div>
            ) : tab === "screenings" ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  title="Update Now"
                  onClick={async () => {
                    const errors = await validateForm();
                    if (Object.keys(errors).length > 0) {
                      const firstErrorMessage = Object.values(errors)[0];
                      addToastMessage({
                        status: false,
                        message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                      });
                    }
                    handleSubmit();
                  }}
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  onClick={() => {
                    updateQueryParam("tab", "background-checks");
                  }}
                  title="Next"
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
              </div>
            ) : tab === "background-checks" ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  onClick={() => {
                    updateQueryParam("tab", "pto");
                  }}
                  title="Next"
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
              </div>
            ) : tab === "pto" ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  title="Update Now"
                  onClick={async () => {
                    const errors = await validateForm();
                    if (Object.keys(errors).length > 0) {
                      const firstErrorMessage = Object.values(errors)[0];
                      addToastMessage({
                        status: false,
                        message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                      });
                    }
                    handleSubmit();
                  }}
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  onClick={() => {
                    updateQueryParam("tab", "degrees");
                  }}
                  title="Next"
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
              </div>
            ) : tab === "degrees" ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  title="Update Now"
                  onClick={async () => {
                    const errors = await validateForm();
                    if (Object.keys(errors).length > 0) {
                      const firstErrorMessage = Object.values(errors)[0];
                      addToastMessage({
                        status: false,
                        message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                      });
                      updateQueryParam("tab", "basic-information");
                    }
                    handleSubmit();
                  }}
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
                <ButtonTextIcon
                  type="button"
                  disabled={loading || uploaded}
                  onClick={() => {
                    updateQueryParam("tab", "documents");
                  }}
                  title="Next"
                  className="mar-l-a"
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
              </div>
            ) : tab === "documents" ? (
              <ButtonTextIcon
                type="button"
                disabled={loading || uploaded}
                title="Update Now"
                onClick={async () => {
                  const errors = await validateForm();
                  if (Object.keys(errors).length > 0) {
                    const firstErrorMessage = Object.values(errors)[0];
                    addToastMessage({
                      status: false,
                      message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                    });
                    updateQueryParam("tab", "basic-information");
                  }
                  handleSubmit();
                }}
                className="mar-l-a"
                icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
              />
            ) : (
              <ButtonTextIcon
                type="button"
                disabled={loading || uploaded}
                onClick={async () => {
                  const errors = await validateForm();
                  if (!errors || Object.keys(errors).length === 0) {
                    updateQueryParam("tab", "facilities");
                  } else {
                    handleSubmit();
                  }
                }}
                title="Next"
                className="mar-l-a"
                icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
              />
            )}
          </div>
          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const NestedBackgroundCheckForm = ({
  onAdd,
  list,
  onRemove,
  setUploaded,
  uploaded,
}) => {
  const fileUploadRef = useRef();
  const location = useLocation();
  const validationSchema = Yup.object().shape({
    date_performed: Yup.date()
      .required("Date performed is required")
      .typeError("Date performed must be a valid date"),
    notes: Yup.string()
      .required("Notes is required")
      .max(255, "Notes must be less than 255 characters"),
    fee_paid: Yup.number()
      .typeError("Fee paid must be a valid number")
      .required("Fee paid is required")
      .min(0, "Fee paid must be greater than 0")
      .max(999999, "Fee paid must be less than 999999"),
  });
  const handleReset = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFile();
    }
  };
  return (
    <Formik
      initialValues={{
        fee_paid: "",
        date_performed: "",
        notes: "",
        attachments: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        resetForm();
        handleReset();
      }}
    >
      {({
        errors,
        touched,
        setFieldValue,
        values,
        submitForm,
        setFieldTouched,
      }) => (
        <Form>
          <table>
            <tbody>
              <br />
              <tr>
                <td width="50%">
                  <Label title="Date Complete" required={true} />
                  <DatePickerInput
                    value={values.date_performed}
                    setTouched={() => setFieldTouched("date_performed", true)}
                    callback={(date) => {
                      setFieldValue("date_performed", date);
                    }}
                    error={errors.date_performed && touched.date_performed}
                  />
                  <ErrorMessage
                    name="date_performed"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td width="50%">
                  <Label title="Fees Paid" required={true} />
                  <Field
                    type="number"
                    placeholder="Fees Paid"
                    name="fee_paid"
                    className={`col-1-1 primary ${
                      errors.fee_paid && touched.fee_paid ? "error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="fee_paid"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <Label title="Notes" required={true} />
                  <Field
                    as="textarea"
                    placeholder="Enter Notes"
                    maxLength={255}
                    name="notes"
                    className={`col-1-1 primary ${
                      errors.notes && touched.notes ? "error" : ""
                    }`}
                  />
                  <p
                    className="flex center-right"
                    style={{
                      fontSize: "12px",
                      color: values.notes.length >= 255 ? "red" : "black",
                    }}
                  >
                    {255 - parseInt(values.notes.length)} characters left
                  </p>
                  <ErrorMessage
                    name="notes"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="flex center-left" style={{ gap: "8px" }}>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <Label title="Attachment" />
                    </div>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <FcInfo />
                    </div>
                  </div>
                  <DragFileUpload
                    ref={fileUploadRef}
                    fileType={"BACKGROUND_CHECK_DOC"}
                    error={errors.attachments && touched.attachments}
                    afterUpload={(url) => {
                      setFieldValue("attachments", url);
                    }}
                    setUploading={setUploaded}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonTextIcon
                        title="Add Background Check"
                        type="button"
                        disabled={uploaded}
                        onClick={submitForm}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              {list && (
                <tr>
                  <td colSpan="4">
                    <div className="inner-table-date">
                      <table>
                        <thead>
                          <tr>
                            <th>Date Complete</th>
                            <th>Notes</th>
                            <th>Fees Paid</th>
                            <th>Attachments</th>
                            <th
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((contact, index) => (
                            <tr>
                              <td>
                                {contact.date_performed
                                  ? formatServerDate(contact.date_performed)
                                  : "-"}
                              </td>
                              <td>{contact.notes}</td>
                              <td>{contact?.fee_paid?.toFixed(2)}</td>
                              <td>
                                <div className="flex center-rigth">
                                  <div>
                                    <p>
                                      {contact?.attachments?.split("/").pop() ||
                                        "-"}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="flex center-center">
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      onRemove(index);
                                    }}
                                  >
                                    <MdOutlineDeleteSweep
                                      color="red"
                                      size={18}
                                    />
                                  </span>
                                  {contact?.s3attachment && (
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingLeft: "8px",
                                        gap: "8px",
                                      }}
                                    >
                                      <NavLink
                                        to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                          contact?.s3attachment || ""
                                        )}&title=${encodeURIComponent("")}`}
                                        state={{
                                          ...location.state,
                                        }}
                                      >
                                        <span
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                            cursor: "pointer",
                                          }}
                                          title="View file"
                                        >
                                          <LuEye />
                                        </span>
                                      </NavLink>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

const NestedDegreeForm = ({ onAdd, list, onRemove, setUploaded, uploaded }) => {
  const fileUploadRef = useRef();
  const location = useLocation();
  const validationSchema = Yup.object().shape({
    state: Yup.mixed()
      .nullable()
      .test("state-required", "Required", function (value) {
        return value !== null && value.value;
      }),
    degree: Yup.string()
      .required("Degree is required")
      .typeError("Degree must be a valid string"),
    date_aquired: Yup.date()
      .required("Date aquired is required")
      .typeError("Date aquired must be a valid date"),
    date_expiry: Yup.date()
      .test(
        "is-after-date_aquired",
        "Expired Date must be greater then Graduated Date",
        function (value) {
          const { date_aquired } = this.parent;
          return (
            !date_aquired || !value || new Date(value) > new Date(date_aquired)
          );
        }
      )
      .notRequired(),
    // degree_status: Yup.string().required('Degree status is required').typeError('Degree status must be a valid string'),
    nameOfSchool: Yup.string().required("Name of School is required"),
  });
  const handleReset = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFile();
    }
  };

  const labelStateFunc = (o) => `${o.name}`;

  return (
    <Formik
      initialValues={{
        nameOfSchool: "",
        degree: "",
        date_aquired: "",
        date_expiry: "",
        state: null,
        attachments: "",
        degree_status: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        resetForm();
        handleReset();
      }}
    >
      {({
        errors,
        touched,
        setFieldValue,
        values,
        submitForm,
        setFieldTouched,
      }) => (
        <Form>
          <table>
            <tbody>
              <br />
              <tr>
                <td width="33%">
                  <Label title="Name Of School" required={true} />
                  <Field
                    type="text"
                    placeholder="Enter Name of School"
                    name="nameOfSchool"
                    className={`col-1-1 primary ${
                      errors.nameOfSchool && touched.nameOfSchool ? "error" : ""
                    }`}
                  />
                </td>
                <td width="33%">
                  <Label title="Degree" required={true} />
                  <Field
                    as="select"
                    name="degree"
                    className={`col-1-1 primary ${
                      errors.degree && touched.degree ? "error" : ""
                    }`}
                  >
                    <option value="">Select Degree</option>
                    {DEGREE_OPTIONS.map((d, i) => (
                      <option value={d.value} key={i}>
                        {d.label}
                      </option>
                    ))}
                  </Field>
                </td>
                <td width="33%">
                  <Label title="Date Graduated " required={true} />
                  <DatePickerInput
                    value={values.date_aquired}
                    setTouched={() => setFieldTouched("date_aquired", true)}
                    callback={(date) => {
                      setFieldValue("date_aquired", date);
                    }}
                    error={errors.date_aquired && touched.date_aquired}
                  />
                </td>
              </tr>
              <tr>
                <td width="33%">
                  <Label title="Date Expired - If Applicable" />
                  <DatePickerInput
                    value={values.date_expiry}
                    setTouched={() => setFieldTouched("date_expiry", true)}
                    callback={(date) => {
                      setFieldValue("date_expiry", date);
                    }}
                    error={errors.date_expiry && touched.date_expiry}
                  />
                  <ErrorMessage
                    name="date_expiry"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td width="33%">
                  <Label title="State" required={true} />
                  <PaginatedDropdown
                    datakey="records"
                    url={`${API_URL}/api/1.0.0/reference/states/US?`}
                    mapOptions={(records) =>
                      mapOptions(records, "code", labelStateFunc)
                    }
                    error={errors.state && touched.state}
                    setTouched={() => setFieldTouched("state", true)}
                    placeHolder="Search"
                    selectedValue={values.state}
                    onChange={(v) => {
                      setFieldValue("state", v);
                    }}
                  />
                </td>
                <td width="33%">
                  <Label title="Degree Status" />
                  <Field
                    as="select"
                    name="degree_status"
                    className={`col-1-1 primary ${
                      errors.degree_status && touched.degree_status
                        ? "error"
                        : ""
                    }`}
                  >
                    <option value="">Select Degree status</option>
                    {DEGREE_STATUS_OPTIONS.map((d, i) => (
                      <option value={d.value} key={i}>
                        {d.label}
                      </option>
                    ))}
                  </Field>
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div className="flex center-left" style={{ gap: "8px" }}>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <Label title="Attachment" />
                    </div>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <FcInfo />
                    </div>
                  </div>
                  <DragFileUpload
                    ref={fileUploadRef}
                    fileType={"DEGREE_DOC"}
                    error={errors.attachments && touched.attachments}
                    afterUpload={(url) => {
                      setFieldValue("attachments", url);
                    }}
                    setUploading={setUploaded}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonTextIcon
                        title="Add Degree"
                        type="button"
                        disabled={uploaded}
                        onClick={submitForm}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              {list && (
                <tr>
                  <td colSpan="4">
                    <div className="inner-table-date">
                      <table>
                        <thead>
                          <tr>
                            <th>Name Of School</th>
                            <th>Degree</th>
                            <th>Date Graduated</th>
                            <th>Date Expired</th>
                            <th>State</th>
                            <th>Status</th>
                            <th>Attachments</th>
                            <th
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((contact, index) => (
                            <tr>
                              <td>{contact.nameOfSchool}</td>
                              <td>{contact.degree}</td>
                              <td>
                                {contact.date_aquired
                                  ? formatServerDate(contact.date_aquired)
                                  : "-"}
                              </td>
                              <td>
                                {contact.date_expiry
                                  ? formatServerDate(contact.date_expiry)
                                  : "-"}
                              </td>
                              <td>{contact.state.label}</td>
                              <td>{contact.degree_status}</td>
                              <td>
                                <div className="flex center-rigth">
                                  <div>
                                    <p>
                                      {contact?.attachments?.split("/").pop() ||
                                        "-"}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="flex center-center">
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      onRemove(index);
                                    }}
                                  >
                                    <MdOutlineDeleteSweep
                                      color="red"
                                      size={18}
                                    />
                                  </span>
                                  {contact?.s3attachment && (
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingLeft: "8px",
                                        gap: "8px",
                                      }}
                                    >
                                      <NavLink
                                        to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                          contact?.s3attachment || ""
                                        )}&title=${encodeURIComponent("")}`}
                                        state={{
                                          ...location.state,
                                        }}
                                      >
                                        <span
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                            cursor: "pointer",
                                          }}
                                          title="View file"
                                        >
                                          <LuEye />
                                        </span>
                                      </NavLink>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

const NestedPTOForm = ({ onAdd, list, onRemove, setUploaded, uploaded }) => {
  const fileUploadRef = useRef();
  const location = useLocation();
  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .required("Date is required")
      .typeError("Date must be a valid date"),
    endDate: Yup.date()
      .required("End Date is required")
      .typeError("End Date must be a valid date")
      .min(Yup.ref("date"), "End Date must be greater than Start Date"),
    type: Yup.object().required("Type is required"),
    amount: Yup.number()
      .typeError("Hours must be a valid number")
      .required("Hours is required")
      .moreThan(0, "Hours must be at least 0") // Ensures hours are not less than 0
      .max(24, "Hours must not be more than 24") // Ensures hours are not more than 24
      .test(
        "is-integer",
        "Hours must be a whole number (no decimals allowed)",
        (value) => /^\d+$/.test(value?.toString()) // Strictly allows only whole numbers (no decimals)
      ),
  });
  const handleReset = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFile();
    }
  };
  const labelVaccinationFunc = (o) => `${o.facilityName}`;
  return (
    <Formik
      initialValues={{
        date: "",
        endDate: "",
        type: null,
        amount: "",
        attachments: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        resetForm();
        handleReset();
      }}
    >
      {({
        errors,
        touched,
        setFieldValue,
        values,
        submitForm,
        setFieldTouched,
        validateForm,
      }) => (
        <Form>
          <table>
            <tbody>
              <br />
              <tr>
                <td width="50%">
                  <Label title="Start Date" required={true} />
                  <DatePickerInput
                    value={values.date}
                    setTouched={() => {
                      setFieldTouched("date", true);
                    }}
                    callback={(v) => {
                      setFieldValue("date", v);
                    }}
                    error={errors.date && touched.date}
                  />
                  <ErrorMessage
                    name="date"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td>
                  <Label title="End Date" required={true} />
                  <DatePickerInput
                    value={values.endDate}
                    setTouched={() => {
                      setFieldTouched("endDate", true);
                    }}
                    callback={(v) => {
                      setFieldValue("endDate", v);
                    }}
                    error={errors.endDate && touched.endDate}
                  />
                  <ErrorMessage
                    name="endDate"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <Label title="Type" required={true} />
                  <CustomSelect
                    options={ASSISTANT_PTO_DROP_DOWN_OPTIONS.map((o) => {
                      return {
                        value: o.value,
                        label: o.label.toUpperCase(),
                      };
                    })}
                    name="type"
                    placeHolder="Select Type"
                    selectedValue={values.type}
                    error={errors.type && touched.type}
                    setTouched={() => setFieldTouched("type", true)}
                    onChange={async (v) => {
                      await setFieldValue("type", v);
                      await validateForm();
                    }}
                  />
                  <ErrorMessage
                    name="type"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td>
                  <Label title="Hours per Day" required={true} />
                  <Field
                    type="text"
                    placeholder="Eg: 5"
                    name="amount"
                    className={`col-1-1 primary ${errors.amount && touched.amount ? "error" : ""}`}
                  />
                  <ErrorMessage
                    name="amount"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div className="flex center-left" style={{ gap: "8px" }}>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <Label title="Attachment" />
                    </div>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <FcInfo />
                    </div>
                  </div>
                  <DragFileUpload
                    ref={fileUploadRef}
                    fileType={"PTO_DOC"}
                    error={errors.attachments && touched.attachments}
                    afterUpload={(url) => {
                      setFieldValue("attachments", url);
                    }}
                    setUploading={setUploaded}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonTextIcon
                        title="Add PTO"
                        type="button"
                        disabled={uploaded}
                        onClick={submitForm}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              {list && (
                <tr>
                  <td colSpan="4">
                    <div className="inner-table-date  ">
                      <table>
                        <thead>
                          <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Type</th>
                            <th>Hours</th>
                            <th>Attachments</th>
                            <th
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((contact, index) => (
                            <tr>
                              <td>
                                {contact.date
                                  ? formatServerDate(contact.date)
                                  : "-"}
                              </td>
                              <td>
                                {contact?.endDate
                                  ? formatServerDate(contact.endDate)
                                  : "-"}
                              </td>
                              <td>{contact?.type?.label || "-"}</td>
                              <td>{contact?.amount || "-"}</td>
                              <td>
                                <div className="flex center-rigth">
                                  {contact?.attachments?.split("/").pop() ||
                                    "-"}
                                </div>
                              </td>
                              <td>
                                <div className="flex center-center">
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      onRemove(index);
                                    }}
                                  >
                                    <MdOutlineDeleteSweep
                                      color="red"
                                      size={18}
                                    />
                                  </span>
                                  {contact?.s3attachment && (
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingLeft: "8px",
                                        gap: "8px",
                                      }}
                                    >
                                      <NavLink
                                        to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                          contact?.s3attachment || ""
                                        )}&title=${encodeURIComponent("")}`}
                                        state={{
                                          ...location.state,
                                        }}
                                      >
                                        <span
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                            cursor: "pointer",
                                          }}
                                          title="View file"
                                        >
                                          <LuEye />
                                        </span>
                                      </NavLink>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

const NestedVaccinationForm = ({
  onAdd,
  list,
  onRemove,
  uploaded,
  setUploaded,
}) => {
  const fileUploadRef = useRef();
  const location = useLocation();
  const validationSchema = Yup.object().shape({
    vaccine: Yup.mixed().nullable().required("Vaccine is required"),
    date_administer: Yup.date()
      .required("Date administer is required")
      .typeError("Date administer must be a valid date"),
    date_expiry: Yup.date()
      .required("Date Administered is required")
      .typeError("Date Administered must be a valid date")
      .test(
        "is-after-date_administer",
        "Expiration Date must be greater then Date Administered",
        function (value) {
          const { date_administer } = this.parent;
          return (
            !date_administer ||
            !value ||
            new Date(value) > new Date(date_administer)
          );
        }
      ),
  });
  const handleReset = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFile();
    }
  };
  const labelVaccinationFunc = (o) => `${o.name}`;

  return (
    <Formik
      initialValues={{
        vaccine: null,
        date_administer: "",
        date_expiry: "",
        attachments: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        resetForm();
        handleReset();
      }}
    >
      {({
        errors,
        touched,
        setFieldValue,
        values,
        submitForm,
        setFieldTouched,
      }) => (
        <Form>
          <table>
            <tbody>
              <br />
              <tr>
                <td width="33%">
                  <Label title="Vaccination" required={true} />
                  <PaginatedDropdown
                    url={`${API_URL}/api/1.0.0/reference/vaccinations?`}
                    mapOptions={(records) =>
                      mapOptions(records, "id", labelVaccinationFunc)
                    }
                    error={errors.vaccine && touched.vaccine}
                    setTouched={() => setFieldTouched("vaccine", true)}
                    placeHolder="Search Vaccination"
                    selectedValue={values.vaccine}
                    onChange={(v) => setFieldValue("vaccine", v)}
                  />
                </td>
                <td width="33%">
                  <Label title="Date Administered " required={true} />
                  <DatePickerInput
                    value={values.date_administer}
                    setTouched={() => setFieldTouched("date_administer", true)}
                    callback={(date) => {
                      setFieldValue("date_administer", date);
                    }}
                    error={errors.date_administer && touched.date_administer}
                  />
                </td>
                <td width="33%">
                  <Label title="Expiration Date" required={true} />
                  <DatePickerInput
                    value={values.date_expiry}
                    setTouched={() => setFieldTouched("date_expiry", true)}
                    callback={(date) => {
                      setFieldValue("date_expiry", date);
                    }}
                    error={errors.date_expiry && touched.date_expiry}
                  />
                  <ErrorMessage
                    name="date_expiry"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div className="flex center-left" style={{ gap: "8px" }}>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <Label title="Attachment" />
                    </div>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <FcInfo />
                    </div>
                  </div>
                  <DragFileUpload
                    ref={fileUploadRef}
                    fileType={"VACCINATION_DOC"}
                    error={errors.attachments && touched.attachments}
                    afterUpload={(url) => {
                      setFieldValue("attachments", url);
                    }}
                    setUploading={setUploaded}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonTextIcon
                        title="Add Vaccination"
                        type="button"
                        disabled={uploaded}
                        onClick={submitForm}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              {list && (
                <tr>
                  <td colSpan="4">
                    <div className="inner-table-date">
                      <table>
                        <thead>
                          <tr>
                            <th>Vaccine</th>
                            <th>Date Administered</th>
                            <th>Expiration Date</th>
                            <th>Attachments</th>
                            <th
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((contact, index) => (
                            <tr>
                              <td>{contact.vaccine.label}</td>
                              <td>
                                {contact.date_administer
                                  ? formatServerDate(contact.date_administer)
                                  : "-"}
                              </td>
                              <td>
                                {contact.date_expiry
                                  ? formatServerDate(contact.date_expiry)
                                  : "-"}
                              </td>
                              <td>
                                <div className="flex center-rigth">
                                  <div>
                                    <p>
                                      {contact?.attachments?.split("/").pop() ||
                                        "-"}
                                    </p>
                                  </div>
                                </div>{" "}
                              </td>
                              <td>
                                <div className="flex center-center">
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      onRemove(index);
                                    }}
                                  >
                                    <MdOutlineDeleteSweep
                                      color="red"
                                      size={18}
                                    />
                                  </span>
                                  {contact?.s3attachment && (
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingLeft: "8px",
                                        gap: "8px",
                                      }}
                                    >
                                      <NavLink
                                        to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                          contact?.s3attachment || ""
                                        )}&title=${encodeURIComponent("")}`}
                                        state={{
                                          ...location.state,
                                        }}
                                      >
                                        <span
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                            cursor: "pointer",
                                          }}
                                          title="View file"
                                        >
                                          <LuEye />
                                        </span>
                                      </NavLink>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

const NestedFacilityForm = ({
  onAdd,
  list,
  onRemove,
  setUploaded,
  uploaded,
}) => {
  const fileUploadRef = useRef();
  const location = useLocation();
  const validationSchema = Yup.object().shape({
    facility: Yup.mixed()
      .nullable()
      .test("surgeon-required", "Required", function (value) {
        return value !== null && value.value;
      }),
    date_renewed: Yup.date()
      .required("Date renewed is required")
      .typeError("Date renewed must be a valid date"),
    date_expiry: Yup.date()
      .required("Renewal Date is required")
      .typeError("Renewal Date must be a valid date")
      .test(
        "is-after-date_renewed",
        "Expiration Date must be greater then Renewal Date",
        function (value) {
          const { date_renewed } = this.parent;
          return (
            !date_renewed || !value || new Date(value) > new Date(date_renewed)
          );
        }
      ),
    fee_paid: Yup.number()
      .typeError("Fee paid must be a valid number")
      .required("Fee paid is required")
      .min(0, "Fee paid must be greater than 0")
      .max(999999, "Fee paid must be less than 999999"),
  });
  const handleReset = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFile();
    }
  };
  const labelFacilityFunc = (o) => `${o.facilityName}`;

  return (
    <Formik
      initialValues={{
        facility: null,
        date_renewed: "",
        date_expiry: "",
        fee_paid: "",
        attachments: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        resetForm();
        handleReset();
      }}
    >
      {({
        errors,
        touched,
        setFieldValue,
        values,
        submitForm,
        setFieldTouched,
      }) => (
        <Form>
          <table>
            <tbody>
              <br />
              <tr>
                <td>
                  <Label title="Facility" required={true} />
                  <PaginatedDropdown
                    url={`${API_URL}/api/1.0.0/reference/facility?`}
                    mapOptions={(records) =>
                      mapOptions(records, "id", labelFacilityFunc)
                    }
                    error={errors.facility && touched.facility}
                    setTouched={() => setFieldTouched("facility", true)}
                    placeHolder="Search Facility"
                    selectedValue={values.facility}
                    onChange={(v) => setFieldValue("facility", v)}
                  />
                  <ErrorMessage
                    name="facility"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td width="25%">
                  <Label title="Renewed Date" required={true} />
                  <DatePickerInput
                    value={values.date_renewed}
                    setTouched={() => setFieldTouched("date_renewed", true)}
                    callback={(date) => {
                      setFieldValue("date_renewed", date);
                    }}
                    error={errors.date_renewed && touched.date_renewed}
                  />
                  <ErrorMessage
                    name="date_renewed"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td width="25%">
                  <Label title="Expiration Date" required={true} />
                  <DatePickerInput
                    value={values.date_expiry}
                    setTouched={() => setFieldTouched("date_expiry", true)}
                    callback={(date) => {
                      setFieldValue("date_expiry", date);
                    }}
                    error={errors.date_expiry && touched.date_expiry}
                  />
                  <ErrorMessage
                    name="date_expiry"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td width="25%">
                  <Label title="Fees Paid" required={true} />
                  <Field
                    type="number"
                    placeholder="Enter Fees Paid"
                    name="fee_paid"
                    className={`col-1-1 primary ${
                      errors.fee_paid && touched.fee_paid ? "error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="fee_paid"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  <div className="flex center-left" style={{ gap: "8px" }}>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <Label title="Attachment" />
                    </div>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <FcInfo />
                    </div>
                  </div>
                  <DragFileUpload
                    ref={fileUploadRef}
                    fileType={"FACILITY_DOC"}
                    error={errors.attachments && touched.attachments}
                    afterUpload={(url) => {
                      setFieldValue("attachments", url);
                    }}
                    setUploading={setUploaded}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonTextIcon
                        title="Add Facility"
                        type="button"
                        disabled={uploaded}
                        onClick={submitForm}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              {list && (
                <tr>
                  <td colSpan="4">
                    <div className="inner-table-date">
                      <table>
                        <thead>
                          <tr>
                            <th>Facility</th>
                            <th>Renewal Date</th>
                            <th>Expiration Date</th>
                            <th>Fees Paid</th>
                            <th>Attachments</th>
                            <th
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((contact, index) => (
                            <tr>
                              <td>{contact.facility.label}</td>
                              <td>
                                {contact.date_renewed
                                  ? formatServerDate(contact.date_renewed)
                                  : "-"}
                              </td>
                              <td>
                                {contact.date_expiry
                                  ? formatServerDate(contact.date_expiry)
                                  : "-"}
                              </td>
                              <td>{contact?.fee_paid?.toFixed(2)}</td>
                              <td>
                                <div className="flex center-rigth">
                                  <div>
                                    <p>
                                      {contact?.attachments?.split("/").pop() ||
                                        "-"}
                                    </p>
                                  </div>
                                </div>{" "}
                              </td>
                              <td>
                                <div className="flex center-center">
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      onRemove(index);
                                    }}
                                  >
                                    <MdOutlineDeleteSweep
                                      color="red"
                                      size={18}
                                    />
                                  </span>
                                  {contact?.s3attachment && (
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingLeft: "8px",
                                        gap: "8px",
                                      }}
                                    >
                                      <NavLink
                                        to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                          contact?.s3attachment || ""
                                        )}&title=${encodeURIComponent("")}`}
                                        state={{
                                          ...location.state,
                                        }}
                                      >
                                        <span
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                            cursor: "pointer",
                                          }}
                                          title="View file"
                                        >
                                          <LuEye />
                                        </span>
                                      </NavLink>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

const NestedScreeningForm = ({ onAdd, list, onRemove }) => {
  const fileUploadRef = useRef();
  const location = useLocation();
  const validationSchema = Yup.object().shape({
    name: Yup.object().nullable().required("Screening is required"),
    administeredDate: Yup.date()
      .required("Date is required")
      .typeError("Date must be a valid date"),
    expiryDate: Yup.date()
      .required("End Date is required")
      .typeError("End Date must be a valid date")
      .min(
        Yup.ref("administeredDate"),
        "End Date must be greater than Start Date"
      ),
    attachments: Yup.string().required("Attachment is required"),
  });
  const handleReset = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFile();
    }
  };
  const labelVaccinationFunc = (o) => `${o.name}`;

  const mapOptions = (records, valueKey, labelFormatter) =>
    records.map((record) => ({
      value: record[valueKey],
      label: labelFormatter(record),
    }));

  return (
    <Formik
      initialValues={{
        name: null,
        administeredDate: "",
        expiryDate: "",
        attachments: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        resetForm();
        handleReset();
      }}
    >
      {({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        values,
        submitForm,
        validateForm,
      }) => (
        <Form>
          <table>
            <tbody>
              <br />
              <tr>
                <td width="33%">
                  <Label title="Screening" required={true} />
                  <CustomSelect
                    options={ASSISTANT_SCREENING_DROP_DOWN_OPTIONS.map((o) => {
                      return {
                        value: o.value,
                        label: o.label.toUpperCase(),
                      };
                    })}
                    name="name"
                    placeHolder="Select Screening"
                    selectedValue={values.name}
                    error={errors.name && touched.name}
                    setTouched={() => setFieldTouched("name", true)}
                    onChange={async (v) => {
                      await setFieldValue("name", v);
                      await validateForm();
                    }}
                  />
                  <ErrorMessage
                    name="name"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td width="33%">
                  <Label title="Date Administered " required={true} />
                  <DatePickerInput
                    value={values.administeredDate}
                    setTouched={() => {
                      setFieldTouched("administeredDate", true);
                    }}
                    callback={(v) => {
                      setFieldValue("administeredDate", v);
                    }}
                    error={errors.administeredDate && touched.administeredDate}
                  />
                  <ErrorMessage
                    name="administeredDate"
                    component="p"
                    className="error-messages"
                  />
                  <ErrorMessage
                    name="administeredDate"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td width="33%">
                  <Label title="Expiration Date" required={true} />
                  <DatePickerInput
                    value={values.expiryDate}
                    setTouched={() => {
                      setFieldTouched("expiryDate", true);
                    }}
                    callback={(v) => {
                      setFieldValue("expiryDate", v);
                    }}
                    error={errors.expiryDate && touched.expiryDate}
                  />
                  <ErrorMessage
                    name="expiryDate"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div className="flex center-left" style={{ gap: "8px" }}>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <Label title="Attachment" />
                    </div>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <FcInfo />
                    </div>
                  </div>
                  <DragFileUpload
                    ref={fileUploadRef}
                    fileType={"SCREENING_DOC"}
                    error={errors.attachments && touched.attachments}
                    afterUpload={(url) => {
                      setFieldValue("attachments", url);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonTextIcon
                        title="Add Screening"
                        type="button"
                        onClick={submitForm}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              {list && (
                <tr>
                  <td colSpan="4">
                    <div className="inner-table-date">
                      <table>
                        <thead>
                          <tr>
                            <th>Screening</th>
                            <th>Date Administered</th>
                            <th>Expiration Date</th>
                            <th>Attachments</th>
                            <th
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((contact, index) => (
                            <tr>
                              <td>{contact?.name?.label}</td>
                              <td>
                                {contact.administeredDate
                                  ? formatServerDate(contact.administeredDate)
                                  : "-"}
                              </td>
                              <td>
                                {contact.expiryDate
                                  ? formatServerDate(contact.expiryDate)
                                  : "-"}
                              </td>
                              <td>
                                <div className="flex center-rigth">
                                  <div>
                                    <p>
                                      {contact?.attachments?.split("/").pop() ||
                                        "-"}
                                    </p>
                                  </div>
                                </div>{" "}
                              </td>
                              <td>
                                <div className="flex center-center">
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      onRemove(index);
                                    }}
                                  >
                                    <MdOutlineDeleteSweep
                                      color="red"
                                      size={18}
                                    />
                                  </span>
                                  {contact?.attachmentS3URL && (
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingLeft: "8px",
                                        gap: "8px",
                                      }}
                                    >
                                      <NavLink
                                        to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                          contact?.attachmentS3URL || ""
                                        )}&title=${encodeURIComponent("")}`}
                                        state={{
                                          ...location.state,
                                        }}
                                      >
                                        <span
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                            cursor: "pointer",
                                          }}
                                          title="View file"
                                        >
                                          <LuEye />
                                        </span>
                                      </NavLink>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

const NestedDocumentsForm = ({ onAdd, list, onRemove }) => {
  const fileUploadRef = useRef();
  const location = useLocation();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Document Name is required"),
    documentPath: Yup.string().required("Attachments is required"),
    notes: Yup.string()
      .nullable()
      .max(255, "Notes must be less than 255 characters"),
  });
  const handleReset = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFile();
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        notes: "",
        documentPath: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        resetForm();
        handleReset();
      }}
    >
      {({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        values,
        submitForm,
      }) => (
        <Form>
          <table>
            <tbody>
              <br />
              <tr>
                <td width="50%">
                  <Label title="Document Name" required={true} />
                  <Field
                    type="text"
                    placeholder="Eg. Document Name"
                    name="name"
                    className={`col-1-1 primary ${
                      errors.name && touched.name ? "error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="name"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="flex center-left" style={{ gap: "8px" }}>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <Label title="Attachment" />
                    </div>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <FcInfo />
                    </div>
                  </div>
                  <DragFileUpload
                    ref={fileUploadRef}
                    fileType={"DOCUMENT"}
                    error={errors.documentPath && touched.documentPath}
                    afterUpload={(url) => {
                      setFieldValue("documentPath", url);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <Label title="Notes" />
                  <Field
                    as="textarea"
                    placeholder="Eg. Notes"
                    name="notes"
                    maxLength={255}
                    className={`col-1-1 primary ${
                      errors.notes && touched.notes ? "error" : ""
                    }`}
                  />
                  <p
                    className="flex center-right"
                    style={{
                      fontSize: "12px",
                      color: values.notes.length >= 255 ? "red" : "black",
                    }}
                  >
                    {255 - parseInt(values.notes.length)} characters left
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonTextIcon
                        title="Add Document"
                        type="button"
                        onClick={submitForm}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              {list && (
                <tr>
                  <td colSpan="4">
                    <div className="inner-table-date">
                      <table>
                        <thead>
                          <tr>
                            <th>Document Name</th>
                            <th>Attachments</th>
                            <th>Notes</th>
                            <th>Created At</th>
                            <th
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((contact, index) => (
                            <tr key={index}>
                              <td>{contact?.name || "-"}</td>
                              <td>
                                <div className="flex center-rigth">
                                  <div>
                                    <p>
                                      {contact?.documentPath
                                        ?.split("/")
                                        .pop() || "-"}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>{contact?.notes || "-"}</td>
                              <td>
                                {contact?.createdAt
                                  ? formatDateTime(contact.createdAt)
                                  : "-"}
                              </td>
                              <td>
                                <div className="flex center-center">
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => onRemove(index)}
                                  >
                                    <MdOutlineDeleteSweep
                                      color="red"
                                      size={18}
                                    />
                                  </span>
                                  {contact?.documentS3Url && (
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingLeft: "8px",
                                        gap: "8px",
                                      }}
                                    >
                                      <NavLink
                                        to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                          contact?.documentS3Url || ""
                                        )}&title=${encodeURIComponent("")}`}
                                        state={{
                                          ...location.state,
                                        }}
                                      >
                                        <span
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                            cursor: "pointer",
                                          }}
                                          title="View file"
                                        >
                                          <LuEye />
                                        </span>
                                      </NavLink>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

export default AddForm;
