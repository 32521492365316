import { useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import { NavLink } from "react-router-dom";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { formatInputPhoneNumber, formatRateUSD, formatServerDate } from "../../utilities/helpers";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import { getRandomColor, handleApiCall } from "../../utilities/helpers";
import InfoItem from "../../components/ui/InfoItem";
import { TbArrowBackUp, TbCalendarShare, TbUserCircle } from "react-icons/tb";
import { FiEdit, FiFlag } from "react-icons/fi";
import { CiHospital1, CiWallet } from "react-icons/ci";
import { SiCodesignal } from "react-icons/si";
import { MdAlternateEmail, MdFolderSpecial } from "react-icons/md";
import { CgPokemon } from "react-icons/cg";
import { Ri24HoursFill, RiContactsLine } from "react-icons/ri";
import { PiPathBold } from "react-icons/pi";
import { HiOutlineCalendarDays } from "react-icons/hi2";

const ContractDetails = ({ id, setShow }) => {
  console.log("ContractDetails -> id", id);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { permissions } = useUI();
  const fetchContractData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/contract/${id}`,
        {
          method: "GET",
        }
      );

      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      } else {
        setData(null);
        setError("Failed to fetch facility data.");
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch facility data.");
      setLoading(false);
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchContractData(id);
  }, [id]);

  return (
    <div className="box" id="activity-detail-box">
      <div className="flex center-left">
        <div>
          <h6 className="color-primary f-w-300">Contract Details</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ButtonLightTextIcon
            title="Cancel"
            onClick={() => setShow(false)}
            icon={<TbArrowBackUp />}
          />
        </div>
      </div>
      <div
        style={{
          height: "60vh",
          margin: "10px",
          overflowY: "scroll",
          padding: "20px 5px",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
      >
        {loading && (
          <div
            className="col-1-1"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderIcon
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
        )}
        {!loading && error && (
          <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
            {error}
          </p>
        )}
        {data && !loading && !error && (
          <div className="activity-card pos-rel">
            <div
              style={{
                position: "absolute",
                content: "",
                height: "4px",
                left: "-1px",
                right: "-1px",
                bottom: "-2px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            ></div>
            <div
              className="col-1-1 flex center-left"
              style={{
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <h5 className="f-w-700">
                <span className="f-w-300">#</span>
                &nbsp;
                {data?.contractDetails?.id}
              </h5>
              <hr
                style={{
                  outline: "none",
                  border: "none",
                  height: "16px",
                  width: "2px",
                  background: "#EBEBEB",
                }}
              />
            </div>
            <div
              style={{
                padding: "12px 0 12px 0 ",
              }}
            >
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<FiFlag color={getRandomColor()} size={20} />}
                  label={"Contract Type"}
                  name={data?.contractDetails?.contractType?.typeName || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CiHospital1 color={getRandomColor()} size={20} />}
                  label={"Facility"}
                  name={data?.contractDetails?.facility?.facilityName || "NA"}
                />
              </div>
              {data?.contractDetails?.contactPersonDetails?.length ? (
                data?.contractDetails?.contactPersonDetails?.map(
                  (contractPersonalDetails, index) => (
                    <>
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <SiCodesignal color={getRandomColor()} size={20} />
                          }
                          label={"Contact Person Designation"}
                          name={
                            contractPersonalDetails?.contactPersonTitle || "NA"
                          }
                        />
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <TbUserCircle color={getRandomColor()} size={20} />
                          }
                          label={"Contact Person Name"}
                          name={contractPersonalDetails?.contactPersonName}
                        />
                      </div>
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <MdAlternateEmail
                              color={getRandomColor()}
                              size={20}
                            />
                          }
                          label={"Contact Person Email"}
                          name={
                            contractPersonalDetails?.contactPersonEmail || "NA"
                          }
                        />
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <RiContactsLine
                              color={getRandomColor()}
                              size={20}
                            />
                          }
                          label={"Contact Person Phone"}
                          name={
                            contractPersonalDetails?.contactPersonPhone
                              ? formatInputPhoneNumber(
                                  contractPersonalDetails?.contactPersonPhone.trim()
                                )
                              : "NA"
                          }
                        />
                      </div>
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <TbCalendarShare
                              color={getRandomColor()}
                              size={20}
                            />
                          }
                          label={"Contract Start Date"}
                          name={
                            data?.contractDetails?.startDate
                              ? formatServerDate(
                                  data?.contractDetails?.startDate
                                )
                              : "NA"
                          }
                        />
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <TbCalendarShare
                              color={getRandomColor()}
                              size={20}
                            />
                          }
                          label={"Contact End Date"}
                          name={
                            data?.contractDetails?.endDate
                              ? formatServerDate(data?.contractDetails?.endDate)
                              : "NA"
                          }
                        />
                      </div>
                      <hr
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </>
                  )
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px 12px",
                    backgroundColor: "rgb(245, 245, 245)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        No detail found.
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                Procedure Configuration
              </p>
              {data?.procedureConfiguration?.length ? (
                data?.procedureConfiguration?.map((procedureConfig, index) => (
                  <>
                    <div
                      key={index}
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={<CgPokemon color={getRandomColor()} size={20} />}
                        label="Specialty"
                        name={procedureConfig?.specialty?.name || "NA"}
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={<PiPathBold color={getRandomColor()} size={20} />}
                        label="Rate Type"
                        name={
                          procedureConfig?.rateTypeDetails?.typeName || "NA"
                        }
                      />
                    </div>
                    <div
                      key={index}
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      {procedureConfig?.rateTypeDetails?.id === 1 && (
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={<CiWallet color={getRandomColor()} size={20} />}
                          label="Rate"
                          name={
                            procedureConfig?.rate
                              ? formatRateUSD(procedureConfig?.rate)
                              : "NA"
                          }
                        />
                      )}

                      {procedureConfig?.rateTypeDetails?.id === 2 && (
                        <>
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <CiWallet color={getRandomColor()} size={20} />
                            }
                            label="Hourly Rate"
                            name={
                              procedureConfig?.hourlyRate
                                ? formatRateUSD(procedureConfig?.hourlyRate)
                                : "NA"
                            }
                            // name={procedureConfig?.hourlyRate || "NA"}
                          />
                        </>
                      )}
                      {procedureConfig?.rateTypeDetails?.id === 2 && (
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <Ri24HoursFill color={getRandomColor()} size={20} />
                          }
                          label="Number Of Hours"
                          name={procedureConfig?.numberOfHours || "NA"}
                        />
                      )}
                      {procedureConfig?.rateTypeDetails?.id === 3 && (
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <Ri24HoursFill color={getRandomColor()} size={20} />
                          }
                          label="Number Of Hours"
                          name={procedureConfig?.numberOfHours || "NA"}
                        />
                      )}
                      {procedureConfig?.rateTypeDetails?.id === 3 && (
                        <>
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <CiWallet color={getRandomColor()} size={20} />
                            }
                            label="Hourly Rate"
                            name={
                              procedureConfig?.hourlyRate
                                ? formatRateUSD(procedureConfig?.hourlyRate)
                                : "NA"
                            }
                            // name={procedureConfig?.hourlyRate || "NA"}
                          />
                        </>
                      )}
                    </div>
                    <hr
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px 12px",
                    backgroundColor: "rgb(245, 245, 245)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        No detail found.
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <p
                style={{
                  fontWeight: "700",
                  paddingBottom: "8px",
                }}
              >
                On Call Configuration
              </p>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<PiPathBold color={getRandomColor()} size={20} />}
                  label={"On Call Type"}
                  name={data?.onCallConfiguration?.onCallType?.typeName || "NA"}
                />
              </div>
              <br />
              {data?.onCallConfiguration?.onCallChild?.length ? (
                data.onCallConfiguration?.onCallChild?.map(
                  (onCallDetails, index) => (
                    <>
                      <div
                        key={index}
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        {data?.onCallConfiguration?.onCallType?.id === 1 && (
                          <>
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <HiOutlineCalendarDays
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label="Days Per Week"
                              name={onCallDetails?.daysPerWeek || "NA"}
                            />
                          </>
                        )}
                        {data?.onCallConfiguration?.onCallType?.id === 1 && (
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <CiWallet color={getRandomColor()} size={20} />
                            }
                            label="Daily Rate"
                            name={
                              onCallDetails?.dailyRate
                                ? formatRateUSD(onCallDetails?.dailyRate)
                                : "NA"
                            }
                          />
                        )}
                        {data?.onCallConfiguration?.onCallType?.id === 2 && (
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <Ri24HoursFill
                                color={getRandomColor()}
                                size={20}
                              />
                            }
                            label="Number Of Hours"
                            name={onCallDetails?.numberOfHours || "NA"}
                          />
                        )}
                        {data?.onCallConfiguration?.onCallType?.id === 2 && (
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <CiWallet color={getRandomColor()} size={20} />
                            }
                            label="Hourly Rate"
                            name={
                              onCallDetails?.hourlyRate
                                ? formatRateUSD(onCallDetails?.hourlyRate)
                                : "NA"
                            }
                            // name={onCallDetails?.hourlyRate || "NA"}
                          />
                        )}
                        {data?.onCallConfiguration?.onCallType?.id === 3 && (
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <MdFolderSpecial color={getRandomColor()} size={20}/>
                            }
                            label="Rate Tier Limit"
                            name={onCallDetails?.rateTierLimit || "NA"}
                          />
                        )}
                        {data?.onCallConfiguration?.onCallType?.id === 3 && (
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <CiWallet color={getRandomColor()} size={20} />
                            }
                            label="Staggered Rate"
                            name={
                              onCallDetails?.staggeredRate
                                ? formatRateUSD(onCallDetails?.staggeredRate)
                                : "NA"
                            }
                            // name={onCallDetails?.staggeredRate || "NA"}
                          />
                        )}
                      </div>
                      <hr
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </>
                  )
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px 12px",
                    backgroundColor: "rgb(245, 245, 245)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        No detail found.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        {permissions?.isUpdateAllowed && (
          <NavLink
            to={
              data?.contractDetails?.id
                ? `/edit-contracts?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                : "#"
            }
            state={{
              id: data?.contractDetails?.id,
            }}
          >
            <ButtonLightTextIcon
              title="Edit Details"
              onClick={() => {
                setShow(false);
              }}
              icon={<FiEdit />}
            />
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default ContractDetails;
