import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import GroupFilter from "./group/GroupFilter";
import CustomModal from "./../components/common/CustomModal";
import { useUI } from "../context/ui";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import GroupDetails from "./group/GroupDetails";
import useQueryParams from "../hooks/useQueryParams";
import { handleApiCall, jsonToQueryParams } from "../utilities/helpers";
import PageToolbar from "../components/common/PageToolbar";
import { FiPlusCircle } from "react-icons/fi";
const GroupPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageNo = queryParams.get("pageNo") || 0;
  const pageSize = queryParams.get("pageSize") || 12;
  const { addToastMessage } = useUI();
  const { permissions } = useUI();
  const [controller, setController] = useState(null);

  // ====== RESET FILTER
  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("pageNo", 0);
  };

  const handleDelete = async (id) => {
    try {
      if (!id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting group. Please try again.`,
        });
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/groups/${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.responseStatus === true) {
        callListApi();
        addToastMessage({
          status: true,
          message: `Group deleted successfully.`,
        });
        return true;
      } else {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting group. Please try again.`,
        });
        return false;
      }
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting group. Please try again.`,
      });
      return false;
    }
  };

  const callListApi = async () => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;

    const query = jsonToQueryParams({
      search: queryParams.get("search") || "",
      pageNo: queryParams.get("pageNo") || 0,
      pageSize: queryParams.get("pageSize") || 12,
    });
    setLoading(true);

    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/groups?${query}&sortOrder=DESC`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    callListApi();
  }, [location.search]);

  return (
    <section id="activity-v2">
      <PageToolbar
        title="Groups"
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => navigate("/add-group")}
              icon={<FiPlusCircle />}
            />
          )
        }
      />
      <GroupFilter
        resetFilters={() => {
          resetFilters();
        }}
      />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.list?.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Group Name</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan="8"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <LoaderIcon style={{ height: "32px", width: "32px" }} />
                  </td>
                </tr>
              ) : (
                data &&
                data?.list.map((data, i) => (
                  <tr key={i}>
                    <td>{pageNo * pageSize + i + 1}</td>
                    <td>{data?.groupName}</td>
                    <td>
                      <div
                        className="flex center-center"
                        style={{ gap: "12px" }}
                      >
                        {permissions?.isReadAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="view"
                                  title="View"
                                />
                              }
                              content={<GroupDetails id={data?.id} />}
                            />
                          </div>
                        )}
                        {permissions?.isUpdateAllowed && (
                          <div className="flex color-light font-size-10 ">
                            <NavLink
                              to={`/edit-group?next=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                              state={{ id: data?.id }}
                            >
                              <ButtonTertiary actionType="edit" title="Edit" />
                            </NavLink>
                          </div>
                        )}
                        {permissions?.isDeleteAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="delete"
                                  title="Delete"
                                />
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    await handleDelete(data.id);
                                    return true;
                                  }}
                                  message="Are you sure you want to delete this group?"
                                />
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default GroupPage;
