import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import PaginatedDropdown, {
  mapOptions,
} from "../../components/ui/PaginatedDropdown";
import { useUI } from "../../context/ui";
import {
  formatInputPhoneNumber,
  formatRateUSD,
  handleApiCall,
} from "../../utilities/helpers";
import PhoneNumberInput from "../../components/ui/PhoneNumberInput";
import DatePickerInput from "../../components/ui/DatePickerInput";
import Label from "../../components/ui/Label";
import { useNavigate } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { FiArrowRightCircle, FiPlusCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdOutlineDeleteSweep } from "react-icons/md";

const UpdateForm = ({ data }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const { addToastMessage } = useUI();
  const labelFacilityFunc = (o) => `${o.facilityName}`;
  const { queryParams, updateQueryParam } = useQueryParams();
  const nextUrl = decodeURIComponent(queryParams.get("next")) || "";

  const [initialValues, setInitialValues] = useState({
    id: null,
    procedureConfiguration: [],
    onCallChild: [],
    contractTypeId: null,
    startDate: "",
    endDate: "",
    contractContactDetails: [],
    facilityId: null,
  });

  useEffect(() => {
    setInitialValues({
      id: data?.contractDetails?.id || null,
      contractTypeId: data?.contractDetails?.contractType
        ? {
            value: data?.contractDetails?.contractType?.id,
            label: data?.contractDetails?.contractType?.typeName,
          }
        : null,
      startDate: data?.contractDetails?.startDate || null,
      endDate: data?.contractDetails?.endDate || null,
      contractContactDetails: data?.contractDetails?.contactPersonDetails
        ? data?.contractDetails?.contactPersonDetails.map((item) => ({
            contactPersonTitle: item.contactPersonTitle || null,
            contactPersonEmail: item.contactPersonEmail || null,
            contactPersonPhone: item.contactPersonPhone || null,
            contactPersonName: item.contactPersonName || null,
          }))
        : [],
      facilityId: data?.contractDetails?.facility
        ? {
            value: data?.contractDetails?.facility?.id,
            label: data?.contractDetails?.facility?.facilityName,
          }
        : null,
      procedureConfiguration: data?.procedureConfiguration
        ? data?.procedureConfiguration.map((item) => ({
            specialityId: item?.specialty
              ? {
                  value: item?.specialty?.id,
                  label: item?.specialty?.name,
                }
              : null,
            contractId: item.contractId,
            rateType: item.rateTypeDetails
              ? {
                  value: item?.rateTypeDetails?.id,
                  label: item?.rateTypeDetails?.typeName,
                }
              : null,
            numberOfHours: item.numberOfHours,
            hourlyRate: item.hourlyRate,
            contractId: item.contractId,
            staggeredRate: item.staggeredRate,
            rate: item.rate,
          }))
        : [],
      onCallTypeId: data?.onCallConfiguration?.onCallType
        ? {
            value: data?.onCallConfiguration?.onCallType?.id,
            label: data?.onCallConfiguration?.onCallType?.typeName,
          }
        : null,
      onCallChild: data?.onCallConfiguration?.onCallChild
        ? data?.onCallConfiguration?.onCallChild.map((child) => ({
            onCallTypeId: data?.onCallConfiguration?.onCallType
              ? {
                  value: data?.onCallConfiguration?.onCallType?.id,
                  label: data?.onCallConfiguration?.onCallType?.typeName,
                }
              : null,
            rate: child.rate || null,
            daysPerWeek: child.daysPerWeek || null,
            dailyRate: child.dailyRate || null,
            rateTierLimit: child.rateTierLimit || null,
            staggeredRate: child.staggeredRate || null,
            numberOfHour: child.numberOfHours || null,
            hourlyRate: child.hourlyRate || null,
          }))
        : [],
    });
  }, [data]);

  const validationSchema = Yup.object({
    contractTypeId: Yup.mixed()
      .nullable()
      .test(
        "Contract Type required",
        "Contract type is required",
        function (value) {
          return value !== null && value.value;
        }
      ),
    startDate: Yup.string().required("Start Date required"),
    endDate: Yup.date()
      .required("End Date is required")
      .test(
        "is-after-startDate",
        "End date must be greater then Start date",
        function (value) {
          const { startDate } = this.parent;
          return !startDate || !value || new Date(value) > new Date(startDate);
        }
      ),
    facilityId: Yup.mixed()
      .nullable()
      .test("facility-required", "Facility is required", function (value) {
        return value !== null && value.value;
      }),
  });

  const updateContract = async (values) => {
    setLoading(true);
    setStatus("");
    try {
      if (values?.contractContactDetails?.length === 0) {
        setStatus(
          "At least one contact detail is required to update a contract. Please provide a valid contact detail."
        );
        addToastMessage({
          status: false,
          message: `At least one contact detail is required to update a contract. Please provide a valid contact detail.`,
        });
        return false;
      }

      let payload = {
        procedureConfiguration: values.procedureConfiguration
          ? values.procedureConfiguration.map((item) => ({
              rateType: item?.rateType?.value || null,
              rate: item.rate || null,
              daysPerWeek: item.daysPerWeek || null,
              dailyRate: item.dailyRate || null,
              staggeredRate: item.staggeredRate || null,
              hourlyRate: item.hourlyRate || null,
              specialtyId: item?.specialityId?.value || null,
              numberOfHours: item?.numberOfHours || null,
            }))
          : [],
        contractTypeId: values.contractTypeId.value,
        startDate: values.startDate,
        endDate: values.endDate,
        contactPersonDetails: values.contractContactDetails
          ? values.contractContactDetails.map((item) => ({
              contactPersonEmail: item?.contactPersonEmail || null,
              contactPersonPhone: item?.contactPersonPhone || null,
              contactPersonName: item?.contactPersonName || null,
              contactPersonTitle: item?.contactPersonTitle || null,
            }))
          : [],
        facilityId: values.facilityId.value || null,
      };

      if (values.onCallChild?.length > 0) {
        payload["onCallConfiguration"] = {
          onCallTypeId: values.onCallChild[0]?.onCallTypeId?.value || null,
          onCallChild: values.onCallChild
            ? values.onCallChild.map((item) => ({
                rate: item.rate || null,
                daysPerWeek: item.daysPerWeek || null,
                dailyRate: item.dailyRate || null,
                numberOfHours: item.numberOfHour || null,
                hourlyRate: item.hourlyRate || null,
                rateTierLimit: item.rateTierLimit || null,
                staggeredRate: item.staggeredRate || null,
              }))
            : [],
        };
      } else {
        payload["onCallConfiguration"] = null;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/contract/${values.id}`,
        {
          method: "PUT",
          body: payload,
        }
      );

      if (response.responseStatus === true) {
        setStatus("You have successfully updated a contract, thank you.");
        addToastMessage({
          status: true,
          message: `You have successfully updated a contract, thank you.`,
        });
        if (nextUrl) {
          navigate(nextUrl);
        } else {
          navigate("/contracts");
        }
        return true;
      } else {
        setStatus(
          `${response.responseDescription || "Something went wrong, please try again later."}`
        );
        addToastMessage({
          status: false,
          message: `${response.responseDescription || "Something went wrong, please try again later."}`,
        });
        return false;
      }
    } catch (e) {
      console.log(e);
      setStatus("Something went wrong, please try again later.");
      addToastMessage({
        status: false,
        message: `Something went wrong, please try again later.`,
      });
      console.error("Error:", e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const SingleStep = ({ title, subtitle, completion, style }) => {
    const getClassName = () => {
      const hasEmptyElement = completion.some((element) => element === "");
      if (hasEmptyElement) {
        return "incomplete";
      } else {
        return "complete";
      }
    };
    const dynamicStyle = {
      fontSize: "11px",
      ...style, // Spread the additional styles from the `style` prop
    };

    return (
      <div className={`step t-a-c ${getClassName()}`}>
        <p className="color-primary" style={dynamicStyle}>
          <span className="f-w-700">0{title}</span> {subtitle}
        </p>
        <div className={`b-r-100 icon`}>
          <IoMdCheckmarkCircle />
        </div>
      </div>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await updateContract(values);
        if (api) {
          resetForm();
        }
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        handleSubmit,
        validateForm,
      }) => (
        <Form>
          <section
            id="stepper-should-fix"
            className="config-application-stepper"
          >
            <div className="steps flex center-center" style={{ gap: "20px" }}>
              <SingleStep
                title="1"
                subtitle="Basic  Information"
                style={{ marginRight: "20px" }}
                completion={[
                  values.contractTypeId,
                  values.facilityId,
                  values.startDate,
                  values.endDate,
                  values?.contractContactDetails?.length > 0 ? "values" : "",
                  // values.contactPersonPhone,
                  // values.contactPersonName,
                  // values.contactPersonEmail,
                ]}
              />

              <SingleStep
                title="2"
                subtitle="Procedure Configuration"
                completion={[
                  values?.procedureConfiguration?.length > 0 ? "values" : "",
                ]}
              />
              <SingleStep
                title="3"
                subtitle="On Call Configuration"
                completion={[values?.onCallChild?.length > 0 ? "values" : ""]}
              />
            </div>
          </section>
          <br />
          <section id="stepper-should-impact">
            <div className="col-1-1">
              <table>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <h5 className="color-primary">1. Basic Information</h5>
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td width="50%">
                      <Label title="Contract Type" required={true} />
                      <PaginatedDropdown
                        datakey="records"
                        url={`${API_URL}/api/1.0.0/reference/contract-types?`}
                        mapOptions={(records) =>
                          mapOptions(records, "id", labelOnCallTypeFunc)
                        }
                        setFieldTouched={() =>
                          setFieldTouched("contractTypeId", true)
                        }
                        placeHolder="Search"
                        selectedValue={values.contractTypeId}
                        error={errors.contractTypeId && touched.contractTypeId}
                        onChange={(v) => {
                          setFieldValue("contractTypeId", v);
                        }}
                      />
                    </td>
                    <td width="50%">
                      <Label title="Facility" required={true} />
                      <PaginatedDropdown
                        url={`${API_URL}/api/1.0.0/reference/facility?`}
                        mapOptions={(records) =>
                          mapOptions(records, "id", labelFacilityFunc)
                        }
                        setFieldTouched={() =>
                          setFieldTouched("facilityId", true)
                        }
                        error={errors.facilityId && touched.facilityId}
                        placeHolder="Search Facility"
                        selectedValue={values.facilityId}
                        onChange={(v) => setFieldValue("facilityId", v)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <Label title="Start Date" required={true} />
                      <DatePickerInput
                        callback={(v) => setFieldValue("startDate", v)}
                        value={values.startDate}
                        error={errors.startDate && touched.startDate}
                        setTouched={() => setFieldTouched("startDate", true)}
                      />
                      <ErrorMessage
                        name="startDate"
                        component="p"
                        className="error-messages"
                      />
                    </td>
                    <td width="50%">
                      <Label title="End Date" required={true} />
                      <DatePickerInput
                        callback={(v) => setFieldValue("endDate", v)}
                        value={values.endDate}
                        error={errors.endDate && touched.endDate}
                        setTouched={() => setFieldTouched("endDate", true)}
                      />
                      <ErrorMessage
                        name="endDate"
                        component="p"
                        className="error-messages"
                      />
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td colSpan="2">
                      <NestedPersonalDetailForm
                        onAdd={(newProcedure) => {
                          setFieldValue("contractContactDetails", [
                            ...values.contractContactDetails,
                            newProcedure,
                          ]);
                        }}
                      />
                    </td>
                  </tr>
                  {values.contractContactDetails && (
                    <tr>
                      <td colSpan="2">
                        <div className="contect-date">
                          <table>
                            <thead>
                              <tr>
                                <th>Designation</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.contractContactDetails.map(
                                (contact, index) => (
                                  <tr>
                                    <td>
                                      <span>
                                        {contact?.contactPersonTitle || "-"}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {contact?.contactPersonName || "-"}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {contact.contactPersonPhone
                                          ? formatInputPhoneNumber(
                                              contact.contactPersonPhone.trim()
                                            )
                                          : "-"}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {contact?.contactPersonEmail || "-"}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="flex center-center">
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            const updatedConfiguration =
                                              values.contractContactDetails.filter(
                                                (_, i) => i !== index
                                              );
                                            setFieldValue(
                                              "contractContactDetails",
                                              updatedConfiguration
                                            );
                                          }}
                                        >
                                          <MdOutlineDeleteSweep color="red" />
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td colSpan="2">
                      <br />
                      <hr />
                      <br />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2}>
                      <h5 className="color-primary">
                        2. Procedure Configuration
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <NestedProcedureForm
                        list={values.procedureConfiguration}
                        onAdd={(newProcedure) => {
                          setFieldValue("procedureConfiguration", [
                            ...values.procedureConfiguration,
                            newProcedure,
                          ]);
                        }}
                      />
                    </td>
                  </tr>
                  {values.procedureConfiguration && (
                    <tr>
                      <td colSpan="2">
                        <div className="contect-date">
                          <table>
                            <thead>
                              <tr>
                                <th>Specialty</th>
                                <th>Rate Type</th>
                                <th>Number Hours</th>
                                <th>Rate</th>
                                <th
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.procedureConfiguration.map(
                                (contact, index) => (
                                  <tr>
                                    <td>
                                      <span>
                                        {contact?.specialityId?.label || "-"}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {contact.rateType?.label || "-"}
                                      </span>
                                    </td>

                                    <td>
                                      <span>
                                        {contact?.rateType?.value === 2
                                          ? `${contact.numberOfHours} `
                                          : contact?.rateType?.value === 3
                                            ? `${contact.numberOfHours}`
                                            : "-"}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {contact?.rateType?.value === 1
                                          ? `${contact.rate ? formatRateUSD(contact.rate.toFixed(2)) : 0.0} `
                                          : contact?.rateType?.value === 2
                                            ? ` ${contact.hourlyRate ? formatRateUSD(contact.hourlyRate.toFixed(2)) : 0.0}`
                                            : ` ${contact.staggeredRate ? formatRateUSD(contact.staggeredRate.toFixed(2)) : 0.0}`}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="flex center-center">
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            const updatedConfiguration =
                                              values.procedureConfiguration.filter(
                                                (_, i) => i !== index
                                              );
                                            setFieldValue(
                                              "procedureConfiguration",
                                              updatedConfiguration
                                            );
                                          }}
                                        >
                                          <MdOutlineDeleteSweep color="red" />
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="2">
                      <br />
                      <hr />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <h5 className="color-primary">
                        3. On Call Configuration
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <NestedOnCallForm
                        defaltOnCallTypeId={values.onCallTypeId}
                        list={values.onCallChild}
                        onAdd={(newDetails) => {
                          setFieldValue("onCallChild", [
                            ...values.onCallChild,
                            newDetails,
                          ]);
                        }}
                        onReplace={(newDetails) => {
                          setFieldValue("onCallChild", [newDetails]);
                        }}
                        removeOnCallConfig={() => {
                          setFieldValue("onCallChild", []);
                        }}
                      />
                    </td>
                  </tr>
                  {values.onCallChild && (
                    <tr>
                      <td colSpan="2">
                        <div className="contect-date">
                          <table>
                            <thead>
                              <tr>
                                <th>On Call Type</th>
                                <th>Number Of Hours Or Days</th>
                                <th>Rate</th>
                                <th
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.onCallChild.map((contact, index) => (
                                <tr>
                                  <td>
                                    <span>
                                      {contact.onCallTypeId?.label || "-"}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {contact?.onCallTypeId?.value === 1
                                        ? `${contact?.daysPerWeek ? contact.daysPerWeek : "0"}`
                                        : contact.onCallTypeId?.value === 2
                                          ? `${contact?.numberOfHour ? contact.numberOfHour : "0"}  `
                                          : `${contact?.rateTierLimit ? contact.rateTierLimit.toFixed(2) : "0.00"}`}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {contact?.onCallTypeId?.value === 1
                                        ? `${contact.dailyRate ? formatRateUSD(contact.dailyRate.toFixed(2)) : "0.00"}`
                                        : contact?.onCallTypeId?.value === 2
                                          ? ` ${contact.hourlyRate ? formatRateUSD(contact.hourlyRate.toFixed(2)) : "0.00"}`
                                          : ` ${contact.staggeredRate ? formatRateUSD(contact.staggeredRate.toFixed(2)) : "0.00"}`}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="flex center-center">
                                      <span
                                        className="cursor-pointer"
                                        onClick={() => {
                                          const updatedConfiguration =
                                            values.onCallChild.filter(
                                              (_, i) => i !== index
                                            );
                                          setFieldValue(
                                            "onCallChild",
                                            updatedConfiguration
                                          );
                                        }}
                                      >
                                        <MdOutlineDeleteSweep color="red" />
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <br />
            <div className="flex center-left">
              <div>
                <ButtonTextIcon
                  type="button"
                  className="light"
                  title="Cancel"
                  onClick={(event) => {
                    event.preventDefault();
                    if (nextUrl) {
                      navigate(nextUrl);
                    } else {
                      navigate("/contracts");
                    }
                  }}
                  icon={<TbArrowBackUp />}
                />
              </div>
              <div className="mar-l-a">
                <ButtonTextIcon
                  type="button"
                  disabled={loading}
                  title="Update Now"
                  onClick={async () => {
                    const errors = await validateForm();
                    if (Object.keys(errors).length > 0) {
                      const firstErrorMessage = Object.values(errors)[0];
                      addToastMessage({
                        status: false,
                        message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                      });
                    }
                    handleSubmit();
                  }}
                  icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
                />
              </div>
            </div>
            {status && (
              <>
                <br />
                <p className="color-primary f-w-700">{status}</p>
              </>
            )}
          </section>
        </Form>
      )}
    </Formik>
  );
};

const NestedPersonalDetailForm = ({ onAdd }) => {
  const validationSchema = Yup.object().shape({
    contactPersonName: Yup.string().required("Person Name Required"),
    contactPersonPhone: Yup.string()
      .matches(/^\d+$/, "Phone Number must contain only numbers")
      .max(15, "Phone Number must be at most 15 digits")
      .required("Phone Number is required"),
    contactPersonEmail: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  return (
    <Formik
      initialValues={{
        contactPersonEmail: "",
        contactPersonPhone: "",
        contactPersonName: "",
        contactPersonTitle: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        try {
          onAdd(values);
          resetForm();
        } catch (error) {
          console.error("Error during form submission:", error);
        }
      }}
    >
      {({
        errors,
        touched,
        values,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
      }) => (
        <Form>
          <table>
            <tbody>
              <tr>
                <td width="50%">
                  <Label title="Contact Person Designation" />
                  <Field
                    type="text"
                    placeholder="Eg: Doctor"
                    name="contactPersonTitle"
                    className={`col-1-1 primary ${
                      errors.contactPersonTitle && touched.contactPersonTitle
                        ? "error"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    name="contactPersonTitle"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td width="50%">
                  <Label title="Contact Person Name" required={true} />
                  <Field
                    type="text"
                    placeholder="Enter Name"
                    name="contactPersonName"
                    className={`col-1-1 primary ${
                      errors.contactPersonName && touched.contactPersonName
                        ? "error"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    name="contactPersonName"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <Label title="Contact Person Phone" required={true} />
                  <PhoneNumberInput
                    name="contactPersonPhone"
                    placeholder="Eg: 888-888-8888"
                    error={
                      errors.contactPersonPhone && touched.contactPersonPhone
                    }
                    value={values.contactPersonPhone}
                    setTouched={() =>
                      setFieldTouched("contactPersonPhone", true)
                    }
                    callback={(v) => setFieldValue("contactPersonPhone", v)}
                  />
                  <ErrorMessage
                    name="contactPersonPhone"
                    component="p"
                    className="error-messages"
                  />
                </td>
                <td width="50%">
                  <Label title="Contact Person Email" required={true} />
                  <Field
                    type="text"
                    placeholder="Enter Email"
                    name="contactPersonEmail"
                    className={`col-1-1 primary ${
                      errors.contactPersonEmail && touched.contactPersonEmail
                        ? "error"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    name="contactPersonEmail"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <div className="flex bottom-right">
                    <div>
                      {/* <Label title="&nbsp;" /> */}
                      <ButtonLightTextIcon
                        title="Add Contact Details"
                        type="button"
                        onClick={() => {
                          handleSubmit();
                        }}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

const labelSpecilityFunc = (o) => `${o.name}`;
const labelOnCallTypeFunc = (o) => `${o.typeName}`;

function isValidDecimal(input) {
  const regex = /^(?!0(\.0{1,2})?$)\d+(\.\d{1,2})?$/;
  return regex.test(input);
}

function isValidNumber(input) {
  if (typeof input !== "string" && typeof input !== "number") {
    return false;
  }
  const value = input.toString().trim();
  const regex = /^[1-9]\d*$/;
  return regex.test(value);
}

const NestedProcedureForm = ({ onAdd, list }) => {
  const [status, setStatus] = useState("");
  const { addToastMessage } = useUI();
  const validationSchema = Yup.object().shape({
    rate: Yup.string()
      .test("required-if-1", "Rate is required", function (value) {
        const { rateType } = this.parent;
        if (rateType?.value === 1) {
          return value;
        }
        return true;
      })
      .test(
        "required-if-1",
        "Rate must be a valid number with up to two decimal places.",
        function (value) {
          const { rateType } = this.parent;
          if (rateType?.value === 1) {
            return isValidDecimal(value);
          }
          return true;
        }
      ),
    staggeredRate: Yup.string()
      .test("required-if-3", "Staggered rate is required", function (value) {
        const { rateType } = this.parent;
        if (rateType?.value === 3) {
          return value;
        }
        return true;
      })
      .test(
        "required-if-3",
        "Staggered rate must be a valid number with up to two decimal places.",
        function (value) {
          const { rateType } = this.parent;
          if (rateType?.value === 3) {
            return isValidDecimal(value);
          }
          return true;
        }
      ),
    numberOfHours: Yup.string()
      .test(
        "required-if-rateType",
        "Number of hours is required",
        function (value) {
          const { rateType } = this.parent;
          if (rateType?.value === 2 || rateType?.value === 3) {
            return !!value; // Ensure the value is not empty
          }
          return true;
        }
      )
      .test(
        "valid-number-if-rateType",
        "Number of hours must be a valid number.",
        function (value) {
          const { rateType } = this.parent;
          // console.log("Validating numberOfHour (number):", value, "rateType:", rateType);
          if ((rateType?.value === 2 || rateType?.value === 3) && value) {
            return isValidNumber(value); // Validate the value is a number
          }
          return true;
        }
      ),
    hourlyRate: Yup.string()
      .test("required-if-2", "Hourly rate is required", function (value) {
        const { rateType } = this.parent;
        if (rateType?.value === 2) {
          return !!value;
        }
        return true;
      })
      .test(
        "valid-number",
        "Hourly Rate must be a valid number.",
        function (value) {
          const { rateType } = this.parent;
          //   console.log("Validating hourlyRate (number):", value, "rateType:", rateType?.value);
          if (rateType?.value === 2) {
            return isValidDecimal(value);
          }
          return true;
        }
      ),
    rateType: Yup.mixed()
      .nullable()
      .test("Rate type required", "Rate type is required", function (value) {
        return value !== null && value.value;
      }),
    specialityId: Yup.mixed()
      .nullable()
      .test("specialty-required", "Specialty is required", function (value) {
        return value !== null && value.value;
      }),
  });

  return (
    <Formik
      initialValues={{
        rateType: null,
        specialityId: null,
        rate: "",
        numberOfHours: "",
        staggeredRate: "",
        hourlyRate: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm, setFieldValue }) => {
        setStatus("");
        try {
          const isSpecialityPresentWithDifferentRateType =
            list?.length > 0 &&
            list.some(
              (config) =>
                config.specialityId?.label === values?.specialityId?.label &&
                config.rateType?.label !== values?.rateType?.label
            );

          if (isSpecialityPresentWithDifferentRateType) {
            setStatus(
              `Specialty exists with a different rate type, and cannot be added with '${values?.rateType?.label}' rate type.`
            );
            addToastMessage({
              status: false,
              message: `Specialty exists with a different rate type, and cannot be added with '${values?.rateType?.label}' rate type.`,
            });
            return false;
          }

          const isSpecialityPresent =
            list?.length > 0 &&
            list.some(
              (config) =>
                config.specialityId?.label === values?.specialityId?.label
            );
          const isSpecialityWithFixedPresent = list?.some(
            (config) =>
              config.specialityId?.label === values?.specialityId?.label &&
              config.rateType?.label === "Fixed (daily)"
          );
          // Check if the speciality exists for the "OnDemand (hourly variable)" rate type
          const isSpecialityWithOnDemandVariablePresent = list?.some(
            (config) =>
              config.specialityId?.label === values?.specialityId?.label &&
              config.rateType?.label === "OnDemand (hourly variable)"
          );
          // Check if the speciality exists for the "OnDemand (hourly fixed)" rate type
          const isSpecialityWithOnDemandFixedPresent = list?.some(
            (config) =>
              config.specialityId?.label === values?.specialityId?.label &&
              config.rateType?.label === "OnDemand (hourly fixed)"
          );
          // If the rateType is "Fixed (daily)", only allow one speciality
          if (values?.rateType?.label === "Fixed (daily)") {
            if (
              isSpecialityWithFixedPresent ||
              isSpecialityWithOnDemandVariablePresent ||
              isSpecialityWithOnDemandFixedPresent
            ) {
              addToastMessage({
                status: false,
                message:
                  "A Specialty can have only one fixed(daily) rate type configuration.",
              });
              setStatus(
                "A Specialty can have only one fixed(daily) rate type configuration"
              );
              return; // Stop the submission if speciality already exists with "Fixed (daily)"
            }
          } else if (values?.rateType?.label === "OnDemand (hourly variable)") {
            if (isSpecialityWithOnDemandVariablePresent) {
              // setStatus("Speciality already exists in the configuration list with 'OnDemand (hourly variable)' rate type, but it will be added again.")
              // addToastMessage({
              //     status: true,
              //     message: "Speciality already exists in the configuration list with 'OnDemand (hourly variable)'.",
              // });
            } else if (
              isSpecialityWithOnDemandFixedPresent ||
              isSpecialityWithFixedPresent
            ) {
              setStatus(
                "Specialty exists with a different rate type, and cannot be added with 'OnDemand (hourly variable)' rate type."
              );
              addToastMessage({
                status: false,
                message:
                  "Specialty exists with a different rate type, and cannot be added with 'OnDemand (hourly variable)' rate type.",
              });
              return;
            }
          }

          //If the rateType is "OnDemand (hourly fixed)", allow same speciality multiple times only with the same rate type
          else if (values?.rateType?.label === "OnDemand (hourly fixed)") {
            if (isSpecialityWithOnDemandFixedPresent) {
              // addToastMessage({
              //     status: true,  // Success or info, as we allow the speciality to be added multiple times with same rate type
              //     message: "Speciality already exists in the configuration list with 'OnDemand (hourly fixed)' rate type, but it will be added again.",
              // });
            } else if (
              isSpecialityWithOnDemandVariablePresent ||
              isSpecialityWithFixedPresent
            ) {
              setStatus(
                "Specialty exists with a different rate type, and cannot be added with 'OnDemand (hourly fixed)' rate type."
              );
              addToastMessage({
                status: false,
                message:
                  "Specialty exists with a different rate type, and cannot be added with 'OnDemand (hourly fixed)' rate type.",
              });
              return;
            }
          }

          // check

          if (values.rateType.value === 2) {
            if (
              list?.some(
                (config) =>
                  config.specialityId?.value === values?.specialityId?.value &&
                  config.rateType?.value === values.rateType?.value &&
                  parseFloat(config.numberOfHours) ===
                    parseFloat(values.numberOfHours)
              )
            ) {
              setStatus("Procedure configration already exists.");
              addToastMessage({
                status: false,
                message: "Procedure configration already exists.",
              });
              return;
            }

            if (
              list?.some(
                (config) =>
                  config.specialityId?.value === values?.specialityId?.value &&
                  config.rateType?.value === values.rateType?.value &&
                  parseFloat(config.hourlyRate) ===
                    parseFloat(values.hourlyRate) &&
                  parseFloat(config.numberOfHours) ===
                    parseFloat(values.numberOfHours)
              )
            ) {
              setStatus("Procedure configration already exists.");
              addToastMessage({
                status: false,
                message: "Procedure configration already exists.",
              });
              return;
            }
          }

          if (values.rateType.value === 3) {
            if (
              list?.some(
                (config) =>
                  config.specialityId?.value === values?.specialityId?.value &&
                  config.rateType?.value === values.rateType?.value &&
                  parseFloat(config.numberOfHours) ===
                    parseFloat(values.numberOfHours)
              )
            ) {
              setStatus("Procedure configration already exists.");
              addToastMessage({
                status: false,
                message: "Procedure configration already exists.",
              });
              return;
            }

            if (
              list?.some(
                (config) =>
                  config.specialityId?.value === values?.specialityId?.value &&
                  config.rateType?.value === values.rateType?.value &&
                  parseFloat(config.staggeredRate) ===
                    parseFloat(values.staggeredRate) &&
                  parseFloat(config.numberOfHours) ===
                    parseFloat(values.numberOfHours)
              )
            ) {
              setStatus("Procedure configration already exists.");
              addToastMessage({
                status: false,
                message: "Procedure configration already exists.",
              });
              return;
            }
          }

          onAdd(values);
          resetForm();
        } catch (error) {
          console.error("Error during form submission:", error);
        }
      }}
    >
      {({
        errors,
        touched,
        values,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
      }) => (
        <Form>
          <table>
            <tbody>
              <tr>
                <td width="50%">
                  <Label title="Specialty" required={true} />
                  <PaginatedDropdown
                    datakey="records"
                    url={`${API_URL}/api/1.0.0/reference/specialities?`}
                    mapOptions={(records) =>
                      mapOptions(records, "id", labelSpecilityFunc)
                    }
                    setFieldTouched={() =>
                      setFieldTouched("specialityId", true)
                    }
                    placeHolder="Search"
                    selectedValue={values.specialityId}
                    error={errors.specialityId && touched.specialityId}
                    onChange={(v) => {
                      setFieldValue("specialityId", v);
                      setFieldValue("rateType", null);
                      setFieldValue("rate", "");
                      setFieldValue("numberOfHours", "");
                      setFieldValue("hourlyRate", "");
                      setFieldValue("staggeredRate", "");
                    }}
                  />
                </td>
                <td width="50%">
                  <Label title="Rate Type" required={true} />
                  <PaginatedDropdown
                    datakey="records"
                    url={`${API_URL}/api/1.0.0/reference/onCall-types?`}
                    mapOptions={(records) =>
                      mapOptions(records, "id", labelOnCallTypeFunc)
                    }
                    setFieldTouched={() => setFieldTouched("rateType", true)}
                    placeHolder="Search"
                    selectedValue={values.rateType}
                    error={errors.rateType && touched.rateType}
                    onChange={(v) => {
                      setFieldValue("rateType", v);
                      setFieldValue("rate", "");
                      setFieldValue("numberOfHours", "");
                      setFieldValue("hourlyRate", "");
                      setFieldValue("staggeredRate", "");
                    }}
                  />
                </td>
              </tr>
              {values?.rateType?.value === 1 && (
                <tr>
                  {/* Fixed (daily) fields */}
                  <td>
                    <Label title="Rate " required={true} />
                    <Field
                      type="number"
                      name="rate"
                      placeholder="Enter daily rate"
                      className={`col-1-1 primary ${
                        errors.rate && touched.rate ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="rate"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
              )}
              {values?.rateType?.value === 2 && (
                <tr>
                  {/* OnDemand (hourly fixed) fields */}
                  <td width="50%">
                    <Label title="Number Of Hours " required={true} />
                    <Field
                      type="number"
                      name="numberOfHours"
                      placeholder="Enter number of hours"
                      className={`col-1-1 primary ${
                        errors.numberOfHours && touched.numberOfHours
                          ? "error"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="numberOfHours"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td>
                    <Label title="Hourly Rate " required={true} />
                    <Field
                      type="number"
                      name="hourlyRate"
                      placeholder="Enter hourlyRate rate"
                      className={`col-1-1 primary ${
                        errors.hourlyRate && touched.hourlyRate ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="hourlyRate"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
              )}

              {values?.rateType?.value === 3 && (
                <tr>
                  {/* OnDemand (hourly variable) fields */}
                  <td width="50%">
                    <Label title="Number Of Hours " required={true} />
                    <Field
                      type="number"
                      name="numberOfHours"
                      placeholder="Enter number of hours"
                      className={`col-1-1 primary ${
                        errors.numberOfHours && touched.numberOfHours
                          ? "error"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="numberOfHours"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td>
                    <Label title="Staggered Rate" required={true} />
                    <Field
                      type="number"
                      name="staggeredRate"
                      placeholder="Enter staggered rate"
                      className={`col-1-1 primary ${
                        errors.staggeredRate && touched.staggeredRate
                          ? "error"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="staggeredRate"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={2}>
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonLightTextIcon
                        title="Add Procedure Config"
                        type="button"
                        onClick={() => {
                          handleSubmit();
                        }}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {status && (
            <>
              <br />
              <p
                style={{
                  color: "red",
                  marginBottom: "17px",
                }}
              >
                {status}
              </p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const NestedOnCallForm = ({
  defaltOnCallTypeId = null,
  onReplace,
  onAdd,
  list,
  removeOnCallConfig,
}) => {
  const [status, setStatus] = useState();
  const { addToastMessage } = useUI();
  const [initialValues, setInitialValues] = useState({
    onCallTypeId: null,
    numberOfHour: "",
    rate: "",
    daysPerWeek: "",
    dailyRate: "",
    hourlyRate: "",
    rateTierLimit: "",
    staggeredRate: "",
  });

  const validationSchema = Yup.object().shape({
    onCallTypeId: Yup.mixed()
      .nullable()
      .test("On Call Type required", "Specialty is required", function (value) {
        return value !== null && value.value;
      }),

    daysPerWeek: Yup.string()
      .test(
        "required-if-onCallTypeId-1",
        "Days per week is required",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 1) {
            return !!value; // Ensure value exists if onCallTypeId is 1
          }
          return true;
        }
      )
      .test(
        "valid-decimal-if-onCallTypeId-1",
        "Days per week must be a valid number with up to two decimal places.",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 1) {
            return isValidDecimal(value);
          }
          return true;
        }
      )
      .test(
        "max-daysPerWeek",
        "Days Per Week cannot exceed 7.",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 1 && value) {
            return parseFloat(value) <= 7;
          }
          return true;
        }
      ),

    dailyRate: Yup.string()
      .test(
        "required-if-onCallTypeId-1",
        "Daily rate is required",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 1) {
            return !!value;
          }
          return true;
        }
      )
      .test(
        "valid-decimal-if-onCallTypeId-1",
        "Daily rate must be a valid number with up to two decimal places.",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 1) {
            return isValidDecimal(value);
          }
          return true;
        }
      ),

    numberOfHour: Yup.string()
      .test(
        "required-if-onCallTypeId-2",
        "Number of hours is required",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 2) {
            return !!value;
          }
          return true;
        }
      )
      .test(
        "valid-decimal-if-onCallTypeId-2",
        "Number of hours must be a valid number.",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 2) {
            return isValidNumber(value);
          }
          return true;
        }
      ),

    hourlyRate: Yup.string()
      .test(
        "required-if-onCallTypeId-2",
        "Hourly rate is required",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 2) {
            return !!value;
          }
          return true;
        }
      )
      .test(
        "valid-decimal-if-onCallTypeId-2",
        "Hourly Rate must be a valid number.",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 2) {
            return isValidDecimal(value);
          }
          return true;
        }
      ),

    rateTierLimit: Yup.string()
      .test(
        "required-if-onCallTypeId-3",
        "Tier limit rate is required",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 3) {
            return !!value;
          }
          return true;
        }
      )
      .test(
        "valid-number-if-onCallTypeId-3",
        "Tier Limit Rate must be a valid number.",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 3) {
            return isValidNumber(value);
          }
          return true;
        }
      ),

    staggeredRate: Yup.string()
      .test("required-if-3", "Staggered rate is required", function (value) {
        const { onCallTypeId } = this.parent;
        if (onCallTypeId?.value === 3) {
          return !!value;
        }
        return true;
      })
      .test(
        "required-if-3",
        "Staggered rate must be a valid number with up to two decimal places.",
        function (value) {
          const { onCallTypeId } = this.parent;
          if (onCallTypeId?.value === 3) {
            return isValidDecimal(value);
          }
          return true;
        }
      ),
  });

  useEffect(() => {
    if (defaltOnCallTypeId) {
      setInitialValues({
        onCallTypeId: defaltOnCallTypeId,
        numberOfHour: "",
        rate: "",
        daysPerWeek: "",
        dailyRate: "",
        hourlyRate: "",
        rateTierLimit: "",
        staggeredRate: "",
      });
    }
  }, [defaltOnCallTypeId]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnChange={false}
      onSubmit={(values, { setFieldValue }) => {
        if (list?.length > 0 && values?.onCallTypeId?.value === 1) {
          setStatus(
            "Fixed (Daily) call type can have only one configuration. "
          );
          addToastMessage({
            status: false,
            message:
              "Fixed (Daily) call type can have only one configuration. ",
          });
          return false;
        } else {
          onAdd(values);
        }
        setStatus("");
        setFieldValue("numberOfHour", "");
        setFieldValue("rate", "");
        setFieldValue("daysPerWeek", "");
        setFieldValue("dailyRate", "");
        setFieldValue("hourlyRate", "");
        setFieldValue("rateTierLimit", "");
        setFieldValue("staggeredRate", "");
      }}
    >
      {({
        errors,
        touched,
        values,
        setFieldError,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
      }) => (
        <Form onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}>
          <table>
            <tbody>
              <tr>
                <td width="50%">
                  <Label title="On Call Type" required={true} />
                  <PaginatedDropdown
                    datakey="records"
                    url={`${API_URL}/api/1.0.0/reference/onCall-types?`}
                    mapOptions={(records) =>
                      mapOptions(records, "id", labelOnCallTypeFunc)
                    }
                    setFieldTouched={() =>
                      setFieldTouched("onCallTypeId", true)
                    }
                    placeHolder="Search"
                    selectedValue={values.onCallTypeId}
                    error={errors.onCallTypeId && touched.onCallTypeId}
                    onChange={(v) => {
                      setFieldValue("onCallTypeId", v);
                      if (v && v !== values.onCallTypeId) {
                        removeOnCallConfig();
                      }
                      setFieldValue("daysPerWeek", "");
                      setFieldValue("dailyRate", "");
                      setFieldValue("numberOfHour", "");
                      setFieldValue("hourlyRate", "");
                      setFieldValue("rateTierLimit", "");
                      setFieldValue("staggeredRate", "");
                    }}
                  />
                </td>
                <td width="50%"></td>
              </tr>
              {values?.onCallTypeId?.value === 1 && (
                <tr>
                  {/* Fixed (daily) fields */}
                  <td width="50%">
                    <Label title="Days Per Week" required={true} />
                    <Field
                      type="number"
                      values={values.daysPerWeek}
                      name="daysPerWeek"
                      placeholder="Enter days per week"
                      className={`col-1-1 primary ${
                        errors.daysPerWeek && touched.daysPerWeek ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="daysPerWeek"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td>
                    <Label title="Daily Rate" required={true} />
                    <Field
                      type="number"
                      name="dailyRate"
                      placeholder="Enter daily rate"
                      className={`col-1-1 primary ${
                        errors.dailyRate && touched.dailyRate ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="dailyRate"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
              )}
              {values?.onCallTypeId?.value === 2 && (
                <tr>
                  {/* OnDemand (hourly variable) fields */}
                  <td width="50%">
                    <Label title="Number of Hours" required={true} />
                    <Field
                      type="number"
                      name="numberOfHour"
                      placeholder="Enter number of hours"
                      className={`col-1-1 primary ${
                        errors.numberOfHour && touched.numberOfHour
                          ? "error"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="numberOfHour"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td>
                    <Label title="Hourly Rate" required={true} />
                    <Field
                      type="number"
                      name="hourlyRate"
                      placeholder="Enter hourlyRate rate"
                      className={`col-1-1 primary ${
                        errors.hourlyRate && touched.hourlyRate ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="hourlyRate"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
              )}

              {values?.onCallTypeId?.value === 3 && (
                <tr>
                  {/* OnDemand (hourly fixed) fields */}
                  <td width="50%">
                    <Label title="Rate Tier Limit" required={true} />
                    <Field
                      type="number"
                      name="rateTierLimit"
                      placeholder="Enter rate tier limit"
                      className={`col-1-1 primary ${
                        errors.rateTierLimit && touched.rateTierLimit
                          ? "error"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="rateTierLimit"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td>
                    <Label title="Staggered Rate" required={true} />
                    <Field
                      type="number"
                      name="staggeredRate"
                      placeholder="Enter staggered rate"
                      className={`col-1-1 primary ${
                        errors.staggeredRate && touched.staggeredRate
                          ? "error"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="staggeredRate"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={2}>
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonLightTextIcon
                        title={`Add On Call Config`}
                        type="button"
                        onClick={() => {
                          handleSubmit();
                        }}
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {status && (
            <>
              <br />
              <p
                style={{
                  color: "red",
                  marginBottom: "17px",
                }}
              >
                {status}
              </p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default UpdateForm;