import ResultsPerPage from "../../components/common/ResultsPerPage";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import PaginatedDropdown, { mapOptions } from "../../components/ui/PaginatedDropdown";
import {
	API_URL,
	CLAIM_STATUS_DROP_DOWN_OPTIONS,
	ACTIVITY_STATUS_DROP_DOWN_OPTIONS,
	ACTIVITY_BILL_DROP_DOWN_OPTIONS,
	HOSPITAL_CONTRACT_DROP_DOWN_OPTIONS,
} from "../../utilities/constants"; 
import { formatServerDate, getUserTypeInfo, labelAssistantsFunc, labelPatientsFunc } from "../../utilities/helpers";
import DateRangeInput from "../../components/ui/DateRangeInput";
import useQueryParams from "../../hooks/useQueryParams";
import Label from "../../components/ui/Label";
import { GrPowerReset } from "react-icons/gr";


const ActivityFilters = ({ resetFilters }) => {
	const { updateQueryParam, queryParams } = useQueryParams(); 
 

	const DROPDOWNS = [
		{
			title: "Patients",
			url: "/reference/patients",
			param: "patient",
			mapFunc: mapOptions,
			labelFunc: labelPatientsFunc,
			placeholder: "Search patient",
		},
		{
			title: "Users",
			url: "/users/list",
			param: "createdBy",
			mapFunc: mapOptions,
			labelFunc: (o) => `${o.name} ${o.lastName}`,
			placeholder: "Search user",
		},
		{
			title: "Regions",
			url: "/reference/regions",
			param: "company",
			mapFunc: mapOptions,
			labelFunc: (o) => `${o.name}`,
			placeholder: "Search region",
		},
		// {
		// 	title: "Practices",
		// 	url: "/reference/practice",
		// 	param: "practice",
		// 	mapFunc: mapOptions,
		// 	labelFunc: (o) => o.payToName,
		// 	placeholder: "Search practice",
		// },
		{
			title: "Facilities",
			url: "/reference/facility",
			param: "facility",
			mapFunc: mapOptions,
			labelFunc: (o) => o.facilityName,
			placeholder: "Search facility",
		},
		{
			title: "Surgeons",
			url: "/reference/surgeon",
			param: "physician",
			mapFunc: mapOptions,
			labelFunc: (o) => `${o?.firstName} ${o?.middleName} ${o?.lastName}`,
			placeholder: "Search surgeon",
		},
		// {
		// 	title: "Insurances",
		// 	url: "/reference/insurances",
		// 	param: "insurance",
		// 	mapFunc: mapOptions,
		// 	labelFunc: (o) => o.description,
		// 	placeholder: "Search insirance",
		// },
		{
			title: "Assistants",
			url: "/reference/assistants",
			param: "assistant",
			mapFunc: mapOptions,
			labelFunc: labelAssistantsFunc,
			placeholder: "Search assistant",
		},
	];

	return (
		<div className='col-1-1 filters'>
			{DROPDOWNS.map(
				({ title, url, param, mapFunc, labelFunc, placeholder }) => (
					<div key={param}>
						<Label variant="filter" title={title} />
						<PaginatedDropdown
							url={`${API_URL}/api/1.0.0${url}?`}
							selectedValue={
								queryParams.get(param)
									? JSON.parse(queryParams.get(param))
									: null
							}
							onChange={(v) =>{
								console.log(v);
								updateQueryParam(
									param,
									v ? JSON.stringify(v) : null
								)
							}
							}
							mapOptions={(records) =>
								mapFunc(records, "id", labelFunc)
							}
							placeHolder={placeholder}
						/>
					</div>
				)
			)}
			<div>
				<Label variant="filter" title='Status' />
				<select
					className='primary'
					value={queryParams.get("statusFilter") || ""}
					onChange={(v) =>
						updateQueryParam("statusFilter", v.target.value)
					}>
					<option value=''>Select Status</option>
					{ACTIVITY_STATUS_DROP_DOWN_OPTIONS.map((o) => (
						<option key={o.value} value={o.value}>
							{o.label}
						</option>
					))}
				</select>
			</div>
			<div>
				<Label variant="filter" title='Bill Options' />
				<select
					className='primary'
					value={queryParams.get("billoption") || ""}
					onChange={(v) =>
						updateQueryParam("billoption", v.target.value)
					}>
					<option value=''>Select Bill Options</option>
					{ACTIVITY_BILL_DROP_DOWN_OPTIONS.map((o) => (
						<option key={o.value} value={o.value}>
							{o.label}
						</option>
					))}
				</select>
			</div>
			<div>
				<Label variant="filter" title='Date Filter' />
				<DateRangeInput 
				    initialRange={{
							startDate: queryParams.get("startDate") || null , 
							endDate : queryParams.get("endDate") || null 
						}} 
					onApply={(v) => { 
						updateQueryParam("startDate", v?.startDate || null); 
						updateQueryParam("endDate", v?.endDate || null); 
						}} 
					onCancel={(v) => { 
						updateQueryParam("startDate", null); 
						updateQueryParam("endDate", null); 
					}}  
					/>
			</div>
			<div className='flex center-left' style={{ gap: "12px" }}>
				<div>
					<Label variant="filter" title='Reset' />
					<ButtonLightTextIcon
						title='Reset'
						className='light'
						icon={
							<GrPowerReset />

						}
						onClick={resetFilters}
					/>
				</div>
				<div className='mar-l-a'>
					<Label variant="filter" title='Per page' />
					<ResultsPerPage />
				</div>
			</div>
		</div>
	);
};

export default ActivityFilters;
