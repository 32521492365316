import React, { useState, useEffect, useRef } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { handleApiCall } from "../../utilities/helpers";
import zipcodes from "zipcodes";
import { API_URL } from "../../utilities/constants";
import LoaderIcon from "./LoaderIcon";

const ZipCodeInput = ({
  name,
  clearFields,
  onZipDetailsFetched,
  enableApiCall = true,
  ...props
}) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    setFieldError,
  } = useFormikContext();
  const [loading, setLoading] = useState(false);
  const lastFetchedZip = useRef(null);
  const debounceRef = useRef(null);
  const [foundInDb, setFoundInDb] = useState(false);
  const [foundInZipcodes, setFoundInZipcodes] = useState(false);

  useEffect(() => {
    if (!enableApiCall) return;
    const zip = values[name];

    if (!zip) {
      return;
    }

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      setLoading(true);
      setFoundInDb(false);
      setFoundInZipcodes(false);

      // Step 1: Validate ZIP code
      const validateZip = () => {
        return new Promise((resolve, reject) => {
          if (/^\d{5}$/.test(zip)) {
            resolve();
          } else {
            setFieldError(name, "Invalid ZIP code format");
            console.log("Invalid ZIP code format");
            clearFields();

            setFieldValue("stateCode", "");
            setLoading(false);
            reject("Invalid ZIP code format");
          }
        });
      };

      // Step 2: Search in DB
      const searchInDb = () => {
        return handleApiCall(
          `${API_URL}/api/1.0.0/reference/zip-details/${zip}`
        ).then((response) => {
          if (response.responseStatus === true && response?.data) {
            setFoundInDb(true);
            onZipDetailsFetched(response.data);
            return Promise.resolve();
          }
          clearFields();

          setFieldValue("stateCode", "");
          return Promise.reject("ZIP code not found");
        });
      };

      // Step 3: Search in ZIP library and store in DB
      const searchInLib = () => {
        const zipData = zipcodes.lookup(zip);

        if (!zipData) {
          return Promise.reject("ZIP code not found");
        }

        setFoundInZipcodes(true);
        return handleApiCall(`${API_URL}/api/1.0.0/zip`, {
          method: "POST",
          body: {
            stateCode: zipData.state,
            cityName: zipData.city,
            zipCode: zip,
          },
        }).then((postResponse) => {
          if (postResponse.responseStatus === true) {
            onZipDetailsFetched(postResponse.data);
          } else {
            clearFields();

            setFieldValue("stateCode", "");
            throw new Error(
              postResponse.responseDescription || "Failed to save ZIP code"
            );
          }
        });
      };

      // Execute in sequence
      validateZip()
        .then(() => searchInDb())
        .catch(() => searchInLib())
        .catch((error) => {
          console.error("Error fetching ZIP code details:", error);
          setFieldError(name, "ZIP code not found");
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  }, [values[name]]);

  return (
    <div
      style={{ position: "relative", display: "inline-block", width: "100%" }}
    >
      <Field
        type="text"
        name={name}
        placeholder="Zip Code"
        className={`col-1-1 primary ${
          errors[name] && touched[name] ? "error" : ""
        }`}
        style={{ paddingRight: loading ? "30px" : "10px" }} // Adjust padding to make space for loader
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          setFieldTouched(name, true, false);
        }}
        {...props}
      />
      {loading && (
        <span
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            display: "flex",
            alignItems: "center",
            pointerEvents: "none",
          }}
        >
          <LoaderIcon style={{ height: "20px", width: "20px" }} />
        </span>
      )}
      <ErrorMessage name={name} component="p" className="error-messages" />
    </div>
  );
};

export default ZipCodeInput;
