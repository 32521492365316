import { useRef, useState } from "react";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import DragFileUpload from "../../components/ui/DragFileUpload";
import Label from "../../components/ui/Label";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { ALLOWED_FILE_TYPES, API_URL } from "../../utilities/constants";
import { handleApiCall } from "../../utilities/helpers";
import { useScanflow } from "./scanflow-context";
import { useUI } from "../../context/ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TbArrowBackUp } from "react-icons/tb";
import { MdCancel } from "react-icons/md";
import { FcInfo } from "react-icons/fc";
import { FiPlusCircle } from "react-icons/fi";
const DocumentForm = ({ id, files, setShow }) => {
    const {
      addToastMessage,
      profile: { id: userId },
    } = useUI();
    const [loading, setLoading] = useState(false);
    const fileUploadRef = useRef();
    const validationSchema = Yup.object().shape({
      file_type: Yup.string().required("File Type is required"),
      file: Yup.string().required("File is required"),
    });
    const [uploaded, setUploaded] = useState(false);
  
    const handleReset = () => {
      if (fileUploadRef.current) {
        fileUploadRef.current.resetFile();
      }
    };
    const { refreshHandler } = useScanflow();
  
    return (
      <section
        style={{
          position: "fixed",
          inset: 0,
          zIndex: 9999,
          backgroundColor: "rgba(255, 255, 255, 0.86)",
        }}
      >
        <section
          className="col-1-1 flex center-center"
          style={{
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            style={{
              width: "486px",
              padding: "24px",
              height: "auto",
              maxHeight: "80vh",
              border: "solid 1px #f0f0f0",
              background: "white",
              borderRadius: "8px",
              margin: "0 auto",
              boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
            }}
          >
            <div
              className="col-1-1 flex center-left"
              style={{
                borderBottom: "solid 1px #f0f0f0",
                paddingBottom: "12px",
                marginBottom: "16px",
              }}
            >
              <h6
                className="color-primary f-w-300 opl-tooltip"
                data-tooltip="View entire history of the activity below."
              >
                Add File
              </h6>
              <div
                className="mar-l-a cursor-pointer"
                onClick={() => setShow(false)}
              >
                <MdCancel size={20} />
              </div>
            </div>
            <div>
              <Formik
                initialValues={{
                  file_type: "facesheet",
                  file: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {
                  setLoading(true);
                  try {
                    let newFiles = files.map((o) => ({
                      type: o.type,
                      fileUrl: o.fileUrl,
                    }));

                    const hasFacesheet = newFiles.some(
                      (file) => file.type === "facesheet"
                    );

                    if (values.file_type === "facesheet" && hasFacesheet) {
                      newFiles = newFiles.filter(
                        (file) => file.type !== "facesheet"
                      );
                    }

                    const payloadJSON = {
                      files: [
                        {
                          type: values.file_type,
                          fileUrl: values.file,
                        },
                        ...newFiles,
                      ],
                      user_id: userId,
                      id: id,
                    };

                    const response = await handleApiCall(
                      API_URL + `/api/1.0.0/activity`,
                      {
                        method: "PUT",
                        body: payloadJSON,
                      }
                    );
                    if (response.responseStatus === true) {
                      addToastMessage({
                        status: true,
                        message: "File added successfully.",
                      });
                      resetForm();
                      setShow(false);
                      refreshHandler();
                      handleReset();
                    } else {
                      addToastMessage({
                        status: false,
                        message: `${
                          response.responseDescription ||
                          "Something went wrong, please try again later."
                        }`,
                      });
                    }
                  } catch (e) {
                    addToastMessage({
                      status: false,
                      message: "Something went wrong, please try again later.",
                    });
                    console.error("Error:", e);
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                {({ errors, touched, setFieldValue, values, submitForm }) => (
                  <Form className="col-1-1">
                    <table className="col-1-1">
                      <tbody>
                        <tr>
                          <td colSpan="2">
                            <Label title="File Type" />
                            <Field
                              as="select"
                              name="file_type"
                              onChange={(e) => {
                                setFieldValue("file_type", e.target.value);
                                handleReset();
                              }}
                              className={`col-1-1 primary ${
                                errors.file_type && touched.file_type
                                  ? "error"
                                  : ""
                              }`}
                            >
                              <option value="">Select</option>
                              {ALLOWED_FILE_TYPES.map((d, i) => (
                                <option value={d.value} key={i}>
                                  {d.label}
                                </option>
                              ))}
                            </Field>
                          </td>
                        </tr>
                        {values.file_type && (
                          <tr>
                            <td colSpan="2">
                              <div
                                className="flex center-left"
                                style={{
                                  gap: "8px",
                                }}
                              >
                                <div
                                  className="opl-tooltip"
                                  data-tooltip="Please only select PDF files for upload."
                                >
                                  <Label title="Upload File" />
                                </div>
                                <div
                                  className="opl-tooltip"
                                  data-tooltip="Please only select PDF files for upload."
                                >
                                  <FcInfo />
                                </div>
                              </div>
                              <DragFileUpload
                                ref={fileUploadRef}
                                fileType={values.file_type}
                                error={errors.file && touched.file}
                                afterUpload={(url) => {
                                  if (url) {
                                    setFieldValue("file", url);
                                  }
                                }}
                                setUploading={setUploaded}
                              />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <br />
                        </tr>
                        <tr>
                          <div
                            style={{
                              marginLeft: "auto",
                            }}
                          >
                            <ButtonTextIcon
                              title="Add File"
                              type="submit"
                              style={{
                                width: "100%",
                              }}
                              disabled={loading || uploaded}
                              icon={loading ? <LoaderIcon /> : <FiPlusCircle />}
                            />
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </Form>
                )}
              </Formik>
            </div>
            <div
              className="col-1-1 flex center-left"
              style={{
                marginTop: "16px",
                borderTop: "solid 1px #f0f0f0",
                paddingTop: "16px",
              }}
            >
              <ButtonLightTextIcon
                title="Cancel"
                onClick={() => setShow(false)}
                icon={<TbArrowBackUp />}
              />
            </div>
          </div>
        </section>
      </section>
    );
};
  
export default DocumentForm;