  const InfoItem = ({ icon, label, name, showfulltext = false,variant="view", ...rest }) => {
    return (
      <div {...rest}>
        {!showfulltext ? (
          <div className="flex center-left" style={{ gap: "10px" }}>
            <div>{icon}</div>
            <div style={{ overflow: "hidden" }}>
              <p
                className="f-w-300"
                style={{
                  color: "#aaaaaa",
                  fontSize: "10px",
                  lineHeight: 1,
                  paddingBottom: "2px",
                }}
                title={label}
              >
                {label}
              </p>
              <p
                title={name}
                style={{
                  width: variant!=="view"?"132px":"350px",
                  lineHeight: 1,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  paddingBottom: "2px",
                }}
              >
                {name}
              </p>
            </div>
          </div>
        ) : (
          <div
            style={{
              gap: "10px",
              width: "100%",
            }}
          >
            <div className="flex center-left" style={{ gap: "10px" }}>
              <div>{icon}</div>
              <div>
                <p
                  className="f-w-300"
                  style={{
                    color: "#aaaaaa",
                    fontSize: "10px",
                    lineHeight: 1,
                    paddingBottom: "2px",
                  }}
                  title={label}
                >
                  {label}
                </p>
              </div>
            </div>
            <div style={{ overflow: "hidden" }}>
                <p
                style={{
                  width: "100%",
                  lineHeight: 1.2,
                  borderRadius: "5px",
                  marginLeft: "30px",
                  "white-space": "pre-wrap",
                  paddingRight: "30px",
                  paddingBottom: "2px",
                }}
                title={name}
              >
                {name}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  export default InfoItem;