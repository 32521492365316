import React, { useEffect, useState } from "react";
import {
  backgroundColor,
  chartOptions,
  emptyBarData,
  emptyPieData,
} from "./ChartConfig";
import DateRangeInput from "../../components/ui/DateRangeInput";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { handleApiCall, jsonToQueryParams } from "../../utilities/helpers";
import { API_URL } from "../../utilities/constants";
import BarChart from "../../components/charts/BarChart";

const InsurancePi = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [insuranceData, setInsuranceData] = useState([]);
  const [controller, setController] = useState(null);

  const fetchData = async () => {
    setInsuranceData([]);
    setLoading(true);
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    setLoading(true);

    try {
      const query = jsonToQueryParams({
        startDate: startDate || null,
        endDate: endDate || null,
      });
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/analytics/top-insurance?${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setInsuranceData(response.data);
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const labels = insuranceData.map(
    (item) => item.insurance.toUpperCase() || ""
  );
  const data = insuranceData.map((item) => item.count);
  const pieData = {
    labels,
    datasets: [
      {
        label: "Insurance Usage (%)",
        data,
        backgroundColor: backgroundColor.slice(0, insuranceData.length),
        borderColor: backgroundColor
          .slice(0, insuranceData.length)
          .map((color) => color.replace("0.7", "1")),
        borderWidth: 1,
      },
    ],
  };

  const customChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    animation: {
      animateRotate: true,
      animateScale: true,
    },
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        display: false,
        position: "right",
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            const label = labels[context.dataIndex] || "";
            const count = data[context.dataIndex] || 0;
            return `${label} : ${count} Activities`;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (value) {
            const label = this.getLabelForValue(value);
            return label.length > 10 ? label.substring(0, 10) + "..." : label;
          },
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="chart-card">
      <div
        className="flex center-center justify-between flex-row of-hid"
        style={{
          padding: "16px",
          gap: "16px",
        }}
      >
        <p className="f-w-700">Top 10 Insurance Usage</p>
        <div>
          <DateRangeInput
            className="date-range-input"
            initialRange={{ startDate, endDate }}
            onApply={(v) => {
              setStartDate(v?.startDate || null);
              setEndDate(v?.endDate || null);
            }}
            onCancel={() => {
              setStartDate(null);
              setEndDate(null);
            }}
          />
        </div>
      </div>
      <div style={{ height: "300px" }}>
        {loading ? (
          <div className="flex center-center" style={{ height: "100%" }}>
            <LoaderIcon style={{ width: "30px", height: "30px" }} />
          </div>
        ) : insuranceData.length > 0 ? (
          <BarChart data={pieData} options={customChartOptions} />
        ) : (
          <BarChart data={emptyBarData} options={customChartOptions} />
        )}
      </div>
    </div>
  );
};

export default InsurancePi;
