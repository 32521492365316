import FallbackErrorUI from "./components/FallbackErrorUI";

const NotFoundPage = () => {
  return (
    <FallbackErrorUI
      pageTitle="404"
      title="Page Not Found."
      message="The page you are looking for does not exist or has been moved."
    />
  );
};

export default NotFoundPage;