import React, { useEffect, useState } from "react";
import PageToolbar from "../components/common/PageToolbar";
import ActvitiesArc from "./dashboard/ActvitiesArc";
import ActvitiesBar from "./dashboard/ActvitiesBar";
import InvoicePi from "./dashboard/InvoicePi";
import BillLine from "./dashboard/BillLine";
import InvoiceLine from "./dashboard/InvoiceLine";
import ScanFlowArc from "./dashboard/ScanFlowArc";
import ScanFlowBar from "./dashboard/ScanFlowBar";
import AssistantPi from "./dashboard/AssistantPi";
import FacilitiesPi from "./dashboard/FacilitiesPi";
import InsurancePi from "./dashboard/InsurancePi";
import PatientLine from "./dashboard/PatientLine";
import UserTypePi from "./dashboard/UserTypeDoughnut";
import {
  TbUserHeart,
  TbUsers,
  TbStethoscope,
  TbClipboardList,
  TbUserPlus,
  TbClockCheck,
  TbCalendarCheck,
  TbUserStar,
} from "react-icons/tb";
import { BsMotherboard } from "react-icons/bs";
import { API_URL } from "../utilities/constants";
import { handleApiCall } from "../utilities/helpers";
import { use } from "react";
import { useNavigate } from "react-router-dom";

const SmallCard = ({ title, count, icon, loading, url }) => {
  const [displayCount, setDisplayCount] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    let start = 0;
    const duration = 1000;
    const increment = Math.ceil(count / (duration / 16));
    let timer;

    if (count > 0) {
      timer = setInterval(() => {
        start += increment;
        if (start >= count) {
          setDisplayCount(count);
          clearInterval(timer);
        } else {
          setDisplayCount(start);
        }
      }, 16);
    } else {
      setDisplayCount(0);
    }

    return () => clearInterval(timer);
  }, [count]);
  return (
    <div
      className="small-card-container cursor-pointer"
      onClick={() => {
        navigate(url);
      }}
    >
      <div className="flex small-card">
        <div className="small-card-content">
          <p className="small-card-title">{title}</p>
          <p className="small-card-count">{displayCount}</p>
        </div>
        <div className="small-card-icon">{icon}</div>
      </div>
      <p className="small hover-t-d-ul">Click here to view</p>
    </div>
  );
};

const DashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    patientCount: 0,
    assistantCount: 0,
    userCount: 0,
    surgeonCount: 0,
    facilityCount: 0,
  });

  const smallCardsData = [
    {
      title: "Total Patients",
      count: data.patientCount,
      icon: <TbUserHeart />,
      url: "/patients",
    },
    {
      title: "Total Users",
      count: data.userCount,
      icon: <TbUsers />,
      url: "/users",
    },
    {
      title: "Total Assistant",
      count: data.assistantCount,
      icon: <TbClipboardList />,
      url: "/surgical_assistance",
    },
    {
      title: "Total Facility",
      count: data.facilityCount,
      icon: <TbStethoscope />,
      url: "/facilities",
    },
    {
      title: "Total Surgeon",
      count: data.surgeonCount,
      icon: <TbUserPlus />,
      url: "/surgeons",
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/analytics/stat-overview`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        setData({
          patientCount: response.data.patientCount,
          assistantCount: response.data.assistantCount,
          userCount: response.data.userCount,
          surgeonCount: response.data.surgeonCount,
          facilityCount: response.data.facilityCount,
        });
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section id="patient">
      <PageToolbar title="Analytics" />
      <div className="dashboard">
        <div className="small-cards-container">
          {smallCardsData.map((card, index) => (
            <SmallCard
              key={index}
              title={card.title}
              count={card.count}
              icon={card.icon}
              url={card.url}
              loading={loading}
            />
          ))}
        </div>

        <section className="charts-container">
          <div className="chart-section">
            <div className="section-title mar-0 ">
              <div className="flex center-left" style={{ gap: "5px" }}>
                <TbCalendarCheck size={24} />
                <span className="f-w-600 font-size-14">
                  Activity's Overview
                </span>
              </div>
            </div>
            <div className="chart-grid">
              <ActvitiesBar />
              <ActvitiesArc />
            </div>
          </div>
          <div className="chart-section">
            <div className="section-title mar-0 ">
              <div className="flex center-left" style={{ gap: "5px" }}>
                <TbClockCheck size={24} />
                <span className="f-w-600 font-size-14">Scan Flow Overview</span>
              </div>
            </div>
            <div className="chart-grid">
              <ScanFlowBar />
              <ScanFlowArc />
            </div>
          </div>

          <div className="chart-section">
            <div className="section-title mar-0 ">
              <div className="flex center-left" style={{ gap: "5px" }}>
                <TbUserStar size={24} />
                <span className="f-w-600 font-size-14">Account's Overview</span>
              </div>
            </div>
            <div className="chart-grid">
              <BillLine />
              <InvoicePi />
            </div>
            <div className="chart-grid" style={{ marginTop: "20px" }}>
              <InvoiceLine />
            </div>
          </div>
          <div className="chart-section">
            <div className="section-title mar-0 ">
              <div className="flex center-left" style={{ gap: "5px" }}>
                <BsMotherboard size={24} />
                <span className="f-w-600 font-size-14">Others</span>
              </div>
            </div>
            <div className="chart-grid">
              <PatientLine />
              <UserTypePi />
            </div>
            <div className="chart-grid" style={{ marginTop: "20px" }}>
              <AssistantPi />
            </div>
            <div className="chart-grid" style={{ marginTop: "20px" }}>
              <FacilitiesPi />
              <InsurancePi />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default DashboardPage;
