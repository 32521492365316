import { useNavigate } from "react-router-dom";
import PageToolbar from "../components/common/PageToolbar";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import AddForm from "./contract/AddForm";
import { TbArrowBackUp, TbXboxX } from "react-icons/tb";

const AddContractPage = () => {
	const navigate = useNavigate();
  return (
    <section id="add-insurance">
      <PageToolbar
        title="Add Contract"
        button={
          <ButtonTextIcon
            title="Cancel"
            className="light mar-l-a"
            onClick={() => navigate("/contracts")}
            icon={
            <TbArrowBackUp />
            }
          />
        }
      />
      <div className="col-1-1">
        <div className="form-holder">
          <div className="inner">
            <AddForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddContractPage;
