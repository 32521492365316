import React, { useEffect, useState } from "react";
import PieChart from "../../components/charts/PieChart";
import { backgroundColor, chartOptions, emptyPieData } from "./ChartConfig";
import { API_URL, USER_TYPE_OPTIONS } from "../../utilities/constants";
import { handleApiCall, jsonToQueryParams } from "../../utilities/helpers";
import LoaderIcon from "../../components/ui/LoaderIcon";

const generateRandomData = () => {
  return USER_TYPE_OPTIONS.map(() => Math.floor(Math.random() * 100) + 1);
};

const UserTypePi = () => {
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState(null);
  const [data, setData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalAdmin, setTotalAdmin] = useState(0);
  const fetchData = async () => {
    setData([]);
    setLoading(true);
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    setLoading(true);

    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/analytics/user-type-count`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        if (response.data.length === 0) {
          setTotalUsers(0);
          setTotalAdmin(0);
          setData([])
        } else if (response.data.length > 0) {
          const validTypes = USER_TYPE_OPTIONS.map((option) => option.value);
          const filteredData = response.data
            .filter((item) => validTypes.includes(item.type))
            .map((item) => {
              let updatedType = item.type.replace(/-/g, " ");
              if (updatedType === "assistant") {
                updatedType = "Surgical Assistant";
              }
              return { ...item, type: updatedType };
            });
          const totalUsers = filteredData.reduce(
            (acc, item) => acc + item.count,
            0
          );
          const totalAdmin =
            filteredData.find((item) => item.type === "admin")?.count || 0;
          setTotalUsers(totalUsers);
          setTotalAdmin(totalAdmin);
          setData(filteredData);
        }
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const labels = data.map((item) => item.type.toUpperCase() || "Unknown");
  const labelData = data.map((item) => item.count);

  const pieData = {
    labels,
    datasets: [
      {
        label: "User Type",
        data: labelData,
        backgroundColor: backgroundColor.slice(0, data.length),
        borderColor: backgroundColor
          .slice(0, data.length)
          .map((color) => color.replace("0.7", "1")),
        borderWidth: 1,
      },
    ],
  };
  const customChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    animation: {
      animateRotate: true,
      animateScale: true,
    },
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 10,
          padding: 20,
          color: "#000",
          font: {
            size: 12,
            family: "Poppins",
            weight: "500",
          },
        },
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            const label = pieData.labels[context.dataIndex] || "";
            const count = pieData.datasets[0].data[context.dataIndex] || 0;
            return `${label}: ${count}`;
          },
        },
      },
    },
  };

  return (
    <div className="chart-card">
      <div
        className="flex center-center justify-between flex-row of-hid"
        style={{
          padding: "16px",
          gap: "16px",
        }}
      >
        <p className="f-w-700"> User Types</p>
      </div>
      <div style={{ height: "300px" }}>
        {loading ? (
          <div className="flex center-center" style={{ height: "100%" }}>
            <LoaderIcon style={{ width: "30px", height: "30px" }} />
          </div>
        ) : data.length > 0 ? (
          <PieChart data={pieData} options={customChartOptions} />
        ) : (
          <PieChart data={emptyPieData} options={chartOptions} />
        )}
      </div>
    </div>
  );
};

export default UserTypePi;
