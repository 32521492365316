import React, { useState, useEffect } from "react";
import Select from "react-select";

const Caution = ({ title }) => {
  return (
    <p
      className="t-a-c"
      style={{
        fontSize: "12px",
        padding: "5px",
        color: "#777",
      }}
    >
      {title}
    </p>
  );
};

const CustomSelect = ({
  options = [],
  placeHolder = "Search options...",
  selectedValue = null,
  onChange,
  setTouched = () => {},
  error = false,
  disabled = false,
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedOption, setSelectedOption] = useState(selectedValue || null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSelectedOption(selectedValue || null);
  }, [selectedValue]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [options, searchQuery]);

  const handleInputChange = (query) => {
    setSearchQuery(query);
  };

  const handleChange = (option) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
    setTouched(true);
  };

  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 999999999999,
    }),
    control: (base, { isFocused }) => ({
      ...base,
      borderColor: "#ccc",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#283f54",
      },
      ...(isFocused && {
        borderColor: "#283f54",
        boxShadow: "none",
      }),
    }),
    option: (base, { isSelected }) => ({
      ...base,
      borderBottom: "1px solid #f0f0f0",
      padding: "5px 12px",
      fontSize: "12px",
      color: "#283f54",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
      ...(isSelected && {
        backgroundColor: "#e0e0e0",
      }),
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "200px",
      width: "100%",
      overflowY: "auto",
      wordBreak: "break-word",
      border: "1px solid #ccc",
      borderRadius: "4px",
      backgroundColor: "white",
      zIndex: 999999999999,
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#aaa",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "#888",
        },
      },
    }),
    input: (base) => ({
      ...base,
      color: "inherit",
      fontSize: "12px",
      minWidth: "140px",
      "&:hover": {
        color: "inherit",
      },
    }),
  };

  return (
    <div className="dropdown-container pos-rel">
      <Select
        isDisabled={disabled}
        isClearable
        options={filteredOptions}
        value={selectedOption}
        onInputChange={handleInputChange}
        onChange={handleChange}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        placeholder={placeHolder}
        menuIsOpen={isOpen}
        styles={customStyles}
        classNamePrefix={error ? "error" : ""}
        menuPortalTarget={document.body} // Render the dropdown menu outside the current DOM
        menuPosition="fixed" // Ensure the menu is positioned correctly
      />
    </div>
  );
};

export default CustomSelect;
