import { useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "./ButtonTextIcon";
import { useEffect, useState } from "react";
import LoaderIcon from "./LoaderIcon";
import { handleApiCall } from "../../utilities/helpers";
import PageToolbar from "../common/PageToolbar";
import { TbArrowBackUp, TbDownload, TbXboxX, TbXboxXFilled } from "react-icons/tb";

const DocView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state || {};
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const encodedUrl = queryParams.get("fileUrl") || "";
  const encodedtitle = queryParams.get("title") || "";
  const title = decodeURIComponent(encodedtitle);
  const fileUrl = decodeURIComponent(encodedUrl);
  const nextUrl = decodeURIComponent(queryParams.get("next")) || null;
  const [error, setError] = useState(null);
  const [isPdf, setIsPdf] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (!fileUrl) {
      navigate(-1);
      return;
    }
  }, [nextUrl]);

  const handleCancel = () => {
    if (nextUrl) {
      navigate(nextUrl, { state: { ...state } });
    } else {
      navigate(-1);
    }
    queryParams.delete("fileUrl");
    queryParams.delete("next");
  };

  useEffect(() => {
    if (!fileUrl) {
      navigate(-1);
      setLoading(false);
      return;
    }
    const fetchAndRenderPdf = async () => {
      try {
        setLoading(true);
        const response = await fetch(fileUrl, {
          method: "GET",
        });
        if (response.status === 401 || response.status === 403) {
          if (response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          } else if (response.status === 403) {
            setError(
              "You do not have permission to view this file. Please contact your administrator."
            );
          }
          return false;
        }

        if (!response.ok) {
          throw new Error("Failed to fetch PDF");
        }

        const pdfData = await response.arrayBuffer();
        const pdf = await window.pdfjsLib.getDocument({ data: pdfData })
          .promise;
        setNumPages(pdf.numPages);

        const newPages = [];
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          const page = await pdf.getPage(pageNumber);
          const viewport = page.getViewport({ scale: 1 });

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Apply styling for 100% width
          canvas.style.width = "100%";
          canvas.style.height = "auto";

          await page.render({
            canvasContext: context,
            viewport: viewport,
          }).promise;
          newPages.push(canvas);
        }

        setPages(newPages);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    setLoading(true);
    const urlObj = new URL(fileUrl);

    if (urlObj.pathname.toLowerCase().endsWith(".pdf")) {
      setIsPdf(true);
      fetchAndRenderPdf();
    } else {
      setIsPdf(false);
      setLoading(false);
    }

    return () => {
      setLoading(true);
      setError(null);
      setPages([]);
    };
  }, [fileUrl]);

  return (
    <section id="add-physician-assistant">
      <PageToolbar
        title={"Document Viewer"}
        button={
          <div
            style={{
              display: "flex",
              gap: "10px",
              margin: "0 10px",
            }}
          >
            <ButtonTextIcon
              type="button"
              title="Download Document"
              disabled={!(!loading && !error)}
              onClick={() => {
                if (fileUrl) {
                  const link = document.createElement("a");
                  link.href = fileUrl;
                  link.download = title || "document"; // Fallback to 'document' if title is not available
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }}
              className="mar-l-a"
              icon={<TbDownload />}
            />
            <ButtonTextIcon
              title="Cancel"
              className="light mar-l-a"
              onClick={handleCancel}
              icon={<TbArrowBackUp />}
            />
          </div>
        }
      />
      <div className="form-holder">
        <div
          className="inner"
          style={{
            overflowY: "auto",
            maxHeight: "800px",
            padding: "20px",
            boxShadow: "0 0 40px rgba(0,0,0,0.13)",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
              paddingBottom: "10px",
              borderBottom: "solid 1px rgb(93, 93, 93)",
            }}
          >
            <h6 className="color-primary f-w-700 t-t-u l-s-n">Document</h6>
            <div
              style={{
                maxWidth: "200px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "inline-block",
              }}
            >
              {title?.toUpperCase() || ""}
            </div>
          </div>
          <div
            style={{
              overflowY: "auto",
              maxHeight: "600px",
              minHeight: "500px",
              scrollbarWidth: "thin",
              borderRadius: "10px",
              padding: "10px",
              backgroundColor: "#fff",
            }}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  margin: "100px 0",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoaderIcon style={{ width: "20px", height: "20px" }} />
              </div>
            ) : error ? (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Sorry, we couldn't load the PDF: {error}
              </p>
            ) : isPdf ? (
              pages.map((canvas, index) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                  <canvas ref={(node) => node && node.replaceWith(canvas)} />
                </div>
              ))
            ) : (
              !loading &&
              !error &&
              !isPdf && (
                <img
                  src={fileUrl}
                  alt="Sorry, we couldn't load the image"
                  style={{ width: "100%" }}
                />
              )
            )}
          </div>

          <div
            style={{
              margin: "20px 0",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ButtonTextIcon
              title="Cancel"
              className="light "
              onClick={handleCancel}
              icon={<TbArrowBackUp />}
            />
            <ButtonTextIcon
              type="button"
              title="Download Document"
              onClick={() => {
                if (fileUrl) {
                  const link = document.createElement("a");
                  link.href = fileUrl;
                  link.download = title || "document"; // Fallback to 'document' if title is not available
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }}
              disabled={!(!loading && !error)}
              icon={<TbDownload />}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DocView;
