import { AiFillInfoCircle } from "react-icons/ai";
import Pagination from "../../components/common/Pagination";
import useQueryParams from "../../hooks/useQueryParams";
import { MdAutorenew, MdClearAll, MdDoneAll } from "react-icons/md";

const TaskStats = ({ stats, taskList }) => {
  const { updateQueryParam } = useQueryParams();
	return (
    <>
      {taskList && taskList?.list?.length !== 0 && (
        <div
          className="flex scan-flow-stats"
          style={{
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p
              className="f-w-300"
              style={{
                fontSize: "11px",
                whiteSpace: "nowrap",
              }}
            >
              Page no:
            </p>
            <Pagination
              totalRecords={taskList?.totalRecords}
              pageNo={taskList?.pageNo}
              pageSize={taskList?.pageSize}
              removeParamKey={"id"}
            />
          </div>
          <div
            style={{
              padding: "10px 10px",
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
          >
            <ul className="info flex" style={{ gap: "4px" }}>
              <li
                className="l-s-n flex center-center b-r-20 cursor-pointer"
                style={{
                  background: "#56baec",
                  padding: "10px 16px",
                  gap: "4px",
                }}
                onClick={() => {
                  updateQueryParam("id");
                  updateQueryParam("pageNo",0);
                  updateQueryParam("isCompleted", "all");
                }}
              >
                <MdClearAll color="white" />
                <p className="l-h-1 color-white f-w-600">
                  {stats?.total || 0}
                </p>
                <p
                  className="l-h-1 color-white f-w-300"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  All
                </p>
               
              </li>
              <li
                className="l-s-n flex center-center b-r-20 cursor-pointer"
                style={{
                  background: "#E65464",
                  padding: "10px 16px",
                  gap: "4px",
                }}
                onClick={() => {
                  updateQueryParam("id");
                  updateQueryParam("pageNo",0);
                  updateQueryParam("isCompleted", "incomplete");
                }}
              >
                <MdAutorenew color="white" />
                <p className="l-h-1 color-white f-w-600">
                  {stats?.incomplete || 0}
                </p>
                <p
                  className="l-h-1 color-white f-w-300"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Incomplete
                </p>
                
              </li>
              <li
                className="l-s-n flex center-center b-r-20 cursor-pointer"
                style={{
                  background: "#27ae60",
                  padding: "10px 16px",
                  gap: "4px",
                }}
                onClick={() => {
                  updateQueryParam("id");
                  updateQueryParam("pageNo",0);
                  updateQueryParam("isCompleted", "completed");
                }}
              >
                <MdDoneAll color="white" />
                <p className="l-h-1 color-white f-w-600">
                  {stats?.completed || 0}
                </p>
                <p
                  className="l-h-1 color-white f-w-300"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Completed
                </p>
                
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskStats;
