export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: "40%",
  animation: {
    animateRotate: true,
    animateScale: true,
  },
  layout: {
    padding: 10,
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
      },
    },
    tooltip: {
      enabled: true,
    },
  },
};

export const backgroundColor = [
  "rgba(255, 99, 132, 0.7)", // Red
  "rgba(54, 162, 235, 0.7)", // Blue
  "rgba(153, 102, 255, 0.7)", // Purple
  "rgba(255, 159, 64, 0.7)", // Orange
  "rgba(83, 102, 255, 0.7)", // Indigo
  "rgba(40, 159, 64, 0.7)", // Green
  "rgba(255, 206, 86, 0.7)", // Yellow
  "rgba(75, 192, 192, 0.7)", // Teal
  "rgba(255, 99, 71, 0.7)", // Tomato
  "rgba(0, 128, 128, 0.7)", // Teal
  "rgba(220, 20, 60, 0.7)", // Crimson
  "rgba(255, 140, 0, 0.7)", // Dark Orange
  "rgba(75, 0, 130, 0.7)", // Indigo
  "rgba(0, 191, 255, 0.7)", // Deep Sky Blue
  "rgba(34, 139, 34, 0.7)", // Forest Green
  "rgba(128, 0, 128, 0.7)", // Purple (for "Other")
  "rgba(199, 199, 199, 0.7)", // Gray
];

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * backgroundColor.length);
  return backgroundColor[randomIndex];
};

export const emptyPieData = {
  labels: ["No Data Available"],
  datasets: [
    {
      label: "Assistant Usage",
      data: [1],
      backgroundColor: [" #e0e0e0"],
      borderColor: [" #e0e0e0"],
      borderWidth: 1,
    },
  ],
};
export const emptyArcData = {
  total: 1,
  divisions: [1],
  colors: [" #e0e0e0"],
  labels: ["No Data Available"],
};

export const emptyBarData = {
  labels: ["No Data Available"],
  datasets: [
    {
      label: "No Data Available",
      data: [],
      backgroundColor: [" #e0e0e0"],
      borderColor: [" #e0e0e0"],
      borderWidth: 1,
    },
  ],
};
export const emptyLineData = {
  labels: [],
  datasets: [
    {
      label: "No Data Available",
      data: [],
      backgroundColor: "#e0e0e0",
      borderColor: "#e0e0e0",
      borderWidth: 1,
    },
  ],
};
