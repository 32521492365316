import {
	convertGMTToLocal,
	formatTime,
	getScanflowStatusInfo,
	houstonDateTime,
	isManager,
} from "../../utilities/helpers";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useEffect, useRef, useState } from "react";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { useUI } from "../../context/ui";
import { useScanflow } from "./scanflow-context";
import ScanflowForm from "./ScanflowForm";
import CustomModal from "../../components/common/CustomModal";
import ScanFlowImages from "./ScanFlowImages";
import Label from "../../components/ui/Label";
import AssigneeFormModal from "./AssignFormModle";
import ScanFlowHistory from "./ScanFlowHistory";
import DocumentForm from "./DocumentForm";
import PopUpModal from "../../components/common/PopUpModal";
import LoaderIcon from "../../components/ui/LoaderIcon";
import useQueryParams from "../../hooks/useQueryParams";
import { TbArrowBackUp } from "react-icons/tb";
import { BiTime } from "react-icons/bi";

const AssigneeDetails = ({ data }) => {
	return (
		<div
			style={{
				padding: "20px",
				borderBottom: "solid 1px #d8d8d8",
			}}>
			<Label title='Assignee' />
			<p
				className='color-primary f-w-300 l-h-1'
				style={{ fontSize: "12px", paddingBottom: "6px" }}>
				{data?.assignee?.name || "-"} {data?.assignee?.lastName}
			</p>
		</div>
	);
};

const Timer = ({ task }) => {
	const [elapsedTime, setElapsedTime] = useState(0);
	const [isTimerRunning, setIsTimerRunning] = useState(false);
	useEffect(() => {
		let timerInterval = null;
		if (isTimerRunning) {
			timerInterval = setInterval(() => {
				setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
			}, 1000);
		}
		return () => clearInterval(timerInterval);
	}, [isTimerRunning]);

	useEffect(() => {
		setElapsedTime(0);
		if (task?.statusId === 2) {
			const intervals = task?.intervals
				? JSON.parse(task?.intervals)
				: "";
			if (intervals && intervals.length > 0) {
				const savedStartTime =
					intervals[intervals.length - 1].startTime;
				if (savedStartTime) {
					const defaultDurationInSeconds = task?.timeSpent || 0;
					const currentTime = new Date(houstonDateTime());
					const startTime = new Date(
						savedStartTime.replace(" ", "T")
					);
					if (!isNaN(startTime) && !isNaN(currentTime)) {
						const elapsedSeconds = Math.floor(
							(currentTime - startTime) / 1000
						);
						const newRemainingTime =
							defaultDurationInSeconds + elapsedSeconds;
						setElapsedTime(
							newRemainingTime > 0 ? newRemainingTime : 0
						);
					} else {
						console.error(
							"Invalid Date object(s): Check savedStartTime or currentTime."
						);
					}
				}
			}
			setIsTimerRunning(true);
		} else {
			setElapsedTime(task?.timeSpent ? task?.timeSpent : 0);
			setIsTimerRunning(false);
		}
	}, [task]);

	return (
		<h4 className='l-h-1-2 f-w-900 t-a-r' style={{ color: "#7f8c8d" }}>
			{formatTime(elapsedTime)}
		</h4>
	);
};

const TaskDetails = ({ CloseTaskdetails }) => {
	const { updateQueryParam } = useQueryParams();
	const { task, taskLoading } = useScanflow();
	const {
		profile: { id: userId, type: role },
	} = useUI();
	const [viewFileUpload, setViewFileUpload] = useState("");
	return (
		<section id='scanflow-details'>
			<section className='content flex'>
				{taskLoading && (
					<div
						className='col-1-1 flex center-center'
						style={{
							height: "100vh",
						}}>
						<LoaderIcon
							style={{
								width: "20px",
								height: "20px",
							}}
						/>
					</div>
				)}

				{!taskLoading && task && (
					<>
						<div className='preview-area'>
							<div
								style={{
									margin: "20px",
									display: "inline-block",
								}}>
								<ButtonTextIcon
									title='Return Back'
									onClick={() => {
										CloseTaskdetails();
										updateQueryParam("id", null);
									}}
									icon={<TbArrowBackUp />}
								/>
							</div>
							<div className='wrap'>
								<div>
									<div
										style={{
											padding: "10px",
											overflowX: "hidden",
											borderBottom: "solid 1px #f0f0f0",
										}}>
										<div>
											<h5
												className='l-h-1-2 f-w-700'
												style={{ color: "#2c3e50" }}>
												Document
											</h5>
											<p
												className='l-h-1 f-w-300'
												style={{ color: "#8f8f8f" }}>
												(All Files)
											</p>
										</div>
									</div>
									{task?.patientActivity?.patientActivity
										?.files?.length > 0 && (
										<ScanFlowImages
											p={
												task?.patientActivity
													?.patientActivity
													?.patient || null
											}
											files={
												task?.patientActivity
													?.patientActivity?.files
											}
											handleAddMore={() => {
												setViewFileUpload("Handle");
											}}
										/>
									)}
									{viewFileUpload && (
										<PopUpModal>
											<DocumentForm
												setShow={setViewFileUpload}
												id={
													task?.patientActivity
														?.patientActivity
														?.patientEventId
												}
												files={
													task?.patientActivity
														?.patientActivity?.files
												}
											/>
										</PopUpModal>
									)}
								</div>
							</div>
						</div>
						<div className='edit-areas'>
							<div className='scanflow-details-right-content'>
								<div>
									{isManager(role) &&
										[1].includes(task?.statusId) && (
											<div
												style={{
													padding: "20px",
													borderBottom:
														"solid 1px #d8d8d8",
												}}>
												{!(
													task?.invoiceStatus === 2 ||
													task?.invoiceStatus === 4
												) && (
													<div
														style={{
															color: "red",
															fontSize: "8px",
														}}>
														<p
															style={{
																fontWeight:
																	"bold",
															}}>
															Disclaimer:
														</p>
														<p>
															{" "}
															This scan flow task
															cannot be edited as
															the invoice related
															to this is either
															sent to the facility
															or already been
															settled.
														</p>
													</div>
												)}
											</div>
										)}
								</div>
								<div style={{ padding: "12px 16px" }}>
									<div
										className='col-1-1 flex center-left'
										style={{
											paddingBottom: "12px",
											borderBottom: "solid 1px #f0f0f0",
											marginBottom: "16px",
										}}>
										<h6 className='color-primary f-w-600'>
											Update
										</h6>
										<div className='mar-l-a'>
											<CustomModal
												selector={
													<ButtonLightTextIcon
														title='Scanflow history'
														icon={
															<BiTime />
														}
													/>
												}
												content={
													<ScanFlowHistory
														id={
															task
																?.patientActivity
																?.patientActivity
																?.id
														}
													/>
												}
											/>
										</div>
									</div>
									<ScanflowForm
										CloseTaskdetails={CloseTaskdetails}
									/>
								</div>
							</div>
						</div>
					</>
				)}

				{!taskLoading && !task && (
					<div
						style={{
							height: "100vh",
							width: "100%",
						}}>
						<div
							style={{ margin: "20px", display: "inline-block" }}>
							<ButtonTextIcon
								title='Return Back'
								onClick={() => {
									CloseTaskdetails();
									updateQueryParam("id", null);
								}}
								icon={<TbArrowBackUp />}
							/>
						</div>
						<div
							className='flex center-center'
							style={{
								height: "100%",
								width: "100%",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<p>No Data Found</p>
						</div>
					</div>
				)}
			</section>
		</section>
	);
};

export default TaskDetails;
