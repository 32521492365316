import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const HalfCircleChart = ({ title_heading, title_count, divisions, colors, labels }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: divisions,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
        circumference: 270, // Half circle
        rotation: 227, // Start from the top
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    animation: {
      animateRotate: true,
      animateScale: true,
    },
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <Doughnut data={data} options={options} />
      <div
        style={{
          position: 'absolute',
          top: '65%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <p style={{ margin: 0, fontSize: '24px', fontWeight: 'bold' }}>{title_count}</p>
        <p style={{ margin: 0, fontSize: '14px', color: '#666' }}>{title_heading}</p>
      </div>
    </div>
  );
};

export default HalfCircleChart;