import { useLocation, useNavigate } from 'react-router-dom';

 
const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  
  const updateQueryParam = (key, value) => {
    if (value) {
      queryParams.set(key, value);   
    } else {
      queryParams.delete(key);  
    }
    navigate({ search: queryParams.toString() });
  };

  const resetQueryParams = () => {
    const resetParams = new URLSearchParams();
    resetParams.set('key', null); 
    navigate({ search: resetParams.toString() });
  };

  return { updateQueryParam, resetQueryParams, queryParams, location };
};

export default useQueryParams;
