import { useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { NavLink } from "react-router-dom";
import {
  formatServerDate,
  getRandomColor,
  getUserTypeInfo,
  handleApiCall,
} from "../../utilities/helpers";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import DefaultUserImage from "../../images/icons/default.png";
import { useUI } from "../../context/ui";
import InfoItem from "../../components/ui/InfoItem";
import { TbArrowBackUp, TbCalendarShare } from "react-icons/tb";
import { FiEdit } from "react-icons/fi";
import { MdAlternateEmail, MdOutlineSpeakerNotes } from "react-icons/md";
import { SiCodesignal } from "react-icons/si";
import { LuBadgeInfo, LuCombine, LuMessageCircleCode } from "react-icons/lu";

const UserDetails = ({ id, showedit, setShow }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { permissions } = useUI();

  const fetchUserData = async (id) => {
    try {
      const response = await handleApiCall(API_URL + `/api/1.0.0/users/${id}`, {
        method: "GET",
      });
      if (response.responseStatus === true) {
        setUserData(response.data);
      } else {
        setError("Failed to fetch activity data.");
      }
      setLoading(false);
    } catch (e) {
      setError("Failed to fetch activity data.");
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    fetchUserData(id);
  }, [id]);

  return (
    <div id="activity-detail-box">
      <div className="flex center-left">
        <div>
          <h6 className="color-primary f-w-300">User Details</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ButtonLightTextIcon
            title="Cancel"
            onClick={() => {
              setShow(false);
            }}
            icon={<TbArrowBackUp />}
          />
        </div>
      </div>
      <div
        style={{
          height: "60vh",
          margin: "10px",
          overflowY: "scroll",
          padding: "20px 5px",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
      >
        {loading && (
          <div
            className="col-1-1"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderIcon
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
        )}
        {!loading && error && (
          <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
            {error}
          </p>
        )}
        {userData && !loading && !error && (
          <div className="activity-card pos-rel">
            <div
              style={{
                position: "absolute",
                content: "",
                height: "4px",
                left: "-1px",
                right: "-1px",
                bottom: "-2px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            ></div>
            <div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "20px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <img
                      src={userData?.userDAO?.imageS3Url || DefaultUserImage}
                      alt={userData?.userDAO.name}
                      style={{
                        width: "55px",
                        height: "55px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  }
                  label={"Name"}
                  name={
                    userData?.userDAO?.name +
                      " " +
                      userData?.userDAO?.lastName || "NA"
                  }
                />
              </div>
            </div>
            <div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<MdAlternateEmail color={getRandomColor()} size={20} />}
                  label={"Email"}
                  name={userData?.userDAO?.email || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<SiCodesignal color={getRandomColor()} size={20} />}
                  label={"User Type"}
                  name={getUserTypeInfo(userData?.userDAO?.type)?.label || "--"}
                />
              </div>
            </div>
            <div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbCalendarShare color={getRandomColor()} size={20} />}
                  label={"Active Date"}
                  name={
                    userData?.userDAO?.activeStart
                      ? formatServerDate(userData.userDAO.activeStart)
                      : "NA"
                  }
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbCalendarShare color={getRandomColor()} size={20} />}
                  label={"Active End"}
                  name={
                    userData?.userDAO?.activeEnd
                      ? formatServerDate(userData.userDAO.activeEnd)
                      : "NA"
                  }
                />
              </div>
            </div>
            <div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <LuMessageCircleCode color={getRandomColor()} size={20} />
                  }
                  label={"status"}
                  name={
                    userData?.userDAO?.isActive === "1" ? "Active" : "Inactive"
                  }
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineSpeakerNotes color={getRandomColor()} size={20} />
                  }
                  label={"notes"}
                  name={userData?.userDAO?.notes || "NA"}
                />
              </div>
            </div>
            <div></div>
            <div className="col-1-1 info-row-column">
              <div
                className="flex center-left"
                style={{
                  gap: "10px",
                  alignItems: "flex-start",
                }}
              >
                <div>
                  <LuBadgeInfo color={getRandomColor()} size={20} />
                </div>
                <div
                  style={{
                    overflow: "hidden",
                  }}
                >
                  <p
                    className="f-w-300"
                    style={{
                      color: "#aaaaaa",
                      fontSize: "10px",
                      lineHeight: 1,
                      paddingBottom: "2px",
                    }}
                  >
                    Practice
                  </p>
                  <p
                    style={{
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  >
                    {userData?.practice && userData?.practice.length > 0
                      ? userData?.practice.map((Practice) => (
                          <li
                            style={{
                              listStyle: "none",
                              marginRight: "4px",
                              marginBottom: "4px",
                              borderRadius: "2px",
                              lineHeight: "32px",
                              padding: "10px 12px",
                              verticalAlign: "middle",
                            }}
                            className=" bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                          >
                            <div className="flex center-left">
                              <p
                                className="of-hid"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "108px",
                                  textOverflow: "ellipsis",
                                  fontSize: "12px",
                                }}
                              >
                                {Practice.payToName}
                              </p>
                            </div>
                          </li>
                        ))
                      : "NA"}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="col-1-1 info-row-column">
                <div
                  className="flex center-left"
                  style={{
                    gap: "10px",
                    alignItems: "flex-start",
                  }}
                >
                  <div>
                    <LuCombine color={getRandomColor()} size={20} />
                  </div>
                  <div
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    <p
                      className="f-w-300"
                      style={{
                        color: "#aaaaaa",
                        fontSize: "10px",
                        lineHeight: 1,
                        paddingBottom: "2px",
                      }}
                    >
                      Groups
                    </p>
                    <p
                      style={{
                        whiteSpace: "normal",
                        overflowWrap: "break-word",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      {userData?.groups && userData?.groups.length > 0
                        ? userData?.groups.map((groups) => (
                            <li
                              style={{
                                listStyle: "none",
                                marginRight: "4px",
                                marginBottom: "4px",
                                borderRadius: "2px",
                                lineHeight: "32px",
                                padding: "10px 12px",
                                verticalAlign: "middle",
                              }}
                              className=" bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                            >
                              <div className="flex center-left">
                                <p
                                  className="of-hid"
                                  style={{
                                    whiteSpace: "nowrap",
                                    width: "108px",
                                    textOverflow: "ellipsis",
                                    fontSize: "12px",
                                  }}
                                >
                                  {groups.groupName}
                                </p>
                              </div>
                            </li>
                          ))
                        : "NA"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        {showedit && (
          <NavLink
            to={
              userData?.userDAO?.id
                ? `/edit-user?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                : "#"
            }
            state={{
              id: userData?.userDAO?.id,
            }}
          >
            <ButtonLightTextIcon
              title="Edit Details"
              icon={<FiEdit />}
              onClick={() => {
                setShow(false);
              }}
            />
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
