import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  formatServerDate,
  getPatientName,
  getRandomColor,
  getUserTypeInfo,
  handleApiCall,
} from "../../utilities/helpers";
import { useEffect, useState } from "react";
import CustomModal from "../../components/common/CustomModal";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import PopUpModal from "../../components/common/PopUpModal";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { API_URL } from "../../utilities/constants";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useUI } from "../../context/ui";
import FullScreenFileViewerModal from "../../components/ui/FullScreenFileViewerModal";
import InfoItem from "../../components/ui/InfoItem";
import useQueryParams from "../../hooks/useQueryParams";
import {
  TbAlertCircle,
  TbArrowBackUp,
  TbBellPlusFilled,
  TbCalendarShare,
  TbEye,
  TbFileDescription,
  TbFlagFilled,
  TbHospitalCircle,
  TbMap2,
  TbMoneybag,
  TbPaywall,
  TbPencilMinus,
  TbTimelineEvent,
  TbUserCircle,
  TbUserPlus,
  TbUserShield,
} from "react-icons/tb";
import {
  MdAutorenew,
  MdDeleteSweep,
  MdDoneAll,
  MdOutlineAccountBalance,
  MdOutlineDeleteSweep,
} from "react-icons/md";
import { PiDotsThreeCircleVerticalLight } from "react-icons/pi";
import { FiEdit, FiFlag } from "react-icons/fi";
import { CiCircleChevRight, CiHospital1 } from "react-icons/ci";
import { FaRegFileLines, FaUserDoctor } from "react-icons/fa6";
import { GiDoctorFace } from "react-icons/gi";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { LuBadgeInfo, LuEye, LuMapPin } from "react-icons/lu";
import { BiTime } from "react-icons/bi";

const getRespectiveBorderColor = (id) => {
  switch (id) {
    case 1:
      return "#F08D3B";
    case 2:
      return "#E65464";
    case 3:
      return "#56BAEC";
    case 4:
      return "#8e44ad";
    case 5:
      return "#27ae60";
    default:
      return "#f0f0f0";
  }
};

const StatusIcon = ({ id }) => {
  switch (id) {
    case "incomplete":
      return (
        <div className="flex center-left" style={{ gap: "6px" }}>
          <MdAutorenew color={getRespectiveBorderColor(1)} />
          <p
            className="f-w-300"
            style={{
              fontSize: "12px",
              color: getRespectiveBorderColor(1),
            }}
          >
            Ongoing
          </p>
        </div>
      );
    case "complete":
      return (
        <div className="flex center-left" style={{ gap: "6px" }}>
          <MdDoneAll color={getRespectiveBorderColor(5)} />
          <p
            className="f-w-300"
            style={{
              fontSize: "12px",
              color: getRespectiveBorderColor(5),
            }}
          >
            Completed
          </p>
        </div>
      );
    default:
      return <></>;
  }
};

const getInitials = (name) => {
  if (name) {
    // Replace multiple spaces with a single space and trim the string
    const cleanedName = name.replace(/\s+/g, " ").trim();
    const nameParts = cleanedName.split(" ");
    const firstNameInitial = nameParts[0][0].toUpperCase();
    const lastNameInitial = nameParts[1] ? nameParts[1][0].toUpperCase() : "";
    return firstNameInitial + lastNameInitial;
  } else {
    return "-";
  }
};

const getRandomWidth = (startDate, endDate) => {
  if (startDate && endDate) {
    return "100%";
  } else {
    return "50%";
  }
};

const ActivityCard = ({ data = null, autoShow }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { addToastMessage } = useUI();
  const { updateQueryParam, queryParams } = useQueryParams();
  const [isOpen, setIsOpen] = useState(false);
  const [viewActivity, setViewActivity] = useState("");
  const { permissions } = useUI();

  const status = queryParams.get("status") || "incomplete";
  const statusId = queryParams.get("status") === "incomplete" ? 1 : 5;

  return (
    <div className="activity-card pos-rel">
      <div
        style={{
          position: "absolute",
          content: "",
          height: "4px",
          left: "-1px",
          right: "-1px",
          bottom: "-2px",
          background: getRespectiveBorderColor(statusId),
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
      ></div>
      <div
        className="col-1-1 flex center-left"
        style={{ gap: "10px", paddingBottom: "5px" }}
      >
        <h5 className="f-w-700">
          <span className="f-w-300">#</span>&nbsp;
          {data.id}
        </h5>
        <StatusIcon id={status} />

        <div style={{ marginLeft: "auto" }}>
          <div className="menu-container" style={{ position: "relative" }}>
            <PiDotsThreeCircleVerticalLight
              size={24}
              onClick={() => setIsOpen(!isOpen)}
            />

            {isOpen && (
              <ul>
                {data.invoiceSentStatus === 2 ||
                data.invoiceSentStatus === 4 ? (
                  <li>
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        padding: "0 8px",
                      }}
                    >
                      Actions are not allowed for this activity.
                    </p>
                  </li>
                ) : (
                  <>
                    {permissions?.isUpdateAllowed && (
                      <li>
                        <NavLink
                          to={`/edit-activity?next=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                          state={{ id: data.id }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              gap: "4px",
                              padding: "0 8px",
                            }}
                          >
                            <FiEdit size={"14px"} />
                            <p
                              style={{
                                fontSize: "11px",
                                lineHeight: "28px",
                              }}
                            >
                              Edit
                            </p>
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {permissions?.isDeleteAllowed && (
                      <CustomModal
                        selector={
                          <li>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: "4px",
                                padding: "0 8px",
                              }}
                            >
                              <MdOutlineDeleteSweep />
                              <p
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "28px",
                                }}
                              >
                                Delete
                              </p>
                            </div>
                          </li>
                        }
                        content={
                          <ConfirmationModal
                            onConfirm={async () => {
                              try {
                                const response = await handleApiCall(
                                  API_URL + `/api/1.0.0/activity/${data.id}`,
                                  {
                                    method: "PUT",
                                  }
                                );
                                if (response.responseStatus === true) {
                                  addToastMessage({
                                    status: true,
                                    message: `Activity deleted successfully.`,
                                  });
                                  queryParams.set(
                                    "r",
                                    Math.floor(10000 + Math.random() * 90000)
                                  );
                                  navigate({
                                    search: queryParams.toString(),
                                  });
                                } else {
                                  addToastMessage({
                                    status: false,
                                    message: `Something went wrong while deleting activity. Please try again.`,
                                  });
                                }
                              } catch (e) {
                                console.log(e);
                                addToastMessage({
                                  status: false,
                                  message: `Something went wrong while deleting activity. Please try again.`,
                                });
                              }
                            }}
                            message="Are you sure you want to delete this activity?"
                          />
                        }
                      />
                    )}
                  </>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "12px 0 12px 0 ",
        }}
      >
        <div
          className="info-row flex center-center"
          style={{
            gap: "10px",
            paddingBottom: "10px",
          }}
        >
          <InfoItem
            className="col-1-1 info-row-column"
            variant="card"
            icon={<GiDoctorFace color={getRandomColor()} size={20} />}
            label={"Assistant"}
            name={data?.assistant ? `${data?.assistant}` : "-"}
          />
          <InfoItem
            className="col-1-1 info-row-column"
            variant="card"
            icon={<CiHospital1 color={getRandomColor()} size={20} />}
            label={"Facility"}
            name={data?.facility || "-"}
          />
        </div>
        <div
          className="info-row flex center-center"
          style={{
            gap: "10px",
            paddingBottom: "10px",
          }}
        >
          <InfoItem
            className="col-1-1 info-row-column"
            variant="card"
            icon={<FaUserDoctor color={getRandomColor()} size={20} />}
            label={"Surgeon"}
            name={data?.surgeon ? `${data?.surgeon}` : "-"}
          />
          <InfoItem
            className="col-1-1 info-row-column"
            variant="card"
            icon={<TbTimelineEvent color={getRandomColor()} size={20} />}
            label={"Event Type"}
            name={data?.eventType || "-"}
          />
        </div>
        <div
          className="info-row flex center-center"
          style={{
            gap: "10px",
            paddingBottom: "10px",
          }}
        >
          <InfoItem
            className="col-1-1 info-row-column"
            variant="card"
            icon={<TbUserCircle color={getRandomColor()} size={20} />}
            label={"Created By"}
            name={data?.createdBy ? `${data?.createdBy}` : "-"}
          />
          <InfoItem
            className="col-1-1 info-row-column"
            variant="card"
            icon={<TbUserCircle color={getRandomColor()} size={20} />}
            label={"Modified By"}
            name={data?.modifiedBy ? `${data?.modifiedBy}` : "-"}
          />
        </div>
      </div>
      <div style={{ paddingBottom: "10px" }}>
        <div>
          <div className="col-1-1 flex center-left">
            <p
              className="color-faded f-w-300 l-h-1"
              style={{ fontSize: "9px" }}
            >
              {data?.startDate ? formatServerDate(data?.startDate) : "-"}{" "}
              {data?.startTime || ""}
            </p>
            <p
              className="color-faded f-w-300 l-h-1 mar-l-a"
              style={{ fontSize: "9px" }}
            >
              {data?.endDate ? formatServerDate(data?.endDate) : "-"}{" "}
              {data?.endTime || ""}
            </p>
          </div>
        </div>
        <div
          className="flex center-left"
          style={{
            padding: "5px 0",
          }}
        >
          <div
            className="b-r-100"
            style={{
              height: "12px",
              width: "12px",
              background: "white",
              border: "solid 3px",
              borderColor: "#283f54",
            }}
          ></div>
          <div
            className="pos-rel"
            style={{
              flex: 1,
              height: "2px",
              background: "#f0f0f0",
            }}
          >
            <div
              style={{
                position: "absolute",
                content: "''",
                height: "2px",
                left: "0",
                top: 0,
                background: getRespectiveBorderColor(statusId),
                width: getRandomWidth(data?.startDate, data?.endDate),
              }}
            ></div>
          </div>
          <div
            className="b-r-100"
            style={{
              height: "12px",
              width: "12px",
              background: "white",
              border: "solid 3px",
              borderColor: getRespectiveBorderColor(statusId),
            }}
          ></div>
        </div>
      </div>
      <div className="col-1-1 flex center-left" style={{ gap: "10px" }}>
        <div
          className="b-r-100 flex center-center"
          style={{
            height: "28px",
            width: "28px",
            background: getRandomColor(),
            border: "solid 1px rgba(0,0,0,0.08)",
            fontSize: "12px",
            fontWeight: "300",
            color: "white",
          }}
        >
          {getInitials(data?.patient)}
        </div>
        <div>
          <p
            className="f-w-300"
            style={{
              color: "#aaaaaa",
              fontSize: "11px",
              lineHeight: 1.2,
            }}
          >
            Patient
          </p>
          <p className="f-w-600 color-primary" style={{ lineHeight: 1 }}>
            {data?.patient ? data.patient : "-"}
          </p>
        </div>
        <div
          style={{ gap: "6px", marginLeft: "auto" }}
          className="flex center-left cursor-pointer"
          onClick={() => {
            updateQueryParam("showid", data.id);
          }}
        >
          <p
            className="f-w-300"
            style={{
              color: "#283f54",
              fontSize: "11px",
              lineHeight: 1,
            }}
          >
            View Details
          </p>
          <IoArrowForwardCircleOutline />
        </div>
      </div>
      {viewActivity ||
        (autoShow && (
          <PopUpModal>
            <ActivityDetailsContent
              setViewActivity={setViewActivity}
              id={data?.id}
              status={status}
              statusId={statusId}
              navigate={navigate}
            />
          </PopUpModal>
        ))}
    </div>
  );
};

const ActivityDetailsContent = ({ setViewActivity, id, status, navigate }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const typeCounters = {};
  const { permissions } = useUI();
  const location = useLocation();
  const callApi = async (id) => {
    setLoading(true);
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/activity/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      } else {
        setData(null);
        setError("Failed to fetch activity data.");
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch activity data.");
      setLoading(false);
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) callApi(id);
  }, [id]);
  const { queryParams, updateQueryParam } = useQueryParams();
  return (
    <section id="custom-modal">
      <div className="inner">
        <div className="box" id="activity-detail-box">
          <div className="flex center-left">
            <div>
              <h6 className="color-primary f-w-300">Activity Details</h6>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <ButtonLightTextIcon
                title="Cancel"
                onClick={() => {
                  updateQueryParam("showid");
                }}
                icon={<TbArrowBackUp />}
              />
            </div>
          </div>
          <div
            style={{
              height: "60vh",
              margin: "10px",
              overflowY: "scroll",
              padding: "20px 5px",
              overflowX: "hidden",
              scrollbarWidth: "thin",
            }}
          >
            {loading && (
              <div
                className="col-1-1"
                style={{
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoaderIcon
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
            )}
            {!loading && error && (
              <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
                {error}
              </p>
            )}
            {data && !loading && !error && (
              <div className="activity-card pos-rel">
                <div
                  style={{
                    position: "absolute",
                    content: "",
                    height: "4px",
                    left: "-1px",
                    right: "-1px",
                    bottom: "-2px",
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                ></div>
                <div
                  className="col-1-1 flex center-left"
                  style={{
                    gap: "10px",
                    paddingBottom: "5px",
                  }}
                >
                  <h5 className="f-w-700">
                    <span className="f-w-300">#</span>
                    &nbsp;
                    {data.patientActivityDAO.id}
                  </h5>
                  <StatusIcon id={status} />
                </div>
                <div
                  style={{
                    padding: "12px 0 12px 0 ",
                  }}
                >
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<TbUserCircle color={getRandomColor()} size={20} />}
                      label={"Patient"}
                      name={getPatientName(data?.patientActivityDAO?.patient)}
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <MdOutlineAccountBalance
                          color={getRandomColor()}
                          size={20}
                        />
                      }
                      label={"Account Number"}
                      name={data?.patientActivityDAO?.account_number || "-"}
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<FaUserDoctor color={getRandomColor()} size={20} />}
                      label={"Surgeon"}
                      name={
                        data?.eventSurgeon
                          ? `${data?.eventSurgeon?.firstName} ${data?.eventSurgeon?.lastName}`
                          : "-"
                      }
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<CiHospital1 color={getRandomColor()} size={20} />}
                      label={"Facility"}
                      name={
                        data?.patientActivityDAO?.event_facility
                          ?.facilityName || "-"
                      }
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<GiDoctorFace color={getRandomColor()} size={20} />}
                      label={"Assistant"}
                      name={
                        data?.patientActivityDAO?.event_surgical_assitant
                          ? `${data?.patientActivityDAO?.event_surgical_assitant?.name} ${data?.patientActivityDAO?.event_surgical_assitant?.lastName}${data?.patientActivityDAO?.event_surgical_assitant?.credential ? ", " + data?.patientActivityDAO?.event_surgical_assitant?.credential : ""}`
                          : "-"
                      }
                    />

                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <TbTimelineEvent color={getRandomColor()} size={20} />
                      }
                      label={"Event Type"}
                      name={data?.eventType?.eventTypeName || "-"}
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<LuMapPin color={getRandomColor()} size={20} />}
                      label={"Place of Service"}
                      name={
                        data?.placeOfService?.name
                          ? `${data?.placeOfService?.name}`
                          : "-"
                      }
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<FiFlag color={getRandomColor()} size={20} />}
                      label={"Event Flags"}
                      name={
                        data?.eventFlags?.scheduleFlag
                          ? `${data?.eventFlags?.scheduleFlag}`
                          : "-"
                      }
                    />
                  </div>

                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <div className="col-1-1 info-row-column">
                      <div
                        className="flex center-left"
                        style={{
                          gap: "10px",
                          alignItems: "flex-start",
                        }}
                      >
                        <div>
                          <LuBadgeInfo color={getRandomColor()} size={20} />
                        </div>
                        <div
                          style={{
                            overflow: "hidden",
                          }}
                        >
                          <p
                            className="f-w-300"
                            style={{
                              color: "#aaaaaa",
                              fontSize: "10px",
                              lineHeight: 1,
                              paddingBottom: "2px",
                            }}
                          >
                            Procedures
                          </p>
                          <p
                            style={{
                              whiteSpace: "normal",
                              overflowWrap: "break-word",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          >
                            {data?.procedure?.length
                              ? data.procedure
                                  .map((procedure) => procedure.procedureName)
                                  .join(", ")
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />
                  <div style={{ marginBottom: "16px" }}>
                    <p
                      style={{
                        fontWeight: "700",
                        paddingBottom: "8px",
                      }}
                    >
                      Files
                    </p>
                    <div
                      style={{
                        display: "grid",
                        gap: "16px",
                      }}
                    >
                      {data.patientActivityDAO?.files.length ? (
                        data.patientActivityDAO?.files.map((file, index) => {
                          const type = file.type;
                          typeCounters[type] = (typeCounters[type] || 0) + 1;

                          return (
                            <div
                              className="cursor-pointer"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "5px 12px",
                                backgroundColor: "rgb(245, 245, 245)",
                                borderRadius: "8px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "12px",
                                }}
                              >
                                <FaRegFileLines />
                                <div>
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                    className="t-t-u l-s-n"
                                  >
                                    {`${
                                      data?.patientActivityDAO?.patient
                                        ?.firstName
                                        ? data?.patientActivityDAO?.patient
                                            ?.firstName + "-"
                                        : ""
                                    }${
                                      data?.patientActivityDAO?.patient
                                        ?.lastName
                                        ? data?.patientActivityDAO?.patient
                                            ?.lastName + "-"
                                        : ""
                                    }${file.type || ""}-${
                                      typeCounters[file.type]
                                    }`}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "10px",
                                      color: "#7D7D7D",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {file.type}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "8px",
                                }}
                              >
                                <NavLink
                                  to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                    file?.fileS3Url || ""
                                  )}&title=${encodeURIComponent(
                                    `${
                                      data?.patientActivityDAO?.patient
                                        ?.firstName
                                        ? data?.patientActivityDAO?.patient
                                            ?.firstName + "-"
                                        : ""
                                    }${
                                      data?.patientActivityDAO?.patient
                                        ?.lastName
                                        ? data?.patientActivityDAO?.patient
                                            ?.lastName + "-"
                                        : ""
                                    }${file.type || ""}-${
                                      typeCounters[file.type]
                                    }`
                                  )}`}
                                  state={location.state}
                                >
                                  <span
                                    style={{
                                      background: "transparent",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                    title="View file"
                                  >
                                    <LuEye />
                                  </span>
                                </NavLink>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px 12px",
                            backgroundColor: "rgb(245, 245, 245)",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                No file found.
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <hr
                    style={{
                      marginTop: "10px",
                      marginBottom: "12px",
                    }}
                  />

                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <TbCalendarShare color={getRandomColor()} size={20} />
                      }
                      label={"Admission Date"}
                      name={
                        data?.patientActivityDAO?.admission_date
                          ? formatServerDate(
                              data?.patientActivityDAO?.admission_date
                            )
                          : "-"
                      }
                    />

                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<BiTime color={getRandomColor()} size={20} />}
                      label={"Start Date & Time"}
                      name={
                        (data?.patientActivityDAO?.event_start_date
                          ? formatServerDate(
                              data?.patientActivityDAO?.event_start_date
                            )
                          : "-") +
                        " " +
                        (data?.patientActivityDAO?.event_start_time || "")
                      }
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<BiTime color={getRandomColor()} size={20} />}
                      label={"End Date & Time"}
                      name={
                        (data?.patientActivityDAO?.event_end_date
                          ? formatServerDate(
                              data?.patientActivityDAO?.event_end_date
                            )
                          : "-") +
                        " " +
                        (data?.patientActivityDAO?.event_end_time || "")
                      }
                    />
                  </div>

                  <hr
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />

                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "20px",
                    }}
                  >
                    <div className="col-1-1 info-row-column">
                      <div className="flex center-left" style={{ gap: "10px" }}>
                        <div>
                          <LuBadgeInfo color={getRandomColor()} size={20} />
                        </div>
                        <div
                          style={{
                            overflow: "hidden",
                          }}
                        >
                          <p
                            className="f-w-300"
                            style={{
                              color: "#aaaaaa",
                              fontSize: "10px",
                              lineHeight: 1,
                              paddingBottom: "2px",
                            }}
                          >
                            Note
                          </p>
                          <p
                            style={{
                              whiteSpace: "normal",
                              overflowWrap: "break-word",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          >
                            {data?.patientActivityDAO?.event_notes || "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      label={"Created By"}
                      name={
                        data?.createdBy
                          ? `${data?.createdBy?.name} ${data?.createdBy?.lastName}`
                          : "-"
                      }
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      label={"Modified By"}
                      name={
                        data?.modifiedBy
                          ? `${data?.modifiedBy?.name} ${data?.modifiedBy?.lastName}`
                          : "-"
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {!loading && (
            <div>
              {permissions?.isUpdateAllowed && (
                <ButtonLightTextIcon
                  title="Edit Details"
                  onClick={() => {
                    updateQueryParam("showid");
                    if (data?.patientActivityDAO?.id) {
                      navigate(
                        `/edit-activity?next=${encodeURIComponent(window.location.pathname + "?" + queryParams.toString())}`,
                        {
                          state: {
                            id: data?.patientActivityDAO?.id,
                          },
                        }
                      );
                    }
                  }}
                  disabled={
                    data?.invoiceStatus == 2 || data?.invoiceStatus === 4
                  }
                  icon={<TbPencilMinus />}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ActivityCard;
