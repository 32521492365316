import Header from "../components/layout/Header"; 

const WithLayout = ({ children }) => {
	return ( 
			<main> 
				<section className='main-section'>
					<Header />
					{children}
				</section>
			</main> 
	);
};

export default WithLayout;
