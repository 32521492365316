import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import ActivityCard from "./activities/ActivityCard";
import Pagination from "../components/common/Pagination";
import ActivityFilters from "./activities/ActivityFilter";
import { jsonToQueryParams, handleApiCall } from "../utilities/helpers";
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NO } from "../utilities/constants";
import { useLocation, useNavigate } from "react-router-dom";
import useQueryParams from "../hooks/useQueryParams";
import { useUI } from "../context/ui";
import PageToolbar from "../components/common/PageToolbar";
import { FiPlusCircle } from "react-icons/fi";

const ActivityPage = () => {
	const { updateQueryParam, queryParams } = useQueryParams();
	const location = useLocation();
	const navigate = useNavigate();
	const { profile, permissions } = useUI();

	const [controller, setController] = useState(null);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);

	const pageSize = queryParams.get("pageSize") || DEFAULT_PAGE_SIZE;
	const pageNo = queryParams.get("pageNo") || DEFAULT_PAGE_NO;
	const status = queryParams.get("status") || "incomplete";
	const showid = queryParams.get("showid") || "";
	// ====== FILTER QUERY PARAMS

	const startDate = queryParams.get("startDate") || "";
	const endDate = queryParams.get("endDate") || "";
	const company = queryParams.get("company") || "";
	const assistant = queryParams.get("assistant") || "";
	const facility = queryParams.get("facility") || null;
	const patient = queryParams.get("patient") || null;
	const createdBy = queryParams.get("createdBy") || null;
	const physician = queryParams.get("physician") || null;
	const statusFilter = queryParams.get("statusFilter") || "";
	const billoption = queryParams.get("billoption") || "";

	// ====== RESET FILTER
	const resetFilters = () => {
		updateQueryParam("defaultFilterVal", "");
		updateQueryParam("startDate", "");
		updateQueryParam("endDate", "");
		updateQueryParam("company", "");
		updateQueryParam("assistant", "");
		updateQueryParam("facility", "");
		updateQueryParam("patient", "");
		updateQueryParam("createdBy", "");
		updateQueryParam("physician", "");
		updateQueryParam("statusFilter", "");
		updateQueryParam("billoption", "");
		updateQueryParam("pageNo", 0);
	};

	const getList = async (s) => {
		// if (queryParams.get("showid")) {
		// 	return;
		// }
		controller?.abort();
		const newController = new AbortController();
		setController(newController);
		const { signal } = newController;
		try {
			setLoading(true);

			const query = jsonToQueryParams({
				fromDate: startDate,
				toDate: endDate,
				statusFilter,
				billoption,
				companyId: company ? JSON.parse(company)?.value : null,
				assistantId: assistant ? JSON.parse(assistant)?.value : null,
				facilityId: facility ? JSON.parse(facility)?.value : null,
				patientId: patient ? JSON.parse(patient)?.value : null,
				createdBy: createdBy ? JSON.parse(createdBy)?.value : null,
				physicianId: physician ? JSON.parse(physician)?.value : null,
			});

			const response = await handleApiCall(
				API_URL +
					`/api/1.0.1/activity/status/${s}?pageSize=${pageSize}&pageNo=${pageNo}&${query}&role=${profile.type}&userId=${profile.id}`,
				{
					method: "GET",
				},
				null,
				signal
			);

			if (response.responseStatus == true) {
				setData(response.data);
			} else {
				setData(null);
			}

			if (response.responseCode !== 299) {
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			console.error("Error:", e);
		}
	};

	useEffect(() => {
		getList(status);
		return () => {
			if (controller) {
				controller.abort();
			}
		};
	}, [location.search, status]);

	return (
		<section id='activity-v2'>
			<PageToolbar
				title='Activities'
				button={
					permissions?.isCreateAllowed ? (
						<ButtonTextIcon
							title='Add New'
							className='mar-l-a'
							onClick={() => navigate("/add-activity")}
							icon={<FiPlusCircle />}
						/>
					) : (
						<></>
					)
				}
				tabs={
					<ul className='tab-selector'>
						<li
							className={`transition cursor-pointer pos-rel ${
								status === "incomplete" ? "active-tab" : ""
							}`}
							onClick={() => {
								updateQueryParam("status", "incomplete");
								updateQueryParam("pageNo", 0);
							}}>
							Ongoing
						</li>
						<li
							className={`transition cursor-pointer pos-rel ${
								status === "complete" ? "active-tab" : ""
							}`}
							onClick={() => {
								updateQueryParam("status", "complete");
								updateQueryParam("pageNo", 0);
							}}>
							Completed
						</li>
					</ul>
				}
			/>
			<ActivityFilters
				resetFilters={() => {
					resetFilters();
				}}
			/>
			<div>
				{data && !loading && (
					<div
						className='flex center-left'
						style={{
							padding: "10px 15px 10px 15px",
							gap: "12px",
						}}>
						<p className='f-w-300' style={{ fontSize: "11px" }}>
							Page no:
						</p>
						<Pagination
							totalRecords={data.totalRecords}
							pageNo={data.pageNo}
							pageSize={data.pageSize}
						/>
					</div>
				)}
			</div>
			{loading ? (
				<section
					style={{
						height: "100vh",
						paddingBottom: "20vh",
						backgroundColor: "#fff",
					}}
					className='flex center-center'>
					<LoaderIcon style={{ height: "32px", width: "32px" }} />
				</section>
			) : data?.list?.length ? (
				<div className='activity-cards'>
					{data?.list?.map((data, i) => (
						<ActivityCard
							key={i}
							data={data}
							autoShow={showid == data.id ? true : false}
						/>
					))}
				</div>
			) : (
				<section
					style={{
						height: "80vh",
						paddingBottom: "20vh",
						backgroundColor: "#fff",
					}}
					className='flex center-center'>
					<p>No records found.</p>
				</section>
			)}
			<div>
				{data && !loading && (
					<div
						className='flex center-center'
						style={{
							padding: "24px 15px 32px 15px",
							gap: "12px",
						}}>
						<p className='f-w-300' style={{ fontSize: "11px" }}>
							Page no:
						</p>
						<Pagination
							totalRecords={data.totalRecords}
							pageNo={data.pageNo}
							pageSize={data.pageSize}
						/>
					</div>
				)}
			</div>
		</section>
	);
};

export default ActivityPage;
