import { useNavigate } from "react-router-dom";
import LoginErrorImg from "../../../images/icons/login-error.svg";
import ButtonTextIcon from "../../../components/ui/ButtonTextIcon";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { TbArrowBackUp } from "react-icons/tb";

const FallbackErrorUI = ({
  pageTitle = "Error",
  title = "Error",
  message = "Something went wrong.",
}) => {
  const navigate = useNavigate();
  useDocumentTitle(pageTitle);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <main>
      <section className="main-section">
        <div
          className="flex center-center"
          style={{ backgroundColor: "white", height: "100vh" }}
        >
          <div className="t-a-c">
            <img
              src={LoginErrorImg}
              style={{
                maxWidth: "320px",
                height: "auto",
                margin: "0 auto",
                display: "block",
              }}
            />
            <br />
            <p className="color-primary font-size-14 f-w-600">{title}</p>
            <p className="color-primary font-size-12 f-w-300">{message}</p>
            <br />

            <ButtonTextIcon
              icon={<TbArrowBackUp />}
              onClick={handleGoBack}
              title="Go back"
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default FallbackErrorUI;
