import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import TableFilter from "./knowledge-base-sub-categories/TableFilter";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import useQueryParams from "../hooks/useQueryParams";
import { handleApiCall, jsonToQueryParams } from "../utilities/helpers";
import PageToolbar from "../components/common/PageToolbar";
import { FiPlusCircle } from "react-icons/fi";

const KnowledgeBaseSubCategoryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { updateQueryParam, queryParams } = useQueryParams();
  const [controller, setController] = useState(null);

  const pageNo = queryParams.get("pageNo") || 0;
  const pageSize = queryParams.get("pageSize") || 12;
  const { addToastMessage } = useUI();
  const { permissions } = useUI();
  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("pageNo", 0);
  };

  const getCategoryApi = async () => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    setLoading(true);
    try {
      const query = jsonToQueryParams({
        name: queryParams.get("search") || "",
      });
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/knowledge-base-subcategory?pageNo=${pageNo}&pageSize=${pageSize}&${query}&sortOrder=DESC`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setData(response.data);
      } else {
        setData(null);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.error("Error:", e);
    }
  };

  const handleDelete = async (id) => {
    if (!id) {
      addToastMessage({
        status: false,
        message:
          "Something went wrong while deleting sub category. Please try again.",
      });
      return false;
    }
    try {
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/knowledge-base-subcategory/change-status/${id}`,
        {
          method: "PUT",
          body: { statusId: 2 },
        }
      );
      if (response.responseStatus === true) {
        getCategoryApi();
        addToastMessage({
          status: true,
          message: "Sub category deleted successfully.",
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    addToastMessage({
      status: false,
      message:
        "Something went wrong while deleting sub category. Please try again.",
    });
    return false;
  };

  useEffect(() => {
    getCategoryApi();
  }, [location.search]);

  return (
    <section id="category">
      <PageToolbar
        title="Knowledge Base Sub Categories"
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => navigate("/add-knowledge-base-sub-categories")}
              icon={<FiPlusCircle />}
            />
          )
        }
      />
      <TableFilter
        resetFilters={() => {
          resetFilters();
        }}
      />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          className="flex center-center"
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.knowledgeBaseSubCategoryList.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Code</th>
                <th>Category Name</th>
                <th>Sub Category Name</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.knowledgeBaseSubCategoryList?.map((item, index) => (
                <tr key={item.id}>
                  <td>{pageNo * pageSize + index + 1}</td>
                  <td>{item.code}</td>
                  <td>{item.knowledgeBaseCategory.name}</td>
                  <td>{item.name}</td>
                  <td>
                    <div className="flex center-center" style={{ gap: "12px" }}>
                      {permissions?.isUpdateAllowed && (
                        <div className="flex color-light font-size-10 ">
                          <NavLink
                            to={`/edit-knowledge-base-sub-categories?next=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                            state={{ id: item.id }}
                          >
                            <ButtonTertiary actionType="edit" title="Edit" />
                          </NavLink>
                        </div>
                      )}
                      {permissions?.isDeleteAllowed && (
                        <div className="color-secondary transition hover-color-primary cursor-pointer ">
                          <CustomModal
                            selector={
                              <ButtonTertiary
                                actionType="delete"
                                title="Delete"
                              />
                            }
                            content={
                              <ConfirmationModal
                                onConfirm={async () => {
                                  await handleDelete(item?.id);
                                  return true;
                                }}
                                message="Are you sure you want to delete this sub category?"
                              />
                            }
                          />
                        </div>
                      )}
                      <div></div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default KnowledgeBaseSubCategoryPage;
