import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useEffect, useState, Fragment } from "react";
import {
  API_URL,
  CLEARANCE_OPTIONS,
  EMR_ACCESS_OPTIONS,
  FACILITY_INVOICE_OPTIONS,
  FACILITY_TYPE_OPTIONS,
  PLACE_OF_SERVICE,
  SYSTEM_TOKEN,
} from "../../utilities/constants";
import PaginatedDropdown from "../../components/ui/PaginatedDropdown";
import { useUI } from "../../context/ui";
import PhoneNumberInput from "../../components/ui/PhoneNumberInput";
import { formatInputPhoneNumber } from "../../utilities/helpers";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { handleApiCall } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import { mapOptions } from "../../components/ui/PaginatedDropdown";
import ZipCodeInput from "../../components/ui/ZipCodeInput";
import useQueryParams from "../../hooks/useQueryParams";
import { FiArrowRightCircle, FiPlusCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { RxCrossCircled } from "react-icons/rx";

const UpdateFacilityForm = ({ facilityData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const { addToastMessage } = useUI();
  const { queryParams, updateQueryParam } = useQueryParams();
  const nextUrl = decodeURIComponent(queryParams.get("next")) || "";
  const [isZipDataFilled, setIsZipDataFilled] = useState(false);
  const [initialValues, setInitialValues] = useState({
    additionalContacts: [],
    id: null,
    facilityName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    contactFax: "",
    gpo: "",
    idn: "",
    npi: "",
    taxPercent: "",
    caseCapacity: "",
    dayConfirmEmail: "",
    facilityType: "",
    financialClearanceFacility: "",
    facilityEmrAccess: "",
    facilityPlaceOfService: "",
    invoiceReq: "",
    facilityAdditionalContact: " ",
    internalIdentifier: "",
    taxonomy: "",
  });

  useEffect(() => {
    setInitialValues({
      id: facilityData?.id,
      facilityName: facilityData?.facilityName,
      address: facilityData?.address,
      address2: facilityData?.address2,
      state: facilityData?.stateRecord
        ? {
            value: facilityData?.stateRecord?.code,
            label: facilityData?.stateRecord?.name,
          }
        : null,
      city: facilityData?.city
        ? {
            value: facilityData?.city,
            label: facilityData?.city,
          }
        : null,
      zipCode: facilityData?.zipCode,
      // contactName: facilityData?.contactName,
      // contactEmail: facilityData?.contactEmail,
      // contactPhone: facilityData?.contactPhone,
      contactFax: facilityData?.contactFax,
      gpo: facilityData?.gpo,
      idn: facilityData?.idn,
      npi: facilityData?.npi,
      taxPercent: facilityData?.taxPercent,
      caseCapacity: facilityData?.caseCapacity,
      dayConfirmEmail: facilityData?.dayConfirmEmail,
      facilityType: facilityData?.facilityType,
      financialClearanceFacility: facilityData?.financialClearanceFacility,
      facilityEmrAccess: facilityData?.facilityEmrAccess,
      facilityPlaceOfService: facilityData?.facilityPlaceOfService,
      invoiceReq: facilityData?.invoiceReq,
      facilityAdditionalContact: facilityData?.facilityAdditionalContact,
      internalIdentifier: facilityData?.internalIdentifier,
      taxonomy: facilityData?.taxonomy,
      additionalContacts: facilityData?.additionalContacts
        ? facilityData?.additionalContacts.map((item) => ({
            contactTitle: item.title || null,
            contactEmail: item.email || null,
            contactPhone: item.phone || null,
            contactName: item.name || null,
          }))
        : [],
    });
  }, [facilityData]);

  const validationSchema = Yup.object({
    facilityName: Yup.string().required("Facility Name is required"),
    npi: Yup.string().required("NPI is required"),
    facilityPlaceOfService: Yup.string().required(
      "Place Of Service is required"
    ),
    additionalContacts: Yup.array()
      .of(
        Yup.object().shape({
          contactName: Yup.string().trim().required("Person Name is required"),

          contactPhone: Yup.string()
            .matches(
              /^\d{1,15}$/,
              "Phone Number must be 1-15 digits and contain only numbers"
            )
            .required("Phone Number is required"),

          contactEmail: Yup.string()
            .trim()
            .email("Invalid email address")
            .required("Email is required"),

          contactTitle: Yup.string().trim().required("Title is required"),
        })
      )
      .notRequired(),
  });

  const updateFacility = async (values) => {
    setLoading(true);
    setStatus("");

    try {
      const payload = {
        facilityName: values.facilityName,
        address: values.address,
        address2: values.address2,
        city: values.city?.label,
        state: values.state?.value,
        zipCode: values.zipCode,
        // contactName: values.contactName,
        // contactEmail: values.contactEmail,
        // contactPhone: values.contactPhone,
        contactFax: values.contactFax,
        gpo: values.gpo,
        idn: values.idn,
        npi: values.npi,
        taxPercent: values.taxPercent,
        caseCapacity: values.caseCapacity,
        dayConfirmEmail: values.dayConfirmEmail,
        facilityType: values.facilityType,
        financialClearanceFacility: values.financialClearanceFacility || "",
        facilityEmrAccess: values.facilityEmrAccess,
        facilityPlaceOfService: values.facilityPlaceOfService,
        invoiceReq: values.invoiceReq,
        facilityAdditionalContact: values.facilityAdditionalContact,
        internalIdentifier: values.internalIdentifier,
        taxonomy: values.taxonomy,
        additionalContacts: values.additionalContacts
          ? values.additionalContacts.map((item) => ({
              email: item?.contactEmail || null,
              phone: item?.contactPhone || null,
              name: item?.contactName || null,
              title: item?.contactTitle || null,
            }))
          : [],
      };

      const response = await handleApiCall(
        API_URL + `/api/1.0.0/facility/${values?.id}`,
        {
          method: "PUT",
          body: payload,
        }
      );
      if (response.responseStatus == true) {
        setStatus("You have successfully updated a facility, thank you.");
        addToastMessage({
          status: true,
          message: `You have successfully updated a facility, thank you.`,
        });
        if (nextUrl) {
          navigate(nextUrl);
        } else {
          navigate("/facilities");
        }
        return true;
      } else {
        addToastMessage({
          status: false,
          message: `${response.responseDescription || "Something went wrong, please try again later."}`,
        });
        return false;
      }
    } catch (e) {
      addToastMessage({
        status: false,
        message: "Something went wrong, please try again later.",
      });
      console.error("Error:", e);
      return false;
    }
  };

  const labelStateFunc = (o) => `${o.name}`;
  const labelCityFunc = (o) => `${o.name}`;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await updateFacility(values);
        if (api) {
          resetForm();
        } else {
          setStatus("Something went wrong, please try again later.");
        }
        setLoading(false);
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
        validateForm,
        setFieldTouched,
      }) => (
        <Form>
          <div className="col-1-1">
            <table>
              <tbody>
                <tr>
                  <td width="50%">
                    <Label title="Facility Name" required={true} />
                    <Field
                      type="text"
                      placeholder="Enter Facility Name"
                      name="facilityName"
                      className={`col-1-1 primary ${
                        errors.facilityName && touched.facilityName
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                  <td width="50%">
                    <Label title="Address Line1" />
                    <Field
                      type="text"
                      placeholder="Enter Address Line1"
                      name="address"
                      className={`col-1-1 primary ${
                        errors.address && touched.address ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Address Line2" />
                    <Field
                      type="text"
                      placeholder="Enter Address Line1"
                      name="address2"
                      className={`col-1-1 primary ${
                        errors.address2 && touched.address2 ? "error" : ""
                      }`}
                    />
                  </td>
                  <td>
                    <Label title="Zip Code" />
                    <ZipCodeInput
                      name="zipCode"
                      clearFields={() => {
                        setFieldValue("state", null);
                        setFieldValue("city", null);
                      }}
                      onZipDetailsFetched={(data) => {
                        if (data.country.code !== "US") return;
                        setIsZipDataFilled(true);
                        setFieldValue("state", {
                          value: data.state.code,
                          label: data.state.name,
                          obj: data.state,
                        });
                        setFieldValue("city", {
                          value: data.city.code,
                          label: data.city.name,
                          obj: data.city,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="State" />
                    <PaginatedDropdown
                      datakey="records"
                      url={`${API_URL}/api/1.0.0/reference/states/US?`}
                      mapOptions={(records) =>
                        mapOptions(records, "code", labelStateFunc)
                      }
                      error={errors.state && touched.state}
                      setTouched={() => setFieldTouched("state", true)}
                      placeHolder="Search"
                      selectedValue={values.state}
                      onChange={(v) => {
                        if (isZipDataFilled) setFieldValue("zipCode", "");
                        setIsZipDataFilled(false);
                        setFieldValue("state", v);
                        setFieldValue("city", null);
                      }}
                    />
                  </td>
                  <td>
                    <Label title="City" />
                    <PaginatedDropdown
                      datakey="records"
                      url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.state?.value}?`}
                      mapOptions={(records) =>
                        mapOptions(records, "name", labelCityFunc)
                      }
                      error={errors.city && touched.city}
                      setTouched={() => setFieldTouched("city", true)}
                      placeHolder="Search"
                      selectedValue={values.city}
                      onChange={(v) => {
                        if (isZipDataFilled) setFieldValue("zipCode", "");
                        setIsZipDataFilled(false);
                        setFieldValue("city", v);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="GPO" />
                    <Field
                      type="text"
                      placeholder="Enter GPO"
                      name="gpo"
                      className={`col-1-1 primary ${
                        errors.gpo && touched.gpo ? "error" : ""
                      }`}
                    />
                  </td>
                  <td width="50%">
                    <Label title="IDN" />
                    <Field
                      type="text"
                      placeholder="Enter IDN"
                      name="idn"
                      className={`col-1-1 primary ${
                        errors.idn && touched.idn ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Facility NPI" required={true} />
                    <Field
                      type="text"
                      placeholder="Enter Facility NPI"
                      name="npi"
                      className={`col-1-1 primary ${
                        errors.npi && touched.npi ? "error" : ""
                      }`}
                    />
                  </td>
                  <td width="50%">
                    <Label title="Taxonomy Id" />
                    <Field
                      type="text"
                      placeholder="Enter Taxonomy Id"
                      name="taxonomy"
                      className={`col-1-1 primary ${
                        errors.taxonomy && touched.taxonomy ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Sales Tax Percent" />
                    <Field
                      type="text"
                      placeholder="Enter Sales Tax Percent"
                      name="taxPercent"
                      className={`col-1-1 primary ${
                        errors.taxPercent && touched.taxPercent ? "error" : ""
                      }`}
                    />
                  </td>
                  <td width="50%">
                    <Label title="Case Capacity" />
                    <Field
                      type="text"
                      placeholder="Enter Case Capacity"
                      name="caseCapacity"
                      className={`col-1-1 primary ${
                        errors.caseCapacity && touched.caseCapacity
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Day Confirmation Email Address" />
                    <Field
                      type="email"
                      placeholder="Enter Confirmation Email"
                      name="dayConfirmEmail"
                      className={`col-1-1 primary ${
                        errors.dayConfirmEmail && touched.dayConfirmEmail
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                  <td width="50%">
                    <Label title="Facility Type" />
                    <Field
                      as="select"
                      name="facilityType"
                      className={`col-1-1 primary ${
                        errors.facilityType && touched.facilityType
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {FACILITY_TYPE_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Financial Clearance" />
                    <Field
                      as="select"
                      name="financialClearanceFacility"
                      className={`col-1-1 primary ${
                        errors.financialClearanceFacility &&
                        touched.financialClearanceFacility
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {CLEARANCE_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                  <td width="50%">
                    <Label title="EMR Access" />
                    <Field
                      as="select"
                      name="facilityEmrAccess"
                      className={`col-1-1 primary ${
                        errors.facilityEmrAccess && touched.facilityEmrAccess
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {EMR_ACCESS_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Place Of Service" required={true} />
                    <Field
                      as="select"
                      name="facilityPlaceOfService"
                      className={`col-1-1 primary ${
                        errors.facilityPlaceOfService &&
                        touched.facilityPlaceOfService
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {PLACE_OF_SERVICE.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                  <td>
                    <Label title="Fax" />
                    <Field
                      type="text"
                      placeholder="Enter Primary Contact Fax"
                      name="contactFax"
                      className={`col-1-1 primary ${
                        errors.contactFax && touched.contactFax ? "error" : ""
                      }`}
                    />
                  </td>
                  {/* <td width="50%">
                    <Label title="Adition Contact(s)" />
                    <Field
                      type="text"
                      placeholder="Enter Adition Contact(s)"
                      name="facilityAdditionalContact"
                      className={`col-1-1 primary ${
                        errors.facilityAdditionalContact &&
                        touched.facilityAdditionalContact
                          ? "error"
                          : ""
                      }`}
                    />
                  </td> */}
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Invoice Procedure CPT Requirements" />
                    <Field
                      as="select"
                      name="invoiceReq"
                      className={`col-1-1 primary ${
                        errors.invoiceReq && touched.invoiceReq ? "error" : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {FACILITY_INVOICE_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                  <td width="50%">
                    <Label title="Internal Identifier" />
                    <Field
                      type="text"
                      placeholder="Enter Internal Identifier"
                      name="internalIdentifier"
                      className={`col-1-1 primary ${
                        errors.internalIdentifier && touched.internalIdentifier
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{ margin: "10px" }}>
                    <FieldArray name="additionalContacts">
                      {({ push, remove }) => (
                        <>
                          {values.additionalContacts.map(
                            (additionalContact, index) => (
                              <Fragment key={index}>
                                <>
                                  <div
                                    style={{
                                      margin: "10px 0",
                                      justifyContent: "space-between",
                                      borderBottom: "1px solid  #ccc",
                                      alignContent: "center",
                                      padding: "5px 0px",
                                    }}
                                    className="flex center-center "
                                  >
                                    <h5 className="color-primary f-w-700">
                                      Contact Details #{index + 1}
                                    </h5>
                                    <div
                                      style={{
                                        margin: "2px 0px",
                                      }}
                                    >
                                      <ButtonLightTextIcon
                                        type="button"
                                        title="Remove"
                                        icon={<RxCrossCircled size={28} />}
                                        onClick={() => remove(index)}
                                      />
                                    </div>
                                  </div>
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <Label
                                            title="Primary Contact Title"
                                            required={true}
                                          />
                                          <Field
                                            type="text"
                                            placeholder="Enter Primary Contact Title"
                                            name={`additionalContacts.${index}.contactTitle`}
                                            className={`col-1-1 primary ${
                                              errors?.additionalContacts &&
                                              errors.additionalContacts[index]
                                                ?.contactTitle &&
                                              touched?.additionalContacts &&
                                              touched.additionalContacts[index]
                                                ?.contactTitle
                                                ? "error"
                                                : ""
                                            }`}
                                          />
                                          <ErrorMessage
                                            name={`additionalContacts.${index}.contactTitle`}
                                            component="p"
                                            className="error-messages"
                                          />
                                        </td>
                                        <td>
                                          <Label
                                            title="Primary Contact Name"
                                            required={true}
                                          />
                                          <Field
                                            type="text"
                                            placeholder="Enter Primary Contact Name"
                                            name={`additionalContacts.${index}.contactName`}
                                            className={`col-1-1 primary ${
                                              errors?.additionalContacts &&
                                              errors.additionalContacts[index]
                                                ?.contactName &&
                                              touched?.additionalContacts &&
                                              touched.additionalContacts[index]
                                                ?.contactName
                                                ? "error"
                                                : ""
                                            }`}
                                          />
                                          <ErrorMessage
                                            name={`additionalContacts.${index}.contactName`}
                                            component="p"
                                            className="error-messages"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td width="50%">
                                          <Label
                                            title="Primary Contact Email"
                                            required={true}
                                          />
                                          <Field
                                            type="email"
                                            placeholder="Enter Primary Contact Email"
                                            name={`additionalContacts.${index}.contactEmail`}
                                            className={`col-1-1 primary ${
                                              errors?.additionalContacts &&
                                              errors.additionalContacts[index]
                                                ?.contactEmail &&
                                              touched?.additionalContacts &&
                                              touched.additionalContacts[index]
                                                ?.contactEmail
                                                ? "error"
                                                : ""
                                            }`}
                                          />
                                          <ErrorMessage
                                            name={`additionalContacts.${index}.contactEmail`}
                                            component="p"
                                            className="error-messages"
                                          />
                                        </td>
                                        <td>
                                          <Label
                                            title="Primary Contact Phone"
                                            required={true}
                                          />
                                          <PhoneNumberInput
                                            name={`additionalContacts.${index}.contactPhone`}
                                            placeholder="Eg: 888-888-8888"
                                            error={
                                              errors?.additionalContacts &&
                                              errors.additionalContacts[index]
                                                ?.contactPhone &&
                                              touched?.additionalContacts &&
                                              touched.additionalContacts[index]
                                                ?.contactPhone
                                            }
                                            value={
                                              values.additionalContacts[index]
                                                .contactPhone
                                            }
                                            setTouched={() =>
                                              setFieldTouched(
                                                `additionalContacts.${index}.contactPhone`,
                                                true
                                              )
                                            }
                                            callback={(v) => {
                                              setFieldValue(
                                                `additionalContacts.${index}.contactPhone`,
                                                v
                                              );
                                            }}
                                          />
                                          <ErrorMessage
                                            name={`additionalContacts.${index}.contactPhone`}
                                            component="p"
                                            className="error-messages"
                                          />
                                        </td>
                                      </tr>
                                      <tr></tr>
                                    </tbody>
                                  </table>
                                </>
                              </Fragment>
                            )
                          )}
                          <div
                            className="flex center-center"
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            <ButtonTextIcon
                              title="Add Contact Details"
                              type="button"
                              onClick={async () => {
                                push({
                                  contactName: "",
                                  contactEmail: "",
                                  contactPhone: "",
                                  contactTitle: "",
                                });
                              }}
                              icon={<FiPlusCircle />}
                            />
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => {
                if (nextUrl) {
                  navigate(nextUrl);
                } else {
                  navigate("/facilities");
                }
              }}
              icon={<TbArrowBackUp />}
            />
            <ButtonTextIcon
              type="submit"
              disabled={loading}
              title="Update Now"
              onClick={async () => {
                const errors = await validateForm();
                if (Object.keys(errors).length > 0) {
                  let firstErrorMessage = "";
                  const getFirstErrorMessage = (errObj) => {
                    if (!errObj) return null;
                    if (typeof errObj === "string") return errObj;
                    if (Array.isArray(errObj)) {
                      for (const item of errObj) {
                        const message = getFirstErrorMessage(item);
                        if (message) return message;
                      }
                    }
                    if (typeof errObj === "object") {
                      for (const key of Object.keys(errObj)) {
                        const message = getFirstErrorMessage(errObj[key]);
                        if (message) return message;
                      }
                    }
                    return null;
                  };
                  firstErrorMessage =
                    getFirstErrorMessage(errors) ||
                    "Please fill in all required fields";
                  addToastMessage({
                    status: false,
                    message: firstErrorMessage,
                  });
                  return;
                }
                handleSubmit();
              }}
              className="mar-l-a"
              icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
            />
          </div>
          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const NestedPersonalDetailForm = ({ onAdd }) => {
  const validationSchema = Yup.object().shape({
    contactName: Yup.string().required("Person Name Required"),
    contactPhone: Yup.string()
      .matches(/^\d+$/, "Phone Number must contain only numbers")
      .max(15, "Phone Number must be at most 15 digits")
      .required("Phone Number is required"),
    contactEmail: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    contactTitle: Yup.string().required("Title is Required"),
  });

  return (
    <Formik
      initialValues={{
        contactName: "",
        contactEmail: "",
        contactPhone: "",
        contactTitle: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        try {
          onAdd(values);
          resetForm();
        } catch (error) {
          console.error("Error during form submission:", error);
        }
      }}
    >
      {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
        <Form>
          <table>
            <tbody>
              <tr>
                <td>
                  <Label title="Primary Contact Title" />
                  <Field
                    type="text"
                    placeholder="Enter Primary Contact Title"
                    name="contactTitle"
                    className={`col-1-1 primary ${
                      errors.contactTitle && touched.contactTitle ? "error" : ""
                    }`}
                  />
                </td>
                <td>
                  <Label title="Primary Contact Name" />
                  <Field
                    type="text"
                    placeholder="Enter Primary Contact Name"
                    name="contactName"
                    className={`col-1-1 primary ${
                      errors.contactName && touched.contactName ? "error" : ""
                    }`}
                  />
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <Label title="Primary Contact Email" />
                  <Field
                    type="email"
                    placeholder="Enter Primary Contact Email"
                    name="contactEmail"
                    className={`col-1-1 primary ${
                      errors.contactEmail && touched.contactEmail ? "error" : ""
                    }`}
                  />
                </td>
                <td>
                  <Label title="Primary Contact Phone" />
                  <PhoneNumberInput
                    name="contactPhone"
                    placeholder="Eg: 888-888-8888"
                    error={errors.contactPhone && touched.contactPhone}
                    value={values.contactPhone}
                    setTouched={() => setFieldTouched("contactPhone", true)}
                    callback={(v) => setFieldValue("contactPhone", v)}
                  />
                  <ErrorMessage
                    name="contactPhone"
                    component="p"
                    className="error-messages"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <div className="flex bottom-right">
                    <div>
                      <ButtonLightTextIcon
                        title="Add Contact Details"
                        type="submit"
                        icon={<FiPlusCircle />}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateFacilityForm;
