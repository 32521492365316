const PageToolbar = ({ title, button, tabs }) => {
  return (
      <div className="col-1-1 flex center-left pad-0-10 main-section-top-row">
          <h5 className="page-active color-primary f-w-700">{title}</h5>
        {tabs &&tabs}
          {button && <div className="mar-l-a">{button}</div>}
      </div>
  );
};

export default PageToolbar;