import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { lazy, Suspense, useRef, useState } from "react";
import { API_URL } from "../utilities/constants";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import {
  formatDate,
  getUserTypeInfo,
  handleApiCall,
} from "../utilities/helpers";
import PopUpModal from "../components/common/PopUpModal";
import ButtonLightTextIcon from "../components/ui/ButtonLightTextIcon";
import DragFileUpload from "../components/ui/DragFileUpload";
import { useUI } from "../context/ui";
import Label from "../components/ui/Label";
import { TbArrowBackUp } from "react-icons/tb";
import { FiArrowRightCircle, FiEdit } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
const OverviewSection = lazy(() => import("../pages/profile/OverviewSection"));

const SectionLoader = () => {
  return (
    <section className="flex center-center" style={{ height: "480px" }}>
      <LoaderIcon style={{ height: "32px", width: "32px", opacity: "0.2" }} />
    </section>
  );
};

const ProfilePage = () => {
  const [show, setShow] = useState(false);
  const { addToastMessage, profile: data } = useUI();
  const fileUploadRef = useRef();
  const [uploaded, setUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleReset = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFile();
    }
  };
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <section>
      <section id="profile-page">
        <div className="cover-image b-r-10 default-image"></div>
        <div className="user-info">
          <div
            className="default-image user-picture b-r-100 pos-rel"
            style={{
              backgroundImage: data?.image ? `url(${data?.image})` : null,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div className="t-a-l">
            <div className="flex bottom-left" style={{ gap: "8px" }}>
              <h5 className="color-primary l-h-1">
                {data?.name} {data?.lastName}
              </h5>
              <p className="color-light f-w-300" style={{ fontSize: "11px" }}>
                ({getUserTypeInfo(data?.type)?.label})
              </p>
            </div>
            <p className="color-light font-size-14">
              Joined{" "}
              {data?.createdAt ? formatDate(data?.createdAt) : "--"}
            </p>
            <p className="color-light font-size-12">{data?.email}</p>
          </div>
          <div className="mar-l-a">
            <ButtonTextIcon
              onClick={() => setShow(true)}
              title="Edit Profile"
              icon={
                <FiEdit /> 
              }
            />
          </div>
        </div>
        <div className="rs-tabs">
          <ul className="tab-selector">
            <li className="transition cursor-pointer pos-rel active-tab">
              About
            </li>
          </ul>
          <div className="tab-content">
            <div className="about-content">
              <div className="left">
                <br />
                <ul>
                  <li
                    className={`font-size-14 b-r-5 transition cursor-pointer pos-rel active-tab`}
                    style={{
                      backgroundColor: "#cfdbe5",
                    }}
                  >
                    Overview
                  </li>
                </ul>
              </div>
              <div className="right">
                <Suspense fallback={<SectionLoader />}>
                  <OverviewSection data={data} />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </section>
      {show && (
        <PopUpModal>
            <section
              style={{
                position: "fixed",
                inset: 0,
                zIndex: 9999,
                backgroundColor: "rgba(255, 255, 255, 0.86)",
              }}
            >
              <section
                className="col-1-1 flex center-center"
                style={{
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <div
                  style={{
                    width: "486px",
                    padding: "24px",
                    height: "auto",
                    maxHeight: "80vh",
                    border: "solid 1px #f0f0f0",
                    background: "white",
                    borderRadius: "8px",
                    margin: "0 auto",
                    boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
                  }}
                >
                  <div
                    className="col-1-1 flex center-left"
                    style={{
                      borderBottom: "solid 1px #f0f0f0",
                      paddingBottom: "12px",
                      marginBottom: "16px",
                    }}
                  >
                    <h6
                      className="color-primary f-w-300 opl-tooltip"
                      data-tooltip="View entire history of the activity below."
                    >
                      Update Profile
                    </h6>
                    <div
                      className="mar-l-a cursor-pointer opl-tooltip"
                      data-tooltip="Close to return to the previous section."
                      onClick={() => setShow(false)}
                    >
                      <MdCancel size={24}/>
                    </div>
                  </div>
                  <div>
                    <section>
                      <div style={{ marginLeft: "auto" }}>
                        <Formik
                          initialValues={{
                            email: data?.email || "",
                            firstName: data?.name || "",
                            lastName: data?.lastName || "",
                            file: "",
                          }}
                          validationSchema={Yup.object({
                            firstName:
                              Yup.string().required("Name is required"),
                            lastName: Yup.string().required(
                              "lastName is required"
                            ),
                          })}
                          onSubmit={async (values, { resetForm }) => {
                            if (!data) {
                              addToastMessage({
                                status: false,
                                message: `Something went wrong, please try again later.`,
                              });
                              return;
                            }

                            try {
                              setLoading(true);
                              const payload = {
                                firstName: values.firstName,
                                lastName: values.lastName,
                                email: values.email,
                                id: data?.id,
                              };
                              if (values?.file) {
                                payload["imageUrl"] = values.file;
                              }

                              const response = await handleApiCall(
                                API_URL + `/api/1.0.0/profile`,
                                {
                                  method: "PUT",
                                  body: payload,
                                }
                              );
                              if (response.responseStatus == true) {
                                addToastMessage({
                                  status: true,
                                  message: `You have successfully update profile details, thank you.`,
                                });
                                handleReset();
                                handleReload();
                                setShow(false);
                                return true;
                              } else {
                                addToastMessage({
                                  status: false,
                                  message:
                                    response?.responseDescription ||
                                    `Something went wrong, please try again later.`,
                                });
                                return false;
                              }
                            } catch (error) {
                              console.log(error);
                              addToastMessage({
                                status: false,
                                message: `Something went wrong, please try again later.`,
                              });
                            }
                            setLoading(false);
                            resetForm();
                          }}
                        >
                          {({
                            handleSubmit,
                            setFieldValue,
                            values,
                            errors,
                            touched,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <div className="col-1-1">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          padding: "10px",
                                        }}
                                      >
                                        <Label title="First Name" required />
                                        <Field
                                          name="firstName"
                                          type="text"
                                          placeholder="Enter  "
                                          className={`primary ${
                                            errors.firstName &&
                                            touched.firstName
                                              ? "error"
                                              : ""
                                          }`}
                                        />
                                      </td>
                                      <td
                                        style={{
                                          padding: "10px",
                                        }}
                                      >
                                        <Label title="Last Name" required />
                                        <Field
                                          name="lastName"
                                          type="text"
                                          placeholder="Enter  "
                                          className={`primary ${
                                            errors.lastName && touched.lastName
                                              ? "error"
                                              : ""
                                          }`}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan="2"
                                        style={{
                                          padding: "10px",
                                        }}
                                      >
                                        <Label title="Profile Image" required />
                                        <DragFileUpload
                                          ref={fileUploadRef}
                                          fileType="PROFILE_PICTURE"
                                          error={errors.file && touched.file}
                                          afterUpload={(url) => {
                                            if (url) {
                                              setFieldValue("file", url);
                                            } else {
                                              setFieldValue("file", "");
                                            }
                                        }}
                                        setUploading={setUploaded}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2">
                                        {" "}
                                        <hr />{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2">
                                        <div
                                          className="col-1-1 flex center-left"
                                          style={{
                                            marginTop: "16px",
                                            borderTop: "solid 1px #f0f0f0",
                                            paddingTop: "16px",
                                          }}
                                        >
                                          <ButtonLightTextIcon
                                          title="Cancel"
                                          type="button"
                                          onClick={() => setShow(false)}
                                          icon={
                                            <TbArrowBackUp />}
                                          />
                                          <div
                                            style={{
                                              marginLeft: "auto",
                                            }}
                                          >
                                            <ButtonTextIcon
                                              title="Update"
                                              type="submit"
                                              disabled={loading || uploaded}
                                              icon={
                                                loading ? (
                                                  <LoaderIcon />
                                                ) : (
                                                  <FiArrowRightCircle />
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <br />
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
            </section>
        </PopUpModal>
      )}
    </section>
  );
};

export default ProfilePage;
