import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Pagination from "../components/common/Pagination";
import {
  decodeModuleAccessFromToken,
  formatRateUSD,
  formatServerDate,
  getPatientName,
  getRandomColor,
  getUserTypeInfo,
  handleApiCall,
  jsonToQueryParams,
} from "../utilities/helpers";
import { useEffect, useState } from "react";
import { useUI } from "../context/ui";
import LoaderIcon from "../components/ui/LoaderIcon";
import TableFilter from "./bill/TableFilter";
import CustomModal from "../components/common/CustomModal";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import FullScreenBillDetailsModal from "./bill/FullScreenBillDetailsModal";
import ButtonLightTextIcon from "../components/ui/ButtonLightTextIcon";
import { API_URL } from "../utilities/constants";
import FullScreenFileViewerModal from "../components/ui/FullScreenFileViewerModal";
import FullScreenUpdateBillFormModal from "./bill/FullScreenUpdateBillFormModal";
import useQueryParams from "../hooks/useQueryParams";
import PageToolbar from "../components/common/PageToolbar";
import InfoItem from "../components/ui/InfoItem";
import BillHistory from "./bill/BillHistory";
import {
  TbArrowBackUp,
  TbCalendarShare,
  TbTimelineEvent,
  TbUserCircle,
} from "react-icons/tb";
import { MdOutlineAccountBalance } from "react-icons/md";
import { CiHospital1, CiWallet } from "react-icons/ci";
import { FaRegFileLines, FaUserDoctor } from "react-icons/fa6";
import { GiDoctorFace } from "react-icons/gi";
import { LuBadgeInfo, LuEye, LuMapPin } from "react-icons/lu";
import { FiEdit, FiFlag } from "react-icons/fi";
import { BiTime } from "react-icons/bi";
import PatientDetails from "./patient/PatientDetails";

const BillPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const { permissions } = useUI();
  const [controller, setController] = useState(null);
  const patientPermissions = decodeModuleAccessFromToken("patients");
  const activitiePermissions = decodeModuleAccessFromToken("activities");

  // ====== FILTER QUERY PARAMS
  const startDate = queryParams.get("startDate") || "";
  const endDate = queryParams.get("endDate") || "";
  const facility = queryParams.get("facility") || null;
  const patient = queryParams.get("patient") || null;
  const billType = queryParams.get("billType") || "";

  // ====== RESET FILTER
  const resetFilters = () => {
    updateQueryParam("startDate", "");
    updateQueryParam("endDate", "");
    updateQueryParam("facility", "");
    updateQueryParam("patient", "");
    updateQueryParam("pageNo", 0);
    updateQueryParam("billType", "");
  };

  const getListApi = async (s) => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    setLoading(true);
    try {
      const query = jsonToQueryParams({
        startDate,
        endDate,
        facilityId: facility ? JSON.parse(facility)?.value : null,
        patientId: patient ? JSON.parse(patient)?.value : null,
        billType: billType || null,
      });
      const response = await handleApiCall(
        API_URL +
          `/api/1.0.0/bill?pageSize=${pageSize}&pageNo=${pageNo}&${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus == true) {
        setData(response.data);
      } else {
        setData(null);
      }

      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    getListApi();
  }, [location.search]);

  return (
    <section id="patient">
      <PageToolbar title="Bills" />
      <TableFilter
        resetFilters={() => {
          resetFilters();
        }}
      />
      <div className="flex center-left">
        <div>
          {data && !loading && (
            <div
              className="flex center-left"
              style={{
                padding: "10px 15px 10px 15px",
                gap: "12px",
              }}
            >
              <p className="f-w-300" style={{ fontSize: "11px" }}>
                Page no:
              </p>
              <Pagination
                totalRecords={data.totalRecords}
                pageNo={data.pageNo}
                pageSize={data.pageSize}
              />
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.list.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>Bill ID</th>
                <th>Activity ID</th>
                <th>Patient ID</th>
                <th>Patient DOB</th>
                <th>Patient Name</th>
                <th>Patient DOS</th>
                <th>Charge Amount</th>
                <th>Facility</th>
                <th style={{ textAlign: "center" }}>
                  <div data-tooltip="Actions available: View, Edit, or Delete the record.">
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.list.map((data, index) => (
                  <tr key={index}>
                    <td>
                      {data.id ? (
                        <div
                          className="flex center-left"
                          style={{ gap: "10px" }}
                        >
                          <div>{data?.id}</div>
                        </div>
                      ) : (
                        "NA"
                      )}
                    </td>
                    <td>
                      {data?.activity?.id ? (
                        <div
                          className="flex center-left"
                          style={{
                            gap: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>{data?.activity?.id}</div>
                          <div className="flex" style={{ gap: "10px" }}>
                            {activitiePermissions?.isUpdateAllowed && (
                              <div>
                                <NavLink
                                  to={
                                    data?.activity?.id
                                      ? `/edit-activity?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                                      : "#"
                                  }
                                  state={{ id: data?.activity?.id }}
                                >
                                  <ButtonTertiary
                                    disabled={
                                      data?.invoiceStatus === 2 ||
                                      data?.invoiceStatus === 4
                                    }
                                    actionType="edit"
                                    title="Edit Activity "
                                    className="tertiary-sm"
                                  />
                                </NavLink>
                              </div>
                            )}
                            {activitiePermissions?.isReadAllowed && (
                              <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                      actionType="view"
                                      title="View Activity"
                                      className="tertiary-sm"
                                    />
                                  }
                                  content={
                                    <ActivityDetailsContent
                                      id={data?.activity?.id}
                                    />
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        "NA"
                      )}
                    </td>
                    <td>
                      {data?.activity?.patient?.id ? (
                        <div
                          className="flex center-left"
                          style={{
                            gap: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>{data?.activity?.patient?.id}</div>
                          <div className="flex" style={{ gap: "10px" }}>
                            {patientPermissions?.isUpdateAllowed && (
                              <div>
                                <NavLink
                                  to={`/edit-patient?next=${encodeURIComponent(
                                    window.location.pathname +
                                      window.location.search
                                  )}`}
                                  state={{ id: data?.activity?.patient?.id }}
                                >
                                  <ButtonTertiary
                                    actionType="edit"
                                    title="Edit Patient"
                                    className="tertiary-sm"
                                  />
                                </NavLink>
                              </div>
                            )}
                            {patientPermissions?.isReadAllowed && (
                              <div>
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                      actionType="view"
                                      title="Patient Detail"
                                      className="tertiary-sm"
                                    />
                                  }
                                  content={
                                    <PatientDetails
                                      id={data?.activity?.patient?.id}
                                    />
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        "NA"
                      )}
                    </td>

                    <td>
                      {data?.activity?.patient?.dateOfBirth
                        ? formatServerDate(data?.activity?.patient?.dateOfBirth)
                        : "NA"}
                    </td>
                    <td>
                      {data?.activity?.patient?.firstName || "NA"}{" "}
                      {data?.activity?.patient?.middleName || ""}{" "}
                      {data?.activity?.patient?.lastName || ""}
                    </td>
                    <td className="t-t-u t-a-l">
                      {data?.activity?.patient?.serviceDate
                        ? formatServerDate(data?.activity?.patient?.serviceDate)
                        : "NA"}
                    </td>
                    <td>
                      {data?.amount ? `${formatRateUSD(data?.amount)}` : "NA"}
                    </td>
                    <td>{data?.facility?.facilityName || "NA"}</td>
                    <td>
                      <div
                        className="flex center-center"
                        style={{ gap: "12px" }}
                      >
                        {permissions?.isReadAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="view"
                                  title="View"
                                />
                              }
                              content={
                                <FullScreenBillDetailsModal
                                  title="Bill Detail"
                                  id={data.id}
                                />
                              }
                            />
                          </div>
                        )}
                        {permissions?.isUpdateAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  disabled={
                                    data?.invoiceStatus === 2 ||
                                    data?.invoiceStatus === 4
                                  }
                                  actionType="edit"
                                  title="Edit"
                                />
                              }
                              content={
                                <FullScreenUpdateBillFormModal
                                  title="Edit Bill"
                                  id={data.id}
                                  Callback={getListApi}
                                />
                              }
                            />
                          </div>
                        )}
                        <div>
                          <CustomModal
                            selector={
                              <ButtonTertiary
                                actionType="view"
                                title="History"
                              />
                            }
                            content={<BillHistory id={data.id} />}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}

      <div>
        <div>
          {data && !loading && (
            <div
              className="flex center-center"
              style={{
                padding: "24px 15px 32px 15px",
                gap: "12px",
              }}
            >
              <p className="f-w-300" style={{ fontSize: "11px" }}>
                Page no:
              </p>
              <Pagination
                totalRecords={data.totalRecords}
                pageNo={data.pageNo}
                pageSize={data.pageSize}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default BillPage;

const ActivityDetailsContent = ({ setShow, id }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const typeCounters = {};
  const permissions = decodeModuleAccessFromToken("activities");

  useEffect(() => {
    if (!permissions) {
      navigate("/login");
    }
  }, [permissions]);

  const callApi = async (id) => {
    setLoading(true);
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/activity/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus == true) {
        setData(response.data);
        setLoading(false);
      } else {
        setError("Failed to fetch activity data.");
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch activity data.");
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    if (id) callApi(id);
  }, [id]);

  return (
    <div id="activity-detail-box">
      <div className="flex center-left">
        <div>
          <h6 className="color-primary f-w-300">Activity Details</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ButtonLightTextIcon
            title="Cancel"
            onClick={() => setShow(false)}
            icon={<TbArrowBackUp />}
          />
        </div>
      </div>
      <div
        style={{
          height: "60vh",
          margin: "10px",
          overflowY: "scroll",
          padding: "20px 5px",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
      >
        {loading && (
          <div
            className="col-1-1"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderIcon
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
        )}
        {!loading && error && (
          <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
            {error}
          </p>
        )}
        {data && !loading && !error && (
          <div className="activity-card pos-rel">
            <div
              style={{
                position: "absolute",
                content: "",
                height: "4px",
                left: "-1px",
                right: "-1px",
                bottom: "-2px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            ></div>
            <div
              className="col-1-1 flex center-left"
              style={{
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <h5 className="f-w-700">
                <span className="f-w-300">#</span>
                &nbsp;
                {data.patientActivityDAO.id}
              </h5>
            </div>
            <div
              style={{
                padding: "12px 0 12px 0 ",
              }}
            >
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbUserCircle color={getRandomColor()} size={24} />}
                  label={"Patient"}
                  name={getPatientName(data?.patientActivityDAO?.patient)}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <MdOutlineAccountBalance
                      color={getRandomColor()}
                      size={24}
                    />
                  }
                  label={"Account Number"}
                  name={data?.patientActivityDAO?.account_number || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CiWallet color={getRandomColor()} size={24} />}
                  label={"Pay To"}
                  name={data?.payToAdmin?.payToName || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<FaUserDoctor color={getRandomColor()} size={24} />}
                  label={"Surgeon"}
                  name={
                    data?.eventSurgeon
                      ? `${data?.eventSurgeon?.firstName} ${data?.eventSurgeon?.lastName}`
                      : "NA"
                  }
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<CiHospital1 color={getRandomColor()} size={24} />}
                  label={"Facility"}
                  name={
                    data?.patientActivityDAO?.event_facility?.facilityName ||
                    "NA"
                  }
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<GiDoctorFace color={getRandomColor()} size={24} />}
                  label={"Assistant"}
                  name={
                    data?.patientActivityDAO?.event_surgical_assitant
                      ? `${data?.patientActivityDAO?.event_surgical_assitant?.name} ${data?.patientActivityDAO?.event_surgical_assitant?.lastName}${data?.patientActivityDAO?.event_surgical_assitant?.credential ? ", " + data?.patientActivityDAO?.event_surgical_assitant?.credential : ""}`
                      : "NA"
                  }
                />

                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbTimelineEvent color={getRandomColor()} size={24} />}
                  label={"Event Type"}
                  name={data?.eventType?.eventTypeName || "NA"}
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuMapPin color={getRandomColor()} size={24} />}
                  label={"Place of Service"}
                  name={
                    data?.placeOfService?.name
                      ? `${data?.placeOfService?.name}`
                      : "NA"
                  }
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<FiFlag color={getRandomColor()} size={24} />}
                  label={"Event Flags"}
                  name={
                    data?.eventFlags?.scheduleFlag
                      ? `${data?.eventFlags?.scheduleFlag}`
                      : "NA"
                  }
                />
              </div>

              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div className="col-1-1 info-row-column">
                  <div
                    className="flex center-left"
                    style={{
                      gap: "10px",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>
                      <LuBadgeInfo color={getRandomColor()} size={24} />
                    </div>
                    <div
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <p
                        className="f-w-300"
                        style={{
                          color: "#aaaaaa",
                          fontSize: "10px",
                          lineHeight: 1,
                          paddingBottom: "2px",
                        }}
                      >
                        Procedures
                      </p>
                      <p
                        style={{
                          whiteSpace: "normal",
                          overflowWrap: "break-word",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                        }}
                      >
                        {data?.procedure?.length
                          ? data.procedure
                              .map((procedure) => procedure.procedureName)
                              .join(", ")
                          : "NA"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr
                style={{
                  marginTop: "10px",
                  marginBottom: "12px",
                }}
              />
              <div style={{ marginBottom: "16px" }}>
                <p
                  style={{
                    fontWeight: "700",
                    paddingBottom: "8px",
                  }}
                >
                  Files
                </p>
                <div
                  style={{
                    display: "grid",
                    gap: "16px",
                  }}
                >
                  {data.patientActivityDAO?.files.length ? (
                    data.patientActivityDAO?.files.map((file, index) => {
                      const type = file.type;
                      typeCounters[type] = (typeCounters[type] || 0) + 1;

                      return (
                        <CustomModal
                          key={index}
                          selector={
                            <div
                              className="cursor-pointer"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "5px 12px",
                                backgroundColor: "rgb(245, 245, 245)",
                                borderRadius: "8px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "12px",
                                }}
                              >
                                <FaRegFileLines />

                                <div>
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                    className="t-t-u l-s-n"
                                  >
                                    {`${data?.patientActivityDAO?.patient?.firstName ? data?.patientActivityDAO?.patient?.firstName + "-" : ""}${data?.patientActivityDAO?.patient?.middleName ? data?.patientActivityDAO?.patient?.middleName + "-" : ""} ${data?.patientActivityDAO?.patient?.lastName ? data?.patientActivityDAO?.patient?.lastName + "-" : ""}${file.type || ""}-${typeCounters[file.type]}`}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "10px",
                                      color: "#7D7D7D",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {file.type}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "8px",
                                }}
                              >
                                <span
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  title="View file"
                                >
                                  <LuEye />
                                </span>
                              </div>
                            </div>
                          }
                          content={
                            <FullScreenFileViewerModal
                              title={`Document - (${data?.patientActivityDAO?.patient?.firstName ? data?.patientActivityDAO?.patient?.firstName + "-" : ""}${data?.patientActivityDAO?.patient?.lastName || ""}-${file.type || ""}-${typeCounters[file.type]})`}
                              fileUrl={file?.fileS3Url || null}
                            />
                          }
                        />
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px 12px",
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            No file found.
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <hr
                style={{
                  marginTop: "10px",
                  marginBottom: "12px",
                }}
              />

              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbCalendarShare color={getRandomColor()} size={24} />}
                  label={"Admission Date"}
                  name={
                    data?.patientActivityDAO?.admission_date
                      ? formatServerDate(
                          // convertToYYYYMMDD(
                          data?.patientActivityDAO?.admission_date
                          // )
                        )
                      : "NA"
                  }
                />

                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<BiTime color={getRandomColor()} size={24} />}
                  label={"Start Date & Time"}
                  name={
                    (data?.patientActivityDAO?.event_start_date
                      ? formatServerDate(
                          data?.patientActivityDAO?.event_start_date
                        )
                      : "NA") +
                    " " +
                    (data?.patientActivityDAO?.event_start_time || "")
                  }
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<BiTime color={getRandomColor()} size={24} />}
                  label={"End Date & Time"}
                  name={
                    (data?.patientActivityDAO?.event_end_date
                      ? formatServerDate(
                          data?.patientActivityDAO?.event_end_date
                        )
                      : "NA") +
                    " " +
                    (data?.patientActivityDAO?.event_end_time || "")
                  }
                />
              </div>

              <hr
                style={{
                  marginTop: "10px",
                  marginBottom: "12px",
                }}
              />

              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "20px",
                }}
              >
                <div className="col-1-1 info-row-column">
                  <div className="flex center-left" style={{ gap: "10px" }}>
                    <div>
                      <LuBadgeInfo color={getRandomColor()} size={24} />
                    </div>
                    <div
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <p
                        className="f-w-300"
                        style={{
                          color: "#aaaaaa",
                          fontSize: "10px",
                          lineHeight: 1,
                          paddingBottom: "2px",
                        }}
                      >
                        Note
                      </p>
                      <p
                        style={{
                          whiteSpace: "normal",
                          overflowWrap: "break-word",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                        }}
                      >
                        {data?.patientActivityDAO?.event_notes || "NA"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  label={"Created By"}
                  name={
                    data?.createdBy
                      ? `${data?.createdBy?.name} ${data?.createdBy?.lastName}`
                      : "NA"
                  }
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  label={"Modified By"}
                  name={
                    data?.modifiedBy
                      ? `${data?.modifiedBy?.name} ${data?.modifiedBy?.lastName}`
                      : "NA"
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {permissions?.isUpdateAllowed && !loading && (
          <NavLink
            to={
              data?.patientActivityDAO?.id
                ? `/edit-activity?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                : "#"
            }
            state={{ id: data?.patientActivityDAO?.id }}
          >
            <ButtonLightTextIcon
              title="Edit Details"
              disabled={data?.invoiceStatus === 2 || data?.invoiceStatus === 4}
              onClick={() => setShow(false)}
              icon={<FiEdit />}
            />
          </NavLink>
        )}
      </div>
    </div>
  );
};
