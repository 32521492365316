import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useEffect, useState } from "react";
import { API_URL, BILLING_LISTING_OPTIONS } from "../../utilities/constants";
import Accordion from "../../components/common/Accordion";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import PaginatedDropdown, {
	mapOptions,
} from "../../components/ui/PaginatedDropdown";
import { useUI } from "../../context/ui";
import DatePicker from "react-datepicker";
import { formatISO, parseISO } from "date-fns";
import { handleApiCall, handleDatePickerChange } from "../../utilities/helpers";
import PhoneNumberInput from "../../components/ui/PhoneNumberInput";
import { useLocation, useNavigate } from "react-router-dom";
import Label from "../../components/ui/Label";
import LikeInput from "../../components/ui/LikeInput";
import DatePickerInput from "../../components/ui/DatePickerInput";
import CustomModal from "../../components/common/CustomModal";
import InsuranceSelect from "./InsuranceSelect";
import ZipCodeInput from "../../components/ui/ZipCodeInput";
import useQueryParams from "../../hooks/useQueryParams";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { IoShieldOutline } from "react-icons/io5";
const AddForm = ({ PatientData }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [id, setId] = useState(null);
	const { addToastMessage } = useUI();
	const { queryParams, updateQueryParam } = useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";
	const [isZipDataFilled, setIsZipDataFilled] = useState(false);
	const [isGuarantorZipDataFilled, setIsGuarantorZipDataFilled] =
		useState(false);
	const [initialValues, setInitialValues] = useState({
		firstName: "",
		lastName: "",
		middleName: "",
		gender: "",
		workPhone: "",
		homePhone: "",
		cellPhone: "",
		fax: "",
		accountNumber: "",
		email: "",
		dob: "",
		serviceDate: "",
		patientChartNo: "",
		ssn: "",
		address1: "",
		address2: "",
		zipCode: "",
		state: "",
		city: "",
		country: "",
		notes: "",
		primaryBillableType: "",
		tertiaryBillableType: "",
		secondaryBillableType: "",
		primaryPayerID: "",
		primaryInsuranceName: "",
		primaryMasterPayerID: "",
		primaryInsuranceTypeCode: "",
		primaryInsurancePhone: "",
		primaryInsuranceFax: "",
		primaryInsuranceAddress: "",
		primarySubscriberFirstName: "",
		primarySubscriberLastName: "",
		primaryMemberNumber: "",
		primaryGroupNumber: "",
		primarySubscriberDOB: "",
		guarantorFirstName: "",
		guarantorLastName: "",
		guarantorAddressLine1: "",
		guarantorAddressLine2: "",
		guarantorZipCode: "",
		guarantorState: "",
		guarantorCity: "",
		guarantorSpouseOrParentsName: "",
		guarantorSpousePartnerPhone: "",
		guarantorRelationshipToPatient: "",
		secondaryInsuranceName: "",
		secondaryPayerID: "",
		secondaryMasterPayerID: "",
		secondaryInsuranceTypeCode: "",
		secondaryInsurancePhone: "",
		secondaryInsuranceFax: "",
		secondaryInsuranceAddress: "",
		secondarySubscriberFirstName: "",
		secondarySubscriberLastName: "",
		secondaryMemberNumber: "",
		secondaryGroupNumber: "",
		secondarySubscriberDOB: "",
		tertiaryInsuranceName: "",
		tertiaryPayerID: "",
		tertiaryMasterPayerID: "",
		tertiaryInsuranceTypeCode: "",
		tertiaryInsurancePhone: "",
		tertiaryInsuranceFax: "",
		tertiaryInsuranceAddress: "",
		tertiarySubscriberFirstName: "",
		tertiarySubscriberLastName: "",
		tertiaryMemberNumber: "",
		tertiaryGroupNumber: "",
		tertiarySubscriberDOB: "",
		patientCondition: "",
		employerName: "",
		illnessIndicator: "",
		dateOfInjury: "",
		nickName: "",
		ethnicity: "",
		primaryPhysicianPhoneName: "",
		mrn: "",
		salutation: "",
		nickName: "",
		signature: "",
		assignedUser: "",
		status: "",
		ethnicity: "",
		race: "",
		specialNeed: "",
		sourceDetail: "",
		diagnosisCode1: "",
		diagnosisCode2: "",
		primaryDiagnosisCode: "",
		additionalDiagnosisCode: "",
		secondaryLastName: "",
		secondaryFirstName: "",
		secondaryPhoneNumber: "",
		secondaryEmail: "",
		primaryPhysicianLastName: "",
		primaryPhysicianFirstName: "",
		primaryPhysicianPhone: "",
		primaryPhysicianFax: "",
		responsibleLastName: "",
		responsibleFirstName: "",
		responsiblePhone: "",
		responsibleEmail: "",
		primaryInsuranceId: null,
		tertiaryInsuranceId: null,
		secondaryInsuranceId: null,
		guarantorId: "",
	});

	useEffect(() => {
		setInitialValues({
			id: setId(PatientData?.id),
			firstName: PatientData?.firstName || null,
			lastName: PatientData?.lastName || null,
			middleName: PatientData?.middleName || null,
			gender: PatientData?.gender || null,
			workPhone: PatientData?.additionalNumber || null,
			homePhone: PatientData?.phoneNumber || null,
			cellPhone: PatientData?.cellNumber || null,
			fax: PatientData?.fax || null,
			accountNumber: PatientData?.accountNumber || null,
			email: PatientData?.email || null,
			dob: PatientData?.dateOfBirth || null,
			serviceDate: PatientData?.serviceDate || null,
			patientChartNo: PatientData?.patientChartNo || null,
			ssn: PatientData?.ssn || null,
			address1: PatientData?.address1 || null,
			address2: PatientData?.address2 || null,
			zipCode: PatientData?.zip || null,
			primaryBillableType: PatientData?.primaryBillableType || null,
			secondaryBillableType: PatientData?.secondaryBillableType || null,
			tertiaryBillableType: PatientData?.tertiaryBillableType || null,
			state: PatientData?.state
				? {
						value: PatientData?.state?.code,
						label: PatientData?.state?.name,
					}
				: null,
			city: PatientData?.city
				? {
						value: PatientData?.city?.code,
						label: PatientData?.city?.name,
					}
				: null,
			// country:PatientData?.country.name,
			country: PatientData?.country
				? {
						value: PatientData?.country?.code,
						label: PatientData?.country?.name,
					}
				: null,
			notes: PatientData?.notes || null,
			primaryInsuranceId: PatientData?.primaryInsurance
				? {
						value: PatientData?.primaryInsurance?.id,
						label: PatientData?.primaryInsurance?.description,
					}
				: null,
			secondaryInsuranceId: PatientData?.secondaryInsurance
				? {
						value: PatientData?.secondaryInsurance?.id,
						label: PatientData?.secondaryInsurance?.description,
					}
				: null,
			tertiaryInsuranceId: PatientData?.tertiaryInsurance
				? {
						value: PatientData?.tertiaryInsurance?.id,
						label: PatientData?.tertiaryInsurance?.description,
					}
				: null,
			primaryPayerID:
				PatientData?.primaryInsurance?.electronicPayerId || null,
			primaryInsuranceName:
				PatientData?.primaryInsurance?.description || null,
			primaryMasterPayerID:
				PatientData?.primaryInsurance?.masterPayerId || null,
			primaryInsuranceTypeCode:
				PatientData?.primaryInsurance?.insuranceType || null,
			primaryInsurancePhone:
				PatientData?.primaryInsurance?.insuranceContactDetails?.find(
					(detail) => detail.type === "Phone"
				)?.information || null,
			primaryInsuranceFax:
				PatientData?.primaryInsurance?.insuranceContactDetails?.find(
					(detail) => detail.type === "Phone"
				)?.information || null,
			// primaryInsurancePhone: PatientData?.primaryInsurance?.insuranceContactDetails?.type ==="Phone"? PatientData?.tertiaryInsurance?.insuranceContactDetails?.information: null,
			// primaryInsuranceFax: PatientData?.primaryInsurance?.insuranceContactDetails?.type ==="Fax"? PatientData?.tertiaryInsurance?.insuranceContactDetails?.information: null,
			primaryInsuranceAddress:
				PatientData?.primaryInsurance?.addressLine1 || null,
			primarySubscriberFirstName:
				PatientData?.primarySubscriberData?.subscriberFirstName || null,
			primarySubscriberLastName:
				PatientData?.primarySubscriberData?.subscriberLastName || null,
			primaryMemberNumber: PatientData?.primarySubscriberData
				? PatientData?.primarySubscriberData?.subscriberMemberNumber
				: null,
			primaryGroupNumber:
				PatientData?.primarySubscriberData?.subscriberGroupNumber ||
				null,
			primarySubscriberDOB:
				PatientData?.primarySubscriberData?.subscriberDob || null,
			guarantorId: PatientData?.guarantor?.guarantorId || null,
			guarantorFirstName:
				PatientData?.guarantor?.guarantorFirstName || null,
			guarantorLastName:
				PatientData?.guarantor?.guarantorLastName || null,
			guarantorAddressLine1:
				PatientData?.guarantor?.guarantorAddress1 || null,
			guarantorAddressLine2:
				PatientData?.guarantor?.guarantorAddress2 || null,
			guarantorZipCode: PatientData?.guarantor?.guarantorZip || null,
			guarantorState: PatientData?.guarantor?.guarantorState
				? {
						value: PatientData?.guarantor?.guarantorState?.code,
						label: PatientData?.guarantor?.guarantorState?.name,
					}
				: null,
			guarantorCity: PatientData?.guarantor?.guarantorCity
				? {
						value: PatientData?.guarantor?.guarantorCity?.name,
						label: PatientData?.guarantor?.guarantorCity?.name,
					}
				: null,
			guarantorSpouseOrParentsName:
				PatientData?.guarantor?.guarantorSpouseName || null,
			guarantorSpousePartnerPhone:
				PatientData?.guarantor?.guarantorSpousePhone || null,
			guarantorRelationshipToPatient:
				PatientData?.guarantor?.guarantorRelationship || null,
			secondaryInsuranceName:
				PatientData?.secondaryInsurance?.description || null,
			secondaryPayerID:
				PatientData?.secondaryInsurance?.electronicPayerId || null,
			secondaryMasterPayerID:
				PatientData?.secondaryInsurance?.masterPayerId || null,
			secondaryInsuranceTypeCode:
				PatientData?.secondaryInsurance?.insuranceType || null,
			secondaryInsurancePhone:
				PatientData?.secondaryInsurance?.insuranceContactDetails?.find(
					(detail) => detail.type === "Phone"
				)?.information || null,
			secondaryInsuranceFax:
				PatientData?.secondaryInsurance?.insuranceContactDetails?.find(
					(detail) => detail.type === "Fax"
				)?.information || null,
			secondaryInsuranceAddress:
				PatientData?.secondaryInsurance?.addressLine1 || null,
			secondarySubscriberFirstName:
				PatientData?.secondarySubscriberData?.subscriberFirstName ||
				null,
			secondarySubscriberLastName:
				PatientData?.secondarySubscriberData?.subscriberLastName ||
				null,
			secondaryMemberNumber:
				PatientData?.secondarySubscriberData?.subscriberMemberNumber ||
				null,
			secondaryGroupNumber:
				PatientData?.secondarySubscriberData?.subscriberGroupNumber ||
				null,
			secondarySubscriberDOB:
				PatientData?.secondarySubscriberData?.subscriberDob || null,
			tertiaryInsuranceName:
				PatientData?.tertiaryInsurance?.description || null,
			tertiaryPayerID:
				PatientData?.tertiaryInsurance?.electronicPayerId || null,
			tertiaryMasterPayerID:
				PatientData?.tertiaryInsurance?.masterPayerId || null,
			tertiaryInsuranceTypeCode:
				PatientData?.tertiaryInsurance?.insuranceType || null,
			tertiaryInsurancePhone:
				PatientData?.tertiaryInsurance?.insuranceContactDetails?.find(
					(detail) => detail.type === "Phone"
				)?.information || null,
			tertiaryInsuranceFax:
				PatientData?.tertiaryInsurance?.insuranceContactDetails?.find(
					(detail) => detail.type === "Phone"
				)?.information || null,
			tertiaryInsuranceAddress:
				PatientData?.tertiaryInsurance?.addressLine1 || null,
			tertiarySubscriberFirstName:
				PatientData?.tertiarySubscriberData?.subscriberFirstName ||
				null,
			tertiarySubscriberLastName:
				PatientData?.tertiarySubscriberData?.subscriberLastName || null,
			tertiaryMemberNumber:
				PatientData?.tertiarySubscriberData?.subscriberMemberNumber ||
				null,
			tertiaryGroupNumber:
				PatientData?.tertiarySubscriberData?.subscriberGroupNumber ||
				null,
			tertiarySubscriberDOB:
				PatientData?.tertiarySubscriberData?.subscriberDob || null,
			patientCondition: PatientData?.workerComp?.patientCondition || null,
			employerName: PatientData?.workerComp?.employerName || null,
			illnessIndicator: PatientData?.workerComp?.illnessIndicator || null,
			dateOfInjury: PatientData?.workerComp?.dateOfInjury || null,
			nickName: PatientData?.workerComp?.dateOfInjury || null,
			ethnicity: PatientData?.workerComp?.dateOfInjury || null,
			primaryPhysicianPhoneName:
				PatientData?.workerComp?.dateOfInjury || null,
			mrn: PatientData?.workerComp?.dateOfInjury || null,
			salutation: PatientData?.workerComp?.dateOfInjury || null,
			signature: PatientData?.workerComp?.dateOfInjury || null,
			assignedUser: PatientData?.workerComp?.dateOfInjury || null,
			status: PatientData?.status || null,
			ethnicity: PatientData?.ethnicity || null,
			race: PatientData?.race || null,
			specialNeed: PatientData?.specialNeed || null,
			sourceDetail: PatientData?.sourceDetail || null,
			diagnosisCode1: PatientData?.diagnosisCode1 || null,
			diagnosisCode2: PatientData?.diagnosisCode2 || null,
			primaryDiagnosisCode: PatientData?.primaryDiagnosisCode || null,
			additionalDiagnosisCode:
				PatientData?.additionalDiagnosisCode || null,
			secondaryLastName: PatientData?.secondaryLastName || null,
			secondaryFirstName: PatientData?.secondaryFirstName || null,
			secondaryPhoneNumber: PatientData?.secondaryPhoneNumber || null,
			secondaryEmail: PatientData?.secondaryEmail || null,
			primaryPhysicianLastName:
				PatientData?.primaryPhysicianLastName || null,
			primaryPhysicianFirstName:
				PatientData?.primaryPhysicianFirstName || null,
			primaryPhysicianPhone: PatientData?.primaryPhysicianPhone || null,
			primaryPhysicianFax: PatientData?.primaryPhysicianFax || null,
			responsibleLastName: PatientData?.responsibleLastName || null,
			responsibleFirstName: PatientData?.responsibleFirstName || null,
			responsiblePhone: PatientData?.responsiblePhone || null,
			responsibleEmail: PatientData?.responsibleEmail || null,
			id: PatientData?.id || null,
		});
	}, [PatientData]);

	const validationSchema = Yup.object({
		firstName: Yup.string()
			.min(2, "First Name must be at least 2 characters long")
			.required("First Name is required"),
		lastName: Yup.string()
			.min(2, "Last Name must be at least 2 characters long")
			.required("Last Name is required"),
		gender: Yup.string().required("Gender is required"),
		country: Yup.object().required("Country is required"),
		zipCode: Yup.string().required("Zip Code is required"),
		email: Yup.string()
			.email("Please enter a valid email address") // Validates only if a value is entered
			.nullable(),
		workPhone: Yup.string()
			.matches(/^\d+$/, "Work Phone Number must contain only numbers")
			.max(15, "Work Phone Number must be at most 15 digits")
			.notRequired(),
		homePhone: Yup.string()
			.matches(/^\d+$/, "Home Phone Number must contain only numbers")
			.max(15, "Home Phone Number must be at most 15 digits")
			.notRequired(),
		cellPhone: Yup.string()
			.matches(/^\d+$/, "Cell Phone must contain only numbers")
			.max(15, "Cell Phone must be at most 15 digits")
			.notRequired(),
		accountNumber: Yup.string().required("Account # is required"),
		dob: Yup.date().required("DOB is required"),
		ssn: Yup.string().notRequired(),
		primaryInsuranceId: Yup.object()
			.nullable()
			.test(
				"unique-ids",
				"Primary Insurance ID must be unique",
				function (value) {
					const {
						primaryInsuranceId,
						secondaryInsuranceId,
						tertiaryInsuranceId,
					} = this.parent;

					// Extract the current field's value and compare with others
					const primaryId = value?.value; // Current value of primaryInsuranceId
					const secondaryId = secondaryInsuranceId?.value; // value of secondaryInsuranceId
					const tertiaryId = tertiaryInsuranceId?.value; // value of tertiaryInsuranceId

					// Only check if Primary ID has conflicts
					if (
						primaryId &&
						(primaryId === secondaryId || primaryId === tertiaryId)
					) {
						return this.createError({
							message: "Insurances must be unique",
						});
					}

					return true;
				}
			),

		secondaryInsuranceId: Yup.object()
			.nullable()
			.test(
				"unique-ids",
				"Secondary Insurance ID must be unique",
				function (value) {
					const {
						primaryInsuranceId,
						secondaryInsuranceId,
						tertiaryInsuranceId,
					} = this.parent;

					// Extract the current field's value and compare with others
					const primaryId = primaryInsuranceId?.value; // value of primaryInsuranceId
					const secondaryId = value?.value; // Current value of secondaryInsuranceId
					const tertiaryId = tertiaryInsuranceId?.value; // value of tertiaryInsuranceId

					// Only check if Secondary ID has conflicts
					if (
						secondaryId &&
						(secondaryId === primaryId ||
							secondaryId === tertiaryId)
					) {
						return this.createError({
							message: "Insurances must be unique",
						});
					}

					return true;
				}
			),

		tertiaryInsuranceId: Yup.object()
			.nullable()
			.test(
				"unique-ids",
				"Tertiary Insurance ID must be unique",
				function (value) {
					const {
						primaryInsuranceId,
						secondaryInsuranceId,
						tertiaryInsuranceId,
					} = this.parent;

					// Extract the current field's value and compare with others
					const primaryId = primaryInsuranceId?.value; // value of primaryInsuranceId
					const secondaryId = secondaryInsuranceId?.value; // value of secondaryInsuranceId
					const tertiaryId = value?.value; // Current value of tertiaryInsuranceId

					// Only check if Tertiary ID has conflicts
					if (
						tertiaryId &&
						(tertiaryId === primaryId || tertiaryId === secondaryId)
					) {
						return this.createError({
							message: "Insurances must be unique",
						});
					}

					return true;
				}
			),
	});

	const updatePatient = async (values, id) => {
		setLoading(true);
		setStatus("");
		try {
			const payload = {
				assignedUser: values.assignedUser,
				additionalNumber: values.workPhone,
				firstName: values.firstName,
				status: values.status,
				address1: values.address1,
				address2: values.address2,
				email: values.email,
				primaryBillableType: values?.primaryBillableType,
				tertiaryBillableType: values?.tertiaryBillableType,
				secondaryBillableType: values?.secondaryBillableType,
				fax: values.fax,
				city: values?.city?.label || "",
				country: values?.country?.value || "",
				ethnicity: values.ethnicity,
				dateOfBirth: values.dob,
				serviceDate: values.serviceDate,
				patientChartNo: values.patientChartNo,
				gender: values.gender,
				race: values.race,
				specialNeed: values.specialNeed,
				sourceDetail: values.sourceDetail,
				diagnosisCode1: values.diagnosisCode1,
				diagnosisCode2: values.diagnosisCode2,
				primaryDiagnosisCode: values.primaryDiagnosisCode,
				additionalDiagnosisCode: values.additionalDiagnosisCode,
				secondaryLastName: values.secondaryLastName,
				secondaryFirstName: values.secondaryFirstName,
				secondaryPhoneNumber: values.secondaryPhoneNumber,
				secondaryEmail: values.secondaryEmail,
				primaryPhysicianLastName: values.primaryPhysicianLastName,
				primaryPhysicianFirstName: values.primaryPhysicianFirstName,
				primaryPhysicianPhoneName: values.primaryPhysicianPhoneName,
				primaryPhysicianFaxName: values.primaryPhysicianFaxName,
				guarantor: {
					guarantorId: values.guarantorId,
					guarantorFirstName: values.guarantorFirstName,
					guarantorLastName: values.guarantorLastName,
					guarantorAddress1: values.guarantorAddressLine1,
					guarantorAddress2: values.guarantorAddressLine2,
					guarantorCity: values?.guarantorCity?.label
						? values?.guarantorCity?.label
						: "",
					guarantorSpousePhone: values.guarantorSpousePartnerPhone,
					guarantorRelationship:
						values.guarantorRelationshipToPatient,
					guarantorSpouseName: values.guarantorSpouseOrParentsName,
					guarantorState: values?.guarantorState?.value,
					guarantorZip: values.guarantorZipCode,
				},
				lastName: values.lastName,
				middleName: values.middleName,
				notes: values.notes,
				phoneNumber: values.homePhone,
				cellNumber: values.cellPhone,
				accountNumber: values.accountNumber,
				primary: {
					subscriberFirstName: values.primarySubscriberFirstName,
					subscriberLastName: values.primarySubscriberLastName,
					subscriberDob: values.primarySubscriberDOB,
					subscriberGroupNumber: values.primaryGroupNumber,
					subscriberMemberNumber: values.primaryMemberNumber,
				},
				primaryInsuranceId: values?.primaryInsuranceId?.value
					? values?.primaryInsuranceId?.value
					: "",
				responsibleFirstName: values.responsibleFirstName,
				responsibleLastName: values.responsibleLastName
					? values.responsibleLastName
					: "",
				responsiblePhone: values.responsiblePhone
					? values.responsiblePhone
					: "",
				secondary: {
					subscriberFirstName: values.secondarySubscriberFirstName
						? values.secondarySubscriberFirstName
						: "",
					subscriberLastName: values.secondarySubscriberLastName
						? values.secondarySubscriberLastName
						: "",
					subscriberDob: values.secondarySubscriberDOB
						? values.secondarySubscriberDOB
						: "",
					subscriberGroupNumber: values?.secondaryGroupNumber,
					subscriberMemberNumber: values.secondaryMemberNumber
						? values.secondaryMemberNumber
						: "",
				},
				secondaryInsuranceId: values?.secondaryInsuranceId?.value
					? values?.secondaryInsuranceId?.value
					: "",
				tertiaryInsuranceId: values?.tertiaryInsuranceId?.value
					? values?.tertiaryInsuranceId?.value
					: "",
				ssn: values.ssn ? values.ssn : "",
				state: values?.state?.value || "",
				tertiary: {
					subscriberFirstName: values.tertiarySubscriberFirstName
						? values.tertiarySubscriberFirstName
						: "",
					subscriberLastName: values.tertiarySubscriberLastName
						? values.tertiarySubscriberLastName
						: "",
					subscriberDob: values.tertiarySubscriberDOB
						? values.tertiarySubscriberDOB
						: "",
					subscriberGroupNumber: values.tertiaryGroupNumber
						? values.tertiaryGroupNumber
						: "",
					subscriberMemberNumber: values.tertiaryMemberNumber
						? values.tertiaryMemberNumber
						: "",
				},
				workerComp: {
					patientCondition: values.patientCondition
						? values.patientCondition
						: "",
					employerName: values.employerName
						? values.employerName
						: "",
					dateOfInjury: values.dateOfInjury
						? values.dateOfInjury
						: "",
					illnessIndicator: values.illnessIndicator
						? values.illnessIndicator
						: "",
				},
				zip: values.zipCode ? values.zipCode : "",
			};
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/patient/${id}`,
				{
					method: "PUT",
					body: payload,
				}
			);
			if (response.responseStatus === true) {
				setStatus(
					"You have successfully updated a patient, thank you."
				);
				addToastMessage({
					status: true,
					message: `You have successfully updated a patient, thank you.`,
				});
				if (nextUrl) {
					navigate(nextUrl);
				} else {
					navigate("/patients");
				}
				return true;
			} else {
				setStatus(
					`${response.responseDescription || "Something went wrong, please try again later."}`
				);
				addToastMessage({
					status: false,
					message: `${response.responseDescription || "Something went wrong, please try again later."}`,
				});
				return false;
			}
		} catch (e) {
			console.error("Error:", e);
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
			return false;
		} finally {
			setLoading(false);
		}
	};

	const labelInsuranceFunc = (o) => `${o.description}`;
	const labelCountryFunc = (o) => `${o.name}`;
	const labelStateFunc = (o) => `${o.name}`;
	const labelCityFunc = (o) => `${o.name}`;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				const api = await updatePatient(values, id);
				if (api) {
					resetForm();
				}
			}}>
			{({
				values,
				setFieldValue,
				errors,
				setValues,
				touched,
				handleSubmit,
				validateForm,
				setFieldTouched,
			}) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td width='50%'>
										<Label
											title='First Name'
											required={true}
										/>
										<Field
											type='text'
											placeholder='Enter First Name '
											name='firstName'
											className={`col-1-1 primary ${
												errors.firstName &&
												touched.firstName
													? "error"
													: ""
											}`}
										/>
										<ErrorMessage
											name='firstName'
											component='p'
											className='error-messages'
										/>
									</td>
									<td width='50%'>
										<Label title='Middle Name' />
										<Field
											type='text'
											placeholder='Enter Middle Name '
											name='middleName'
											className={`col-1-1 primary ${
												errors.middleName &&
												touched.middleName
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label
											title='Last Name'
											required={true}
										/>
										<Field
											type='text'
											placeholder='Enter Last Name '
											name='lastName'
											className={`col-1-1 primary ${
												errors.lastName &&
												touched.lastName
													? "error"
													: ""
											}`}
										/>
										<ErrorMessage
											name='lastName'
											component='p'
											className='error-messages'
										/>
									</td>
									<td>
										<Label title='Gender' required={true} />
										<Field
											as='select'
											placeholder='Select Gender'
											name='gender'
											className={`col-1-1 primary ${
												errors.gender && touched.gender
													? "error"
													: ""
											}`}>
											<option value=''>
												Select Gender
											</option>
											{["Male", "Female", "Other"].map(
												(d, i) => (
													<option value={d} key={i}>
														{" "}
														{d}
													</option>
												)
											)}
										</Field>
										<ErrorMessage
											name='gender'
											component='p'
											className='error-messages'
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='Phone Number (Work)' />
										<PhoneNumberInput
											name='workPhone'
											placeholder='Eg: 888-888-8888'
											error={
												errors.workPhone &&
												touched.workPhone
											}
											value={values.workPhone}
											setTouched={() =>
												setFieldTouched(
													"workPhone",
													true
												)
											}
											callback={(v) =>
												setFieldValue("workPhone", v)
											}
										/>
										<ErrorMessage
											name='workPhone'
											component='p'
											className='error-messages'
										/>
									</td>
									<td>
										<Label title='Phone Number (Home)' />
										<PhoneNumberInput
											name='homePhone'
											placeholder='Eg: 888-888-8888'
											error={
												errors.homePhone &&
												touched.homePhone
											}
											value={values.homePhone}
											setTouched={() =>
												setFieldTouched(
													"homePhone",
													true
												)
											}
											callback={(v) =>
												setFieldValue("homePhone", v)
											}
										/>
										<ErrorMessage
											name='homePhone'
											component='p'
											className='error-messages'
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='Cell Phone' />
										<PhoneNumberInput
											name='cellPhone'
											placeholder='Eg: 888-888-8888'
											error={
												errors.cellPhone &&
												touched.cellPhone
											}
											value={values.cellPhone}
											setTouched={() =>
												setFieldTouched(
													"cellPhone",
													true
												)
											}
											callback={(v) =>
												setFieldValue("cellPhone", v)
											}
										/>
										<ErrorMessage
											name='cellPhone'
											component='p'
											className='error-messages'
										/>
									</td>
									<td>
										<Label title='Patient Chart No.' />
										<Field
											type='text'
											placeholder='Eg: RTY907'
											name='patientChartNo'
											className={`col-1-1 primary ${
												errors.patientChartNo &&
												touched.patientChartNo
													? "error"
													: ""
											}`}
										/>
									</td>
									{/* <td>
                    <Label title="Fax" />
                    <Field
                      type="text"
                      placeholder="Enter Fax"
                      name="fax"
                      className={`col-1-1 primary ${
                        errors.fax && touched.fax ? "error" : ""
                      }`}
                    />
                  </td> */}
								</tr>
								<tr>
									<td width='50%'>
										<Label
											title='Account #'
											required={true}
										/>
										<Field
											type='text'
											placeholder='Enter Account #'
											name='accountNumber'
											className={`col-1-1 primary ${
												errors.accountNumber &&
												touched.accountNumber
													? "error"
													: ""
											}`}
										/>
										<ErrorMessage
											name='accountNumber'
											component='p'
											className='error-messages'
										/>
									</td>
									<td>
										<Label title='Email Address' />
										<Field
											type='text'
											placeholder='Enter Email Address'
											name='email'
											className={`col-1-1 primary ${
												errors.email && touched.email
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='DOB' required={true} />
										<DatePickerInput
											callback={(v) =>
												setFieldValue("dob", v)
											}
											value={values.dob}
											error={errors.dob && touched.dob}
											setTouched={() =>
												setFieldTouched("dob", true)
											}
										/>
										<ErrorMessage
											name='dob'
											component='p'
											className='error-messages'
										/>
									</td>
									<td>
										<Label title='Social Security number' />
										<Field
											type='text'
											placeholder='Enter Social Security number'
											name='ssn'
											className={`col-1-1 primary ${
												errors.ssn && touched.ssn
													? "error"
													: ""
											}`}
										/>
										<ErrorMessage
											name='ssn'
											component='p'
											className='error-messages'
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='Date of Service' />
										<DatePickerInput
											callback={(v) =>
												setFieldValue("serviceDate", v)
											}
											value={values.serviceDate}
											error={
												errors.serviceDate &&
												touched.serviceDate
											}
											setTouched={() =>
												setFieldTouched(
													"serviceDate",
													true
												)
											}
										/>
									</td>
									{/* <td>
                    <Label title="Patient Chart No." />
                    <Field
                      type="text"
                      placeholder="Eg: RTY907"
                      name="patientChartNo"
                      className={`col-1-1 primary ${
                        errors.patientChartNo && touched.patientChartNo
                          ? "error"
                          : ""
                      }`}
                    />
                  </td> */}
								</tr>
								<tr>
									<td colSpan='2'>
										<Label title='Address Line 1' />
										<Field
											type='textarea'
											placeholder='Enter Address Line 1'
											name='address1'
											className={`col-1-1 primary ${
												errors.address1 &&
												touched.address1
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan='2'>
										<Label title='Address Line 2' />
										<Field
											type='textarea'
											placeholder='Enter Address Line 2'
											name='address2'
											className={`col-1-1 primary ${
												errors.address2 &&
												touched.address2
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label
											title='Zip Code'
											required={true}
										/>
										<ZipCodeInput
											name='zipCode'
											clearFields={() => {
												setFieldValue("state", null);
												setFieldValue("city", null);
											}}
											onZipDetailsFetched={(data) => {
												setIsZipDataFilled(true);
												setFieldValue("country", {
													value: data.country.code,
													label: data.country.name,
													obj: data.country,
												});
												setFieldValue("state", {
													value: data.state.code,
													label: data.state.name,
													obj: data.state,
												});
												setFieldValue("city", {
													value: data.city.code,
													label: data.city.name,
													obj: data.city,
												});
											}}
										/>
									</td>
									<td>
										<Label
											title='Country'
											required={true}
										/>
										<PaginatedDropdown
											datakey='records'
											url={`${API_URL}/api/1.0.0/reference/countries?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"code",
													labelCountryFunc
												)
											}
											error={
												errors.country &&
												touched.country
											}
											setTouched={() =>
												setFieldTouched("country", true)
											}
											selectedValue={values?.country}
											placeHolder='Search'
											onChange={async (v) => {
												if (isZipDataFilled)
													setFieldValue(
														"zipCode",
														""
													);
												setIsZipDataFilled(false);
												await setFieldValue(
													"country",
													v
												);
												await setFieldValue(
													"state",
													null
												);
												await setFieldValue(
													"city",
													null
												);
												await validateForm();
											}}
										/>
										<ErrorMessage
											name='country'
											component='p'
											className='error-messages'
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label title='State' />
										<PaginatedDropdown
											datakey='records'
											url={`${API_URL}/api/1.0.0/reference/states/${values.country?.value}?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"code",
													labelStateFunc
												)
											}
											error={
												errors.state && touched.state
											}
											setTouched={() =>
												setFieldTouched("state", true)
											}
											placeHolder='Search'
											selectedValue={values?.state}
											onChange={(v) => {
												if (isZipDataFilled)
													setFieldValue(
														"zipCode",
														""
													);
												setIsZipDataFilled(false);
												setFieldValue("state", v);
												setFieldValue("city", null);
											}}
										/>
									</td>
									<td width='50%'>
										<Label title='City' />
										<PaginatedDropdown
											datakey='records'
											url={`${API_URL}/api/1.0.0/reference/cities/${values.country?.value}/${values.state?.value}?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"name",
													labelCityFunc
												)
											}
											error={errors.city && touched.city}
											setTouched={() =>
												setFieldTouched("city", true)
											}
											selectedValue={values?.city}
											placeHolder='Search'
											onChange={(v) => {
												if (isZipDataFilled)
													setFieldValue(
														"zipCode",
														""
													);
												setIsZipDataFilled(false);
												setFieldValue("city", v);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan='2'>
										<Label title='Notes' />
										<Field
											as='textarea'
											placeholder='Enter Notes'
											name='notes'
											className={`col-1-1 primary ${
												errors.notes && touched.notes
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan='4'>
										<Accordion
											activeIndicesVal={[
												// Primary Subscriber Data
												PatientData
													?.primarySubscriberData
													?.subscriberFirstName ||
													PatientData
														?.primarySubscriberData
														?.subscriberLastName ||
													PatientData
														?.primarySubscriberData
														?.subscriberMemberNumber ||
													PatientData
														?.primarySubscriberData
														?.subscriberGroupNumber ||
													PatientData
														?.primarySubscriberData
														?.subscriberDob ||
													PatientData
														?.primaryInsurance?.id,

												// Guarantor Data
												PatientData?.guarantor
													?.guarantorId ||
													PatientData?.guarantor
														?.guarantorFirstName ||
													PatientData?.guarantor
														?.guarantorLastName ||
													PatientData?.guarantor
														?.guarantorAddress1 ||
													PatientData?.guarantor
														?.guarantorAddress2 ||
													PatientData?.guarantor
														?.guarantorZip ||
													PatientData?.guarantor
														?.guarantorState
														?.code ||
													PatientData?.guarantor
														?.guarantorCity?.code ||
													PatientData?.guarantor
														?.guarantorSpouseName ||
													PatientData?.guarantor
														?.guarantorSpousePhone ||
													PatientData?.guarantor
														?.guarantorRelationship,

												// Secondary Subscriber Data
												PatientData
													?.secondarySubscriberData
													?.subscriberFirstName ||
													PatientData
														?.secondarySubscriberData
														?.subscriberLastName ||
													PatientData
														?.secondarySubscriberData
														?.subscriberMemberNumber ||
													PatientData
														?.secondarySubscriberData
														?.subscriberGroupNumber ||
													PatientData
														?.secondarySubscriberData
														?.subscriberDob ||
													PatientData
														?.secondaryInsurance
														?.id,

												// Tertiary Subscriber Data
												PatientData
													?.tertiarySubscriberData
													?.subscriberFirstName ||
													PatientData
														?.tertiarySubscriberData
														?.subscriberLastName ||
													PatientData
														?.tertiarySubscriberData
														?.subscriberMemberNumber ||
													PatientData
														?.tertiarySubscriberData
														?.subscriberGroupNumber ||
													PatientData
														?.tertiarySubscriberData
														?.subscriberDob ||
													PatientData
														?.tertiaryInsurance?.id,

												// Worker Compensation Data
												PatientData?.workerComp
													?.patientCondition ||
													PatientData?.workerComp
														?.employerName ||
													PatientData?.workerComp
														?.illnessIndicator ||
													PatientData?.workerComp
														?.dateOfInjury,
											].reduce((acc, value, index) => {
												// If the group is truthy, add its 1-based index
												if (value) acc.push(index);
												return acc;
											}, [])}
											items={[
												{
													title: (
														<span>
															 <IoShieldOutline />
															<span>
																Primary
																Insurance
															</span>
														</span>
													),
													content: (
														<div className='pad-20'>
															<table>
																<tbody>
																	<tr>
																		<td colSpan='2'>
																			<ButtonLightTextIcon
																				type='button'
																				title='Remove Payer'
																				onClick={() => {
																					setValues(
																						(
																							prevValues
																						) => ({
																							...prevValues,
																							primarySubscriberFirstName:
																								"",
																							primarySubscriberLastName:
																								"",
																							primaryMemberNumber:
																								"",
																							primaryInsuranceFax:
																								"",
																							primaryGroupNumber:
																								"",
																							primarySubscriberDOB:
																								"",
																							primaryInsuranceId:
																								null,
																							primaryInsuranceName:
																								"",
																							primaryPayerID:
																								"",
																							primaryMasterPayerID:
																								"",
																							primaryInsuranceTypeCode:
																								"",
																							primaryInsuranceAddress:
																								"",
																							primaryInsurancePhone:
																								"",
																							primaryInsuranceEmail:
																								"",
																							primaryInsuranceCity:
																								"",
																							primaryInsuranceState:
																								"",
																							primaryInsuranceZip:
																								"",
																							primaryInsuranceCountry:
																								"",
																							primaryBillableType:
																								"",
																						})
																					);
																				}}
																			/>
																		</td>
																	</tr>
																	{/* <tr>
                                    <td width="50%">
                                      <Label title="Billable / Non-Billable / Non-Billable Commercial" />
                                      <Field
                                        as="select"
                                        name="primaryBillableType"
                                        className={`col-1-1 primary ${
                                          errors.primaryBillableType &&
                                          touched.primaryBillableType
                                            ? "error"
                                            : ""
                                        }`}
                                      >
                                        <option value="">Select</option>
                                        {BILLING_LISTING_OPTIONS.map((d, i) => (
                                          <option value={d.value} key={i}>
                                            {d.label}
                                          </option>
                                        ))}
                                      </Field>
                                    </td>
                                  </tr> */}
																	<tr>
																		<td width='50%'>
																			<Label title='Primary Insurance Name' />
																			<CustomModal
																				selector={
																					<div>
																						<Field
																							type='text'
																							placeholder='Select Insurance'
																							name='primaryInsuranceName'
																							value={
																								values.primaryInsuranceName
																							}
																							className={`col-1-1 primary cursor-pointer ${
																								errors.primaryInsuranceId &&
																								touched.primaryInsuranceId
																									? "error"
																									: ""
																							}`}
																						/>
																						<ErrorMessage
																							name='primaryInsuranceId'
																							component='p'
																							className='error-messages'
																						/>
																					</div>
																				}
																				content={
																					<InsuranceSelect
																						setTouched={async () => {
																							await setFieldTouched(
																								"primaryInsuranceName",
																								true
																							);
																						}}
																						callback={async (
																							v
																						) => {
																							console.log(
																								"Insurance Selected:::",
																								v
																							);
																							if (
																								v
																							) {
																								await setFieldValue(
																									"primaryInsuranceId",
																									v
																								);
																								await setFieldValue(
																									"primaryInsuranceName",
																									v.label
																								);
																								await setFieldValue(
																									"primaryPayerID",
																									v
																										.obj
																										.electronicPayerId
																								);
																								await setFieldValue(
																									"primaryMasterPayerID",
																									v
																										.obj
																										.masterPayerId
																								);
																								await setFieldValue(
																									"primaryInsuranceTypeCode",
																									v
																										.obj
																										.insuranceType
																								);
																								await setFieldValue(
																									"primaryInsuranceAddress",
																									v
																										.obj
																										.addressLine1
																								);
																								const phoneContact =
																									v.obj.insuranceContactDetails.find(
																										(
																											contact
																										) =>
																											contact.type ===
																											"Phone"
																									);
																								await setFieldValue(
																									"primaryInsurancePhone",
																									phoneContact
																										? phoneContact.information
																										: ""
																								); // Default to empty if no phone contact found

																								// Handle insuranceContactDetails for "Fax"
																								const faxContact =
																									v.obj.insuranceContactDetails.find(
																										(
																											contact
																										) =>
																											contact.type ===
																											"Fax"
																									);
																								await setFieldValue(
																									"primaryInsuranceFax",
																									faxContact
																										? faxContact.information
																										: ""
																								);
																							} else {
																								// If no value is selected (deselected), clear the values
																								await setFieldValue(
																									"primaryInsuranceId",
																									""
																								);
																								await setFieldValue(
																									"primaryInsuranceName",
																									""
																								);
																								await setFieldValue(
																									"primaryPayerID",
																									""
																								);
																								await setFieldValue(
																									"primaryMasterPayerID",
																									""
																								);
																								await setFieldValue(
																									"primaryInsuranceTypeCode",
																									""
																								);
																								await setFieldValue(
																									"primaryInsuranceAddress",
																									""
																								);
																								await setFieldValue(
																									"primaryInsurancePhone",
																									""
																								);
																								await setFieldValue(
																									"primaryInsuranceFax",
																									""
																								);
																							}
																						}}
																					/>
																				}
																			/>
																		</td>
																		<td width='50%'>
																			<Label title='Primary Electronic Payer ID' />
																			<LikeInput
																				title={
																					values.primaryPayerID ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Master Payer ID' />
																			<LikeInput
																				title={
																					values.primaryMasterPayerID ||
																					"-"
																				}
																			/>
																		</td>
																		<td>
																			<Label title='Insurance Type Code' />
																			<LikeInput
																				title={
																					values.primaryInsuranceTypeCode ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Primary Insurance Phone' />
																			<LikeInput
																				title={
																					values.primaryInsurancePhone ||
																					"-"
																				}
																			/>
																		</td>
																		<td>
																			<Label title='Primary Insurance Fax' />
																			<LikeInput
																				title={
																					values.primaryInsuranceFax ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td colSpan='2'>
																			<Label title='Primary Insurance Address' />
																			<LikeInput
																				title={
																					values.primaryInsuranceAddress ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<ButtonTextIcon
																				title='Fill Form With Patient Info'
																				type='button'
																				onClick={() => {
																					setValues(
																						(
																							prevValues
																						) => ({
																							...prevValues, // Spread the existing form values
																							primarySubscriberFirstName:
																								values.firstName,
																							primarySubscriberLastName:
																								values.lastName,
																							primarySubscriberDOB:
																								values.dob,
																						})
																					);
																				}}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Subscriber First Name' />
																			<Field
																				type='text'
																				placeholder='Enter Subscriber First Name'
																				name='primarySubscriberFirstName'
																				className={`col-1-1 primary ${
																					errors.primarySubscriberFirstName &&
																					touched.primarySubscriberFirstName
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																		<td>
																			<Label title='Subscriber Last Name' />
																			<Field
																				type='text'
																				placeholder='Enter Subscriber Last Name'
																				name='primarySubscriberLastName'
																				className={`col-1-1 primary ${
																					errors.primarySubscriberLastName &&
																					touched.primarySubscriberLastName
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Member Number' />
																			<Field
																				type='text'
																				placeholder='Enter Member Number'
																				name='primaryMemberNumber'
																				className={`col-1-1 primary ${
																					errors.primaryMemberNumber &&
																					touched.primaryMemberNumber
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																		<td>
																			<Label title='Group Number' />
																			<Field
																				type='text'
																				placeholder='Enter Group Number'
																				name='primaryGroupNumber'
																				className={`col-1-1 primary ${
																					errors.primaryGroupNumber &&
																					touched.primaryGroupNumber
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Subscriber DOB' />
																			<DatePickerInput
																				callback={(
																					v
																				) =>
																					setFieldValue(
																						"primarySubscriberDOB",
																						v
																					)
																				}
																				value={
																					values.primarySubscriberDOB
																				}
																				error={
																					errors.primarySubscriberDOB &&
																					touched.primarySubscriberDOB
																				}
																				setTouched={() =>
																					setFieldTouched(
																						"primarySubscriberDOB",
																						true
																					)
																				}
																			/>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													),
												},
												{
													title: (
														<span>
														 <IoShieldOutline />
															<span>
																Guarantor
															</span>
														</span>
													),
													content: (
														<div className='pad-20'>
															<table>
																<tbody>
																	<tr>
																		<td colSpan='2'>
																			<div
																				className='flex'
																				style={{
																					gap: "10px",
																				}}>
																				<ButtonLightTextIcon
																					type='button'
																					title='Remove Payer'
																					onClick={() => {
																						setValues(
																							(
																								prevValues
																							) => ({
																								...prevValues,
																								guarantorFirstName:
																									"",
																								guarantorLastName:
																									"",
																								guarantorAddressLine1:
																									"",
																								guarantorAddressLine2:
																									"",
																								guarantorZipCode:
																									"",
																								guarantorState:
																									"",
																								guarantorCity:
																									"",
																								guarantorSpouseOrParentsName:
																									"",
																								guarantorSpousePartnerPhone:
																									"",
																								guarantorRelationshipToPatient:
																									"",
																							})
																						);
																					}}
																				/>

																				<ButtonTextIcon
																					type='button'
																					title='Fill Form With Patient Info'
																					onClick={() => {
																						setValues(
																							(
																								prevValues
																							) => ({
																								...prevValues,
																								...(values
																									?.country
																									?.value ===
																									"US" && {
																									guarantorFirstName:
																										values.firstName,
																									guarantorLastName:
																										values.lastName,
																									guarantorAddressLine1:
																										values.address1,
																									guarantorAddressLine2:
																										values.address2,

																									guarantorState:
																										values.state,
																									guarantorCity:
																										values.city,
																									guarantorZipCode:
																										values.zipCode,
																								}),
																							})
																						);
																					}}
																				/>
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Guarantor First Name' />
																			<Field
																				type='text'
																				placeholder='Enter Guarantor First Name'
																				name='guarantorFirstName'
																				className={`col-1-1 primary ${
																					errors.guarantorFirstName &&
																					touched.guarantorFirstName
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																		<td width='50%'>
																			<Label title='Guarantor Last Name' />
																			<Field
																				type='text'
																				placeholder='Enter Guarantor Last Name'
																				name='guarantorLastName'
																				className={`col-1-1 primary ${
																					errors.guarantorLastName &&
																					touched.guarantorLastName
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Address Line 1' />
																			<Field
																				type='text'
																				placeholder='Enter Address Line 1'
																				name='guarantorAddressLine1'
																				className={`col-1-1 primary ${
																					errors.guarantorAddressLine1 &&
																					touched.guarantorAddressLine1
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																		<td width='50%'>
																			<Label title='Address Line 2' />
																			<Field
																				type='text'
																				placeholder='Address Line 2'
																				name='guarantorAddressLine2'
																				className={`col-1-1 primary ${
																					errors.guarantorAddressLine2 &&
																					touched.guarantorAddressLine2
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Zip Code' />
																			<ZipCodeInput
																				name='guarantorZipCode'
																				clearFields={() => {
																					setFieldValue(
																						"guarantorState",
																						null
																					);
																					setFieldValue(
																						"guarantorCity",
																						null
																					);
																				}}
																				onZipDetailsFetched={(
																					data
																				) => {
																					if (
																						data
																							.country
																							.code !==
																						"US"
																					)
																						return;
																					setIsGuarantorZipDataFilled(
																						true
																					);
																					setFieldValue(
																						"guarantorState",
																						{
																							value: data
																								.state
																								.code,
																							label: data
																								.state
																								.name,
																							obj: data.state,
																						}
																					);
																					setFieldValue(
																						"guarantorCity",
																						{
																							value: data
																								.city
																								.code,
																							label: data
																								.city
																								.name,
																							obj: data.city,
																						}
																					);
																				}}
																			/>
																		</td>
																		<td width='50%'>
																			<Label title='State' />
																			<PaginatedDropdown
																				datakey='records'
																				url={`${API_URL}/api/1.0.0/reference/states/US?`}
																				mapOptions={(
																					records
																				) =>
																					mapOptions(
																						records,
																						"code",
																						labelStateFunc
																					)
																				}
																				error={
																					errors.guarantorState &&
																					touched.guarantorState
																				}
																				setTouched={() =>
																					setFieldTouched(
																						"guarantorState",
																						true
																					)
																				}
																				placeHolder='Search'
																				selectedValue={
																					values.guarantorState
																				}
																				onChange={(
																					v
																				) => {
																					if (
																						isGuarantorZipDataFilled
																					)
																						setFieldValue(
																							"guarantorZipCode",
																							""
																						);
																					setIsGuarantorZipDataFilled(
																						false
																					);
																					setFieldValue(
																						"guarantorState",
																						v
																					);
																					setFieldValue(
																						"guarantorCity",
																						null
																					);
																				}}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='City' />
																			<PaginatedDropdown
																				datakey='records'
																				url={`${API_URL}/api/1.0.0/reference/cities/US/${values.guarantorState?.value}?`}
																				mapOptions={(
																					records
																				) =>
																					mapOptions(
																						records,
																						"name",
																						labelCityFunc
																					)
																				}
																				error={
																					errors.guarantorCity &&
																					touched.guarantorCity
																				}
																				setTouched={() =>
																					setFieldTouched(
																						"guarantorCity",
																						true
																					)
																				}
																				placeHolder='Search'
																				selectedValue={
																					values.guarantorCity
																				}
																				onChange={(
																					v
																				) => {
																					if (
																						isGuarantorZipDataFilled
																					)
																						setFieldValue(
																							"guarantorZipCode",
																							""
																						);
																					setIsGuarantorZipDataFilled(
																						false
																					);
																					setFieldValue(
																						"guarantorCity",
																						v
																					);
																				}}
																			/>
																		</td>
																		<td width='50%'>
																			<Label
																				style={{
																					fontSize:
																						"11px",
																				}}
																				title="Spouse or Partner's/Parents Name(if patient minor)"
																			/>
																			<Field
																				type='text'
																				placeholder='Enter Spouse or Parents Name'
																				name='guarantorSpouseOrParentsName'
																				className={`col-1-1 primary ${
																					errors.guarantorSpouseOrParentsName &&
																					touched.guarantorSpouseOrParentsName
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Spouse, Partner or Partners Phone' />
																			<PhoneNumberInput
																				name='guarantorSpousePartnerPhone'
																				placeholder='Eg: 888-888-8888'
																				error={
																					errors.guarantorSpousePartnerPhone &&
																					touched.guarantorSpousePartnerPhone
																				}
																				value={
																					values.guarantorSpousePartnerPhone
																				}
																				setTouched={() =>
																					setFieldTouched(
																						"guarantorSpousePartnerPhone",
																						true
																					)
																				}
																				callback={(
																					v
																				) =>
																					setFieldValue(
																						"guarantorSpousePartnerPhone",
																						v
																					)
																				}
																			/>
																			<ErrorMessage
																				name='guarantorSpousePartnerPhone'
																				component='p'
																				className='error-messages'
																			/>
																		</td>
																		<td width='50%'>
																			<Label title='Relationship To Patient' />
																			<Field
																				type='text'
																				placeholder='Enter Relationship To Patient'
																				name='guarantorRelationshipToPatient'
																				className={`col-1-1 primary ${
																					errors.guarantorRelationshipToPatient &&
																					touched.guarantorRelationshipToPatient
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													),
												},
												{
													title: (
														<span>
															
															 <IoShieldOutline />
															<span>
																Secondary
																Insurance
															</span>
														</span>
													),
													content: (
														<div className='pad-20'>
															<table>
																<tbody>
																	<tr>
																		<td colSpan='2'>
																			<ButtonLightTextIcon
																				type='button'
																				title='Remove Payer'
																				onClick={() => {
																					setValues(
																						(
																							prevValues
																						) => ({
																							...prevValues,
																							secondarySubscriberFirstName:
																								"",
																							secondarySubscriberLastName:
																								"",
																							secondaryMemberNumber:
																								"",
																							secondaryGroupNumber:
																								"",
																							secondarySubscriberDOB:
																								"",
																							secondaryInsuranceId:
																								null,
																							secondaryInsuranceName:
																								"",
																							secondaryInsuranceAddress:
																								"",
																							secondaryInsuranceCity:
																								"",
																							secondaryInsuranceState:
																								"",
																							secondaryInsuranceZip:
																								"",
																							secondaryInsurancePhone:
																								"",
																							secondaryInsuranceFax:
																								"",
																							secondaryMasterPayerID:
																								"",
																							secondaryPayerID:
																								"",
																							secondaryInsuranceTypeCode:
																								"",
																							secondaryBillableType:
																								"",
																						})
																					);
																				}}
																			/>
																		</td>
																	</tr>
																	{/* <tr>
                                    <td width="50%">
                                      <Label title="Billable / Non-Billable / Non-Billable Commercial" />
                                      <Field
                                        as="select"
                                        name="secondaryBillableType"
                                        className={`col-1-1 primary ${
                                          errors.secondaryBillableType &&
                                          touched.secondaryBillableType
                                            ? "error"
                                            : ""
                                        }`}
                                      >
                                        <option value="">Select</option>
                                        {BILLING_LISTING_OPTIONS.map((d, i) => (
                                          <option value={d.value} key={i}>
                                            {d.label}
                                          </option>
                                        ))}
                                      </Field>
                                    </td>
                                  </tr> */}
																	<tr>
																		<td>
																			<Label title='Secondary Insurance Name' />
																			<CustomModal
																				selector={
																					<div>
																						<Field
																							type='text'
																							placeholder='Select Insurance'
																							name='secondaryInsuranceName'
																							value={
																								values.secondaryInsuranceName
																							}
																							className={`col-1-1 primary ${
																								errors.secondaryInsuranceId &&
																								touched.secondaryInsuranceId
																									? "error"
																									: ""
																							}`}
																						/>
																						<ErrorMessage
																							name='secondaryInsuranceId'
																							component='p'
																							className='error-messages'
																						/>
																					</div>
																				}
																				content={
																					<InsuranceSelect
																						setTouched={() =>
																							setFieldTouched(
																								"secondaryInsuranceId",
																								true
																							)
																						}
																						callback={async (
																							v
																						) => {
																							if (
																								v
																							) {
																								await setFieldValue(
																									"secondaryInsuranceId",
																									v
																								);
																								await setFieldValue(
																									"secondaryInsuranceName",
																									v.label
																								);
																								await setFieldValue(
																									"secondaryPayerID",
																									v
																										.obj
																										.electronicPayerId
																								);
																								await setFieldValue(
																									"secondaryMasterPayerID",
																									v
																										.obj
																										.masterPayerId
																								);
																								await setFieldValue(
																									"secondaryInsuranceTypeCode",
																									v
																										.obj
																										.insuranceType
																								);
																								await setFieldValue(
																									"secondaryInsuranceAddress",
																									v
																										.obj
																										.addressLine1
																								);
																								const phoneContact =
																									v.obj.insuranceContactDetails.find(
																										(
																											contact
																										) =>
																											contact.type ===
																											"Phone"
																									);
																								await setFieldValue(
																									"secondaryInsurancePhone",
																									phoneContact
																										? phoneContact.information
																										: ""
																								); // Default to empty if no phone contact found

																								// Handle insuranceContactDetails for "Fax"
																								const faxContact =
																									v.obj.insuranceContactDetails.find(
																										(
																											contact
																										) =>
																											contact.type ===
																											"Fax"
																									);
																								await setFieldValue(
																									"secondaryInsuranceFax",
																									faxContact
																										? faxContact.information
																										: ""
																								);
																							} else {
																								await setFieldValue(
																									"secondaryInsuranceId",
																									""
																								);
																								await setFieldValue(
																									"secondaryInsuranceName",
																									""
																								);
																								await setFieldValue(
																									"secondaryPayerID",
																									""
																								);
																								await setFieldValue(
																									"secondaryMasterPayerID",
																									""
																								);
																								await setFieldValue(
																									"secondaryInsuranceTypeCode",
																									""
																								);
																								await setFieldValue(
																									"secondaryInsuranceAddress",
																									""
																								);
																								await setFieldValue(
																									"secondaryInsurancePhone",
																									""
																								);
																								await setFieldValue(
																									"secondaryInsuranceFax",
																									""
																								);
																							}
																						}}
																					/>
																				}
																			/>
																		</td>
																		<td>
																			<Label title='Secondary Payer ID' />
																			<LikeInput
																				title={
																					values.secondaryPayerID ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Master Payer ID' />
																			<LikeInput
																				title={
																					values.secondaryMasterPayerID ||
																					"-"
																				}
																			/>
																		</td>
																		<td>
																			<Label title='Insurance Type Code' />
																			<LikeInput
																				title={
																					values.secondaryInsuranceTypeCode ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Secondary Insurance Phone' />
																			<LikeInput
																				title={
																					values.secondaryInsurancePhone ||
																					"-"
																				}
																			/>
																		</td>
																		<td>
																			<Label title='Secondary Insurance Fax' />
																			<LikeInput
																				title={
																					values.secondaryInsuranceFax ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td colSpan='2'>
																			<Label title='Secondary Insurance Address' />
																			<LikeInput
																				title={
																					values.secondaryInsuranceAddress ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<ButtonTextIcon
																				title='Fill Form With Patient Info'
																				type='button'
																				onClick={() => {
																					setValues(
																						(
																							prevValues
																						) => ({
																							...prevValues, // Spread the existing form values
																							secondarySubscriberFirstName:
																								values.firstName,
																							secondarySubscriberLastName:
																								values.lastName,
																							secondarySubscriberDOB:
																								values.dob,
																						})
																					);
																				}}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Subscriber First Name' />
																			<Field
																				type='text'
																				placeholder='Enter Subscriber First Name'
																				name='secondarySubscriberFirstName'
																				className={`col-1-1 primary ${
																					errors.secondarySubscriberFirstName &&
																					touched.secondarySubscriberFirstName
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																		<td>
																			<Label title='Subscriber Last Name' />
																			<Field
																				type='text'
																				placeholder='Enter Subscriber Last Name'
																				name='secondarySubscriberLastName'
																				className={`col-1-1 primary ${
																					errors.secondarySubscriberLastName &&
																					touched.secondarySubscriberLastName
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Member Number' />
																			<Field
																				type='text'
																				placeholder='Enter Member Number'
																				name='secondaryMemberNumber'
																				className={`col-1-1 primary ${
																					errors.secondaryMemberNumber &&
																					touched.secondaryMemberNumber
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																		<td>
																			<Label title='Group Number' />
																			<Field
																				type='text'
																				placeholder='Enter Group Number'
																				name='secondaryGroupNumber'
																				className={`col-1-1 primary ${
																					errors.secondaryGroupNumber &&
																					touched.secondaryGroupNumber
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Subscriber DOB' />
																			<DatePickerInput
																				callback={(
																					v
																				) =>
																					setFieldValue(
																						"secondarySubscriberDOB",
																						v
																					)
																				}
																				value={
																					values.secondarySubscriberDOB
																				}
																				error={
																					errors.secondarySubscriberDOB &&
																					touched.secondarySubscriberDOB
																				}
																				setTouched={() =>
																					setFieldTouched(
																						"secondarySubscriberDOB",
																						true
																					)
																				}
																			/>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													),
												},
												{
													title: (
														<span>
															
															 <IoShieldOutline />
															<span>
																Tertiary
																Insurance
															</span>
														</span>
													),
													content: (
														<div className='pad-20'>
															<table>
																<tbody>
																	<tr>
																		<td colSpan='2'>
																			<ButtonLightTextIcon
																				type='button'
																				title='Remove Payer'
																				onClick={() => {
																					setValues(
																						(
																							prevValues
																						) => ({
																							...prevValues,
																							tertiarySubscriberFirstName:
																								"",
																							tertiarySubscriberLastName:
																								"",
																							tertiaryMemberNumber:
																								"",
																							tertiaryGroupNumber:
																								"",
																							tertiarySubscriberDOB:
																								"",
																							tertiaryInsuranceId:
																								null,
																							tertiaryInsuranceName:
																								"",
																							tertiaryPayerID:
																								"",
																							tertiaryMasterPayerID:
																								"",
																							tertiaryInsuranceTypeCode:
																								"",
																							tertiaryInsurancePhone:
																								"",
																							tertiaryInsuranceFax:
																								"",
																							tertiaryInsuranceAddress:
																								"",
																							tertiaryBillableType:
																								"",
																						})
																					);
																				}}
																			/>
																		</td>
																	</tr>
																	{/* <tr>
                                    <td width="50%">
                                      <Label title="Billable / Non-Billable / Non-Billable Commercial" />
                                      <Field
                                        as="select"
                                        name="tertiaryBillableType"
                                        className={`col-1-1 primary ${
                                          errors.tertiaryBillableType &&
                                          touched.tertiaryBillableType
                                            ? "error"
                                            : ""
                                        }`}
                                      >
                                        <option value="">Select</option>
                                        {BILLING_LISTING_OPTIONS.map((d, i) => (
                                          <option value={d.value} key={i}>
                                            {d.label}
                                          </option>
                                        ))}
                                      </Field>
                                    </td>
                                  </tr> */}
																	<tr>
																		<td>
																			<Label title='Tertiary Insurance Name' />
																			<CustomModal
																				selector={
																					<div>
																						<Field
																							type='text'
																							placeholder='Select Insurance'
																							name='tertiaryInsuranceName'
																							className={`col-1-1 primary ${
																								errors.tertiaryInsuranceId &&
																								touched.tertiaryInsuranceId
																									? "error"
																									: ""
																							}`}
																						/>
																						<ErrorMessage
																							name='tertiaryInsuranceId'
																							component='p'
																							className='error-messages'
																						/>
																					</div>
																				}
																				content={
																					<InsuranceSelect
																						setTouched={() =>
																							setFieldTouched(
																								"tertiaryInsuranceId",
																								true
																							)
																						}
																						callback={async (
																							v
																						) => {
																							if (
																								v
																							) {
																								await setFieldValue(
																									"tertiaryInsuranceId",
																									v
																								);
																								await setFieldValue(
																									"tertiaryInsuranceName",
																									v.label
																								);
																								await setFieldValue(
																									"tertiaryPayerID",
																									v
																										.obj
																										.electronicPayerId
																								);
																								await setFieldValue(
																									"tertiaryMasterPayerID",
																									v
																										.obj
																										.masterPayerId
																								);
																								await setFieldValue(
																									"tertiaryInsuranceTypeCode",
																									v
																										.obj
																										.insuranceType
																								);
																								await setFieldValue(
																									"tertiaryInsuranceAddress",
																									v
																										.obj
																										.addressLine1
																								);
																								await setFieldValue(
																									"secondaryInsuranceAddress",
																									v
																										.obj
																										.addressLine1
																								);
																								const phoneContact =
																									v.obj.insuranceContactDetails.find(
																										(
																											contact
																										) =>
																											contact.type ===
																											"Phone"
																									);
																								await setFieldValue(
																									"tertiaryInsurancePhone",
																									phoneContact
																										? phoneContact.information
																										: ""
																								); // Default to empty if no phone contact found

																								// Handle insuranceContactDetails for "Fax"
																								const faxContact =
																									v.obj.insuranceContactDetails.find(
																										(
																											contact
																										) =>
																											contact.type ===
																											"Fax"
																									);
																								await setFieldValue(
																									"tertiaryInsuranceFax",
																									faxContact
																										? faxContact.information
																										: ""
																								);
																							} else {
																								await setFieldValue(
																									"tertiaryInsuranceName",
																									""
																								);
																								await setFieldValue(
																									"tertiaryPayerID",
																									""
																								);
																								await setFieldValue(
																									"tertiaryMasterPayerID",
																									""
																								);
																								await setFieldValue(
																									"tertiaryInsuranceTypeCode",
																									""
																								);
																								await setFieldValue(
																									"tertiaryInsuranceAddress",
																									""
																								);
																								await setFieldValue(
																									"tertiaryInsurancePhone",
																									""
																								);
																								await setFieldValue(
																									"tertiaryInsuranceFax",
																									""
																								);
																							}
																						}}
																					/>
																				}
																			/>
																		</td>
																		<td>
																			<Label title='Tertiary Payer ID' />
																			<LikeInput
																				title={
																					values.tertiaryPayerID ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Master Payer ID' />
																			<LikeInput
																				title={
																					values.tertiaryMasterPayerID ||
																					"-"
																				}
																			/>
																		</td>
																		<td>
																			<Label title='Insurance Type Code' />
																			<LikeInput
																				title={
																					values.tertiaryInsuranceTypeCode ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Tertiary Insurance Phone' />
																			<LikeInput
																				title={
																					values.tertiaryInsurancePhone ||
																					"-"
																				}
																			/>
																		</td>
																		<td>
																			<Label title='Tertiary Insurance Fax' />
																			<LikeInput
																				title={
																					values.tertiaryInsuranceFax ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td colSpan='2'>
																			<Label title='Tertiary Insurance Address' />
																			<LikeInput
																				title={
																					values.tertiaryInsuranceAddress ||
																					"-"
																				}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<ButtonTextIcon
																				title='Fill Form With Patient Info'
																				type='button'
																				onClick={() => {
																					setValues(
																						(
																							prevValues
																						) => ({
																							...prevValues, // Spread the existing form values
																							tertiarySubscriberFirstName:
																								values.firstName,
																							tertiarySubscriberLastName:
																								values.lastName,
																							tertiarySubscriberDOB:
																								values.dob,
																						})
																					);
																				}}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Subscriber First Name' />
																			<Field
																				type='text'
																				placeholder='Enter Subscriber First Name'
																				name='tertiarySubscriberFirstName'
																				className={`col-1-1 primary ${
																					errors.tertiarySubscriberFirstName &&
																					touched.tertiarySubscriberFirstName
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																		<td>
																			<Label title='Subscriber Last Name' />
																			<Field
																				type='text'
																				placeholder='Enter Subscriber Last Name'
																				name='tertiarySubscriberLastName'
																				className={`col-1-1 primary ${
																					errors.tertiarySubscriberLastName &&
																					touched.tertiarySubscriberLastName
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Member Number' />
																			<Field
																				type='text'
																				placeholder='Enter Member Number'
																				name='tertiaryMemberNumber'
																				className={`col-1-1 primary ${
																					errors.tertiaryMemberNumber &&
																					touched.tertiaryMemberNumber
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																		<td>
																			<Label title='Group Number' />
																			<Field
																				type='text'
																				placeholder='Enter Group Number'
																				name='tertiaryGroupNumber'
																				className={`col-1-1 primary ${
																					errors.tertiaryGroupNumber &&
																					touched.tertiaryGroupNumber
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Subscriber DOB' />
																			<DatePickerInput
																				callback={(
																					v
																				) =>
																					setFieldValue(
																						"tertiarySubscriberDOB",
																						v
																					)
																				}
																				value={
																					values.tertiarySubscriberDOB
																				}
																				error={
																					errors.tertiarySubscriberDOB &&
																					touched.tertiarySubscriberDOB
																				}
																				setTouched={() =>
																					setFieldTouched(
																						"tertiarySubscriberDOB",
																						true
																					)
																				}
																			/>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													),
												},
												{
													title: (
														<span>
															 <IoShieldOutline />
															<span>
																Worker's Comp or
																Other Property &
																Casualty Claim
																Details (For
																Event ID)
															</span>
														</span>
													),
													content: (
														<div className='pad-20'>
															<table>
																<tbody>
																	<tr>
																		<td>
																			<Label title='Patient Condition Related To' />
																			<Field
																				type='text'
																				placeholder='Select Patient Condition Related To'
																				name='patientCondition'
																				className={`col-1-1 primary ${
																					errors.patientCondition &&
																					touched.patientCondition
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																		<td>
																			<Label title='Employer Name' />
																			<Field
																				type='text'
																				placeholder='Enter Employer Name'
																				name='employerName'
																				className={`col-1-1 primary ${
																					errors.employerName &&
																					touched.employerName
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td width='50%'>
																			<Label title='Date of Injury' />
																			<DatePickerInput
																				callback={(
																					v
																				) =>
																					setFieldValue(
																						"dateOfInjury",
																						v
																					)
																				}
																				value={
																					values.dateOfInjury
																				}
																				error={
																					errors.dateOfInjury &&
																					touched.dateOfInjury
																				}
																				setTouched={() =>
																					setFieldTouched(
																						"dateOfInjury",
																						true
																					)
																				}
																			/>
																		</td>
																		<td>
																			<Label title='Illness Indicator' />
																			<Field
																				type='text'
																				placeholder='Select Illness Indicator'
																				name='illnessIndicator'
																				className={`col-1-1 primary ${
																					errors.illnessIndicator &&
																					touched.illnessIndicator
																						? "error"
																						: ""
																				}`}
																			/>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													),
												},
											]}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => {
								if (nextUrl) {
									navigate(nextUrl);
								} else {
									navigate("/patients");
								}
							}}
							icon={
							<TbArrowBackUp />
							}
						/>
						<ButtonTextIcon
							type='button'
							disabled={loading}
							title='Update Now'
							onClick={async () => {
								const errors = await validateForm();
								if (Object.keys(errors).length > 0) {
									const firstErrorMessage =
										Object.values(errors)[0];
									addToastMessage({
										status: false,
										message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
									});
								}
								handleSubmit();
							}}
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<FiArrowRightCircle />
								)
							}
						/>
					</div>
					{status && (
						<>
							<br />
							<p className='color-primary f-w-700'>{status}</p>
						</>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default AddForm;
