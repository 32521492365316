import { useEffect, useState } from "react";
import BillCard from "./BillCard";
import { API_URL } from "../../utilities/constants";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { handleApiCall } from "../../utilities/helpers";
import { CiHospital1 } from "react-icons/ci";

export default function Invoice({ id, invoiceNo, facility, amount }) {
  const [bills, setBills] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async (id) => {
    setLoading(true);
    setBills(null);
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/bill?pageSize=1000&pageNo=0&invoiceId=${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        if (response.data.list.length !== 0) {
          setBills(response.data.list);
          return true;
        }
      }
      setBills(null);
      return false;
    } catch (e) {
      setBills(null);
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "960px",
        margin: "0 auto",
        padding: "16px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 0px 6px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div>
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <span>Invoice #{invoiceNo||"-"}</span>
          <span style={{ fontSize: "1.25rem", fontWeight: "normal" }}>
            {/* {formatRateUSD(amount)} */}
          </span>
        </div>
        <div
          className="flex color-light"
          style={{
            alignItems: "center",
            marginBottom: "16px",
            gap: "5px",
          }}
        >
          <span>
            <CiHospital1 />
          </span>
          <span>{facility}</span>
        </div>
      </div>
      <div
        style={{
          maxHeight: "55vh",
          overflowY: "auto",
        }}
      >
        <div style={{ marginBottom: "16px" }}>
          {loading && (
            <div
              className="col-1-1 flex center-center"
              style={{ height: "300px" }}
            >
              <LoaderIcon
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
          )}
          {!loading &&
            bills &&
            bills?.map((bill) => <BillCard key={bill.id} bill={bill} />)}
          {!loading && !bills && (
            <div
              className="col-1-1 flex center-center"
              style={{ height: "300px" }}
            >
              <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
                No bill records.
              </p>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "1.125rem",
          fontWeight: "bold",
          marginTop: "24px",
        }}
      >
        <div>Total Charge Amount:</div>
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              marginRight: "8px",
              width: "20px",
              height: "20px",
              backgroundColor: "#4CAF50",
              borderRadius: "50%",
            }}
          ></span>
          {amount}
        </div>
      </div>
    </div>
  );
}
