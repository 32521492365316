import * as Yup from "yup";
import { useEffect, useState } from "react";
import CustomModal from "./../common/CustomModal";
import ButtonLightTextIcon from "./ButtonLightTextIcon";
import ButtonTextIcon from "./ButtonTextIcon";
import { Field, Form, Formik } from "formik";
import CustomSelect from "./CustomSelect";
import { TbArrowBackUp, TbCalendarShare } from "react-icons/tb";
import { MdCancel } from "react-icons/md";
import { FiArrowRightCircle } from "react-icons/fi";

const Label = ({ title, required }) => {
  return (
    <p className="color-primary f-w-600" style={{ paddingBottom: "5px" }}>
      {title}
      {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
    </p>
  );
};

const MonthYearInput = ({ onApply, onCancel, initialValue = {} }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    setSelectedMonth(initialValue?.month ? initialValue.month : null);
    setSelectedYear(initialValue?.year ? initialValue.year : null);
  }, [initialValue]);

  const displaySelectedFilter = () => {
    if (!selectedYear) return "Select Year and Month";

    return selectedMonth
      ? `${selectedMonth?.label}, ${selectedYear?.label}`
      : `${selectedYear?.label}`;
  };

  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = Array.from({ length: 20 }, (_, i) => ({
    value: (new Date().getFullYear() - i).toString(),
    label: (new Date().getFullYear() - i).toString(),
  }));

  return (
    <div className="pos-rel dis-i-b" style={{ width: "100%" }}>
      {!selectedMonth && !selectedYear ? (
        <CustomModal
          selector={
            <div
              className="ui-like-input col-1-1 cursor-pointer"
              style={{
                display: "flex",
                justifyContent: "space-between",
                whiteSpace: "nowrap",
                minWidth: "200px",
              }}
            >
              <div>{displaySelectedFilter()}&nbsp;</div>
              <div>
              <TbCalendarShare />
              </div>
            </div>
          }
          content={
            <ModalContent
              initialValue={initialValue}
              onApply={onApply}
              onCancel={onCancel}
              months={months}
              years={years}
            />
          }
        />
      ) : (
        <div
          className="ui-like-input col-1-1 cursor-pointer"
          style={{
            display: "flex",
            justifyContent: "space-between",
            whiteSpace: "nowrap",
            minWidth: "200px",
          }}
        >
          <CustomModal
            selector={<div>{displaySelectedFilter()}&nbsp;</div>}
            content={
              <ModalContent
                initialValue={initialValue}
                onApply={onApply}
                onCancel={onCancel}
                months={months}
                years={years}
              />
            }
          />
          <div
            style={{
              margin: "auto 0",
            }}
          >
            <span
              className="cursor-pointer"
              style={{
                backgroundColor: "#f0f0f0",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
              }}
              onClick={() => {
                onCancel();
                setSelectedMonth(null);
                setSelectedYear(null);
              }}
            >
              &times;
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const ModalContent = ({
  initialValue,
  months,
  years,
  onApply,
  onCancel,
  setShow,
}) => {
  const [initialValues, setInitialValues] = useState({ month: "", year: "" });

  useEffect(() => {
    setInitialValues(initialValue);
  }, [initialValue]);
  const validationSchema = Yup.object().shape({
    year: Yup.mixed()
      .nullable()
      .test("year-required", "Year is required", function (value) {
        return value !== null && value.value;
      }),
  });

  return (
    <div>
      <section
        style={{
          position: "fixed",
          inset: 0,
          zIndex: 9999,
        }}
      >
        <section
          className="col-1-1 flex center-center"
          style={{
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            style={{
              width: "486px",
              padding: "24px",
              height: "auto",
              maxHeight: "80vh",
              border: "solid 1px #f0f0f0",
              background: "white",
              borderRadius: "8px",
              margin: "0 auto",
              boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
            }}
          >
            <div
              className="col-1-1 flex center-left"
              style={{
                borderBottom: "solid 1px #f0f0f0",
                paddingBottom: "12px",
                marginBottom: "16px",
              }}
            >
              <h6
                className="color-primary f-w-700 opl-tooltip"
                data-tooltip="View entire history of the activity below."
              >
                Filter
              </h6>
              <div
                className="mar-l-a cursor-pointer"
                onClick={() => setShow(false)}
              >
                <MdCancel  size={28}/> 
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                try {
                  onApply(values);
                  setShow(false);
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                validateForm,
              }) => (
                <Form>
                  <div>
                    <section>
                      <div>
                        <div
                          className="flex center-center"
                          style={{ gap: "10px" }}
                        >
                          <div style={{ width: "100%", paddingBottom: "10px" }}>
                            <Label title="Month" />
                            <CustomSelect
                              options={months}
                              setTouched={() => {
                                setFieldTouched("month", true);
                              }}
                              placeHolder="Select an option..."
                              selectedValue={values.month}
                              error={errors.month && touched.month}
                              onChange={(option) =>
                                setFieldValue("month", option)
                              }
                            />
                          </div>
                        </div>

                        <div
                          className="flex center-center"
                          style={{ gap: "10px" }}
                        >
                          <div style={{ width: "100%" }}>
                            <Label title="Year" required={true} />
                            <CustomSelect
                              options={years}
                              setTouched={() => {
                                setFieldTouched("year", true);
                              }}
                              placeHolder="Select an option..."
                              selectedValue={values.year}
                              error={errors.year && touched.year}
                              onChange={async (option) => {
                                await setFieldValue("year", option);
                                await validateForm();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="col-1-1 flex center-left"
                    style={{
                      marginTop: "16px",
                      borderTop: "solid 1px #f0f0f0",
                      paddingTop: "16px",
                    }}
                  >
                    <ButtonLightTextIcon
                      type="button"
                      title="Clear"
                      onClick={() => {
                        setShow(false);
                      }}
                      icon={
                        <TbArrowBackUp /> 
                      }
                    />

                    <div style={{ marginLeft: "auto" }}></div>
                    <ButtonTextIcon
                      title="Confirm"
                      type="submit"
                      icon={
                        <FiArrowRightCircle />
                      }
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </section>
    </div>
  );
};

export default MonthYearInput;
