import { useState } from "react";
import useQueryParams from "../../hooks/useQueryParams";
import { useScanflow } from "./scanflow-context";
import { formatServerDate, getRandomColor } from "../../utilities/helpers";
import InfoItem from "../../components/ui/InfoItem";
import { MdAutorenew, MdDoneAll } from "react-icons/md";
import { FaUserDoctor } from "react-icons/fa6";
import { CiHospital1 } from "react-icons/ci";
import { TbCalendarShare } from "react-icons/tb";
import { FiFlag } from "react-icons/fi";
import { CgPokemon } from "react-icons/cg";
import { IoArrowForwardCircleOutline } from "react-icons/io5";

const getRespectiveBorderColor = (isCompleted) => {
  switch (isCompleted) {
    case true:
      return " #27ae60";
    default:
      return " #E65464";
  }
};

const StatusIcon = ({ isCompleted }) => {
  switch (isCompleted) {
    case true:
      return (
        <div className="flex center-left" style={{ gap: "6px" }}>
          <MdDoneAll color={getRespectiveBorderColor(isCompleted)} />
          <p
            className="f-w-300"
            style={{
              fontSize: "12px",
              color: getRespectiveBorderColor(isCompleted),
            }}
          >
            Completed
          </p>
        </div>
      );

    default:
      return (
        <div className="flex center-left" style={{ gap: "6px" }}>
          <MdAutorenew color={getRespectiveBorderColor(isCompleted)} />
          <p
            className="f-w-300"
            style={{
              fontSize: "12px",
              color: getRespectiveBorderColor(isCompleted),
            }}
          >
            Incomplete
          </p>
        </div>
      );
  }
};

const getInitials = (obj) => {
  if (obj) {
    if (obj.firstName && obj.lastName) {
      const nameInitial = obj.firstName[0].toUpperCase();
      const lastNameInitial = obj.lastName[0].toUpperCase();
      return nameInitial + lastNameInitial;
    } else {
      return "-";
    }
  } else {
    return "-";
  }
};

const getPatientName = (obj) => {
  if (!obj || typeof obj !== "object") return "Not Selected";

  const { firstName = "", middleName = "", lastName = "" } = obj;
  const fullName = [firstName, middleName, lastName]
    .filter((name) => name && name.trim() !== "")
    .join(" ");

  return fullName || "Not Selected";
};

const getRandomWidth = (startDate, endDate) => {
  if (startDate && endDate) {
    return "100%";
  } else {
    return "50%";
  }
};

const SingleTask = ({ id, data = null }) => {
  const { updateQueryParam, queryParams } = useQueryParams();
  const [isOpen, setIsOpen] = useState(false);
  const { handleReOpen } = useScanflow();

  return (
    <div id={`scanflow-${data?.id}`} tabIndex={0} className="one pos-rel">
      <div
        style={{
          position: "absolute",
          content: "",
          height: "5px",
          left: "-1px",
          right: "-1px",
          bottom: "-2px",
          background: getRespectiveBorderColor(data.isCompleted),
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
      ></div>
      <div className="col-1-1 flex center-left" style={{ gap: "10px" }}>
        <h5 className="f-w-700">
          <span className="f-w-300">#</span>&nbsp;
          {data.patientActivity.id}
        </h5>
        <hr
          style={{
            outline: "none",
            border: "none",
            height: "16px",
            width: "2px",
            background: "#EBEBEB",
          }}
        />
        <StatusIcon isCompleted={data.isCompleted} />
      </div>
      <div
        onClick={() => updateQueryParam("id", data?.id)}
        className="cursor-pointer"
      >
        <div>
          <div
            style={{
              padding: "12px 0 12px 0 ",
            }}
          >
            <div
              className="info-row flex center-center"
              style={{
                gap: "10px",
                paddingBottom: "10px",
              }}
            >
              <InfoItem
                className="col-1-1 info-row-column"
                variant="card"
                icon={<FaUserDoctor color={getRandomColor()} size={20} />}
                label={"Assistant"}
                name={
                  data?.patientActivity?.event_surgical_assitant
                    ? `${(data?.patientActivity?.event_surgical_assitant?.name || "") + (data?.patientActivity?.event_surgical_assitant?.lastName ? " " + data?.patientActivity?.event_surgical_assitant?.lastName : "")}`
                    : "-"
                }
              />
              <InfoItem
                className="col-1-1 info-row-column"
                variant="card"
                icon={<CiHospital1 color={getRandomColor()} size={20} />}
                label={"Facility"}
                name={
                  data?.patientActivity?.event_facility?.facilityName || "-"
                }
              />
            </div>
            <div
              className="info-row flex center-center"
              style={{
                gap: "10px",
                paddingBottom: "10px",
              }}
            >
              <InfoItem
                className="col-1-1 info-row-column"
                variant="card"
                icon={<TbCalendarShare color={getRandomColor()} size={20} />}
                label={"Service Date"}
                name={
                  data?.patientActivity?.event_start_date
                    ? `${formatServerDate(data?.patientActivity?.event_start_date)}`
                    : "-"
                }
              />
              <InfoItem
                className="col-1-1 info-row-column"
                variant="card"
                icon={<FiFlag color={getRandomColor()} size={20} />}
                label={"Bill Status"}
                name={
                  data?.patientActivity?.billStatus === "not_set"
                    ? "NOT SET"
                    : (data?.patientActivity?.billStatus || "-").toUpperCase()
                }
              />
            </div>
            <div
              className="info-row flex center-center"
              style={{
                gap: "10px",
                paddingBottom: "10px",
              }}
            >
              <InfoItem
                className="col-1-1 info-row-column"
                variant="card"
                icon={<CgPokemon color={getRandomColor()} size={20} />}
                label={"Insurance"}
                name={data?.patientActivity?.insurance?.description || "-"}
              />
            </div>
          </div>
          <div>
            <div className="col-1-1 flex center-left">
              <p
                className="color-faded f-w-300 l-h-1"
                style={{ fontSize: "9px" }}
              >
                {data?.patientActivity?.event_start_date
                  ? formatServerDate(data?.patientActivity?.event_start_date)
                  : "-"}{" "}
                {data?.patientActivity?.event_start_time || ""}
              </p>
              <p
                className="color-faded f-w-300 l-h-1 mar-l-a"
                style={{ fontSize: "9px" }}
              >
                {data?.patientActivity?.event_end_date
                  ? formatServerDate(data?.patientActivity?.event_end_date)
                  : "-"}{" "}
                {data?.patientActivity?.event_end_time || ""}
              </p>
            </div>
          </div>
          <div
            className="flex center-left"
            style={{
              padding: "5px 0",
            }}
          >
            <div
              className="b-r-100"
              style={{
                height: "12px",
                width: "12px",
                background: "white",
                border: "solid 3px",
                borderColor: "#283f54",
              }}
            ></div>
            <div
              className="pos-rel"
              style={{
                flex: 1,
                height: "2px",
                background: "#f0f0f0",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  content: "''",
                  height: "2px",
                  left: "0",
                  top: 0,
                  background: getRespectiveBorderColor(data.isCompleted),
                  width: getRandomWidth(
                    data?.patientActivity?.event_start_date,
                    data?.patientActivity?.event_end_date
                  ),
                }}
              ></div>
            </div>
            <div
              className="b-r-100"
              style={{
                height: "12px",
                width: "12px",
                background: "white",
                border: "solid 3px",
                borderColor: getRespectiveBorderColor(data.isCompleted),
              }}
            ></div>
          </div>
          <div className="col-1-1 flex center-center" style={{ gap: "10px" }}>
            <div
              className="b-r-100 flex center-center"
              style={{
                height: "28px",
                width: "28px",
                background: getRandomColor(),
                border: "solid 1px rgba(0,0,0,0.08)",
                fontSize: "12px",
                fontWeight: "300",
                color: "white",
              }}
            >
              {getInitials(data?.patientActivity?.patient)}
            </div>
            <div>
              <p
                className="f-w-300 l-h-1-2"
                style={{ color: "#aaaaaa", fontSize: "11px" }}
              >
                Patient
              </p>
              <p
                className="f-w-600 l-h-1 color-primary"
                style={{
                  width: "132px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  height: "16px",
                  whiteSpace: "nowrap",
                }}
              >
                {getPatientName(data.patientActivity.patient)}
              </p>
            </div>
            <IoArrowForwardCircleOutline
              size={20}
              style={{
                marginLeft: "auto",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTask;
