import { useEffect } from "react";

const useDocumentTitle = (title, baseTitle = "CoreMaxCloud") => {
  useEffect(() => {
    const originalTitle = document.title;
    if (title) {
      document.title = `${title} | ${baseTitle}`;
    }
    return () => {
      document.title = originalTitle;
    };
  }, [title, baseTitle]);
};

export default useDocumentTitle;
