import Pagination from "../components/common/Pagination";
import {
  formatRateUSD,
  handleApiCall,
  jsonToQueryParams,
} from "../utilities/helpers";
import { useEffect, useState } from "react";
import { useUI } from "../context/ui";
import LoaderIcon from "../components/ui/LoaderIcon";
import TableFilter from "./invoicing/TableFilter";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import { API_URL } from "../utilities/constants";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import CustomModal from "../components/common/CustomModal";
import InvoiceDetailModal from "./invoicing/InvoiceDetailModal";
import FullScreenFileViewerModal from "../components/ui/FullScreenFileViewerModal";
import { NavLink, useLocation } from "react-router-dom";
import useQueryParams from "../hooks/useQueryParams";
import PageToolbar from "../components/common/PageToolbar";
import InvoiceHistory from "./invoicing/InvoiceHistory";
import InvoiceStatus from "./invoicing/InvoiceStatus";
import PopUpModal from "../components/common/PopUpModal";

const InvoicingPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { updateQueryParam, queryParams } = useQueryParams();
  const [controller, setController] = useState(null);
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const { addToastMessage, permissions } = useUI();
  const showId = queryParams.get("showId") || null;
  const [viewInvoice, setViewInvoice] = useState(false);
  const [showInvoiceData, setShowInvoiceData] = useState(null);

  const month = queryParams.get("month") || "";
  const year = queryParams.get("year") || "";
  const facility = queryParams.get("facility") || null;
  const patient = queryParams.get("patient") || null;
  const billType = queryParams.get("billType") || null;

  const resetFilters = () => {
    updateQueryParam("month", "");
    updateQueryParam("year", "");
    updateQueryParam("facility", "");
    updateQueryParam("patient", "");
    updateQueryParam("pageNo", 0);
    updateQueryParam("billType", "");
  };

  useEffect(() => {
    if (showId && data?.list) {
      const invoice = data.list.find((item) => item.id == showId);
      if (invoice) {
        setViewInvoice(true);
        setShowInvoiceData(invoice);
      }
    } else {
      setViewInvoice(false);
    }
  }, [showId, data]);

  const getListApi = async (s) => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    try {
      setLoading(true);
      const query = jsonToQueryParams({
        month: month ? JSON.parse(month)?.value : null,
        year: year ? JSON.parse(year)?.value : null,
        facilityId: facility ? JSON.parse(facility)?.value : null,
        patientId: patient ? JSON.parse(patient)?.value : null,
        billType: billType || null,
      });
      const response = await handleApiCall(
        API_URL +
          `/api/1.0.0/invoice?pageSize=${pageSize}&pageNo=${pageNo}&${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus == true) {
        setData(response.data);
      } else {
        setData(null);
      }

      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    getListApi();
  }, [location.search]);

  const handleRegenerate = async (id) => {
    try {
      if (!id) {
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/invoice/regenerate-non-billable/${id}`,
        {
          method: "POST",
        }
      );
      if (response.responseStatus === true) {
        getListApi();
        addToastMessage({
          status: true,
          message: `Invoice regenerate successfully.`,
        });
        return true;
      }

      addToastMessage({
        status: false,
        message: `Something went wrong while regenerate the invoice. Please try again.`,
      });

      return false;
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while regenerate the invoice. Please try again.`,
      });
      return false;
    }
  };
  const handleRegeneratebillable = async (id) => {
    try {
      if (!id) {
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/invoice/regenerate-billable/${id}`,
        {
          method: "POST",
        }
      );
      if (response.responseStatus === true) {
        getListApi();
        addToastMessage({
          status: true,
          message: `Invoice regenerate successfully.`,
        });
        return true;
      }

      addToastMessage({
        status: false,
        message: `Something went wrong while regenerate the invoice. Please try again.`,
      });

      return false;
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while regenerate the invoice. Please try again.`,
      });
      return false;
    }
  };

  return (
    <section id="patient">
      <PageToolbar title="Invoicing" />
      <TableFilter
        resetFilters={() => {
          resetFilters();
        }}
      />
      <div className="flex center-left">
        <div>
          {data && !loading && (
            <div
              className="flex center-left"
              style={{
                padding: "10px 15px 10px 15px",
                gap: "12px",
              }}
            >
              <p className="f-w-300" style={{ fontSize: "11px" }}>
                Page no:
              </p>
              <Pagination
                totalRecords={data.totalRecords}
                pageNo={data.pageNo}
                pageSize={data.pageSize}
              />
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.list.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Invoice ID</th>
                <th>Facility</th>
                <th>Amount</th>
                <th>No. of Bills</th>
                <th>Invoice Status</th>
                <th style={{ textAlign: "center" }}>
                  <div data-tooltip="Actions available: View, Edit, or Delete the record.">
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.list.map((data, index) => (
                  <tr key={index}>
                    <td>{pageNo * pageSize + index + 1}</td>
                    <td>{data.invoiceNo || "NA"}</td>
                    <td>{data?.facilityName || "NA"}</td>
                    <td>
                      {data?.totalAmount
                        ? `${formatRateUSD(data?.totalAmount)}`
                        : "NA"}
                    </td>
                    <td>{data?.numberOfBills || "NA"}</td>
                    <td>
                      {data.invoiceSentStatus === 1
                        ? "Ready"
                        : data.invoiceSentStatus === 2
                          ? "Send to Facility"
                          : data.invoiceSentStatus === 3
                            ? "Update Required"
                            : data.invoiceSentStatus === 4
                              ? "Payment Received"
                              : "NA"}
                    </td>
                    <td>
                      <div
                        className="flex center-center"
                        style={{ gap: "12px" }}
                      >
                        <div>
                          <ButtonTertiary
                            actionType="view"
                            title="View"
                            onClick={() => {
                              setShowInvoiceData(data);
                              updateQueryParam("showId", data.id);
                              setViewInvoice(true);
                            }}
                          />
                        </div>
                        <div>
                          <NavLink
                            to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                              data.invoicePath || ""
                            )}&title=${encodeURIComponent(
                              `Invoice No-(${data.invoiceNo})`
                            )}`}
                            state={location.state}
                          >
                            <ButtonTertiary
                              actionType="download"
                              title="Download Invoice"
                            />
                          </NavLink>
                        </div>
                        <a
                          href={data.excelPath}
                          download
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ButtonTertiary
                            actionType="download"
                            title="Download Excel"
                            style={{ background: "#03002e" }}
                          />
                        </a>
                        <div>
                          <CustomModal
                            selector={
                              <ButtonTertiary
                                actionType="view"
                                title="Invoice Status"
                                disabled={
                                  data.invoiceSentStatus === 4 ? true : false
                                }
                              />
                            }
                            content={
                              <InvoiceStatus
                                callback={() => getListApi()}
                                id={data.id}
                                invoiceStatus={data.invoiceSentStatus}
                              />
                            }
                          />
                        </div>
                        <div>
                          <CustomModal
                            selector={
                              <ButtonTertiary
                                actionType="view"
                                title="History"
                              />
                            }
                            content={<InvoiceHistory id={data.id} />}
                          />
                        </div>
                        <div>
                          {permissions?.isUpdateAllowed && (
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="reload"
                                  title="Regenerate"
                                  disabled={
                                    data.requiredRegenerate === 1 ? false : true
                                  }
                                />
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    if (data.billType === "billable") {
                                      await handleRegeneratebillable(data.id);
                                      return true;
                                    } else {
                                      await handleRegenerate(data.id);
                                      return true;
                                    }
                                  }}
                                  message="Are you sure you want to regenerate the invoice?"
                                />
                              }
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      {viewInvoice && (
        <PopUpModal>
          <InvoiceDetailModal data={showInvoiceData} setShow={setViewInvoice} />
        </PopUpModal>
      )}
      <div>
        <div>
          {data && !loading && (
            <div
              className="flex center-center"
              style={{
                padding: "24px 15px 32px 15px",
                gap: "12px",
              }}
            >
              <p className="f-w-300" style={{ fontSize: "11px" }}>
                Page no:
              </p>
              <Pagination
                totalRecords={data.totalRecords}
                pageNo={data.pageNo}
                pageSize={data.pageSize}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default InvoicingPage;
