import Header from "../components/layout/Header";
import LoaderIcon from "../components/ui/LoaderIcon";
import UpdateActivityForm from "./activities/UpdateActivityForm";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { useLocation, useNavigate } from "react-router-dom";
import PageToolbar from "../components/common/PageToolbar";
import { handleApiCall } from "../utilities/helpers";
import useQueryParams from "../hooks/useQueryParams";
import { TbArrowBackUp } from "react-icons/tb";

const UpdateActivity = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [controller, setController] = useState(null);

  const id = location.state?.id;
  const [activityData, setActivityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const {queryParams,updateQueryParam } =useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";

  const fetchActivityData = async (id) => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/activity/${id}`,
        {
          method: "GET",
        },
        false,
        signal
      );
      if (response.responseStatus === true) {
        setActivityData(response.data);
        setLoading(false);
      } else {
        setActivityData(null);
        setLoading(false);
      }
    } catch (e) {
      if (e.name === "AbortError") {
        console.log("Previous request was aborted");
      } else {
        setError("Failed to fetch activity data.");
        setLoading(false);
      }
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivityData(id);
  }, [id]);

  return (
    <section id="add-activity">
      <PageToolbar
        title="Update Activity"
        button={
          <ButtonTextIcon
            title="Cancel"
            className="light mar-l-a"
            onClick={() => {
              if (nextUrl) {
                navigate(nextUrl ,{state:location.state});
              } else {
                navigate("/activities");
              }
            }}
            icon={
              <TbArrowBackUp />
            }
          />
        }
      />
      <div className="col-1-1">
        <div className="form-holder">
          <div className="inner">
            {!error && !loading && activityData && (
              <UpdateActivityForm activityData={activityData} />
            )}
            {!error && loading && !activityData && (
              <p
                style={{
                  height: "100vh",
                  paddingBottom: "20vh",
                  backgroundColor: "#fafaff",
                }}
                className="flex center-center"
              >
                <LoaderIcon
                  style={{
                    height: "32px",
                    width: "32px",
                  }}
                />
              </p>
            )}
            {error && !loading && !activityData && (
              <p
                style={{
                  height: "100vh",
                  paddingBottom: "20vh",
                  backgroundColor: "#fafaff",
                }}
                className="flex center-center"
              >
                {error || "Error"}
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdateActivity;
