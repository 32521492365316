import { useEffect, useState } from "react";
import React from "react";
import UpdateForm from "./knowledge-base-sub-categories/UpdateForm";
import { API_URL } from "../utilities/constants";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import LoaderIcon from "../components/ui/LoaderIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { handleApiCall } from "../utilities/helpers";
import PageToolbar from "../components/common/PageToolbar";
import useQueryParams from "../hooks/useQueryParams";
import { TbArrowBackUp } from "react-icons/tb";

const UpdateKnowledgeBaseSubCategoryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location?.state?.id;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {queryParams,updateQueryParam } =useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";

  const fetchRegionData = async () => {
    try {
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/knowledge-base-subcategory/${id}`,
        {
          method: "GET",
        }
      );
      if (response) {
        setData(response.data);
      } else {
        setError("Failed to fetch region data");
      }
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch region data");
      setLoading(false);
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    fetchRegionData(id);
  }, [id]);

  return (
        <section id="add-activity">
          <PageToolbar
            title="Update Knowledge Base Sub Category"
            button={<ButtonTextIcon
                title="Cancel"
                className="light mar-l-a"
                onClick={() => {
                  if (nextUrl) {
                    navigate(nextUrl);
                  } else {
                    navigate("/knowledge-base-sub-categories");
                  }
                }}
                icon={
                 <TbArrowBackUp />
                }
              />}/>
          <div className="col-1-1">
            <div className="form-holder">
              <div className="inner">
                {!loading && data ? (
                  <UpdateForm data={data} />
                ) : (
                  <p
                    style={{
                      height: "100vh",
                      paddingBottom: "20vh",
                      backgroundColor: "#fafaff",
                    }}
                    className="flex center-center"
                  >
                    <LoaderIcon style={{ height: "32px", width: "32px" }} />
                  </p>
                )}
                {error && <p>{error}</p>}
              </div>
            </div>
          </div>
        </section>
  );
};
export default UpdateKnowledgeBaseSubCategoryPage;
