import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import PaginatedDropdown from "../../components/ui/PaginatedDropdown";
import { API_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import { useNavigate } from "react-router-dom";
import { handleApiCall } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import { TbArrowBackUp } from "react-icons/tb";
import { FiArrowRightCircle } from "react-icons/fi";
const validationSchema = Yup.object({
  category: Yup.mixed()
    .nullable()
    .test("category-required", "Category is required", function (value) {
      return value !== null && value.value;
    }),
  name: Yup.string().required("Sub Category is required"),
});

function AddForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const { addToastMessage } = useUI();

  const createSubCategory = async (values) => {
    setLoading(true);
    try {
      const response = await handleApiCall(
        API_URL + "/api/1.0.0/knowledge-base-subcategory",
        {
          method: "POST",
          body: {
            name: values.name,
            knowledgeBaseCategoryId: values.category.value,
          },
        }
      );
      if (response.responseStatus == true) {
        setStatus(
          "Your knowledge base sub category has been successfully created. Thank you."
        );
        addToastMessage({
          status: true,
          message: `Your knowledge base sub category has been successfully created. Thank you.`,
        });
        navigate("/knowledge-base-sub-categories");
        return true;
      } else {
        setStatus(
          `${response.responseDescription || "Something went wrong, please try again later."}`
        );
        addToastMessage({
          status: false,
          message: `${response.responseDescription || "Something went wrong, please try again later."}`,
        });
        return false;
      }
    } catch (e) {
      console.error("Error:", e);
      setStatus("Something went wrong, please try again later.");
      addToastMessage({
        status: false,
        message: `Something went wrong, please try again later.`,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        category: null,
        name: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        const api = await createSubCategory(values);
        if (api) {
          setLoading(false);
          resetForm();
        }
      }}
    >
      {({
        handleSubmit,
        validateForm,
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="col-1-1">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Label title="Category" required />
                    <PaginatedDropdown
                      searchkey="name"
                      url={`${API_URL}/api/1.0.0/knowledge-base-category?`}
                      mapOptions={(records) =>
                        records.map((record) => ({
                          value: record["id"],
                          label: record.name,
                          obj: record,
                        }))
                      }
                      setTouched={() => setFieldTouched("category", true)}
                      error={errors.category && touched.category}
                      selectedValue={values.category}
                      placeHolder="Search"
                      datakey="knowledgeBaseCategoryList"
                      onChange={(v) => {
                        setFieldValue("category", v);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="Sub Category" required />
                    <Field
                      name="name"
                      type="text"
                      placeholder="Eg: Scanflow"
                      className={`primary ${errors.name && touched.name ? "error" : ""}`}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => navigate("/knowledge-base-sub-categories")}
              icon={<TbArrowBackUp />}
            />
            <ButtonTextIcon
              type="submit"
              disabled={loading}
              title="Create Now"
              onClick={async () => {
                const errors = await validateForm();
                if (Object.keys(errors).length > 0) {
                  const firstErrorMessage = Object.values(errors)[0];
                  addToastMessage({
                    status: false,
                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                  });
                }
                handleSubmit();
              }}
              className="mar-l-a"
              icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AddForm;
