import AddForm from "./insurance/AddForm";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import { useNavigate } from "react-router-dom";
import { TbArrowBackUp } from "react-icons/tb";

const AddInsurancePage = () => {
  const navigate = useNavigate();

  return (
    <section id="add-insurance">
      <div className="col-1-1 flex center-left pad-5 main-section-top-row">
        <h5 className="page-active color-primary f-w-700">Add Insurance</h5>
        <ButtonTextIcon
          title="Cancel"
          className="light mar-l-a"
          onClick={() => navigate("/insurance")}
          icon={
            <TbArrowBackUp />
          }
        />
        &nbsp;
      </div>
      <div className="col-1-1">
        <div className="form-holder">
          <div className="inner">
            <AddForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddInsurancePage;
