import { TbArrowBackUp, TbCalendarShare, TbUserCircle } from "react-icons/tb";
import CustomModal from "../../components/common/CustomModal";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import FullScreenFileViewerModal from "../../components/ui/FullScreenFileViewerModal";
import InfoItem from "../../components/ui/InfoItem";
import { formatInputPhoneNumber, formatServerDate, getRandomColor } from "../../utilities/helpers";
import { MdAlternateEmail, MdOutlineAccountBalance, MdOutlineRealEstateAgent } from "react-icons/md";
import { CiVoicemail } from "react-icons/ci";
import { FaRegFileLines } from "react-icons/fa6";

 
const PatientRegistrationDetail = ({ data, setShow }) => { 

    return (
      <div className="box" id="activity-detail-box">
        <div className="flex center-left">
          <div>
            <h6 className="color-primary f-w-300">
              Patient Registration Details
            </h6>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <ButtonLightTextIcon
              title="Cancel"
              onClick={() => setShow(false)}
              icon={<TbArrowBackUp />}
            />
          </div>
        </div>
        <div
          style={{
            height: "60vh",
            margin: "10px",
            overflowY: "scroll",
            padding: "20px 5px",
            overflowX: "hidden",
            scrollbarWidth: "thin",
          }}
        >
          {data && (
            <div className="activity-card pos-rel">
              <div
                style={{
                  position: "absolute",
                  content: "",
                  height: "4px",
                  left: "-1px",
                  right: "-1px",
                  bottom: "-2px",
                  borderBottomLeftRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              ></div>
              <div
                className="col-1-1 flex center-left"
                style={{
                  gap: "10px",
                  paddingBottom: "5px",
                }}
              >
                <h5 className="f-w-700">
                  <span className="f-w-300">#</span>
                  &nbsp;
                  {data?.id}
                </h5>
                <hr
                  style={{
                    outline: "none",
                    border: "none",
                    height: "16px",
                    width: "2px",
                    background: "#EBEBEB",
                  }}
                />
              </div>
              <div
                style={{
                  padding: "12px 0 12px 0 ",
                }}
              >
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<TbUserCircle color={getRandomColor()} size={20} />}
                    label={"Name"}
                    name={
                      data?.firstName +
                        " " +
                        data?.middleName +
                        " " +
                        data?.lastName || "NA"
                    }
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdAlternateEmail color={getRandomColor()} size={20} />
                    }
                    label={"Email"}
                    name={data?.email || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<CiVoicemail color={getRandomColor()} size={20} />}
                    label={"Phone Number"}
                    name={
                      data?.contactNo
                        ? formatInputPhoneNumber(data?.contactNo.trim())
                        : "NA"
                    }
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineRealEstateAgent
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"Address"}
                    name={data?.address || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdOutlineAccountBalance
                        color={getRandomColor()}
                        size={20}
                      />
                    }
                    label={"SSN"}
                    name={data?.ssn || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <TbCalendarShare color={getRandomColor()} size={20} />
                    }
                    label="Date Of Birth"
                    name={data?.dob ? formatServerDate(data?.dob) : "NA"}
                  />
                </div>
                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
                <div style={{ marginBottom: "16px" }}>
                  <p
                    style={{
                      fontWeight: "700",
                      paddingBottom: "8px",
                    }}
                  >
                    Documents
                  </p>
                  <div
                    style={{
                      display: "grid",
                      gap: "16px",
                    }}
                  >
                    {data?.s3urls?.length > 0 ? (
                      data.s3urls.map((url, index) => (
                        <CustomModal
                          selector={
                            <InfoItem
                              className="col-1-1 info-row-column cursor-pointer"
                              icon={
                                <FaRegFileLines
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label={"Attachment"}
                              name="Click to View"
                            />
                          }
                          content={
                            <FullScreenFileViewerModal
                              title={`Document`}
                              fileUrl={url}
                            />
                          }
                        />
                      ))
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "5px 12px",
                          backgroundColor: "rgb(245, 245, 245)",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              No document found.
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};
export default PatientRegistrationDetail;
