import { CiWarning } from "react-icons/ci";
import useQueryParams from "../../hooks/useQueryParams";
import { formatRateUSD } from "../../utilities/helpers";
import Invoice from "./Invoice";
import { MdCancel } from "react-icons/md";

const InvoiceDetailModal = ({ data, setShow, location, history }) => {
const {updateQueryParam,queryParams} = useQueryParams();
  return (
    <section id="opl_coremax">
      <section
        style={{
          position: "fixed",
          inset: 0,
          zIndex: 9999,
          backgroundColor: "rgba(255, 255, 255, 0.86)",
        }}
      >
        <section
          className="col-1-1 flex center-center"
          style={{
            height: "100vh",
            width: "100vw",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              height: "100vh",
              width: "100vw",
              margin: "10px",
              padding: "15px",
              border: "solid 1px #f0f0f0",
              background: "white",
              borderRadius: "8px",
              margin: "0 auto",
              boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
            }}
          >
            <div
              className="col-1-1 flex "
              style={{
                borderBottom: "solid 1px #f0f0f0",
                paddingBottom: "12px",
                marginBottom: "16px",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <h6 className="color-primary f-w-700 t-t-u l-s-n">
                Invoice Detail
              </h6>
              {data.requiredRegenerate === 1 && (
                <div
                  className="flex items-center gap-2 p-2 border border-yellow-500 bg-yellow-100 rounded-md"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    padding: "8px",
                    border: "1px solid #d97706",
                    backgroundColor: "#FEF3C7",
                    borderRadius: "6px",
                  }}
                >
                  <span
                    className="flex-shrink-0"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <CiWarning color="#D97706" />
                  </span>
                  <p
                    className="text-yellow-700 font-semibold text-sm leading-none"
                    style={{
                      color: "#D97706",
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "1",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Invoice needs to be regenerated, Go back and Click on
                    Regenerate button
                  </p>
                </div>
              )}
              <div
                className="cursor-pointer "
                onClick={() => {
                  updateQueryParam("showId", null);
                }}
              >
               <MdCancel  size={28}/> 
              </div>
            </div>
            <section className="col-1-1">
              <main
                style={{
                  maxWidth: "1024px",
                  margin: "0 auto",
                  padding: "16px",
                  fontFamily: "Arial, sans-serif",
                }}
              >
                <Invoice
                  invoiceNo={data?.invoiceNo || "NA"}
                  facility={data?.facilityName || "NA"}
                  amount={
                    data?.totalAmount
                      ? `${formatRateUSD(data?.totalAmount)}`
                      : "NA"
                  }
                  id={data?.id}
                />
              </main>
            </section>
          </div>
        </section>
      </section>
    </section>
  );
};

export default InvoiceDetailModal;
