import { useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../../components/ui/LoaderIcon";
import {
  formatInputPhoneNumber,
  getRandomColor,
  handleApiCall,
} from "../../utilities/helpers";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import CustomModal from "../../components/common/CustomModal";
import FullScreenFileViewerModal from "../../components/ui/FullScreenFileViewerModal";
import { useUI } from "../../context/ui";
import InfoItem from "../../components/ui/InfoItem";
import useQueryParams from "../../hooks/useQueryParams";
import { TbArrowBackUp, TbUserCircle } from "react-icons/tb";
import { FiEdit } from "react-icons/fi";
import { LuEye, LuMapPin } from "react-icons/lu";
import { PiContactlessPayment } from "react-icons/pi";
import { MdOutlineContactless, MdOutlineRealEstateAgent } from "react-icons/md";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { CiWallet } from "react-icons/ci";
import { FaRegFileLines } from "react-icons/fa6";

const InsuranceDetails = ({ id, setViewInsurance }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { permissions } = useUI();
  const { queryParams, updateQueryParam } = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const fetchFacilityData = async (id) => {
    setLoading(true);
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/insurance/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus == true) {
        setData(response.data);
      } else {
        setData(null);
        setError("Failed to fetch facility data.");
      }
    } catch (e) {
      setError("Failed to fetch facility data.");
      setLoading(false);
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchFacilityData(id);
  }, [id]);

  return (
    <section
      style={{
        position: "fixed",
        inset: 0,
        zIndex: 9999,
        backgroundColor: "rgba(255, 255, 255, 0.86)",
      }}
    >
      <section
        className="col-1-1 flex center-center"
        style={{
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          id="activity-detail-box"
          style={{
            backgroundColor: "rgba(255, 255, 255)",
            borderRadius: "12px",
          }}
        >
          <div className="flex center-left">
            <div>
              <h6 className="color-primary f-w-300">Insurance Details</h6>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <ButtonLightTextIcon
                title="Cancel"
                onClick={() => {
                  setViewInsurance(false);
                  updateQueryParam("showId");
                }}
                icon={<TbArrowBackUp />}
              />
            </div>
          </div>
          <div
            style={{
              height: "60vh",
              margin: "10px",
              overflowY: "scroll",
              padding: "20px 5px",
              overflowX: "hidden",
              scrollbarWidth: "thin",
            }}
          >
            {loading && (
              <div
                className="col-1-1"
                style={{
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoaderIcon
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
            )}
            {!loading && error && (
              <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
                {error}
              </p>
            )}
            {data && !loading && !error && (
              <div className="activity-card pos-rel">
                <div
                  style={{
                    position: "absolute",
                    content: "",
                    height: "4px",
                    left: "-1px",
                    right: "-1px",
                    bottom: "-2px",
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                ></div>
                <div
                  className="col-1-1 flex center-left"
                  style={{
                    gap: "10px",
                    paddingBottom: "5px",
                  }}
                >
                  <h5 className="f-w-700">
                    <span className="f-w-300">#</span>
                    &nbsp;
                    {data?.id}
                  </h5>
                  <hr
                    style={{
                      outline: "none",
                      border: "none",
                      height: "16px",
                      width: "2px",
                      background: "#EBEBEB",
                    }}
                  />
                </div>
                <div
                  style={{
                    padding: "12px 0 12px 0 ",
                  }}
                >
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<TbUserCircle color={getRandomColor()} size={20} />}
                      label={"Insurance Name"}
                      name={data?.description}
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<LuMapPin color={getRandomColor()} size={20} />}
                      label={"Address 1"}
                      name={data?.addressLine1 || "NA"}
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<LuMapPin color={getRandomColor()} size={20} />}
                      label={"Address 2"}
                      name={data?.addressLine2 || "NA"}
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <PiContactlessPayment
                          color={getRandomColor()}
                          size={20}
                        />
                      }
                      label={"Payer Type"}
                      name={data?.category || "NA"}
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <MdOutlineRealEstateAgent
                          color={getRandomColor()}
                          size={20}
                        />
                      }
                      label={"City"}
                      name={data?.city?.name || "NA"}
                    />

                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <MdOutlineRealEstateAgent
                          color={getRandomColor()}
                          size={20}
                        />
                      }
                      label={"state"}
                      name={data?.state?.name || "NA"}
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <AiOutlineFieldNumber
                          color={getRandomColor()}
                          size={20}
                        />
                      }
                      label={"Electronic Payer Id"}
                      name={data?.electronicPayerId || "NA"}
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <AiOutlineFieldNumber
                          color={getRandomColor()}
                          size={20}
                        />
                      }
                      label={"Master Payer Id"}
                      name={data?.masterPayerId || "NA"}
                    />
                  </div>

                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<LuMapPin color={getRandomColor()} size={20} />}
                      label={"Zip"}
                      name={data?.zip || "NA"}
                    />
                  </div>
                  <hr
                    style={{
                      marginTop: "10px",
                      marginBottom: "12px",
                    }}
                  />
                  <p
                    style={{
                      fontWeight: "700",
                      paddingBottom: "8px",
                    }}
                  >
                    Contact Details
                  </p>
                  {data?.insuranceContactDetails?.length ? (
                    data.insuranceContactDetails.map((contactDetail, index) => (
                      <>
                        <div
                          key={index}
                          className="info-row flex center-center"
                          style={{
                            gap: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <MdOutlineContactless
                                color={getRandomColor()}
                                size={20}
                              />
                            }
                            label="Contact Type"
                            name={contactDetail.type || "NA"}
                          />
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <CiWallet color={getRandomColor()} size={20} />
                            }
                            label="Information"
                            name={
                              contactDetail.type === "Phone"
                                ? contactDetail.information
                                  ? formatInputPhoneNumber(
                                      contactDetail.information.trim()
                                    )
                                  : "NA"
                                : contactDetail.information || "NA"
                            }
                          />
                        </div>
                        <hr
                          style={{
                            marginTop: "10px",
                            marginBottom: "12px",
                          }}
                        />
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px 12px",
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            No detail found.
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div style={{ marginBottom: "16px" }}>
                    <p
                      style={{
                        fontWeight: "700",
                        paddingBottom: "8px",
                      }}
                    >
                      Documents
                    </p>
                    <div
                      style={{
                        display: "grid",
                        gap: "16px",
                      }}
                    >
                      {data?.insuranceDocuments?.length ? (
                        data.insuranceDocuments.map((document, index) => (
                          <div
                            className="cursor-pointer"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "5px 12px",
                              backgroundColor: "rgb(245, 245, 245)",
                              borderRadius: "8px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "12px",
                              }}
                            >
                              <FaRegFileLines />
                              <div>
                                <div
                                  className="t-t-u"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {`${data.description || ""} Document - ${
                                    index + 1
                                  }`}
                                </div>
                                <div
                                  style={{
                                    fontSize: "10px",
                                    color: "#7D7D7D",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {document.description || "Document"}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                              }}
                            >
                              <NavLink
                                to={`/doc?next=${encodeURIComponent(
                                  window.location.pathname +
                                    window.location.search
                                )}&fileUrl=${encodeURIComponent(
                                  document?.fileName || ""
                                )}&title=${encodeURIComponent(
                                  `${data.description || ""} Document - ${
                                    index + 1
                                  }`
                                )}`}
                                state={location.state}
                              >
                                <span
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  title="View file"
                                >
                                  <LuEye />
                                </span>
                              </NavLink>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px 12px",
                            backgroundColor: "rgb(245, 245, 245)",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                No document found.
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            {permissions?.isUpdateAllowed && (
              <ButtonLightTextIcon
                title="Edit Details"
                onClick={() => {
                  updateQueryParam("showId");
                  if (data?.id) {
                    navigate(
                      `/edit-insurance?next=${encodeURIComponent(window.location.pathname + "?" + queryParams.toString())}`,
                      { state: { id: data?.id } }
                    );
                  }
                }}
                icon={<FiEdit />}
              />
            )}
          </div>
        </div>
      </section>
    </section>
  );
};

export default InsuranceDetails;
