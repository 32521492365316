import React, { useState, useEffect } from "react";
import HalfCircleChart from "../../components/charts/HalfCircleChart";
import DateRangeInput from "../../components/ui/DateRangeInput";
import { backgroundColor, emptyArcData } from "./ChartConfig";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL } from "../../utilities/constants";
import { handleApiCall, jsonToQueryParams } from "../../utilities/helpers";
import { set } from "date-fns";

const ActvitiesArc = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [activitiesData, setActivitiesData] = useState({
    totalActivities: 0,
    ongoingActivities: 0,
    completedActivities: 0,
  });
  const [controller, setController] = useState(null);

  const fetchData = async () => {
    setActivitiesData({
      totalActivities: 0,
      ongoingActivities: 0,
      completedActivities: 0,
    });
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;

    const query = jsonToQueryParams({
      startDate: startDate || null,
      endDate: endDate || null,
    });
    setLoading(true);

    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/analytics/activity-overview?${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setActivitiesData({
          totalActivities: response.data.total,
          ongoingActivities: response.data.incomplete,
          completedActivities: response.data.completed,
        });
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const halfCircleData = {
    total: activitiesData.totalActivities,
    divisions: [
      activitiesData.completedActivities,
      activitiesData.ongoingActivities,
    ],
    colors: [backgroundColor[1], backgroundColor[0]],
    labels: ["Completed", "Ongoing"],
  };

  return (
    <div className="chart-card">
      <div
        className="flex center-center justify-between flex-row of-hid"
        style={{
          padding: "16px",
          gap: "16px",
        }}
      >
        <p className="f-w-700">Activity Progress</p>
        <div>
          <DateRangeInput
            initialRange={{
              startDate: startDate || null,
              endDate: endDate || null,
            }}
            onApply={(v) => {
              setStartDate(v?.startDate || null);
              setEndDate(v?.endDate || null);
            }}
            onCancel={(v) => {
              setStartDate(null);
              setEndDate(null);
            }}
          />
        </div>
      </div>
      <div style={{ height: "300px" }}>
        {loading ? (
          <div className="flex center-center" style={{ height: "100%" }}>
            <LoaderIcon
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </div>
        ) : activitiesData.totalActivities ||
          activitiesData.completedActivities ||
          activitiesData.ongoingActivities ? (
          <HalfCircleChart
            {...halfCircleData}
            title_count={activitiesData.totalActivities}
            title_heading={"Total Activity"}
          />
        ) : (
          <HalfCircleChart
            {...emptyArcData}
            title_count={0}
            title_heading={"No Data Available"}
          />
        )}
      </div>
    </div>
  );
};

export default ActvitiesArc;
