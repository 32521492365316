import React, { useEffect, useState } from "react";
import LineChart from "../../components/charts/LineChart";
import { backgroundColor, chartOptions, emptyLineData } from "./ChartConfig";
import LoaderIcon from "../../components/ui/LoaderIcon";
import DateRangeInput from "../../components/ui/DateRangeInput";
import { handleApiCall, jsonToQueryParams } from "../../utilities/helpers";
import { API_URL } from "../../utilities/constants";
import MonthRangeInput from "../../components/ui/MonthRangeInput";

const BillLine = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [yearMonth1, setYearMonth1] = useState(
    `${currentYear}-${currentMonth < 10 ? "0" : ""}${currentMonth}`
  );
  const [yearMonth2, setYearMonth2] = useState(
    `${currentYear}-${currentMonth - 1 < 10 ? "0" : ""}${currentMonth - 1}`
  );
  const [controller, setController] = useState(null);
  const [data, setData] = useState([]);
  const [label1, setLabel1] = useState([]);
  const [label2, setLabel2] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setData([]);
    setLineData((prev) => ({
      ...prev,
      labels: [],
      datasets: [
        {
          ...prev.datasets[0],
          data: [],
        },
        {
          ...prev.datasets[1],
          data: [],
        },
      ],
    }));
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;

    const query = jsonToQueryParams({
      yearMonth1: yearMonth1 || null,
      yearMonth2: yearMonth2 || null,
    });
    setLoading(true);

    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/analytics/bill-generated?${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setData(response.data);
        const month1Data = response.data.month1.map((item) => item.count);
        const month2Data = response.data.month2.map((item) => item.count);
        const maxWeeks = Math.max(month1Data.length, month2Data.length);
        const labels = Array.from(
          { length: maxWeeks },
          (_, i) => `Week ${i + 1}`
        );
        const label1 = response.data.month1.map(
          (item) =>
            `${item.count || 0} Bill Generated, Amount $${item.amount || 0}`
        );
        const label2 = response.data.month2.map(
          (item) =>
            `${item.count || 0} Bill Generated, Amount $${item.amount || 0}`
        );
        setLabel1(label1);
        setLabel2(label2);

        setLineData((prev) => ({
          labels,
          datasets: [
            {
              ...prev.datasets[0],
              data: month1Data,
            },
            {
              ...prev.datasets[1],
              data: month2Data,
            },
          ],
        }));
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    setLineData((prev) => ({
      ...prev,
      labels: [],
      datasets: [
        {
          ...prev.datasets[0],
          label: `${yearMonth1} Bill Generated`,
          data: [],
        },
        {
          ...prev.datasets[1],
          label: `${yearMonth2} Bill Generated`,
          data: [],
        },
      ],
    }));
  }, [yearMonth1, yearMonth2]);

  const [lineData, setLineData] = useState({
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: `In ${yearMonth1} Bill Generated`,
        data: [],
        backgroundColor: backgroundColor[0],
        borderColor: backgroundColor[0],
        borderWidth: 1,
        barThickness: 20,
      },
      {
        label: `In ${yearMonth2} Bill Generated`,
        data: [],
        backgroundColor: backgroundColor[1],
        borderColor: backgroundColor[1],
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "40%",
    animation: {
      animateRotate: true,
      animateScale: true,
    },
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const datasetIndex = context.datasetIndex;
            const dataIndex = context.dataIndex;
            return datasetIndex === 0 ? label1[dataIndex] : label2[dataIndex];
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
    },
  };

  return (
    <div className="chart-card">
      <div
        className="flex center-center justify-between flex-row of-hid"
        style={{
          padding: "16px",
          gap: "16px",
        }}
      >
        <p className="f-w-700"> Bills Generated</p>
        <div>
          <MonthRangeInput
            initialValue={{
              yearMonth1: yearMonth1 ? yearMonth1 : null,
              yearMonth2: yearMonth2 ? yearMonth2 : null,
            }}
            onApply={(v) => {
              setYearMonth1(v.yearMonth1);
              setYearMonth2(v.yearMonth2);
            }}
            onCancel={() => {
              setYearMonth1(null);
              setYearMonth2(null);
            }}
          />
        </div>
      </div>
      <div style={{ height: "300px" }}>
        {loading ? (
          <div className="flex center-center" style={{ height: "100%" }}>
            <LoaderIcon
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </div>
        ) : data.length === 0 ? (
          <LineChart data={emptyLineData} options={{ chartOptions }} />
        ) : (
          <LineChart
            data={lineData}
            options={{
              ...options,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default BillLine;
