import { createContext, useContext, useState, useCallback } from "react"; 
import SingleToast from "../components/common/SingleToast";

const UIContext = createContext();

const UIProvider = ({ children }) => {  
    const [permissions, setPermissions] = useState(null);
    const [profile, setProfile] = useState(null);
    const [status, setStatus] = useState("idle"); // "idle", "loading", "success", "error"
	const [toastMessage, setToastMessage] = useState([]); 

	const addToastMessage = (d) => {
		setToastMessage((toastMessage) => [
			{
				...d,
				id: Date.now(),
			},
			...toastMessage,
		]);
	};

	const removeToast = useCallback((id) => {
		setToastMessage((prevToasts) =>
			prevToasts.filter((toast) => toast.id !== id)
		);
	}, []);

	return (
		<UIContext.Provider
			value={{   
				addToastMessage,
				profile, 
				setProfile, 
				status, 
				setStatus,
				setPermissions,
				permissions,
			}}>
			{toastMessage.length > 0 && ( 
				<div className="toast-messages" >
					{toastMessage.map((t, i) => (
						<SingleToast
							data={t}
							key={t.id}
							removeToast={removeToast}
						/>
					))}
				</div> 
			)}
			{children}
		</UIContext.Provider>
	);
};

const useUI = () => {
	const context = useContext(UIContext);
	if (!context) {
		throw new Error("Curate Provider error.");
	}
	return context;
};


export { UIProvider, useUI };