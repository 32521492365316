import { useState } from "react";
import { decodeModuleAccessFromToken, formatRateUSD, formatServerDate } from "../../utilities/helpers";
import { NavLink } from "react-router-dom";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import FullScreenBillDetailsModal from "../bill/FullScreenBillDetailsModal";
import { FiEdit } from "react-icons/fi";
import { TbCalendarShare, TbUserCircle } from "react-icons/tb";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { MdOutlineLocalActivity } from "react-icons/md";
import { FaUserDoctor } from "react-icons/fa6";
import { IoMdOpen } from "react-icons/io";


export default function BillCard({ bill }) {
  const [show, setShow] = useState(false);
  const patientPermissions = decodeModuleAccessFromToken("patients");
  const activitiePermissions = decodeModuleAccessFromToken("activities");
  return (
    <>
      {!show ? (
        <div
          style={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "16px",
            marginBottom: "16px",
          }}
        >
          <div
            style={{
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%", // Ensure the container takes full height
            }}
          >
            <div>
              <div
                className="flex center-center"
                style={{
                  fontSize: "1.125rem",
                  justifyContent: "space-between",
                }}
              >
                <span>Bill #{bill?.id}</span>
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: "normal",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></span>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  justifyContent: "space-between",
                  paddingTop: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div
                      className="color-light flex "
                      style={{
                        fontSize: "0.875rem",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span>
                        <TbUserCircle />
                      </span>
                      <div>
                        Patient Name:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {`${
                            bill?.activity?.patient?.firstName
                              ? bill.activity.patient.firstName
                                  .charAt(0)
                                  .toUpperCase() +
                                bill.activity.patient.firstName.slice(1)
                              : "NA"
                          } 
                        ${
                          bill?.activity?.patient?.lastName
                            ? bill.activity.patient.lastName
                                .charAt(0)
                                .toUpperCase() +
                              bill.activity.patient.lastName.slice(1)
                            : ""
                        }`}
                        </span>
                      </div>
                    </div>
                    <div
                      className="color-light flex "
                      style={{
                        fontSize: "0.875rem",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span>
                        <AiOutlineFieldNumber />
                      </span>
                      ID: {bill?.activity?.patient?.id || "NA"}
                    </div>
                    <div
                      className="color-light flex "
                      style={{
                        fontSize: "0.875rem",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span>
                        <TbCalendarShare />
                      </span>
                      Born:{" "}
                      {bill?.activity?.patient?.dateOfBirth
                        ? formatServerDate(bill?.activity?.patient?.dateOfBirth)
                        : "NA"}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div
                      className="color-light flex "
                      style={{
                        fontSize: "0.875rem",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span>
                        <MdOutlineLocalActivity />
                      </span>
                      Activity: {bill?.activity?.id}
                    </div>
                    <div
                      className="color-light flex "
                      style={{
                        fontSize: "0.875rem",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span>
                        <TbCalendarShare />
                      </span>
                      DOS:{" "}
                      {bill?.activity?.patient?.serviceDate
                        ? formatServerDate(bill?.activity?.patient?.serviceDate)
                        : "NA"}
                    </div>
                    <div
                      className="color-light flex "
                      style={{
                        fontSize: "0.875rem",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span>
                        <FaUserDoctor />
                      </span>
                      Surgeon:{" "}
                      {bill?.activity?.surgeon?.firstName
                        ? bill?.activity?.surgeon?.firstName +
                          " " +
                          (bill?.activity?.surgeon?.middleName || "")
                        : "NA"}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="color-light "
                    style={{
                      fontSize: "0.875rem",
                      alignItems: "center",
                      gap: "2px",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="flex"
                      style={{ justifyContent: "space-between", gap: "10px" }}
                    >
                      {" "}
                      Bill Amount :{" "}
                      <span>
                        {bill?.amount ? formatRateUSD(bill?.amount) : "NA"}
                      </span>
                    </p>

                    {bill?.billType === "billable" && (
                      <>
                        <p
                          className="flex"
                          style={{
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          Insurance Payment Received:{" "}
                          <span
                            style={{
                              color: bill?.insurancePaymentReceived
                                ? "green"
                                : "red",
                            }}
                          >
                            {bill?.insurancePaymentReceived
                              ? formatRateUSD(bill?.insurancePaymentReceived)
                              : "NA"}
                          </span>
                        </p>
                        <p
                          className="flex"
                          style={{
                            justifyContent: "space-between",
                            gap: "10px",
                            fontWeight: "bold",
                            padding: "10px 0",
                          }}
                        >
                          Outstanding Amount:{" "}
                          <span>
                            {formatRateUSD(
                              Math.max(
                                0,
                                bill?.amount - bill?.insurancePaymentReceived
                              )
                            )}{" "}
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="flex"
                style={{
                  marginTop: "16px",
                  justifyContent: "flex-end", // Align buttons to the right
                  gap: "10px", // Add gap between buttons
                }}
              >
                {patientPermissions?.isUpdateAllowed && (
                  <div>
                    <NavLink
                      to={`/edit-patient?next=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                      state={{ id: bill?.activity?.patient?.id }}
                    >
                      <ButtonLightTextIcon
                        title="Edit Patient"
                        disabled={
                          bill?.invoiceStatus === 2 || bill?.invoiceStatus === 4
                        }
                        icon={<FiEdit />}
                      />
                    </NavLink>
                  </div>
                )}
                {activitiePermissions?.isUpdateAllowed && (
                  <div>
                    <NavLink
                      to={
                        bill?.activity?.id
                          ? `/edit-activity?next=${encodeURIComponent(
                              window.location.pathname + window.location.search
                            )}`
                          : "#"
                      }
                      state={{ id: bill?.activity?.id }}
                    >
                      <ButtonLightTextIcon
                        title={"Edit Activity "}
                        disabled={
                          bill?.invoiceStatus === 2 || bill?.invoiceStatus === 4
                        }
                        icon={<FiEdit />}
                      />
                    </NavLink>
                  </div>
                )}

                <ButtonLightTextIcon
                  title={"Bill Detail"}
                  icon={<IoMdOpen />}
                  onClick={() => setShow(true)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <FullScreenBillDetailsModal
          title="Bill Detail"
          id={bill.id}
          setShow={setShow}
          editEnabled={true}
        />
      )}
    </>
  );
}
