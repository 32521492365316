import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PaginatedDropdown, {
	mapOptions,
} from "../../components/ui/PaginatedDropdown";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useUI } from "../../context/ui";
import { API_URL } from "../../utilities/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { handleApiCall } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import useQueryParams from "../../hooks/useQueryParams";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";

const UpdateProcedureForm = ({ procedureData }) => {
	const navigate = useNavigate();
	const { addToastMessage } = useUI();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(false);
	const { queryParams, updateQueryParam } = useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";

	const [initialValues, setInitialValues] = useState({
		procedureName: "",
		specialityId: null,
		cptCode: "",
		cptDescription: "",
		relatedDxCode: "",
	});

	const validationSchema = Yup.object({
		procedureName: Yup.string().required("Name is required"),
		specialityId: Yup.mixed()
			.nullable()
			.test(
				"Specialty-required",
				"Specialty is Required",
				function (value) {
					return value !== null && value.value;
				}
			),
		cptCode: Yup.string().required("CPT Code is required"),
		cptDescription: Yup.string().nullable(),
		relatedDxCode: Yup.string().nullable(),
	});

	useEffect(() => {
		setInitialValues({
			id: procedureData?.id,
			procedureName: procedureData?.procedureName || null,
			specialityId: procedureData?.specialtyId || null,
			specialityId: procedureData?.specialty
				? {
						value: procedureData?.specialty?.id,
						label: procedureData?.specialty?.name,
					}
				: null,
			cptDescription: procedureData?.cptDescription || null,
			cptCode: procedureData?.cptCode || null,
			relatedDxCode: procedureData?.relatedDxCode || null,
		});
	}, [procedureData]);

	const updateProcedure = async (values) => {
		setLoading(true);
		setStatus("");

		try {
			const payload = {
				procedureName: values?.procedureName || null,
				specialityId: values?.specialityId?.value || null,
				cptCode: values.cptCode || null,
				cptDescription: values.cptDescription || null,
				relatedDxCode: values.relatedDxCode || null,
			};
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/procedure-list/${values?.id}`,
				{
					method: "PUT",
					body: payload,
				}
			);
			if (response.responseStatus === true) {
				setStatus(
					"You have successfully updated a procedure, thank you."
				);
				addToastMessage({
					status: true,
					message: `You have successfully updated a procedure, thank you.`,
				});
				if (nextUrl) {
					navigate(nextUrl);
				} else {
					navigate("/procedures");
				}
				return true;
			} else {
				addToastMessage({
					status: false,
					message: `Something went wrong, please try again later.`,
				});
				return false;
			}
		} catch (e) {
			addToastMessage({
				status: false,
				message: "Something went wrong, please try again later.",
			});
			console.error("Error:", e);
			return false;
		} finally {
			setLoading(false);
		}
	};

	const labelSpecilityFunc = (o) => `${o.name}`;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				const api = await updateProcedure(values);
				if (api) {
					resetForm();
				} else {
					setStatus("Something went wrong, please try again later.");
				}
			}}>
			{({
				values,
				errors,
				touched,
				setFieldValue,
				validateForm,
				handleSubmit,
				setFieldTouched,
			}) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td>
										<Label title='Name' required={true} />
										<Field
											name='procedureName'
											type='text'
											placeholder='Enter Name'
											className={`primary ${
												errors.procedureName &&
												touched.procedureName
													? "error"
													: ""
											}`}
										/>
									</td>
									<td>
										<Label
											title='Specialty'
											required={true}
										/>
										<PaginatedDropdown
											datakey='records'
											url={`${API_URL}/api/1.0.0/reference/specialities?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelSpecilityFunc
												)
											}
											setTouched={() =>
												setFieldTouched(
													"specialityId",
													true
												)
											}
											selectedValue={values.specialityId}
											placeHolder='Search'
											error={
												errors.specialityId &&
												touched.specialityId
											}
											onChange={(v) => {
												setFieldValue(
													"specialityId",
													v
												);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label
											title='CPT code'
											required={true}
										/>
										<Field
											name='cptCode'
											type='text'
											placeholder='CPT code'
											className={`primary ${
												errors.cptCode &&
												touched.cptCode
													? "error"
													: ""
											}`}
										/>
									</td>
									<td>
										<Label title='CPT Description' />
										<Field
											name='cptDescription'
											type='text'
											placeholder='CPT Description'
											className={`primary ${
												errors.cptDescription &&
												touched.cptDescription
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan='2'>
										<Label title='Related DX code' />
										<Field
											name='relatedDxCode'
											type='text'
											placeholder='Related Dx code'
											className={`primary ${
												errors.relatedDxCode &&
												touched.relatedDxCode
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => {
								if (nextUrl) {
									navigate(nextUrl);
								} else {
									navigate("/procedures");
								}
							}}
							icon={
								<TbArrowBackUp />
							}
						/>
						<ButtonTextIcon
							type='submit'
							disabled={loading}
							title='Update Now'
							onClick={async () => {
								const errors = await validateForm();
								if (Object.keys(errors).length > 0) {
									const firstErrorMessage =
										Object.values(errors)[0];
									addToastMessage({
										status: false,
										message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
									});
								}
								handleSubmit();
							}}
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<FiArrowRightCircle />
								)
							}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default UpdateProcedureForm;
