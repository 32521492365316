import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { getRandomColor, handleApiCall } from "../../utilities/helpers";
import InfoItem from "../../components/ui/InfoItem";
import { TbArrowBackUp, TbUserCircle } from "react-icons/tb";
import { PiPathBold } from "react-icons/pi";
import { GiMedicalThermometer } from "react-icons/gi";
import { FiEdit } from "react-icons/fi";

const PreferenceCardDetails = ({ setShow, id, setUpdateCard }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchPreferenceData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/preference-card/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      } else {
        setError("Failed to fetch Preference data.");
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch Preference data.");
      setLoading(false);
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchPreferenceData(id);
  }, [id]);
  const getTitleName = (title) => {
    try {
      return JSON.parse(title)?.name || "NA";
    } catch (error) {
      return "NA";
    }
  };
  return (
    <section
      style={{
        position: "fixed",
        inset: 0,
        zIndex: 9999,
        backgroundColor: "rgba(255, 255, 255, 0.86)",
      }}
    >
      <section
        className="col-1-1 flex center-center"
        style={{
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          id="activity-detail-box"
          style={{
            backgroundColor: "white",
          }}
        >
          <div className="flex center-left">
            <div>
              <h6 className="color-primary f-w-300">Preference Card Details</h6>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <ButtonLightTextIcon
                title="Cancel"
                onClick={() => setShow(false)}
                icon={<TbArrowBackUp />}
              />
            </div>
          </div>
          <div
            style={{
              height: "60vh",
              margin: "10px",
              overflowY: "scroll",
              padding: "20px 5px",
              overflowX: "hidden",
              scrollbarWidth: "thin",
            }}
          >
            {loading && (
              <div
                className="col-1-1"
                style={{
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoaderIcon
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
            )}
            {!loading && error && (
              <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
                {error}
              </p>
            )}
            {data && !loading && !error && (
              <div className="activity-card pos-rel">
                <div
                  style={{
                    position: "absolute",
                    content: "",
                    height: "4px",
                    left: "-1px",
                    right: "-1px",
                    bottom: "-2px",
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                ></div>
                <div
                  className="col-1-1 flex center-left"
                  style={{
                    gap: "10px",
                    paddingBottom: "5px",
                  }}
                >
                  <h5 className="f-w-700">
                    <span className="f-w-300">#</span>
                    &nbsp;
                    {data?.id}
                  </h5>
                </div>
                <div
                  style={{
                    padding: "12px 0 12px 0 ",
                  }}
                >
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<TbUserCircle color={getRandomColor()} size={20} />}
                      label={"Procedure"}
                      name={getTitleName(data?.title) || "NA"}
                    />
                    
                  </div>
                  <div
                    className=" flex"
                    style={{
                      flexWrap: "wrap",
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <div>
                      <GiMedicalThermometer
                        color={getRandomColor()}
                        size={20}
                      />
                    </div>
                    <p
                      className="f-w-300"
                      style={{
                        color: "#aaaaaa",
                        fontSize: "10px",
                      }}
                      title="Tools"
                    >
                      Tools
                    </p>
                    <ul className="col-1-1 t-a-l">
                      {data.tools.split(", ").map((tool, index) => (
                        <li
                          style={{
                            listStyle: "none",
                            marginRight: "4px",
                            marginBottom: "4px",
                            borderRadius: "2px",
                            lineHeight: "32px",
                            padding: "10px 12px",
                            verticalAlign: "middle",
                          }}
                          key={index}
                          className=" bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                        >
                          <div className="flex center-left">
                            <p
                              className="of-hid font-size-12"
                              style={{
                                whiteSpace: "nowrap",
                                width: "108px",
                                textOverflow: "ellipsis",
                              }}
                              title={tool}
                            >
                              {tool}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<PiPathBold color={getRandomColor()} size={20} />}
                      label={"CPT Description"}
                      showfulltext={true}
                      style={{
                        wordBreak: "break-word",
                      }}
                      name={data?.description || "NA"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <ButtonLightTextIcon
              title="Edit Card"
              onClick={() => {
                setShow(false);
                setUpdateCard(true);
              }}
              icon={<FiEdit />}
            />
          </div>
        </div>
      </section>
    </section>
  );
};
export default PreferenceCardDetails;
    