import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useRef, useState } from "react";
import {
  ALLOWED_FILE_TYPES,
  API_URL,
  PLACE_OF_SERVICE,
} from "../../utilities/constants";
import PaginatedDropdown, {
  mapOptions,
} from "../../components/ui/PaginatedDropdown";
import DragFileUpload from "../../components/ui/DragFileUpload";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import {
  convertTo12HourFormat,
  convertToMMDDYYYY,
  formatServerDate,
  getUserTypeInfo,
  handleApiCall,
  handleDatePickerChange,
  labelAssistantsFunc,
  labelPatientsFunc,
  labelProcedureFunc,
  sleep,
} from "../../utilities/helpers";
import { useUI } from "../../context/ui";
import AddPatientForm from "./AddPatientForm";
import { useLocation, useNavigate } from "react-router-dom";
import DatePickerInput from "../../components/ui/DatePickerInput";
import Label from "../../components/ui/Label";
import { FiArrowRightCircle, FiPlusCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { MdCancel, MdOutlineDeleteSweep } from "react-icons/md";
import { FcInfo } from "react-icons/fc";

const AddForm = () => {
  const navigate = useNavigate();
  const { profile } = useUI();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [procedures, setProcedures] = useState(null);
  const { addToastMessage } = useUI();
  const [uploaded, setUploaded] = useState(false);
  const [show, setShow] = useState(false);
  const initialValues = {
    document_detail: [],
    user_id: profile.id,
    patient_id: null,
    patient_name: "",
    event_type: null,
    procedure_id: [],
    paytoadmin_id: null,
    account_number: "",
    event_surgeon: null,
    event_surgical_assitant: null,
    event_facility: null,
    admission_date: "",
    event_start_date: new Date().toISOString().split("T")[0],
    event_start_time: "",
    event_end_date: "",
    event_end_time: "",
    placeOfService: "",
    event_flags: null,
    modified_by: profile.id,
    notes: "",
  };

  const validationSchema = Yup.object({
    // event_type: Yup.mixed()
    // 	.nullable()
    // 	.test("event-type-required", "Required",
    // 		function (value) { return value !== null && value.value; }),
    // event_surgeon: Yup.mixed()
    // 	.nullable()
    // 	.test("surgeon-required", "Required",
    // 		function (value) { return value !== null && value.value; }),
    // event_facility: Yup.mixed()
    // 	.nullable()
    // 	.test("facility-required", "Required",
    // 		function (value) { return value !== null && value.value; }),
    // event_surgical_assitant: Yup.mixed()
    // 	.nullable()
    // 	.test("surgical-assitant-required", "Required",
    // 		function (value) { return value !== null && value.value; }),
    // event_start_date: Yup.string().required("Required"),
    // event_start_time: Yup.string().required("Required"),
    // placeOfService: Yup.string().required("Required"),
    event_start_date: Yup.string().notRequired(),
    event_start_time: Yup.string().notRequired(),
    event_end_date: Yup.date()
      .test(
        "is-after-start-date",
        "End date must be greater than the start date unless they are the same",
        function (value) {
          const { event_start_date, event_start_time, event_end_time } =
            this.parent;

          if (!event_start_date || !value) {
            return true; // No validation if start or end date is missing
          }

          const startDate = new Date(event_start_date);
          const endDate = new Date(value);

          // Check if the dates are the same
          if (startDate.toDateString() === endDate.toDateString()) {
            return true; // No validation required if the dates are the same
          }
          return endDate > startDate;
        }
      )
      .notRequired(),
    event_end_time: Yup.string()
      .test(
        "is-after-start-time",
        "End time must be greater than start time if the dates are the same",
        function (value) {
          const { event_start_date, event_end_date, event_start_time } =
            this.parent;

          if (!event_start_date || !event_end_date) {
            return true; // No validation if dates are missing
          }

          const startDate = new Date(event_start_date);
          const endDate = new Date(event_end_date);

          // Only validate times if the dates are the same
          if (startDate.toDateString() === endDate.toDateString()) {
            if (event_start_time && value) {
              const [startHours, startMinutes] = event_start_time
                .split(":")
                .map(Number);
              const [endHours, endMinutes] = value.split(":").map(Number);

              const startTime = new Date();
              startTime.setHours(startHours, startMinutes);

              const endTime = new Date();
              endTime.setHours(endHours, endMinutes);

              return endTime > startTime;
            }
          }

          return true; // Pass validation if the dates are different or times are not provided
        }
      )
      .notRequired(),
  });

  const createActivity = async (values) => {
    setLoading(true);
    setStatus("");
    try {
      const files = values.document_detail.map((o) => ({
        type: o.file_type,
        fileUrl: o.file,
      }));

      /* ADD ACTIVITY VALIDATION START */
      const hasFile = files.length;
      const hasRequiredFiled =
        values.event_facility?.value &&
        values.patient_id?.value &&
        values.event_surgical_assitant;
      if (!hasFile && !hasRequiredFiled) {
        addToastMessage({
          status: false,
          message: `Please upload a file or select Facility, Patient, and Assistant to proceed with creating the activity.`,
        });
        setStatus(
          "Please upload a file or select Facility, Patient, and Assistant to proceed with creating the activity."
        );
        return false;
      }
      /* ADD ACTIVITY VALIDATION START */

      /* ADD FACESHEET VALIDATION START */
      // const hasFacesheet = files.some((file) => file.type === "facesheet");
      // if (!hasFacesheet) {
      // 	addToastMessage({
      // 		status: false,
      // 		message: `Please add a facesheet to proceed with creating the activity.`,
      // 	})
      // 	setStatus("Please add a facesheet to proceed with creating the activity.");
      // 	return false;
      // }
      /* ADD FACESHEET VALIDATION END   */

      const payload = {};

      if (values.document_detail.length) payload.files = files;
      if (profile?.id) payload.user_id = profile.id;
      if (values.event_surgeon?.value)
        payload.event_surgeon = values.event_surgeon.value + "";
      if (values.patient_id?.value)
        payload.patient_id = values.patient_id.value + "";
      if (values.paytoadmin_id)
        payload.paytoadmin_id = values.paytoadmin_id.value + "";
      payload.modified_by = profile.id;
      if (values.patient_name) payload.patient_name = values.patient_name;
      if (values.procedure_id.length)
        payload.procedure_id = values.procedure_id
          .map((item) => item.val)
          .join(",");
      if (values.event_facility?.value)
        payload.event_facility = values.event_facility.value + "";
      if (values.event_surgical_assitant?.value)
        payload.event_surgical_assitant =
          values.event_surgical_assitant.value + "";
      if (values.account_number)
        payload.account_number = values.account_number + "";
      if (values.placeOfService) payload.placeOfService = values.placeOfService;
      if (values.event_start_date)
        payload.event_start_date = convertToMMDDYYYY(values.event_start_date);
      if (values.event_end_date)
        payload.event_end_date = convertToMMDDYYYY(values.event_end_date);
      if (values.event_start_time)
        payload.event_start_time = convertTo12HourFormat(
          values.event_start_time
        );
      if (values.event_end_time)
        payload.event_end_time = convertTo12HourFormat(values.event_end_time);
      if (values.event_type?.value)
        payload.event_type = values.event_type.value + "";
      if (values.event_flags?.value)
        payload.event_flags = values.event_flags.value + "";
      if (values.admission_date)
        payload.admission_date = convertToMMDDYYYY(values.admission_date);
      if (values.notes) payload.event_notes = values.notes;

      const resp = await handleApiCall(API_URL + `/api/1.0.0/activity`, {
        method: "POST",
        body: payload,
      });
      if (resp.responseStatus == true) {
        return true;
      } else {
        addToastMessage({
          status: false,
          message: `Something went wrong, please try again later.`,
        });
        return false;
      }
    } catch (e) {
      addToastMessage({
        status: false,
        message: "Something went wrong, please try again later.",
      });
      console.error("Error:", e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const labelSurgeonFunc = (o) =>
    `${o?.firstName} ${o?.middleName} ${o?.lastName}`;
  const labelFacilityFunc = (o) => `${o.facilityName}`;
  const labelPayToFunc = (o) => `${o.payToName}`;
  const labelEventFlagsFunc = (o) => `${o.scheduleFlag}`;
  const labelEventTypeFunc = (o) => `${o.eventTypeName}`;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await createActivity(values);
        if (api) {
          resetForm();
          setStatus("You have successfully created an activity, thank you.");
          addToastMessage({
            status: true,
            message: `You have successfully created an activity, thank you.`,
          });
          navigate("/activities");
        }
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
        validateForm,
        setFieldTouched,
      }) => (
        <Form>
          <div className="col-1-1">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Label title="Event Type" required={false} />
                    <PaginatedDropdown
                      url={`${API_URL}/api/1.0.0/reference/event-type?`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelEventTypeFunc)
                      }
                      setTouched={() => setFieldTouched("event_type", true)}
                      error={errors.event_type && touched.event_type}
                      placeHolder="Search"
                      selectedValue={values.event_type}
                      onChange={(v) => {
                        setFieldValue("event_type", v);
                      }}
                    />
                  </td>
                  <td width="50%">
                    <Label title="Patient" />
                    <PaginatedDropdown
                      url={`${API_URL}/api/1.0.0/reference/patients?`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelPatientsFunc)
                      }
                      error={errors.patient_id && touched.patient_id}
                      setTouched={() => setFieldTouched("patient_id", true)}
                      selectedValue={values.patient_id}
                      placeHolder="Search"
                      onChange={(v) => {
                        if (v) {
                          setFieldValue("patient_id", v);
                          setFieldValue("patient_name", v.label);
                          setFieldValue("account_number", v.obj.accountNumber);
                        } else {
                          setFieldValue("patient_id", null);
                          setFieldValue("patient_name", "");
                          setFieldValue("account_number", "");
                        }
                      }}
                    />
                    <p
                      onClick={() => {
                        setShow(true);
                      }}
                      style={{
                        paddingTop: "4px",
                        color: "#8f8f8f",
                      }}
                    >
                      Could't find the patient?{" "}
                      <span className="cursor-pointer color-secondary hover-color-primary hover-t-d-ul transition">
                        Click to add
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <AddPatientForm
                      setShow={setShow}
                      show={show}
                      callback={(v) => {
                        setFieldValue("patient_id", v);
                        setFieldValue("patient_name", v.label);
                        setFieldValue("account_number", "");
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <NestedDocumentForm
                      onAdd={(newDocument) => {
                        setFieldValue("document_detail", [
                          ...values.document_detail,
                          newDocument,
                        ]);
                      }}
                      p={values?.patient_id}
                      list={values?.document_detail}
                      onRemove={(index) => {
                        const updatedData = values.document_detail.filter(
                          (_, i) => i !== index
                        );
                        setFieldValue("document_detail", updatedData);
                      }}
                      setUploaded={setUploaded}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Label title="Procedure Name/CPT Code" />
                    <PaginatedDropdown
                      url={`${API_URL}/api/1.0.0/reference/procedure?`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelProcedureFunc)
                      }
                      error={errors.procedure_id && touched.procedure_id}
                      setTouched={() => setFieldTouched("procedure_id", true)}
                      selectedValue={procedures}
                      placeHolder="Search"
                      onChange={async (v) => {
                        if (v) {
                          setProcedures(v);
                          const p = values.procedure_id;
                          const n = {
                            val: v.value,
                            name: v.label,
                          };
                          if (!p.some((item) => item.val === v.value)) {
                            setFieldValue("procedure_id", [
                              ...values.procedure_id,
                              n,
                            ]);
                          }
                          await sleep();
                          setProcedures(null);
                        }
                      }}
                    />
                    {values.procedure_id.length > 0 && (
                      <ul
                        className="col-1-1 t-a-l"
                        style={{
                          padding: "10px 0",
                        }}
                      >
                        {values.procedure_id.map((p, j) => (
                          <li
                            data-tooltip={`Click to delete the procedure.`}
                            onClick={() => {
                              const filtered = values.procedure_id.filter(
                                (obj) => obj.val !== p.val
                              );
                              setFieldValue("procedure_id", filtered);
                            }}
                            style={{
                              listStyle: "none",
                              marginRight: "4px",
                              marginBottom: "4px",
                              borderRadius: "2px",
                              lineHeight: "32px",
                              padding: "10px 12px",
                              verticalAlign: "middle",
                            }}
                            key={j}
                            className="opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                          >
                            <div className="flex center-left">
                              <p
                                className="of-hid"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "108px",
                                  textOverflow: "ellipsis",
                                  fontSize: "12px",
                                }}
                              >
                                {p.name}
                              </p>
							  <MdCancel  size={28}/>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                    <p
                      style={{
                        paddingTop: "4px",
                        color: "#8f8f8f",
                      }}
                    >
                      Couldn't find the procedure?{" "}
                      <a
                        href="/add-procedure"
                        target="_blank"
                        className="cursor-pointer color-secondary hover-color-primary hover-t-d-ul transition"
                      >
                        Click to add
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Label title="Account Number" />
                    <Field
                      type="text"
                      placeholder="Enter Acc. No."
                      name="account_number"
                      className={`col-1-1 primary ${
                        errors.account_number && touched.account_number
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="Surgeon" required={false} />
                    <PaginatedDropdown
                      url={`${API_URL}/api/1.0.0/reference/surgeon?`}
                      error={errors.event_surgeon && touched.event_surgeon}
                      selectedValue={values.event_surgeon}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelSurgeonFunc)
                      }
                      setTouched={() => setFieldTouched("event_surgeon", true)}
                      placeHolder="Search"
                      onChange={(v) => {
                        setFieldValue("event_surgeon", v);
                      }}
                    />
                  </td>
                  <td>
                    <Label title="Facility" required={false} />
                    <PaginatedDropdown
                      url={`${API_URL}/api/1.0.0/reference/facility?`}
                      error={errors.event_facility && touched.event_facility}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelFacilityFunc)
                      }
                      setTouched={() => setFieldTouched("event_facility", true)}
                      selectedValue={values.event_facility}
                      placeHolder="Search"
                      onChange={(v) => {
                        setFieldValue("event_facility", v);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="Assistant" required={false} />
                    <PaginatedDropdown
                      url={`${API_URL}/api/1.0.0/reference/assistants?`}
                      error={
                        errors.event_surgical_assitant &&
                        touched.event_surgical_assitant
                      }
                      selectedValue={values.event_surgical_assitant}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelAssistantsFunc)
                      }
                      setTouched={() =>
                        setFieldTouched("event_surgical_assitant", true)
                      }
                      placeHolder="Search"
                      onChange={(v) => {
                        setFieldValue("event_surgical_assitant", v);
                      }}
                    />
                  </td>
                  <td>
                    <Label title="Admission Date" />
                    <DatePickerInput
                      callback={(v) => {
                        setFieldValue("admission_date", v);
                      }}
                      value={values.admission_date}
                      error={errors.admission_date && touched.admission_date}
                      setTouched={() => setFieldTouched("admission_date", true)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="Start Date" required={false} />
                    <DatePickerInput
                      callback={(v) => {
                        setFieldValue("event_start_date", v);
                      }}
                      value={values.event_start_date}
                      error={
                        errors.event_start_date && touched.event_start_date
                      }
                      setTouched={() =>
                        setFieldTouched("event_start_date", true)
                      }
                    />
                  </td>
                  <td>
                    <Label title="Start Time" required={false} />
                    <Field
                      type="time"
                      name="event_start_time"
                      className={`col-1-1 primary ${
                        errors.event_start_time && touched.event_start_time
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="End Date" />
                    <DatePickerInput
                      callback={(v) => {
                        setFieldValue("event_end_date", v);
                      }}
                      value={values.event_end_date}
                      error={errors.event_end_date && touched.event_end_date}
                      setTouched={() => setFieldTouched("event_end_date", true)}
                    />
                    <ErrorMessage
                      name="event_end_date"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td>
                    <Label title="End Time" />
                    <Field
                      type="time"
                      name="event_end_time"
                      className={`col-1-1 primary ${
                        errors.event_end_time && touched.event_end_time
                          ? "error"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="event_end_time"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="Place of Service" required={false} />
                    <Field
                      as="select"
                      name="placeOfService"
                      className={`col-1-1 primary ${
                        errors.placeOfService && touched.placeOfService
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {PLACE_OF_SERVICE.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                  <td>
                    <Label title="Event Flags" />
                    <PaginatedDropdown
                      url={`${API_URL}/api/1.0.0/reference/event-flags?`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelEventFlagsFunc)
                      }
                      error={errors.event_flags && touched.event_flags}
                      setTouched={() => setFieldTouched("event_flags", true)}
                      selectedValue={values.event_flags}
                      placeHolder="Search"
                      onChange={(v) => {
                        if (v) {
                          setFieldValue("event_flags", v);
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Label title="Notes" />
                    <Field
                      as="textarea"
                      name="notes"
                      placeholder="Add Notes"
                      className={`primary ${
                        errors.notes && touched.notes ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <p
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        color: "#8f8f8f",
                      }}
                      className="l-h-1"
                    >
                      Click checkbox only if you do not know the end time.
                    </p>
                    <div>
                      <label className="cursor-pointer">
                        <div
                          className="flex center-left"
                          style={{ gap: "12px" }}
                        >
                          <Field
                            type="checkbox"
                            name="endTimeNotConfirmed"
                            className={`primary ${
                              errors.endTimeNotConfirmed &&
                              touched.endTimeNotConfirmed
                                ? "error"
                                : ""
                            }`}
                          />
                          <p className="color-light">End time not confirmed.</p>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => navigate("/activities")}
              icon={<TbArrowBackUp />}
            />
            <ButtonTextIcon
              type="button"
              disabled={loading || uploaded}
              title="Create Now"
              className="mar-l-a"
              onClick={async () => {
                const errors = await validateForm();
                if (Object.keys(errors).length > 0) {
                  const firstErrorMessage = Object.values(errors)[0];
                  addToastMessage({
                    status: false,
                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                  });
                }
                handleSubmit();
              }}
              icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
            />
          </div>
          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const NestedDocumentForm = ({ onAdd, onRemove, p, list, setUploaded }) => {
  const [addFile, setAddFile] = useState(true);
  const fileUploadRef = useRef();
  let typeCounters = {};
  const validationSchema = Yup.object().shape({
    file_type: Yup.string().required("File Type is required"),
    file: Yup.string().required("File is required"),
  });
  const handleReset = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFile();
    }
  };
  return (
    <Formik
      initialValues={{
        file_type: "",
        file: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        setAddFile(true);
        resetForm();
        handleReset();
      }}
    >
      {({
        errors,
        touched,
        setFieldValue,
        values,
        submitForm,
        validateForm,
      }) => (
        <Form>
          <table>
            <tbody>
              {list && (
                <tr>
                  <td colSpan="2">
                    <div className="inner-data">
                      <table>
                        <thead>
                          <tr>
                            <th className="color-primary">File Type</th>
                            <th className="color-primary">File</th>
                            <th
                              className="color-primary"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.length ? (
                            list.map((d, index) => {
                              if (index === 0) {
                                typeCounters = {};
                              }

                              const type = d.file_type;
                              typeCounters[type] =
                                (typeCounters[type] || 0) + 1;

                              return (
                                <tr key={index}>
                                  <td>
                                    <span className="badge color-primary">
                                      {d.file_type}
                                    </span>
                                  </td>
                                  <td className="color-primary t-t-u l-s-n ">{`${p?.label ? p?.label + "-" : ""}${d.file_type || ""}-${typeCounters[d.file_type]}`}</td>
                                  <td>
                                    <div className="flex center-center">
                                      <span
                                        className="cursor-pointer opl-tooltip"
                                        data-tooltip="Click to Remove File."
                                        onClick={() => {
                                          onRemove(index);
                                        }}
                                      >
                                        <MdOutlineDeleteSweep color="red" />
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="3">
                                <div className="t-a-c color-primary">
                                  {" "}
                                  No files uploaded.{" "}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
              {addFile ? (
                <tr>
                  <td colSpan="2">
                    <div className="flex center-center">
                      <div
                        className="opl-tooltip"
                        data-tooltip="Click to Add File."
                      >
                        <Label title="&nbsp;" />
                        <ButtonLightTextIcon
                          title="Add File"
                          type="button"
                          onClick={() => {
                            setAddFile((pre) => !pre);
                          }}
                          icon={<FiPlusCircle />}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  <tr>
                    <td colSpan="2">
                      <Label title="File Type" />
                      <Field
                        as="select"
                        name="file_type"
                        className={`col-1-1 primary ${
                          errors.file_type && touched.file_type ? "error" : ""
                        }`}
                      >
                        <option value="">Select</option>
                        {ALLOWED_FILE_TYPES.map(({ value, label }) => {
                          const isFacesheet = value === "facesheet";
                          const isFacesheetPresent = list.some(
                            (file) => file.file_type === "facesheet"
                          );

                          if (isFacesheet && isFacesheetPresent) return null;

                          return (
                            <option value={value} key={label}>
                              {label}
                            </option>
                          );
                        }).filter(Boolean)}
                      </Field>
                    </td>
                  </tr>
                  {values.file_type && (
                    <tr>
                      <td colSpan="2">
                        <div
                          className="flex center-left"
                          style={{ gap: "8px" }}
                        >
                          <div
                            className="opl-tooltip"
                            data-tooltip="Please only select PDF files for upload."
                          >
                            <Label title="Upload File" />
                          </div>
                          <div
                            className="opl-tooltip"
                            data-tooltip="Please only select PDF files for upload."
                          >
                            <FcInfo />
                          </div>
                        </div>
                        <DragFileUpload
                          ref={fileUploadRef}
                          fileType={values.file_type}
                          error={errors.file && touched.file}
                          afterUpload={async (url) => {
                            if (url) {
                              await setFieldValue("file", url);
                              setTimeout(async () => {
                                const e = await validateForm();
                                await submitForm();
                              }, 0);
                            } else {
                              await setFieldValue("file", "");
                            }
                          }}
                          setUploading={setUploaded}
                        />
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

export default AddForm;