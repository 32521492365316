import { useEffect, useRef, useState } from "react";
import ResultsPerPage from "../../components/common/ResultsPerPage";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useLocation, useNavigate } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import Label from "../../components/ui/Label";
import { GrPowerReset } from "react-icons/gr";

const InsuranceFilter = ({ resetFilters }) => {
  const debounceTimeout = useRef(null);
  const { updateQueryParam, queryParams } = useQueryParams();
  const [searchValue, setSearchValue] = useState(
    queryParams.get("description") || ""
  );
  const handleSearchChange = (value) => {
    setSearchValue(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      queryParams.set("pageNo", 0);
      updateQueryParam("description", value);
    }, 500);
  };
  return (
    <div className="col-1-1 flex center-left filters">
      <div>
        <Label variant="filter" title="Insurance Name" />
        <input
          className="primary"
          type="text"
          value={searchValue}
          placeholder="Eg: Primary"
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </div>
      <div className="flex center-left" style={{ gap: "12px" }}>
        <div>
          <Label variant="filter" title="Reset" />
          <ButtonLightTextIcon
            title="Reset"
            className="light"
            icon={
            <GrPowerReset />
            }
            onClick={() => {
              setSearchValue("");
              resetFilters();
            }}
          />
        </div>
        <div className="mar-l-a">
          <Label variant="filter" title="Per page" />
          <ResultsPerPage />
        </div>
      </div>
    </div>
  );
};

export default InsuranceFilter;
