import { useEffect, useState } from "react";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL } from "../../utilities/constants";
import {
  formatDateTime,
  formatRateUSD,
  handleApiCall,
} from "../../utilities/helpers";
import CustomModal from "../../components/common/CustomModal";
import ButtonTertiary from "../../components/ui/ButtonTertiary";
import FullScreenFileViewerModal from "../../components/ui/FullScreenFileViewerModal";
import { TbArrowBackUp } from "react-icons/tb";
import { MdCancel } from "react-icons/md";

const Caution = ({ title }) => (
  <p
    className="t-a-c"
    style={{
      fontSize: "14px",
      padding: "5px",
      color: "#777",
    }}
  >
    {title}
  </p>
);

const InvoiceHistory = ({ id, setShow }) => {
  const [datacount, setDatacount] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [noMoreOptions, setNoMoreOptions] = useState(false);

  const callApi = async () => {
    setLoading(true);
    try {
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/invoice/history/${id}?pageNo=${pageNo}&pageSize=5&sortOrder=DESC`,
        {
          method: "GET",
        }
      );
      const data = await response.data;
      if (response.responseStatus === true) {
        if (data.totalPages - 1 === pageNo) {
          setNoMoreOptions(true);
        }
        setData((prevData) =>
          pageNo === 0 ? data.list : [...prevData, ...data.list]
        );
        setDatacount((prevDataCount) =>
          pageNo === 0 ? data.list.length : prevDataCount + data.list.length
        );
      } else {
        setError(
          `${response.responseDescription || "Something went wrong, please try again later."}`
        );
      }
    } catch (e) {
      setError("An error occurred while retrieving Invoice history.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) callApi();
  }, [id, pageNo]);

  const handleScroll = () => {
    setPageNo((prevPage) => prevPage + 1);
  };

  if (!id) return null;

  return (
    <div
      className="box"
      id="activity-detail-box"
    >
      <div className="col-1-1 flex center-left">
        <h6 className="color-primary f-w-300">Invoice History</h6>
        <div className="mar-l-a cursor-pointer " onClick={() => setShow(false)}>
        <MdCancel  size={28}/>
        </div>
      </div>
      <div
        style={{
          height: "492px",
          overflowY: "auto",
          background: "white",
          padding: " 0px 10px",
          alignContent: "center",
        }}
      >
        <section>
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LoaderIcon
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
          )}
          {!loading && error && (
            <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
              {error}
            </p>
          )}
          {!error &&
            data &&
            datacount !== 0 &&
            data?.map(
              (
                {
                  invoiceNo,
                  totalAmount,
                  invoicePath,
                  excelPath,
                  facilityName,
                  numberOfBills,
                  createdAt,
                },
                i
              ) => (
                <div className="col-1-1" key={i}>
                  <div>
                    <div className="flex center-left">
                      <div
                        className="b-r-20"
                        style={{
                          padding: "12px 16px",
                          backgroundColor: "#DA914E",
                        }}
                      >
                        <p
                          className="color-white f-w-400 l-h-1 t-t-u"
                          style={{
                            fontSize: "11px",
                          }}
                        >
                          {"REGENERATED AT : " + formatDateTime(createdAt)}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        height: "16px",
                        width: "4px",
                        borderLeft: "dashed 2px rgb(196, 196, 196)",
                        marginLeft: "20px",
                      }}
                    ></div>
                    <div
                      className="b-r-5"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gridTemplateRows: "repeat(auto-fill, auto)",
                        gridColmnGap: "12px",
                        gridRowGap: "12px",
                        background: "#f4fbff",
                        padding: "16px",
                        border: "solid 1px #f0f0f0",
                      }}
                    >
                      <div>
                        <DetailRow
                          label="Number of Bills"
                          value={numberOfBills || "-"}
                        />
                      </div>
                      <div>
                        <DetailRow
                          label="Invoice No."
                          value={invoiceNo || "-"}
                        />
                      </div>
                      <div>
                        <DetailRow
                          label="Total Amount"
                          value={formatRateUSD(totalAmount) || "-"}
                        />
                      </div>
                      <div>
                        <DetailRow
                          label="Total Amount"
                          value={facilityName || "-"}
                        />
                      </div>
                      <div>
                        <CustomModal
                          selector={
                            <ButtonTertiary
                              actionType="download"
                              title="Download Invoice"
                            />
                          }
                          content={
                            <FullScreenFileViewerModal
                              fileUrl={invoicePath}
                              title={`INVOICE No- ${invoiceNo}`}
                            />
                          }
                        />
                      </div>
                      <div>
                        <a
                          href={excelPath}
                          download
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ButtonTertiary
                            actionType="download"
                            title="Download Excel"
                            style={{ background: "#03002e" }}
                          />
                        </a>
                      </div>
                    </div>
                    {datacount !== i + 1 && (
                      <div
                        style={{
                          height: "48px",
                          width: "4px",
                          borderLeft: "dashed 2px rgb(196, 196, 196)",
                          marginLeft: "20px",
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              )
            )}

          {!error && data && datacount !== 0 && (
            <>
              {!loading && !noMoreOptions && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleScroll();
                  }}
                >
                  <Caution title="Click to load more." />
                </div>
              )}
              {loading && (
                <Caution
                  title={
                    <>
                      Loading{" "}
                      <LoaderIcon
                        style={{
                          width: "10px",
                          height: "10px",
                        }}
                      />
                    </>
                  }
                />
              )}
              {!loading && noMoreOptions && (
                <Caution title="No more entries available." />
              )}
            </>
          )}

          {!loading && !error && data && datacount === 0 && (
            <p className="f-w-300 color-primary flex center-center">
              No history available.
            </p>
          )}
        </section>
      </div>
      <div
        className=" flex"
        style={{
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <ButtonLightTextIcon
          title="Cancel"
          onClick={() => setShow(false)}
          icon={
            <TbArrowBackUp />
          }
        />
      </div>
    </div>
  );
};
const DetailRow = ({ label, value }) => (
  <div>
    <p
      className="f-w-300 l-h-1"
      style={{
        fontSize: "12px",
        paddingBottom: "4px",
        color: "#aaaaaa",
      }}
    >
      {label}:
    </p>
    <p className="f-w-400 color-primary l-h-1" style={{}}>
      {value}
    </p>
  </div>
);
export default InvoiceHistory;
