import * as Yup from "yup";
import { useEffect, useState } from "react";
import CustomModal from "./../common/CustomModal";
import ButtonLightTextIcon from "./ButtonLightTextIcon";
import ButtonTextIcon from "./ButtonTextIcon";
import { Form, Formik, Field, ErrorMessage, FastField } from "formik";
import { TbArrowBackUp, TbCalendarShare } from "react-icons/tb";
import { MdCancel, MdOutlineCalendarMonth } from "react-icons/md";
import { FiArrowRightCircle } from "react-icons/fi";
import Label from "./Label";


const currentYear = new Date().getFullYear();
const months = [
  { value: "", label: "Select Month" },
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const years = [
  { value: "", label: "Select a year" },
  ...Array.from({ length: currentYear - 1900 + 1 }, (_, i) => ({
    value: (1900 + i).toString(),
    label: (1900 + i).toString(),
  })),
];

const MonthRangeInput = ({ onApply, onCancel, initialValue = {} }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth2, setSelectedMonth2] = useState(null);
  const [selectedYear2, setSelectedYear2] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (initialValue.yearMonth1) {
      const [year, month] = initialValue.yearMonth1.split("-");
      setSelectedYear(year);
      setSelectedMonth(month);
    }
    if (initialValue.yearMonth2) {
      const [year, month] = initialValue.yearMonth2.split("-");
      setSelectedYear2(year);
      setSelectedMonth2(month);
    }
  }, [initialValue]);

  const displaySelectedFilter = () => {
    if (!selectedMonth && !selectedYear && !selectedMonth2 && !selectedYear2) {
      return "Select Month Range";
    }

    const date1 =
      selectedMonth && selectedYear ? `${selectedYear}-${selectedMonth}` : "";
    const date2 =
      selectedMonth2 && selectedYear2
        ? `${selectedYear2}-${selectedMonth2}`
        : "";

    return `${date2} ${date1 && date2 ? "&" : ""} ${date1}`;
  };

  const handleApply = (values) => {
    const result = {
      yearMonth1:
        values.month1 && values.year1
          ? `${values.year1}-${values.month1}`
          : null,
      yearMonth2:
        values.month2 && values.year2
          ? `${values.year2}-${values.month2}`
          : null,
    };
    onApply(result);
    setSelectedMonth(values.month1);
    setSelectedYear(values.year1);
    setSelectedMonth2(values.month2);
    setSelectedYear2(values.year2);
    setShowModal(false);
  };

  const handleCancel = () => {
    onCancel();
    setSelectedMonth(null);
    setSelectedYear(null);
    setSelectedMonth2(null);
    setSelectedYear2(null);
  };

  return (
    <div className="pos-rel dis-i-b" style={{ width: "100%" }}>
      <div
        className="ui-like-input col-1-1 cursor-pointer"
        style={{
          display: "flex",
          justifyContent: "space-between",
          whiteSpace: "nowrap",
          minWidth: "200px",
        }}
      >
        <CustomModal
          selector={<div>{displaySelectedFilter()}&nbsp; </div>}
          content={
            <ModalContent
              initialValue={{
                month1: selectedMonth || "",
                year1: selectedYear || "",
                month2: selectedMonth2 || "",
                year2: selectedYear2 || "",
              }}
              onApply={handleApply}
              onCancel={() => setShowModal(false)}
              setShow={setShowModal}
            />
          }
          show={showModal}
          onClose={() => setShowModal(false)}
        />
        {selectedMonth || selectedYear || selectedMonth2 || selectedYear2 ? (
          <div style={{ margin: "auto 0" }}>
            <span
              className="cursor-pointer"
              style={{
                backgroundColor: "#f0f0f0",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
              }}
              onClick={handleCancel}
            >
              &times;
            </span>
          </div>
        ) : (
          <div
            className="flex center-center"
            style={{ margin: "auto 0" }}
            onClick={() => setShowModal(true)}
          >
            <MdOutlineCalendarMonth size={20} />
          </div>
        )}
      </div>
    </div>
  );
};

const ModalContent = ({ initialValue, onApply, onCancel, setShow }) => {
  const validationSchema = Yup.object()
    .shape({
      year1: Yup.string().required("Year is required"),
      month1: Yup.string().required("Month is required"),
      year2: Yup.string().required("Year is required"),
      month2: Yup.string().required("Month is required"),
    })
    .test(
      "date-validation",
      "End date must be after or equal to start date",
      function (values) {
        if (
          !values.year1 ||
          !values.month1 ||
          !values.year2 ||
          !values.month2
        ) {
          return true; // Let required validation handle empty fields
        }

        const startDate = new Date(`${values.year1}-${values.month1}-01`);
        const endDate = new Date(`${values.year2}-${values.month2}-01`);

        return endDate >= startDate;
      }
    );

  return (
    <div>
      <section
        style={{
          position: "fixed",
          inset: 0,
          zIndex: 9999,
        }}
      >
        <section
          className="col-1-1 flex center-center"
          style={{
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            style={{
              width: "50%",
              padding: "24px",
              height: "auto",
              maxHeight: "80vh",
              border: "solid 1px #f0f0f0",
              background: "white",
              borderRadius: "8px",
              margin: "0 auto",
              boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
            }}
          >
            <div
              className="col-1-1 flex center-left"
              style={{
                borderBottom: "solid 1px #f0f0f0",
                paddingBottom: "12px",
                marginBottom: "16px",
              }}
            >
              <h6 className="color-primary f-w-700 opl-tooltip">Filter</h6>
              <div
                className="mar-l-a cursor-pointer"
                onClick={() => setShow(false)}
              >
                <MdCancel size={28} />
              </div>
            </div>
            <Formik
              initialValues={initialValue}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                onApply(values);
                setShow(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <Form>
                  <div>
                    <table className="col-1-1">
                      <tbody>
                        <tr>
                          <td width={"33%"}>
                            <Label title="&nbsp;" />
                            <p className="color-primary f-w-600">
                              Time Frame A
                            </p>
                          </td>
                          <td width={"33%"}>
                            <Label title="Year" required={true} />
                            <Field
                              as="select"
                              name="year1"
                              className={`col-1-1 primary ${
                                errors.year1 && touched.year1 ? "error" : ""
                              }`}
                              value={values.year1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {years.map((yr) => (
                                <option key={yr.value} value={yr.value}>
                                  {yr.label}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="year1"
                              component="p"
                              className="error-messages"
                            />
                          </td>
                          <td>
                            <Label title="Month" required={true} />
                            <Field
                              as="select"
                              name="month1"
                              className={`col-1-1 primary ${
                                errors.month1 && touched.month1 ? "error" : ""
                              }`}
                              value={values.month1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {months.map((m) => (
                                <option key={m.value} value={m.value}>
                                  {m.label}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="month1"
                              component="p"
                              className="error-messages"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width={"33%"}>
                            <Label title="&nbsp;" />
                            <p className="color-primary f-w-600">
                              Time Frame B
                            </p>
                          </td>
                          <td width={"33%"}>
                            <Label title="Year" required={true} />
                            <Field
                              as="select"
                              name="year2"
                              className={`col-1-1 primary ${
                                errors.year2 && touched.year2 ? "error" : ""
                              }`}
                              value={values.year2}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {years.map((yr) => (
                                <option key={yr.value} value={yr.value}>
                                  {yr.label}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="year2"
                              component="p"
                              className="error-messages"
                            />
                          </td>
                          <td>
                            <Label title="Month" required={true} />
                            <Field
                              as="select"
                              name="month2"
                              className={`col-1-1 primary ${
                                errors.month2 && touched.month2 ? "error" : ""
                              }`}
                              value={values.month2}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {months.map((m) => (
                                <option key={m.value} value={m.value}>
                                  {m.label}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="month2"
                              component="p"
                              className="error-messages"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="col-1-1 flex center-left"
                    style={{
                      marginTop: "16px",
                      borderTop: "solid 1px #f0f0f0",
                      paddingTop: "16px",
                    }}
                  >
                    <ButtonLightTextIcon
                      type="button"
                      title="Cancel"
                      onClick={() => {
                        setShow(false);
                      }}
                      icon={<TbArrowBackUp />}
                    />
                    <div style={{ marginLeft: "auto" }}></div>
                    <ButtonTextIcon
                      title="Apply"
                      disabled={Object.keys(errors).length > 0 || isSubmitting}
                      type="submit"
                      icon={<FiArrowRightCircle />}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </section>
    </div>
  );
};

export default MonthRangeInput;
