import { useEffect, useRef, useState } from "react";
import ResultsPerPage from "../../components/common/ResultsPerPage";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { USER_TYPE_OPTIONS } from "../../utilities/constants";
import { useNavigate } from "react-router-dom";
import Label from "../../components/ui/Label";
import useQueryParams from "../../hooks/useQueryParams";
import { GrPowerReset } from "react-icons/gr";

const UserFilter = ({ resetFilters }) => {
  const navigate = useNavigate();
  const debounceTimeout = useRef(null);

  const { updateQueryParam, queryParams } = useQueryParams();
  const [searchValue, setSearchValue] = useState(
    queryParams.get("search") || ""
  );

  const handleSearchChange = (value) => {
    setSearchValue(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      queryParams.set("pageNo", 0);
      updateQueryParam("search", value);
    }, 500);
  };

  const handleQueryParamChange = (key, value) => {
    queryParams.set("pageNo", 0);
    value ? queryParams.set(key, value) : queryParams.delete(key);
    navigate({ search: queryParams.toString() });
  };

  return (
    <div className="col-1-1 flex center-left filters">
      <div>
        <Label variant="filter" title="User Name" />
        <input
          className="primary"
          type="text"
          value={searchValue}
          placeholder="Eg: John Doe"
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </div>
      <div>
        <Label variant="filter" title={"User Type"} />
        <select
          className="primary"
          value={queryParams.get("userType") || ""}
          onChange={(e) => handleQueryParamChange("userType", e.target.value)}
        >
          <option value="">Select</option>
          {USER_TYPE_OPTIONS.map((d, i) => (
            <option value={d.value} key={i}>
              {d.label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex center-left" style={{ gap: "12px" }}>
        <div>
          <Label variant="filter" title="Reset" />
          <ButtonLightTextIcon
            title="Reset"
            className="light"
            icon={
             <GrPowerReset />
            }
            onClick={() => {
              setSearchValue("");
              resetFilters();
            }}
          />
        </div>
        <div className="mar-l-a">
          <Label variant="filter" title="Per page" />
          <ResultsPerPage />
        </div>
      </div>
    </div>
  );
};

export default UserFilter;
