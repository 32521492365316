import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import LoaderIcon from "../components/ui/LoaderIcon";
import Header from "../components/layout/Header";
import Pagination from "../components/common/Pagination";
import { useUI } from "../context/ui";
import TableFilter from "./zip/TableFilter";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import CustomModal from "../components/common/CustomModal";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import AddFormzip from "./zip/AddForm";
import { handleApiCall, jsonToQueryParams } from "../utilities/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import PageToolbar from "../components/common/PageToolbar";
import useQueryParams from "../hooks/useQueryParams";
import { FiPlusCircle } from "react-icons/fi";

const ZipPage = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const search = queryParams.get("search") || "";
  const [controller, setController] = useState(null);
  const { addToastMessage, permissions } = useUI();

  const resetFilters = () => {
    updateQueryParam("pageNo", 0);
    updateQueryParam("search", "");
  };

  const callListApi = async () => {
    setLoading(true);
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    try {
      const query = jsonToQueryParams({
        search: search,
      });
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/zip?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );

      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.log("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      if (!id) {
        return false;
      }
      const response = await handleApiCall(API_URL + `/api/1.0.0/zip/${id}`, {
        method: "DELETE",
      });
      if (response.responseStatus == true) {
        await callListApi();
        addToastMessage({
          status: true,
          message: `Zip Deleted Successfully`,
        });
      } else {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting user. Please try again.`,
        });
      }
      return true;
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting the Zip`,
      });
      return false;
    }
  };

  useEffect(() => {
    callListApi();
  }, [location.search]);

  return (
    <section id="category">
      <PageToolbar
        title="Zip"
        button={
          permissions?.isCreateAllowed && (
            <CustomModal
              selector={
                <ButtonTextIcon
                  title="Add New"
                  className="mar-l-a"
                  icon={<FiPlusCircle />}
                />
              }
              content={<AddFormzip callback={callListApi} />}
            />
          )
        }
      />
      <TableFilter resetFilters={resetFilters} />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          className="flex center-center"
          style={{ height: "100vh", backgroundColor: "#fafaff" }}
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.list.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>ZIP</th>
                {/* <th>Country</th> */}
                <th>City</th>
                <th>State</th>
                {permissions?.isDeleteAllowed && (
                  <th style={{ textAlign: "center" }}>Action</th>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.list.map((item, index) => (
                <tr key={item.id}>
                  <td>{pageNo * pageSize + index + 1}</td>
                  <td>{item.zipCode || "NA"}</td>
                  <td>{item.city || "NA"}</td>
                  <td>{item.state || "NA"}</td>
                  {permissions?.isDeleteAllowed && (
                    <td>
                      <div className="flex space-between">
                        <CustomModal
                          selector={
                            <ButtonTertiary
                              actionType="delete"
                              title="Delete"
                            />
                          }
                          content={
                            <ConfirmationModal
                              onConfirm={async () => {
                                await handleDelete(item.id);
                                return true;
                              }}
                              message="Are you sure you want to delete this Zip Code?"
                            />
                          }
                        />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "24px 15px 32px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default ZipPage;
