import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import Header from "../components/layout/Header";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import PatientFilter from "./patient/PatientFilter";
import {
  formatServerDate,
  handleApiCall,
  jsonToQueryParams,
} from "../utilities/helpers";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import CustomModal from "../components/common/CustomModal";
import { useUI } from "../context/ui";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import PatientDetails from "./patient/PatientDetails";
import useQueryParams from "../hooks/useQueryParams";
import PageToolbar from "../components/common/PageToolbar";
import { FiPlusCircle } from "react-icons/fi";

const PatientPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const { addToastMessage } = useUI();
  const { permissions } = useUI();
  // ====== FILTER QUERY PARAMS
  const search = queryParams.get("search") || "";
  const dob = queryParams.get("dob") || "";
  const [controller, setController] = useState(null);

  // ====== RESET FILTER
  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("dob", "");
    updateQueryParam("pageNo", 0);
  };

  const handleDelete = async (id) => {
    try {
      if (!id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting patient. Please try again.`,
        });
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/patient/${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.responseStatus == true) {
        await getListApi();
        addToastMessage({
          status: true,
          message: `Patient deleted successfully.`,
        });
        return true;
      } else {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting patient. Please try again.`,
        });
        return false;
      }
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting patient. Please try again.`,
      });
      return false;
    }
  };

  const getListApi = async (s) => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    setLoading(true);
    try {
      const query = jsonToQueryParams({
        search,
        dob,
        pageNo,
        pageSize,
      });
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/patient/list?${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    getListApi();
  }, [location.search]);

  return (
    <section id="patient">
      <PageToolbar
        title="Patients"
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => navigate("/add-patient")}
             icon={<FiPlusCircle />}
            />
          )
        }
      />
      <PatientFilter
        resetFilters={() => {
          resetFilters();
        }}
      />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.records.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th className="t-t-u">Full Name</th>
                <th className="t-t-u">Date of Birth</th>
                {/* <th className="t-t-u">Age (Yrs)</th> */}
                <th className="t-t-u">Chart No.</th>
                <th className="t-t-u">Date of Service</th>
                {/* <th className="t-t-u">Gender (Male / Female)</th> */}
                <th className="t-t-u">Insurance</th>
                <th style={{ textAlign: "center" }}>
                  <div
                    className="t-t-u"
                    data-tooltip="Actions available: View, Edit, or Delete the record."
                  >
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.records.map((data, index) => (
                  <tr key={index}>
                    <td>{pageNo * pageSize + index + 1}</td>
                    <td className="t-t-u">
                      {data?.firstName || ""}{" "}
                      {data?.middleName || ""}{" "}{data?.lastName || ""}
                    </td>
                    <td className="t-t-u">
                      {formatServerDate(data.dateOfBirth)}
                    </td>
                    {/* <td className="t-t-u">
                      {data?.age ? data.age + " Yrs" : "-"}{" "}
                    </td> */}
                    <td className="t-t-u">{data.patientChartNo || "-"}</td>
                    <td className="t-t-u">
                      {data?.serviceDate
                        ? formatServerDate(data.serviceDate)
                        : "-"}
                    </td>
                    {/* <td className="t-t-u">{data?.gender || "-"}</td> */}
                    <td className="t-t-u">
                      {data?.patientInsuranceData?.primaryInsurance
                        ?.description || ""}
                    </td>
                    <td>
                      <div
                        className="flex center-center"
                        style={{ gap: "12px" }}
                      >
                        {permissions?.isReadAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="view"
                                  title="View"
                                />
                              }
                              content={<PatientDetails id={data?.id} />}
                            />
                          </div>
                        )}
                        {permissions?.isUpdateAllowed && (
                          <div>
                            <NavLink
                              to={
                                data?.id
                                  ? `/edit-patient?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                                  : "#"
                              }
                              state={{ id: data?.id }}
                            >
                              <ButtonTertiary actionType="edit" title="Edit" />
                            </NavLink>
                          </div>
                        )}
                        {permissions?.isDeleteAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="delete"
                                  title="Delete"
                                />
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    await handleDelete(data.id);
                                    return true;
                                  }}
                                  message="Are you sure you want to delete this patient?"
                                />
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}

      <div></div>
      <div>
        <div>
          {data && !loading && (
            <div
              className="flex center-center"
              style={{
                padding: "24px 15px 32px 15px",
                gap: "12px",
              }}
            >
              <p className="f-w-300" style={{ fontSize: "11px" }}>
                Page no:
              </p>
              <Pagination
                totalRecords={data.totalRecords}
                pageNo={data.pageNo}
                pageSize={data.pageSize}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PatientPage;
