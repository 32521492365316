import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const BarChart = ({ data, options, style, className }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const barChart = new Chart(chartRef.current, {
      type: "bar",
      data: data,
      options: {
        ...options,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 1000, // Animation duration in milliseconds
          easing: "easeInOutQuad", // Animation easing function
        },
      },
    });

    return () => barChart.destroy();
  }, [data, options]);

  return <canvas ref={chartRef} style={style} className={className} />;
};

export default BarChart;