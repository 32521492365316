import { useNavigate } from "react-router-dom";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import AddFacilityForm from "./facilities/AddFacilityForm";
import PageToolbar from "../components/common/PageToolbar";
import { TbArrowBackUp } from "react-icons/tb";

const AddFacility = () => {
  const navigate = useNavigate();
  return (
    <section id="add-insurance">
      <PageToolbar
        tittle="Add Facility"
        button={
          <ButtonTextIcon
            title="Cancel"
            className="light mar-l-a"
            onClick={() => navigate("/facilities")}
            icon={
              <TbArrowBackUp />
            }
          />
        }
      />
      <div className="col-1-1">
        <div className="form-holder">
          <div className="inner">
            <AddFacilityForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddFacility;
