import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateProcedureForm from "./procedure/UpdateProcedureForm";
import { API_URL } from "../utilities/constants";
import { handleApiCall } from "../utilities/helpers";
import PageToolbar from "../components/common/PageToolbar";
import useQueryParams from "../hooks/useQueryParams";
import { TbArrowBackUp } from "react-icons/tb";

const UpdateProcedurePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state?.id;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // State to show loading status
  const [error, setError] = useState(null);
  const {queryParams,updateQueryParam } =useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";

  const fetchProcedureData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/procedure-list/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      } else {
        setError("Failed to fetch group data.");
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch group data.");
      setLoading(false);
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProcedureData(id);
  }, [id]);
  return (
    <section id="add-activity">
      <PageToolbar
        title={"Update Procedure"}
        button={
          <ButtonTextIcon
            title="Cancel"
            className="light mar-l-a"
            onClick={() => {
              if (nextUrl) {
                navigate(nextUrl);
              } else {
                navigate("/procedures");
              }
            }}
            icon={
             <TbArrowBackUp />
            }
          />
        }
      />
      <div className="col-1-1">
        <div className="form-holder">
          <div className="inner">
            {!loading && data ? (
              <UpdateProcedureForm procedureData={data} />
            ) : (
              <p
                style={{
                  height: "100vh",
                  paddingBottom: "20vh",
                  backgroundColor: "#fafaff",
                }}
                className="flex center-center"
              >
                <LoaderIcon style={{ height: "32px", width: "32px" }} />
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default UpdateProcedurePage;
