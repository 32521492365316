import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import Header from "../components/layout/Header";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import ProcedureDetails from "./procedure/ProcedureDetails";
import { handleApiCall, jsonToQueryParams } from "../utilities/helpers";
import useQueryParams from "../hooks/useQueryParams";
import PageToolbar from "../components/common/PageToolbar";
import ProcedureFilter from "./procedure/ProcedureFilter";
import { FiPlusCircle } from "react-icons/fi";

const ProcedurePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { addToastMessage } = useUI();
  const { updateQueryParam, queryParams } = useQueryParams();
  const { permissions } = useUI();

  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;

  const [selectedIds, setSelectedIds] = useState([]);
  const isChecked = (id) => selectedIds.includes(id);
  const [controller, setController] = useState(null);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const resetFilters = () => {
    updateQueryParam("name", "");
    updateQueryParam("pageNo", 0);
  };

  const handleDelete = async (id) => {
    try {
      if (!id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting procedure. Please try again.`,
        });
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/procedure-list/${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.responseStatus === true) {
        getProcedureApi();
        addToastMessage({
          status: true,
          message: `procedure deleted successfully.`,
        });
        return true;
      } else {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting procedure. Please try again.`,
        });
        return false;
      }
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting procedure. Please try again.`,
      });
      return false;
    }
  };

  const getProcedureApi = async () => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    setLoading(true);
    try {
      const query = jsonToQueryParams({
        name: queryParams.get("name") || "",
        pageNo: queryParams.get("pageNo") || 0,
        pageSize: queryParams.get("pageSize") || 12,
      });
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/procedure-list?${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );

      if (response.responseStatus === true) {
        setData(response.data);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    getProcedureApi();
  }, [location.search]);

  const handleMultipleDelete = async () => {
    if (selectedIds.length === 0) {
      addToastMessage({
        status: false,
        message: `No procedure selected for deletion.`,
      });
      return;
    }

    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/procedure-list`,
        {
          method: "DELETE",
          body: { ids: selectedIds },
        }
      );

      if (response.responseStatus === true) {
        addToastMessage({
          status: true,
          message: `Selected procedures deleted successfully.`,
        });
        getProcedureApi(); // Refresh the list
        setSelectedIds([]); // Clear selection
      } else {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting procedures. Please try again.`,
        });
      }
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting procedures. Please try again.`,
      });
    }
  };

  return (
    <section id="category">
      <PageToolbar
        title="Procedures"
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => navigate("/add-procedure")}
              icon={<FiPlusCircle />}
            />
          )
        }
      />
      <ProcedureFilter
        resetFilters={resetFilters}
        callBack={async () => {
          await handleMultipleDelete(data.id);
          return true;
        }}
        isDeleteDisabled={selectedIds.length === 0} // Pass button disable state
      />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.procedureList.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>CPT code</th>
                <th>CPT Description</th>
                <th>Name</th>
                <th>Specialty</th>
                <th>Related Diagnosis</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.procedureList?.map((data, index) => (
                <tr key={data.id}>
                  <td width="12%">
                    <span
                      className="color-primary"
                      style={{
                        // width: "20px",
                        height: "23px",
                        backgroundColor: false ? "#4CAF50" : "#ccc",
                        borderRadius: "4px",
                        display: "inline-block",
                        position: "relative",
                        cursor: "pointer",
                        marginRight: "8px",
                        color: "white",
                        textAlign: "center",
                        lineHeight: "20px",
                        fontSize: "14px",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="primary"
                        checked={isChecked(data.id)}
                        onChange={() => handleCheckboxChange(data.id)}
                      />
                    </span>
                  </td>
                  <td>{data.cptCode}</td>
                  <td>{data.cptDescription}</td>
                  <td>{data.procedureName}</td>
                  <td>{data.specialty?.name}</td>
                  <td>{data.relatedDxCode}</td>
                  <td>
                    <div className="flex center-center" style={{ gap: "12px" }}>
                      {permissions?.isReadAllowed && (
                        <div className="color-secondary transition hover-color-primary cursor-pointer ">
                          <CustomModal
                            selector={
                              <ButtonTertiary actionType="view" title="View" />
                            }
                            content={<ProcedureDetails id={data?.id} />}
                          />
                        </div>
                      )}
                      {permissions?.isUpdateAllowed && (
                        <div>
                          <NavLink
                            to={`/edit-procedure?next=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                            state={{ id: data.id }}
                          >
                            <ButtonTertiary actionType="edit" title="Edit" />
                          </NavLink>
                        </div>
                      )}
                      {permissions?.isDeleteAllowed && (
                        <div className="color-secondary transition hover-color-primary cursor-pointer ">
                          <CustomModal
                            selector={
                              <ButtonTertiary
                                actionType="delete"
                                title="Delete"
                              />
                            }
                            content={
                              <ConfirmationModal
                                onConfirm={async () => {
                                  await handleDelete(data.id);
                                  return true;
                                }}
                                message="Are you sure you want to delete this procedure ?"
                              />
                            }
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}

      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default ProcedurePage;
