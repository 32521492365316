import jwtDecode from "jwt-decode";
import {
	FIELD_ALLOWED_SCANFLOW,
	ROLES_LIST,
	SCANFLOW_STATUS_LIST,
	SYSTEM_TOKEN,
	USER_TYPE_OPTIONS,
} from "./constants";
import { formatISO, parseISO } from "date-fns";

export const formatYYYYMMDD = (str) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const arr = str.split("-");
	return arr[2] + " " + months[parseInt(arr[1] - 1)] + " " + arr[0];
};

export const formatServerDate = (str) => {
	try {
        if (!str || typeof str !== "string") {
            return null; // Return null for invalid input
        }

        const parts = str.trim().split("-");
        if (parts.length !== 3) {
            return null; // Return null if format is incorrect
        }

        const [year, month, day] = parts;

        // Validate that year, month, and day are numeric and within valid ranges
        if (
            isNaN(year) || isNaN(month) || isNaN(day) ||
            year.length !== 4 || month.length !== 2 || day.length !== 2 ||
            parseInt(month, 10) < 1 || parseInt(month, 10) > 12 ||
            parseInt(day, 10) < 1 || parseInt(day, 10) > 31
        ) {
            return null; // Return null for invalid values
        }

        return `${month}/${day}/${year}`;
    } catch {
        return null; // Return null for any unexpected errors
    }
};

export const formatInputPhoneNumber = (value) => {
	if (!value) return value;

	const onlyNums = value.replace(/[^\d]/g, "");

	if (onlyNums.length <= 3) {
		return onlyNums;
	} else if (onlyNums.length <= 6) {
		return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
	} else {
		return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6)}`;
	}
};
export const formatDate =  (str) => {
    // const months = [
    //     "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
    //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    // ];
	const months = [
        "01", "02", "03", "04", "05", "06", 
        "07", "08", "09", "10", "11", "12"
    ];

    // const date = new Date(str);
	const date = convertGMTToLocal(str);
    // const day = date.getDate(); 
	const day = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()]; 
    const year = date.getFullYear(); 

    return month + "/" + day + "/" + year;
};

export function formatDateTime(dateString) {
	const date = convertGMTToLocal(dateString);
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	const formattedDate = `${formatDate(
		dateString
	)} ${hours}:${minutes}:${seconds}`;
	return formattedDate;
}

export const sleep = (m) => new Promise((r) => setTimeout(r, m));

export const formatAmountValue = (value) => {
	if (isNaN(value)) return value;
	return parseFloat(value).toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export function getScanflowStatusById(id = 0) {
	const status = SCANFLOW_STATUS_LIST.find((d) => d.id == id);
	return status
		? status
		: {
				id: "0",
				name: "Undefined",
				class: "not-started",
			};
}
export const formatTime = (totalSeconds) => {
	const hours = Math.floor(totalSeconds / 3600)
		.toString()
		.padStart(2, "0");
	const minutes = Math.floor((totalSeconds % 3600) / 60)
		.toString()
		.padStart(2, "0");
	const seconds = (totalSeconds % 60).toString().padStart(2, "0");
	return `${hours}:${minutes}:${seconds}`;
};

export const getLogInUserDetails = (type) => {
	const LOGGED_IN_USER = localStorage.getItem("LOGGED_IN_USER");
	if (!LOGGED_IN_USER) return "";
	const user = JSON.parse(LOGGED_IN_USER);
	const detailsMap = {
		type: user.type,
		id: user.id,
		fullname: user.fullname,
	};
	return detailsMap[type] || "";
};

export const isManager = (roleName) => {
	const role = ROLES_LIST.find((role) => role.name === roleName);
	return role && role.type === "manager";
};

export const isInDepartment = (roleName, department) => {
	const role = ROLES_LIST.find((role) => role.name === roleName);
	return role && role.department === department;
};

export const getUserTypeInfo = (type) => {
	return (
		USER_TYPE_OPTIONS.find((userType) => userType.value === type) || null
	);
};

export const getRoleInfo = (roleName) => {
	return ROLES_LIST.find((role) => role.name === roleName) || null;
};

export const getScanflowStatusInfo = (key, value) => {
	return SCANFLOW_STATUS_LIST.find((status) => status[key] === value) || null;
};

export const convertGMTToLocal = (gmtInput) => {
	const gmtTime = new Date(gmtInput);
	return gmtTime;
	// if (isNaN(gmtTime.getTime())) {
	// 	console.error("Invalid GMT input");
	// 	return null;
	// }
	// const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
	// const localTime = new Date(
	// 	gmtTime.getTime() - timezoneOffsetInMinutes * 60000
	// );
	// return localTime;
};

export const houstonDateTime = () => {
	const gmtTime = new Date();

	const houstonTime = new Intl.DateTimeFormat("en-US", {
		timeZone: "America/Chicago",
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: true,
	}).format(gmtTime);

	return houstonTime;
};
/**
 * Decodes user permissions for a given module from a JWT token.
 *
 * @param {string} module - Module name (e.g., "insurance").
 * @returns {null|object} - Returns `null` if the token or module is invalid,
 * or an object with permissions:
 * {
 *   isCreateAllowed: boolean, // Create permission
 *   isReadAllowed: boolean,   // Read permission
 *   isUpdateAllowed: boolean, // Update permission
 *   isDeleteAllowed: boolean  // Delete permission
 * }
 */

export const decodeModuleAccessFromToken = (module) => {
	const token = localStorage.getItem("token");

	if (!token) {
		console.warn("Token not found in localStorage.");
		return null;
	}

	try {
		const decodedToken = jwtDecode(token);
		const modules = decodedToken.modules;

		if (!modules || !modules.hasOwnProperty(module)) {
			console.warn(`Module "${module}" not found in token.`);
			return {
				isCreateAllowed: false,
				isReadAllowed: false,
				isUpdateAllowed: false,
				isDeleteAllowed: false,
			};
		}

		const crudValue = modules[module];

		if (typeof crudValue !== "string" || crudValue.length < 4) {
			console.error(`Invalid CRUD value for module "${module}".`);
			return {
				isCreateAllowed: false,
				isReadAllowed: false,
				isUpdateAllowed: false,
				isDeleteAllowed: false,
			};
		}

		if (crudValue === "0000") {
			console.error(`Invalid CRUD value for module "${module}".`);
			return {
				isCreateAllowed: false,
				isReadAllowed: false,
				isUpdateAllowed: false,
				isDeleteAllowed: false,
			};
		}

		return {
			isCreateAllowed: crudValue[0] === "1",
			isReadAllowed: crudValue[1] === "1",
			isUpdateAllowed: crudValue[2] === "1",
			isDeleteAllowed: crudValue[3] === "1",
		};
	} catch (error) {
		console.error("Error decoding token:", error.message);
		return null;
	}
};

export const getDepartmentId = () => {
	const token = localStorage.getItem("token");

	if (!token) {
		console.warn("Token not found in localStorage.");
		return null;
	}

	try {
		const decodedToken = jwtDecode(token);
		return decodedToken.department || null;
	} catch (error) {
		console.error("Error decoding token:", error.message);
		return null;
	}
};

export const getUserDeatilsFromToken = () => {
	const token = localStorage.getItem("token");

	if (!token) {
		console.warn("Token not found in localStorage.");
		return null;
	}

	try {
		const decodedToken = jwtDecode(token);
		return decodedToken || null;
	} catch (error) {
		console.error("Error decoding token:", error.message);
		return null;
	}
};

/**
 * Converts a JSON object into a query parameter string.
 *
 * @param {Object} json - The JSON object to be converted.
 * @returns {string} - A string of encoded query parameters.
 */

export const jsonToQueryParams = (json) => {
	const queryParams = Object.keys(json)
		.filter((key) => json[key] !== null && json[key] !== "")
		.map(
			(key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
		)
		.join("&");
	return queryParams;
};

function normalizeResponse(response) {
	if (!response) {
		throw new Error("Invalid response object");
	}

	if (response.result) {
		return {
			responseDescription: response.result.responseDescription,
			responseCode: response.result.responseCode,
		};
	}

	return {
		responseDescription: response.responseDescription,
		responseCode: response.responseCode,
	};
}

/**
 * Handles API calls with customizable options, optional system token, and request cancellation support.
 *
 * @param {string} url - The API endpoint URL.
 * @param {Object} options - The options for the request (method, headers, body, etc.).
 * @param {boolean} [systemToken=false] - Flag to include system token in the request headers.
 * @param {AbortSignal} [signal=null] - Optional abort signal to cancel the request.
 * @returns {Object|null} - The response data or null in case of failure or cancellation.
 */

export const handleApiCall = async (
	url,
	options = {},
	systemToken = false,
	signal = null
) => {
	const { method = "GET", headers = {}, body = null } = options;

	const controller = new AbortController();
	const currentSignal = signal || controller.signal;

	try {
		const response = await fetch(url, {
			method,
			headers: {
				"Content-Type": "application/json",
				"X-JWT-Assertion": systemToken
					? SYSTEM_TOKEN
					: localStorage.getItem("token"),
				...headers,
			},
			body: body ? JSON.stringify(body) : null,
			signal: currentSignal, // Attach the signal to the request
		});
		if (response.status === 401) {
			if (localStorage.removeItem("token") === undefined) {
				window.location.replace("/login");
			}
			return null;
		}
		if (response.status === 403) {
			return {
				responseDescription:
					"You are not authorized to perform this action.",
				responseCode: 403,
				responseStatus: false,
			};
		}

		const data = await response.json();

		if (response.status === 200) {
			if (data.result.responseCode === 200) {
				return { ...data, responseStatus: true };
			}
		}

		const data1 = normalizeResponse(data);

		return { ...data1, responseStatus: false };
	} catch (error) {
		if (error.name === "AbortError") {
			return {
				responseDescription: "API request was aborted",
				responseCode: 299,
				responseStatus: false,
			};
		} else {
			console.error("API call failed:", error);
			return {
				responseDescription:
					"Something went wrong, please try again later.",
				responseCode: 500,
				responseStatus: false,
			};
		}
	}
};

export const formatAndValidateDate = (inputDate) => {
	const sanitizedDate = inputDate.replace(/[^0-9]/g, "");

	if (sanitizedDate.length !== 8) {
		//   console.log("Invalid date format: Length should be 8 characters.");
		return "";
	}

	const month = sanitizedDate.slice(0, 2);
	const day = sanitizedDate.slice(2, 4);
	const year = sanitizedDate.slice(4);

	const date = new Date(`${year}-${month}-${day}`);

	if (
		date.getMonth() + 1 !== parseInt(month) ||
		date.getDate() !== parseInt(day) ||
		date.getFullYear() !== parseInt(year)
	) {
		//   console.log("Invalid date.");
		return "";
	}

	return `${year}-${month}-${day}`;
};

export const handleDatePickerChange = (setValue, key, date, e) => {
	if (e?.target?.value !== undefined) {
		if (e?.target?.value) {
			setValue(key, formatAndValidateDate(e.target.value));
		} else {
			setValue(key, "");
		}
	} else if (date) {
		setValue(key, formatISO(date, { representation: "date" }));
	} else {
		setValue(key, "");
	}
};

export const getRandomColor = () => {
	const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
	const randomIndex = Math.floor(Math.random() * array.length);
	return array[randomIndex];
};

export function convertToMMDDYYYY(dateString) {
	const [year, month, day] = dateString.split("-").map((d) => parseInt(d, 10));
	const formattedDate = `${("0" + month).slice(-2)}/${("0" + day).slice(-2)}/${year}`;
	return formattedDate;
}
export const formatRateUSD = (value, currency = "USD") => {
	if (value == null || isNaN(value)) {
		return "NA"; // Check if value is a valid number and not null/undefined
	}
	const formattedValue = Number(value).toFixed(2);

	return `${formattedValue} ${currency}`;
};

export function convertTo12HourFormat(timeString) {
	const [hours, minutes] = timeString.split(":");
	const hour = parseInt(hours, 10);
	const period = hour >= 12 ? "PM" : "AM";
	const convertedHour = hour % 12 || 12;
	return `${("0" + convertedHour).slice(-2)}:${minutes} ${period}`;
}
export function convertTo24HourFormat(timeString) {
	if (!timeString) return ""; // Handle cases where the time is undefined or empty
	const [time, period] = timeString.split(" ");
	let [hours, minutes] = time.split(":");
	hours = parseInt(hours, 10);

	if (period === "PM" && hours !== 12) {
		hours += 12;
	}
	if (period === "AM" && hours === 12) {
		hours = 0;
	}

	return `${("0" + hours).slice(-2)}:${minutes}`;
}
export const getPatientName = (obj) => {
  if (!obj || typeof obj !== "object") return "Not Selected";

  const { firstName = "", middleName = "", lastName = "" } = obj;
  const fullName = [firstName, middleName, lastName]
      .filter(name => name && name.trim() !== "")
      .join(" ");

  return fullName || "Not Selected";
};

export function getRandomNumber() {
	return Math.floor(1000000000 + Math.random() * 9000000000); // Ensures the number is 10 digits
}

export const labelPatientsFunc = (o) => `${o?.firstName || ''} ${o?.middleName || ''} ${o?.lastName || ''} ${o?.dateOfBirth ? `(${formatServerDate(o.dateOfBirth)})` : ''}`;
export const labelProcedureFunc = (o) => `${o.procedureName}${o?.cptCode ? (", " + o.cptCode) : ""}`;
export const labelAssistantsFunc = (o) => `${o.name}${o?.credential ? "," : ""}${o?.credential ? " " + o.credential : ""}`;

export const getAllowedFields = (userType) => {
	const userFields = FIELD_ALLOWED_SCANFLOW.find(item => item.userType === userType);
	return userFields ? userFields.fieldsAllowed : [];
  };