import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import { useLocation, useNavigate } from "react-router-dom";
import { handleApiCall } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import useQueryParams from "../../hooks/useQueryParams";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";

const validationSchema = Yup.object({
	name: Yup.string().required("Region name is required"),
	email: Yup.string()
		.email("Invalid email format")
		.required("Region email is required"),
});

function UpdateForm({ regiondata }) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const { addToastMessage } = useUI();
	const { queryParams, updateQueryParam } = useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";
	const [initialValues, setInitialValues] = useState({ name: "", email: "" });
	const [id, setId] = useState("");
	useEffect(() => {
		setInitialValues({
			name: regiondata?.name || null,
			email: regiondata?.email || null,
		});
		setId(regiondata?.id || null);
	}, [regiondata]);

	const updateRegion = async (values) => {
		setLoading(true);
		try {
			const payload = {
				name: values.name,
				email: values.email,
			};
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/region/${id}`,
				{
					method: "PUT",
					body: payload,
				}
			);
			if (response.responseStatus == true) {
				setStatus("You have successfully updated a region, thank you.");
				addToastMessage({
					status: true,
					message: `You have successfully updated a region, thank you.`,
				});
				if (nextUrl) {
					navigate(nextUrl);
				} else {
					navigate("/regions");
				}
				return true;
			} else {
				setStatus(
					`${response?.responseDescription}||Something went wrong, please try again later.`
				);
				addToastMessage({
					status: false,
					message: `${response?.responseDescription}||Something went wrong, please try again later.`,
				});
				return false;
			}
		} catch (e) {
			console.error("Error:", e);
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: "Something went wrong, please try again later.",
			});
			return false;
		} finally {
			setLoading(false);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={validationSchema}
			onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				const api = await updateRegion(values);
				if (api) {
					resetForm();
				}
				setLoading(false);
			}}>
			{({ handleSubmit, validateForm, errors, touched }) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td>
										<Label title='Region Name' required />
										<Field
											name='name'
											type='text'
											placeholder='Enter Region Name'
											className={`primary ${errors.name && touched.name ? "error" : ""}`}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label title='Region Email' required />
										<Field
											name='email'
											type='email'
											placeholder='Enter Region Email'
											className={`primary ${errors.email && touched.email ? "error" : ""}`}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => {
								if (nextUrl) {
									navigate(nextUrl);
								} else {
									navigate("/regions");
								}
							}}
							icon={
								<TbArrowBackUp />
							}
						/>
						<ButtonTextIcon
							type='submit'
							disabled={loading}
							title='Update Now'
							onClick={async () => {
								const errors = await validateForm();
								if (Object.keys(errors).length > 0) {
									const firstErrorMessage =
										Object.values(errors)[0];
									addToastMessage({
										status: false,
										message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
									});
								}
								handleSubmit();
							}}
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<FiArrowRightCircle />
								)
							}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default UpdateForm;
