import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PaginatedDropdown, {
	mapOptions,
} from "../../components/ui/PaginatedDropdown";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import { useNavigate } from "react-router-dom";
import { handleApiCall } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";

const AddForm = () => {
	const navigate = useNavigate();
	const { addToastMessage } = useUI();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(false);

	const initialValues = {
		procedureName: "",
		specialityId: null,
		cptCode: "",
		cptDescription: "",
		relatedDxCode: "",
	};

	const validationSchema = Yup.object({
		procedureName: Yup.string().required("Name is required"),
		specialityId: Yup.mixed()
			.nullable()
			.test(
				"Specialty-required",
				"Specialty is Required",
				function (value) {
					return value !== null && value.value;
				}
			),
		cptCode: Yup.string().required("CPT Code is required"),
		cptDescription: Yup.string().nullable(),
		relatedDxCode: Yup.string().nullable(),
	});

	const createProcedure = async (values) => {
		setLoading(true);
		setStatus("");

		try {
			const payload = {
				procedureName: values?.procedureName || null,
				specialityId: values?.specialityId?.value || null,
				cptCode: values?.cptCode || null,
				cptDescription: values?.cptDescription || null,
				relatedDxCode: values?.relatedDxCode || null,
			};
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/procedure-list`,
				{
					method: "POST",
					body: payload,
				}
			);

			if (response.responseStatus === true) {
				setStatus(
					"You have successfully created a procedure, thank you."
				);
				addToastMessage({
					status: true,
					message: `You have successfully created a procedure, thank you.`,
				});
				navigate("/procedures");
				return true;
			} else {
				setStatus(
					`${response.responseDescription || "Something went wrong, please try again later."}`
				);
				addToastMessage({
					status: false,
					message: `${response.responseDescription || "Something went wrong, please try again later."}`,
				});
				return false;
			}
		} catch (e) {
			addToastMessage({
				status: false,
				message: "Something went wrong, please try again later.",
			});
			console.error("Error:", e);
			return false;
		} finally {
			setLoading(false);
		}
	};
	const labelSpecilityFunc = (o) => `${o.name}`;
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				const api = await createProcedure(values);
				if (api) {
					resetForm();
				}
			}}>
			{({
				values,
				errors,
				touched,
				setFieldValue,
				validateForm,
				handleSubmit,
				setFieldTouched,
			}) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td width={"50%"}>
										<Label title='Name' required={true} />
										<Field
											name='procedureName'
											type='text'
											placeholder='Enter Name'
											className={`primary ${
												errors.procedureName &&
												touched.procedureName
													? "error"
													: ""
											}`}
										/>
									</td>
									<td>
										<Label
											title='Specialty'
											required={true}
										/>
										<PaginatedDropdown
											datakey='records'
											url={`${API_URL}/api/1.0.0/reference/specialities?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelSpecilityFunc
												)
											}
											setTouched={() =>
												setFieldTouched(
													"specialityId",
													true
												)
											}
											placeHolder='Search'
											error={
												errors.specialityId &&
												touched.specialityId
											}
											onChange={(v) => {
												setFieldValue(
													"specialityId",
													v
												);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label
											title='CPT code'
											required={true}
										/>
										<Field
											name='cptCode'
											type='text'
											placeholder='CPT code'
											className={`primary ${
												errors.cptCode &&
												touched.cptCode
													? "error"
													: ""
											}`}
										/>
									</td>
									<td>
										<Label title='CPT Description' />
										<Field
											name='cptDescription'
											type='text'
											placeholder='CPT Description'
											className={`primary ${
												errors.cptDescription &&
												touched.cptDescription
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan='2'>
										<Label title='Related DX code' />
										<Field
											name='relatedDxCode'
											type='text'
											placeholder='Related Dx code'
											className={`primary ${
												errors.relatedDxCode &&
												touched.relatedDxCode
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => navigate("/procedures")}
							icon={
								<TbArrowBackUp />
							}
						/>
						<ButtonTextIcon
							type='submit'
							disabled={loading}
							title='Create Now'
							onClick={async () => {
								const errors = await validateForm();
								if (Object.keys(errors).length > 0) {
									const firstErrorMessage =
										Object.values(errors)[0];
									addToastMessage({
										status: false,
										message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
									});
								}
								handleSubmit();
							}}
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<FiArrowRightCircle />
								)
							}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default AddForm;
