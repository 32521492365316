import { useState, forwardRef, useImperativeHandle  } from "react";
import LoaderIcon from "./LoaderIcon";
import { API_URL} from "../../utilities/constants";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import { MdCancel } from "react-icons/md";

const DragFileUpload = forwardRef(({ afterUpload, fileType, error = false, setUploading=()=>{} }, ref) => {
	const [loading, setLoading] = useState(false);
	const [uploadedError, setUploadedError] = useState(null);
	const [f, setF] = useState("");
	const [uploaded, setUploaded] = useState(false);

	const resetFile = () => {
		setF("");
		setUploaded(false);
		setLoading(false);
		afterUpload("");
	};

	useImperativeHandle(ref, () => ({
		resetFile,
	}));

	return (
    <>
      <div
        className={`opl-file-drag-upload flex center-center pos-rel cursor-pointer ${error || uploadedError ? "error-border" : ""}`}
      >
        <input
          type="file"
          onChange={async (e) => {
            setUploadedError(null);
            const acceptedFileTypes = [
              // Images
              "image/jpeg",
              "image/jpg",
              "image/png",
              "image/webp",
              // PDF
              "application/pdf",
            ];

            if (e.target.files.length > 0) {
              setF(e.target.files[0].name);
              setLoading(true);
              setUploading(true);
              const file = e.target.files[0];
              if (!acceptedFileTypes.includes(file.type)) {
                setUploadedError(
                  "Invalid file type. Please upload a PNG, JPEG, JPG, WEBP, or PDF file."
                );
                setLoading(false);
                setUploading(false);
                return;
              }

              try {
                const formdata = new FormData();
                formdata.append("file", e.target.files[0]);
                formdata.append("type", fileType);
                const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
                  method: "POST",
                  headers: {
                    "X-JWT-Assertion": localStorage.getItem("token"),
                  },
                  body: formdata,
                  redirect: "follow",
                });
                if (resp.ok) {
                  const response = await resp.json();
                  if (response.result.responseCode === 200) {
                    const uploadedURL = response.data;
                    afterUpload(uploadedURL);
                    setUploaded(true);
                  }
                } else {
                  const response = await resp.json();
                  setUploadedError(
                    response?.result?.responseDescription ||
                      "File upload failed. Please try again."
                  );
                }
              } catch (e) {
                setUploadedError("File upload failed. Please try again.");
              } finally {
                setUploading(false);
                setLoading(false);
              }
            }
          }}
        />
        <div className="t-a-c">
          <div style={{ marginBottom: "10px" }}>
            {loading ? (
              <LoaderIcon
                style={{
                  height: "24px",
                  width: "24px",
                }}
              />
            ) : (
              <>
                {uploaded ? (
                  <FcApproval />
                ) : uploadedError ? (
                  <FcHighPriority />
                ) : (
                  <IoCloudUploadOutline />
                )}
              </>
            )}
          </div>
          <div>
            {loading ? (
              <p style={{ color: "#8f8f8f" }} className="small l-h-1">
                Uploading...
                <br />
                {`${f}`}
              </p>
            ) : (
              <>
                {uploaded ? (
                  <p style={{ color: "#27ae60" }} className="small l-h-1">
                    Your file has been successfully uploaded.
                  </p>
                ) : uploadedError ? (
                  <p style={{ color: "red" }} className="small l-h-1">
                    {uploadedError}
                  </p>
                ) : (
                  <p style={{ color: "#8f8f8f" }} className="small l-h-1">
                    Drag files here or click to select files.
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {uploaded && (
        <div
          className="flex"
          style={{
            marginTop: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#f5f5f5", // Example muted background color
            padding: "5px",
            borderRadius: "8px",
          }}
        >
          <span className="small l-h-1 color-primary">&nbsp; {f}</span>
          <button
            onClick={resetFile}
            style={{
              display: "flex",
              alignItems: "center",
              color: "#d9534f",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              height: "25px",
            }}
            className="small l-h-1"
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "rgba(217, 83, 79, 0.1)")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "transparent")
            }
          >
            <MdCancel />
            Remove
          </button>
        </div>
      )}
    </>
  );
});

export default DragFileUpload;