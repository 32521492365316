import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ResultsPerPage from "../../components/common/ResultsPerPage";
import CustomModal from "../../components/common/CustomModal";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { useRef, useState } from "react";
import useQueryParams from "../../hooks/useQueryParams";
import { useUI } from "../../context/ui";
import Label from "../../components/ui/Label";
import { GrPowerReset } from "react-icons/gr";

const PhysicianAssistantFilter = ({ callback, ids, resetFilters }) => {
  const { updateQueryParam, queryParams } = useQueryParams();
  const debounceTimeout = useRef(null);

  const [searchValue, setSearchValue] = useState(
    queryParams.get("search") || ""
  );

  const handleSearchChange = (value) => {
    setSearchValue(value);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      queryParams.set("pageNo", 0);
      updateQueryParam("search", value);
    }, 500);
  };

  const { permissions } = useUI();
  return (
    <div className="col-1-1 flex center-left filters">
      <div>
        <Label variant="filter" title="Name" />
        <input
          className="primary"
          type="text"
          value={searchValue}
          placeholder="Eg: John Doe"
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </div>
      <div>
        <Label variant="filter" title="Status" />
        <select
          className="primary"
          value={queryParams.get("status") || ""}
          onChange={(v) => {
            updateQueryParam("status", v.target.value);
          }}
        >
          <option value="">All</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
      </div>
      <div className="flex center-left" style={{ gap: "12px" }}>
        <div>
          <Label variant="filter" title="Reset" />
          <ButtonLightTextIcon
            title="Reset"
            className="light"
            icon={
              <GrPowerReset />
            }
            onClick={() => {
              setSearchValue("");
              resetFilters();
            }}
          />
        </div>
        <div className="mar-l-a">
          <Label variant="filter" title="Per page" />
          <ResultsPerPage />
        </div>
      </div>
      {/* {permissions?.isUpdateAllowed && (
        <div>
          <Label variant="filter" title="&nbsp;" />
          <CustomModal
            selector={
              <ButtonTextIcon
                disabled={!ids.length}
                title="Active"
                type="button"
                style={{ width: "100%" }}
              />
            }
            content={
              <ConfirmationModal
                onConfirm={async () => {
                  await callback("1");
                  return true;
                }}
                message={`Are you sure you want to activate ${ids.length} physician assistants?`}
              />
            }
          />
        </div>
      )}
      {permissions?.isUpdateAllowed && (
        <div>
          <Label variant="filter" title="&nbsp;" />
          <CustomModal
            selector={
              <ButtonTextIcon
                disabled={!ids.length}
                title="Inactive"
                type="button"
                style={{ width: "100%" }}
              />
            }
            content={
              <ConfirmationModal
                onConfirm={async () => {
                  await callback("0");
                  return true;
                }}
                message={`Are you sure you want to deactivate ${ids.length} physician assistants?`}
              />
            }
          />
        </div>
      )} */}
    </div>
  );
};

export default PhysicianAssistantFilter;
