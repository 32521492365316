import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useEffect, useRef, useState } from "react";
import {
	ALLOWED_FILE_TYPES,
	API_URL,
	PLACE_OF_SERVICE,
} from "../../utilities/constants";
import PaginatedDropdown, {
	mapOptions,
} from "../../components/ui/PaginatedDropdown";
import DragFileUpload from "../../components/ui/DragFileUpload";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import {
	convertTo12HourFormat,
	convertToMMDDYYYY,
	formatServerDate,
	getUserTypeInfo,
	handleApiCall,
	labelAssistantsFunc,
	labelPatientsFunc,
	labelProcedureFunc,
	sleep,
} from "../../utilities/helpers";
import { useUI } from "../../context/ui";
import AddPatientForm from "./AddPatientForm";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import DatePickerInput from "../../components/ui/DatePickerInput";
import Label from "../../components/ui/Label";
import CustomModal from "../../components/common/CustomModal";
import FullScreenFileViewerModal from "../../components/ui/FullScreenFileViewerModal";
import useQueryParams from "../../hooks/useQueryParams";
import { TbArrowBackUp } from "react-icons/tb";
import { FiArrowRightCircle, FiPlusCircle } from "react-icons/fi";
import { MdCancel, MdOutlineDeleteSweep } from "react-icons/md";
import { LuBadgeInfo, LuEye } from "react-icons/lu";
import { FcInfo } from "react-icons/fc";
const UpdateActivityForm = ({ activityData }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [uploaded, setUploaded] = useState(false);
	const [procedures, setProcedures] = useState(null);
	const { addToastMessage } = useUI();
	const { profile } = useUI();
	const { queryParams, updateQueryParam } = useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";
	const [initialValues, setInitialValues] = useState({
		document_detail: [],
		user_id: "",
		patient_id: null,
		patient_name: "",
		event_type: null,
		procedure_id: [],
		paytoadmin_id: null,
		account_number: "",
		event_surgeon: null,
		event_facility: null,
		admission_date: "",
		event_start_date: "",
		event_start_time: "",
		event_end_date: "",
		event_end_time: "",
		placeOfService: "",
		event_flags: null,
		event_surgical_assitant: null,
		modified_by: profile.id,
		event_notes: "",
	});
	const convertTo24HourFormat = (timeString) => {
		if (!timeString) return "";

		timeString = timeString.replace(/\s+/g, " ").trim();

		const match = timeString.match(/^(\d{1,2}:\d{2})\s*(AM|PM)$/i);
		if (!match) return "";

		let [_, time, period] = match;
		let [hours, minutes] = time.split(":");
		hours = parseInt(hours, 10);
		minutes = parseInt(minutes, 10);

		if (hours < 0 || hours > 12 || minutes < 0 || minutes >= 60) {
			return "";
		}

		if (period.toUpperCase() === "PM" && hours !== 12) {
			hours += 12;
		}
		if (period.toUpperCase() === "AM" && hours === 12) {
			hours = 0;
		}

		return `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}`;
	};

	useEffect(() => {
		setInitialValues({
			document_detail: activityData.patientActivityDAO?.files
				? activityData.patientActivityDAO?.files.map((o) => ({
						file_type: o.type,
						file: o.fileUrl,
						url: o.fileS3Url,
					}))
				: [],
			event_start_date:
				activityData?.patientActivityDAO?.event_start_date || null,
			event_end_date:
				activityData?.patientActivityDAO?.event_end_date || null,
			modified_by: activityData?.patientActivityDAO?.modified_by || null,
			event_start_time: activityData?.patientActivityDAO?.event_start_time
				? convertTo24HourFormat(
						activityData?.patientActivityDAO?.event_start_time
					)
				: null,
			event_end_time: activityData?.patientActivityDAO?.event_end_time
				? convertTo24HourFormat(
						activityData?.patientActivityDAO?.event_end_time
					)
				: null,
			admission_date:
				activityData?.patientActivityDAO?.admission_date || "",
			event_notes: activityData?.patientActivityDAO?.event_notes || "",
			placeOfService: activityData?.placeOfService
				? activityData?.placeOfService?.slug
				: "",
			account_number:
				activityData?.patientActivityDAO?.account_number || "",
			procedure_id: activityData?.procedure
				? activityData.procedure.map((item) => ({
						val: item.id,
						name: item.procedureName,
					}))
				: [],
			patient_id: activityData?.patientActivityDAO?.patient
				? {
						value: activityData.patientActivityDAO.patient.id,
						label: [
							activityData.patientActivityDAO.patient.firstName?.toUpperCase(),
							activityData.patientActivityDAO.patient.middleName?.toUpperCase(),
							activityData.patientActivityDAO.patient.lastName?.toUpperCase(),
						]
							.filter(Boolean)
							.join(" "),
					}
				: null,

			event_flags: activityData?.eventFlags
				? {
						value: activityData?.eventFlags?.id,
						label: activityData?.eventFlags?.scheduleFlag,
					}
				: null,
			event_type: activityData?.eventType
				? {
						value: activityData.eventType.id,
						label: activityData.eventType.eventTypeName,
					}
				: null,
			event_surgical_assitant: activityData?.patientActivityDAO
				?.event_surgical_assitant
				? {
						value: activityData?.patientActivityDAO
							?.event_surgical_assitant.id,
						label:
							(activityData?.patientActivityDAO
								?.event_surgical_assitant.name || "") +
							" " +
							(activityData?.patientActivityDAO
								?.event_surgical_assitant?.lastName || ""),
					}
				: null,
			event_surgeon: activityData?.eventSurgeon
				? {
						value: activityData?.eventSurgeon.id,
						label: [
							activityData?.eventSurgeon?.firstName.toUpperCase(),
							activityData?.eventSurgeon?.middleName.toUpperCase(),
							activityData?.eventSurgeon?.lastName?.toUpperCase(),
						]
							.filter(Boolean)
							.join(" "),
					}
				: null,

			event_facility: activityData?.patientActivityDAO?.event_facility
				? {
						value: activityData?.patientActivityDAO?.event_facility
							.id,
						label: activityData?.patientActivityDAO?.event_facility
							.facilityName,
					}
				: null,
			paytoadmin_id: activityData?.payToAdmin
				? {
						value: activityData?.payToAdmin?.id,
						label: activityData?.payToAdmin?.payToName,
					}
				: null,
			id: activityData?.patientActivityDAO?.patientEventId || null,
			user_id: activityData?.patientActivityDAO?.user_id || null,
		});
	}, [activityData]);

	const validationSchema = Yup.object({
		// event_type: Yup.mixed()
		// 	.nullable()
		// 	.test("event-type-required", "Required",
		// 		function (value) { return value !== null && value.value; }),
		// event_surgeon: Yup.mixed()
		// 	.nullable()
		// 	.test("surgeon-required", "Required",
		// 		function (value) { return value !== null && value.value; }),
		// event_facility: Yup.mixed()
		// 	.nullable()
		// 	.test("facility-required", "Required",
		// 		function (value) { return value !== null && value.value; }),
		// event_surgical_assitant: Yup.mixed()
		// 	.nullable()
		// 	.test("surgical-assitant-required", "Required",
		// 		function (value) { return value !== null && value.value; }),
		// event_start_date: Yup.string().required("Required"),
		// event_start_time: Yup.string().required("Required"),
		// placeOfService: Yup.string().required("Required"),
		// account_number: Yup.string().required("Required"),
		// admission_date: Yup.string().required("Required"),
		// event_start_date: Yup.string().required("Required"),
		// event_start_time: Yup.string().required("Required"),
		// placeOfService: Yup.string().required("Required"),
		event_start_date: Yup.string().notRequired(),
		event_start_time: Yup.string().notRequired(),
		event_end_date: Yup.date()
			.test(
				"is-after-start-date",
				"End date must be greater than the start date unless they are the same",
				function (value) {
					const {
						event_start_date,
						event_start_time,
						event_end_time,
					} = this.parent;

					if (!event_start_date || !value) {
						return true; // No validation if start or end date is missing
					}

					const startDate = new Date(event_start_date);
					const endDate = new Date(value);

					// Check if the dates are the same
					if (startDate.toDateString() === endDate.toDateString()) {
						return true; // No validation required if the dates are the same
					}
					return endDate > startDate;
				}
			)
			.notRequired(),
		event_end_time: Yup.string()
			.test(
				"is-after-start-time",
				"End time must be greater than start time if the dates are the same",
				function (value) {
					const {
						event_start_date,
						event_end_date,
						event_start_time,
					} = this.parent;

					if (!event_start_date || !event_end_date) {
						return true; // No validation if dates are missing
					}

					const startDate = new Date(event_start_date);
					const endDate = new Date(event_end_date);

					// Only validate times if the dates are the same
					if (startDate.toDateString() === endDate.toDateString()) {
						if (event_start_time && value) {
							const [startHours, startMinutes] = event_start_time
								.split(":")
								.map(Number);
							const [endHours, endMinutes] = value
								.split(":")
								.map(Number);

							const startTime = new Date();
							startTime.setHours(startHours, startMinutes);

							const endTime = new Date();
							endTime.setHours(endHours, endMinutes);

							return endTime > startTime;
						}
					}

					return true; // Pass validation if the dates are different or times are not provided
				}
			)
			.notRequired(),
	});

	const updateActivity = async (values) => {
		setLoading(true);
		setStatus("");
		try {
			const files = values.document_detail.map((o) => ({
				type: o.file_type,
				fileUrl: o.file,
			}));

			/* ADD ACTIVITY VALIDATION START */
			const hasFile = files.length;
			const hasRequiredFiled =
				values.event_facility?.value &&
				values.patient_id?.value &&
				values.event_surgical_assitant;
			if (!hasFile && !hasRequiredFiled) {
				addToastMessage({
					status: false,
					message: `Please upload a file or select Facility, Patient, and Assistant to proceed with updating the activity.`,
				});
				setStatus(
					"Please upload a file or select Facility, Patient, and Assistant to proceed with updating the activity."
				);
				return false;
			}
			/* ADD ACTIVITY VALIDATION START */

			/* ADD FACESHEET VALIDATION START */
			// const hasFacesheet = files.some((file) => file.type === "facesheet");

			// if (!hasFacesheet) {
			// 	addToastMessage({
			// 		status: false,
			// 		message: `Please add a facesheet to proceed with updating the activity.`,
			// 	})
			// 	setStatus("Please add a facesheet to proceed with updating the activity.");
			// 	return false;
			// }
			/* ADD FACESHEET VALIDATION END   */

			const payload = {};

			if (values.document_detail.length) payload.files = files;

			// payload.document_detail = values.document_detail;

			if (values.patient_id?.value) {
				payload.patient_id = values.patient_id.value;
				payload.patient_name = values.patient_id.label; // Why it required
			}

			if (values.event_facility?.value) {
				payload.event_facility = values.event_facility.value;
			}

			if (values.paytoadmin_id?.value) {
				payload.paytoadmin_id = values.paytoadmin_id.value;
			}
			if (values?.procedure_id && values.procedure_id?.length > 0) {
				payload.procedure_id = values.procedure_id
					.map((item) => item.val) // Extract 'value' from each object
					.join(","); // Join with commas,
			}

			if (values.user_id) {
				payload.user_id = values.user_id;
			}

			if (values.event_surgeon?.value) {
				payload.event_surgeon = values.event_surgeon.value;
			}

			// Conditionally handle file and file_type based on whether a file is uploaded
			// if (values.file) {
			//     payload.file = values.file;
			//     payload.file_type = values.file_type; // Only send file_type if the file is present
			// }

			if (values.event_surgical_assitant) {
				payload.event_surgical_assitant =
					values.event_surgical_assitant.value;
			}
			if (values.account_number) {
				payload.account_number = values.account_number;
			}
			if (values.placeOfService) {
				payload.placeOfService = values.placeOfService;
			}
			if (values.event_start_date) {
				payload.event_start_date = convertToMMDDYYYY(
					values.event_start_date
				);
			}
			if (values.event_end_date) {
				payload.event_end_date = convertToMMDDYYYY(
					values.event_end_date
				);
			}
			if (values.event_start_time) {
				payload.event_start_time = convertTo12HourFormat(
					values.event_start_time
				);
			}
			if (values.event_end_time) {
				payload.event_end_time = convertTo12HourFormat(
					values.event_end_time
				);
			}
			if (values.event_type?.value) {
				payload.event_type = values.event_type.value;
			}
			if (values.event_flags?.value) {
				payload.event_flags = values.event_flags.value;
			}
			if (values.event_notes) {
				payload.event_notes = values.event_notes;
			}
			if (values?.admission_date) {
				payload.admission_date = convertToMMDDYYYY(
					values.admission_date
				);
			}
			if (values.id) {
				payload.id = values.id;
			}
			payload.status = "new";
			payload.modified_by = values.modified_by;

			const response = await handleApiCall(
				API_URL + `/api/1.0.0/activity`,
				{
					method: "PUT",
					body: payload,
				}
			);
			if (response.responseStatus === true) {
				setStatus(
					"You have successfully updated an activity, thank you."
				);
				addToastMessage({
					status: true,
					message: `You have successfully updated an activity, thank you.`,
				});
				if (nextUrl) {
					navigate(nextUrl);
				} else {
					navigate("/activities");
				}
				return true;
			} else {
				addToastMessage({
					status: false,
					message: `${response.responseDescription || "Something went wrong, please try again later."}`,
				});
				return false;
			}
		} catch (e) {
			console.error("Error occurred during updateActivity:", e);
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
			return false;
		} finally {
			setLoading(false);
		}
	};

	const labelSurgeonFunc = (o) =>
		`${o?.firstName} ${o?.middleName} ${o?.lastName}`;
	const labelFacilityFunc = (o) => `${o.facilityName}`;
	const labelPayToFunc = (o) => `${o.payToName}`;
	const labelEventFlagsFunc = (o) => `${o.scheduleFlag}`;
	const labelEventTypeFunc = (o) => `${o.eventTypeName}`;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, { validateForm }) => {
				await validateForm();
				updateActivity(values);
			}}>
			{({
				values,
				setFieldValue,
				errors,
				touched,
				handleSubmit,
				validateForm,
				setFieldTouched,
				dirty,
			}) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td>
										<Label
											title='Event Type'
											required={false}
										/>
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/event-type?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelEventTypeFunc
												)
											}
											error={
												errors.event_type &&
												touched.event_type
											}
											setTouched={() => {
												setFieldTouched(
													"event_type",
													true
												);
											}}
											placeHolder='Search'
											selectedValue={values.event_type}
											onChange={(v) => {
												setFieldValue("event_type", v);
											}}
										/>
									</td>
									<td width='50%'>
										<Label title='Patient' />
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/patients?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelPatientsFunc
												)
											}
											error={
												errors.patient_id &&
												touched.patient_id
											}
											setTouched={() => {
												setFieldTouched(
													"patient_id",
													true
												);
											}}
											placeHolder='Search'
											selectedValue={values.patient_id}
											onChange={(v) => {
												if (v) {
													setFieldValue(
														"patient_id",
														v
													);
													setFieldValue(
														"patient_name",
														v.label
													);
													setFieldValue(
														"account_number",
														v.obj.accountNumber
													);
												} else {
													setFieldValue(
														"patient_id",
														null
													);
													setFieldValue(
														"patient_name",
														""
													);
													setFieldValue(
														"account_number",
														""
													);
												}
											}}
										/>
										<p
											onClick={() => {
												setShow(true);
											}}
											style={{
												paddingTop: "4px",
												color: "#8f8f8f",
											}}>
											Could't find the patient?{" "}
											<span className='cursor-pointer color-secondary hover-color-primary hover-t-d-ul transition'>
												Click to add
											</span>
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan='2'>
										<AddPatientForm
											setShow={setShow}
											show={show}
											callback={(v) => {
												setFieldValue("patient_id", v);
												setFieldValue(
													"patient_name",
													v.label
												);
												setFieldValue(
													"account_number",
													""
												);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan='2'>
										<NestedDocumentForm
											onAdd={(newDocument) => {
												setFieldValue(
													"document_detail",
													[
														...values.document_detail,
														newDocument,
													]
												);
											}}
											p={values?.patient_id}
											list={values?.document_detail}
											onRemove={(index) => {
												const updatedData =
													values.document_detail.filter(
														(_, i) => i !== index
													);
												setFieldValue(
													"document_detail",
													updatedData
												);
											}}
											setUploaded={setUploaded}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan='2'>
										<Label title='Procedure Name/CPT Code' />
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/procedure?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelProcedureFunc
												)
											}
											selectedValue={procedures}
											placeHolder='Search'
											error={
												errors.procedure_id &&
												touched.procedure_id
											}
											setTouched={() => {
												setFieldTouched(
													"procedure_id",
													true
												);
											}}
											onChange={async (v) => {
												if (v) {
													setProcedures(v);
													const p =
														values.procedure_id;
													const n = {
														val: v.value,
														name: v.label,
													};
													if (
														!p.some(
															(item) =>
																item.val ===
																v.value
														)
													) {
														setFieldValue(
															"procedure_id",

															[
																...values.procedure_id,
																n,
															]
														);
													}
													await sleep();
													setProcedures(null);
												}
											}}
										/>

										{values.procedure_id.length > 0 && (
											<ul
												className='col-1-1 t-a-l'
												style={{
													padding: "10px 0",
												}}>
												{values.procedure_id.map(
													(p, j) => (
														<li
															data-tooltip={`Click to delete the procedure.`}
															onClick={() => {
																const filtered =
																	values.procedure_id.filter(
																		(obj) =>
																			obj.val !==
																			p.val
																	);
																setFieldValue(
																	"procedure_id",
																	filtered
																);
															}}
															style={{
																listStyle:
																	"none",
																marginRight:
																	"4px",
																marginBottom:
																	"4px",
																borderRadius:
																	"2px",
																lineHeight:
																	"32px",
																padding:
																	"10px 12px",
																verticalAlign:
																	"middle",
															}}
															key={j}
															className='opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition'>
															<div className='flex center-left'>
																<p
																	className='of-hid'
																	style={{
																		whiteSpace:
																			"nowrap",
																		width: "108px",
																		textOverflow:
																			"ellipsis",
																		fontSize:
																			"12px",
																	}}>
																	{p.name}
																</p>
																<MdCancel  size={20}/>
															</div>
														</li>
													)
												)}
											</ul>
										)}

										<p
											style={{
												paddingTop: "4px",
												color: "#8f8f8f",
											}}>
											Could't find the procedure?{" "}
											<span
												className='cursor-pointer color-secondary hover-color-primary hover-t-d-ul transition'
												onClick={() =>
													navigate("/add-procedure")
												}>
												Click to add
											</span>
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										<Label title='Account Number' />
										<Field
											type='text'
											placeholder='Enter Acc. No.'
											value={values.account_number}
											name='account_number'
											className={`col-1-1 primary ${
												errors.account_number &&
												touched.account_number
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label
											title='Surgeon'
											required={false}
										/>
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/surgeon?`}
											error={
												errors.event_surgeon &&
												touched.event_surgeon
											}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelSurgeonFunc
												)
											}
											setTouched={() => {
												setFieldTouched(
													"event_surgeon",
													true
												);
											}}
											placeHolder='Search'
											selectedValue={values.event_surgeon}
											onChange={(v) => {
												setFieldValue(
													"event_surgeon",
													v
												);
											}}
										/>
									</td>
									<td>
										<Label
											title='Facility'
											required={false}
										/>
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/facility?`}
											error={
												errors.event_facility &&
												touched.event_facility
											}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelFacilityFunc
												)
											}
											setTouched={() => {
												setFieldTouched(
													"event_facility",
													true
												);
											}}
											placeHolder='Search'
											selectedValue={
												values.event_facility
											}
											onChange={(v) => {
												setFieldValue(
													"event_facility",
													v
												);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label
											title='Assistant'
											required={false}
										/>
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/assistants?`}
											error={
												errors.event_surgical_assitant &&
												touched.event_surgical_assitant
											}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelAssistantsFunc
												)
											}
											setTouched={() => {
												setFieldTouched(
													"event_surgical_assitant",
													true
												);
											}}
											placeHolder='Search'
											selectedValue={
												values.event_surgical_assitant
											}
											onChange={(v) => {
												setFieldValue(
													"event_surgical_assitant",
													v
												);
											}}
										/>
									</td>
									<td>
										<Label title='Admission Date' />
										<DatePickerInput
											className='col-1-1'
											value={values.admission_date}
											callback={(v) =>
												setFieldValue(
													"admission_date",
													v
												)
											}
											error={
												errors.admission_date &&
												touched.admission_date
											}
											sertTouched={() => {
												setFieldTouched(
													"admission_date",
													true
												);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label
											title='Start Date'
											required={false}
										/>
										<DatePickerInput
											className='col-1-1'
											value={values.event_start_date}
											callback={(v) =>
												setFieldValue(
													"event_start_date",
													v
												)
											}
											error={
												errors.event_start_date &&
												touched.event_start_date
											}
											setTouched={() => {
												setFieldTouched(
													"event_start_date",
													true
												);
											}}
										/>
									</td>
									<td>
										<Label
											title='Start Time'
											required={false}
										/>
										<Field
											type='time'
											name='event_start_time'
											value={values.event_start_time}
											className={`col-1-1 primary ${
												errors.event_start_time &&
												touched.event_start_time
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label title='End Date' />
										<DatePickerInput
											className='col-1-1'
											value={values.event_end_date}
											callback={(v) =>
												setFieldValue(
													"event_end_date",
													v
												)
											}
											error={
												errors.event_end_date &&
												touched.event_end_date
											}
											setTouched={() => {
												setFieldTouched(
													"event_end_date",
													true
												);
											}}
										/>
										<ErrorMessage
											name='event_end_date'
											component='p'
											className='error-messages'
										/>
									</td>
									<td>
										<Label title='End Time' />
										<Field
											type='time'
											name='event_end_time'
											value={values.event_end_time}
											className={`col-1-1 primary ${
												errors.event_end_time &&
												touched.event_end_time
													? "error"
													: ""
											}`}
										/>
										<ErrorMessage
											name='event_end_time'
											component='p'
											className='error-messages'
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label
											title='Place of Service'
											required={false}
										/>
										<Field
											as='select'
											name='placeOfService'
											value={values.placeOfService}
											className={`col-1-1 primary ${
												errors.placeOfService &&
												touched.placeOfService
													? "error"
													: ""
											}`}>
											<option value=''>Select</option>
											{PLACE_OF_SERVICE.map((d, i) => (
												<option value={d.value} key={i}>
													{d.label}
												</option>
											))}
										</Field>
									</td>
									<td>
										<Label title='Event Flags' />
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/event-flags?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelEventFlagsFunc
												)
											}
											error={
												errors.event_flags &&
												touched.event_flags
											}
											setTouched={() => {
												setFieldTouched(
													"event_flags",
													true
												);
											}}
											placeHolder='Search'
											selectedValue={values.event_flags}
											onChange={(v) => {
												setFieldValue("event_flags", v);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan='2'>
										<Label title='Notes' />
										<Field
											as='textarea'
											name='event_notes'
											placeholder='Add Notes'
											value={values.event_notes}
											className={`primary ${
												errors.event_notes &&
												touched.event_notes
													? "error"
													: ""
											}`}
										/>
									</td>
								</tr>
								{/* <tr>
                                    <td colSpan='2'>
                                        <p
                                            style={{
                                                paddingTop: "10px",
                                                paddingBottom: "10px",
                                                color: "#8f8f8f",
                                            }}
                                            className='l-h-1'>
                                            Click checkbox only if you do not
                                            know the end time.
                                        </p>
                                        <div>
                                            <label className='cursor-pointer'>
                                                <div
                                                    className='flex center-left'
                                                    style={{ gap: "12px" }}>
                                                    <Field
                                                        type='checkbox'
                                                        name='endTimeNotConfirmed'
                                                        className={`primary ${errors.endTimeNotConfirmed &&
                                                            touched.endTimeNotConfirmed
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                    <p className='color-light'>
                                                        End time not confirmed.
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                    </td>
                                </tr> */}
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => {
								if (nextUrl) {
									navigate(nextUrl);
								} else {
									navigate("/activities");
								}
							}}
							icon={<TbArrowBackUp />}
						/>

						<ButtonTextIcon
							type='button'
							disabled={loading || uploaded || !dirty}
							title='Update Now'
							className='mar-l-a'
							onClick={async () => {
								const errors = await validateForm();
								if (Object.keys(errors).length > 0) {
									const firstErrorMessage =
										Object.values(errors)[0];
									addToastMessage({
										status: false,
										message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
									});
								}
								handleSubmit();
							}}
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<FiArrowRightCircle />
								)
							}
						/>
					</div>
					{status && (
						<>
							<br />
							<p className='color-primary f-w-700'>{status}</p>
						</>
					)}
				</Form>
			)}
		</Formik>
	);
};

const NestedDocumentForm = ({ onAdd, onRemove, p, list, setUploaded }) => {
	const [addFile, setAddFile] = useState(true);
	const fileUploadRef = useRef();
	const location = useLocation();
	let typeCounters = {};
	const validationSchema = Yup.object().shape({
		file_type: Yup.string().required("File Type is required"),
		file: Yup.string().required("File is required"),
	});
	const handleReset = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.resetFile();
		}
	};
	return (
		<Formik
			initialValues={{
				file_type: "",
				file: "",
			}}
			validationSchema={validationSchema}
			onSubmit={(values, { resetForm }) => {
				onAdd(values);
				setAddFile(true);
				resetForm();
				handleReset();
			}}>
			{({
				errors,
				touched,
				setFieldValue,
				values,
				submitForm,
				validateForm,
			}) => (
				<Form>
					<table>
						<tbody>
							{list && (
								<tr>
									<td colSpan='2'>
										<div className='inner-data'>
											<table>
												<thead>
													<tr>
														<th className='color-primary'>
															File Type
														</th>
														<th className='color-primary'>
															File
														</th>
														<th
															className='color-primary'
															style={{
																textAlign:
																	"center",
															}}>
															Action
														</th>
													</tr>
												</thead>
												<tbody>
													{list.map((d, index) => {
														if (index === 0) {
															typeCounters = {};
														}

														const type =
															d.file_type;
														typeCounters[type] =
															(typeCounters[
																type
															] || 0) + 1;

														return (
															<tr key={index}>
																<td>
																	<span className='badge color-primary'>
																		{
																			d.file_type
																		}
																	</span>
																</td>
																<td className='color-primary t-t-u l-s-n '>{`${p?.label ? p?.label + "-" : ""}${d.file_type || ""}-${typeCounters[d.file_type]}`}</td>
																<td className='color-primary'>
																	<div
																		className='flex center-center'
																		style={{
																			gap: "10px",
																		}}>
																		<span
																			className='cursor-pointer'
																			onClick={() => {
																				onRemove(
																					index
																				);
																			}}>
																			<MdOutlineDeleteSweep color="red"/> 
																		</span>
																		{d.url && (
																			<div
																				className='opl-tooltip'
																				data-tooltip='Click to View File.'>
																				<div
																					style={{
																						display:
																							"flex",
																						gap: "8px",
																					}}>
																					<NavLink
																						to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
																							d?.url ||
																								""
																						)}&title=${encodeURIComponent(
																							`${p?.label ? p?.label + "-" : ""}${d.file_type || ""}-${typeCounters[d.file_type]}`
																						)}`}
																						state={
																							location.state
																						}>
																						<span
																							style={{
																								background:
																									"transparent",
																								border: "none",
																								cursor: "pointer",
																							}}
																							title='View file'>
																							 <LuEye />
																						</span>
																					</NavLink>
																				</div>
																			</div>
																		)}
																	</div>
																</td>
															</tr>
														);
													})}
													{[list].length === 0 && (
														<tr>
															<td colSpan='3'>
																<div className='t-a-c color-primary'>
																	{" "}
																	No files
																	uploaded.{" "}
																</div>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</td>
								</tr>
							)}
							{addFile ? (
								<tr>
									<td colSpan='2'>
										<div className='flex center-center'>
											<div
												className='opl-tooltip'
												data-tooltip='Click to Add File.'>
												<Label title='&nbsp;' />
												<ButtonLightTextIcon
													title='Add File'
													type='button'
													onClick={() => {
														setAddFile(
															(pre) => !pre
														);
													}}
													icon={<FiPlusCircle />}
												/>
											</div>
										</div>
									</td>
								</tr>
							) : (
								<>
									<tr>
										<td colSpan='2'>
											<Label title='File Type' />
											<Field
												as='select'
												name='file_type'
												className={`col-1-1 primary ${
													errors.file_type &&
													touched.file_type
														? "error"
														: ""
												}`}>
												<option value=''>Select</option>
												{ALLOWED_FILE_TYPES.map(
													({ value, label }) => {
														const isFacesheet =
															value ===
															"facesheet";
														const isFacesheetPresent =
															list.some(
																(file) =>
																	file.file_type ===
																	"facesheet"
															);

														if (
															isFacesheet &&
															isFacesheetPresent
														)
															return null;

														return (
															<option
																value={value}
																key={label}>
																{label}
															</option>
														);
													}
												).filter(Boolean)}
											</Field>
										</td>
									</tr>
									{values.file_type && (
										<tr>
											<td colSpan='2'>
												<div
													className='flex center-left'
													style={{ gap: "8px" }}>
													<div
														className='opl-tooltip'
														data-tooltip='Please only select PDF files for upload.'>
														<Label title='Upload File' />
													</div>
													<div
														className='opl-tooltip'
														data-tooltip='Please only select PDF files for upload.'>
														<FcInfo />
													</div>
												</div>
												<DragFileUpload
													ref={fileUploadRef}
													fileType={values.file_type}
													error={
														errors.file &&
														touched.file
													}
													afterUpload={async (
														url
													) => {
														if (url) {
															await setFieldValue(
																"file",
																url
															);
															setTimeout(
																async () => {
																	const e =
																		await validateForm();
																	await submitForm();
																},
																0
															);
														} else {
															await setFieldValue(
																"file",
																""
															);
														}
													}}
													setUploading={setUploaded}
												/>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</table>
				</Form>
			)}
		</Formik>
	);
};

export default UpdateActivityForm;
