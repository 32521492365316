const Label = ({ variant="default", title, required }) => {

	const getClassName = () => {
		if(variant === "filter"){
            return "color-primary f-w-300 l-h-1";
		}else{
			return "color-primary f-w-600"
		}
	}

	const setStyle = () => {
		if(variant === "filter"){
			return { fontSize: "12px", paddingBottom: "6px" };
		}else{
            return { paddingBottom: "5px" };
		}
	}
	return (
		<p className={getClassName()} style={setStyle()} >
			{title}
			{required && (
				<span style={{ color: "red", marginLeft: "4px" }}>*</span>
			)}
		</p>
	);
};
export default Label;