import AddForm from "./surgical-assistant/AddForm";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import PageToolbar from "../components/common/PageToolbar";
import { useNavigate } from "react-router-dom";
import { TbArrowBackUp } from "react-icons/tb";

const AddSurgicalAssistant = () => {
  const navigate = useNavigate();
  return (
    <section id="add-physician-assistant">
      <PageToolbar
        title={"Surgical Assistant"}
        button={
          <ButtonTextIcon
            title="Cancel"
            className="light mar-l-a"
            onClick={() => navigate("/surgical_assistance")}
            icon={
             <TbArrowBackUp />
            }
          />
        }
      />
      <div className="col-1-1">
        <div className="form-holder">
          <div className="inner">
            <AddForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddSurgicalAssistant;
