import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import LoaderIcon from "../../components/ui/LoaderIcon";
import {
  formatRateUSD,
  formatServerDate,
  getRandomNumber,
  handleApiCall,
} from "../../utilities/helpers";
import { useUI } from "../../context/ui";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import useQueryParams from "../../hooks/useQueryParams";
import Label from "../../components/ui/Label";
import { TbArrowBackUp } from "react-icons/tb";
import { MdCancel } from "react-icons/md";
import { FiArrowRightCircle } from "react-icons/fi";

const FullScreenUpdateBillFormModal = ({ id = null, title = "", setShow,Callback }) => {
  const [billData, setBillData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBillData = async (id) => {
    try {
      const response = await handleApiCall(API_URL + `/api/1.0.0/bill/${id}`, {
        method: "GET",
      });
      if (response.responseStatus === true) {
        setBillData(response.data);
      } else {
        setError("Failed to fetch bill data.");
      }
    } catch (e) {
      setError("Failed to fetch bill data.");
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBillData(id);
  }, [id]);

  return (
    <section
      style={{
        position: "fixed",
        inset: 0,
        zIndex: 9999,
        backgroundColor: "rgba(255, 255, 255, 0.86)",
      }}
    >
      <section
        className="col-1-1 flex center-center"
        style={{
          height: "100vh",
          width: "100vw",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            height: "100vh",
            width: "100vw",
            margin: "10px",
            padding: "15px",
            border: "solid 1px #f0f0f0",
            background: "white",
            borderRadius: "8px",
            margin: "0 auto",
            boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
          }}
        >
          <div
            className="col-1-1 flex center-left"
            style={{
              borderBottom: "solid 1px #f0f0f0",
              paddingBottom: "12px",
              marginBottom: "16px",
              gap: "10px",
            }}
          >
            <h6 className="color-primary f-w-700 t-t-u l-s-n">{title || ""}</h6>
            <div
              className="mar-l-a cursor-pointer "
              onClick={() => setShow(false)}
            >
             <MdCancel  size={28}/>
            </div>
          </div>
          <section id="add-activity">
            <div className="col-1-1">
            {!error && !loading && billData && <MedicalBill setShow={setShow} data={billData} Callback = {Callback} />}

              {!error && loading && !billData && (
                <p
                  style={{
                    height: "100vh",
                    paddingBottom: "20vh",
                    backgroundColor: "#fafaff",
                  }}
                  className="flex center-center"
                >
                  <LoaderIcon
                    style={{
                      height: "32px",
                      width: "32px",
                    }}
                  />
                </p>
              )}
              {error && !loading && !billData && (
                <p
                  style={{
                    height: "100vh",
                    paddingBottom: "20vh",
                    backgroundColor: "#fafaff",
                  }}
                  className="flex center-center"
                >
                  {error || "Error"}
                </p>
              )}
            </div>
          </section>
        </div>
      </section>
    </section>
  );
};

export default FullScreenUpdateBillFormModal;

const MedicalBill = ({ data, setShow ,Callback}) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [initialValues, setInitialValues] = useState({
    amount: 0.0,
    insurancePaymentReceived: 0.0,
    comment: "",
  });
  const [amount, setAmount] = useState(0.0);
  const [insurancePaymentReceived, setInsurancePaymentReceived] = useState(0.0);
  const { addToastMessage } = useUI();
  const { updateQueryParam } = useQueryParams();

  const validationSchema = Yup.object({
    amount: Yup.number()
      .typeError("Amount must be a number")
      .test(
        "is-positive",
        "Amount must be greater than 0",
        (value) => value > 0
      )
      .test(
        "two-decimals",
        "Amount must have at most two decimal places",
        (value) => /^\d+(\.\d{1,2})?$/.test(value)
      )
      .required("Amount is required"),
    comment: Yup.string()
      .max(255, "Comment must be less than 255 characters")
      .required("Comment is required"),
    insurancePaymentReceived: Yup.number()
      .typeError("Insurance payment received must be a number")
      .min(0, "Insurance payment received must be greater than or equal to 0")
    .max(999999, "Insurance payment received must be less than 999999"),
  });

  const updateBill = async (values) => {
    setLoading(true);
    setStatus("");
    try {
      const payload = {
        charge: parseFloat(values.amount).toFixed(2),
        comment: values.comment,
        insurancePayment: parseFloat(values.insurancePaymentReceived).toFixed(2),
      };

      const response = await handleApiCall(
        API_URL + `/api/1.0.0/bill/${data.id}`,
        {
          method: "PUT",
          body: payload,
        }
      );

      if (response.responseStatus === true) {
        return true;
      } else {
        setStatus(
          `${response.responseDescription || "Something went wrong, please try again later."}`
        );
        addToastMessage({
          status: false,
          message: `${response.responseDescription || "Something went wrong, please try again later."}`,
        });
        return false;
      }
    } catch (e) {
      setStatus("Something went wrong, please try again later.");
      addToastMessage({
        status: false,
        message: `Something went wrong, please try again later.`,
      });
      console.error("Error:", e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setInitialValues({
        amount: data?.amount ? data?.amount.toFixed(2) : 0.0,
        comment: "",
        insurancePaymentReceived: data?.insurancePaymentReceived ? data?.insurancePaymentReceived.toFixed(2) : 0.0,
      });
      setAmount(parseInt(data?.amount).toFixed(2));
      setInsurancePaymentReceived(parseInt(data?.insurancePaymentReceived).toFixed(2));
    }
  }, [data]);
  const preventExponentialInput = (event) => {
    if (event.key === "e" || event.key === "E" || event.key === "+" || event.key === "-") {
      event.preventDefault();
    }
  };


  return (
    <div
      style={{
        maxWidth: "700px",
        margin: "20px auto",
        padding: "20px",
        backgroundColor: "white",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        fontSize: "11px",
        border: "1px solid #ccc",
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          borderBottom: "1px solid #ccc",
          paddingBottom: "10px",
        }}
      >
        <div>
          <h1 style={{ fontSize: "18px", fontWeight: "bold", color: "#333" }}>
            Medical Bill
          </h1>
          {/* <p style={{ fontSize: "10px", color: "#666", marginTop: "4px" }}>
            Bill Id: #{data.id}
          </p> */}
        </div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gap: "16px",
          marginTop: "20px",
        }}
      >
        <div>
          <h2
            style={{
              fontWeight: "600",
              fontSize: "12px",
              color: "#555",
              marginBottom: "8px",
              textTransform: "uppercase",
            }}
          >
            Patient Information
          </h2>
          <div style={{ color: "#666", lineHeight: "1.5" }}>
            {[
              {
                label: "Name:",
                value: `${data?.activity?.patient?.firstName || "NA"} ${data?.activity?.patient?.middleName || ""} ${data?.activity?.patient?.lastName || ""}`,
              },
              {
                label: "DOB:",
                value: data?.activity?.patient?.dateOfBirth
                  ? formatServerDate(data?.activity?.patient?.dateOfBirth)
                  : "NA",
              },
              {
                label: "Chart No:",
                value: data?.activity?.patient?.patientChartNo || "NA",
              },
              {
                label: "Service Date:",
                value: data?.activity?.patient?.serviceDate
                  ? formatServerDate(data?.activity?.patient?.serviceDate)
                  : "NA",
              },
              {
                label: "Surgeon:",
                value: data?.activity?.surgeon?.firstName
                  ? data?.activity?.surgeon?.firstName +
                    " " +
                    (data?.activity?.surgeon?.middleName || "")
                  : "NA",
              },
            ].map((item, idx) => (
              <p
                key={idx}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    minWidth: "100px",
                    fontWeight: "500",
                    flexShrink: 0,
                  }}
                >
                  {item.label}
                </span>
                <span>{item.value}</span>
              </p>
            ))}
          </div>
        </div>
        <div>
          <h2
            style={{
              fontWeight: "600",
              fontSize: "12px",
              color: "#555",
              marginBottom: "8px",
              textTransform: "uppercase",
            }}
          >
            Activity Details
          </h2>
          <div style={{ color: "#666", lineHeight: "1.5" }}>
            {[
              {
                label: "Facility:",
                value: data?.facility?.facilityName || "NA",
              },
              { label: "Specialty:", value: data?.speciality?.name || "NA" },
              {
                label: "Procedures:",
                value: data?.procedures?.length
                  ? data?.procedures
                      .map((proc) => proc.procedureName)
                      .join(", ")
                  : "NA",
              },
              {
                label: "Assistant:",
                value: data?.activity?.event_surgical_assitant?.name
                  ? data?.activity?.event_surgical_assitant?.name +
                    " " +
                    (data?.activity?.event_surgical_assitant?.lastName || "")
                  : "NA",
              },
            ].map((item, idx) => (
              <p
                key={idx}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    minWidth: "100px",
                    fontWeight: "500",
                    flexShrink: 0,
                  }}
                >
                  {item.label}
                </span>
                <span>{item.value}</span>
              </p>
            ))}
          </div>
        </div>
      </div>

      <hr style={{ border: "0.5px solid #ccc", margin: "20px 0" }} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          const api = await updateBill(values);
          if (api) {
            resetForm();
            setStatus("You have successfully updated a bill, thank you.");
            addToastMessage({
              status: true,
              message: `You have successfully updated a bill, thank you.`,
            });
            Callback(data.id);
            setShow(false);
            updateQueryParam("r", getRandomNumber());
          }
        }}
      >
        {({
          values,
          setFieldValue,
          setValues,
          errors,
          touched,
          handleSubmit,
          validateForm,
          setFieldTouched,
        }) => (
          <Form>
            <div>
              <h2
                style={{
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "#555",
                  marginBottom: "12px",
                  textTransform: "uppercase",
                }}
              >
                Charges
              </h2>
              <table
                style={{
                  width: "100%",
                  fontSize: "11px",
                  color: "#666",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#f9f9f9" }}>
                    <th
                      className="color-primary"
                      style={{
                        textAlign: "left",
                        padding: "8px",
                        fontWeight: "600",
                        color: "#555",
                      }}
                    >
                      Description
                    </th>
                    <th
                      className="color-primary"
                      style={{
                        textAlign: "right",
                        padding: "8px",
                        fontWeight: "600",
                        color: "#555",
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.oncallCharge ? (
                    <tr>
                      <td
                        className="color-primary"
                        style={{
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        On-call Charge
                      </td>
                      <td
                        className="color-primary"
                        style={{
                          textAlign: "right",
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div className="flex center-rigth">
                          <div style={{ marginLeft: "auto" }}>
                            <Field
                              style={{
                                width: "170px",
                                height: "26px",
                                marginLeft: "0",
                              }}
                              onKeyDown={preventExponentialInput}
                              type="number"
                              name="amount"
                              className="primary"
                            />
                          </div>
                        </div>
                        <div className="flex center-rigth">
                          <div style={{ marginLeft: "auto" }}>
                            <p style={{ color: "red", fontSize: "10px" }}>
                              {" "}
                              {errors.amount}&nbsp;
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}

                  {data.procedureCharge ? (
                    <tr>
                      <td
                        className="color-primary"
                        style={{
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        Specialty Charge
                      </td>
                      <td
                        className="color-primary"
                        style={{
                          textAlign: "right",
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div className="flex center-rigth">
                          <div style={{ marginLeft: "auto" }}>
                            <Field
                              style={{
                                width: "170px",
                                height: "26px",
                                marginLeft: "0",
                              }}
                              onKeyDown={preventExponentialInput}
                              type="number"
                              name="amount"
                              className="primary"
                            />
                          </div>
                        </div>
                        <div className="flex center-rigth">
                          <div style={{ marginLeft: "auto" }}>
                            <p style={{ color: "red", fontSize: "10px" }}>
                              {" "}
                              {errors.amount}&nbsp;
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {data.billType === "billable" && (
                    <tr>
                      <td
                        className="color-primary"
                        style={{
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        Insurance Payment
                      </td>
                      <td
                        className="color-primary"
                        style={{
                          textAlign: "right",
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div className="flex center-rigth">
                          <div style={{ marginLeft: "auto" }}>
                            <Field
                              style={{
                                width: "170px",
                                height: "26px",
                                marginLeft: "0",
                              }}
                              onKeyDown={preventExponentialInput}
                              type="number"
                              name="insurancePaymentReceived"
                              className="primary"
                            />
                          </div>
                        </div>
                        <div className="flex center-rigth">
                          <div style={{ marginLeft: "auto" }}>
                            <p style={{ color: "red", fontSize: "10px" }}>
                              {" "}
                              {errors.insurancePaymentReceived}&nbsp;
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr style={{ fontWeight: "bold", color: "#333" }}>
                    <td className="color-primary" style={{ padding: "8px" }}>
                      Total Amount Due
                    </td>
                    <td
                      className="color-primary"
                      style={{ textAlign: "right", padding: "8px" }}
                    >
                      {formatRateUSD(
                        Math.max(
                          0,
                          values.amount - values.insurancePaymentReceived
                        )
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <Label title="Comments" required={true} />
              <Field
                className={`primary ${errors.comment && touched.comment ? "error" : ""}`}
                style={{
                  width: "100%",
                  marginLeft: "0",
                }}
                as="textarea"
                name="comment"
                value={values.comment}
                placeholder="Comments"
                onChange={(e) => {
                  setFieldValue("comment", e.target.value);
                }}
              />
              <div className="flex center-rigth">
                <div style={{ marginLeft: "auto" }}>
                  {errors.comment && touched.comment && (
                    <p style={{ color: "red", fontSize: "10px" }}>
                      {errors.comment}&nbsp;
                    </p>
                  )}
                </div>
              </div>
            </div>
            <br />
            <div className="flex center-left">
              <ButtonTextIcon
                type="button"
                className="light"
                title="Cancel"
                onClick={() => {
                  setShow(false);
                }}
                icon={
                  <TbArrowBackUp />
                }
              />

              <div style={{ marginLeft: "auto" }}>
                <ButtonTextIcon
                  title="Update Bill"
                  disabled={
                    (parseFloat(amount) === parseFloat(values.amount) &&
                      parseFloat(insurancePaymentReceived) ===
                        parseFloat(values.insurancePaymentReceived)) ||
                    loading
                  }
                  onClick={handleSubmit}
                  icon={
                    loading ? (
                      <LoaderIcon />
                    ) : (
                      <FiArrowRightCircle />
                    )
                  }
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
