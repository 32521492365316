import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import RegionsFilter from "./regions/TableFilter";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import CustomModal from "../components/common/CustomModal";
import { useUI } from "../context/ui";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import useQueryParams from "../hooks/useQueryParams";
import { handleApiCall, jsonToQueryParams } from "../utilities/helpers";
import PageToolbar from "../components/common/PageToolbar";
import { FiPlusCircle } from "react-icons/fi";

const RegionPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const search = queryParams.get("search") || "";
  const { addToastMessage, permissions } = useUI();
  const [controller, setController] = useState(null);

  const resetFilters = () => {
    updateQueryParam("pageNo", 0);
    updateQueryParam("search", "");
  };

  const getCategoryApi = async () => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    try {
      setLoading(true);
      const query = jsonToQueryParams({
        name: search,
      });
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/region?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus == true) {
        setData(response.data);
      } else {
        setData(null);
      }

      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/region/change-status/${id}`,
        {
          method: "PUT",
          body: { statusId: 2 },
        }
      );
      if (response.responseStatus === true) {
        getCategoryApi();
        addToastMessage({
          status: true,
          message: `Region deleted successfully.`,
        });
        return true;
      }
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting region. Please try again.`,
      });
    } catch (e) {
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategoryApi();
  }, [location.search]);

  return (
    <section id="category">
      <PageToolbar
        title="Regions"
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => navigate("/add-region")}
              icon={<FiPlusCircle />}
            />
          )
        }
      />
      <RegionsFilter resetFilters={resetFilters} />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          className="flex center-center"
          style={{ height: "100vh", backgroundColor: "#fafaff" }}
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Region Name</th>
                <th>Email</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.regionList.map((item, index) => (
                <tr key={index}>
                  <td>{pageNo * pageSize + index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>
                    <div className="flex center-center" style={{ gap: "12px" }}>
                      {permissions?.isDeleteAllowed && (
                        <div>
                          <NavLink
                            to={
                              item?.id
                                ? `/edit-region?&next=${window.location.pathname + window.location.search}`
                                : "#"
                            }
                            state={{ id: item?.id }}
                          >
                            <ButtonTertiary actionType="edit" title="Edit" />
                          </NavLink>
                        </div>
                      )}
                      {permissions?.isUpdateAllowed && (
                        <div className="color-secondary transition hover-color-primary cursor-pointer ">
                          <CustomModal
                            selector={
                              <ButtonTertiary
                                actionType="delete"
                                title="Delete"
                              />
                            }
                            content={
                              <ConfirmationModal
                                onConfirm={async () => {
                                  await handleDelete(item.id);
                                  return true;
                                }}
                                message="Are you sure you want to delete this region?"
                              />
                            }
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "24px 15px 32px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default RegionPage;
