import AddForm from "./activities/AddForm";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import { useNavigate } from "react-router-dom";
import PageToolbar from "../components/common/PageToolbar";
import { TbArrowBackUp, TbXboxX } from "react-icons/tb";

const AddActivityPage = () => {
	const navigate = useNavigate(); 
	
	return (
    <section>
      <section id="add-activity">
        <PageToolbar
          title="Activities"
          button={
            <ButtonTextIcon
              title="Cancel"
              className="light mar-l-a"
              onClick={() => navigate("/activities")}
              icon={<TbArrowBackUp />}
            />
          }
        />
        <div className="col-1-1">
          <div className="form-holder">
            <div className="inner">
              <AddForm />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default AddActivityPage;
