import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import PaginatedDropdown, { mapOptions } from "../../components/ui/PaginatedDropdown"; 
import { useEffect, useRef, useState } from "react";
import {
	API_URL,
	BILL_STATUS_OPTTIONS,
	CLAIM_STATUS_DROP_DOWN_OPTIONS,
	HOSPITAL_CONTRACT_DROP_DOWN_OPTIONS,
} from "../../utilities/constants";
import { formatServerDate, labelAssistantsFunc, labelPatientsFunc } from "../../utilities/helpers";
import DateRangeInput from "../../components/ui/DateRangeInput";
import useQueryParams from "../../hooks/useQueryParams";
import { useUI } from "../../context/ui";
import Label from "../../components/ui/Label";
import ResultsPerPage from "../../components/common/ResultsPerPage";
import CustomSelect from "../../components/ui/CustomSelect";
import { GrPowerReset } from "react-icons/gr";

const TableFilter = ({   resetFilters }) => {
  const { profile } = useUI();
  const { updateQueryParam, queryParams } = useQueryParams();
	const [patientChartNo, setPatientChartNo] = useState(queryParams.get("patientChartNo") || "");
  const debounceTimeout = useRef(null);

  const handlePatientChartNoChange = (value) => {
    setPatientChartNo(value);
 
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
 
    debounceTimeout.current = setTimeout(() => {
      queryParams.set("pageNo", 0);
      updateQueryParam("patientChartNo", value);
    }, 500);
  };
 
	const DROPDOWNS = [
		{
			title: "Patients",
			url: "/reference/patients",
			param: "patient",
			mapFunc: mapOptions,
			labelFunc: labelPatientsFunc,
			placeholder: "Search patient",
		},
		{
			title: "Practices",
			url: "/reference/practice",
			param: "practice",
			mapFunc: mapOptions,
			labelFunc: (o) => o.payToName,
			placeholder: "Search practice",
		},
		{
			title: "Facilities",
			url: "/reference/facility",
			param: "facility",
			mapFunc: mapOptions,
			labelFunc: (o) => o.facilityName,
			placeholder: "Search facility",
		},
		{
			title: "Insurances",
			url: "/reference/insurances",
			param: "insurance",
			mapFunc: mapOptions,
			labelFunc: (o) => `${o.description}${o.addressLine1 ? ', ' + o.addressLine1.trim() : ''}`,
			placeholder: "Search insurance",
		},
		{
			title: "Assistants",
			url: "/reference/assistants",
			param: "assistant",
			mapFunc: mapOptions,
			labelFunc: labelAssistantsFunc,
			placeholder: "Search assistant",
		},
	];

	return (
    <div className="col-1-1 flex center-left filters">
      {profile.type === "admin" && (
        <div>
          <Label variant="filter" title="Department" />
          <PaginatedDropdown
            datakey="departments"
            url={`${API_URL}/api/1.0.0/department?`}
            selectedValue={
              queryParams.get("department")
                ? JSON.parse(queryParams.get("department"))
                : null
            }
            onChange={(v) =>
              updateQueryParam("department", v ? JSON.stringify(v) : null)
            }
            mapOptions={(records) =>
              mapOptions(records, "id", (o) => `${o.name}`)
            }
            placeHolder="Search department"
          />
        </div>
      )}
      <div>
        <Label variant="filter" title="Bill Status" />
        <CustomSelect
          options={[ { value: "not_set", label: "Not Set" },...BILL_STATUS_OPTTIONS]}
          placeHolder={"Select Bill Status"}
          selectedValue={
            BILL_STATUS_OPTTIONS.find(
              (o) => o.value === queryParams.get("billStatus")
            ) || null
          } 
          onChange={(selectedOption) => {
            const newValue = selectedOption ? selectedOption.value : "";
            updateQueryParam("billStatus", newValue);
          }}
        />
      </div>
      {DROPDOWNS.map(
        ({ title, url, param, mapFunc, labelFunc, placeholder }) => (
          <div key={param}>
            <Label variant="filter" title={title} />
            <PaginatedDropdown
              url={`${API_URL}/api/1.0.0${url}?`}
              selectedValue={
                queryParams.get(param)
                  ? JSON.parse(queryParams.get(param))
                  : null
              }
              onChange={(v) =>
                updateQueryParam(param, v ? JSON.stringify(v) : null)
              }
              mapOptions={(records) => mapFunc(records, "id", labelFunc)}
              placeHolder={placeholder}
            />
          </div>
        )
      )}
      <div>
        <Label variant="filter" title="Hospital Contract" />
        <CustomSelect
          options={HOSPITAL_CONTRACT_DROP_DOWN_OPTIONS}
          placeHolder={"Select Hospital Contract"}
          selectedValue={
            HOSPITAL_CONTRACT_DROP_DOWN_OPTIONS.find(
              (o) => o.value === queryParams.get("hospitalContract")
            ) || null
          } 
          onChange={(selectedOption) => {
            const newValue = selectedOption ? selectedOption.value : "";
            updateQueryParam("hospitalContract", newValue);
          }}
        />
      </div>
      <div>
        <Label variant="filter" title="Claim Status" />
        <CustomSelect
          options={CLAIM_STATUS_DROP_DOWN_OPTIONS}
          placeHolder={"Select Claim Status"}
          selectedValue={
            CLAIM_STATUS_DROP_DOWN_OPTIONS.find(
              (o) => o.value === queryParams.get("claimStatus")
            ) || null
          } 
          onChange={(selectedOption) => {
            const newValue = selectedOption ? selectedOption.value : "";
            updateQueryParam("claimStatus", newValue);
          }}
        />
      </div>
      <div>
        <Label variant="filter" title="Patient Chart No." />
        <input
          className="primary"
          type="text"
          value={patientChartNo}
          placeholder="Eg: 1234"
          onChange={(e) => handlePatientChartNoChange(e.target.value)}
        />
      </div>
      <div>
				<Label variant="filter" title='Date Filter' />
				<DateRangeInput
				    initialRange={{
							startDate: queryParams.get("startDate") || null , 
							endDate : queryParams.get("endDate") || null 
						}} 
					onApply={(v) => { 
							updateQueryParam("startDate", v?.startDate || null); 
							updateQueryParam("endDate", v?.endDate || null); 
						}} 
					onCancel={(v) => { 
						updateQueryParam("startDate", null); 
						updateQueryParam("endDate", null); 
					}}  
					/>
			</div> 
      <div className='flex center-left' style={{ gap: "12px" }}>
        <div>
          <Label variant="filter" title="Reset" />
          <ButtonLightTextIcon
            title="Reset"
            className="light"
            icon={
            <GrPowerReset />
            }
            onClick={() => {
              resetFilters();
              setPatientChartNo("");
            }}
          />
        </div>
        <div className='mar-l-a'>
            <Label variant="filter" title='Per page' />
            <ResultsPerPage />
        </div>
      </div>
    </div>
  );
};

export default TableFilter;
