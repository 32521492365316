import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import { API_URL } from "../../utilities/constants";
import PaginatedDropdown, {
  mapOptions,
} from "../../components/ui/PaginatedDropdown";
import LoaderIcon from "../../components/ui/LoaderIcon";
import PopUpModal from "../../components/common/PopUpModal";
import DatePickerInput from "../../components/ui/DatePickerInput";
import PhoneNumberInput from "../../components/ui/PhoneNumberInput";
import { handleApiCall } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import CustomModal from "../../components/common/CustomModal";
import InsuranceSelect from "../patient/InsuranceSelect";
import { TbArrowBackUp } from "react-icons/tb";
import { RxCrossCircled } from "react-icons/rx";
import { MdCancel } from "react-icons/md";
import { FiCheckCircle, FiPlusCircle } from "react-icons/fi";

const AddPatientForm = ({ callback }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const { addToastMessage } = useUI();

  const initialValues = {
    firstName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    email: "",
    phoneNumber: "",
    primaryInsuranceId: null,
    secondaryInsuranceId: null,
    tertiaryInsuranceId: null,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "First Name must be at least 2 characters long")
      .required("First Name is required"),
    lastName: Yup.string()
      .min(2, "Last Name must be at least 2 characters long")
      .required("Last Name is required"),
    gender: Yup.string().required("Gender is required"),
    dateOfBirth: Yup.date().required("DOB is required"),
    email: Yup.string()
      .email("Please enter a valid email address") // Validates only if a value is entered
      .nullable(),
    // phoneNumber: Yup.string()
    // .matches(/^\d+$/, "Phone Number must contain only numbers")
    // .max(15, "Phone Number must be at most 15 digits") // Ensures exactly 15 digits
    //     .required("Phone Number is required"),
    primaryInsuranceId: Yup.object()
      .nullable()
      .test(
        "unique-ids",
        "Primary Insurance ID must be unique",
        function (value) {
          const {
            primaryInsuranceId,
            secondaryInsuranceId,
            tertiaryInsuranceId,
          } = this.parent;

          // Extract the current field's value and compare with others
          const primaryId = value?.value; // Current value of primaryInsuranceId
          const secondaryId = secondaryInsuranceId?.value; // value of secondaryInsuranceId
          const tertiaryId = tertiaryInsuranceId?.value; // value of tertiaryInsuranceId

          // Only check if Primary ID has conflicts
          if (
            primaryId &&
            (primaryId === secondaryId || primaryId === tertiaryId)
          ) {
            return this.createError({ message: "Insurances must be unique" });
          }

          return true;
        }
      ),

    secondaryInsuranceId: Yup.object()
      .nullable()
      .test(
        "unique-ids",
        "Secondary Insurance ID must be unique",
        function (value) {
          const {
            primaryInsuranceId,
            secondaryInsuranceId,
            tertiaryInsuranceId,
          } = this.parent;

          // Extract the current field's value and compare with others
          const primaryId = primaryInsuranceId?.value; // value of primaryInsuranceId
          const secondaryId = value?.value; // Current value of secondaryInsuranceId
          const tertiaryId = tertiaryInsuranceId?.value; // value of tertiaryInsuranceId

          // Only check if Secondary ID has conflicts
          if (
            secondaryId &&
            (secondaryId === primaryId || secondaryId === tertiaryId)
          ) {
            return this.createError({ message: "Insurances must be unique" });
          }

          return true;
        }
      ),

    tertiaryInsuranceId: Yup.object()
      .nullable()
      .test(
        "unique-ids",
        "Tertiary Insurance ID must be unique",
        function (value) {
          const {
            primaryInsuranceId,
            secondaryInsuranceId,
            tertiaryInsuranceId,
          } = this.parent;

          // Extract the current field's value and compare with others
          const primaryId = primaryInsuranceId?.value; // value of primaryInsuranceId
          const secondaryId = secondaryInsuranceId?.value; // value of secondaryInsuranceId
          const tertiaryId = value?.value; // Current value of tertiaryInsuranceId

          // Only check if Tertiary ID has conflicts
          if (
            tertiaryId &&
            (tertiaryId === primaryId || tertiaryId === secondaryId)
          ) {
            return this.createError({ message: "Insurances must be unique" });
          }

          return true;
        }
      ),
  });

  const labelInsuranceFunc = (o) => `${o.description}`;

  const checkPatientExists = async (name, dob) => {
    try {
      const resp = await handleApiCall(
        `${API_URL}/api/1.0.0/reference/patients?dob=${dob}&search=${name}`,
        {
          method: "GET",
        }
      );
      if (resp.responseStatus === true) {
        if (resp.data.length > 0) {
          return true;
        }
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
    }
  };

  return (
    <>
      <p
        onClick={() => {
          setShow(true);
        }}
        className="cursor-pointer"
        style={{
          paddingTop: "4px",
          paddingBottom: "10px",
          color: "#8f8f8f",
          fontSize: "10px",
        }}
      >
        Could't find the patient?{" "}
        <span className="color-secondary hover-color-primary hover-t-d-ul transition">
          Click to add
        </span>
      </p>
      {show && (
        <div
          style={{
            border: "solid 1px #5dc6b3",
            backgroundColor: "#ecf0f1",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values) => {
              setLoading(true);
              setError("");
              try {
                const payload = {
                  userId: 1,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  gender: values.gender,
                  dateOfBirth: values.dateOfBirth,
                  email: values.email,
                  phoneNumber: values?.phoneNumber || null,
                  primaryInsuranceId: values?.primaryInsuranceId?.value || null,
                  secondaryInsuranceId:
                    values?.secondaryInsuranceId?.value || null,
                  tertiaryInsuranceId:
                    values?.tertiaryInsuranceId?.value || null,
                };
                const response = await handleApiCall(
                  API_URL + `/api/1.0.0/patient`,
                  {
                    method: "POST",
                    body: payload,
                  }
                );
                if (response.responseStatus === true) {
                  callback(response?.data);
                  setShow(false);
                  addToastMessage({
                    status: true,
                    message: `You have successfully created an patient, thank you.`,
                  });
                } else {
                  setError(
                    response.responseDescription ||
                      "Something went wrong, please try again later."
                  );
                }
              } catch (e) {
                setError("Something went wrong, please try again later.");
              } finally {
                setLoading(false);
              }
            }}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleSubmit,
              resetForm,
              validateForm,
              setFieldTouched,
            }) => (
              <Form>
                <div style={{ width: "100%", paddingBottom: "10px" }}>
                  <Label title="First Name" required={true} />
                  <Field
                    type="text"
                    placeholder="e.g., John"
                    name="firstName"
                    className={`col-1-1 primary ${
                      errors.firstName && touched.firstName ? "error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="firstName"
                    component="p"
                    className="error-messages"
                  />
                </div>
                <div style={{ width: "100%", paddingBottom: "10px" }}>
                  <Label title="Last Name" required={true} />
                  <Field
                    type="text"
                    placeholder="e.g., Doe "
                    name="lastName"
                    className={`col-1-1 primary ${
                      errors.lastName && touched.lastName ? "error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="lastName"
                    component="p"
                    className="error-messages"
                  />
                </div>
                <div style={{ width: "100%", paddingBottom: "10px" }}>
                  <Label title="Gender" required={true} />
                  <Field
                    as="select"
                    placeholder="Select Gender"
                    name="gender"
                    className={`col-1-1 primary ${
                      errors.gender && touched.gender ? "error" : ""
                    }`}
                  >
                    <option value="">Select Gender</option>
                    {["Male", "Female", "Other"].map((d, i) => (
                      <option value={d} key={i}>
                        {" "}
                        {d}
                      </option>
                    ))}
                  </Field>
                </div>
                <div style={{ width: "100%", paddingBottom: "10px" }}>
                  <Label title="DOB (DD/MM/YYYY)" required={true} />
                  <DatePickerInput
                    callback={(v) => {
                      setFieldValue("dateOfBirth", v);
                    }}
                    value={values.dateOfBirth}
                    error={errors.dateOfBirth && touched.dateOfBirth}
                    setTouched={() => setFieldTouched("dateOfBirth", true)}
                  />
                </div>
                <div style={{ width: "100%", paddingBottom: "10px" }}>
                  <Label title="Email Address" />
                  <Field
                    type="text"
                    placeholder="e.g., example@domain.com"
                    name="email"
                    className={`col-1-1 primary ${
                      errors.email && touched.email ? "error" : ""
                    }`}
                  />
                </div>
                <div style={{ width: "100%", paddingBottom: "10px" }}>
                  <Label title="Phone Number" />
                  <PhoneNumberInput
                    name="phoneNumber"
                    placeholder="Eg: 888-888-8888"
                    error={errors.phoneNumber && touched.phoneNumber}
                    value={values.phoneNumber}
                    setTouched={() => setFieldTouched("phoneNumber", true)}
                    callback={(v) => setFieldValue("phoneNumber", v)}
                  />
                </div>

                <div style={{ width: "100%", paddingBottom: "10px" }}>
                  <Label title="Primary Insurance Name" />
                  <CustomModal
                    selector={
                      <div>
                        <Field
                          type="text"
                          placeholder="Select Insurance"
                          name="primaryInsuranceName"
                          value={values.primaryInsuranceName}
                          className={`col-1-1 primary cursor-pointer ${
                            errors.primaryInsuranceId &&
                            touched.primaryInsuranceId
                              ? "error"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="primaryInsuranceId"
                          component="p"
                          className="error-messages"
                        />
                      </div>
                    }
                    content={
                      <InsuranceSelect
                        setTouched={async () => {
                          await setFieldTouched("primaryInsuranceName", true);
                        }}
                        callback={async (v) => {
                          if (v) {
                            await setFieldValue("primaryInsuranceId", v);
                            await setFieldValue(
                              "primaryInsuranceName",
                              v.label
                            );
                          } else {
                            await setFieldValue("primaryInsuranceId", "");
                            await setFieldValue("primaryInsuranceName", "");
                          }
                        }}
                      />
                    }
                  />
                </div>

                <div style={{ width: "100%", paddingBottom: "10px" }}>
                  <Label title="Secondary Insurance Name" />
                  <CustomModal
                    selector={
                      <div>
                        <Field
                          type="text"
                          placeholder="Select Insurance"
                          name="secondaryInsuranceName"
                          value={values.secondaryInsuranceName}
                          className={`col-1-1 primary cursor-pointer ${
                            errors.secondaryInsuranceId &&
                            touched.secondaryInsuranceId
                              ? "error"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="secondaryInsuranceId"
                          component="p"
                          className="error-messages"
                        />
                      </div>
                    }
                    content={
                      <InsuranceSelect
                        setTouched={async () => {
                          await setFieldTouched("secondaryInsuranceName", true);
                        }}
                        callback={async (v) => {
                          if (v) {
                            await setFieldValue("secondaryInsuranceId", v);
                            await setFieldValue(
                              "secondaryInsuranceName",
                              v.label
                            );
                          } else {
                            await setFieldValue("secondaryInsuranceId", "");
                            await setFieldValue("secondaryInsuranceName", "");
                          }
                        }}
                      />
                    }
                  />
                </div>

                <div style={{ width: "100%", paddingBottom: "10px" }}>
                  <Label title="Tertiary Insurance Name" />
                  <CustomModal
                    selector={
                      <div>
                        <Field
                          type="text"
                          placeholder="Select Insurance"
                          name="tertiaryInsuranceName"
                          value={values.tertiaryInsuranceName}
                          className={`col-1-1 primary cursor-pointer ${
                            errors.tertiaryInsuranceId &&
                            touched.tertiaryInsuranceId
                              ? "error"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="tertiaryInsuranceId"
                          component="p"
                          className="error-messages"
                        />
                      </div>
                    }
                    content={
                      <InsuranceSelect
                        setTouched={async () => {
                          await setFieldTouched("tertiaryInsuranceName", true);
                        }}
                        callback={async (v) => {
                          if (v) {
                            await setFieldValue("tertiaryInsuranceId", v);
                            await setFieldValue(
                              "tertiaryInsuranceName",
                              v.label
                            );
                          } else {
                            await setFieldValue("tertiaryInsuranceId", "");
                            await setFieldValue("tertiaryInsuranceName", "");
                          }
                        }}
                      />
                    }
                  />
                </div>

                <div
                  className="flex center-left "
                  style={{ gap: "5px", paddingBlock: "12px" }}
                >
                  {loading && (
                    <>
                      <LoaderIcon
                        style={{
                          width: "10px",
                          height: "10px",
                        }}
                      />
                      <p
                        className="color-primary f-w-300"
                        style={{ fontSize: "12px" }}
                      >
                        Saving patient's details, please wait...
                      </p>
                    </>
                  )}
                  {error && (
                    <>
                      <RxCrossCircled color="red" />
                      <p
                        className=" f-w-300"
                        style={{ fontSize: "12px", color: "red" }}
                      >
                        {error}
                      </p>
                    </>
                  )}
                </div>

                <div
                  className="flex center-center"
                  style={{ width: "100%", gap: "10px", paddingBottom: "10px" }}
                >
                  <ButtonLightTextIcon
                    icon={<MdCancel size={20} />}
                    title="Close"
                    style={{ width: "100%" }}
                    type="button"
                    onClick={() => {
                      setShow(false);
                      resetForm();
                    }}
                    disabled={loading}
                  />
                  <ButtonTextIcon
                    title="Add"
                    style={{ width: "100%" }}
                    icon={loading ? <LoaderIcon /> : <FiPlusCircle />}
                    type="button"
                    disabled={loading}
                    onClick={async () => {
                      const errors = await validateForm();
                      if (Object.keys(errors).length === 0) {
                        setLoading(true);
                        const check = await checkPatientExists(
                          `${values.firstName} ${values.lastName}`,
                          values.dateOfBirth
                        );

                        if (check) {
                          setConfirmation(true);
                        } else {
                          handleSubmit();
                        }
                      } else {
                        handleSubmit();
                      }
                    }}
                  />
                </div>
                {confirmation && (
                  <PopUpModal>
                    <section
                      style={{
                        position: "fixed",
                        inset: 0,
                        zIndex: 9999,
                        backgroundColor: "rgba(255, 255, 255, 0.86)",
                      }}
                    >
                      <section
                        className="col-1-1 flex center-center"
                        style={{
                          height: "100vh",
                          width: "100vw",
                        }}
                      >
                        <div
                          style={{
                            width: "486px",
                            padding: "24px",
                            height: "auto",
                            maxHeight: "80vh",
                            border: "solid 1px #f0f0f0",
                            background: "white",
                            borderRadius: "8px",
                            margin: "0 auto",
                            boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
                          }}
                        >
                          <div
                            className="col-1-1 flex center-left"
                            style={{
                              borderBottom: "solid 1px #f0f0f0",
                              paddingBottom: "12px",
                              marginBottom: "16px",
                            }}
                          >
                            <h6
                              className="color-primary f-w-300 opl-tooltip"
                              data-tooltip="View entire history of the activity below."
                            >
                              Confirmation
                            </h6>
                            <div
                              className="mar-l-a cursor-pointer opl-tooltip"
                              data-tooltip="Close to return to the previous section."
                              onClick={() => {
                                setConfirmation(false);
                                setLoading(false);
                              }}
                            >
                              <MdCancel size={20} />
                            </div>
                          </div>
                          <div>
                            <section>
                              <p>
                                Patient with same name and date of birth found
                                in the system, do you still want to continue?
                              </p>
                            </section>
                          </div>
                          <div
                            className="col-1-1 flex center-left"
                            style={{
                              marginTop: "16px",
                              borderTop: "solid 1px #f0f0f0",
                              paddingTop: "16px",
                            }}
                          >
                            <ButtonLightTextIcon
                              title="Cancel"
                              onClick={() => {
                                setConfirmation(false);
                                setLoading(false);
                              }}
                              icon={<TbArrowBackUp />}
                            />

                            <div style={{ marginLeft: "auto" }}></div>
                            <ButtonTextIcon
                              title="Confirm"
                              onClick={() => {
                                handleSubmit();
                                setConfirmation(false);
                              }}
                              icon={<FiCheckCircle />}
                            />
                          </div>
                        </div>
                      </section>
                    </section>
                  </PopUpModal>
                )}
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default AddPatientForm;
