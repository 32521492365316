import { NavLink, useLocation } from "react-router-dom";
import LogoImage from "../../images/icons/logo.png";
import { useEffect, useState } from "react";
import {
	APP_VERSION,
	MENU_LIST,
	PBFUSA_EMAIL_ARRAY,
	PBFUSA_MENU_LIST,
} from "../../utilities/constants";
import ConfirmationModal from "../ui/ConfirmationModal";
import { decodeModuleAccessFromToken } from "../../utilities/helpers";
import CustomModal from "../common/CustomModal";
import { useUI } from "../../context/ui";
import {
	TbBulb,
	TbEyeShare,
	TbLogout,
	TbMenu4,
	TbMenuDeep,
	TbUser,
	TbUserCircle,
} from "react-icons/tb";
import { IoIosArrowDown } from "react-icons/io";

const ProfileMenu = () => {
	const [show, setShow] = useState(false);
	return (
		<li className='cursor-pointer'>
			<TbUserCircle
				onClick={() => setShow(!show)}
				className='color-primary width-auto dis-b'
				style={{
					height: "28px",
				}}
			/>
			{show && (
				<ul>
					<li className='cursor-pointer transition'>
						<NavLink
							to='/profile'
							onClick={() => {
								setShow(false);
							}}>
							<div
								className='col-1-1 flex center-left'
								style={{ gap: "8px" }}>
								<TbEyeShare
									className='color-primary width-auto dis-b'
									style={{
										height: "18px",
									}}
								/>
								View Profile
							</div>
						</NavLink>
					</li>
					<CustomModal
						selector={
							<li className='cursor-pointer transition'>
								<div
									className='col-1-1 flex center-left'
									style={{ gap: "8px" }}>
									<TbLogout
										className='color-primary width-auto dis-b'
										style={{
											height: "18px",
										}}
									/>
									Log out
								</div>
							</li>
						}
						content={
							<ConfirmationModal
								onConfirm={async () => {
									localStorage.clear();
									window.location.href = "/login";
								}}
								message='Are you sure you want to log out of CoreMax? Once logged out, you will need to log in again.'
							/>
						}
					/>
				</ul>
			)}
		</li>
	);
};

const Single = ({ active, title, href, icon }) => {
	return (
		<li className={`${active === href ? "active-page" : ""}`}>
			<NavLink className='transition' to={`/${href}`}>
				<span className='flex center-left'>
					{icon ? icon : <TbMenu4 />}
					{title}
				</span>
			</NavLink>
		</li>
	);
};

const WithSubMenu = ({ nestedMenu, subMenuHead, setMenuOpen, icon }) => {
	const location = useLocation();
	const [show, setShow] = useState(false);

	useEffect(() => {
		function getEndStringFromPath(path) {
			const pathSegments = path.split("/").filter(Boolean);
			return pathSegments.pop() || "";
		}
		function isSubMenuUnderMenu(menuList, menuTitle, subMenuSlug) {
			const menu = [
				...menuList,
				{
					title: "PBFUSA",
					slug: "",
					icon: null,
					sub_menu: [
						{
							title: "Patient Registrations",
							slug: "patient-registrations",
							icon: null,
							sub_menu: [],
						},
						{
							title: "Volunteer Registrations",
							slug: "volunteer-registrations",
							icon: null,
							sub_menu: [],
						},
					],
				},
			].find((item) => item.title === menuTitle);
			if (!menu) return false;
			return menu.sub_menu.some(
				(subItem) => subItem.slug === subMenuSlug
			);
		}
		const slug = getEndStringFromPath(location.pathname);
		const s = isSubMenuUnderMenu(MENU_LIST, subMenuHead, slug);
		setShow(s);
	}, [location.pathname, subMenuHead]);

	return (
		<li className='cursor-pointer' onClick={() => setShow(!show)}>
			<a className='transition'>
				<span className='flex center-left'>
					{icon ? icon : <TbMenuDeep />}
					{subMenuHead}
					<IoIosArrowDown
						className={`transition width-auto dis-b mar-l-a ${show ? "arr-open" : ""}`}
						style={{ height: "14px" }}
					/>
				</span>
			</a>
			{show && (
				<ul>
					{nestedMenu?.map((menu, index) => {
						return (
							<li key={index}>
								<NavLink
									className='transition'
									to={{
										pathname: `/${menu.slug}`,
										state: {
											activePage: `/${menu.slug}`,
											toggleMenu: true,
											subMenuHead,
										},
									}}
									onClick={(e) => {
										e.stopPropagation();
										setMenuOpen(false);
									}}>
									{menu.title}
								</NavLink>
							</li>
						);
					})}
				</ul>
			)}
		</li>
	);
};

const Header = () => {
	const location = useLocation();
	const { profile } = useUI();
	const [menuOpen, setMenuOpen] = useState(false);
	const permissions = decodeModuleAccessFromToken("knowledge-base");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	useEffect(() => {
		setMenuOpen(false);
	}, [location.pathname]);

	const renderNestedMenu = (
		setMenuOpen,
		nestedMenu,
		subMenuHead,
		index,
		icon
	) => {
		return (
			<WithSubMenu
				setMenuOpen={setMenuOpen}
				key={index}
				nestedMenu={nestedMenu}
				subMenuHead={subMenuHead}
				icon={icon}
			/>
		);
	};

	const renderMenu = (m) => {
		if (m.sub_menu && m.sub_menu.length) {
			return (
				<>
					{renderNestedMenu(
						setMenuOpen,
						m.sub_menu,
						m.title,
						m.index,
						m.icon
					)}
				</>
			);
		}
		return (
			<Single
				key={m.index}
				active='/'
				href={m.slug}
				title={m.title}
				icon={m.icon}
			/>
		);
	};

	useEffect(() => {
		const handleMouseMove = (e) => {
			if (e.clientX <= 10) {
				setTimeout(() => setMenuOpen(true), 100);
			}
		};
		window.addEventListener("mousemove", handleMouseMove);
		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
		};
	}, []);

	return (
		<div
			className={`${menuOpen ? "sidebar-overlay" : ""}`}
			onClick={() => setMenuOpen(!menuOpen)}>
			<header
				className={`${menuOpen ? "menu-open" : ""}`}
				onClick={(e) => e.stopPropagation()}>
				<section className='top'>
					<div
						className={`menu-btn flex center-center ${
							menuOpen ? "menu-btn-open" : ""
						}`}
						onClick={() => setMenuOpen(!menuOpen)}>
						<div>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					<div
						className='logo flex center-center'
						style={{
							gap: "12px",
						}}>
						<img src={LogoImage} />
						<div className='flex center-left'>
							<div>
								<p
									className='l-h-1 color-primary'
									style={{
										fontSize: "13px",
									}}>
									CoreMax Cloud
								</p>
								<p
									className='l-h-1'
									style={{
										fontSize: "8px",
										color: "rgb(143, 143, 143)",
									}}>
									Version v{APP_VERSION}
								</p>
							</div>
						</div>
					</div>
					<div className='right'>
						<div className='user'>
							<div className='t-a-r'>
								<p className='l-h-1'>
									<span
										style={{
											color: "#8f8f8f",
										}}>
										Hi,
									</span>{" "}
									<span className='color-primary'>
										{profile?.fullName || "Loading..."}
									</span>
								</p>
								<p
									className='l-h-1 t-t-u'
									style={{
										fontSize: "12px",
										color: "#8F8F8F",
									}}>
									{profile?.type || "Type not available"}
								</p>
							</div>
						</div>
						<ul className='actions flex center-center'>
							{permissions?.isReadAllowed && (
								<li className='cursor-pointer'>
									<NavLink
										to='/knowledge-base'
										style={{
											borderRight: 0,
											padding: "auto",
										}}>
										<TbBulb
											className='color-primary width-auto dis-b'
											style={{
												height: "28px",
											}}
										/>
									</NavLink>
								</li>
							)}
							<ProfileMenu />
						</ul>
					</div>
				</section>
			</header>
			<nav
				className={`${menuOpen ? "menu-open" : ""}`}
				onClick={(e) => e.stopPropagation()}
				onMouseEnter={() => setTimeout(() => setMenuOpen(true), 100)}
				onMouseLeave={() => setTimeout(() => setMenuOpen(false), 100)}>
				<ul>
					{MENU_LIST.map((menu, index) =>
						renderMenu({ ...menu, index })
					)}
					{PBFUSA_EMAIL_ARRAY.includes(profile?.email) &&
						PBFUSA_MENU_LIST.map((menu, index) =>
							renderMenu({ ...menu, index })
						)}
					<li className='cursor-pointer transition'>
						<NavLink className='transition' to='/profile'>
							<span className='flex center-left'>
								<TbUser />
								View Profile
							</span>
						</NavLink>
					</li>
					<CustomModal
						selector={
							<li className='cursor-pointer transition'>
								<a className='transition'>
									<span
										className='flex center-left'
										style={{ gap: "8px" }}>
										<TbLogout />
										Log out
									</span>
								</a>
							</li>
						}
						content={
							<ConfirmationModal
								onConfirm={async () => {
									if (localStorage.clear() === undefined) {
										window.location.href = "/login";
									}
								}}
								message='Are you sure you want to log out of CoreMax? Once logged out, you will need to log in again.'
							/>
						}
					/>
				</ul>
			</nav>
		</div>
	);
};

export default Header;
