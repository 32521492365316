import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import LoaderIcon from "../components/ui/LoaderIcon";
import Header from "../components/layout/Header";
import Pagination from "../components/common/Pagination";
import { useUI } from "../context/ui";
import TableFilter from "./npi/TableFilter";
import { handleApiCall, jsonToQueryParams } from "../utilities/helpers";
import useQueryParams from "../hooks/useQueryParams";
import { useLocation, useNavigate } from "react-router-dom";
import PageToolbar from "../components/common/PageToolbar";
const NPIPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const search = queryParams.get("search") || "";
  const [controller, setController] = useState(null);
  const { addToastMessage } = useUI();
  const navigate = useNavigate();
  const resetFilters = () => {
    updateQueryParam("pageNo", 0);
    updateQueryParam("search", "");
  };

  const getNPIApi = async () => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    setLoading(true);
    try {
      const query = jsonToQueryParams({
        search: search,
      });
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/reference/npi-list?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.log("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNPIApi();
  }, [location.search]);

  const getEditPage = (type) => {
    switch (type?.toLowerCase()) {
      case "facility":
        return "/edit-facility";
      case "surgeon":
        return "/edit-surgeon";
      case "physician assistant":
        return "/edit-physician-assistant";
      case "surgical assistant":
        return "/edit-assistant";
      default:
        return "/npi"; 
    }
  }

  return (
    <section id="category">
      <PageToolbar title="NPI" />
      <TableFilter resetFilters={resetFilters} />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          className="flex center-center"
          style={{ height: "100vh", backgroundColor: "#fafaff" }}
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.list.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>NPI</th>
                <th>Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {data?.list.map((item, index) => (
                <tr
                  key={item.id}
                  onClick={() => {
                    if (item?.id) {
                      if (item?.status === "Archived") {
                        addToastMessage({
                          status: false,
                          message:
                            "This User is archived and cannot be edited.",
                        });
                        return;
                      }
                      navigate(
                        `${getEditPage(item.type)}?next=${encodeURIComponent(window.location.pathname + window.location.search)}`,
                        { state: { id: item?.id } }
                      );
                    }
                  }}
                >
                  <td>{pageNo * pageSize + index + 1}</td>
                  <td>{item?.npi || "NA"}</td>
                  <td>{item?.name || "NA"}</td>
                  <td>{item?.email || "NA"}</td>
                  <td>{item?.status || "NA"}</td>
                  <td>{item?.type || "NA"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "24px 15px 32px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default NPIPage;
