import { Formik, Form } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL } from "../../utilities/constants";
import PaginatedDropdown, {
	mapOptions,
} from "../../components/ui/PaginatedDropdown";
import {
	getUserTypeInfo,
	handleApiCall,
	labelAssistantsFunc,
} from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import { useState } from "react";
import { useUI } from "../../context/ui";
import { useLocation, useNavigate } from "react-router-dom";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";

const AddForm = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const { addToastMessage } = useUI();

	const initialValues = {
		assistants: "",
		surgeon: "",
	};

	const validationSchema = Yup.object({
		assistants: Yup.string().required("Assistant is required"),
		surgeon: Yup.string().required("Surgeon is required"),
	});

	const createSurgeonAssociation = async (values) => {
		setLoading(true);
		setStatus("");
		try {
			const payload = {
				surgeonId: values.surgeon,
				userId: values.assistants,
			};

			const response = await handleApiCall(
				`${API_URL}/api/1.0.0/surgeon-association`,
				{
					method: "POST",
					body: payload,
				}
			);

			if (response.responseStatus == true) {
				setStatus(
					"You have successfully created a Surgeon Association. Thank you."
				);
				addToastMessage({
					status: true,
					message:
						"You have successfully created a Surgeon Association.",
				});
				navigate("/surgeon-association");
				return true;
			} else {
				addToastMessage({
					status: false,
					message: `Something went wrong, please try again later.`,
				});
				return false;
			}
		} catch (error) {
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: "Something went wrong, please try again later.",
			});
			console.log("Error:", error);

			return false;
		}
	};

	const labelsurgeonFunc = (o) =>
		`${o?.firstName} ${o?.middleName} ${o?.lastName}`;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={async (values, { resetForm }) => {
				const success = await createSurgeonAssociation(values);
				if (success) {
					resetForm();
				}
				setLoading(false);
			}}>
			{({
				setFieldValue,
				errors,
				values,
				touched,
				validateForm,
				handleSubmit,
				setFieldTouched,
			}) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td width='50%'>
										<Label title='Assistant' required />
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/assistants?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelAssistantsFunc
												)
											}
											placeHolder='Search'
											onChange={(v) => {
												setFieldValue(
													"assistants",
													v?.value
												);
											}}
											error={
												errors.assistants &&
												touched.assistants
											}
											setTouched={() =>
												setFieldTouched(
													"assistants",
													true
												)
											}
										/>
									</td>
									<td>
										<Label title='Surgeon' required />
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/surgeon?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelsurgeonFunc
												)
											}
											placeHolder='Search'
											onChange={(v) => {
												setFieldValue(
													"surgeon",
													v?.value
												);
											}}
											error={
												errors.surgeon &&
												touched.surgeon
											}
											setTouched={() =>
												setFieldTouched("surgeon", true)
											}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => navigate("/surgeon-association")}
							icon={
								<TbArrowBackUp />
							}
						/>
						<ButtonTextIcon
							type='submit'
							disabled={loading}
							title='Create Now'
							onClick={async () => {
								const errors = await validateForm();
								if (Object.keys(errors).length > 0) {
									const firstErrorMessage =
										Object.values(errors)[0];
									addToastMessage({
										status: false,
										message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
									});
								}
								handleSubmit();
							}}
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<FiArrowRightCircle />
								)
							}
						/>
					</div>
					{status && (
						<>
							<br />
							<p className='color-primary f-w-700'>{status}</p>
						</>
					)}
				</Form>
			)}
		</Formik>
	);
};
export default AddForm;
