import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import {
  formatInputPhoneNumber,
  handleApiCall,
  jsonToQueryParams,
} from "../utilities/helpers";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import TableFilter from "./contract/TableFilter";
import ContractDetails from "./contract/ContractDetails";
import useQueryParams from "../hooks/useQueryParams";
import PageToolbar from "../components/common/PageToolbar";
import { FiPlusCircle } from "react-icons/fi";

const ContractPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageNo = queryParams.get("pageNo") || 0;
  const pageSize = queryParams.get("pageSize") || 12;
  const { addToastMessage, permissions } = useUI();
  const [controller, setController] = useState(null);

  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("pageNo", 0);
  };
  const callListApi = async () => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    try {
      setLoading(true);
      const query = jsonToQueryParams({
        search: queryParams.get("search") || "",
      });
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/contract?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus == true) {
        setData(response.data);
      } else {
        setData(null);
      }

      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    callListApi();
  }, [location.search]);

  const handleDelete = async (id) => {
    try {
      if (!id) {
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/contract/${id}`,
        {
          method: "DELETE",
        }
      );

      if (response.responseStatus == true) {
        callListApi();
        addToastMessage({
          status: true,
          message: `Contract deleted successfully.`,
        });
        return true;
      }
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting procedure. Please try again.`,
      });
      return false;
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting procedure. Please try again.`,
      });
      return false;
    }
  };
  return (
    <section id="activity-v2">
      <PageToolbar
        title="Contracts"
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => navigate("/add-contracts")}
              icon={<FiPlusCircle />}
            />
          )
        }
      />
      <TableFilter resetFilters={resetFilters} />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.records.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Facility</th>
                <th>Contract Type</th>
                <th>Contact Person Name</th>
                <th>Contact Person Phone</th>
                <th>Contact Person Email</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.records.map((data, index) => (
                  <tr key={index}>
                    <td>{pageNo * pageSize + index + 1}</td>
                    <td>{data.facility?.facilityName}</td>
                    <td>{data?.contractType?.typeName || "-"}</td>
                    <td>
                      {data?.contactPersonDetails?.length > 0 &&
                      data.contactPersonDetails[0]?.contactPersonName?.trim()
                        ? data.contactPersonDetails[0].contactPersonName
                        : "-"}
                    </td>
                    <td>
                      {data?.contactPersonDetails?.length > 0 &&
                      data.contactPersonDetails[0]?.contactPersonPhone?.trim()
                        ? formatInputPhoneNumber(
                            data.contactPersonDetails[0].contactPersonPhone
                          )
                        : "-"}
                    </td>
                    <td>
                      {data?.contactPersonDetails?.length > 0 &&
                      data.contactPersonDetails[0]?.contactPersonEmail?.trim()
                        ? data.contactPersonDetails[0].contactPersonEmail
                        : "-"}
                    </td>
                    <td>
                      <div
                        className="flex center-center"
                        style={{ gap: "12px" }}
                      >
                        {permissions?.isReadAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="view"
                                  title="View"
                                />
                              }
                              content={<ContractDetails id={data?.id} />}
                            />
                          </div>
                        )}
                        {permissions?.isUpdateAllowed && (
                          <div>
                            <NavLink
                              to={`/edit-contracts?next=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                              state={{ id: data?.id }}
                            >
                              <ButtonTertiary actionType="edit" title="Edit" />
                            </NavLink>
                          </div>
                        )}
                        {permissions?.isDeleteAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="delete"
                                  title="Delete"
                                />
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    await handleDelete(data.id);
                                    return true;
                                  }}
                                  message="Are you sure you want to delete this contract ?"
                                />
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default ContractPage;
