import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import UpdateForm from "./surgical-assistant/UpdateForm";
import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import LoaderIcon from "../components/ui/LoaderIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { handleApiCall } from "../utilities/helpers";
import PageToolbar from "../components/common/PageToolbar";
import useQueryParams from "../hooks/useQueryParams";
import { TbArrowBackUp } from "react-icons/tb";

const UpdateSurgicalAssistant = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false); // State to show loading status
  const [error, setError] = useState(null);

  const {queryParams,updateQueryParam } =useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";
  const fetchData = async (id) => {
    try {
      setLoading(true);
      setError(null);
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/surgical-assistant/${id}`,
        {
          method: "GET",
        }
      );
      if (response) {
        setData(response.data);
        setLoading(false);
      } else {
        setData(null);
        setError("Failed to fetch group data.");
        setLoading(false);
      }
    } catch (e) {
      setData(null);
      setError("Failed to fetch group data.");
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <section id="add-physician-assistant">
      <PageToolbar
        title="Surgical Assistant"
        button={
          <ButtonTextIcon
            title="Cancel"
            className="light mar-l-a"
            onClick={() => {
              if (nextUrl) {
                navigate(nextUrl);
              } else {
                navigate("/surgical_assistance");
              }
            }}
            icon={
           <TbArrowBackUp />
            }
          />
        }
      />
      <div className="col-1-1">
        <div className="form-holder">
          <div className="inner">
            {!error && !loading && data && <UpdateForm data={data} />}
            {!error && loading && !data && (
              <p
                style={{
                  height: "100vh",
                  paddingBottom: "20vh",
                  backgroundColor: "#fafaff",
                }}
                className="flex center-center"
              >
                <LoaderIcon style={{ height: "32px", width: "32px" }} />
              </p>
            )}
            {!loading && !data && (
              <p
                style={{
                  height: "100vh",
                  paddingBottom: "20vh",
                  backgroundColor: "#fafaff",
                }}
                className="flex center-center"
              >
                {error || "Error"}
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdateSurgicalAssistant;
