import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useState } from "react";
import {
	API_URL,
	FACILITY_LISTING_OPTIONS,
	PATIENT_LISTING_OPTIONS,
	PREFRENCE_CARD_LISTING_OPTIONS,
	SURGEON_LISTING_OPTIONS,
} from "../../utilities/constants";
import { useNavigate } from "react-router-dom";
import PaginatedDropdown, {
	mapOptions,
} from "../../components/ui/PaginatedDropdown";
import { set, truncate } from "lodash";
import { useUI } from "../../context/ui";
import { handleApiCall, sleep } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { MdCancel } from "react-icons/md";

const AddForm = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [webModule, setWebModule] = useState(null);
	const [appModule, setAppModule] = useState(null);
	const { addToastMessage } = useUI();

	const initialValues = {
		preferenceCardListingOption: "",
		patient_id: "",
		name: "",
		event_type: "",
		facilityListingOption: "",
		patientListingOption: "",
		surgeonListingOption: "",
		webModules: [],
		appModules: [],
	};

	const validationSchema = Yup.object({
		name: Yup.string().required("Name is required"),
	});

	const createGroup = async (values) => {
		setLoading(true);
		setStatus("");
		try {
			const payload = {
				name: values.name,
				preferenceCardListingOption: values.preferenceCardListingOption,
				patientListingOption: values.patientListingOption,
				surgeonListingOption: values.surgeonListingOption,
				facilityListingOption: values.facilityListingOption,
				appModules: values.appModules
					? values.appModules.map((item) => ({
							id: item.val,
							isAddAccess: item.isAddAccess,
							isListAccess: item.isListAccess,
							isUpdateAccess: item.isUpdateAccess,
							isDeleteAccess: item.isDeleteAccess,
						}))
					: [],
				webModules: values.webModules
					? values.webModules.map((item) => ({
							id: item.val,
							isAddAccess: item.isAddAccess,
							isListAccess: item.isListAccess,
							isUpdateAccess: item.isUpdateAccess,
							isDeleteAccess: item.isDeleteAccess,
						}))
					: [],
			};
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/groups`,
				{
					method: "POST",
					body: payload,
				}
			);
			if (response.responseStatus === true) {
				setStatus("You have successfully created a group, thank you.");
				addToastMessage({
					status: true,
					message: `You have successfully created a group, thank you.`,
				});
				navigate("/groups");
				return true;
			} else {
				setStatus(
					`${response.responseDescription || "Something went wrong, please try again later."}`
				);
				addToastMessage({
					status: false,
					message: `${response.responseDescription || "Something went wrong, please try again later."}`,
				});
				return false;
			}
		} catch (e) {
			console.error("Error:", e);
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
			return false;
		} finally {
			setLoading(false);
		}
	};

	const labelModuleFunc = (o) => `${o.moduleName}`;

	return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await createGroup(values);
        if (api) {
          resetForm();
        }
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
        validateForm,
        setFieldTouched,
      }) => (
        <Form>
          <div className="col-1-1">
            <table>
              <tbody>
                <tr>
                  <td colSpan="3" width="50%">
                    <Label title="Group Name" required={truncate} />
                    <Field
                      type="text"
                      placeholder="Enter Group Name"
                      name="name"
                      className={`col-1-1 primary ${
                        errors.name && touched.name ? "error" : ""
                      }`}
                    />
                  </td>
                  <td colSpan="3" width="50%">
                    <Label title="Patient Listing Options" />
                    <Field
                      as="select"
                      name="patientListingOption"
                      className={`col-1-1 primary ${
                        errors.patientListingOption &&
                        touched.patientListingOption
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {PATIENT_LISTING_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <Label title="Facility Listing Options" />
                    <Field
                      as="select"
                      name="facilityListingOption"
                      className={`col-1-1 primary ${
                        errors.facilityListingOption &&
                        touched.facilityListingOption
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {FACILITY_LISTING_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                  <td colSpan="3">
                    <Label title="Surgeon Listing Options" />
                    <Field
                      as="select"
                      name="surgeonListingOption"
                      className={`col-1-1 primary ${
                        errors.surgeonListingOption &&
                        touched.surgeonListingOption
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {SURGEON_LISTING_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <Label title="Preference Card Listing Options" />
                    <Field
                      as="select"
                      name="preferenceCardListingOption"
                      className={`col-1-1 primary ${
                        errors.preferenceCardListingOption &&
                        touched.preferenceCardListingOption
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {PREFRENCE_CARD_LISTING_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td colSpan="6">
                    <Label title="Web Module " />
                    <PaginatedDropdown
                      searchkey="moduleName"
                      datakey="list"
                      url={`${API_URL}/api/1.0.0/reference/module?isOnApp=${false}&`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelModuleFunc)
                      }
                      error={errors.webModules && touched.webModules}
                      setTouched={() => {
                        setFieldTouched("webModules", true);
                      }}
                      selectedValue={webModule}
                      placeHolder="Search"
                      onChange={async (v) => {
                        if (v) {
                          setWebModule(v);
                          const p = values.webModules;
                          const n = {
                            val: v.value,
                            name: v.label,
                            isAddAccess: false,
                            isDeleteAccess: false,
                            isListAccess: false,
                            isUpdateAccess: false,
                          };
                          if (!p.some((item) => item.val === v.value)) {
                            setFieldValue("webModules", [
                              ...values.webModules,
                              n,
                            ]);
                          }
                        }
                        await sleep();
                        setWebModule(null);
                      }}
                    />

                    {values.webModules.length > 0 && (
                      <ul
                        className="col-1-1 t-a-l"
                        style={{
                          padding: "10px 0",
                        }}
                      >
                        {values.webModules.map((p, j) => (
                          <li
                            data-tooltip={`Click to delete the module.`}
                            onClick={() => {
                              const filtered = values.webModules.filter(
                                (obj) => obj.val !== p.val
                              );
                              setFieldValue("webModules", filtered);
                            }}
                            style={{
                              listStyle: "none",
                              marginRight: "4px",
                              marginBottom: "4px",
                              borderRadius: "2px",
                              lineHeight: "32px",
                              padding: "10px 12px",
                              verticalAlign: "middle",
                            }}
                            key={j}
                            className="opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                          >
                            <div className="flex center-left">
                              <p
                                className="of-hid"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "108px",
                                  textOverflow: "ellipsis",
                                  fontSize: "12px",
                                }}
                              >
                                {p.name}
                              </p>
                              <MdCancel size={20} />
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                </tr>
                {/* {Web Module} */}
                {values.webModules.length > 0 && (
                  <FieldArray name="webModules">
                    {({ push, remove }) => (
                      <>
                        <tr>
                          <td className="color-primary">Module</td>
                          <td className="color-primary">List</td>
                          <td className="color-primary">Add</td>
                          <td className="color-primary">Update</td>
                          <td className="color-primary">Delete</td>
                          <td className="color-primary">Action</td>
                        </tr>

                        {values.webModules.length > 0 && (
                          <>
                            {" "}
                            {values.webModules.map((val, index) => (
                              <tr key={index}>
                                <td className="color-primary">{val.name} </td>
                                <td width="12%">
                                  <Field
                                    name={`webModules.${index}.isListAccess`}
                                    type="checkbox"
                                    // className="primary"
                                    className="checkbox-field primary"
                                  />
                                </td>
                                <td width="12%">
                                  <Field
                                    name={`webModules.${index}.isAddAccess`}
                                    type="checkbox"
                                    className="primary checkbox-field "
                                  />
                                </td>
                                <td width="12%">
                                  <Field
                                    name={`webModules.${index}.isUpdateAccess`}
                                    type="checkbox"
                                    className="primary checkbox-field "
                                  />
                                </td>
                                <td width="12%">
                                  <Field
                                    name={`webModules.${index}.isDeleteAccess`}
                                    type="checkbox"
                                    className="primary checkbox-field"
                                  />
                                </td>
                                <td width="12%">
                                  <button
                                    data-button="delete"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </FieldArray>
                )}
                <tr>
                  <td colSpan="6">
                    <Label title="App Module " />
                    <PaginatedDropdown
                      searchkey="moduleName"
                      datakey="list"
                      url={`${API_URL}/api/1.0.0/reference/module?isOnApp=${true}&`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelModuleFunc)
                      }
                      error={errors.appModules && touched.appModules}
                      setTouched={() => {
                        setFieldTouched("appModules", true);
                      }}
                      selectedValue={appModule}
                      placeHolder="Search"
                      onChange={async (v) => {
                        if (v) {
                          setAppModule(v);
                          const p = values.appModules;
                          const n = {
                            val: v.value,
                            name: v.label,
                            isAddAccess: false,
                            isDeleteAccess: false,
                            isListAccess: false,
                            isUpdateAccess: false,
                          };
                          if (!p.some((item) => item.val === v.value)) {
                            setFieldValue("appModules", [
                              ...values.appModules,
                              n,
                            ]);
                          }
                        }
                        await sleep();
                        setAppModule(null);
                      }}
                    />

                    {values.appModules.length > 0 && (
                      <ul
                        className="col-1-1 t-a-l"
                        style={{
                          padding: "10px 0",
                        }}
                      >
                        {values.appModules.map((p, j) => (
                          <li
                            data-tooltip={`Click to delete the module.`}
                            onClick={() => {
                              const filtered = values.appModules.filter(
                                (obj) => obj.val !== p.val
                              );
                              setFieldValue("appModules", filtered);
                            }}
                            style={{
                              listStyle: "none",
                              marginRight: "4px",
                              marginBottom: "4px",
                              borderRadius: "2px",
                              lineHeight: "32px",
                              padding: "10px 12px",
                              verticalAlign: "middle",
                            }}
                            key={j}
                            className="opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                          >
                            <div className="flex center-left">
                              <p
                                className="of-hid"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "108px",
                                  textOverflow: "ellipsis",
                                  fontSize: "12px",
                                }}
                              >
                                {p.name}
                              </p>
                              <MdCancel size={20} />
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                </tr>
                {/* {App Module} */}
                {values.appModules.length > 0 && (
                  <FieldArray name="appModules">
                    {({ push, remove }) => (
                      <>
                        <tr>
                          <td className="color-primary">Module</td>
                          <td className="color-primary">List</td>
                          <td className="color-primary">Add</td>
                          <td className="color-primary">Update</td>
                          <td className="color-primary">Delete</td>
                          <td className="color-primary">Action</td>
                        </tr>
                        {values.appModules.length > 0 && (
                          <>
                            {values.appModules.map((val, index) => (
                              <tr key={index}>
                                <td className="color-primary">{val.name} </td>
                                <td width="12%">
                                  <Field
                                    name={`appModules.${index}.isListAccess`}
                                    type="checkbox"
                                    className="primary ceneter-center checkbox-field "
                                  />
                                </td>
                                <td width="12%">
                                  <Field
                                    name={`appModules.${index}.isAddAccess`}
                                    type="checkbox"
                                    className="primary checkbox-field "
                                  />
                                </td>
                                <td width="12%">
                                  <Field
                                    name={`appModules.${index}.isUpdateAccess`}
                                    type="checkbox"
                                    className="primary center-center checkbox-field "
                                  />
                                </td>
                                <td width="12%">
                                  <Field
                                    name={`appModules.${index}.isDeleteAccess`}
                                    type="checkbox"
                                    className="primary center-center checkbox-field "
                                  />
                                </td>
                                <td width="12%">
                                  <button
                                    data-button="delete"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </FieldArray>
                )}
              </tbody>
            </table>
          </div>

          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => navigate("/groups")}
              icon={<TbArrowBackUp />}
            />
            <ButtonTextIcon
              type="submit"
              disabled={loading}
              title="Create Now"
              onClick={async () => {
                const errors = await validateForm();
                if (Object.keys(errors).length > 0) {
                  const firstErrorMessage = Object.values(errors)[0];
                  addToastMessage({
                    status: false,
                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                  });
                }
                handleSubmit();
              }}
              className="mar-l-a"
              icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
            />
          </div>
          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddForm;
