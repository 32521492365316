import React, { useEffect, useState } from "react";
import LineChart from "../../components/charts/LineChart";
import { backgroundColor, chartOptions, emptyLineData } from "./ChartConfig";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { handleApiCall, jsonToQueryParams } from "../../utilities/helpers";
import { API_URL } from "../../utilities/constants";

const InvoiceLine = () => {
  const [controller, setController] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1900 + 1 },
    (_, i) => 1900 + i
  );
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);

  const fetchData = async () => {
    setData([]);
    setLineData((prev) => ({
      ...prev,
      labels: [],
      datasets: [
        {
          ...prev.datasets[1],
          data: [],
        },
      ],
    }));
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;

    const query = jsonToQueryParams({
      year: year || null,
    });
    setLoading(true);

    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/analytics/invoice-count?${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        const monthData = response.data.map((item) => item.count);
        const monthLabels = response.data.map((item) => {
          const monthIndex = item.month - 1; // Adjust for zero-based index
          return `${month[monthIndex]} ${year}`;
        });
        setData(response.data);
        setLineData({
          labels: monthLabels,
          datasets: [
            {
              label: `Invoices Generated`,
              data: monthData,
              fill: true,
              tension: 0.4,
              borderWidth: 2,
            },
          ],
        });
        setLoading(false);
      }
      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [year]);

  const [lineData, setLineData] = useState({
    labels: [...data.map((item) => item.month)],
    datasets: [
      {
        label: `${year} Invoices Generated`,
        data: [],
        backgroundColor: backgroundColor[6],
        borderColor: backgroundColor[6],
      },
    ],
  });
  const customChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "40%",
    animation: {
      animateRotate: true,
      animateScale: true,
    },
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
    },
  };

  return (
    <div className="chart-card">
      <div
        className="flex center-center justify-between flex-row of-hid"
        style={{
          padding: "16px",
          gap: "16px",
        }}
      >
        <p className="f-w-700"> Invoices Generated</p>
        <div>
          <select
            className="select-input primary"
            style={{
              width: "100px",
              height: "40px",
              borderRadius: "5px",
            }}
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            {years.map((yr) => (
              <option key={yr} value={yr}>
                {yr}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div style={{ height: "300px" }}>
        {loading ? (
          <div className="flex center-center" style={{ height: "100%" }}>
            <LoaderIcon
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </div>
        ) : data.length === 0 ? (
          <LineChart data={emptyLineData} options={{ chartOptions }} />
        ) : (
          <LineChart
            data={lineData}
            options={{
              ...customChartOptions,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default InvoiceLine;
