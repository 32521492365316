import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import { useUI } from "../context/ui";
import Pagination from "../components/common/Pagination";
import LoaderIcon from "../components/ui/LoaderIcon";
import CustomModal from "../components/common/CustomModal";
import TableFilter from "./surgeonassociation/TableFilter";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import { useLocation, useNavigate } from "react-router-dom";
import useQueryParams from "../hooks/useQueryParams";
import { handleApiCall, jsonToQueryParams } from "../utilities/helpers";
import PageToolbar from "../components/common/PageToolbar";
import { FiPlusCircle } from "react-icons/fi";

const SurgeonAssociationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToastMessage, permissions } = useUI();
  const { updateQueryParam, queryParams } = useQueryParams();

  const pageNo = queryParams.get("pageNo") || 0;
  const pageSize = queryParams.get("pageSize") || 12;
  const search = queryParams.get("search") || "";
  const assistantSearch = queryParams.get("assistantSearch") || "";
  const [controller, setController] = useState(null);

  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("assistantSearch", "");
    updateQueryParam("pageNo", 0);
  };

  const callListApi = async () => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    const query = jsonToQueryParams({
      search: search,
      assistantSearch: assistantSearch,
    });
    setLoading(true);
    try {
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/surgeon-association?pageSize=${pageSize}&pageNo=${pageNo}&${query}&sortOrder=DESC`,
        {
          method: "GET",
        },
        null,
        signal
      );

      if (response.responseStatus == true) {
        setData(response.data);
      } else {
        setData(null);
      }

      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error", e);
    }
  };

  useEffect(() => {
    callListApi();
  }, [location.search]);

  const handleDelete = async (id) => {
    try {
      if (!id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting user. Please try again.`,
        });
        return false;
      }
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/surgeon-association/${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.responseStatus == true) {
        await callListApi();
        addToastMessage({
          status: true,
          message: `surgeon-association deleted successfully.`,
        });
      } else {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting surgeon-association. Please try again.`,
        });
      }
      return true;
    } catch (error) {
      console.error("Error deleting patient:", error);
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting surgeon-association. Please try again.`,
      });
      return false;
    }
  };

  return (
    <section id="activity-v2">
      <PageToolbar
        title="Surgeon Association"
        button={
          <ButtonTextIcon
            title="Add New"
            className="mar-l-a"
            onClick={() => navigate("/add-surgeon-association")}
            icon={<FiPlusCircle />}
          />
        }
      />
      <TableFilter
        resetFilters={() => {
          resetFilters();
        }}
      />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.records.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Surgical Assistant Name</th>
                <th>Surgeon First Name</th>
                <th>Surgeon Last Name</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.records.map((data, index) => (
                  <tr key={index}>
                    <td>{pageNo * pageSize + index + 1}</td>
                    <td>{data.user.name + " " + data.user.lastName}</td>
                    <td>{data.surgeon.firstName}</td>
                    <td>{data.surgeon.lastName}</td>
                    <td>
                      <div className="flex center-center">
                        {permissions?.isDeleteAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="delete"
                                  title="Delete"
                                />
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    await handleDelete(data.id);
                                    return true;
                                  }}
                                  message="Are you sure you want to delete this Surgeon Association?"
                                />
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "24px 15px 32px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default SurgeonAssociationPage;
