import { useEffect, useState } from "react";
import FileViewer from "../../components/ui/FileViewer";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { useNavigate } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { TbDownload, TbUpload } from "react-icons/tb";
import { FaRegFileLines } from "react-icons/fa6";

const ScanFlowImages = ({ p, files, handleAddMore }) => {
	const [allData, setAllData] = useState(null);
	const [active, setActive] = useState("");
	const [subActive, setSubActive] = useState("");
	const navigate = useNavigate();

	const { updateQueryParam } = useQueryParams();
	const groupedFiles = (files) => {
		const x = files.reduce((acc, file) => {
			if (!acc[file.type]) {
				acc[file.type] = [];
			}
			acc[file.type].push(file);
			return acc;
		}, {});
		return x;
	};

	useEffect(() => {
		if (files.length) {
			const d = groupedFiles(files);
			setAllData(d);
			setActive(Object.keys(d)[0]);
			setSubActive(d[Object.keys(d)[0]][0].fileS3Url);
		}
	}, [files]);

	useEffect(() => {
		if (allData) {
			const f = allData[active][0].fileS3Url;
			setSubActive(f);
		}
	}, [active]);

	const Selector = ({ f, ...rest }) => {
		return (
			<li
				onClick={() => {
					setActive(f);
				}}
				className='t-t-u l-s-n'
				style={{
					fontSize: "12px",
					borderRadius: "4px",
					fontWeight: 600,
					lineHeight: "48px",
					color: active !== f ? "#8f8f8f" : "#283f54",
					padding: "0 24px",
					background: active !== f ? "white" : "#F0F0F0",
					boxShadow:
						active === f
							? "none"
							: "inset 0 0 4px rgba(0,0,0,0.12)",
					cursor: "pointer",
				}}
				{...rest}>
				<span className='flex center-left'>
					<FaRegFileLines />
					{f}
				</span>
			</li>
		);
	};

	const SubSelector = ({ a, index, ...rest }) => {
    return (
      <li
        className="l-s-n cursor-pointer"
        onClick={() => setSubActive(a.fileS3Url)}
        style={{
          position: "relative",
          fontSize: "10px",
          padding: "0 4px",
          fontWeight: 600,
          textTransform: "uppercase",
          color: subActive !== a.fileS3Url ? "#8f8f8f" : "#283f54",
          lineHeight: "36px",
        }}
        {...rest}
      >
        <span
          style={{
            position: "absolute",
            content: '""',
            left: 0,
            bottom: "-2px",
            height: "3px",
            width: "100%",
            display: subActive === a.fileS3Url ? "block" : "none",
            background: "#8f8f8f",
          }}
        ></span>
        {p?.firstName || ""} {p?.lastName || ""} {a.fileType} ({index + 1})
      </li>
    );
  };

	return (
    <div className="col-1-1">
      <div className="">
        {allData && (
          <div>
            <div className="col-1-1 flex center-left pos-rel">
              <div className="left-tabs-holder">
                <ul
                  className="flex"
                  style={{
                    gap: "5px",
                    padding:"5px"
                  }}
                >
                  {Object.keys(allData).flatMap((f, i) =>
                    Array(1)
                      .fill(null)
                      .map((_, j) => <Selector f={f} key={`${f}-${j}`} />)
                  )}
                </ul>
              </div>
              <div
                className="flex center-right"
                style={{
                  width: "160px",
                }}
              >
                <ButtonTextIcon
                  icon={
                    <TbUpload />
                  }
                  type="button"
                  title={"Upload Files"}
                  onClick={() => handleAddMore()}
                />
              </div>
            </div>
            {/* File View Area */}
            <div
              className="col-1-1"
              style={{
                background: "white",
                padding: "0px 12px 12px 12px",
              }}
            >
              <ul
                className="col-1-1 flex"
                style={{
                  gap: "24px",
                  borderBottom: "solid 1px #cfcfcf",
                  paddingTop: "8px",
                  marginBottom: "12px",
                }}
              >
                {allData[active].map((a, x) => (
                  <SubSelector key={x} index={x} a={a} />
                ))}
              </ul>
              {subActive && (
                <>
                  <div
                    className="scanflow-details-left-content"
                    style={{
                      overflowY: "auto",
                    }}
                  >
                    <FileViewer fileUrl={subActive} isNoFullHeight={true} />
                  </div>
                  <div
                    className="col-1-1 flex center-left"
                    style={{
                      padding: "10px",
                      borderTop: "solid 1px #f0f0f0",
                    }}
                  >
                    <div>
                      <p>
                        <span
                          className="color-primary t-d-ul cursor-pointer"
                          onClick={() => {
                            updateQueryParam("id");
                          }}
                        >
                          Click here
                        </span>
                        {` to return to the main screen.`}
                      </p>
                    </div>
                    <div className="mar-l-a">
                      <ButtonTextIcon
                        icon={
                          <TbDownload />
                        }
                        onClick={() => {
                          window.open(subActive, "_blank");
                        }}
                        title="Download"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScanFlowImages;
