import { useEffect, useState } from "react";
import { API_URL, PBFUSA_EMAIL_ARRAY } from "../utilities/constants";
import LoaderIcon from "../components/ui/LoaderIcon";
import Pagination from "../components/common/Pagination";
import TableFilter from "../pages/pbfusa/TableFilter";
import CustomModal from "../components/common/CustomModal";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import PatientRegistrationDetail from "./pbfusa/PatientRegistrationDetail";
import {
  formatInputPhoneNumber,
  getUserDeatilsFromToken,
  handleApiCall,
  jsonToQueryParams,
} from "../utilities/helpers";
import useQueryParams from "../hooks/useQueryParams";
import NotAuthorizedPage from "./_common/NotAuthorizedPage";
import WithLayout from "../hoc/withLayout";
import PageToolbar from "../components/common/PageToolbar";
import { useUI } from "../context/ui";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";

const PatientRegistrationPage = () => {
  const [controller, setController] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { location, updateQueryParam, queryParams } = useQueryParams();
  const { profile, setProfile, status, setStatus } = useUI();
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const search = queryParams.get("search") || "";
  const [isAccessAllowed, setIsAccessAllowed] = useState(true);

  useDocumentTitle("Patient Registration");

  useEffect(() => {
    if (!profile && status === "idle") {
      const fetchUserProfile = async () => {
        setStatus("loading");
        try {
          const user = getUserDeatilsFromToken();
          const response = await handleApiCall(
            API_URL + "/api/1.0.0/profile/" + user?.userid,
            {
              method: "GET",
            }
          );
          if (response.responseStatus === true) {
            setProfile(response.data);
            if (PBFUSA_EMAIL_ARRAY.includes(response.data?.email)) {
              setIsAccessAllowed(true);
            } else {
              setIsAccessAllowed(false);
            }
            setStatus("success");
          } else {
            setStatus("error");
            navigate("/login");
          }
        } catch (error) {
          console.error("Failed to fetch user profile:", error);
          setStatus("error");
          navigate("/login");
        }
      };
      fetchUserProfile();
    }
  }, [
    module,
    location.pathname,
    profile,
    status,
    setProfile,
    setStatus,
    navigate,
  ]);

  const resetFilters = () => {
    updateQueryParam("pageNo", 0);
    updateQueryParam("search", "");
  };

  const getApi = async () => {
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    try {
      setLoading(true);
      const query = jsonToQueryParams({
        search: search,
      });
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/pfb-patient?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&${query}`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus === true) {
        setData(response.data);
      } else {
        setData(null);
      }
    } catch (e) {
      console.error("Error:", e);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getApi();
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [location.search]);

  if (status === "loading" || status === "idle") {
    return (
      <section style={{ height: "100vh" }}>
        <div
          className="flex center-center"
          style={{ backgroundColor: "white", height: "100vh" }}
        >
          <LoaderIcon
            style={{
              width: "32px",
              height: "32px",
            }}
          />
        </div>
      </section>
    );
  }

  if (!isAccessAllowed) {
    return (
      <WithLayout>
        <NotAuthorizedPage />
      </WithLayout>
    );
  }

  return (
    <WithLayout>
      <section id="category">
        <PageToolbar title="Patient Registration" />
        <TableFilter resetFilters={resetFilters} />
        <div>
          {data && !loading && (
            <div
              className="flex center-left"
              style={{
                padding: "10px 15px 10px 15px",
                gap: "12px",
              }}
            >
              <p className="f-w-300" style={{ fontSize: "11px" }}>
                Page no:
              </p>
              <Pagination
                totalRecords={data.totalRecords}
                pageNo={pageNo}
                pageSize={data.pageSize}
              />
            </div>
          )}
        </div>
        {loading ? (
          <section
            style={{
              height: "100vh",
              paddingBottom: "20vh",
              backgroundColor: "#fafaff",
            }}
            className="flex center-center"
          >
            <LoaderIcon style={{ height: "32px", width: "32px" }} />
          </section>
        ) : data?.list.length ? (
          <div className="rs-table-holder">
            <table className="rs-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data?.list.map((item, index) => (
                    <tr key={item.id}>
                      <td>{pageNo * pageSize + index + 1}</td>
                      <td>{item?.firstName + " "+ item?.middleName + " " + item?.lastName}</td>
                      <td>{item?.email|| "-"}</td>
                      <td>{formatInputPhoneNumber(item?.contactNo.trim())}</td>
                      <td>{item?.address|| "-"}</td>
                      <td>
                        <div
                          className="flex center-center"
                          style={{ gap: "12px" }}
                        >
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="view"
                                  title="View"
                                />
                              }
                              content={
                                <PatientRegistrationDetail
                                  id={item.id}
                                  data={item}
                                />
                              }
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <section
            style={{
              height: "80vh",
              paddingBottom: "20vh",
              backgroundColor: "#fff",
            }}
            className="flex center-center"
          >
            <p>No records found.</p>
          </section>
        )}
        <div>
          {data && !loading && (
            <div
              className="flex center-center"
              style={{
                padding: "24px 15px 32px 15px",
                gap: "12px",
              }}
            >
              <p className="f-w-300" style={{ fontSize: "11px" }}>
                Page no:
              </p>
              <Pagination
                totalRecords={data.totalRecords}
                pageNo={pageNo}
                pageSize={data.pageSize}
              />
            </div>
          )}
        </div>
      </section>
    </WithLayout>
  );
};

export default PatientRegistrationPage;
