import ResultsPerPage from "../../components/common/ResultsPerPage";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useEffect, useRef, useState } from "react";
import Label from "../../components/ui/Label";
import useQueryParams from "../../hooks/useQueryParams";
import { GrPowerReset } from "react-icons/gr";

const TableFilter = ({ resetFilters }) => {
  const debounceTimeout = useRef(null);
  const { updateQueryParam, queryParams } = useQueryParams();
  const [searchValue, setSearchValue] = useState(
    queryParams.get("search") || ""
  );
  const handleSearchChange = (value) => {
    setSearchValue(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      queryParams.set("pageNo", 0);
      updateQueryParam("search", value);
    }, 500);
  };

  return (
    <div className="col-1-1 flex center-left filters">
      <div>
        <Label variant="filter" title="Sub Category Name" />
        <input
          className="primary"
          type="text"
          value={searchValue}
          onChange={(v) => {
            handleSearchChange(v.target.value);
          }}
          placeholder="Eg: Hospital Billing"
        />
      </div>
      <div className="flex center-left" style={{ gap: "12px" }}>
        <div>
          <Label variant="filter" title="Reset" />
          <ButtonLightTextIcon
            title="Reset"
            className="light"
            icon={
             <GrPowerReset />
            }
            onClick={() => {
              setSearchValue("");
              resetFilters();
            }}
          />
        </div>
        <div className="mar-l-a">
          <Label variant="filter" title="Per page" />
          <ResultsPerPage />
        </div>
      </div>
    </div>
  );
};

export default TableFilter;
