import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom"; 
import LoginErrorImg from "../../images/icons/login-error.svg";
import {
	ANDROID_APP_NAME,
	API_URL,
	IOS_APP_NAME
} from "../../utilities/constants";  
import LoaderIcon from "../../components/ui/LoaderIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { handleApiCall } from "../../utilities/helpers";
import { TbArrowBackUp } from "react-icons/tb";

const AzureCallbackPage = () => {
    const navigate = useNavigate(); 
	const location = useLocation();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const validateApi = async (code, sessionState) => {
		try {
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/login/client/azure/validate`,
				{
					method: "POST",
					body: {
						code: code,
						redirectURL: window.location.origin + "/auth/callback",
						sessionState: sessionState,
						platform: "WEB",
					},
				}
			);
				if (
					response.responseStatus === true 
				) {
					localStorage.setItem( "token", response.data.token ); 
					navigate("/activities")
				} else {
					setError(
						response?.responseDescription ||
							"Oops, something went wrong."
					);
					setLoading(false);
				}
		
		} catch (e) {
			setError("Oops, something went wrong.");
			setLoading(false);
		}
	};

	useEffect(() => {
		const code = new URLSearchParams(location.search).get("code");
		const state = new URLSearchParams(location.search).get("state");
		const sessionState = new URLSearchParams(location.search).get("session_state");
		if (code && sessionState && state) {
			switch (state) {
				case "web":
					validateApi(code, sessionState);
					break;
				case "android":
					window.location.href =
						ANDROID_APP_NAME +
						`://app?code=${encodeURIComponent(
							code
						)}&session_state=${encodeURIComponent(sessionState)}`;
					break;
				case "ios":
					window.location.href =
						IOS_APP_NAME +
						`://app?code=${encodeURIComponent(
							code
						)}&session_state=${encodeURIComponent(sessionState)}`;

					break;

				default:
					setError("Oops, something went wrong.");
					setLoading(false);
					return;
			}
		} else {
			setError("Oops, something went wrong.");
			setLoading(false);
		}
	}, []);

	return (
		<section style={{ height: "100vh" }}>
			<div
				className='flex center-center'
				style={{ backgroundColor: "white", height: "100vh" }}>
				{!error && loading && (
					<LoaderIcon
						style={{
							width: "32px",
							height: "32px",
						}}
					/>
				)}
				{!loading && error && (
					<div className='t-a-c'>
						<img
							src={LoginErrorImg}
							style={{
								maxWidth: "320px",
								height: "auto",
								margin: "0 auto",
								display: "block",
							}}
						/>
						<br />
						<p className='color-primary font-size-14 f-w-600'>
							We could not log you into CoreMax at the moment,
							please try again later.
						</p>
						<p className='color-primary font-size-12 f-w-300'>
							{error}
						</p>
						<br />
						<NavLink to='/login'>
							<ButtonTextIcon
								icon={
									<TbArrowBackUp />
								}
								title='Back to Login'
							/>
						</NavLink>
					</div>
				)}
			</div>
		</section>
	);
};

export default AzureCallbackPage;
