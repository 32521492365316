import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { formatInputPhoneNumber, formatServerDate } from "../../utilities/helpers";
import { getRandomColor } from "../../utilities/helpers";
import InfoItem from "../../components/ui/InfoItem";
import FullScreenFileViewerModal from "../../components/ui/FullScreenFileViewerModal";
import CustomModal from "../../components/common/CustomModal";
import { TbArrowBackUp, TbTimelineEvent, TbUserCircle } from "react-icons/tb";
import { MdAlternateEmail } from "react-icons/md";
import { CiVoicemail, CiWallet } from "react-icons/ci";
import { BiTime } from "react-icons/bi";
import { FaUserDoctor } from "react-icons/fa6";
import { LuEye } from "react-icons/lu";

const VolunteerRegistrtionDetail = ({ setShow, data, }) => {  
  
    return (
      <div className="box" id="activity-detail-box">
        <div className="flex center-left">
          <div>
            <h6 className="color-primary f-w-300">
              Volunteer Registration Details
            </h6>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <ButtonLightTextIcon
              title="Cancel"
              onClick={() => setShow(false)}
              icon={<TbArrowBackUp />}
            />
          </div>
        </div>
        <div
          style={{
            height: "60vh",
            margin: "10px",
            overflowY: "scroll",
            padding: "20px 5px",
            overflowX: "hidden",
            scrollbarWidth: "thin",
          }}
        >
          {data && (
            <div className="activity-card pos-rel">
              <div
                style={{
                  position: "absolute",
                  content: "",
                  height: "4px",
                  left: "-1px",
                  right: "-1px",
                  bottom: "-2px",
                  borderBottomLeftRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              ></div>
              <div
                style={{
                  padding: "12px 0 12px 0 ",
                }}
              >
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<TbUserCircle color={getRandomColor()} size={20} />}
                    label={"Name"}
                    name={data?.firstName + " " + data?.lastName || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <MdAlternateEmail color={getRandomColor()} size={20} />
                    }
                    label={"Email"}
                    name={data?.email || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<CiVoicemail color={getRandomColor()} size={20} />}
                    label={"Phone Number"}
                    name={
                      data?.contactNo
                        ? formatInputPhoneNumber(data?.contactNo.trim())
                        : "NA"
                    }
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={
                      <TbTimelineEvent color={getRandomColor()} size={20} />
                    }
                    label={"Current Medical Institution"}
                    name={data?.currentMedicalInstitution || "NA"}
                  />
                </div>
                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<CiWallet color={getRandomColor()} size={20} />}
                    label={"Medical Specialization"}
                    name={data?.medicalSpecialization || "NA"}
                  />
                  <InfoItem
                    className="col-1-1 info-row-column"
                    icon={<BiTime color={getRandomColor()} size={20} />}
                    label="Expiry Year"
                    name={
                      data?.yearOfExpiry
                        ? formatServerDate(data?.yearOfExpiry)
                        : "NA"
                    }
                  />
                </div>
                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
                <p
                  style={{
                    fontWeight: "700",
                    paddingBottom: "8px",
                  }}
                >
                  Preferred Surgeries
                </p>

                <div
                  className="info-row flex center-center"
                  style={{
                    gap: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <InfoItem
                    fullText={true}
                    className="col-1-1 info-row-column"
                    icon={<FaUserDoctor color={getRandomColor()} size={20} />}
                    label="Surgery"
                    name={
                      data.preferredSurgeries
                        .map((item) => item.surgery)
                        .join(", ") || "NA"
                    }
                  />
                </div>

                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
                <div style={{ marginBottom: "16px" }}>
                  <p
                    style={{
                      fontWeight: "700",
                      paddingBottom: "8px",
                    }}
                  >
                    Documents
                  </p>
                  <div
                    style={{
                      display: "grid",
                      gap: "16px",
                    }}
                  >
                    {data?.s3urls?.length > 0 ? (
                      data.s3urls.map((url, index) => (
                        <CustomModal
                          selector={
                            <InfoItem
                              className="col-1-1 info-row-column cursor-pointer"
                              icon={
                                <LuEye color={getRandomColor()} size={20} />
                              }
                              label={"Attachment"}
                              name="Click to View"
                            />
                          }
                          content={
                            <FullScreenFileViewerModal
                              title={`Document`}
                              fileUrl={url}
                            />
                          }
                        />
                      ))
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "5px 12px",
                          backgroundColor: "rgb(245, 245, 245)",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              No document found.
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};
export default VolunteerRegistrtionDetail;
