import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PaginatedDropdown from "../../components/ui/PaginatedDropdown";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import { handleApiCall } from "../../utilities/helpers";
import Label from "../../components/ui/Label";
import { useNavigate } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";

const validationSchema = Yup.object({
	category: Yup.mixed()
		.nullable()
		.test("category-required", "Category is required", function (value) {
			return value !== null && value.value;
		}),
	subCategory: Yup.string().required("Sub Category is required"),
});

function UpdateForm({ data }) {
	const navigate = useNavigate();
	const [initialValues, setInitialValues] = useState({
		category: "",
		subCategory: "",
	});
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const { addToastMessage } = useUI();
	const { queryParams, updateQueryParam } = useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";

	useEffect(() => {
		if (data) {
			setInitialValues({
				category: {
					value: data?.knowledgeBaseCategory.id,
					label: data?.knowledgeBaseCategory.name,
					obj: data?.knowledgeBaseCategory,
				},
				subCategory: data?.name || "",
			});
		}
	}, [data]);

	const updateSubCategory = async (values, resetForm) => {
		setLoading(true);
		try {
			const payload = {
				name: values.subCategory,
				knowledgeBaseCategoryId: values.category.value,
			};
			const response = await handleApiCall(
				API_URL + `/api/1.0.0/knowledge-base-subcategory/${data?.id}`,
				{
					method: "PUT",
					body: payload,
				}
			);
			if (response.responseStatus == true) {
				setStatus(
					"Your knowledge base sub category has been successfully updated. Thank you."
				);
				addToastMessage({
					status: true,
					message: `Your knowledge base sub category has been successfully updated. Thank you.`,
				});
				if (nextUrl) {
					navigate(nextUrl);
				} else {
					navigate("/knowledge-base-sub-categories");
				}
				return true;
			} else {
				setStatus(
					`${response?.responseDescription || "Something went wrong, please try again later."}`
				);
				addToastMessage({
					status: false,
					message: `${response?.responseDescription || "Something went wrong, please try again later."}`,
				});
				return false;
			}
		} catch (e) {
			console.error("Error:", e);
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		if (data && data.knowledgeBaseCategory) {
			setInitialValues({
				category: {
					value: data.knowledgeBaseCategory.id,
					label: data.knowledgeBaseCategory.name,
					obj: data.knowledgeBaseCategory,
				},
				subCategory: data.name || "",
			});
		}
	}, [data]);

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={validationSchema}
			onSubmit={async (values, { resetForm }) => {
				await updateSubCategory(values);
			}}>
			{({
				handleSubmit,
				validateForm,
				values,
				errors,
				touched,
				setFieldValue,
				setFieldTouched,
			}) => (
				<Form onSubmit={handleSubmit}>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td>
										<Label title='Category' required />
										<PaginatedDropdown
											searchkey='name'
											url={`${API_URL}/api/1.0.0/knowledge-base-category?`}
											mapOptions={(records) =>
												records.map((record) => ({
													value: record["id"],
													label: record.name,
													obj: record,
												}))
											}
											error={
												errors.category &&
												touched.category
											}
											setTouched={() =>
												setFieldTouched(
													"category",
													true
												)
											}
											selectedValue={values.category}
											placeHolder='Search'
											datakey='knowledgeBaseCategoryList'
											onChange={(v) => {
												setFieldValue("category", v);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label title='Sub Category' required />
										<Field
											name='subCategory'
											type='text'
											placeholder='Sub Category'
											className={`primary ${errors.subCategory && touched.subCategory ? "error" : ""}`}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => {
								if (nextUrl) {
									navigate(nextUrl);
								} else {
									navigate("/knowledge-base-sub-categories");
								}
							}}
							icon={
								<TbArrowBackUp />
							}
						/>
						<ButtonTextIcon
							type='submit'
							disabled={loading}
							title='Update Now'
							onClick={async () => {
								const errors = await validateForm();
								if (Object.keys(errors).length > 0) {
									const firstErrorMessage =
										Object.values(errors)[0];
									addToastMessage({
										status: false,
										message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
									});
								}
								handleSubmit();
							}}
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<FiArrowRightCircle />
								)
							}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default UpdateForm;
