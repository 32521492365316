import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import LoaderIcon from "../components/ui/LoaderIcon";
import Pagination from "../components/common/Pagination";
import { formatServerDate, handleApiCall, jsonToQueryParams } from "../utilities/helpers";
import UserFilter from "./user/UserFilter";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import useQueryParams from "../hooks/useQueryParams";
import UserDetails from "./user/UserDetails";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "../components/ui/ConfirmationModal";

const ArchivedUser = () => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateQueryParam, queryParams } = useQueryParams();
  const pageNo = queryParams.get("pageNo") || 0;
  const pageSize = queryParams.get("pageSize") || 12;
  const { permissions ,addToastMessage } = useUI();
  const [ueserid, setueserid] = useState(null);
  const search = queryParams.get("search") || "";
  const userType = queryParams.get("userType") || "";
  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("pageNo", 0);
    updateQueryParam("userType", "");
  };
  const callListApi = async () => {
    setLoading(true);
    const query = jsonToQueryParams({
      search: search, 
      userType: userType,
    });
    try {
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/users/list?pageSize=${pageSize}&pageNo=${pageNo}&${query}&showArchivedUsers=show`,
        {
          method: "GET",
        }
      );

      if (response.responseStatus === true) {
        setData(response.data);
      } else {
        setData(null);
      }
    } catch (e) {
      console.error("Error", e);
    } finally {
      setLoading(false);
    }
  };

  const handleUnArchive = async (id) => {
    try {
      if (!id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while unarchiving user. Please try again.`,
        });
        return false;
      }
      const response = await fetch(
        API_URL + `/api/1.0.0/users/un-archive/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 401 || response.status === 403) {
        if (response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        } else if (response.status === 403) {
          addToastMessage({
            status: false,
            message:
              "Access denied. You do not have permission to perform this action.",
          });
        }
        return false;
      }
      if (response.status === 200) {
        await callListApi();
        addToastMessage({
          status: true,
          message: `User unarchived successfully.`,
        });
      } else {
        const resp = await response.json();
        addToastMessage({
          status: false,
          message:
            resp?.responseDescription ||
            `Something went wrong while unarchiving user. Please try again.`,
        });
      }
      return true;
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while unarchiving user. Please try again.`,
      });
      return false;
    }
  };

  const handlePermanentDelete = async (id) => {
    try {
      if (!id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while permanently delete user. Please try again.`,
        });
        return false;
      }
      const response = await fetch(
        API_URL + `/api/1.0.0/users/permanent-delete/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 401 || response.status === 403) {
        if (response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        } else if (response.status === 403) {
          addToastMessage({
            status: false,
            message:
              "Access denied. You do not have permission to perform this action.",
          });
        }
        return false;
      }
      if (response.status === 200) {
        await callListApi();
        addToastMessage({
          status: true,
          message: `User permanently delete successfully.`,
        });
      } else {
        const resp = await response.json();
        addToastMessage({
          status: false,
          message:
            resp?.responseDescription ||
            `Something went wrong while permanently delete user. Please try again.`,
        });
      }
      return true;
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while permanently delete user. Please try again.`,
      });
      return false;
    }
  };

  useEffect(() => {
    callListApi();
  }, [location.search]);
  return (
    <section id="activity-v2">
      <div className="col-1-1 flex center-left pad-0-10 main-section-top-row">
        <h5 className="page-active color-primary f-w-700">Archived Users / Assistants</h5>
      </div>
      <UserFilter
        resetFilters={() => {
          resetFilters();
        }}
      />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.records.length && data?.records.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Type</th>
                <th>Active Start</th>
                <th>Active End</th>
                {/* <th>Notes</th> */}
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.records.map((data, index) => (
                  <tr key={index}>
                    <td>{pageNo * pageSize + index + 1}</td>
                    <td>{data.fullName}</td>
                    <td>{data.email}</td>
                    <td>{data.type}</td>
                    <td>
                      {data.activeStart
                        ? formatServerDate(data.activeStart)
                        : "NA"}
                    </td>
                    <td>
                      {data.activeEnd ? formatServerDate(data.activeEnd) : "NA"}
                    </td>
                    {/* <td>{data.notes}</td> */}
                    <td style={{ textAlign: "center" }}>
                        <div className="flex " style={{ gap: "10px", justifyContent: 'center' }}>
                            {permissions?.isReadAllowed && (
                              <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                <CustomModal
                                  selector={
                                    <ButtonTertiary actionType="view" title="View" />
                                  }
                                  content={
                                    <UserDetails id={data?.id} showedit={false} />
                                  }
                                />
                              </div>
                            )}
                            {permissions?.isDeleteAllowed && (
                              <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                      actionType="reload"
                                      title="Restore"
                                    />
                                  }
                                  content={
                                    <ConfirmationModal
                                      onConfirm={async () => {
                                        await handleUnArchive(data.id);
                                        return true;
                                      }}
                                      message="Are you sure you want to unarchive this user?"
                                    />
                                  }
                                />
                              </div>
                            )}
                              {permissions?.isDeleteAllowed && (
                              <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                      actionType="delete"
                                      title="Permanently Delete"
                                    />
                                  }
                                  content={
                                    <ConfirmationModal
                                      onConfirm={async () => {
                                        await handlePermanentDelete(data.id);
                                        return true;
                                      }}
                                      message="Are you sure you want to permanently delete this user? Deleting this user will remove their account from the platform. This action cannot be undone, and the user cannot be restored once deleted.<br/><br/>Please confirm if you wish to proceed."
                                    />
                                  }
                                />
                              </div>
                            )}
                          </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}
      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "24px 15px 32px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default ArchivedUser;
