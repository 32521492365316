import { useEffect, useState } from "react";
import { API_URL } from "../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../components/ui/LoaderIcon";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import TableFilter from "./knowledge-base/TableFilter";
import { useUI } from "../context/ui";
import ButtonTertiary from "../components/ui/ButtonTertiary";
import ConfirmationModal from "../components/ui/ConfirmationModal";
import CustomModal from "../components/common/CustomModal";
import ButtonLightTextIcon from "../components/ui/ButtonLightTextIcon";
import { LuEye, LuQrCode } from "react-icons/lu";
import { LiaAudioDescriptionSolid } from "react-icons/lia";
import {
  getRandomColor,
  handleApiCall,
  jsonToQueryParams,
} from "../utilities/helpers";
import PageToolbar from "../components/common/PageToolbar";
import InfoItem from "../components/ui/InfoItem";
import useQueryParams from "../hooks/useQueryParams";
import { FiEdit, FiPlusCircle } from "react-icons/fi";
import { TbArrowBackUp, TbCategory2, TbUserCircle } from "react-icons/tb";
import { MdFolderSpecial } from "react-icons/md";
import { FaRegFileLines } from "react-icons/fa6";

const KnowledgeBasePage = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { updateQueryParam, queryParams } = useQueryParams();
  const [controller, setController] = useState(null);

  const pageNo = queryParams.get("pageNo") || 0;
  const pageSize = queryParams.get("pageSize") || 12;

  const { permissions, addToastMessage } = useUI();
  const [viewLoding, setViewLoading] = useState(false);
  // ====== RESET FILTER
  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("pageNo", 0);
  };

  const getKnowledgeBaseApi = async () => {
    const query = jsonToQueryParams({
      name: queryParams.get("search") || "",
    });
    controller?.abort();
    const newController = new AbortController();
    setController(newController);
    const { signal } = newController;
    setLoading(true);
    try {
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/knowledge-base?pageSize=${pageSize}&pageNo=${pageNo}&${query}&sortOrder=DESC`,
        {
          method: "GET",
        },
        null,
        signal
      );
      if (response.responseStatus == true) {
        setData(response.data);
      } else {
        setData(null);
      }

      if (response.responseCode !== 299) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  const fetchKBData = async (id) => {
    setViewLoading(true);
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/knowledge-base/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        if (!response.data?.fileLinkS3Url) {
          addToastMessage({
            status: false,
            message: "No file found.",
          });
          setViewLoading(false);
          return false;
        }

        navigate({
          pathname: "/doc",
          search: `?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(response.data?.fileLinkS3Url || "")}&title=${encodeURIComponent(response.data?.description || "")}`,
          state: { ...location.state },
        });
        setViewLoading(false);
      }
    } catch (e) {
      setViewLoading(false);
      console.log("Error:", e);
    } finally {
      setViewLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!id) {
      addToastMessage({
        status: false,
        message: "Something went wrong while deleting . Please try again.",
      });
      return false;
    }
    try {
      const response = await handleApiCall(
        `${API_URL}/api/1.0.0/knowledge-base/change-status/${id}`,
        {
          method: "PUT",
          body: { statusId: 2 },
        }
      );
      if (response.responseStatus === true) {
        getKnowledgeBaseApi();
        addToastMessage({
          status: true,
          message: "Knowledge base deleted successfully.",
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    addToastMessage({
      status: false,
      message:
        "Something went wrong while deleting Knowledge base. Please try again.",
    });
    return false;
  };

  useEffect(() => {
    getKnowledgeBaseApi();
  }, [location.search]);

  return (
    <section id="knowledgebase">
      <PageToolbar
        title="Knowledge Base"
        button={
          permissions?.isCreateAllowed && (
            <ButtonTextIcon
              title="Add New "
              className="mar-l-a"
              onClick={() => navigate("/add-knowledge-base")}
              icon={<FiPlusCircle />}
            />
          )
        }
      />
      <TableFilter
        resetFilters={() => {
          resetFilters();
        }}
      />
      <div>
        {data && !loading && (
          <div
            className="flex center-left"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>

      {loading ? (
        <section
          style={{
            height: "100vh",
            paddingBottom: "20vh",
            backgroundColor: "#fafaff",
          }}
          className="flex center-center"
        >
          <LoaderIcon style={{ height: "32px", width: "32px" }} />
        </section>
      ) : data?.knowledgeBaseList.length ? (
        <div className="rs-table-holder">
          <table className="rs-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Category Name</th>
                <th>Sub Category Name</th>
                <th>Description</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan="8"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <LoaderIcon style={{ height: "32px", width: "32px" }} />
                  </td>
                </tr>
              ) : (
                data &&
                data?.knowledgeBaseList.map((entry, i) => (
                  <tr key={entry.id}>
                    <td>{pageNo * pageSize + i + 1}</td>
                    <td>{entry.name}</td>
                    <td>
                      {
                        entry.knowledgeBaseSubCategory.knowledgeBaseCategory
                          .name
                      }
                    </td>
                    <td>{entry.knowledgeBaseSubCategory.name}</td>
                    <td
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "200px",
                      }}
                    >
                      {entry.description}
                    </td>
                    <td>
                      <div
                        className="flex center-center"
                        style={{ gap: "12px" }}
                      >
                        {permissions?.isUpdateAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <ButtonTertiary
                              actionType="view"
                              title="View"
                              disabled={viewLoding}
                              onClick={() => {
                                fetchKBData(entry?.id);
                              }}
                            />
                          </div>
                        )}

                        {permissions?.isUpdateAllowed && (
                          <div className="flex color-light font-size-10 ">
                            <NavLink
                              to={`/edit-knowledge-base?next=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                              state={{ id: entry?.id }}
                            >
                              <ButtonTertiary actionType="edit" title="Edit" />
                            </NavLink>
                          </div>
                        )}

                        {permissions?.isDeleteAllowed && (
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
                                  actionType="delete"
                                  title="Delete"
                                />
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    await handleDelete(entry?.id);
                                    return true;
                                  }}
                                  message="Are you sure you want to delete this knowledge base?"
                                />
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <section
          style={{
            height: "80vh",
            paddingBottom: "20vh",
            backgroundColor: "#fff",
          }}
          className="flex center-center"
        >
          <p>No records found.</p>
        </section>
      )}

      <div>
        {data && !loading && (
          <div
            className="flex center-center"
            style={{
              padding: "10px 15px 10px 15px",
              gap: "12px",
            }}
          >
            <p className="f-w-300" style={{ fontSize: "11px" }}>
              Page no:
            </p>
            <Pagination
              totalRecords={data.totalRecords}
              pageNo={data.pageNo}
              pageSize={data.pageSize}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default KnowledgeBasePage;

const KnowledgeBaseContent = ({ setShow, id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { permissions } = useUI();
  const location = useLocation();
  const fetchFacilityData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/knowledge-base/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus === true) {
        setData(response.data);
        setLoading(false);
      } else {
        setError("Failed to fetch facility data.");
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch facility data.");
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    if (id) fetchFacilityData(id);
  }, [id]);

  return (
    <div id="activity-detail-box">
      <div className="flex center-left">
        <div>
          <h6 className="color-primary f-w-300">Knowledge Base Details</h6>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ButtonLightTextIcon
            title="Cancel"
            onClick={() => {
              setShow(false);
            }}
            icon={<TbArrowBackUp />}
          />
        </div>
      </div>
      <div
        style={{
          height: "60vh",
          margin: "10px",
          overflowY: "scroll",
          padding: "20px 5px",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
      >
        {loading && (
          <div
            className="col-1-1"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderIcon
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
        )}
        {!loading && error && (
          <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
            {error}
          </p>
        )}
        {data && !loading && !error && (
          <div className="activity-card pos-rel">
            <div
              style={{
                position: "absolute",
                content: "",
                height: "4px",
                left: "-1px",
                right: "-1px",
                bottom: "-2px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            ></div>
            <div
              className="col-1-1 flex center-left"
              style={{
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <h5 className="f-w-700">
                <span className="f-w-300">#</span>
                &nbsp;
                {data?.id}
              </h5>
              <hr
                style={{
                  outline: "none",
                  border: "none",
                  height: "16px",
                  width: "2px",
                  background: "#EBEBEB",
                }}
              />
            </div>
            <div
              style={{
                padding: "12px 0 12px 0 ",
              }}
            >
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbUserCircle color={getRandomColor()} size={20} />}
                  label={"Title"}
                  name={data?.name}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<MdFolderSpecial color={getRandomColor()} size={20} />}
                  label={"Category"}
                  name={
                    data?.knowledgeBaseSubCategory?.knowledgeBaseCategory
                      ?.name || "NA"
                  }
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<TbCategory2 color={getRandomColor()} size={20} />}
                  label={"Sub Category"}
                  name={data?.knowledgeBaseSubCategory?.name || "NA"}
                />
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={<LuQrCode color={getRandomColor()} size={20} />}
                  label={"Code"}
                  name={
                    data?.knowledgeBaseSubCategory?.knowledgeBaseCategory
                      ?.code || "NA"
                  }
                />
              </div>
              <div
                className="info-row flex center-center"
                style={{
                  gap: "10px",
                  paddingBottom: "10px",
                }}
              >
                <InfoItem
                  className="col-1-1 info-row-column"
                  icon={
                    <LiaAudioDescriptionSolid
                      color={getRandomColor()}
                      size={20}
                    />
                  }
                  label={"Description"}
                  logText={true}
                  name={data?.description || "NA"}
                />
              </div>

              <hr
                style={{
                  marginTop: "10px",
                  marginBottom: "12px",
                }}
              />
              <div style={{ marginBottom: "16px" }}>
                <p
                  style={{
                    fontWeight: "700",
                    paddingBottom: "8px",
                  }}
                >
                  Files
                </p>
                <div
                  style={{
                    display: "grid",
                    gap: "16px",
                  }}
                >
                  {data?.fileLinkS3Url ? (
                    <div
                      className="cursor-pointer"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "5px 12px",
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <FaRegFileLines color={getRandomColor()} size={20} />
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            {data?.fileLink?.split("/").pop()}
                          </div>
                          <div
                            style={{
                              fontSize: "10px",
                              color: "#7D7D7D",
                              textTransform: "capitalize",
                            }}
                          >
                            {data?.type}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <NavLink
                          to={{
                            pathname: "/doc",
                            search: `?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                              data?.fileLinkS3Url || ""
                            )}&title=${encodeURIComponent(
                              data?.fileLink?.split("/").pop()
                            )}`,
                            state: { ...location.state },
                          }}
                        >
                          <span
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                            }}
                            title="View file"
                          >
                            <LuEye color={getRandomColor()} size={20} />
                          </span>
                        </NavLink>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px 12px",
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            No file found.
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {permissions?.isUpdateAllowed && (
          <NavLink
            to={
              data?.id
                ? `/edit-knowledge-base?next=${encodeURIComponent(window.location.pathname + window.location.search)}`
                : "#"
            }
            state={data?.id ? { id: data.id } : null}
          >
            <ButtonLightTextIcon title="Edit Details" icon={<FiEdit />} />
          </NavLink>
        )}
      </div>
    </div>
  );
};
