import {useRef, useState } from "react";
import ResultsPerPage from "../../components/common/ResultsPerPage";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import Label from "../../components/ui/Label";
import useQueryParams from "../../hooks/useQueryParams";
import { GrPowerReset } from "react-icons/gr";

const TableFilter = ({ resetFilters }) => {
  const { updateQueryParam, queryParams } = useQueryParams();
  const [searchValue, setSearchValue] = useState(queryParams.get("search") || "");
  const [assistantSearchValue, setAssistantSearchValue] = useState(queryParams.get("assistantSearch") || "");
  const debounceTimeouts = useRef({ search: null, assistantSearch: null });

  const handleDebouncedChange = (key, value, setValue) => {
    setValue(value);

    if (debounceTimeouts.current[key]) {
      clearTimeout(debounceTimeouts.current[key]);
    }

    debounceTimeouts.current[key] = setTimeout(() => {
      queryParams.set("pageNo", 0);
      updateQueryParam(key, value);
    }, 500);
  }

  return (
    <div className="col-1-1 flex center-left filters">
      <div>
        <Label variant="filter" title="Assistant Name" />
        <input
          className="primary"
          placeholder="Eg: John Doe"
          type="text"
          value={assistantSearchValue}
          onChange={(e) =>
            handleDebouncedChange("assistantSearch", e.target.value, setAssistantSearchValue)
          }
        />
      </div>
      <div>
        <Label variant="filter" title="Surgeon Name" />
        <input
          className="primary"
          placeholder="Eg: Johnny Bravo"
          type="text"
          value={searchValue}
          onChange={(e) =>
            handleDebouncedChange("search", e.target.value, setSearchValue)
          }
        />
      </div>
      <div className="flex center-left" style={{ gap: "12px" }}>
        <div>
          <Label variant="filter" title="Reset" />
          <ButtonLightTextIcon
            title="Reset"
            className="light"
            icon={
              <GrPowerReset />
            }
            onClick={() => {
              setSearchValue("");
              setAssistantSearchValue("");
              resetFilters();
            }}
          />
        </div>
        <div className="mar-l-a">
          <Label variant="filter" title="Per page" />
          <ResultsPerPage />
        </div>
      </div>
    </div>
  );
};

export default TableFilter;
