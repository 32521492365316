import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useEffect, useState } from "react";
import {
  API_URL,
  FACILITY_LISTING_OPTIONS,
  PATIENT_LISTING_OPTIONS,
  PREFRENCE_CARD_LISTING_OPTIONS,
  SURGEON_LISTING_OPTIONS,
} from "../../utilities/constants";
import PaginatedDropdown, {
  mapOptions,
} from "../../components/ui/PaginatedDropdown";
import { useUI } from "../../context/ui";
import { handleApiCall, sleep } from "../../utilities/helpers";
import { useNavigate } from "react-router-dom";
import Label from "../../components/ui/Label";
import useQueryParams from "../../hooks/useQueryParams";
import { FiArrowRightCircle } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";
import { MdCancel } from "react-icons/md";

const UpdateForm = ({ groupData }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [webModule, setWebModule] = useState(null);
  const [appModule, setAppModule] = useState(null);
  const { addToastMessage } = useUI();
  const {queryParams,updateQueryParam } =useQueryParams();
	const nextUrl = decodeURIComponent(queryParams.get("next")) || "";

  const [initialValues, setInitialValues] = useState({
    id: null,
    preferenceCardListingOption: "",
    name: "",
    subCatagoryDTOS: [],
    facilityListingOption: "",
    patientListingOption: "",
    surgeonListingOption: "",
    webModules: [],
    appModules: [],
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  useEffect(() => {
    setInitialValues({
      id: groupData?.id,
      name: groupData?.groupName || null,
      subCatagoryDTOS: [],
      facilityListingOption: groupData?.facilityListingOption || null,
      patientListingOption: groupData?.patientListingOption || null,
      surgeonListingOption: groupData?.surgeonListingOption || null,
      webModules: groupData?.webModules
        ? groupData?.webModules.map((item) => ({
            val: item.module.id,
            name: item.module.moduleName,
            isAddAccess: item.isAddAccess,
            isListAccess: item.isListAccess,
            isUpdateAccess: item.isUpdateAccess,
            isDeleteAccess: item.isDeleteAccess,
          }))
        : [],
      appModules: groupData?.appModules
        ? groupData?.appModules.map((item) => ({
            val: item.module.id,
            name: item.module.moduleName,
            isAddAccess: item.isAddAccess,
            isListAccess: item.isListAccess,
            isUpdateAccess: item.isUpdateAccess,
            isDeleteAccess: item.isDeleteAccess,
          }))
        : [],
      preferenceCardListingOption:
        groupData?.preferenceCardListingOption || null,
    });
  }, [groupData]);

  const updateGroup = async (values) => {
    setLoading(true);
    setStatus("");

    if (!values?.id) {
      return false;
    }

    try {
      const payload = {
        name: values.name,
        preferenceCardListingOption: values.preferenceCardListingOption,
        patientListingOption: values.patientListingOption,
        surgeonListingOption: values.surgeonListingOption,
        facilityListingOption: values.facilityListingOption,
        appModules: values.appModules
          ? values.appModules.map((item) => ({
              id: item.val,
              isAddAccess: item.isAddAccess,
              isListAccess: item.isListAccess,
              isUpdateAccess: item.isUpdateAccess,
              isDeleteAccess: item.isDeleteAccess,
            }))
          : [],
        webModules: values.webModules
          ? values.webModules.map((item) => ({
              id: item.val,
              isAddAccess: item.isAddAccess,
              isListAccess: item.isListAccess,
              isUpdateAccess: item.isUpdateAccess,
              isDeleteAccess: item.isDeleteAccess,
            }))
          : [],
      };
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/groups/${values?.id}`,
        {
          method: "PUT",
          body: payload,
        }
      );
      if (response.responseStatus === true) {
        setStatus("You have successfully updated a group, thank you.");
        addToastMessage({
          status: true,
          message: `You have successfully updated a group, thank you.`,
        });
        if (nextUrl) {
          navigate(nextUrl);
        } else {
          navigate("/groups");
        }
        setLoading(false);
        return true;
      } else {
        setStatus(
          `${response.responseDescription || "Something went wrong, please try again later."}`
        );
        addToastMessage({
          status: false,
          message: `${response.responseDescription || "Something went wrong, please try again later."}`,
        });
        setLoading(false);
        return false;
      }
    } catch (e) {
      console.error("Error:", e);
      addToastMessage({
        status: false,
        message: `Something went wrong, please try again later.`,
      });
      return false;
    }
  };

  const labelModuleFunc = (o) => `${o.moduleName}`;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await updateGroup(values);
        if (api) {
          resetForm();
        }
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        validateForm,
        handleSubmit,
        setFieldTouched,
      }) => (
        <Form>
          <div className="col-1-1">
            <table>
              <tbody>
                <tr>
                  <td colSpan="3" width="50%">
                    <Label title="Group Name" />
                    <Field
                      type="text"
                      placeholder="Enter Group Name"
                      name="name"
                      className={`col-1-1 primary ${
                        errors.name && touched.name ? "error" : ""
                      }`}
                    />
                  </td>
                  <td colSpan="3" width="50%">
                    <Label title="Patient Listing Options" />
                    <Field
                      as="select"
                      name="patientListingOption"
                      className={`col-1-1 primary ${
                        errors.patientListingOption &&
                        touched.patientListingOption
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {PATIENT_LISTING_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <Label title="Facility Listing Options" />
                    <Field
                      as="select"
                      name="facilityListingOption"
                      className={`col-1-1 primary ${
                        errors.facilityListingOption &&
                        touched.facilityListingOption
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {FACILITY_LISTING_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                  <td colSpan="3">
                    <Label title="Surgeon Listing Options" />
                    <Field
                      as="select"
                      name="surgeonListingOption"
                      className={`col-1-1 primary ${
                        errors.surgeonListingOption &&
                        touched.surgeonListingOption
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {SURGEON_LISTING_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <Label title="Preference Card Listing Options" />
                    <Field
                      as="select"
                      name="preferenceCardListingOption"
                      className={`col-1-1 primary ${
                        errors.preferenceCardListingOption &&
                        touched.preferenceCardListingOption
                          ? "error"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {PREFRENCE_CARD_LISTING_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td colSpan="6">
                    <Label title="Web Module " />
                    <PaginatedDropdown
                      searchkey="moduleName"
                      datakey="list"
                      url={`${API_URL}/api/1.0.0/reference/module?isOnApp=${false}&`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelModuleFunc)
                      }
                      error={errors.webModules && touched.webModules}
                      setTouched={() => {
                        setFieldTouched("webModules", true);
                      }}
                      selectedValue={webModule}
                      placeHolder="Search"
                      onChange={async (v) => {
                        if (v) {
                          setWebModule(v);
                          const p = values.webModules;
                          const n = {
                            val: v.value,
                            name: v.label,
                            isAddAccess: false,
                            isDeleteAccess: false,
                            isListAccess: false,
                            isUpdateAccess: false,
                          };
                          if (!p.some((item) => item.val === v.value)) {
                            setFieldValue("webModules", [
                              ...values.webModules,
                              n,
                            ]);
                          }
                        }
                        await sleep();
                        setWebModule(null);
                      }}
                    />

                    {values.webModules.length > 0 && (
                      <ul
                        className="col-1-1 t-a-l"
                        style={{
                          padding: "10px 0",
                        }}
                      >
                        {values.webModules.map((p, j) => (
                          <li
                            data-tooltip={`Click to delete the module.`}
                            onClick={() => {
                              const filtered = values.webModules.filter(
                                (obj) => obj.val !== p.val
                              );
                              setFieldValue("webModules", filtered);
                            }}
                            style={{
                              listStyle: "none",
                              marginRight: "4px",
                              marginBottom: "4px",
                              borderRadius: "2px",
                              lineHeight: "32px",
                              padding: "10px 12px",
                              verticalAlign: "middle",
                            }}
                            key={j}
                            className="opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                          >
                            <div className="flex center-left">
                              <p
                                className="of-hid"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "108px",
                                  textOverflow: "ellipsis",
                                  fontSize: "12px",
                                }}
                              >
                                {p.name}
                              </p>
                              <MdCancel size={20} />
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                </tr>
                {/* {Web Module} */}
                {values.webModules.length > 0 && (
                  <FieldArray name="webModules">
                    {({ push, remove }) => (
                      <>
                        <tr>
                          <td className="color-primary">Module</td>
                          <td className="color-primary">List</td>
                          <td className="color-primary">Add</td>
                          <td className="color-primary">Update</td>
                          <td className="color-primary">Delete</td>
                          <td className="color-primary">Action</td>
                        </tr>

                        {values.webModules.length > 0 && (
                          <>
                            {" "}
                            {values.webModules.map((val, index) => (
                              <tr key={index}>
                                <td>
                                  <p className="color-primary">{val.name}</p>
                                </td>
                                <td width="12%">
                                  <Field
                                    name={`webModules.${index}.isListAccess`}
                                    type="checkbox"
                                    className="checkbox-field primary"
                                  />
                                </td>
                                <td width="12%">
                                  <Field
                                    name={`webModules.${index}.isAddAccess`}
                                    type="checkbox"
                                    className="primary checkbox-field "
                                  />
                                </td>
                                <td width="12%">
                                  <Field
                                    name={`webModules.${index}.isUpdateAccess`}
                                    type="checkbox"
                                    className="primary checkbox-field "
                                  />
                                </td>
                                <td width="12%">
                                  <Field
                                    name={`webModules.${index}.isDeleteAccess`}
                                    type="checkbox"
                                    className="primary checkbox-field"
                                  />
                                </td>
                                <td width="12%">
                                  <button
                                    type="button"
                                    data-button="delete"
                                    onClick={() => remove(index)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </FieldArray>
                )}

                <tr>
                  <td colSpan="6">
                    <Label title="App Module " />
                    <PaginatedDropdown
                      searchkey="moduleName"
                      datakey="list"
                      url={`${API_URL}/api/1.0.0/reference/module?isOnApp=${true}&`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelModuleFunc)
                      }
                      error={errors.appModules && touched.appModules}
                      setTouched={() => {
                        setFieldTouched("appModules", true);
                      }}
                      selectedValue={appModule}
                      placeHolder="Search"
                      onChange={async (v) => {
                        if (v) {
                          setAppModule(v);
                          const p = values.appModules;
                          const n = {
                            val: v.value,
                            name: v.label,
                            isAddAccess: false,
                            isDeleteAccess: false,
                            isListAccess: false,
                            isUpdateAccess: false,
                          };
                          if (!p.some((item) => item.val === v.value)) {
                            setFieldValue("appModules", [
                              ...values.appModules,
                              n,
                            ]);
                          }
                        }
                        await sleep();
                        setAppModule(null);
                      }}
                    />

                    {values.appModules.length > 0 && (
                      <ul
                        className="col-1-1 t-a-l"
                        style={{
                          padding: "10px 0",
                        }}
                      >
                        {values.appModules.map((p, j) => (
                          <li
                            data-tooltip={`Click to delete the module.`}
                            onClick={() => {
                              const filtered = values.appModules.filter(
                                (obj) => obj.val !== p.val
                              );
                              setFieldValue("appModules", filtered);
                            }}
                            style={{
                              listStyle: "none",
                              marginRight: "4px",
                              marginBottom: "4px",
                              borderRadius: "2px",
                              lineHeight: "32px",
                              padding: "10px 12px",
                              verticalAlign: "middle",
                            }}
                            key={j}
                            className="opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                          >
                            <div className="flex center-left">
                              <p
                                className="of-hid"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "108px",
                                  textOverflow: "ellipsis",
                                  fontSize: "12px",
                                }}
                              >
                                {p.name}
                              </p>

                              <MdCancel size={20} />
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                </tr>
                {/* {App Module} */}
                {values.appModules.length > 0 && (
                  <FieldArray name="appModules">
                    {({ push, remove }) => (
                      <>
                        <tr>
                          <td className="color-primary">Module</td>
                          <td className="color-primary">List</td>
                          <td className="color-primary">Add</td>
                          <td className="color-primary">Update</td>
                          <td className="color-primary">Delete</td>
                          <td className="color-primary">Action</td>
                        </tr>
                        {values.appModules.length > 0 && (
                          <>
                            {values.appModules.map((val, index) => (
                              <tr key={index}>
                                <td className="color-primary">{val.name} </td>
                                <td>
                                  <Field
                                    name={`appModules.${index}.isListAccess`}
                                    type="checkbox"
                                    className="primary ceneter-center checkbox-field "
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`appModules.${index}.isAddAccess`}
                                    type="checkbox"
                                    className="primary checkbox-field "
                                  />
                                </td>
                                <td className="center-center">
                                  <Field
                                    name={`appModules.${index}.isUpdateAccess`}
                                    type="checkbox"
                                    className="primary center-center checkbox-field "
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`appModules.${index}.isDeleteAccess`}
                                    type="checkbox"
                                    className="primary "
                                  />
                                </td>
                                <td>
                                  <button
                                    data-button="delete"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </FieldArray>
                )}
              </tbody>
            </table>
          </div>

          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => {
                if (nextUrl) {
                  navigate(nextUrl);
                } else {
                  navigate("/groups");
                }
              }}
              icon={<TbArrowBackUp />}
            />
            <ButtonTextIcon
              type="submit"
              disabled={loading}
              title="Update Now"
              onClick={async () => {
                const errors = await validateForm();
                if (Object.keys(errors).length > 0) {
                  const firstErrorMessage = Object.values(errors)[0];
                  addToastMessage({
                    status: false,
                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                  });
                }
                handleSubmit();
              }}
              className="mar-l-a"
              icon={loading ? <LoaderIcon /> : <FiArrowRightCircle />}
            />
          </div>
          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default UpdateForm;
