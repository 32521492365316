import { useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoaderIcon from "../../components/ui/LoaderIcon";
import {
  formatDateTime,
  formatInputPhoneNumber,
  formatServerDate,
  getRandomColor,
  handleApiCall,
} from "../../utilities/helpers";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import InfoItem from "../../components/ui/InfoItem";
import useQueryParams from "../../hooks/useQueryParams";
import {
  TbArrowBackUp,
  TbCalendarShare,
  TbLicense,
  TbTimelineEvent,
  TbUserCircle,
} from "react-icons/tb";
import { Ri24HoursFill } from "react-icons/ri";
import { IoSchoolOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { HiCheckBadge } from "react-icons/hi2";
import {
  MdAirplanemodeInactive,
  MdAlternateEmail,
  MdFolderSpecial,
  MdOutlineAccountBalance,
  MdOutlineLocalActivity,
  MdOutlineSpeakerNotes,
} from "react-icons/md";
import { FaUserDoctor } from "react-icons/fa6";
import { LuEye, LuMapPin, LuMessageCircleCode, LuSchool } from "react-icons/lu";
import { CiHospital1, CiVoicemail, CiWallet } from "react-icons/ci";
import { SiCodesignal } from "react-icons/si";
import { PiPathBold } from "react-icons/pi";

const StatusIcon = ({ id }) => {
  switch (id) {
    case 1:
      return (
        <div className="flex center-left" style={{ gap: "6px" }}>
          <HiCheckBadge color="#27ae60" size={20} />
          <p
            className="f-w-300"
            style={{
              fontSize: "12px",
              color: "#27ae60",
            }}
          >
            Active
          </p>
        </div>
      );
    case 0:
      return (
        <div className="flex center-left" style={{ gap: "6px" }}>
          <MdAirplanemodeInactive color={"#E65464"} />
          <p
            className="f-w-300"
            style={{
              fontSize: "12px",
              color: "#E65464",
            }}
          >
            Inactive
          </p>
        </div>
      );
    default:
      return <></>;
  }
};

const PhysicianAssistantDetails = ({ setViewAssistant, id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { permissions } = useUI();
  const { queryParams, updateQueryParam } = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const fetchFacilityData = async (id) => {
    try {
      const response = await handleApiCall(
        API_URL + `/api/1.0.0/physician-assistant/${id}`,
        {
          method: "GET",
        }
      );
      if (response.responseStatus == true) {
        setData(response.data);
        setLoading(false);
      } else {
        setError("Failed to fetch facility data.");
        setLoading(false);
      }
    } catch (e) {
      setError("Failed to fetch facility data.");
      setLoading(false);
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    if (id) fetchFacilityData(id);
  }, [id]);

  return (
    <section
      style={{
        position: "fixed",
        inset: 0,
        zIndex: 9999,
        backgroundColor: "rgba(255, 255, 255, 0.86)",
      }}
    >
      <section
        className="col-1-1 flex center-center"
        style={{
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          id="activity-detail-box"
          style={{
            backgroundColor: "rgba(255, 255, 255)",
            borderRadius: "12px",
          }}
        >
          <div className="flex center-left">
            <div>
              <h6 className="color-primary f-w-300">
                Physician Assistant Details
              </h6>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <ButtonLightTextIcon
                title="Cancel"
                onClick={() => {
                  setViewAssistant(false);
                  updateQueryParam("showId");
                }}
                icon={<TbArrowBackUp />}
              />
            </div>
          </div>
          <div
            style={{
              height: "60vh",
              margin: "10px",
              overflowY: "scroll",
              padding: "20px 5px",
              overflowX: "hidden",
              scrollbarWidth: "thin",
            }}
          >
            {loading && (
              <div
                className="col-1-1"
                style={{
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoaderIcon
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
            )}
            {!loading && error && (
              <p className="f-w-300 color-primary" style={{ color: "#c0392b" }}>
                {error}
              </p>
            )}
            {data && !loading && !error && (
              <div className="activity-card pos-rel">
                <div
                  style={{
                    position: "absolute",
                    content: "",
                    height: "4px",
                    left: "-1px",
                    right: "-1px",
                    bottom: "-2px",
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                ></div>
                <div
                  className="col-1-1 flex center-left"
                  style={{
                    gap: "10px",
                    paddingBottom: "5px",
                  }}
                >
                  <h5 className="f-w-700">
                    <span className="f-w-300">#</span>
                    &nbsp;
                    {data?.id}
                  </h5>
                  <hr
                    style={{
                      outline: "none",
                      border: "none",
                      height: "16px",
                      width: "2px",
                      background: "#EBEBEB",
                    }}
                  />
                  <StatusIcon id={data.physicianAssistant.statusId} />
                </div>
                <div
                  style={{
                    padding: "12px 0 12px 0 ",
                  }}
                >
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<FaUserDoctor color={getRandomColor()} size={20} />}
                      label={"Physician Assistant"}
                      name={data?.name + " " + data?.lastName}
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<LuMapPin color={getRandomColor()} size={20} />}
                      label={"Address"}
                      name={data?.physicianAssistant?.homeAddress || "NA"}
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <TbTimelineEvent color={getRandomColor()} size={20} />
                      }
                      label={"Type"}
                      name={data?.type || "NA"}
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<CiVoicemail color={getRandomColor()} size={20} />}
                      label={"Work Phone"}
                      name={
                        data?.physicianAssistant?.workCellPhone
                          ? formatInputPhoneNumber(
                              data.physicianAssistant.workCellPhone.trim()
                            )
                          : "NA"
                      }
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<CiVoicemail color={getRandomColor()} size={20} />}
                      label={"Cell Phone"}
                      name={
                        data?.physicianAssistant?.cellPhone
                          ? formatInputPhoneNumber(
                              data.physicianAssistant.cellPhone.trim()
                            )
                          : "NA"
                      }
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <MdAlternateEmail color={getRandomColor()} size={20} />
                      }
                      label={"Work Email"}
                      name={data?.physicianAssistant?.workEmail || "NA"}
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <MdAlternateEmail color={getRandomColor()} size={20} />
                      }
                      label={"Email"}
                      name={data?.email || "NA"}
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<TbLicense color={getRandomColor()} size={20} />}
                      label={"License"}
                      name={data?.physicianAssistant?.drivingLicense || "NA"}
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<SiCodesignal color={getRandomColor()} size={20} />}
                      label={"Primary Taxonomy"}
                      name={data?.physicianAssistant?.primaryTaxonomy || "NA"}
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<SiCodesignal color={getRandomColor()} size={20} />}
                      label={"Secondary Taxonomy"}
                      name={data?.physicianAssistant?.secondaryTaxonomy || "NA"}
                    />
                  </div>

                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <MdFolderSpecial color={getRandomColor()} size={20} />
                      }
                      label={"Certificate"}
                      name={data?.physicianAssistant?.certificate?.name || "NA"}
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<PiPathBold color={getRandomColor()} size={20} />}
                      label={"Region"}
                      name={data?.physicianAssistant?.region?.name || "NA"}
                    />
                  </div>
                  <div
                    className="info-row flex center-center"
                    style={{
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={<LuMapPin color={getRandomColor()} size={20} />}
                      label={"Zip"}
                      name={data?.physicianAssistant?.homeZip || "NA"}
                    />
                    <InfoItem
                      className="col-1-1 info-row-column"
                      icon={
                        <MdFolderSpecial color={getRandomColor()} size={20} />
                      }
                      label={"Credential"}
                      name={data?.physicianAssistant?.credential || "NA"}
                    />
                  </div>
                  <hr
                    style={{
                      marginTop: "10px",
                      marginBottom: "12px",
                    }}
                  />
                  <p
                    style={{
                      fontWeight: "700",
                      paddingBottom: "8px",
                    }}
                  >
                    Facility
                  </p>
                  {data?.physicianAssistant?.facilities?.length ? (
                    data.physicianAssistant?.facilities.map(
                      (facilities, index) => (
                        <>
                          <div
                            key={index}
                            className="info-row flex center-center"
                            style={{
                              gap: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <CiHospital1
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label="Facility"
                              name={facilities.name || "NA"}
                            />
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <CiWallet color={getRandomColor()} size={20} />
                              }
                              label={"Paid Fee"}
                              name={facilities.feePaid || "NA"}
                            />
                          </div>
                          <div
                            key={index}
                            className="info-row flex center-center"
                            style={{
                              gap: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <TbCalendarShare
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label="Renewal Date"
                              name={
                                facilities?.renewalDate
                                  ? formatServerDate(facilities?.renewalDate)
                                  : "NA"
                              }
                            />
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <TbCalendarShare
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label={"Expired Date"}
                              name={
                                facilities?.expiryDate
                                  ? formatServerDate(facilities?.expiryDate)
                                  : "NA"
                              }
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            {facilities?.s3attachment && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  width: "100%",
                                  gap: "8px",
                                }}
                              >
                                <NavLink
                                  to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                    facilities?.s3attachment || ""
                                  )}&title=${encodeURIComponent("")}`}
                                  state={location.state}
                                >
                                  <InfoItem
                                    className="col-1-1 info-row-column cursor-pointer"
                                    icon={
                                      <LuEye
                                        color={getRandomColor()}
                                        size={20}
                                      />
                                    }
                                    label={"Attachment"}
                                    name="Click to View"
                                  />
                                </NavLink>
                              </div>
                            )}
                          </div>
                          <hr
                            style={{
                              marginTop: "10px",
                              marginBottom: "12px",
                            }}
                          />
                        </>
                      )
                    )
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px 12px",
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            No Data Available
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <p
                    style={{
                      fontWeight: "700",
                      paddingBottom: "8px",
                    }}
                  >
                    Vaccination
                  </p>
                  {data?.physicianAssistant?.vaccinations?.length ? (
                    data.physicianAssistant?.vaccinations.map(
                      (vaccinations, index) => (
                        <>
                          <div
                            key={index}
                            className="info-row flex center-center"
                            style={{
                              gap: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <MdOutlineLocalActivity
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label="Vaccinations"
                              name={vaccinations.name || "NA"}
                            />

                            <div style={{ width: "100%" }}>
                              {vaccinations?.s3attachment && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    width: "100%",
                                    gap: "8px",
                                  }}
                                >
                                  <NavLink
                                    to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                      vaccinations?.s3attachment || ""
                                    )}&title=${encodeURIComponent("")}`}
                                    state={location.state}
                                  >
                                    <InfoItem
                                      className="col-1-1 info-row-column cursor-pointer"
                                      icon={
                                        <LuEye
                                          color={getRandomColor()}
                                          size={20}
                                        />
                                      }
                                      label={"Attachment"}
                                      name="Click to View"
                                    />
                                  </NavLink>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            key={index}
                            className="info-row flex center-center"
                            style={{
                              gap: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <TbCalendarShare
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label="Administered Date"
                              name={
                                vaccinations?.administeredDate
                                  ? formatServerDate(
                                      vaccinations?.administeredDate
                                    )
                                  : "NA"
                              }
                            />
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <TbCalendarShare
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label={"Expired Date"}
                              name={
                                vaccinations?.expiryDate
                                  ? formatServerDate(vaccinations?.expiryDate)
                                  : "NA"
                              }
                            />
                          </div>
                          <hr
                            style={{
                              marginTop: "10px",
                              marginBottom: "12px",
                            }}
                          />
                        </>
                      )
                    )
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px 12px",
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            No Data Available
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <p
                    style={{
                      fontWeight: "700",
                      paddingBottom: "8px",
                    }}
                  >
                    Screenings
                  </p>
                  {data?.physicianAssistant?.screenings?.length ? (
                    data.physicianAssistant?.screenings.map(
                      (screenings, index) => (
                        <>
                          <div
                            key={index}
                            className="info-row flex center-center"
                            style={{
                              gap: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <TbUserCircle
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label="Screenings"
                              name={screenings.name || "NA"}
                            />
                            {screenings?.attachmentS3URL && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  width: "100%",
                                  gap: "8px",
                                }}
                              >
                                <NavLink
                                  to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                    screenings?.attachmentS3URL || ""
                                  )}&title=${encodeURIComponent("")}`}
                                  state={location.state}
                                >
                                  <InfoItem
                                    className="col-1-1 info-row-column cursor-pointer"
                                    icon={
                                      <LuEye
                                        color={getRandomColor()}
                                        size={20}
                                      />
                                    }
                                    label={"Attachment"}
                                    name="Click to View"
                                  />
                                </NavLink>
                              </div>
                            )}
                          </div>
                          <div
                            key={index}
                            className="info-row flex center-center"
                            style={{
                              gap: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <TbCalendarShare
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label="Administered Date"
                              name={
                                screenings?.administeredDate
                                  ? formatServerDate(
                                      screenings?.administeredDate
                                    )
                                  : "NA"
                              }
                            />
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <TbCalendarShare
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label={"Expired Date"}
                              name={
                                screenings?.expiryDate
                                  ? formatServerDate(screenings?.expiryDate)
                                  : "NA"
                              }
                            />
                          </div>
                          <hr
                            style={{
                              marginTop: "10px",
                              marginBottom: "12px",
                            }}
                          />
                        </>
                      )
                    )
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px 12px",
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            No Data Available
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <p
                    style={{
                      fontWeight: "700",
                      paddingBottom: "8px",
                    }}
                  >
                    Background Check
                  </p>
                  {data?.physicianAssistant?.backgroundChecks?.length ? (
                    data.physicianAssistant?.backgroundChecks.map(
                      (backgroundChecks, index) => (
                        <>
                          <div
                            key={index}
                            className="info-row flex center-center"
                            style={{
                              gap: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <CiWallet color={getRandomColor()} size={20} />
                              }
                              label="Paid Fee"
                              name={backgroundChecks.feePaid || "NA"}
                            />
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <MdOutlineSpeakerNotes
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label={"Notes"}
                              name={backgroundChecks.notes || "NA"}
                            />
                          </div>
                          <div
                            key={index}
                            className="info-row flex center-center"
                            style={{
                              gap: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <div style={{ width: "100%" }}>
                              {backgroundChecks?.s3attachment && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    width: "100%",
                                    gap: "8px",
                                  }}
                                >
                                  <NavLink
                                    to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                      backgroundChecks?.s3attachment || ""
                                    )}&title=${encodeURIComponent("")}`}
                                    state={location.state}
                                  >
                                    <InfoItem
                                      className="col-1-1 info-row-column cursor-pointer"
                                      icon={
                                        <LuEye
                                          color={getRandomColor()}
                                          size={20}
                                        />
                                      }
                                      label={"Attachment"}
                                      name="Click to View"
                                    />
                                  </NavLink>
                                </div>
                              )}
                            </div>
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <TbCalendarShare
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label={"Performed Date"}
                              name={
                                backgroundChecks?.datePerformed
                                  ? formatServerDate(
                                      backgroundChecks?.datePerformed
                                    )
                                  : "NA"
                              }
                            />
                          </div>
                          <hr
                            style={{
                              marginTop: "10px",
                              marginBottom: "12px",
                            }}
                          />
                        </>
                      )
                    )
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px 12px",
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            No Data Available
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <p
                    style={{
                      fontWeight: "700",
                      paddingBottom: "8px",
                    }}
                  >
                    PTO's
                  </p>
                  {data?.physicianAssistant?.ptos?.length ? (
                    data.physicianAssistant?.ptos.map((ptos, index) => (
                      <>
                        <div
                          key={index}
                          className="info-row flex center-center"
                          style={{
                            gap: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <TbTimelineEvent
                                color={getRandomColor()}
                                size={20}
                              />
                            }
                            label="Type"
                            name={ptos.type || "NA"}
                          />
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <Ri24HoursFill
                                color={getRandomColor()}
                                size={20}
                              />
                            }
                            label={"Hours"}
                            name={ptos.amount || "NA"}
                          />
                        </div>
                        <div
                          key={index}
                          className="info-row flex center-center"
                          style={{
                            gap: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <TbCalendarShare
                                color={getRandomColor()}
                                size={20}
                              />
                            }
                            label={"Start Date"}
                            name={
                              ptos?.date ? formatServerDate(ptos?.date) : "NA"
                            }
                          />
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <TbCalendarShare
                                color={getRandomColor()}
                                size={20}
                              />
                            }
                            label={"End Date"}
                            name={
                              ptos?.endDate
                                ? formatServerDate(ptos?.endDate)
                                : "NA"
                            }
                          />
                        </div>
                        <div
                          key={index}
                          className="info-row flex center-center"
                          style={{
                            gap: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          {ptos?.s3attachment && (
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  width: "100%",
                                  gap: "8px",
                                }}
                              >
                                <NavLink
                                  to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                    ptos?.s3attachment || ""
                                  )}&title=${encodeURIComponent("")}`}
                                  state={location.state}
                                >
                                  <InfoItem
                                    className="col-1-1 info-row-column cursor-pointer"
                                    icon={
                                      <LuEye
                                        color={getRandomColor()}
                                        size={20}
                                      />
                                    }
                                    label={"Attachment"}
                                    name="Click to View"
                                  />
                                </NavLink>
                              </div>
                            </div>
                          )}
                        </div>
                        <hr
                          style={{
                            marginTop: "10px",
                            marginBottom: "12px",
                          }}
                        />
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px 12px",
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            No Data Available
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <p
                    style={{
                      fontWeight: "700",
                      paddingBottom: "8px",
                    }}
                  >
                    Education
                  </p>
                  {data?.physicianAssistant?.degrees?.length ? (
                    data.physicianAssistant?.degrees.map((degree, index) => (
                      <>
                        <div
                          key={index}
                          className="info-row flex center-center"
                          style={{
                            gap: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <LuSchool color={getRandomColor()} size={20} />
                            }
                            label="Name Of School"
                            name={degree.nameOfSchool || "NA"}
                          />
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <IoSchoolOutline
                                color={getRandomColor()}
                                size={20}
                              />
                            }
                            label="Degree"
                            name={degree.degree || "NA"}
                          />
                        </div>
                        <div
                          key={index}
                          className="info-row flex center-center"
                          style={{
                            gap: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <TbCalendarShare
                                color={getRandomColor()}
                                size={20}
                              />
                            }
                            label="Date Graduated"
                            name={
                              degree?.dateAcquired
                                ? formatServerDate(degree?.dateAcquired)
                                : "NA"
                            }
                          />
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <TbCalendarShare
                                color={getRandomColor()}
                                size={20}
                              />
                            }
                            label={"Date Expired"}
                            name={
                              degree?.dateExpired
                                ? formatServerDate(degree?.dateExpired)
                                : "NA"
                            }
                          />
                        </div>
                        <div
                          key={index}
                          className="info-row flex center-center"
                          style={{
                            gap: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <InfoItem
                            className="col-1-1 info-row-column"
                            icon={
                              <LuMessageCircleCode
                                color={getRandomColor()}
                                size={20}
                              />
                            }
                            label={"Status"}
                            name={degree.status || "NA"}
                          />
                          <div style={{ width: "100%" }}>
                            {degree?.s3attachment && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  width: "100%",
                                  gap: "8px",
                                }}
                              >
                                <NavLink
                                  to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                    degree?.s3attachment || ""
                                  )}&title=${encodeURIComponent("")}`}
                                  state={location.state}
                                >
                                  <InfoItem
                                    className="col-1-1 info-row-column cursor-pointer"
                                    icon={
                                      <LuEye
                                        color={getRandomColor()}
                                        size={20}
                                      />
                                    }
                                    label={"Attachment"}
                                    name="Click to View"
                                  />
                                </NavLink>
                              </div>
                            )}
                          </div>
                        </div>

                        <hr
                          style={{
                            marginTop: "10px",
                            marginBottom: "12px",
                          }}
                        />
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px 12px",
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            No Data Available
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <p
                    style={{
                      fontWeight: "700",
                      paddingBottom: "8px",
                    }}
                  >
                    Documents
                  </p>
                  {data?.physicianAssistant?.documents?.length ? (
                    data.physicianAssistant?.documents.map(
                      (documents, index) => (
                        <>
                          <div
                            key={index}
                            className="info-row flex center-center"
                            style={{
                              gap: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <MdFolderSpecial
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label="Document Name"
                              name={documents.name || "NA"}
                            />
                            {documents?.documentS3Url && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  width: "100%",
                                  gap: "8px",
                                }}
                              >
                                <NavLink
                                  to={`/doc?next=${encodeURIComponent(window.location.pathname + window.location.search)}&fileUrl=${encodeURIComponent(
                                    documents?.documentS3Url || ""
                                  )}&title=${encodeURIComponent("")}`}
                                  state={location.state}
                                >
                                  <InfoItem
                                    className="col-1-1 info-row-column cursor-pointer"
                                    icon={
                                      <LuEye
                                        color={getRandomColor()}
                                        size={20}
                                      />
                                    }
                                    label={"Attachment"}
                                    name="Click to View"
                                  />
                                </NavLink>
                              </div>
                            )}
                          </div>
                          <div
                            key={index}
                            className="info-row flex center-center"
                            style={{
                              gap: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <TbCalendarShare
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label="Created At"
                              name={
                                documents?.createdAt
                                  ? formatDateTime(documents?.createdAt)
                                  : "NA"
                              }
                            />
                            <InfoItem
                              className="col-1-1 info-row-column"
                              icon={
                                <MdOutlineSpeakerNotes
                                  color={getRandomColor()}
                                  size={20}
                                />
                              }
                              label={"Notes"}
                              name={documents.notes || "NA"}
                            />
                          </div>
                          <hr
                            style={{
                              marginTop: "10px",
                              marginBottom: "12px",
                            }}
                          />
                        </>
                      )
                    )
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px 12px",
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            No Data Available
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            {permissions?.isUpdateAllowed && (
              <ButtonLightTextIcon
                title="Edit Details"
                onClick={() => {
                  updateQueryParam("showId");
                  if (data?.physicianAssistant?.id) {
                    navigate(
                      `/edit-physician-assistant?next=${encodeURIComponent(window.location.pathname + "?" + queryParams.toString())}`,
                      {
                        state: {
                          id: data.physicianAssistant.id,
                        },
                      }
                    );
                  }
                }}
                icon={<FiEdit />}
              />
            )}
          </div>
        </div>
      </section>
    </section>
  );
};

export default PhysicianAssistantDetails;
